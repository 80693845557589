export const FETCH_CURRENCIES = "FETCH_CURRENCIES";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCIES_FAILURE = "FETCH_CURRENCIES_FAILURE";

export const FETCH_TIMEZONES = "FETCH_TIMEZONES";
export const FETCH_TIMEZONES_SUCCESS = "FETCH_TIMEZONES_SUCCESS";
export const FETCH_TIMEZONES_FAILURE = "FETCH_TIMEZONES_FAILURE";

export const UPDATE_RIVER_SETTINGS = "UPDATE_RIVER_SETTINGS";
export const UPDATE_RIVER_SETTINGS_SUCCESS = "UPDATE_RIVER_SETTINGS_SUCCESS";
export const UPDATE_RIVER_SETTINGS_FAILURE = "UPDATE_RIVER_SETTINGS_FAILURE";

export const UPDATE_BEAT_SETTINGS = "UPDATE_BEAT_SETTINGS";
export const UPDATE_BEAT_SETTINGS_SUCCESS = "UPDATE_BEAT_SETTINGS_SUCCESS";
export const UPDATE_BEAT_SETTINGS_FAILURE = "UPDATE_BEAT_SETTINGS_FAILURE";

  export function fetch(id) {
    return {
      type: FETCH_CURRENCIES,
      id,
    };
  }
  

  export function fetchTimeZones(id) {
    return {
      type: FETCH_TIMEZONES,
      id,
    };
  }
  
  export function updateRiver(data, id) {
    return {
      type: UPDATE_RIVER_SETTINGS,
      data,
      id,
    };
  }

  export function updateBeat(data, id) {
    return {
      type: UPDATE_BEAT_SETTINGS,
      data,
      id,
    };
  }