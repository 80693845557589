import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { PageHeader, Row, Col, Select, Card, Button } from "antd";

import translations from "../../constants/strings";
import Spinner from "../../components/spinner";
import ModalChooseBookingType from "../../components/modal/booking/type";
import ModalOrderFishingCards from "../../components/modal/booking/types/fishing_cards";
import ModalOrderAccommodation from "../../components/modal/booking/types/accommodation";
import ModalOrderFishingPackages from "../../components/modal/booking/types/fishing_packages";
import ModalOrderFreePackages from "../../components/modal/booking/types/free_packages";
import ModalOrderSeasonCards from "../../components/modal/booking/types/season_cards";
import groupBy from "../../services/group_by";

import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import { fetch as fetchAccommodations } from "../../redux/accommodation/actions";
import { fetchForBookingCalendar as fetchFishingCards } from "../../redux/fishing_cards/actions";
import { fetch as fetchCustomers, quiteRemove } from "../../redux/customers/actions";
import { fetch as fetchPackages } from "../../redux/fishing_packages/actions";
import { fetch as fetchFreePackages } from "../../redux/free_packages/actions";
import { fetch as fetchSeasonCards } from "../../redux/season_cards/actions";
import beatOptions from "../../services/select_beat_options";
import ANT_CONFIG from "../../constants/antconfig";
import Scribe from "../../components/Scribe";

class Booking extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.localizer = momentLocalizer(moment);
    this.state = {
      today: moment().format(this.dateFormat),
      showModal: "",
      data: {
        beat_ids: this.props.beats.selected ? [this.props.beats.selected] : [this.props.authentication.data.beat[0]],
        date_start: moment().startOf("month").format(`${this.dateFormat}`),
        date_end: moment().endOf("month").format(`${this.dateFormat}`),
      },
      dataForPeriods: {
        beat_ids: this.props.beats.selected ? [this.props.beats.selected] : [this.props.authentication.data.beat[0]],
        date_start: moment().subtract(1, "months").startOf("month").format(`${this.dateFormat}`),
        date_end: moment().add(1, "months").endOf("month").format(`${this.dateFormat}`),
      },
      modalData: {
        beat_ids: [this.props.authentication.data.beat[0]],
        date_start: null,
        date_end: null,
      },
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (!this.props.customers.data) {
      this.props.dispatch(fetchCustomers());
    }

    this.props.dispatch(fetchAccommodations(this.state.data));
    this.props.dispatch(fetchFishingCards(this.state.data));
    this.props.dispatch(fetchFreePackages(this.state.data));
    this.props.dispatch(fetchPackages({ beat_ids: this.state.data.beat_ids }));
    this.props.dispatch(
      fetchSeasonCards({
        beat_id: this.state.data.beat_ids[0],
        year: moment().format("YYYY"),
      })
    );
  }

  onNavigate(e) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          date_start: moment(e).startOf("month").format(this.dateFormat),
          date_end: moment(e).endOf("month").format(this.dateFormat),
        },
        dataForPeriods: {
          ...prevState.dataForPeriods,
          date_start: moment(e).subtract(1, "months").startOf("month").format(this.dateFormat),
          date_end: moment(e).add(1, "months").endOf("month").format(this.dateFormat),
        },
      }),
      () => {
        this.props.dispatch(fetchAccommodations(this.state.data));
        this.props.dispatch(fetchFishingCards(this.state.data));
        this.props.dispatch(fetchFreePackages(this.state.data));
        this.props.dispatch(
          fetchSeasonCards({
            beat_id: this.state.data.beat_ids[0],
            year: moment(e).format("YYYY"),
          })
        );
      }
    );
  }

  onNewBooking = () => {
    this.toggleModal("new_booking", this.state.today, this.state.today);
  };

  onSelectSlot({ start, end }) {
    const startDate = moment(start).format(this.dateFormat);
    const endDate = moment(end).format(this.dateFormat);
    this.toggleModal("new_booking", startDate, endDate);
  }

  handleBeatChange(e) {
    this.props.dispatch(selectBeat(e));
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          beat_ids: [e],
        },
      }),
      () => {
        this.props.dispatch(fetchAccommodations(this.state.data));
        this.props.dispatch(fetchFishingCards(this.state.data));
        this.props.dispatch(fetchFreePackages(this.state.dataForPeriods));
        this.props.dispatch(fetchPackages({ beat_ids: this.state.data.beat_ids }));
        this.props.dispatch(
          fetchSeasonCards({
            beat_id: this.state.data.beat_ids[0],
            year: moment().format("YYYY"),
          })
        );
      }
    );
  }

  toggleModal(modalName, dateStart, dateEnd) {
    if (dateStart && dateEnd) {
      this.setState((prevState) => ({
        modalData: {
          ...prevState.modalData,
          date_start: dateStart,
          date_end: dateEnd,
        },
      }));
    }
    this.setState({
      showModal: modalName,
    });
  }

  hideModal() {
    this.setState({
      showModal: null,
    });

    this.props.dispatch(fetchAccommodations(this.state.data));
    this.props.dispatch(fetchFishingCards(this.state.data));
    this.props.dispatch(fetchFreePackages(this.state.dataForPeriods));
    this.props.dispatch(
      fetchSeasonCards({
        beat_id: this.state.data.beat_ids[0],
        year: moment().format("YYYY"),
      })
    );
    this.props.dispatch(quiteRemove());
  }

  chooseOrderModal(e) {
    this.toggleModal(e);
  }

  render() {
    if (!this.props.beats.data || !this.props.authentication.data || !this.props.customers.data) {
      return <Spinner />;
    }

    let accommodations = [];
    let fishingCards = [];
    let fishingPackages = [];
    let freePackages = [];
    let seasonCards = [];
    const Event = (event) => <div className={`event-item color-${event.event.color}`}>{event.title}</div>;
    const selectBeats = beatOptions(this.props.beats, this.props.authentication);

    if (this.props.accommodation.data) {
      this.state.data.beat_ids.map((beatId) => {
        const beatAccommodation = this.props.accommodation.data[beatId];

        if (beatAccommodation) {
          beatAccommodation.map((acc) => {
            acc.accommodations.map((item) => {
              if (item.package_id === null) {
                const reserved = false;
                const color = "yellow";
                const items = [item];
                const titleText = this.props.intl.formatMessage({ id: "available" });
                const title = `${titleText}: ${acc.name}`;

                accommodations = [...accommodations, { color, title, items, start: item.date, end: item.date, reserved }];
              }

              return accommodations;
            });

            return true;
          });
        }

        return true;
      });
    }

    if (this.props.fishing_cards.data) {
      this.state.data.beat_ids.map((beatId) => {
        const beatCards = this.props.fishing_cards.data[beatId];

        if (beatCards) {
          Object.keys(beatCards).forEach((key) => {
            const results = groupBy(beatCards[key], (item) => {
              return [
                moment(item.publish_date_start).format("YYYY-MM-DD HH:mm"),
                moment(item.publish_date_end).format("YYYY-MM-DD HH:mm"),
                moment(item.valid_from).format("YYYY-MM-DD HH:mm"),
                moment(item.valid_until).format("YYYY-MM-DD HH:mm"),
                item.fishing_card_type_id,
                item.package_id !== null,
              ];
            });

            Object.values(results).map((items) => {
              if (items.length > 0) {
                items.forEach((item) => {
                  const reserved = false;
                  const color = "blue";
                  const titleText = this.props.intl.formatMessage({ id: "available" });
                  const cardTypeName = item.fishing_card_type?.name ? ` (${item.fishing_card_type?.name})` : null;
                  const title = `${item.total} ${titleText}${cardTypeName}`;

                  fishingCards = [...fishingCards, { color, title, items, start: key, end: key, reserved }];
                });
              }

              return fishingCards;
            });
          });
        }

        return true;
      });
    }

    if (this.props.fishing_packages.packages.data) {
      this.state.data.beat_ids.map((beatId) => {
        const beatPackages = this.props.fishing_packages.packages.data[beatId];

        if (beatPackages) {
          const results = groupBy(beatPackages, (item) => {
            return [item.publish_date_start, item.publish_date_end, item.fishing_card_types, item.type, item.date_start, item.date_end, item.price_options];
          });

          results.map((items) => {
            const color = "green";
            const title = translations.package_types[this.props.intl.locale][items[0].type];
            fishingPackages = [...fishingPackages, { color, title, items, start: items[0].date_start, end: items[0].date_end }];

            return fishingPackages;
          });
        }

        return true;
      });
    }

    if (this.props.free_packages.data) {
      this.state.data.beat_ids.map((beatId) => {
        const beatFreePackages = this.props.free_packages.data[beatId];

        if (beatFreePackages) {
          const results = groupBy(beatFreePackages, (item) => {
            return [
              item.publish_date_start,
              item.publish_date_end,
              item.name,
              item.description,
              item.date_start,
              item.date_end,
              item.price_options,
              item.package_id !== null,
            ];
          });

          results.map((items) => {
            if (items[0].package_id === null) {
              const color = "success-green";
              const title = `${items.length} ${this.props.intl.formatMessage({ id: "available" })}: ${items[0].name}`;

              freePackages = [...freePackages, { color, title, items, start: items[0].date_start, end: items[0].date_end }];
            }

            return freePackages;
          });
        }

        return true;
      });
    }

    if (this.props.season_cards.data) {
      const results = groupBy(this.props.season_cards.data, (item) => {
        return [item.year, item.season_fishing_card_type_id, item.beat_id, item.package_id !== null];
      });
      results.map((items) => {
        const item = items[0];

        if (item.package_id === null) {
          const color = "red";
          let type = "";
          if (item.season_fishing_card_type?.name) {
            type = `- ${item.season_fishing_card_type?.name} - `;
          }
          const title = `${items.length} ${type}${this.props.intl.formatMessage({ id: "available" })}`;

          seasonCards = [...seasonCards, { color, title, items, start: item.season_start_date, end: item.season_end_date }];
        }

        return seasonCards;
      });
    }

    return (
      <div>
        <PageHeader
          title={this.props.intl.formatMessage({ id: "booking" })}
          extra={[
            <Button key="1" type="primary" onClick={this.onNewBooking} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "new_booking" })}
            </Button>,
          ]}
        >
          {selectBeats.length > 1 && (
            <Row style={{ marginTop: 15 }}>
              <Col xs={24} sm={10} md={10} style={{ marginTop: 10, marginBottom: 10, maxWidth: 500 }}>
                <label htmlFor="select_beat" className="ant-label">
                  {this.props.intl.formatMessage({ id: "select_beat" })}
                </label>
                <Select
                  id="select_beat"
                  showSearch
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                  optionFilterProp="children"
                  onChange={(e) => this.handleBeatChange(e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={this.state.data.beat_ids}
                >
                  {selectBeats.map((i, key) => (
                    <Select.Option key={key} value={i.value}>
                      {i.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
        </PageHeader>

        <Card className="booking-calendar-card">
          {this.state.showModal === "new_booking" ? <ModalChooseBookingType intl={this.props.intl} toggleModal={(e) => this.chooseOrderModal(e)} /> : null}

          {this.state.showModal === "fishing_cards" ? (
            <ModalOrderFishingCards
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              modalData={this.state.modalData}
              calendarData={this.state.data}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "accommodation" ? (
            <ModalOrderAccommodation
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              modalData={this.state.modalData}
              calendarData={this.state.data}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "fishing_packages" ? (
            <ModalOrderFishingPackages
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              modalData={this.state.modalData}
              calendarData={this.state.data}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "free_packages" ? (
            <ModalOrderFreePackages
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              modalData={this.state.modalData}
              calendarData={this.state.data}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "season_cards" ? (
            <ModalOrderSeasonCards
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              modalData={this.state.modalData}
              calendarData={this.state.data}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {(this.props.fishing_cards.loading || this.props.accommodation.loading || this.props.free_packages.loading) && !this.state.showModal && (
            <div className="booking-panel-preloader">
              <Spinner />
            </div>
          )}

          <Row>
            <Col xs={24} className="rc-calendar-container">
              <Calendar
                popup
                selectable
                localizer={this.localizer}
                culture={this.props.intl.locale}
                events={[...accommodations, ...fishingCards, ...fishingPackages, ...freePackages, ...seasonCards]}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={(e) => this.onSelectSlot(e)}
                views={["month"]}
                messages={{
                  ...translations.calendar[this.props.intl.locale],
                  showMore: (total) => `+${total} ${this.props.intl.formatMessage({ id: "more" })}`,
                }}
                onNavigate={(e) => this.onNavigate(e)}
                components={{
                  event: Event,
                }}
              />
            </Col>
          </Row>
        </Card>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Manuell_booking__5DWXZJKgQrqbl1Tub2pzXA"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  beats: state.Beats,
  customers: state.Customers,
  authentication: state.Auth,
  accommodation: state.Accommodation,
  fishing_cards: state.FishingCards,
  fishing_packages: state.FishingPackages,
  free_packages: state.FreePackages,
  season_cards: state.SeasonCards,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Booking)));
