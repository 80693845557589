const riverOptionsFromControllerRivers = (props, auth, isAdmin) => {
  if (!props.data) {
    return false;
  }

  let authRivers = auth.data.controller_rivers;

  if(isAdmin) {
    authRivers = auth.data.river
  }

  return props.data
    .filter(river => authRivers.includes(river.id))
    .map(river => {
      return {
        value: river.id,
        label: river.name
      };
    });
};

export default riverOptionsFromControllerRivers;
