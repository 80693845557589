import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Table, Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { fetch as fetchTaxes } from "../../redux/taxes/actions";
import Spinner from "../../components/spinner";
import ModalAddTax from "../../components/modal/tax/add";
import ModalRemoveTax from "../../components/modal/tax/remove";

class Taxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: "",
      modalData: {
        items: []
      }
    };
  }

  componentDidMount() {
    if (!this.props.taxes.data) {
      this.props.dispatch(fetchTaxes());
    }
  }

  toggleModal(modalName, items) {
    if (items) {
      this.setState({
        modalData: {
          items: [items]
        }
      });
    }

    this.setState({
      showModal: modalName
    });
  }

  hideModal() {
    this.setState({
      showModal: "",
      modalData: {
        items: []
      }
    });
  }

  render() {
    if (!this.props.taxes.data) {
      return (
        <div className="panel">
          <h2 className="title">{this.props.intl.formatMessage({ id: "taxes" })}</h2>
          <Spinner />
        </div>
      );
    }

    return (
      <div className="panel">
        {this.state.showModal === "add_tax" ? <ModalAddTax intl={this.props.intl} toggleModal={() => this.hideModal()} /> : null}

        {this.state.showModal === "update_tax" ? (
          <ModalAddTax intl={this.props.intl} modalData={this.state.modalData} toggleModal={() => this.hideModal()} />
        ) : null}

        {this.state.showModal === "remove_tax" ? (
          <ModalRemoveTax intl={this.props.intl} modalData={this.state.modalData} toggleModal={() => this.hideModal()} />
        ) : null}

        <h2 className="title">{this.props.intl.formatMessage({ id: "taxes" })}</h2>
        <Row>
          <Col xs={12}>
            <Button color="secondary" style={{ marginBottom: 15 }} onClick={() => this.toggleModal("add_tax")}>
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  fontSize: 15,
                  marginRight: 10
                }}
              />
              {this.props.intl.formatMessage({ id: "new_tax" })}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{this.props.intl.formatMessage({ id: "name" })}</th>
                  <th>{this.props.intl.formatMessage({ id: "value" })}</th>
                  <th>{this.props.intl.formatMessage({ id: "code" })}</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {this.props.taxes.data.length > 0 ? (
                  this.props.taxes.data.map((item, key) => (
                    <tr key={item.id}>
                      <th scope="row">{key + 1}</th>
                      <td>{item.key}</td>
                      <td>{item.value}%</td>
                      <td>{item.code}</td>
                      <td>
                        <Button color="success" onClick={() => this.toggleModal("update_tax", item)} style={{ marginRight: 10 }}>
                          {this.props.intl.formatMessage({ id: "edit" })}
                        </Button>
                        <Button color="danger" onClick={() => this.toggleModal("remove_tax", item)}>
                          {this.props.intl.formatMessage({ id: "remove" })}
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={9}>{this.props.intl.formatMessage({ id: "no_taxes_found" })}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taxes: state.Taxes,
  authentication: state.Auth
});

export default withRouter(injectIntl(connect(mapStateToProps)(Taxes)));
