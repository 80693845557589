import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, Table } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import { add as addFishingPackage, edit as editFishingPackage, remove as removeFishingPackage } from "../../../redux/fishing_packages/actions";
import { fetchTypes as fetchCardTypes } from "../../../redux/fishing_cards/actions";
import Spinner from "../../spinner";
import SelectInput from "../../select";
import APIMessage from "../../../services/api_messages";
import translations from "../../../constants/strings";
import OptionList from "../../optionlist";
import SaveLabel from "../../helpers/buttons/save_label";
import { DEFAULT_LANGUAGE } from "../../../constants";
import { Checkbox } from "antd";
import ANT_CONFIG from "../../../constants/antconfig";
import { UTCDateToLocalTime, UTCConverter } from '../../../services/utc_converter';

const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;
const labelWidth = 4;
const inputWidth = 8;

class ModalAddFishingPackage extends React.Component {
  constructor(props) {
    super(props);

    const isNew = props.modalData.items.length === 0;
    let publishDateStart = "";
    let publishDateEnd = "";

    if (!isNew) {
      const startDateToLocalDate = UTCDateToLocalTime(props.modalData.items[0].publish_date_start)
      const formatedStartDate = startDateToLocalDate.format('YYYY-MM-DD HH:mm:ss')

      const endDateToLocalDate = UTCDateToLocalTime(props.modalData.items[0].publish_date_end)
      const formatedEndDate = endDateToLocalDate.format('YYYY-MM-DD HH:mm:ss')

      publishDateStart =
        props.modalData.items[0].publish_date_start && props.modalData.items[0].publish_date_start !== "1970-01-01 00:00:00"
          ? formatedStartDate.replace(" ", "T") || ""
          : "";
      publishDateEnd =
        props.modalData.items[0].publish_date_end && props.modalData.items[0].publish_date_end !== "3999-12-31 00:00:00"
          ? formatedEndDate.replace(" ", "T") || ""
          : "";
    }

    this.state = {
      modal: true,
      submitted: false,
      isNew,
      confirmDelete: false,
      edit_price: {},
      prices: {
        name: "",
        description: "",
        amount: "",
        tax_option_id: null,
      },
      data: {
        id: !isNew ? props.modalData.items[0].id : null,
        type: !isNew ? props.modalData.items[0].type : null,
        fishing_card_types: !isNew ? props.modalData.items[0].fishing_card_types.map((i) => i.id) : [],
        beat_id: !isNew ? props.modalData.items[0].beat_id : props.selectedBeat,
        description: !isNew ? props.modalData.items[0].description : '',
        date_start: props.modalData.startDate,
        date_end: props.modalData.endDate,
        publish_date_start: publishDateStart,
        publish_date_end: publishDateEnd,
        updated_date_start: null,
        updated_date_end: null,
        members_only: !isNew ? props.modalData.items[0].members_only : false,
        prices: !isNew ? [...props.modalData.items[0].price_options] : [],
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchCardTypes(this.state.data.beat_id));
  }

  componentDidUpdate() {
    if (!this.props.fishing_packages.packages.loading && !this.props.fishing_packages.packages.error && this.state.submitted) {
      this.toggle();
    }
  }

  onChangeCardType(id) {
    const ids = _.uniq([...this.state.data.fishing_card_types, id]);
    this.setState((prevState) => ({ fishing_card_types: id, data: { ...prevState.data, fishing_card_types: ids } }));
  }

  onRemoveCardType(id) {
    const ids = this.state.data.fishing_card_types.filter((i) => i !== id);
    this.setState((prevState) => ({ fishing_card_types: null, data: { ...prevState.data, fishing_card_types: ids } }));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onChangePrices(newState) {
    this.setState((prevState) => ({ prices: { ...prevState.prices, ...newState } }));
  }

  onDelete() {
    this.setState({
      submitted: true,
    });

    this.props.dispatch(removeFishingPackage({ package_ids: [this.state.data.id] }, this.props.calendarData));
  }



  onSubmit() {
    this.setState({
      submitted: true,
    });

    if (this.state.isNew) {
      const data = {...this.state.data, 
        updated_date_start: UTCConverter(this.state.data.updated_date_start), 
        publish_date_start: UTCConverter(this.state.data.publish_date_start), 
        updated_date_end: UTCConverter(this.state.data.updated_date_end),
        publish_date_end: UTCConverter(this.state.data.publish_date_end),
    }
      this.props.dispatch(addFishingPackage(data, { beat_ids: this.props.authentication.data.beat }));
    } else {
      const { items } = this.props.modalData;

      const data = items.map((item) => {
        return {
          id: item.id,
          members_only: this.state.data.members_only,
          fishing_card_types: this.state.data.fishing_card_types,
          prices: this.state.data.prices,
          date_start: this.state.data.date_start,
          date_end: this.state.data.date_end,
          description: this.state.data.description,
          publish_date_start: this.state.data.updated_date_start !== null ? UTCConverter(this.state.data.updated_date_start) : item.publish_date_start,
          publish_date_end: this.state.data.updated_date_end !== null ? UTCConverter(this.state.data.updated_date_end) : item.publish_date_end,
        };
      });
      this.props.dispatch(editFishingPackage(data[0], this.props.calendarData));
    }
  }

  onChangeEditPrice(newState) {
    this.setState((prevState) => ({ edit_price: { ...prevState.edit_price, ...newState } }));
  }

  confirmDelete(confirmDelete) {
    this.setState({
      confirmDelete,
    });
  }

  toggle() {
    this.props.toggleModal();
  }

  handleRemovePrice(item) {
    const removed = this.state.data.prices.filter(
      (i) => i.description !== item.description || i.amount !== item.amount || i.tax_option_id !== item.tax_option_id
    );

    this.setState((prevState) => ({ data: { ...prevState.data, prices: [...removed] } }));
  }

  handleAddPrice() {
    if (!this.state.prices.description || !this.state.prices.amount || !this.state.prices.tax_option_id) {
      return APIMessage.error(translations.error[lang].fill_all_fields);
    }

    const checkIfExists = this.state.data.prices.filter((i) => i.description === this.state.prices.description);

    if (checkIfExists.length > 0) {
      return APIMessage.error(translations.error[lang].price_already_exists);
    }

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          prices: [...prevState.data.prices, prevState.prices],
        },
      }),
      () => {
        this.setState({
          prices: {
            description: "",
            amount: "",
            tax_option_id: null,
          },
        });
      }
    );

    return true;
  }

  handleEditPrice(item, key) {
    this.setState({
      edit_price: {
        ...item,
        key,
      },
    });
  }

  handleSaveEditPrice(key, item) {
    if (!this.state.edit_price.description || !this.state.edit_price.amount || !this.state.edit_price.tax_option_id) {
      return APIMessage.error(translations.error[lang].fill_all_fields);
    }

    const checkIfExists = this.state.data.prices
      .filter((i) => i.description !== item.description)
      .filter((i) => i.description === this.state.edit_price.description);

    if (checkIfExists.length > 0) {
      return APIMessage.error(translations.error[lang].price_already_exists);
    }

    const { prices } = this.state.data;
    prices[key] = this.state.edit_price;

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          prices: [...prices],
        },
      }),
      () => {
        this.setState({
          edit_price: {},
        });
      }
    );

    return true;
  }

  setMembersOnly = () => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        members_only: !prevState.data.members_only,
      },
    }));
  };

  render() {
    const selectTaxes = this.props.taxes.data.map((i) => {
      return {
        value: i.id,
        label: `${i.key} (${i.value}%)`,
      };
    });
    const selectPackageType = this.props.fishing_packages.types.data.map((item) => {
      return {
        value: item.type,
        label: translations.package_types[this.props.intl.locale][item.type],
      };
    });
    let cardTypes = [];

    if (this.props.fishing_cards.type.data) {
      cardTypes = this.props.fishing_cards.type.data.map((i) => {
        return {
          value: i.id,
          label: i.name,
        };
      });
    }

    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "add_edit_fishing_package" })}</ModalHeader>
        {this.props.fishing_packages.packages.loading ? (
          <Spinner />
        ) : (
          <Form>
            {this.state.confirmDelete ? (
              <div>
                <ModalBody>
                  <h2>{this.props.intl.formatMessage({ id: "confirm_delete_question" })}</h2>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={() => this.onDelete()}>
                    {this.props.intl.formatMessage({ id: "remove" })}
                  </Button>
                  <Button color="secondary" onClick={() => this.confirmDelete(false)}>
                    {this.props.intl.formatMessage({ id: "back" })}
                  </Button>
                </ModalFooter>
              </div>
            ) : (
              <div>
                <ModalBody>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row>
                        <Label for="select_package_type" xs={12} sm={labelWidth}>
                          {`${this.props.intl.formatMessage({ id: "select_package_type" })}`}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <SelectInput
                            id="select_package_type"
                            name="select_package_type"
                            intl={this.props.intl}
                            value={selectPackageType.filter((i) => i.value === this.state.data.type)}
                            onChange={(e) => this.onChangeValue({ type: e.value })}
                            options={selectPackageType}
                            disabled={!this.state.isNew}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="description" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "description" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                              type="textarea"
                              name="description"
                              id="description"
                              required
                              value={this.state.data.description}
                              onChange={(e) => this.onChangeValue({ description: e.target.value })}
                          />
                        </Col>
                      </FormGroup>

                      {this.props.fishing_cards.type.loading ? (
                        <FormGroup row>
                          <Label for="select_card_type" xs={12} sm={labelWidth}>
                            {`${this.props.intl.formatMessage({ id: "fishing_card_type" })}`}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Spinner width={30} style={{ height: 53, marginTop: -15 }} />
                          </Col>
                        </FormGroup>
                      ) : (
                        <FormGroup row>
                          <Label for="select_card_type" xs={12} sm={labelWidth}>
                            {`${this.props.intl.formatMessage({ id: "fishing_card_type" })}`}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <OptionList
                              intl={this.props.intl}
                              options={cardTypes}
                              values={this.state.data.fishing_card_types}
                              onNewOption={(e) => this.onChangeCardType(e.value)}
                              onRemoveOption={(e) => this.onRemoveCardType(e)}
                            />
                          </Col>
                        </FormGroup>
                      )}

                      <FormGroup row>
                        <Label for="date_start" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "date_start" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="date"
                            name="date_start"
                            id="date_start"
                            value={this.state.data.date_start}
                            onChange={(e) => this.onChangeValue({ date_start: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="date_end" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "date_end" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="date"
                            name="date_end"
                            id="date_end"
                            value={this.state.data.date_end}
                            onChange={(e) => this.onChangeValue({ date_end: e.target.value })}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={inputWidth}>
                          <Checkbox size={ANT_CONFIG.size} checked={this.state.data.members_only || false} onChange={this.setMembersOnly}>
                            {this.props.intl.formatMessage({ id: "members_only" })}
                          </Checkbox>
                        </Col>
                      </FormGroup>

                      <hr />

                      <FormGroup row>
                        <Label for="price" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "add_price" })}
                        </Label>
                        <Col xs={12} sm={4} style={{ marginTop: 10 }}>
                          <Input
                            style={{ marginBottom: 5 }}
                            type="number"
                            name="price"
                            id="price"
                            placeholder={this.props.intl.formatMessage({ id: "price" })}
                            required
                            value={this.state.prices.amount}
                            onChange={(e) => this.onChangePrices({ amount: e.target.value })}
                          />
                        </Col>
                        <Col xs={12} sm={4} style={{ marginTop: 10 }}>
                          <SelectInput
                            id="select_tax_value"
                            name="select_tax_value"
                            intl={this.props.intl}
                            placeholder={this.props.intl.formatMessage({ id: "select_tax" })}
                            value={selectTaxes.filter((i) => i.value === this.state.prices.tax_option_id)}
                            onChange={(e) => this.onChangePrices({ tax_option_id: e.value })}
                            options={selectTaxes}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={8} className="price-description-input-btn">
                          <Input
                            type="text"
                            name="price_description"
                            id="price_description"
                            required
                            placeholder={this.props.intl.formatMessage({ id: "description" })}
                            value={this.state.prices.description}
                            onChange={(e) => this.onChangePrices({ description: e.target.value, name: e.target.value })}
                          />
                          <Button color="outline-secondary" onClick={() => this.handleAddPrice()}>
                            {this.props.intl.formatMessage({ id: "add_price" })}
                          </Button>
                        </Col>
                      </FormGroup>

                      {this.state.data.prices.length > 0 ? (
                        <div>
                          <hr style={{ marginBottom: 0 }} />

                          <FormGroup row>
                            <Col xs={12}>
                              <Table bordered responsive style={{ marginBottom: 50 }}>
                                <thead>
                                  <tr>
                                    <th>{this.props.intl.formatMessage({ id: "description" })}</th>
                                    <th>{this.props.intl.formatMessage({ id: "price" })}</th>
                                    <th>{this.props.intl.formatMessage({ id: "tax" })}</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.data.prices.map((item, key) => (
                                    <React.Fragment key={key}>
                                      {"key" in this.state.edit_price && this.state.edit_price.key === key ? (
                                        <tr>
                                          <td>
                                            <Input
                                              style={{ width: 65 }}
                                              type="text"
                                              name="price_description"
                                              id="price_description"
                                              value={this.state.edit_price.description}
                                              onChange={(e) => this.onChangeEditPrice({ description: e.target.value })}
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              style={{ width: 65 }}
                                              type="text"
                                              name="price_amount"
                                              id="price_amount"
                                              value={this.state.edit_price.amount}
                                              onChange={(e) => this.onChangeEditPrice({ amount: e.target.value })}
                                            />
                                          </td>
                                          <td style={{ minWidth: "180px" }}>
                                            <SelectInput
                                              id="select_tax_value"
                                              name="select_tax_value"
                                              intl={this.props.intl}
                                              placeholder={this.props.intl.formatMessage({ id: "select_tax" })}
                                              value={selectTaxes.filter((i) => i.value === this.state.edit_price.tax_option_id)}
                                              onChange={(e) => this.onChangeEditPrice({ tax_option_id: e.value })}
                                              options={selectTaxes}
                                            />
                                          </td>
                                          <td className="text-right" style={{ minWidth: "180px" }}>
                                            <Button color="secondary" onClick={() => this.handleSaveEditPrice(key, item)}>
                                              <SaveLabel intl={this.props.intl} />
                                            </Button>
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td>{item.description}</td>
                                          <td>{item.amount}</td>
                                          <td>{`${this.props.taxes.data.filter((i) => i.id === item.tax_option_id)[0].value}%`}</td>
                                          <td className="text-right" style={{ minWidth: "180px" }}>
                                            <Button color="outline-secondary" onClick={() => this.handleEditPrice(item, key)} style={{ marginRight: 5 }}>
                                              {this.props.intl.formatMessage({ id: "edit" })}
                                            </Button>
                                            <Button color="danger" onClick={() => this.handleRemovePrice(item)}>
                                              {this.props.intl.formatMessage({ id: "remove" })}
                                            </Button>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </Table>
                            </Col>
                          </FormGroup>
                        </div>
                      ) : null}

                      <hr />

                      <FormGroup row style={{ marginTop: 35 }}>
                        <Label for="publish_date_start" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "publish_date_start" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="datetime-local"
                            name="publish_date_start"
                            id="publish_date_start"
                            value={this.state.data.publish_date_start}
                            onChange={(e) => this.onChangeValue({ publish_date_start: e.target.value, updated_date_start: e.target.value })}
                            onSelect={() =>
                              !this.state.data.publish_date_start
                                ? this.onChangeValue({
                                    publish_date_start: moment().format("YYYY-MM-DDT00:00"),
                                    updated_date_start: moment().format("YYYY-MM-DDT00:00"),
                                  })
                                : null
                            }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="publish_date_end" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "publish_date_end" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="datetime-local"
                            name="publish_date_end"
                            id="publish_date_end"
                            value={this.state.data.publish_date_end}
                            onChange={(e) => this.onChangeValue({ publish_date_end: e.target.value, updated_date_end: e.target.value })}
                            onSelect={() =>
                              !this.state.data.publish_date_end
                                ? this.onChangeValue({
                                    publish_date_end: moment().format("YYYY-MM-DDT00:00"),
                                    updated_date_end: moment().format("YYYY-MM-DDT00:00"),
                                  })
                                : null
                            }
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  {!this.state.isNew ? (
                    <Button color="danger" onClick={() => this.confirmDelete(true)}>
                      {this.props.intl.formatMessage({ id: "remove" })}
                    </Button>
                  ) : null}
                  <Button color="secondary" onClick={() => this.onSubmit()} type="submit">
                    <SaveLabel intl={this.props.intl} />
                  </Button>
                </ModalFooter>
              </div>
            )}
          </Form>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  fishing_packages: state.FishingPackages,
  fishing_cards: state.FishingCards,
  taxes: state.Taxes,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddFishingPackage)));
