import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/river-card-blueprints/${action.river_id}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_RIVER_CARD_BLUEPRINTS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_RIVER_CARD_BLUEPRINTS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_RIVER_CARD_BLUEPRINTS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* store(action) {
  try {
    const response = yield call(API.call, `admin/river-card-blueprint`, "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.STORE_RIVER_CARD_BLUEPRINT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.STORE_RIVER_CARD_BLUEPRINT_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, { type: "FETCH_RIVER_CARD_BLUEPRINTS", river_id: action.data.river_id});
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.STORE_RIVER_CARD_BLUEPRINT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* update(action) {
  try {
    const response = yield call(API.call, `admin/river-card-blueprint`, "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UPDATE_RIVER_CARD_BLUEPRINT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UPDATE_RIVER_CARD_BLUEPRINT_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, { type: "FETCH_RIVER_CARD_BLUEPRINTS", river_id: action.data.river_id});
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UPDATE_RIVER_CARD_BLUEPRINT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, `admin/river-card-blueprint/${action.data.id}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_RIVER_CARD_BLUEPRINT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.REMOVE_RIVER_CARD_BLUEPRINT_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, { type: "FETCH_RIVER_CARD_BLUEPRINTS", river_id: action.data.river_id});
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_RIVER_CARD_BLUEPRINT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_RIVER_CARD_BLUEPRINTS, fetch),
    takeEvery(ACTION.UPDATE_RIVER_CARD_BLUEPRINT, update),
    takeEvery(ACTION.REMOVE_RIVER_CARD_BLUEPRINT, remove),
    takeEvery(ACTION.STORE_RIVER_CARD_BLUEPRINT, store),
  ]);
}
