import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Result } from "antd";
import { injectIntl } from "react-intl";

const PaymentSuccess = props => (
  <div className="default-font text-center" style={{ marginTop: 50 }}>
    <Result
      status="success"
      title={props.intl.formatMessage({ id: "payment_successful" })}
      extra={
        <NavLink to="/">
          <Button size="large" type="primary">
            {props.intl.formatMessage({ id: "back_to_dashboard" })}
          </Button>
        </NavLink>
      }
    />
  </div>
);

export default withRouter(injectIntl(PaymentSuccess));
