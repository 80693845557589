export const FETCH_DASHBOARD_RIVER_ADMIN_CATCH_STATISTICS = "FETCH_DASHBOARD_RIVER_ADMIN_CATCH_STATISTICS";
export const FETCH_DASHBOARD_RIVER_ADMIN_CATCH_STATISTICS_SUCCESS = "FETCH_DASHBOARD_RIVER_ADMIN_CATCH_STATISTICS_SUCCESS";
export const FETCH_DASHBOARD_RIVER_ADMIN_CATCH_STATISTICS_FAILURE = "FETCH_DASHBOARD_RIVER_ADMIN_CATCH_STATISTICS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_RIVER_ADMIN_CATCH_STATISTICS,
    payload
  };
}
