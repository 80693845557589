import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Table, Form, Label } from "reactstrap";
import { DatePicker, Card, Col, Row, Divider, Statistic, PageHeader, Select } from "antd";

import Spinner from "../../components/spinner";
import {
  downloadPlatformReport,
  fetchPlatformReports,
} from "../../redux/reports/actions";
import downloadFile from "../../services/download_file";
import ANT_CONFIG from "../../constants/antconfig";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import { currencyOptions }  from "../../services/select_settings_options"
import { fetch as fetchCurrencies } from "../../redux/settings/actions";

const dividerMarginTop = 35;

class PlatformReport extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      filetype: null,
      isAdmin: false,
      isAccountant: false,
      data: {
        date_start: moment()
          .startOf("month")
          .format(`${this.dateFormat}`),
        date_end: moment()
          .subtract(1, "day")
          .format(`${this.dateFormat}`),
        currency_id: 1,
      }
    };
  }

  componentDidMount() {
    const { roles } = this.props.authentication.data;

    if (!this.state.isAdmin) {
      roles.map(role => (role === "admin" ? this.setState({ isAdmin: true }) : ""));
    }

    if (!this.state.isAccountant) {
      roles.map(role => (role === "accountant" ? this.setState({ isAccountant: true }) : ""));
    }

    if (!this.props.settings.currencies) {
      this.props.dispatch(fetchCurrencies());
    }   

    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reports.file !== this.props.reports.file && this.props.reports.file) {
      downloadFile(this.props.reports.file, this.state.filetype);
    }
  }

  handleDownload = (reportType = null, filetype = "pdf") => {
    const { data } = this.props.reports;

    this.setState(
      {
        filetype
      },
      () => {
        data.filters = {
          ...data.filters,
          filetype
        };

        this.props.dispatch(downloadPlatformReport(this.props.reports.data, reportType));
      }
    );
  };

  handleDataChange() {
    this.props.dispatch(fetchPlatformReports(this.state.data));
  }

  handleChangeDate(e, key) {
    this.setState(
      prevState => ({
        data: { ...prevState.data, [key]: moment(e).format(`${this.dateFormat}`) }
      }),
      () => {
        this.handleDataChange();
      }
    );
  }

  handleCurrencyChange(e) {
    this.setState(prevState => ({
      data: {...prevState.data, currency_id: e}
    }),
      () => {
        this.handleDataChange();
      }
    );
  }

  render() {
    if (!this.props.reports.data || this.props.reports.loading) {
      return <Spinner />;
    }

    if (!this.props.settings.currencies || this.props.settings.currencies.loading) {
      return <Spinner />;
    }


    const { data } = this.props.reports;
    const { totals } = data;
    const isFishingCards = data.products && data.products.fishing_cards && data.products.fishing_cards.length > 0;
    const isAccommodations = data.products && data.products.accommodations && data.products.accommodations.length > 0;
    const isFreePackages = data.products && data.products.free_packages && data.products.free_packages.length > 0;
    const isSeasonCards = data.products && data.products.season_cards && data.products.season_cards.length > 0;
    const isRiverCards = data.products && data.products.river_cards && data.products.river_cards.length > 0;
    const isProducts = isFishingCards || isAccommodations || isFreePackages || isSeasonCards || isRiverCards;
    const isFishingCardsTerminal = data.products_terminal_payment && data.products_terminal_payment.fishing_cards && data.products_terminal_payment.fishing_cards.length > 0;
    const isAccommodationsTerminal = data.products_terminal_payment && data.products_terminal_payment.accommodations && data.products_terminal_payment.accommodations.length > 0;
    const isFreePackagesTerminal = data.products_terminal_payment && data.products_terminal_payment.free_packages && data.products_terminal_payment.free_packages.length > 0;
    const isSeasonCardsTerminal = data.products_terminal_payment && data.products_terminal_payment.season_cards && data.products_terminal_payment.season_cards.length > 0;
    const isRiverCardsTerminal = data.products_terminal_payment && data.products_terminal_payment.river_cards && data.products_terminal_payment.river_cards.length > 0;
    const isProductsTerminal = isFishingCardsTerminal || isAccommodationsTerminal || isFreePackagesTerminal || isSeasonCardsTerminal || isRiverCardsTerminal;
    const isManualFishingCards =
      data.products_manual_payment && data.products_manual_payment.fishing_cards && data.products_manual_payment.fishing_cards.length > 0;
    const isManualAccommodations =
      data.products_manual_payment && data.products_manual_payment.accommodations && data.products_manual_payment.accommodations.length > 0;
    const isManualFreePackages =
      data.products_manual_payment && data.products_manual_payment.free_packages && data.products_manual_payment.free_packages.length > 0;
    const isManualSeasonCards =
      data.products_manual_payment && data.products_manual_payment.season_cards && data.products_manual_payment.season_cards.length > 0;
    const isManualTransactionFees =
      data.products_manual_payment && data.products_manual_payment.transaction_fees && data.products_manual_payment.transaction_fees.length > 0;
    const isManualRiverCards = data.products_manual_payment && data.products_manual_payment.river_cards && data.products_manual_payment.river_cards.length > 0;
    const isManualProducts = isManualFishingCards || isManualAccommodations || isManualFreePackages || isManualSeasonCards || isManualTransactionFees || isManualRiverCards;

    const selectCurrencies = currencyOptions(this.props.settings)

    return (
      <div>
        <PageHeader
          title={this.props.intl.formatMessage({ id: "platform_reports" })}
          extra={[<FileDownloadDropdown key="1" handleClick={this.handleDownload} loading={this.props.reports.file_loading} />]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Form>
                <Row gutter={16}>
                  {this.props.settings.currencies.length ? (
                    <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                      <Label className="ant-label">{this.props.intl.formatMessage({ id: "currency" })}</Label>
                      <Select
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        onChange={e => this.handleCurrencyChange(e)}
                        value={this.state.data.currency_id}
                        placeholder={this.props.intl.formatMessage({ id: "currency" })}
                      >
                        {selectCurrencies?.map((i, key) => (
                          <Select.Option key={key} value={i.value}>
                            {i.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>) : null}
                  <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                    <Label className="ant-label">{this.props.intl.formatMessage({ id: "date_start" })}</Label>
                    <DatePicker
                      id="date_start"
                      size={ANT_CONFIG.size}
                      allowClear={false}
                      disabled={this.props.reports.file_loading}
                      format={ANT_CONFIG.datepicker_format}
                      value={moment(this.state.data.date_start)}
                      onChange={e => this.handleChangeDate(e, "date_start")}
                      showToday={false}
                    />
                  </Col>

                  <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                    <Label className="ant-label">{this.props.intl.formatMessage({ id: "date_end" })}</Label>
                    <DatePicker
                      size={ANT_CONFIG.size}
                      allowClear={false}
                      disabled={this.props.reports.file_loading}
                      format={ANT_CONFIG.datepicker_format}
                      value={moment(this.state.data.date_end)}
                      onChange={e => this.handleChangeDate(e, "date_end")}
                      disabledDate={d => !d || d.isAfter(moment().subtract(1, "day"))}
                      showToday={false}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </PageHeader>

        {totals ? (
          <React.Fragment>
            <Divider style={{ marginTop: dividerMarginTop }}>{this.props.intl.formatMessage({ id: "summary" })}</Divider>

            <Row gutter={18} type="flex">
              {Object.keys(totals).map((i, key) => (
                <Col key={key} xs={24} sm={8} lg={6} style={{ marginBottom: 18 }}>
                  <Card style={{ height: "100%" }}>
                    <Statistic title={this.props.intl.formatMessage({ id: i })} value={totals[i] || " - "} />
                  </Card>
                </Col>
              ))}
              {data.transaction_fees ? (
                <Col xs={24} sm={8} lg={6} style={{ marginBottom: 18 }}>
                  <Card style={{ height: "100%" }}>
                    <Statistic title={this.props.intl.formatMessage({ id: "transaction_fees" })} value={data.transaction_fees.amount} />
                  </Card>
                </Col>
              ) : null}
              {data.transaction_fees_bank_terminal_payment ? (
                <Col xs={24} sm={8} lg={6} style={{ marginBottom: 18 }}>
                  <Card style={{ height: "100%" }}>
                    <Statistic
                      title={this.props.intl.formatMessage({ id: "transaction_fees_bank_terminal_payment" })}
                      value={data.transaction_fees_bank_terminal_payment.amount}
                    />
                  </Card>
                </Col>
              ) : null}
              {data.transaction_fees_manual_payment ? (
                <Col xs={24} sm={8} lg={6} style={{ marginBottom: 18 }}>
                  <Card style={{ height: "100%" }}>
                    <Statistic
                      title={this.props.intl.formatMessage({ id: "transaction_fees_manual_payment" })}
                      value={data.transaction_fees_manual_payment.amount}
                    />
                  </Card>
                </Col>
              ) : null}
            </Row>
          </React.Fragment>
        ) : null}

        {isProducts ? (
          <React.Fragment>
            <Divider style={{ marginTop: dividerMarginTop }}>{this.props.intl.formatMessage({ id: "products_nets" })}</Divider>

            {isFishingCards ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "fishing_card" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products.fishing_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isAccommodations ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "accommodation" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products.accommodations.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isFreePackages ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "packs" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products.free_packages.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isSeasonCards ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "season_cards" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products.season_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isRiverCards ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "river_cards" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products.river_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
           ) : null}
          </React.Fragment>
        ) : null}

        {isProductsTerminal ? (
          <React.Fragment>
            <Divider style={{ marginTop: dividerMarginTop }}>{this.props.intl.formatMessage({ id: "products_terminal" })}</Divider>

            {isFishingCardsTerminal ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "fishing_card" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_terminal_payment.fishing_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isAccommodationsTerminal ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "accommodation" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_terminal_payment.accommodations.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isFreePackagesTerminal ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "packs" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_terminal_payment.free_packages.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isSeasonCardsTerminal ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "season_cards" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_terminal_payment.season_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isRiverCardsTerminal ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "river_cards" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_terminal_payment.river_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
           ) : null}
          </React.Fragment>
        ) : null}

        {isManualProducts ? (
          <React.Fragment>
            <Divider style={{ marginTop: dividerMarginTop }}>{this.props.intl.formatMessage({ id: "products_manual" })}</Divider>

            {isManualFishingCards ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "fishing_card" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_manual_payment.fishing_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isManualAccommodations ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "accommodation" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_manual_payment.accommodations.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isManualFreePackages ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "packs" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_manual_payment.free_packages.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isManualSeasonCards ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "season_cards" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_manual_payment.season_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}

            {isManualRiverCards ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "river_cards" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "payment_to_customer" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_manual_payment.river_cards.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                            <td>{i.payment_to_customer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
           ) : null}

            {isManualTransactionFees ? (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "transaction_fees" })}</h2>
                <Row>
                  <Col xs={24}>
                    <Table bordered hover responsive>
                      <thead>
                        <tr>
                          {this.state.isAdmin || this.state.isAccountant ? <th>{this.props.intl.formatMessage({ id: "tax_code" })}</th> : null}
                          <th>{this.props.intl.formatMessage({ id: "tax_percentage" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "revenue_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_tax" })}</th>
                          <th>{this.props.intl.formatMessage({ id: "provision_percentage" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products_manual_payment.transaction_fees.map((i, key) => (
                          <tr className="text-center" key={key}>
                            {this.state.isAdmin || this.state.isAccountant ? <td>{i.tax_code || "-"}</td> : null}
                            <td>{i.tax_percentage}</td>
                            <td>{i.revenue}</td>
                            <td>{i.revenue_tax}</td>
                            <td>{i.provision}</td>
                            <td>{i.provision_tax}</td>
                            <td>{i.provision_percentage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reports: state.Reports,
  authentication: state.Auth,
  settings: state.Settings
});

export default withRouter(injectIntl(connect(mapStateToProps)(PlatformReport)));
