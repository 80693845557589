export const FETCH_RIVER_CARDS = "FETCH_RIVER_CARDS";
export const FETCH_RIVER_CARDS_SUCCESS = "FETCH_RIVER_CARDS_SUCCESS";
export const FETCH_RIVER_CARDS_FAILURE = "FETCH_RIVER_CARDS_FAILURE";

export const FETCH_BUY_RIVER_CARD_BUTTON_TEXT = "FETCH_BUY_RIVER_CARD_BUTTON_TEXT";
export const FETCH_BUY_RIVER_CARD_BUTTON_TEXT_SUCCESS = "FETCH_BUY_RIVER_CARD_BUTTON_TEXT_SUCCESS";
export const FETCH_BUY_RIVER_CARD_BUTTON_TEXT_FAILURE = "FETCH_BUY_RIVER_CARD_BUTTON_TEXT_FAILURE";

export const EDIT_BUY_RIVER_CARD_BUTTON = "EDIT_BUY_RIVER_CARD_BUTTON";
export const EDIT_BUY_RIVER_CARD_BUTTON_SUCCESS = "EDIT_BUY_RIVER_CARD_BUTTON_SUCCESS";
export const EDIT_BUY_RIVER_CARD_BUTTON_FAILURE = "EDIT_BUY_RIVER_CARD_BUTTON_FAILURE";

export function fetchCards(id) {
  return {
    type: FETCH_RIVER_CARDS,
    id,
  };
}

export function fetchBuyRiverCardButtonText(id) {
  return {
    type: FETCH_BUY_RIVER_CARD_BUTTON_TEXT,
    id,
  };
}

export function editBuyRiverCardButton({ river_id, buy_river_card_button_text}) {
  return {
    type: EDIT_BUY_RIVER_CARD_BUTTON,
    payload: {
      river_id,
      buy_river_card_button_text,
    }
  };
}
