export const GET_INFO = "GET_INFO";
export const GET_INFO_SUCCESS = "GET_INFO_SUCCESS";
export const GET_INFO_FAILURE = "GET_INFO_FAILURE";

export const SEND_MAIL = "SEND_MAIL";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAILURE = "SEND_MAIL_FAILURE";

export const ORDER_ADDITIONAL_EMAILS = "ORDER_ADDITIONAL_EMAILS";
export const ORDER_ADDITIONAL_EMAILS_SUCCESS = "ORDER_ADDITIONAL_EMAILS_SUCCESS";
export const ORDER_ADDITIONAL_EMAILS_FAILURE = "ORDER_ADDITIONAL_EMAILS_FAILURE";

export function getInfo(data) {
  return {
    type: GET_INFO,
    data,
  };
}

export function sendEmails(data) {
  return {
    type: SEND_MAIL,
    data,
  };
}

export function orderAdditionalEmails(data) {
  return {
    type: ORDER_ADDITIONAL_EMAILS,
    data,
  };
}
