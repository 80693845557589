import { all } from "redux-saga/effects";

import authSagas from "./authentication/saga";
import customerSagas from "./customers/saga";
import riversSagas from "./rivers/saga";
import riverSagas from "./river/saga";
import beatsSagas from "./beats/saga";
import productSagas from "./products/saga";
import fishingCardsSagas from "./fishing_cards/saga";
import accommodationSagas from "./accommodation/saga";
import fishingPackagesSagas from "./fishing_packages/saga";
import freePackagesSagas from "./free_packages/saga";
import bookingSagas from "./booking/saga";
import packageAvailabilitySagas from "./package_availability/saga";
import orderSagas from "./orders/saga";
import dashboardSagas from "./dashboard/saga";
import taxSagas from "./taxes/saga";
import commissionsSagas from "./commissions/saga";
import beatOptionsSagas from "./beat_options/saga";
import riverOptionsSagas from "./river_options/saga";
import lakseborsSagas from "./laksebors/saga";
import reportsSagas from "./reports/saga";
import stationsSagas from "./stations/saga";
import disinfectionSagas from "./disinfection/saga";
import stationroleSagas from "./stationrole/saga";
import stationadminsSagas from "./stationadmins/saga";
import catchesSagas from "./catches/saga";
import countiesSagas from "./counties/saga";
import countriesSagas from "./countries/saga";
import beatSagas from "./beat/saga";
import flyTypesSagas from "./flytypes/saga";
import facilitiesSagas from "./facilities/saga";
import articlesSagas from "./articles/saga";
import articleSagas from "./article/saga";
import articleCategoriesSagas from "./categories/saga";
import equipmentSagas from "./equipment/saga";
import fishTypesSagas from "./fishtypes/saga";
import extraServicesSagas from "./extra_services/saga";
import seasonPricesSagas from "./season_prices/saga";
import seasonDatesSagas from "./season_dates/saga";
import seasonCardsSagas from "./season_cards/saga";
import customerBlockSagas from "./customer_block/saga";
import pagesSagas from "./pages/saga";
import pageSagas from "./page/saga";
import newsfeedActivitySagas from "./newsfeed_activities/saga";
import riverRequiredFieldsSagas from "./river_require_catch_fields/saga";
import fishTypesAdminSagas from "./fishtypes_admin/saga";
import equipmentAdminSagas from "./equipment_admin/saga";
import cardsSagas from "./cards/saga";
import catchLimitsSagas from "./catch_limits/saga";
import controllerCommentsSagas from "./controller_comments/saga";
import billingsSagas from "./billings/saga";
import fangstrappSagas from "./fangstrapp/saga";
import notificationsSagas from "./notifications/saga";
import zonesSagas from "./zones/saga";
import beatNotificationsSagas from "./beat_notifications/saga";
import dashboardAdminSagas from "./dashboard_admin/saga";
import dashboardAdminCountiesSagas from "./dashboard_admin_counties/saga";
import dashboardAdminRiversSagas from "./dashboard_admin_rivers/saga";
import dashboardRiverAdminSagas from "./dashboard_river_admin/saga";
import dashboardRiverAdminCatchesSagas from "./dashboard_river_admin_catches/saga";
import dashboardRiverAdminCatchStatisticsSagas from "./dashboard_river_admin_catch_statistics/saga";
import dashboardRiverAdminRiverUsersSagas from "./dashboard_river_admin_admins/saga";
import dashboardRiverAdminFishingPressureSagas from "./dashboard_river_admin_fishing_pressure/saga";
import dashboardRiverAdminControllerUsersSagas from "./dashboard_river_admin_controllers/saga";
import dashboardBeatAdminSagas from "./dashboard_beat_admin/saga";
import dashboardBeatAdminBeatUsersSagas from "./dashboard_beat_admin_admins/saga";
import dashboardBeatAdminBeatMembersSagas from "./dashboard_beat_admin_members/saga";
import dashboardBeatAdminDailyCardsSagas from "./dashboard_beat_admin_daily_cards/saga";
import dashboardBeatAdminDailyAccommodationSagas from "./dashboard_beat_admin_daily_accommodation/saga";
import dashboardControllerAdminSagas from "./dashboard_controller_admin/saga";
import dashboardControllerStatisticsSagas from "./dashboard_controller_statistics/saga";
import riverCardBlueprintsSagas from "./river_card_blueprints/saga";
import riverCardsSagas from "./river_cards/saga";
import riverCardSurveysSagas from "./river_card_surveys/saga";
import sendMailSagas from "./send_mail/saga";
import sendMailOrdersSagas from "./send_mail_orders/saga";
import refundsSagas from "./refunds/saga";
import riverOrdersSagas from "./river_orders/saga";
import controllerRiversSagas from "./controller_rivers/saga";
import cancellationPoliciesSagas from "./cancellation_policies/saga";
import settingsSaga from "./settings/saga";
import riverEquipment from "./river_equipment/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    customerSagas(),
    riversSagas(),
    riverSagas(),
    beatsSagas(),
    beatSagas(),
    productSagas(),
    fishingCardsSagas(),
    accommodationSagas(),
    fishingPackagesSagas(),
    freePackagesSagas(),
    bookingSagas(),
    packageAvailabilitySagas(),
    orderSagas(),
    dashboardSagas(),
    taxSagas(),
    commissionsSagas(),
    beatOptionsSagas(),
    riverOptionsSagas(),
    lakseborsSagas(),
    reportsSagas(),
    stationsSagas(),
    disinfectionSagas(),
    stationroleSagas(),
    stationadminsSagas(),
    catchesSagas(),
    countiesSagas(),
    countriesSagas(),
    flyTypesSagas(),
    facilitiesSagas(),
    articlesSagas(),
    articleSagas(),
    articleCategoriesSagas(),
    equipmentSagas(),
    fishTypesSagas(),
    extraServicesSagas(),
    seasonPricesSagas(),
    seasonDatesSagas(),
    seasonCardsSagas(),
    customerBlockSagas(),
    pagesSagas(),
    pageSagas(),
    newsfeedActivitySagas(),
    riverRequiredFieldsSagas(),
    fishTypesAdminSagas(),
    equipmentAdminSagas(),
    cardsSagas(),
    catchLimitsSagas(),
    controllerCommentsSagas(),
    billingsSagas(),
    fangstrappSagas(),
    notificationsSagas(),
    zonesSagas(),
    beatNotificationsSagas(),
    dashboardAdminSagas(),
    dashboardAdminCountiesSagas(),
    dashboardAdminRiversSagas(),
    dashboardRiverAdminSagas(),
    dashboardRiverAdminCatchesSagas(),
    dashboardRiverAdminCatchStatisticsSagas(),
    dashboardRiverAdminRiverUsersSagas(),
    dashboardRiverAdminFishingPressureSagas(),
    dashboardRiverAdminControllerUsersSagas(),
    dashboardBeatAdminSagas(),
    dashboardBeatAdminBeatUsersSagas(),
    dashboardBeatAdminBeatMembersSagas(),
    dashboardBeatAdminDailyCardsSagas(),
    dashboardBeatAdminDailyAccommodationSagas(),
    dashboardControllerAdminSagas(),
    dashboardControllerStatisticsSagas(),
    riverCardBlueprintsSagas(),
    riverCardsSagas(),
    riverCardSurveysSagas(),
    sendMailSagas(),
    sendMailOrdersSagas(),
    refundsSagas(),
    riverOrdersSagas(),
    controllerRiversSagas(),
    cancellationPoliciesSagas(),
    settingsSaga(),
    riverEquipment(),
  ]);
}
