export const FETCH_DASHBOARD_ADMIN = "FETCH_DASHBOARD_ADMIN";
export const FETCH_DASHBOARD_ADMIN_SUCCESS = "FETCH_DASHBOARD_ADMIN_SUCCESS";
export const FETCH_DASHBOARD_ADMIN_FAILURE = "FETCH_DASHBOARD_ADMIN_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_ADMIN,
    payload
  };
}
