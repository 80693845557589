import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimePicker, Checkbox, Alert } from "antd";
import moment from "moment";

import Spinner from "../../../spinner";
import SelectInput from "../../../select";
import { fetchTypes as fetchCardTypes } from "../../../../redux/fishing_cards/actions";
import { add as addBooking } from "../../../../redux/booking/actions";
import { quiteAdd } from "../../../../redux/customers/actions";
import APIMessage from "../../../../services/api_messages";
import SaveLabel from "../../../helpers/buttons/save_label";
import { DEFAULT_LANGUAGE } from "../../../../constants";
import translations from "../../../../constants/strings";
import { fetch as fetchPackageAvailability } from "../../../../redux/package_availability/actions";
import transactionFee from "../../../../services/transaction_fee";
import ANT_CONFIG from "../../../../constants/antconfig";

const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;

const labelWidth = 4;
const inputWidth = 8;

class ModalOrderFishingPackages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flexible_time: false,
      modal: true,
      submitted: false,
      beat_id: props.selectedBeat,
      date_start: props.modalData.date_start || null,
      quantity: 1,
      currentPage: 0,
      fishing_cards: {},
      show_new_user_form: false,
      fishing_card_type_id: null,
      prices: null,
      price_options: null,
      package_blueprint_id: null,
      user: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
      },
      additional_user: null,
      payment_method: false,
      payment_method_selected: null,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.props.dispatch(fetchCardTypes(this.state.beat_id));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers.new_user !== this.props.customers.new_user) {
      this.onComponentUpdate();
    }
  }

  onComponentUpdate() {
    this.setState((prevState) => ({
      user: {
        id: this.props.customers.new_user.id,
        first_name: prevState.user.first_name,
        last_name: prevState.user.last_name,
        email: this.props.customers.new_user.email,
      },
    }));
  }

  onChangeAdditionalUser(name) {
    this.setState((prevState) => ({
      additional_user: {
        ...prevState.additional_user,
        [prevState.currentPage]: {
          name,
          send_to_email: prevState.additional_user?.[prevState.currentPage]?.send_to_email || "",
        },
      },
    }));
  }

  onChangeAdditionalUserEmail(send_to_email) {
    this.setState((prevState) => ({
      additional_user: {
        ...prevState.additional_user,
        [prevState.currentPage]: {
          send_to_email,
          name: prevState.additional_user?.[prevState.currentPage]?.name || "",
        },
      },
    }));
  }

  onChangeUserFirstName(name) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        first_name: name,
      },
    }));
  }

  onChangeUserLastName(name) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        last_name: name,
      },
    }));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({
      data: { ...prevState.data, ...newState },
    }));
  }

  onSubmit(fee) {
    this.setState({
      submitted: true,
    });

    const data = {
      user_id: this.state.user.id,
      payment_method: this.state.payment_method ? this.state.payment_method_selected : "nets",
      packages: [
        {
          blueprint_id: this.state.package_blueprint_id,
          date_start: this.state.date_start,
          quantity: this.state.quantity,
          fishing_card_type_id: this.state.fishing_card_type_id,
          owners: [...Object.values(this.state.fishing_cards).slice(1, Object.values(this.state.fishing_cards).length)],
        },
      ],
    };

    this.props.dispatch(addBooking(data));
  }

  onChangeCardType(id) {
    this.setState({ fishing_card_type_id: id }, () => {
      this.handleFlexTime();
      this.fetchData();
    });
  }

  onTimeSelect(e) {
    if (!e) {
      return;
    }

    this.setState((prevState) => ({
      times: {
        ...prevState.times,
        [prevState.currentPage]: {
          t: e,
        },
      },
    }));
  }

  onChangePackageBlueprint(e) {
    this.setState({ package_blueprint_id: e }, () => {
      this.fetchData();
    });
  }

  onChangeQuantity(e) {
    this.setState({ quantity: e > 0 ? e : 1 }, () => {
      this.fetchData();
    });
  }

  onPriceSelect(e) {
    this.setState((prevState) => ({
      prices: {
        ...prevState.prices,
        [prevState.currentPage]: e,
      },
    }));
  }

  onSelectUser(e) {
    const customer = this.props.customers.data.filter((i) => i.id === e)[0];

    if (customer) {
      this.setState({
        user: {
          id: customer.id,
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
        },
      });
    }
  }

  onSelectPreviousPage() {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  }

  onSelectNextPage() {
    let name;
    let send_to_email;

    if (this.state.currentPage === 1) {
      if (!this.state.user.first_name || !this.state.user.last_name) {
        return APIMessage.error(translations.error[lang].select_user);
      }

      name = `${this.state.user.first_name} ${this.state.user.last_name}`;
    }
    if (this.state.currentPage > 1 && this.state.currentPage <= this.state.quantity) {
      if (!this.state.additional_user[this.state.currentPage]) {
        return APIMessage.error(translations.error[lang].user_field_required);
      }

      name = this.state.additional_user?.[this.state.currentPage]?.name || "";
      send_to_email = this.state.additional_user?.[this.state.currentPage]?.send_to_email || "";
    }

    let item = null;

    if (this.state.prices && this.state.prices[this.state.currentPage]) {
      const currentPackage = this.props.fishing_packages.packages.data[this.state.beat_id].filter(
        (i) => i.id === this.state.package_blueprint_id,
      )[0];
      const priceAmountId = currentPackage.price_amounts.filter(
        (i) => i.price_type_id === this.state.prices[this.state.currentPage],
      )[0].id;

      item = {
        name,
        send_to_email,
        price_amount_id: priceAmountId,
      };
    }

    if (this.state.flexible_time && this.state.times) {
      if (!this.state.times[this.state.currentPage]) {
        return APIMessage.error(translations.error[lang].select_all_times);
      }

      item = {
        ...item,
        flexible_time: this.state.times[this.state.currentPage]
          ? moment(this.state.times[this.state.currentPage].t).format("HH:mm")
          : "",
      };
    }

    if (this.state.currentPage > 0 && this.state.flexible_time && !this.state.times) {
      return APIMessage.error(translations.error[lang].select_all_times);
    }

    if (this.state.currentPage > 0 && this.state.flexible_time && !this.state.times[this.state.currentPage]) {
      return APIMessage.error(translations.error[lang].select_all_times);
    }

    if (this.state.currentPage > 0 && !item) {
      return APIMessage.error(translations.error[lang].select_all_prices);
    }

    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        fishing_cards: {
          ...prevState.fishing_cards,
          [prevState.currentPage]: {
            ...item,
          },
        },
      }),
      () => {
        const packages = this.props.fishing_packages.packages.data[this.state.beat_id].filter(
          (i) => i.id === this.state.package_blueprint_id,
        );
        if (packages && packages[0]) {
          this.setState({
            price_options: packages[0].price_options,
          });
        }
      },
    );

    return true;
  }

  onChangeUserInput(newState) {
    this.setState((prevState) => ({
      user: { ...prevState.user, ...newState },
    }));
  }

  onSaveNewUser() {
    this.props.dispatch(
      quiteAdd({
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        email: this.state.user.email,
      }),
    );
  }

  handleFlexTime() {
    const item = this.props.fishing_cards.type.data.filter((i) => i.id === this.state.fishing_card_type_id);

    this.setState({
      flexible_time: item[0].flexible_time,
    });
  }

  changePaymentMethod(e) {
    this.setState({
      payment_method_selected: e.target.value,
    });
  }

  fetchData() {
    if (this.state.date_start && this.state.package_blueprint_id && this.state.quantity && this.state.fishing_card_type_id) {
      this.props.dispatch(
        fetchPackageAvailability({
          blueprint_id: this.state.package_blueprint_id,
          date_start: this.state.date_start,
          quantity: this.state.quantity,
          fishing_card_type_id: this.state.fishing_card_type_id,
        }),
      );
    }
  }

  handleChangeDate(e) {
    this.setState({ date_start: e }, () => {
      this.fetchData();
    });
  }

  addNewUser() {
    this.setState((prevState) => ({
      show_new_user_form: true,
      user: {
        ...prevState.user,
        id: null,
      },
    }));
  }

  toggle() {
    this.props.toggleModal();
  }

  render() {
    const selectCustomers = [
      ...this.props.customers.data.map((i) => {
        return {
          value: i.id,
          label: `${i.first_name} ${i.last_name} (${i.email})`,
        };
      }),
    ];
    const selectCardTypes = this.props.fishing_cards.type.data
      ? this.props.fishing_cards.type.data.map((i) => {
          return {
            value: i.id,
            label: i.name,
          };
        })
      : null;
    const selectPackages = this.props.fishing_packages.packages.data[this.state.beat_id].map((i) => {
      return {
        value: i.id,
        label: translations.package_types[this.props.intl.locale][i.type],
      };
    });

    if (this.props.booking.loading) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    if (this.props.booking.data && !this.props.booking.loading && !this.props.booking.error && this.state.submitted) {
      if (this.state.payment_method) {
        this.toggle();

        return false;
      }

      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "payment_link" })}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} sm={10}>
                <Input type="text" defaultValue={this.props.booking.data.terminal_urls.url} disabled />
              </Col>
              <Col xs={12} sm={2}>
                <a
                  href={this.props.booking.data.terminal_urls.url}
                  className="btn btn-success"
                  style={{ width: "100%", lineHeight: "24px" }}
                >
                  {this.props.intl.formatMessage({ id: "pay_now" })}
                </a>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      );
    }

    if (this.props.package_availability.loading || !this.props.fishing_cards.type.data) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    const availability = this.props.package_availability.data || null;

    let totalPrice = 0;
    if (this.state.prices) {
      Object.values(this.state.prices).map((item) => {
        const price = this.state.price_options.filter((i) => i.id === item)[0].amount;
        totalPrice += parseFloat(price);

        return totalPrice;
      });
    }

    return (
      <Modal isOpen={this.state.modal}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
        {this.state.currentPage === 0 ? (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Label for="select_package" xs={12} sm={labelWidth}>
                      {`${this.props.intl.formatMessage({
                        id: "select_package",
                      })}`}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <SelectInput
                        id="select_package"
                        name="select_package"
                        intl={this.props.intl}
                        value={selectPackages.filter((i) => i.value === this.state.package_blueprint_id)}
                        onChange={(e) => this.onChangePackageBlueprint(e.value)}
                        options={selectPackages}
                      />
                    </Col>
                  </FormGroup>

                  {this.props.fishing_cards.type.loading ? (
                    <FormGroup row>
                      <Label for="select_card_type" xs={12} sm={labelWidth}>
                        {`${this.props.intl.formatMessage({
                          id: "fishing_card_type",
                        })}`}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <Spinner width={30} style={{ height: 53, marginTop: -15 }} />
                      </Col>
                    </FormGroup>
                  ) : (
                    <FormGroup row>
                      <Label for="select_card_type" xs={12} sm={labelWidth}>
                        {`${this.props.intl.formatMessage({
                          id: "fishing_card_type",
                        })}`}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <SelectInput
                          id="select_card_type"
                          name="select_card_type"
                          intl={this.props.intl}
                          value={selectCardTypes.filter((i) => i.value === this.state.fishing_card_type_id)}
                          onChange={(e) => this.onChangeCardType(e.value)}
                          options={selectCardTypes}
                        />
                      </Col>
                    </FormGroup>
                  )}

                  <FormGroup row>
                    <Label for="date_start" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "date_start" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="date"
                        name="date_start"
                        id="date_start"
                        value={this.state.date_start}
                        onChange={(e) => this.handleChangeDate(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="quantity" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "quantity" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="number"
                        name="quantity"
                        id="quantity"
                        value={this.state.quantity}
                        onChange={(e) => this.onChangeQuantity(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xs={12} sm={labelWidth} />
                    <Col xs={12} sm={inputWidth}>
                      <Checkbox
                        checked={this.state.payment_method}
                        onChange={(event) => {
                          const checked = event.target.checked;

                          if (checked) this.setState({ payment_method: true, payment_method_selected: "manual" });
                          else this.setState({ payment_method: false, payment_method_selected: null });
                        }}
                      >
                        {this.props.intl.formatMessage({
                          id: "payment_performed_manually",
                        })}
                      </Checkbox>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            {availability &&
            !this.props.package_availability.error &&
            this.state.date_start &&
            this.state.package_blueprint_id &&
            this.state.quantity &&
            this.state.fishing_card_type_id ? (
              <ModalFooter>
                <Button color="secondary" onClick={() => this.onSelectNextPage()}>
                  {this.props.intl.formatMessage({ id: "next" })}
                </Button>
              </ModalFooter>
            ) : null}
          </Form>
        ) : null}

        {this.state.currentPage > 0 && this.state.currentPage <= this.state.quantity ? (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  {this.state.currentPage === 1 ? (
                    <div>
                      {this.state.show_new_user_form ? (
                        <FormGroup row>
                          <Label for="or_add_new_customer" xs={12} sm={labelWidth}>
                            {`${this.props.intl.formatMessage({
                              id: "new_customer",
                            })}`}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <FormGroup row>
                              <Col xs={12} sm={6}>
                                <Input
                                  type="text"
                                  disabled={this.props.customers.new_user}
                                  placeholder={this.props.intl.formatMessage({
                                    id: "first_name",
                                  })}
                                  value={this.state.user.first_name}
                                  onChange={(e) =>
                                    this.onChangeUserInput({
                                      first_name: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={12} sm={6}>
                                <Input
                                  type="text"
                                  disabled={this.props.customers.new_user}
                                  placeholder={this.props.intl.formatMessage({
                                    id: "last_name",
                                  })}
                                  value={this.state.user.last_name}
                                  onChange={(e) =>
                                    this.onChangeUserInput({
                                      last_name: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col xs={12}>
                                <Input
                                  type="email"
                                  disabled={this.props.customers.new_user}
                                  placeholder={this.props.intl.formatMessage({
                                    id: "email",
                                  })}
                                  value={this.state.user.email}
                                  onChange={(e) =>
                                    this.onChangeUserInput({
                                      email: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </FormGroup>
                            {this.props.customers.loading ? (
                              <FormGroup row>
                                <Col xs={12}>
                                  <Spinner />
                                </Col>
                              </FormGroup>
                            ) : null}
                            {!this.props.customers.loading && !this.props.customers.new_user ? (
                              <FormGroup row>
                                <Col xs={12}>
                                  <Button color="secondary" onClick={() => this.onSaveNewUser()} style={{ width: "100%" }}>
                                    <SaveLabel intl={this.props.intl} />
                                  </Button>
                                </Col>
                              </FormGroup>
                            ) : null}
                          </Col>
                        </FormGroup>
                      ) : (
                        <div>
                          <FormGroup row>
                            <Label for="select_customer" xs={12} sm={labelWidth}>
                              {`${this.props.intl.formatMessage({
                                id: "select_customer",
                              })}`}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <SelectInput
                                id="select_customer"
                                name="select_customer"
                                intl={this.props.intl}
                                value={selectCustomers.filter((i) => i.value === this.state.user.id)}
                                onChange={(e) => this.onSelectUser(e.value)}
                                options={selectCustomers}
                              />
                            </Col>
                          </FormGroup>
                          {this.state.user.id ? (
                            <FormGroup row>
                              <Col xs={12} sm={labelWidth} />
                              <Col xs={12} sm={4}>
                                <Input
                                  type="text"
                                  value={this.state.user.first_name}
                                  onChange={(e) => this.onChangeUserFirstName(e.target.value)}
                                />
                              </Col>
                              <Col xs={12} sm={4}>
                                <Input
                                  type="text"
                                  value={this.state.user.last_name}
                                  onChange={(e) => this.onChangeUserLastName(e.target.value)}
                                />
                              </Col>
                            </FormGroup>
                          ) : null}
                          {!this.state.user.id ? (
                            <FormGroup row>
                              <Col xs={12} sm={labelWidth} />
                              <Col xs={12} sm={inputWidth}>
                                <Button color="success" onClick={() => this.addNewUser()} style={{ width: "100%" }}>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{
                                      fontSize: 15,
                                      marginRight: 10,
                                    }}
                                  />
                                  {this.props.intl.formatMessage({
                                    id: "add_new_customer",
                                  })}
                                </Button>
                              </Col>
                            </FormGroup>
                          ) : null}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <FormGroup row>
                        <Label for="first_and_last_name" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({
                            id: "first_and_last_name",
                          })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            value={
                              this.state.additional_user?.[this.state.currentPage]?.name
                                ? this.state.additional_user[this.state.currentPage].name
                                : ""
                            }
                            onChange={(e) => this.onChangeAdditionalUser(e.target.value)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="email" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "email" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="email"
                            value={
                              this.state.additional_user?.[this.state.currentPage]?.send_to_email
                                ? this.state.additional_user[this.state.currentPage].send_to_email
                                : ""
                            }
                            onChange={(e) => this.onChangeAdditionalUserEmail(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </Col>
                {this.state.price_options ? (
                  <Col xs={12}>
                    <FormGroup row>
                      <Label for="select_price" xs={12} sm={labelWidth}>
                        {this.props.intl.formatMessage({ id: "select_price" })}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <SelectInput
                          id="select_price"
                          name="select_price"
                          intl={this.props.intl}
                          value={this.state.price_options
                            .map((i) => {
                              return {
                                value: i.id,
                                label: `${i.description} - ${i.amount}`,
                              };
                            })
                            .filter(
                              (i) =>
                                i.value ===
                                (this.state.prices && this.state.prices[this.state.currentPage]
                                  ? this.state.prices[this.state.currentPage]
                                  : null),
                            )}
                          onChange={(e) => this.onPriceSelect(e.value)}
                          options={this.state.price_options.map((i) => {
                            return {
                              value: i.id,
                              label: `${i.description} - ${i.amount}`,
                            };
                          })}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                ) : null}
                {this.state.flexible_time ? (
                  <React.Fragment>
                    <Col xs={12} sm={labelWidth} />
                    <Col xs={12} sm={inputWidth}>
                      <strong style={{ display: "block", margin: "15px 0 10px" }}>
                        {this.props.intl.formatMessage({
                          id: "select_start_dates",
                        })}
                      </strong>
                    </Col>
                    <Col xs={12}>
                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={inputWidth}>
                          <TimePicker
                            allowClear={false}
                            style={{ width: "100%" }}
                            onChange={(e) => this.onTimeSelect(e)}
                            defaultOpenValue={moment("00:00", "HH:mm")}
                            size={ANT_CONFIG.size}
                            format="HH:mm"
                            minuteStep={30}
                            value={
                              this.state.times && this.state.times[this.state.currentPage]
                                ? this.state.times[this.state.currentPage].t
                                : null
                            }
                            placeholder=""
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                ) : null}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => this.onSelectPreviousPage()}>
                {this.props.intl.formatMessage({ id: "back" })}
              </Button>
              <Button color="secondary" onClick={() => this.onSelectNextPage()}>
                {this.props.intl.formatMessage({ id: "next" })}
              </Button>
            </ModalFooter>
          </Form>
        ) : null}

        {this.state.currentPage > this.state.quantity ? (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Label for="total_price" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "total_price" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="text"
                        name="total_price"
                        id="total_price"
                        disabled
                        defaultValue={parseFloat(totalPrice).toFixed(2)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="fee" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "fee" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="text"
                        name="fee"
                        id="fee"
                        disabled
                        defaultValue={transactionFee(parseFloat(totalPrice).toFixed(2))}
                      />
                    </Col>
                  </FormGroup>
                  {this.state.payment_method ? (
                    <FormGroup row>
                      <Col xs={12} sm={labelWidth} />
                      <Col xs={12} sm={inputWidth}>
                        <Alert
                          message={`${this.props.intl.formatMessage({
                            id: "remember",
                          })}!`}
                          description={this.props.intl.formatMessage({
                            id: "payment_performed_manually_2",
                          })}
                          type="warning"
                          showIcon
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => this.onSelectPreviousPage()}>
                {this.props.intl.formatMessage({ id: "back" })}
              </Button>
              <Button color="secondary" onClick={() => this.onSubmit(transactionFee(parseFloat(totalPrice).toFixed(2)))}>
                {this.props.intl.formatMessage({ id: "confirm" })}
              </Button>
            </ModalFooter>
          </Form>
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.Users,
  authentication: state.Auth,
  fishing_cards: state.FishingCards,
  fishing_packages: state.FishingPackages,
  booking: state.Booking,
  customers: state.Customers,
  package_availability: state.PackageAvailability,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalOrderFishingPackages)));
