export const FETCH_RIVER = "FETCH_RIVER";
export const FETCH_RIVER_SUCCESS = "FETCH_RIVER_SUCCESS";
export const FETCH_RIVER_FAILURE = "FETCH_RIVER_FAILURE";

export const UPDATE_RIVER = "UPDATE_RIVER";
export const UPDATE_RIVER_SUCCESS = "UPDATE_RIVER_SUCCESS";
export const UPDATE_RIVER_FAILURE = "UPDATE_RIVER_FAILURE";

export const SET_BEAT_STATUS = "SET_BEAT_STATUS";
export const SET_BEAT_STATUS_SUCCESS = "SET_BEAT_STATUS_SUCCESS";
export const SET_BEAT_STATUS_FAILURE = "SET_BEAT_STATUS_FAILURE";

export function fetch(id) {
  return {
    type: FETCH_RIVER,
    id,
  };
}

export function update(data, id) {
  return {
    type: UPDATE_RIVER,
    data,
    id,
  };
}

export function setBeatStatus(data, id) {
  return {
    type: SET_BEAT_STATUS,
    data,
    id,
  };
}
