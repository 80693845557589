export default {
  nb: {
    package_not_available: "Pakke ikke tilgjengelig",
    fill_all_fields: "Fyll ut alle feltene",
    price_already_exists: "Prisen eksisterer allerede",
    user_field_required: "Bruker er påkrevd",
    select_all_times: "Starttid er påkrevd",
    select_all_prices: "Velg alle priser",
    select_accommodation: "Velg overnatting",
    not_enough_capacity: "Ikke nok kapasitet",
    select_user: "Velg bruker",
    dates_unavailable: "Utvalgte datoer er utilgjengelige",
    quantity_unavailable: "Valgt antall er ikke tilgjengelig",
    something_went_wrong_download: "Noe gikk galt under nedlasting",
  },
  en: {
    package_not_available: "Package not available",
     fill_all_fields: "Fill in all fields",
     price_already_exists: "The price already exists",
     user_field_required: "User is required",
     select_all_times: "Start time is required",
     select_all_prices: "Select all prices",
     select_accommodation: "Select accommodation",
     not_enough_capacity: "Not enough capacity",
     select_user: "Select User",
     dates_unavailable: "Selected dates are unavailable",
     quantity_unavailable: "Selected quantity is not available",
     something_went_wrong_download: "Something went wrong while downloading",
  },
  sv: {
    package_not_available: "Paket inte tillgängligt",
     fill_all_fields: "Fyll i alla fält",
     price_already_exists: "Priset finns redan",
     user_field_required: "Användare krävs",
     select_all_times: "Starttid krävs",
     select_all_prices: "Välj alla priser",
     select_accommodation: "Välj boende",
     not_enough_capacity: "Inte tillräckligt med kapacitet",
     select_user: "Välj användare",
     dates_unavailable: "Utvalda datum är inte tillgängliga",
     quantity_unavailable: "Vald kvantitet är inte tillgänglig",
     something_went_wrong_download: "Något gick fel under nedladdningen",
  }
};
