import {all, call, put, takeEvery} from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";
import strings from "../../constants/languages/strings";

function* fetch(action) {
  try {
    const response = yield call(API.call, "admin/disinfection", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_DISINFECTION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_DISINFECTION_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_DISINFECTION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* download(action) {
    // I have not figured out how to fetch error response from blob call, so this is the generic error message
    const errorMsg =
        strings?.error?.nb?.something_went_wrong_download || "Something went wrong";
    try {
        const response = yield call(
            API.call,
            "admin/download-report/excel/disinfections",
            "POST",
            action.payload
        );
        const responseBody = yield call([response, "blob"]);
        if (response.status >= 400) {
            yield put({
                type: ACTION.DOWNLOAD_DISINFECTIONS_FAILURE,
                message: responseBody.errors,
            });
            APIMessage.error(errorMsg);
        } else {
            yield put({
                type: ACTION.DOWNLOAD_DISINFECTIONS_SUCCESS,
                payload: responseBody,
            });
        }
    } catch (e) {
        yield put({
            type: ACTION.DOWNLOAD_DISINFECTIONS_FAILURE,
            message: e.errors,
        });
        APIMessage.error(errorMsg);
    }
}

function* fetchStationAdmin(action) {
  try {
    const response = yield call(API.call, "admin/disinfection-sa", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_DISINFECTION_SA_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_DISINFECTION_SA_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_DISINFECTION_SA_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* add(action) {
  try {
    const response = yield call(API.call, "auth/disinfection", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_DISINFECTION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.ADD_DISINFECTION_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_DISINFECTION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
    yield all([
        takeEvery(ACTION.FETCH_DISINFECTION, fetch),
        takeEvery(ACTION.DOWNLOAD_DISINFECTIONS, download),
        takeEvery(ACTION.FETCH_DISINFECTION_SA, fetchStationAdmin),
        takeEvery(ACTION.ADD_DISINFECTION, add)]);
}
