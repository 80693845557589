export const FETCH_EQUIPMENT = "FETCH_EQUIPMENT";
export const FETCH_EQUIPMENT_SUCCESS = "FETCH_EQUIPMENT_SUCCESS";
export const FETCH_EQUIPMENT_FAILURE = "FETCH_EQUIPMENT_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_EQUIPMENT,
    payload
  };
}
