import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Table, Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";

import { fetch as fetchStations } from "../../redux/stations/actions";
import Spinner from "../../components/spinner";
import Scribe from "../../components/Scribe";

class Stations extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchStations());
  }

  onRowClick(id) {
    this.props.history.push(`/station/${id}`);
  }

  render() {
    if (!this.props.stations.data || this.props.stations.loading) {
      return <Spinner />;
    }

    return (
      <div className="panel">
        <h2 className="title">{this.props.intl.formatMessage({ id: "stations" })}</h2>
        <Row>
          <Col xs={12}>
            <NavLink to="/station/new">
              <Button color="secondary" style={{ marginBottom: 15 }}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{
                    fontSize: 15,
                    marginRight: 10
                  }}
                />
                {this.props.intl.formatMessage({ id: "new_station" })}
              </Button>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{this.props.intl.formatMessage({ id: "name" })}</th>
                  <th>{this.props.intl.formatMessage({ id: "river" })}</th>
                  <th>{this.props.intl.formatMessage({ id: "staff" })}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.stations.data.length > 0 ? (
                  this.props.stations.data.map(item => (
                    <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                      <th scope="row">{item.id}</th>
                      <th scope="row">{item.name}</th>
                      <th scope="row">{item.river ? item.river.name : "&nbsp;"}</th>
                      <th scope="row">
                        {item.staffed ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{
                              fontSize: 15,
                              marginRight: 10
                            }}
                          />
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </th>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={9}>{this.props.intl.formatMessage({ id: "no_stations_found" })}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Se_liste_over_stasjoner_endre_og_slette_stasjoner__B9oPgUidQ9StJOI51DZlEQ"/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth,
  stations: state.Stations
});

export default withRouter(injectIntl(connect(mapStateToProps)(Stations)));
