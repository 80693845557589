import React from "react";

export const SummaryRenderer = ({ data, record,intl }) => {
  let payment_to_customer_message = intl.formatMessage({ id: "payment_to_customer_online" });
  let transaction_fee_manual = intl.formatMessage({ id: "economic_transaction_fee_manual" });
  let invoice_to_customer = intl.formatMessage({ id: "invoice_to_customer" });
  let total_payout = intl.formatMessage({ id: "economic_total_payout" });
  let currency = intl.formatMessage({ id: "currency" });
  return data === "payment_to_customer" ? (
    <>{payment_to_customer_message}</>
  ) : data === "transaction_fee_manual" ? (
    <>{transaction_fee_manual}</>
  ) : data === "invoice_to_customer" ? (
    <>{invoice_to_customer}</>
  ) : data === "total_payout" ? (
    <>{total_payout}</>
  ) : data === "currency" ? (
    <>{currency}</>
  ) : (
    ""
  );
  };