import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import uuidv1 from "uuid";
import { Button, Checkbox, Modal, Row, Col, Input, Table, Select } from "antd";

import { addType as addCardType } from "../../../../redux/fishing_cards/actions";
import ANT_CONFIG from "../../../../constants/antconfig";

class ModalAddCardType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      data: {
        beat_id: props.selectedBeat,
        name: "",
        description: "",
        flexible_time: false,
        members_only: false
      },
      price_types: [],
      priceTypeModalVisible: false,
      temporary_price: null
    };
  }

  componentDidMount() {
    if (this.props.selectBeats.length === 1) {
      this.onChangeValue({ beat_id: this.props.selectBeats[0].value });
    }
  }

  componentDidUpdate() {
    if (!this.props.fishing_cards.type.loading && !this.props.fishing_cards.type.error && this.state.submitted) {
      this.onCancel();
    }
  }

  onChangeValue(newState) {
    this.setState(prevState => ({ data: { ...prevState.data, ...newState } }));
  }

  onChangeTemporaryPrice(newState) {
    this.setState(prevState => ({ temporary_price: { ...prevState.temporary_price, ...newState } }));
  }

  onSubmit = () => {
    this.setState({
      submitted: true
    });

    this.props.dispatch(
      addCardType({
        ...this.state.data,
        price_types: this.state.price_types
      })
    );
  };

  onSubmitPriceType = () => {
    if (!this.state.temporary_price.tax_option_id || !this.state.temporary_price.name) {
      return false;
    }

    this.setState(
      prevState => ({
        price_types: [
          ...prevState.price_types,
          {
            id: uuidv1(),
            ...prevState.temporary_price
          }
        ]
      }),
      () => {
        this.onCancelAddPriceType();
      }
    );
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  onCancelAddPriceType = () => {
    this.setState({
      temporary_price: null,
      priceTypeModalVisible: false
    });
  };

  onAddPriceType = () => {
    this.setState({
      priceTypeModalVisible: true
    });
  };

  setMembersOnly = () => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        members_only: !prevState.data.members_only
      }
    }));
  };

  render() {
    if (!this.state.data.beat_id) {
      return;
    }

    return (
      <Modal
        width={800}
        title={this.props.intl.formatMessage({ id: "add_card_type" })}
        visible={this.state.modal}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="price_type" disabled={this.props.fishing_cards.type.loading} type="secondary" onClick={this.onAddPriceType}>
            {this.props.intl.formatMessage({ id: "add_price_type" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" loading={this.props.fishing_cards.type.loading} type="primary" onClick={this.onSubmit}>
            {this.props.intl.formatMessage({ id: "save" })}
          </Button>
        ]}
      >
        <Row>
          <Col xs={24}>
            <label className="ant-label" htmlFor="name">
              {this.props.intl.formatMessage({ id: "name" })}
            </label>
            <Input
              size={ANT_CONFIG.size}
              type="text"
              name="name"
              id="name"
              value={this.state.data.name}
              onChange={e => this.onChangeValue({ name: e.target.value })}
            />
          </Col>
          <Col xs={24} style={{ paddingTop: 20 }}>
            <label className="ant-label" htmlFor="description">
              {this.props.intl.formatMessage({ id: "description" })}
            </label>
            <Input.TextArea
              name="description"
              id="description"
              size={ANT_CONFIG.size}
              autoSize={{ minRows: 3 }}
              value={this.state.data.description}
              onChange={e => this.onChangeValue({ description: e.target.value })}
            />
          </Col>
          <Col xs={24} style={{ paddingTop: 20 }}>
            <Checkbox
              checked={this.state.data.flexible_time}
              size={ANT_CONFIG.size}
              onChange={() =>
                this.setState(prevState => ({
                  data: {
                    ...prevState.data,
                    flexible_time: !prevState.data.flexible_time
                  }
                }))
              }
            >
              {this.props.intl.formatMessage({ id: "flexible_time" })}
            </Checkbox>
          </Col>
          <Col xs={24} style={{ paddingTop: 20, paddingBottom: 30 }}>
            <Checkbox size={ANT_CONFIG.size} checked={this.state.data.members_only || false} onChange={this.setMembersOnly}>
              {this.props.intl.formatMessage({ id: "members_only" })}
            </Checkbox>
          </Col>

          <Col xs={24}>
            <label className="ant-label">{this.props.intl.formatMessage({ id: "price_types" })}</label>
            <Table
              className="ant-design-table-border"
              columns={[
                {
                  title: "#",
                  dataIndex: "key",
                  key: "key"
                },
                {
                  title: this.props.intl.formatMessage({ id: "name" }),
                  dataIndex: "name",
                  key: "name"
                },
                {
                  title: this.props.intl.formatMessage({ id: "description" }),
                  dataIndex: "description",
                  key: "description"
                },
                {
                  title: this.props.intl.formatMessage({ id: "tax" }),
                  dataIndex: "tax",
                  key: "tax"
                },
                {
                  title: "",
                  dataIndex: "action",
                  key: "action"
                }
              ]}
              dataSource={this.state.price_types.map((i, key) => {
                const tax = this.props.taxes?.data?.filter(p => p.id === i.tax_option_id)?.[0];

                return {
                  key: key + 1,
                  name: i.name || "-",
                  description: i.description || "-",
                  tax: tax ? `${tax.key} (${tax.value}%)` : "-",
                  action: (
                    <div style={{ textAlign: "right", width: "100%" }}>
                      <Button
                        size={ANT_CONFIG.size}
                        type="danger"
                        onClick={() =>
                          this.setState(prevState => ({
                            price_types: prevState.price_types.filter(p => p.id !== i.id)
                          }))
                        }
                      >
                        {this.props.intl.formatMessage({ id: "remove" })}
                      </Button>
                    </div>
                  )
                };
              })}
              pagination={ANT_CONFIG.pagination}
            />
          </Col>

          <Modal
            title={this.props.intl.formatMessage({ id: "add_price_type" })}
            visible={this.state.priceTypeModalVisible}
            onCancel={this.onCancelAddPriceType}
            footer={[
              <Button
                size={ANT_CONFIG.size}
                disabled={!this.state.temporary_price?.tax_option_id || !this.state.temporary_price?.name || !this.state.temporary_price?.description}
                key="submit"
                type="primary"
                onClick={this.onSubmitPriceType}
              >
                {this.props.intl.formatMessage({ id: "save" })}
              </Button>
            ]}
          >
            <Row>
              <Col xs={24}>
                <label className="ant-label" htmlFor="price_name">
                  {this.props.intl.formatMessage({ id: "name" })}
                </label>
                <Input
                  type="text"
                  name="price_name"
                  id="price_name"
                  size={ANT_CONFIG.size}
                  value={this.state.temporary_price?.name || ""}
                  onChange={e => this.onChangeTemporaryPrice({ name: e.target.value })}
                />
              </Col>
              <Col xs={24} style={{ paddingTop: 20 }}>
                <label className="ant-label" htmlFor="price_description">
                  {this.props.intl.formatMessage({ id: "description" })}
                </label>
                <Input.TextArea
                  name="price_description"
                  id="price_description"
                  size={ANT_CONFIG.size}
                  autoSize={{ minRows: 2 }}
                  value={this.state.temporary_price?.description || ""}
                  onChange={e => this.onChangeTemporaryPrice({ description: e.target.value })}
                />
              </Col>
              <Col xs={24} style={{ paddingTop: 20 }}>
                {this.props.taxes.data && (
                  <React.Fragment>
                    <label className="ant-label" htmlFor="select_tax">
                      {this.props.intl.formatMessage({ id: "tax" })}
                    </label>
                    <Select
                      id="select_tax"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({ id: "select_tax" })}`}
                      onChange={e => this.onChangeTemporaryPrice({ tax_option_id: e })}
                      value={this.state.temporary_price?.tax_option_id}
                    >
                      {this.props.taxes.data.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {`${i.key} (${i.value}%)`}
                        </Select.Option>
                      ))}
                    </Select>
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Modal>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  fishing_cards: state.FishingCards,
  taxes: state.Taxes
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddCardType)));
