import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Select, Checkbox, Alert, Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import { fetch as fetchRequiredFields, update as updateRequiredFields } from "../../redux/river_require_catch_fields/actions";
import Spinner from "../../components/spinner";
import riverOptions from "../../services/select_river_options";
import ANT_CONFIG from "../../constants/antconfig";
import Scribe from "../../components/Scribe";

const { Text } = Typography;

const REQUIRED_FIELDS = [
  "time_of_day",
  "equipment_id",
  "description",
  "sex",
  "boat",
  "adipose_fin_removed",
  "lice",
  "deny_anonymous_catch_report"
];

const REQUIRED_IF_NOT_RELEASED = [
  "scale_sample",
  "delivered",
  "on_quota",
  "damaged"
]

class RequiredCatchFields extends React.Component {
  state = {
    river_id: this.props.rivers.selected || this.props.authentication.data.river[0],
    required_fields: [],
  };

  componentDidMount() {
    const { rivers, dispatch } = this.props;
    const { river_id } = this.state;

    if (!rivers.data) {
      dispatch(fetchRivers());
    }

    dispatch(fetchRequiredFields(river_id));
  }

  componentDidUpdate(prevProps) {
    const { river_required_fields } = this.props;

    if (prevProps.river_required_fields.data !== river_required_fields.data) {
      this.handleFetch(river_required_fields.data || []);
    }
  }

  onSave = () => {
    const { dispatch } = this.props;
    const { river_id } = this.state;
    let { required_fields } = this.state;

    if (required_fields.includes("lice") && !required_fields.includes("lice_count")) {
      required_fields = [...required_fields, "lice_count"];
    }

    if (!required_fields.includes("lice")) {
      required_fields = [...required_fields.filter((field) => field !== "lice_count")];
    }

    dispatch(
      updateRequiredFields(
        {
          river_id,
          required_fields,
        },
        river_id
      )
    );
  };

  onCheckboxClick = (item) => {
    const { required_fields } = this.state;
    const { value } = item.target;

    if (required_fields.filter((i) => i === value).length > 0) {
      this.setState((prevState) => ({
        required_fields: [...prevState.required_fields?.filter((i) => i !== value)],
      }));
    } else {
      this.setState((prevState) => ({
        required_fields: [...prevState.required_fields, value],
      }));
    }
  };

  handleFetch(required_fields) {
    this.setState({
      required_fields,
    });
  }

  handleRiverChange(river_id) {
    const { dispatch } = this.props;

    this.setState({
      river_id,
    });

    dispatch(selectRiver(river_id));

    dispatch(fetchRequiredFields(river_id));
  }

  render() {
    const { rivers, authentication, intl, river_required_fields } = this.props;
    const { river_id, required_fields } = this.state;

    if (!rivers.data) {
      return <Spinner />;
    }

    const selectRivers = riverOptions(rivers, authentication);

    return (
      <>
        <PageHeader
          title={intl.formatMessage({ id: "required_catch_fields" })}
          extra={[
            <Button key="save" type="primary" onClick={this.onSave} loading={river_required_fields.loading} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
          ]}
        >
          {selectRivers?.length > 1 && (
            <Row style={{ marginTop: 15 }}>
              <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                <label className="ant-label" htmlFor="select_river">
                  {intl.formatMessage({ id: "select_river" })}
                </label>
                <Select
                  showSearch
                  id="select_river"
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${intl.formatMessage({ id: "select_river" })}`}
                  optionFilterProp="children"
                  onChange={(e) => this.handleRiverChange(e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={river_id}
                >
                  {selectRivers.map((i, key) => (
                    <Select.Option key={key} value={i.value}>
                      {i.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
        </PageHeader>

        <Row>
          <Col xs={24} style={{ marginTop: 20, marginLeft: "auto", marginRight: "auto" }}>
            <Alert
              showIcon
              message={intl.formatMessage({ id: "obligatory_fields_alert_title" })}
              description={
                <ul style={{ margin: "5px 0 0", paddingLeft: 20 }}>
                  {[1, 2, 3, 4, 5].map((i) => (
                    <li key={i}>{intl.formatMessage({ id: `obligatory_${i}` })}</li>
                  ))}
                </ul>
              }
              type="info"
            />
          </Col>

          <Col xs={24}>
            <Card style={{ marginTop: 20 }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} spinning={river_required_fields.loading}>
                <div className="container-600">
                  <Row>
                    <Col xs={24}>
                      <Text strong>{intl.formatMessage({ id: "additional_required_fields_text" })}</Text>
                    </Col>
                    <Col xs={24}>
                      <Row gutter={[16, 24]} style={{ margin: "10px 0 0" }}>
                        {REQUIRED_FIELDS.map((i) => (
                          <Col xs={24} sm={12} md={8} key={i} style={{ paddingTop: 10 }}>
                            <Checkbox size={ANT_CONFIG.size} value={i} onChange={this.onCheckboxClick} checked={required_fields.includes(i)}>
                              {intl.formatMessage({ id: i })}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={24}>
                      <Text strong>{intl.formatMessage({ id: "required_fields_if_not_released_text" })}</Text>
                    </Col>
                    <Col xs={24}>
                      <Row gutter={[16, 24]} style={{ margin: "10px 0 0" }}>
                        {REQUIRED_IF_NOT_RELEASED.map((i) => (
                          <Col xs={24} sm={12} md={8} key={i} style={{ paddingTop: 10 }}>
                            <Checkbox size={ANT_CONFIG.size} value={i} onChange={this.onCheckboxClick} checked={required_fields.includes(i)}>
                              {intl.formatMessage({ id: i })}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Spin>
            </Card>
          </Col>
        </Row>
      <Scribe intl={intl} srcLink="https://scribehow.com/embed/Innstillinger_for_elva__kundeinformasjon_fangstrapportering_kortgrense__jtnzs_c8SdqDsJMEnDjFuw" />

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  rivers: state.Rivers,
  river_required_fields: state.RiverRequiredFields,
});

export default withRouter(injectIntl(connect(mapStateToProps)(RequiredCatchFields)));
