// remember the original fetch-function to delegate to
const originalFetch = global.fetch;

export const applyBaseUrlToFetch = (baseUrl) => {
    // replace the global fetch() with our version where we prefix the given URL with a baseUrl
    global.fetch = (url, options) => {
        const finalUrl = baseUrl + url;
        return originalFetch(finalUrl, options);
    };
};
