import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetchBlueprint(action) {
  try {
    const response = yield call(API.call, "admin/accommodation-blueprint-all", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ACCOMMODATION_BLEUPRINT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ACCOMMODATION_BLEUPRINT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ACCOMMODATION_BLEUPRINT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* addBlueprint(action) {
  try {
    const response = yield call(API.call, `admin/accommodation-blueprint`, "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_ACCOMMODATION_BLEUPRINT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetchBlueprint, action);
      yield put({
        type: ACTION.ADD_ACCOMMODATION_BLEUPRINT_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_ACCOMMODATION_BLEUPRINT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetch(action) {
  try {
    const response = yield call(API.call, "admin/accommodations-all", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ACCOMMODATIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ACCOMMODATIONS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ACCOMMODATIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* editBlueprint(action) {
  try {
    const response = yield call(API.call, "admin/accommodation-blueprint", "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_ACCOMMODATION_BLEUPRINT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield call(fetchBlueprint, action);
      yield put({
        type: ACTION.EDIT_ACCOMMODATION_BLEUPRINT_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_ACCOMMODATION_BLEUPRINT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* add(action) {
  try {
    const response = yield call(API.call, "admin/accommodations", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_ACCOMMODATION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.ADD_ACCOMMODATION_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_ACCOMMODATION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, "admin/accommodations", "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_ACCOMMODATION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.EDIT_ACCOMMODATION_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_ACCOMMODATION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, `admin/accommodation`, "DELETE", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_ACCOMMODATION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.REMOVE_ACCOMMODATION_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_ACCOMMODATION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchAvailable(action) {
  try {
    const response = yield call(API.call, "admin/accommodations-available", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_AVAILABLE_ACCOMMODATIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_AVAILABLE_ACCOMMODATIONS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_AVAILABLE_ACCOMMODATIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchPrices(action) {
  try {
    const response = yield call(API.call, "accommodations-prices", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ACCOMMODATION_PRICES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ACCOMMODATION_PRICES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ACCOMMODATION_PRICES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_ACCOMMODATION_BLEUPRINT, fetchBlueprint),
    takeEvery(ACTION.ADD_ACCOMMODATION_BLEUPRINT, addBlueprint),
    takeEvery(ACTION.EDIT_ACCOMMODATION_BLEUPRINT, editBlueprint),
    takeEvery(ACTION.FETCH_ACCOMMODATIONS, fetch),
    takeEvery(ACTION.ADD_ACCOMMODATION, add),
    takeEvery(ACTION.EDIT_ACCOMMODATION, edit),
    takeEvery(ACTION.REMOVE_ACCOMMODATION, remove),
    takeEvery(ACTION.FETCH_AVAILABLE_ACCOMMODATIONS, fetchAvailable),
    takeEvery(ACTION.FETCH_ACCOMMODATION_PRICES, fetchPrices),
  ]);
}
