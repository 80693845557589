export const FETCH_FISHING_CARDS = "FETCH_FISHING_CARDS";
export const FETCH_FISHING_CARDS_SUCCESS = "FETCH_FISHING_CARDS_SUCCESS";
export const FETCH_FISHING_CARDS_FAILURE = "FETCH_FISHING_CARDS_FAILURE";

export const FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS = "FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS";
export const FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_SUCCESS = "FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_SUCCESS";
export const FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_FAILURE = "FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_FAILURE";

export const ADD_FISHING_CARDS = "ADD_FISHING_CARDS";
export const ADD_FISHING_CARDS_SUCCESS = "ADD_FISHING_CARDS_SUCCESS";
export const ADD_FISHING_CARDS_FAILURE = "ADD_FISHING_CARDS_FAILURE";

export const EDIT_FISHING_CARDS = "EDIT_FISHING_CARDS";
export const EDIT_FISHING_CARDS_SUCCESS = "EDIT_FISHING_CARDS_SUCCESS";
export const EDIT_FISHING_CARDS_FAILURE = "EDIT_FISHING_CARDS_FAILURE";

export const REMOVE_FISHING_CARDS = "REMOVE_FISHING_CARDS";
export const REMOVE_FISHING_CARDS_SUCCESS = "REMOVE_FISHING_CARDS_SUCCESS";
export const REMOVE_FISHING_CARDS_FAILURE = "REMOVE_FISHING_CARDS_FAILURE";

export const FETCH_AVAILABLE_FISHING_CARDS = "FETCH_AVAILABLE_FISHING_CARDS";
export const FETCH_AVAILABLE_FISHING_CARDS_SUCCESS = "FETCH_AVAILABLE_FISHING_CARDS_SUCCESS";
export const FETCH_AVAILABLE_FISHING_CARDS_FAILURE = "FETCH_AVAILABLE_FISHING_CARDS_FAILURE";

export const FETCH_FISHING_CARDS_TYPES = "FETCH_FISHING_CARDS_TYPES";
export const FETCH_FISHING_CARDS_TYPES_SUCCESS = "FETCH_FISHING_CARDS_TYPES_SUCCESS";
export const FETCH_FISHING_CARDS_TYPES_FAILURE = "FETCH_FISHING_CARDS_TYPES_FAILURE";

export const ADD_FISHING_CARDS_TYPE = "ADD_FISHING_CARDS_TYPE";
export const ADD_FISHING_CARDS_TYPE_SUCCESS = "ADD_FISHING_CARDS_TYPE_SUCCESS";
export const ADD_FISHING_CARDS_TYPE_FAILURE = "ADD_FISHING_CARDS_TYPE_FAILURE";

export const EDIT_FISHING_CARDS_TYPE = "EDIT_FISHING_CARDS_TYPE";
export const EDIT_FISHING_CARDS_TYPE_SUCCESS = "EDIT_FISHING_CARDS_TYPE_SUCCESS";
export const EDIT_FISHING_CARDS_TYPE_FAILURE = "EDIT_FISHING_CARDS_TYPE_FAILURE";

export const FETCH_FISHING_CARD_PRICES = "FETCH_FISHING_CARD_PRICES";
export const FETCH_FISHING_CARD_PRICES_SUCCESS = "FETCH_FISHING_CARD_PRICES_SUCCESS";
export const FETCH_FISHING_CARD_PRICES_FAILURE = "FETCH_FISHING_CARD_PRICES_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_FISHING_CARDS,
    payload,
  };
}

export function fetchForBookingCalendar(payload) {
  return {
    type: FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS,
    payload,
  };
}

export function add(data, payload) {
  return {
    type: ADD_FISHING_CARDS,
    data,
    payload,
  };
}

export function edit(data, payload) {
  return {
    type: EDIT_FISHING_CARDS,
    data,
    payload,
  };
}

export function remove(data, payload) {
  return {
    type: REMOVE_FISHING_CARDS,
    data,
    payload,
  };
}

export function fetchAvailable(payload) {
  return {
    type: FETCH_AVAILABLE_FISHING_CARDS,
    payload,
  };
}

export function fetchTypes(payload) {
  return {
    type: FETCH_FISHING_CARDS_TYPES,
    payload,
  };
}

export function addType(data) {
  return {
    type: ADD_FISHING_CARDS_TYPE,
    data,
  };
}

export function editType(data, payload) {
  return {
    type: EDIT_FISHING_CARDS_TYPE,
    data,
    payload,
  };
}

export function fetchPrices(payload) {
  return {
    type: FETCH_FISHING_CARD_PRICES,
    payload,
  };
}
