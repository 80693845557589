export const FETCH_RIVER_ORDERS = "FETCH_RIVER_ORDERS";
export const FETCH_RIVER_ORDERS_SUCCESS = "FETCH_RIVER_ORDERS_SUCCESS";
export const FETCH_RIVER_ORDERS_FAILURE = "FETCH_RIVER_ORDERS_FAILURE";

export const DOWNLOAD_RIVER_ORDERS = "DOWNLOAD_RIVER_ORDERS";
export const DOWNLOAD_RIVER_ORDERS_SUCCESS = "DOWNLOAD_RIVER_ORDERS_SUCCESS";
export const DOWNLOAD_RIVER_ORDERS_FAILURE = "DOWNLOAD_RIVER_ORDERS_FAILURE";

export const CANCEL_RIVER_ORDER = "CANCEL_RIVER_ORDER";
export const CANCEL_RIVER_ORDER_SUCCESS = "CANCEL_RIVER_ORDER_SUCCESS";
export const CANCEL_RIVER_ORDER_FAILURE = "CANCEL_RIVER_ORDER_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_RIVER_ORDERS,
    payload,
  };
}

export function download(payload) {
  return {
    type: DOWNLOAD_RIVER_ORDERS,
    payload,
  };
}

export function cancel(data, payload) {
  return {
    type: CANCEL_RIVER_ORDER,
    data,
    payload,
    meta: {
      thunk: true,
    },
  };
}
