import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, Row, Col, Input, Select, Popconfirm } from "antd";

import { update, remove } from "../../../../redux/extra_services/actions";
import ANT_CONFIG from "../../../../constants/antconfig";

class ModalEditExtraService extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      data: {
        id: null,
        beat_id: props.selectedBeat,
        name: "",
        description: "",
        price: "",
        tax_option_id: null
      }
    };
  }

  componentDidUpdate() {
    const { extra_services } = this.props;
    const { submitted } = this.state;

    if (!extra_services.loading && !extra_services.error && submitted) {
      this.onCancel();
    }
  }

  onChangeValue(newState) {
    this.setState(prevState => ({ data: { ...prevState.data, ...newState } }));
  }

  validate = () => {
    const { name, description, price, tax_option_id, id } = this.state.data;

    return !(!name || !description || !price || !tax_option_id || !id);
  };

  onSubmit = () => {
    const { data } = this.state;
    const { beat_id } = data;

    if (!this.validate()) {
      return false;
    }

    this.setState({
      submitted: true
    });

    this.props.dispatch(
      update(data, {
        beat_id
      })
    );
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  onDelete = () => {
    const { data } = this.state;
    const { beat_id, id } = data;

    this.setState({
      submitted: true
    });

    this.props.dispatch(
      remove(id, {
        beat_id
      })
    );
  };

  selectExtraService = e => {
    const { extra_services } = this.props;
    const item = extra_services.data?.data?.filter(i => i.id === e)[0];

    if (item) {
      const { name, description, price, tax_option_id, id } = item;

      this.setState(prevState => ({
        data: {
          ...prevState.data,
          id,
          name,
          description,
          price,
          tax_option_id
        }
      }));
    }
  };

  render() {
    const { intl, taxes, extra_services } = this.props;
    const { modal } = this.state;
    const { name, description, price, tax_option_id, beat_id, id } = this.state.data;

    if (!beat_id) {
      return;
    }

    return (
      <Modal
        width={800}
        title={intl.formatMessage({ id: "add_extra_service" })}
        visible={modal}
        onCancel={this.onCancel}
        footer={[
          id && (
            <Popconfirm
              key="delete"
              title={intl.formatMessage({ id: "confirm_delete_question" })}
              onConfirm={this.onDelete}
              okText={intl.formatMessage({ id: "remove" })}
              cancelText={intl.formatMessage({ id: "back" })}
            >
              <Button size={ANT_CONFIG.size} loading={extra_services.loading} type="danger">
                {intl.formatMessage({ id: "remove" })}
              </Button>
            </Popconfirm>
          ),
          id && (
            <Button size={ANT_CONFIG.size} disabled={!this.validate()} key="submit" loading={extra_services.loading} type="primary" onClick={this.onSubmit}>
              {intl.formatMessage({ id: "save" })}
            </Button>
          )
        ]}
      >
        <Row type="flex" justify="space-between" gutter={[16, 24]}>
          {extra_services.data?.data && (
            <Col xs={24}>
              <label className="ant-label" htmlFor="select_extra_service">
                {this.props.intl.formatMessage({ id: "extra_service" })}
              </label>
              <Select
                id="select_extra_service"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={`${this.props.intl.formatMessage({ id: "select_extra_service" })}`}
                onChange={this.selectExtraService}
              >
                {extra_services.data?.data.map(i => (
                  <Select.Option key={i.id} value={i.id}>
                    {`${i.name} - ${i.price} NOK`}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}

          {id && (
            <React.Fragment>
              <Col xs={24}>
                <label className="ant-label" htmlFor="price_name">
                  {intl.formatMessage({ id: "name" })}
                </label>
                <Input
                  type="text"
                  name="price_name"
                  id="price_name"
                  size={ANT_CONFIG.size}
                  value={name || ""}
                  onChange={e => this.onChangeValue({ name: e.target.value })}
                />
              </Col>
              <Col xs={24}>
                <label className="ant-label" htmlFor="price_description">
                  {intl.formatMessage({ id: "description" })}
                </label>
                <Input.TextArea
                  name="price_description"
                  id="price_description"
                  size={ANT_CONFIG.size}
                  autoSize={{ minRows: 2 }}
                  value={description || ""}
                  onChange={e => this.onChangeValue({ description: e.target.value })}
                />
              </Col>
              <Col xs={24} sm={12}>
                <label className="ant-label" htmlFor="price">
                  {intl.formatMessage({ id: "price" })}
                </label>
                <Input
                  type="number"
                  name="price"
                  id="price"
                  size={ANT_CONFIG.size}
                  value={price || ""}
                  onChange={e => this.onChangeValue({ price: e.target.value })}
                />
              </Col>
              <Col xs={24} sm={12}>
                {taxes?.data && (
                  <React.Fragment>
                    <label className="ant-label" htmlFor="select_tax">
                      {intl.formatMessage({ id: "tax" })}
                    </label>
                    <Select
                      id="select_tax"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({ id: "select_tax" })}`}
                      onChange={e => this.onChangeValue({ tax_option_id: e })}
                      value={tax_option_id}
                    >
                      {taxes.data.map(i => (
                        <Select.Option key={i.id} value={i.id}>
                          {`${i.key} (${i.value}%)`}
                        </Select.Option>
                      ))}
                    </Select>
                  </React.Fragment>
                )}
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  extra_services: state.ExtraServices,
  taxes: state.Taxes
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalEditExtraService)));
