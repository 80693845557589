import React from "react";
import { Dropdown, Menu } from "antd";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MAIN_BLUE_COLOR, MAIN_GREEN_COLOR } from "../../constants";
import IframeModal from "./iframeModal";
import Modal from "antd/lib/modal/Modal";
function Scribe({ srcLink, hasMultiple = false, items = [] ,intl}) {
  const [shown, setShown] = React.useState(false);
  const [linkAddress, setLinkAddress] = React.useState(srcLink);
  let windowSize=window.screen.width

  const menu = (
    <Menu >
      {items.map((i, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            setLinkAddress(i.link);
            setShown(true);
          }}
        >
          {i.title}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div
      style={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
        backgroundColor: MAIN_GREEN_COLOR,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:'column',
        padding: windowSize > 450 ? "12px 16px" : "6px 12px",
        borderBottomRightRadius: "16px",
        borderTopLeftRadius: "16px",
        zIndex: 1000,
      }}
      onClick={!hasMultiple ? () => setShown(!shown) : null}
    >
      {hasMultiple ? (
        <Dropdown placement="topLeft" overlay={menu} trigger={["click"]}>
          <a>
            <FontAwesomeIcon
              icon={faBookOpen}
              size={windowSize > 450 ? "4x" : "2x"}
              style={{
                color: MAIN_BLUE_COLOR,
                cursor: "pointer",
              }}
            />
            <div>{intl.formatMessage({ id: "help_text" })}</div>
          </a>
        </Dropdown>
      ) : (
        <>
                <FontAwesomeIcon
          icon={faBookOpen}
          size={windowSize > 450 ? "4x" : "2x"}
          style={{
            color: MAIN_BLUE_COLOR,
            cursor: "pointer",
          }}
        />
        <div>{intl.formatMessage({ id: "help_text" })}</div>
        </>


      )}

      <Modal
        open={shown}
        destroyOnClose
        onCancel={() => {
          setShown(false);
        }}
        onOk={() => {
          setShown(false);
        }}
        style={{ top: 10 }}
        width="100vw"
        footer={null}
      >
        <IframeModal src={linkAddress} />
      </Modal>
    </div>
  );
}

export default Scribe;
