export const FETCH_DASHBOARD_BEAT_ADMIN_DAILY_ACCOMMODATION = "FETCH_DASHBOARD_BEAT_ADMIN_DAILY_ACCOMMODATION";
export const FETCH_DASHBOARD_BEAT_ADMIN_DAILY_ACCOMMODATION_SUCCESS = "FETCH_DASHBOARD_BEAT_ADMIN_DAILY_ACCOMMODATION_SUCCESS";
export const FETCH_DASHBOARD_BEAT_ADMIN_DAILY_ACCOMMODATION_FAILURE = "FETCH_DASHBOARD_BEAT_ADMIN_DAILY_ACCOMMODATION_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_BEAT_ADMIN_DAILY_ACCOMMODATION,
    payload
  };
}
