import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  filtered_data: undefined,
  unfiltered_data: undefined,
  file_loading: false,
  file_error: false,
  file: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_ALL_ECONOMIC_BEAT_REPORTS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_ALL_ECONOMIC_BEAT_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS:
      return {
        ...state,
        file_loading: true,
      };
    case ACTION.DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS:
      return {
        ...state,
        file_loading: false,
      };


    case ACTION.FETCH_EXPORT_BILLING_FAILURE:
      return {
        ...state,
        file_loading: false,
        error: true,
      };

    case ACTION.FETCH_PLATFORM_REPORTS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_PLATFORM_REPORTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_PLATFORM_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_BEAT_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_BEAT_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_BEAT_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.DOWNLOAD_ALL_BEAT_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_ALL_BEAT_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_ALL_BEAT_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        file_loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_PLATFORM_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_PLATFORM_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_PLATFORM_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_PRODUCT_COUNT_REPORT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_PRODUCT_COUNT_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_PRODUCT_COUNT_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_PRODUCT_COUNT_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_PRODUCT_COUNT_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_PRODUCT_COUNT_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_PRODUCT_TYPE_AND_PRICE_REPORT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_SELLER_PAYMENT_METHOD_REPORT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_SELLER_PAYMENT_METHOD_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_SELLER_PAYMENT_METHOD_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_FISHING_CARDS_REPORT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FILTER_FISHING_CARDS_REPORT:
      return {
        ...state,
        filtered_data: action.payload,
        loading: false,
        error: false,
      };

    case ACTION.FILTER_SEASON_CARDS_REPORT:
      return {
        ...state,
        filtered_data: action.payload,
        loading: false,
        error: false,
      };
    case ACTION.FETCH_FISHING_CARDS_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        filtered_data: Object.values(action.payload.data.results).flat(),
        unfiltered_data: Object.values(action.payload.data.results).flat(),
        loading: false,
        error: false,
      };

    case ACTION.FETCH_FISHING_CARDS_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_FISHING_CARDS_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_FISHING_CARDS_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_FISHING_CARDS_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_RIVER_CARDS_REPORT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_RIVER_CARDS_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_RIVER_CARDS_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_RIVER_CARDS_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_RIVER_CARDS_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_RIVER_CARDS_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_SEASON_CARDS_REPORT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_SEASON_CARDS_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        filtered_data: action.payload.data.results,
        unfiltered_data: action.payload.data.results,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_SEASON_CARDS_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_SEASON_CARDS_REPORT:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_SEASON_CARDS_REPORT_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_SEASON_CARDS_REPORT_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_ECONOMY_BEAT_REPORT:
      return {
        ...state,
        loading: true,
      };
    case ACTION.FETCH_ECONOMY_BEAT_REPORT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_ECONOMY_BEAT_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
