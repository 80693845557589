import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/customer/banned-cards/${action.id}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_CUSTOMER_BLOCK_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_CUSTOMER_BLOCK_CARDS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_CUSTOMER_BLOCK_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* ban(action) {
  try {
    const response = yield call(API.call, "admin/ban-user", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.BAN_USER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.BAN_USER_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.BAN_USER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* unban(action) {
  try {
    const response = yield call(API.call, "admin/lift-ban", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UNBAN_USER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UNBAN_USER_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UNBAN_USER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_CUSTOMER_BLOCK_CARDS, fetch), takeEvery(ACTION.BAN_USER, ban), takeEvery(ACTION.UNBAN_USER, unban)]);
}
