import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, "auth/login", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.AUTH_LOGIN_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.AUTH_LOGIN_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.AUTH_LOGIN_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* check() {
  try {
    const response = yield call(API.call, "auth/me", "POST");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.AUTH_CHECK_FAILURE,
        message: responseBody.errors,
      });
    } else {
      yield put({
        type: ACTION.AUTH_CHECK_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.AUTH_CHECK_FAILURE,
      message: e.errors,
    });
  }
}

function* refresh() {
  try {
    const response = yield call(API.call, "auth/refresh", "POST");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.AUTH_REFRESH_TOKEN_FAILURE,
        message: responseBody.errors,
      });
    } else {
      yield put({
        type: ACTION.AUTH_REFRESH_TOKEN_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.AUTH_REFRESH_TOKEN_FAILURE,
      message: e.errors,
    });
  }
}

function* forgotPassword(action) {
  try {
    const response = yield call(API.call, "auth/password/admin", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.AUTH_FORGOT_PASSWORD_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.AUTH_FORGOT_PASSWORD_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.AUTH_FORGOT_PASSWORD_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* resetPassword(action) {
  try {
    const response = yield call(API.call, "auth/password/reset", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.AUTH_RESET_PASSWORD_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.AUTH_RESET_PASSWORD_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.AUTH_RESET_PASSWORD_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* logout() {
  try {
    const response = yield call(API.call, "auth/logout", "POST");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.AUTH_LOGOUT_FAILURE,
        message: responseBody.errors,
      });
    } else {
      yield put({
        type: ACTION.AUTH_LOGOUT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.AUTH_LOGOUT_FAILURE,
      message: e.errors,
    });
  }
}

function* editUser(action) {
  try {
    const response = yield call(API.call, "auth/update", "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_USER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(check);
      yield put({
        type: ACTION.EDIT_USER_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_USER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* changePassword(action) {
  try {
    const response = yield call(API.call, "auth/change-password", "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.CHANGE_PASSWORD_FAILURE,
        message: responseBody.errors,
        payload: responseBody,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.CHANGE_PASSWORD_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.CHANGE_PASSWORD_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.AUTH_LOGIN, fetch),
    takeEvery(ACTION.AUTH_CHECK, check),
    takeEvery(ACTION.AUTH_REFRESH_TOKEN, refresh),
    takeEvery(ACTION.AUTH_FORGOT_PASSWORD, forgotPassword),
    takeEvery(ACTION.AUTH_RESET_PASSWORD, resetPassword),
    takeEvery(ACTION.AUTH_LOGOUT, logout),
    takeEvery(ACTION.EDIT_USER, editUser),
    takeEvery(ACTION.CHANGE_PASSWORD, changePassword),
  ]);
}
