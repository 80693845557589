import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Table, Card, Col, Row, PageHeader, Input, Button } from "antd";

import Spinner from "../../components/spinner";
import ANT_CONFIG from "../../constants/antconfig";
import { fetch as fetchBeats } from "../../redux/beats/actions";
import { ADMIN } from "../../constants";
import { authCheck } from "../../redux/authentication/actions";
import AddNewBeat from "../../components/modal/Beat/AddNewBeat";

class Beats extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";

    this.state = {
      filter: "",
      showModal: false,
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (this.props.authentication.data.beat.length === 1) {
      this.props.history.push(`/content/beat/${this.props.authentication.data.beat[0]}`);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.beats.data !== this.props.beats.data && this.props.beats.data) {
      this.props.dispatch(authCheck());
    }
  }

  onRowClick(id) {
    this.props.history.push(`/content/beat/${id}`);
  }

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  handleFilterChange(filter) {
    this.setState({
      filter,
    });
  }

  render() {
    if (!this.props.beats.data) {
      return <Spinner />;
    }

    let { data } = this.props.beats;
    const isAdmin = this.props.authentication.data.roles.includes(ADMIN);

    data = data.filter((i) => this.props.authentication.data.beat.includes(i.id));

    if (this.state.filter) {
      data = data.filter((i) => {
        const filter = this.state.filter.toLowerCase();

        return (
          i.id?.toString().includes(filter) ||
          i.name?.toLowerCase().includes(filter) ||
          i.river?.name?.toLowerCase().includes(filter) ||
          i.river?.county?.name?.toLowerCase().includes(filter)
        );
      });
    }

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: this.props.intl.formatMessage({ id: "name" }),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this.props.intl.formatMessage({ id: "river" }),
        dataIndex: "river",
        key: "river",
      },
      {
        title: this.props.intl.formatMessage({ id: "county" }),
        dataIndex: "county",
        key: "county",
      },
    ];

    const tableData = data.map((i, key) => {
      return {
        key: key + 1,
        id: i.id,
        name: i.name,
        river: i.river?.name || "-",
        county: i.river?.county?.name || "-",
      };
    });

    return (
      <div>
        <PageHeader
          title={this.props.intl.formatMessage({ id: "beats" })}
          extra={
            isAdmin && [
              <Button onClick={this.showModal} loading={this.props.beats.loading} type="primary" key="add_new_beat" size={ANT_CONFIG.size}>
                {this.props.intl.formatMessage({ id: "add_new_beat" })}
              </Button>,
            ]
          }
        >
          <AddNewBeat visible={this.state.showModal} cancel={this.hideModal} />

          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search" className="ant-label">
                    {this.props.intl.formatMessage({ id: "search" })}
                  </label>
                  <Input
                    placeholder={this.props.intl.formatMessage({ id: "search" })}
                    id="search"
                    size={ANT_CONFIG.size}
                    value={this.state.filter}
                    onChange={(e) => this.handleFilterChange(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
                onRow={(record) => {
                  return {
                    onClick: () => this.onRowClick(record.id),
                  };
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  beats: state.Beats,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Beats)));
