import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Card, Col, Row, PageHeader, Button, Input, Tabs, Checkbox, Alert } from "antd";
import { Marker, Polygon } from "react-google-maps";

import { fetch, update, addSellingBeat, removeSellingBeat } from "../../redux/beat/actions";
import { fetch as flyTypesFetch } from "../../redux/flytypes/actions";
import { fetch as facilityTypesFetch } from "../../redux/facilities/actions";
import Spinner from "../../components/spinner";
import GenericError from "../Errors/GenericError";
import CollapsedCard from "../../components/Card/CollapsedCard";
import ANT_CONFIG from "../../constants/antconfig";
import { DEFAULT_LANGUAGE, DEFAULT_POLYGON_STYLE, GOOGLE_MAP_DEFAULT_POSITION } from "../../constants";
import HTMLEditor from "../../components/HTMLEditor/HTMLEditor";
import Map from "../../components/Map/Map";
import MarkerList from "../../components/Map/MarkerList";
import LanguageDropdown from "../../components/LanguageDropdown/LanguageDropdown";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import AddMarkerModal from "../../components/modal/Map/AddMarkerModal";
import AddPolygonModal from "../../components/modal/Map/AddPolygonModal";
import AddCenterModal from "../../components/modal/Map/AddCenterModal";
import RolesContext from "../../context/RolesContext";
import Counties from "../../components/Counties/Counties";
import Countries from "../../components/Countries/Countries";
import BeatSelect from "../../components/BeatSelect";
import { Table } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Scribe from "../../components/Scribe";

const TABS_TO_HIDE = ["about_beat", "fishing_card", "packs", "accommodation", "map", "about_us", "other_facilities"];

const TABS_INFO = [
  {
    key: "general",
    forAdmin: false,
  },
  {
    key: "fishing_types_and_facilities",
    forAdmin: false,
  },
  {
    key: "images",
    forAdmin: false,
  },
  {
    key: "map",
    forAdmin: false,
  },
  {
    key: "seo",
    forAdmin: true,
  },
  {
    key: "additional_settings",
    forAdmin: false,
  },
  {
    key: "fishing_spot",
    forAdmin: false,
  },
  {
    key: "selling_beats",
    forAdmin: false,
  },
];

class Page extends React.Component {
  static contextType = RolesContext;

  state = {
    activeTab: "general",
    language: DEFAULT_LANGUAGE,
    data: null,
    active: null,
    showAddMarkerModal: false,
    showAddPolygonModal: false,
    showAddCenterModal: false,
    defaultPosition: GOOGLE_MAP_DEFAULT_POSITION,
    beatIDsBeingRemoved: [],
    sellingBeatsTable: {
      columns: null,
      data: null,
    },
  };

  componentDidMount() {
    this.props.dispatch(fetch(this.props.match.params.id));

    if (!this.props.flytypes.data) {
      this.props.dispatch(flyTypesFetch());
    }

    if (!this.props.facilities.data) {
      this.props.dispatch(facilityTypesFetch());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.beat.data !== this.props.beat.data && this.props.beat.data) {
      this.setFetchedData(this.props.beat.data);
    }
  }

  onSave = () => {
    let updatedState = this.state.data;

    if (this.state.data.countries.some((c) => c.country_name)) {
      updatedState = { ...this.state.data, countries: this.state.data.countries?.map((c) => c.id) };
    }
    if (this.state.data.counties.some((c) => c.name)) {
      updatedState = { ...updatedState, counties: this.state.data.counties?.map((c) => c.id) };
    }

    const data = {
      ...updatedState,
      update_data: 1,
    };

    this.hideModal();

    this.props.dispatch(update(data, this.props.match.params.id));
  };

  setFetchedData(data) {
    this.setState({
      data: {
        name: data.name || "",
        latitude: data.latitude,
        longitude: data.longitude,
        objects_translations: data.objects_translations || [],
        contact_person: data.contact_person || "",
        contact_phone: data.contact_phone || "",
        contact_email: data.contact_email || "",
        countries: data.countries,
        counties: data.counties,
        map_data: data?.map_data || [],
        length: data.length || "",
        fly_types: data.fly_types || [],
        facilities: data.facilities || [],
        active_on_web: data.active_on_web || 0,
        active_on_laksebors: data.active_on_laksebors || 0,
        show_on_laksebors: data.show_on_laksebors || 0,
        confirmation_on_new_orders: data.confirmation_on_new_orders || 0,
        disabled_tabs: data.disabled_tabs || [],
        featured_image: data.featured_image
          ? [
              {
                id: 1,
                url: data.featured_image,
              },
            ]
          : [],
        gallery_images: data.gallery_images || [],
        send_email_feature_active: data.send_email_feature_active,
        send_email_feature_limit: data.send_email_feature_limit,
        fishing_spots: data.fishing_spots || [],
      },
    });

    if (data.latitude && data.longitude) {
      this.setState({
        defaultPosition: {
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
        },
      });
    }
  }

  setData(data) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        ...data,
      },
    }));
  }

  setDisabledTabs(tab) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        disabled_tabs: prevState.data.disabled_tabs.includes(tab)
          ? prevState.data.disabled_tabs.filter((i) => i !== tab)
          : [...prevState.data.disabled_tabs, tab],
      },
    }));
  }

  setObjectTranslations(data, context, language) {
    const dataToChange = this.state.data?.objects_translations?.filter(
      (i) => i.language === language && i.key === context,
    )?.[0];

    if (!dataToChange) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              key: context,
              value: data,
              language,
            },
          ],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              ...dataToChange,
              value: data,
            },
          ],
        },
      }));
    }
  }

  setLanguage = (language) => {
    this.setState({
      language,
    });
  };

  setFlyType(item, value) {
    if (value) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          fly_types: [...prevState.data.fly_types, item],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          fly_types: [...prevState.data.fly_types.filter((i) => i.id !== item.id)],
        },
      }));
    }
  }

  setFacilities(item, value) {
    if (value) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          facilities: [...prevState.data.facilities, item],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          facilities: [...prevState.data.facilities.filter((i) => i.id !== item.id)],
        },
      }));
    }
  }

  saveCenterMarker = (marker) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          latitude: marker.latitude,
          longitude: marker.longitude,
        },
      }),
      () => {
        this.onSave();
      },
    );
  };

  saveNewMarker = (marker) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          map_data: [...prevState.data?.map_data, marker],
        },
      }),
      () => {
        this.onSave();
      },
    );
  };

  removeMarker = (id) => {
    this.setState((prevState) => ({
      active: null,
      data: {
        ...prevState.data,
        map_data: [...prevState.data?.map_data.filter((i) => i.id !== id)],
      },
    }));

    this.props.dispatch(
      update(
        {
          remove_marker: {
            id,
          },
        },
        this.props.match.params.id,
      ),
    );
  };

  saveNewPolygon = (polygon) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          map_data: [
            ...prevState.data?.map_data,
            {
              ...polygon,
              latitude: null,
              longitude: null,
              poly_style: JSON.stringify(polygon.poly_style || DEFAULT_POLYGON_STYLE),
            },
          ],
        },
      }),
      () => {
        this.onSave();
      },
    );
  };

  onTabChange = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  addFeatureImage = (file) => {
    if (file?.response?.data?.default) {
      this.props.dispatch(
        update(
          {
            featured_image: file.response.data.default,
          },
          this.props.match.params.id,
        ),
      );
    }
  };

  addSeoFeatureImage = (file) => {
    if (file?.response?.data?.default) {
      this.props.dispatch(
        update(
          {
            seo_featured_image: {
              key: "seo_featured_image",
              value: file.response.data.default,
              language: this.state.language,
            },
          },
          this.props.match.params.id,
        ),
      );
    }
  };

  removeFeatureImage = () => {
    this.props.dispatch(
      update(
        {
          remove_featured_image: true,
        },
        this.props.match.params.id,
      ),
    );
  };

  removeSeoFeatureImage = () => {
    this.props.dispatch(
      update(
        {
          seo_featured_image: {
            key: "seo_featured_image",
            value: "",
            language: this.state.language,
          },
        },
        this.props.match.params.id,
      ),
    );
  };

  addGalleryImage = (file) => {
    if (file.response?.data?.default && file.status === "done") {
      this.props.dispatch(
        update(
          {
            add_gallery_image: {
              url: file.response.data.default,
            },
          },
          this.props.match.params.id,
        ),
      );
    }
  };

  removeGalleryImage = (file) => {
    this.props.dispatch(
      update(
        {
          remove_gallery_image: {
            url: file.file || file.response?.data?.default,
          },
        },
        this.props.match.params.id,
      ),
    );
  };

  activeMarker = (active = null) => {
    this.setState({
      active,
    });
  };

  showAddMarkerModal = () => {
    this.setState({
      showAddMarkerModal: true,
    });
  };

  showAddPolygonModal = () => {
    this.setState({
      showAddPolygonModal: true,
    });
  };

  showAddCenterModal = () => {
    this.setState({
      showAddCenterModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      showAddMarkerModal: false,
      showAddCenterModal: false,
      showAddPolygonModal: false,
    });
  };

  handleCountyChange = (e) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        counties: e,
      },
    }));
  };

  handleCountryChange = (e) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        countries: e,
      },
    }));
  };

  filterData = (b) => {
    const activeOnWeb = b.active_on_web === 1;
    const sameRiver = b.river_id === this.props.beat?.data?.river_id;
    const sameBeat = b.id === this.props.beat?.data?.id;

    if (activeOnWeb && sameRiver && !sameBeat) return true;

    return false;
  };

  handleBeatChange = (selectedBeats) => {
    const newBeat = selectedBeats.find((x) => !this.state?.selling_beats?.includes(x));

    this.props.dispatch(
      addSellingBeat({
        reporting_beat_id: this.props.match.params.id,
        selling_beat_id: newBeat.id,
      }),
    );

    this.setState({
      selling_beats: selectedBeats,
    });
  };

  removeSellingBeat = (id) => {
    this.props.dispatch(
      removeSellingBeat({
        reporting_beat_id: this.props.match.params.id,
        selling_beat_id: id,
      }),
    );

    this.setState((prevState) => ({
      selling_beats: prevState.selling_beats?.filter((b) => b.id !== id),
    }));
  };

  render() {
    if (
      (!this.props.beat.loading && this.props.beat.error) ||
      !this.props.authentication.data?.beat?.includes(parseInt(this.props.match.params.id, 10))
    ) {
      return <GenericError />;
    }

    if (!this.state.data) {
      return <Spinner />;
    }

    const { isAdmin } = this.context;
    const { intl } = this.props;

    const sellingBeatsColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: this.props.intl.formatMessage({ id: "name" }),
        dataIndex: "name",
        key: "name",
      },
      {
        title: "",
        dataIndex: "remove",
        key: "remove",
      },
    ];

    const sellingBeatsData = this.props.beats?.data
      ?.filter((b) => {
        return this.filterData && this.props.beat.data.selling_beats?.map((beat) => beat.id).includes(b.id);
      })
      ?.map((b, key) => {
        const showLoading = this.props.beat.sellingBeatsBeingRemoved?.includes(b.id);

        return {
          key: key + 1,
          id: b.id,
          name: b.name,
          remove: showLoading ? (
            <Button type="danger" disabled={showLoading} icon={<LoadingOutlined spin={showLoading} />} size={ANT_CONFIG.size} />
          ) : (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size={ANT_CONFIG.size}
              onClick={() => this.removeSellingBeat(b.id)}
            />
          ),
        };
      });

    const tabs = (
      <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange}>
        {TABS_INFO?.map(
          (t) =>
            (!t.forAdmin || (t.forAdmin && isAdmin)) && <Tabs.TabPane tab={intl.formatMessage({ id: t.key })} key={t.key} />,
        )}
      </Tabs>
    );

    return (
      <div>
        <PageHeader
          title={this.props.beat.data?.name}
          footer={tabs}
          extra={[
            <Button onClick={this.onSave} loading={this.props.beat.loading} type="primary" key="save" size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
            <LanguageDropdown key="languageDropdown" setLanguage={this.setLanguage} />,
          ]}
        />

        <div style={this.state.activeTab !== "general" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "name" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    id="name"
                    type="text"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.name || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        name: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "country" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Countries handleChange={this.handleCountryChange} current={this.state.data.countries} />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "county" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Counties handleChange={this.handleCountyChange} current={this.state.data.counties} />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "content" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "content" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) => this.setObjectTranslations(editor.getData(), "content", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "information" })}>
            <div className="container-900">
              <Row gutter={30}>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="contact_person">
                    {intl.formatMessage({ id: "contact_person" })}
                  </label>
                  <Input
                    id="contact_person"
                    type="text"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.contact_person || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        contact_person: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="contact_phone">
                    {intl.formatMessage({ id: "contact_phone" })}
                  </label>
                  <Input
                    type="tel"
                    id="contact_phone"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.contact_phone || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        contact_phone: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="contact_email">
                    {intl.formatMessage({ id: "contact_email" })}
                  </label>
                  <Input
                    type="email"
                    id="contact_email"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.contact_email || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        contact_email: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="beat_length">
                    {intl.formatMessage({ id: "beat_length" })}
                  </label>
                  <Input
                    type="number"
                    id="beat_length"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.length || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        length: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "about_us" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "about_us" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) => this.setObjectTranslations(editor.getData(), "about_us", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "other_facilities" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "other_facilities" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) =>
                      this.setObjectTranslations(editor.getData(), "other_facilities", this.state.language)
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "accommodation" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "accommodation" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) =>
                      this.setObjectTranslations(editor.getData(), "accommodation", this.state.language)
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== "fishing_types_and_facilities" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "fishing_types" })}>
            <div className="container-900">
              <Row gutter={[16, 16]}>
                {this.props.flytypes.loading && (
                  <Col xs={24}>
                    <Spinner />
                  </Col>
                )}
                {this.props.flytypes.data?.map((i) => (
                  <Col xs={24} lg={8} key={i.id}>
                    <Checkbox
                      size={ANT_CONFIG.size}
                      checked={!!this.state.data.fly_types?.filter((f) => f.id === i.id)?.[0]}
                      onChange={(e) => this.setFlyType(i, e.target.checked)}
                    >
                      {intl.formatMessage({ id: "flytype-" + i.slug })}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "facilities" })}>
            <div className="container-900">
              <Row gutter={[16, 16]}>
                {this.props.facilities.loading && (
                  <Col xs={24}>
                    <Spinner />
                  </Col>
                )}
                {this.props.facilities.data?.map((i) => (
                  <Col xs={24} lg={8} key={i.id}>
                    <Checkbox
                      size={ANT_CONFIG.size}
                      checked={!!this.state.data.facilities?.filter((f) => f.id === i.id)?.[0]}
                      onChange={(e) => this.setFacilities(i, e.target.checked)}
                    >
                      {intl.formatMessage({ id: "facility-" + i.slug })}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== "images" ? { display: "none" } : {}}>
          <Card style={{ marginTop: 20, marginBottom: 20 }} title={intl.formatMessage({ id: "featured_image" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  data={this.state.data.featured_image}
                  limit={1}
                  onFileRemove={this.removeFeatureImage}
                  onFileAdd={this.addFeatureImage}
                />
              </Col>
            </Row>
          </Card>

          <Card style={{ marginTop: 20, marginBottom: 20 }} title={intl.formatMessage({ id: "image_gallery" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  multiple
                  data={this.state.data.gallery_images}
                  onFileRemove={this.removeGalleryImage}
                  onFileAdd={this.addGalleryImage}
                />
              </Col>
            </Row>
          </Card>
        </div>

        <div style={this.state.activeTab !== "map" ? { display: "none" } : {}}>
          <PageHeader
            title=""
            className="ant-page-header-for-maps"
            extra={[
              <Button onClick={this.showAddCenterModal} disabled={this.props.beat.loading} key="add_center_point">
                {intl.formatMessage({ id: "add_center_point" })}
              </Button>,
              <Button
                onClick={this.showAddMarkerModal}
                disabled={this.props.beat.loading || !this.props.beat.data.latitude || !this.props.beat.data.longitude}
                key="add_marker"
              >
                {intl.formatMessage({ id: "add_marker" })}
              </Button>,
              <Button
                onClick={this.showAddPolygonModal}
                disabled={this.props.beat.loading || !this.props.beat.data.latitude || !this.props.beat.data.longitude}
                key="add_polygon"
              >
                {intl.formatMessage({ id: "add_polygon" })}
              </Button>,
            ]}
          />

          <AddCenterModal
            position={this.state.defaultPosition}
            save={this.saveCenterMarker}
            cancel={this.hideModal}
            show={this.state.showAddCenterModal}
          />

          <AddMarkerModal
            position={this.state.defaultPosition}
            save={this.saveNewMarker}
            cancel={this.hideModal}
            show={this.state.showAddMarkerModal}
          />

          <AddPolygonModal
            position={this.state.defaultPosition}
            save={this.saveNewPolygon}
            cancel={this.hideModal}
            show={this.state.showAddPolygonModal}
          />

          {(!this.props.beat.data.latitude || !this.props.beat.data.longitude) && (
            <Card style={{ marginTop: 20, marginBottom: 20 }}>
              <Alert
                message={`${intl.formatMessage({ id: "remember" })}!`}
                description={intl.formatMessage({
                  id: "add_center_points_first",
                })}
                type="info"
                showIcon
              />
            </Card>
          )}

          <Card style={{ marginTop: 20, marginBottom: 20 }}>
            <Row>
              <Col xs={24}>
                <Map defaultPosition={this.state.defaultPosition}>
                  {this.props.beat.data.latitude && this.props.beat.data.longitude && !this.state.active && (
                    <Marker
                      position={{
                        lat: parseFloat(this.props.beat.data.latitude),
                        lng: parseFloat(this.props.beat.data.longitude),
                      }}
                    />
                  )}

                  {this.state.data?.map_data &&
                    this.state.data?.map_data?.map((i, key) => {
                      if (i.type === "marker") {
                        const icon = i.icon || "default";

                        if (this.state.active) {
                          if (this.state.active === i.id) {
                            return (
                              <Marker
                                key={`${i.id}-${key}`}
                                position={{
                                  lat: parseFloat(i.latitude),
                                  lng: parseFloat(i.longitude),
                                }}
                                icon={`/images/map/${icon}.svg`}
                              />
                            );
                          }

                          return false;
                        }

                        return (
                          <Marker
                            key={`${i.id}-${key}`}
                            position={{
                              lat: parseFloat(i.latitude),
                              lng: parseFloat(i.longitude),
                            }}
                            icon={`/images/map/${icon}.svg`}
                          />
                        );
                      }

                      if (i.type === "polygon") {
                        const cords = JSON.parse(i.poly_coordinates);
                        const style = i.poly_style ? JSON.parse(i.poly_style) : null;

                        if (this.state.active) {
                          if (this.state.active === i.id) {
                            return <Polygon key={`${i.id}-${key}`} paths={cords[0]} options={style || DEFAULT_POLYGON_STYLE} />;
                          }

                          return false;
                        }

                        return <Polygon key={`${i.id}-${key}`} paths={cords[0]} options={style || DEFAULT_POLYGON_STYLE} />;
                      }

                      return false;
                    })}
                </Map>
              </Col>
            </Row>
          </Card>

          {this.state.data?.map_data && (
            <Card style={{ marginTop: 20, marginBottom: 20 }}>
              <Row>
                <Col xs={24}>
                  {this.props.beat.loading ? (
                    <Spinner />
                  ) : (
                    <MarkerList
                      activeMarker={this.activeMarker}
                      data={this.state.data?.map_data}
                      onMarkerRemove={this.removeMarker}
                    />
                  )}
                </Col>
              </Row>
            </Card>
          )}
        </div>

        <div style={this.state.activeTab !== "seo" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "title" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    type="text"
                    id="title"
                    size={ANT_CONFIG.size}
                    value={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "seo_title" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_title", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "description" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input.TextArea
                    name="description"
                    id="description"
                    autoSize={{ minRows: 3 }}
                    size={ANT_CONFIG.size}
                    value={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "seo_description" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_description", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "featured_image" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  data={
                    this.state.data.objects_translations?.filter(
                      (i) => i.key === "seo_featured_image" && i.language === this.state.language,
                    )?.[0]?.value
                      ? [
                          {
                            id: 1,
                            url: this.state.data.objects_translations?.filter(
                              (i) => i.key === "seo_featured_image" && i.language === this.state.language,
                            )[0].value,
                          },
                        ]
                      : []
                  }
                  limit={1}
                  onFileRemove={this.removeSeoFeatureImage}
                  onFileAdd={this.addSeoFeatureImage}
                />
              </Col>
            </Row>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "additional_information" })}>
            <Row>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={
                    this.state.data.objects_translations?.filter(
                      (i) => i.key === "seo_noindex" && i.language === this.state.language && parseInt(i.value, 10),
                    ).length > 0
                      ? this.state.data.objects_translations?.filter(
                          (i) => i.key === "seo_noindex" && i.language === this.state.language && parseInt(i.value, 10),
                        )?.[0].value
                      : 0
                  }
                  onChange={(e) => this.setObjectTranslations(e.target.checked ? 1 : 0, "seo_noindex", this.state.language)}
                >
                  {intl.formatMessage({ id: "hide_from_crawlers" })}
                </Checkbox>
              </Col>
            </Row>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== "additional_settings" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "additional_settings" })}>
            <Row type="flex" gutter={[20, 20]}>
              {isAdmin && (
                <>
                  <Col xs={24}>
                    <Checkbox
                      size={ANT_CONFIG.size}
                      checked={this.state.data.active_on_web}
                      onChange={(e) =>
                        this.setData({
                          active_on_web: e.target.checked ? 1 : 0,
                        })
                      }
                    >
                      {intl.formatMessage({ id: "active_on_web" })}
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      size={ANT_CONFIG.size}
                      checked={this.state.data.active_on_laksebors}
                      onChange={(e) =>
                        this.setData({
                          active_on_laksebors: e.target.checked ? 1 : 0,
                        })
                      }
                    >
                      {intl.formatMessage({ id: "active_on_laksebors" })}
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      size={ANT_CONFIG.size}
                      checked={this.state.data.show_on_laksebors}
                      onChange={(e) =>
                        this.setData({
                          show_on_laksebors: e.target.checked ? 1 : 0,
                        })
                      }
                    >
                      {intl.formatMessage({ id: "show_on_laksebors" })}
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      size={ANT_CONFIG.size}
                      checked={this.state.data.send_email_feature_active}
                      onChange={(e) =>
                        this.setData({
                          send_email_feature_active: e.target.checked ? 1 : 0,
                        })
                      }
                    >
                      {intl.formatMessage({ id: "send_email" })}
                    </Checkbox>
                  </Col>
                  {this.state.data.send_email_feature_active ? (
                    <Col xs={24} md={12} lg={6}>
                      <label className="ant-label" htmlFor="limit">
                        {intl.formatMessage({ id: "limit" })}
                      </label>
                      <Input
                        type="number"
                        id="limit"
                        size={ANT_CONFIG.size}
                        value={this.state.data?.send_email_feature_limit || ""}
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.setData({
                            send_email_feature_limit: e.target.value,
                          })
                        }
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                </>
              )}
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={this.state.data.confirmation_on_new_orders}
                  onChange={(e) =>
                    this.setData({
                      confirmation_on_new_orders: e.target.checked ? 1 : 0,
                    })
                  }
                >
                  {intl.formatMessage({ id: "confirmation_on_new_orders" })}
                </Checkbox>
              </Col>
            </Row>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "hide_unused_tabs" })}>
            <Row type="flex" gutter={[20, 20]}>
              {TABS_TO_HIDE?.map((i) => (
                <Col xs={24} key={i}>
                  <Checkbox
                    size={ANT_CONFIG.size}
                    checked={this.state.data.disabled_tabs?.includes(i)}
                    onChange={() => this.setDisabledTabs(i)}
                  >
                    {intl.formatMessage({ id: i })}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== "fishing_spot" ? { display: "none" } : {}}>
          <Card>
            <p>
              <b>{this.props.intl.formatMessage({ id: "fishing_spot_title" })}</b>
            </p>
            <p>{this.props.intl.formatMessage({ id: "fishing_spot_description" })}</p>
            <div className="container-900">
              {this.state.data.fishing_spots?.map((spot, index) => (
                <Card>
                  <Row type="flex" gutter={[20, 10]}>
                    <Col xs={12}>
                      <Input
                        type="text"
                        id="title"
                        size={ANT_CONFIG.size}
                        value={spot?.name}
                        onChange={(e) => {
                          const fishingSpots = this.state.data.fishing_spots;
                          const newSpot = fishingSpots[index];
                          newSpot.name = e.target.value;
                          fishingSpots[index] = newSpot;
                          this.setData({
                            ...this.state.data,
                            fishing_spots: fishingSpots,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={12}>
                      <Button
                        onClick={() => {
                          const fishingSpots = this.state.data.fishing_spots;
                          fishingSpots.splice(index, 1);
                          this.setData({
                            ...this.state.data,
                            fishing_spots: fishingSpots,
                          });
                        }}
                        type="danger"
                      >
                        {this.props.intl.formatMessage({ id: "delete" })}
                      </Button>
                    </Col>
                  </Row>
                </Card>
              ))}
              <Card>
                <Row>
                  <Button
                    onClick={() => {
                      const fishingSpots = this.state.data.fishing_spots;
                      fishingSpots.push({ name: "" });
                      this.setData({
                        ...this.state.data,
                        fishing_spots: fishingSpots,
                      });
                    }}
                    type="primary"
                  >
                    {intl.formatMessage({ id: "add" })}
                  </Button>
                </Row>
              </Card>
            </div>
          </Card>
        </div>

        <div style={this.state.activeTab !== "selling_beats" ? { display: "none" } : {}}>
          <Card>
            <div className="container-900">
              <Row>
                <BeatSelect
                  onChange={(_, selectedBeats) => {
                    if (this.props.beat?.data?.selling_beats?.length > selectedBeats?.length) {
                      const removedBeat = this.props?.beat?.data?.selling_beats?.find((b) => !selectedBeats.includes(b.id));

                      this.removeSellingBeat(removedBeat.id);
                      return;
                    }

                    this.handleBeatChange(selectedBeats);
                  }}
                  selected={this.props.beat.data.selling_beats?.map((b) => b.id) || []}
                  label="choose_beats"
                  mode="multiple"
                  filterData={this.filterData}
                />
              </Row>
            </div>
          </Card>

          <Card style={{ marginTop: 20, marginBottom: 20 }}>
            <Row>
              <Col xs={24}>
                <Table columns={sellingBeatsColumns} dataSource={sellingBeatsData} pagination={ANT_CONFIG.pagination} />
              </Col>
            </Row>
          </Card>
        </div>

        <Scribe
        intl={intl}
          hasMultiple={true}
          items={[
            {
              title: intl.formatMessage({ id: "scribe_about_beat" }),
              link: "https://scribehow.com/embed/Om_valdet__Oppdatere_tekstinnhold__0ngXqFCaRuC6sI6_ucFtYQ",
            },
            {
              title: intl.formatMessage({ id: "scribe_about_us" }),
              link: "https://scribehow.com/embed/Om_oss__Endre_tekst_og_legge_til_kontaktinformasjon__ZR1HadKkSSOOywNhmTgVcQ",
            },
            {
              title: intl.formatMessage({ id: "scribe_other_facilities" }),
              link: "https://scribehow.com/embed/Ovrige_fasiliteter__A7KXW_I5RfOrX70nw4ymYg",
            },
            {
              title: intl.formatMessage({ id: "scribe_accommodation" }),
              link: "https://scribehow.com/embed/Overnatting__Endrelegge_til_tekst__IJUvqDYnT-G6c0GwysmdXw",
            },
            {
              title: intl.formatMessage({ id: "scribe_images" }),
              link: "https://scribehow.com/embed/Legge_til_og_fjerne_bilder__mS2x_n2_SVm1TNwjec9b5A",
            },
            {
              title: intl.formatMessage({ id: "scribe_map" }),
              link: "https://scribehow.com/embed/Kart__Legge_til_punktomrade__xJ9IfuKzRpqxEzrb5bWo1g",
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    beat: state.Beat,
    beats: state.Beats,
    authentication: state.Auth,
    flytypes: state.FlyTypes,
    facilities: state.Facilities,
  };
};

export default withRouter(injectIntl(connect(mapStateToProps)(Page)));
