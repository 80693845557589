import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, Row, Col, DatePicker } from "antd";
import moment from "moment";

import { post as addDate, fetch } from "../../../../redux/season_dates/actions";
import ANT_CONFIG from "../../../../constants/antconfig";
import Spinner from "../../../spinner";

class ModalAddEditSeasonDates extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";

    this.state = {
      modal: true,
      submitted: false,
      beat_id: props.selectedBeat,
      date_start: null,
      date_end: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetch(this.state.beat_id));
  }

  componentDidUpdate(prevProps) {
    if (!this.props.season_dates.loading && !this.props.season_dates.error && this.state.submitted) {
      this.onCancel();
    }

    if (prevProps.season_dates.data !== this.props.season_dates.data && this.props.season_dates.data) {
      this.setDates(this.props.season_dates.data);
    }
  }

  setDates(dates) {
    this.setState({
      date_start: dates.date_start_option?.value ? moment(`${moment().format("YYYY")}${dates.date_start_option?.value}`).format(this.dateFormat) : "",
      date_end: dates.date_end_option?.value ? moment(`${moment().format("YYYY")}${dates.date_end_option?.value}`).format(this.dateFormat) : "",
    });
  }

  onSubmit = () => {
    this.setState({
      submitted: true,
    });

    const { beat_id, date_start, date_end } = this.state;

    const payload = {
      beat_id,
    };

    if (date_start) {
      payload.date_start = moment(date_start).format("-MM-DD");
    }

    if (date_end) {
      payload.date_end = moment(date_end).format("-MM-DD");
    }

    this.props.dispatch(addDate(payload, beat_id));
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  changeDate(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    if (!this.state.beat_id) {
      return;
    }

    return (
      <Modal
        width={800}
        title={this.props.intl.formatMessage({ id: "add_edit_dates" })}
        visible={this.state.modal}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="submit" loading={this.props.season_dates.loading} type="primary" onClick={this.onSubmit}>
            {this.props.intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        {this.props.season_dates.loading && !this.state.submitted ? (
          <Row>
            <Col xs={24}>
              <Spinner />
            </Col>
          </Row>
        ) : (
          <Row type="flex" gutter={[20, 20]}>
            <Col xs={12}>
              <label htmlFor="date_start" className="ant-label">
                {this.props.intl.formatMessage({ id: "date_start" })}
              </label>
              <DatePicker
                style={{ width: "100%" }}
                id="date_start"
                size={ANT_CONFIG.size}
                allowClear={false}
                format="DD.MM"
                value={this.state.date_start ? moment(this.state.date_start) : null}
                onChange={(e) => this.changeDate("date_start", e.format(ANT_CONFIG.date_format))}
              />
            </Col>

            <Col xs={12}>
              <label htmlFor="date_end" className="ant-label">
                {this.props.intl.formatMessage({ id: "date_end" })}
              </label>
              <DatePicker
                style={{ width: "100%" }}
                id="date_end"
                size={ANT_CONFIG.size}
                allowClear={false}
                format="DD.MM"
                value={this.state.date_end ? moment(this.state.date_end) : null}
                onChange={(e) => this.changeDate("date_end", e.format(ANT_CONFIG.date_format))}
              />
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  season_dates: state.SeasonDates,
  taxes: state.Taxes,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddEditSeasonDates)));
