import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Card, Col, Row, PageHeader, Input, Table, Tag, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { fetch as fetchCustomers } from "../../redux/customers/actions";
import ANT_CONFIG from "../../constants/antconfig";

const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const INITIAL_STATE = {
  filter: "",
  comments_filter: "",
};
const { size, pagination } = ANT_CONFIG;

class SupervisorDashboard extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchCustomers());
  }

  onRowClick(id) {
    const { history } = this.props;

    history.push(`/customer/${id}`);
  }

  setStateAndInitialState(key, value) {
    this.setState({
      [key]: value,
    });

    INITIAL_STATE[key] = value;
  }

  filterData(value) {
    const { filter, comments_filter } = this.state;
    let data = value?.filter((i) => i.controller_comments?.length > 0);

    if (comments_filter === "ok") {
      data = data?.filter((i) => i.controller_comments.filter((i) => i.status === 0 && i.invalid === 0)?.length === 0);
    }

    if (comments_filter === "not_ok") {
      data = data?.filter((i) => i.controller_comments.filter((i) => i.status === 0 && i.invalid === 0)?.length > 0);
    }

    if (filter) {
      data = data?.filter((customer) => {
        const f = filter.toLowerCase();
        const fullName = `${customer.first_name} ${customer.last_name}`;

        return (
          (customer.id && customer.id.toString().includes(f)) ||
          (customer.first_name && customer.first_name.toLowerCase().includes(f)) ||
          (customer.last_name && customer.last_name.toLowerCase().includes(f)) ||
          (fullName && fullName.toLowerCase().includes(f)) ||
          (customer.email && customer.email.toLowerCase().includes(f))
        );
      });
    }

    return data;
  }

  render() {
    const { filter, comments_filter } = this.state;
    const { intl, customers } = this.props;
    const data = this.filterData(customers.data);
    const selectCommentsStatus = [
      {
        value: "",
        label: intl.formatMessage({ id: "all" }),
      },
      {
        value: "ok",
        label: intl.formatMessage({ id: "all_documents_are_ok" }),
      },
      {
        value: "not_ok",
        label: intl.formatMessage({ id: "documents_missing" }),
      },
    ];
    const tableColumns = [
      {
        title: "#",
        dataIndex: "visible_key",
        key: "visible_key",
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({ id: "first_name" }),
        dataIndex: "first_name",
        key: "first_name",
      },
      {
        title: intl.formatMessage({ id: "last_name" }),
        dataIndex: "last_name",
        key: "last_name",
      },
      {
        title: intl.formatMessage({ id: "phone" }),
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: intl.formatMessage({ id: "email" }),
        dataIndex: "email",
        key: "email",
      },
      {
        title: intl.formatMessage({ id: "address" }),
        dataIndex: "address",
        key: "address",
      },
      {
        title: intl.formatMessage({ id: "post_code" }),
        dataIndex: "post_code",
        key: "post_code",
      },
      {
        title: intl.formatMessage({ id: "city" }),
        dataIndex: "city",
        key: "city",
      },
      {
        title: intl.formatMessage({ id: "country" }),
        dataIndex: "country",
        key: "country",
      },
    ];
    const tableData = data?.map((customer, row) => {
      const key = row + 1;
      const ok = customer.controller_comments?.filter((i) => i.status === 0 && i.invalid === 0).length === 0;
      const { id, first_name, last_name, phone, email, address, post_code, city, country } = customer;

      return {
        key,
        visible_key: ok ? <Tag color="#95c11f">{key}</Tag> : <Tag color="#f5222d">{key}</Tag>,
        id,
        first_name,
        last_name,
        phone,
        email,
        address,
        post_code,
        city,
        country,
      };
    });

    return (
      <>
        <PageHeader title={intl.formatMessage({ id: "supervisor_dashboard" })}>
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search_by_id_name_or_email" className="ant-label">
                    {intl.formatMessage({ id: "search_by_id_name_or_email" })}
                  </label>
                  <Input
                    placeholder={intl.formatMessage({ id: "search_by_id_name_or_email" })}
                    id="search_by_id_name_or_email"
                    size={size}
                    value={filter}
                    onChange={(e) => this.setStateAndInitialState("filter", e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="select_comment_status" className="ant-label">
                    {intl.formatMessage({ id: "select_comment_status" })}
                  </label>
                  <Select
                    showSearch
                    size={size}
                    id="select_comment_status"
                    style={{ width: "100%" }}
                    placeholder={`${intl.formatMessage({ id: "select_comment_status" })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.setStateAndInitialState("comments_filter", e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={comments_filter}
                  >
                    {selectCommentsStatus.map((i, key) => (
                      <Select.Option key={key} value={i.value}>
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                loading={{ spinning: customers.loading, indicator: SPIN_ICON }}
                columns={tableColumns}
                dataSource={tableData}
                pagination={pagination}
                onRow={(record) => {
                  return {
                    onClick: () => this.onRowClick(record.id),
                  };
                }}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.Customers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(SupervisorDashboard)));
