export const currencyOptions = (props) => {
    if (!props.currencies) {
      return false;
    }
  
    return props.currencies.map(currency => {
        return {
          value: currency.id,
          label: currency.iso
        };
      });
  };

  export const timezoneOptions = (props) => {
    if (!props.timezones) {
      return false;
    }
    
    return props.timezones.map(timezone => {
        return {
          value: timezone.id,
          label: timezone.name
        };
      });
  };
  
  
