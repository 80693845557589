export const FETCH_RIVER_EQUIPMENT = "FETCH_RIVER_EQUIPMENT";
export const FETCH_RIVER_EQUIPMENT_SUCCESS = "FETCH_RIVER_EQUIPMENT_SUCCESS";
export const FETCH_RIVER_EQUIPMENT_FAILURE = "FETCH_RIVER_EQUIPMENT_FAILURE";

export const UPDATE_RIVER_EQUIPMENT = "UPDATE_RIVER_EQUIPMENT";
export const UPDATE_RIVER_EQUIPMENT_SUCCESS = "UPDATE_RIVER_EQUIPMENT_SUCCESS";
export const UPDATE_RIVER_EQUIPMENT_FAILURE = "UPDATE_RIVER_EQUIPMENT_FAILURE";

export function fetch(id) {
  return {
    type: FETCH_RIVER_EQUIPMENT,
    id
  };
}

export function update(data, id) {
  return {
    type: UPDATE_RIVER_EQUIPMENT,
    data,
    id
  };
}
