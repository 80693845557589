export const FETCH_STATIONS = "FETCH_STATIONS";
export const FETCH_STATIONS_SUCCESS = "FETCH_STATIONS_SUCCESS";
export const FETCH_STATIONS_FAILURE = "FETCH_STATIONS_FAILURE";

export const FETCH_STATION = "FETCH_STATION";
export const FETCH_STATION_SUCCESS = "FETCH_STATION_SUCCESS";
export const FETCH_STATION_FAILURE = "FETCH_STATION_FAILURE";

export const ADD_STATION = "ADD_STATION";
export const ADD_STATION_SUCCESS = "ADD_STATION_SUCCESS";
export const ADD_STATION_FAILURE = "ADD_STATION_FAILURE";

export const EDIT_STATION = "EDIT_STATION";
export const EDIT_STATION_SUCCESS = "EDIT_STATION_SUCCESS";
export const EDIT_STATION_FAILURE = "EDIT_STATION_FAILURE";

export const REMOVE_STATION = "REMOVE_STATION";
export const REMOVE_STATION_SUCCESS = "EDIT_STATION_SUCCESS";
export const REMOVE_STATION_FAILURE = "EDIT_STATION_FAILURE";

export function fetch() {
  return {
    type: FETCH_STATIONS
  };
}

export function show(id) {
  return {
    type: FETCH_STATION,
    id
  };
}

export function add(payload) {
  return {
    type: ADD_STATION,
    payload
  };
}

export function edit(id, payload) {
  return {
    type: EDIT_STATION,
    id,
    payload
  };
}

export function remove(id) {
  return {
    type: REMOVE_STATION,
    id,
    meta: {
      thunk: true
    }
  };
}