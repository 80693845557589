import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Row, Col, FormGroup, Label, Form } from "reactstrap";
import { PageHeader, Input, Button } from "antd";
import {
  fetch as fetchBeats,
  select as selectBeat,
} from "../../redux/beats/actions";
import Spinner from "../../components/spinner";
import SelectInput from "../../components/select";
import {
  fetch as fetchBeatOptions,
  edit as editBeatOptions,
} from "../../redux/beat_options/actions";
import {
  fetch as fetchCancellationPolicies,
  edit as editCancellationPolicies,
} from "../../redux/cancellation_policies/actions";
import beatOptions from "../../services/select_beat_options";
import ANT_CONFIG from "../../constants/antconfig";

const inputWidth = 9;

class CancellationPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beat_id:
        this.props.beats.selected || this.props.authentication.data.beat[0],
      data: {},
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.handleFetchBeatOptions();
    this.handleFetchCancellationPolicies();
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({
      data: { ...prevState.data, ...newState },
    }));
  }

  onSubmit() {
    const data = {
      ...this.state.data,
      beat_id: this.state.beat_id,
    };

    this.props.dispatch(editBeatOptions(data, this.state.beat_id));
  }

  handleCancellationPolicySubmit() {
    const numberOfDays = this.state.data.refund_number_of_days;
    const beatId = this.state.beat_id;
    const payload = {
      beat_id: beatId,
      cancellation_policy_rules: [
        {
          product_type: "FishingCard",
          percentage: 100,
          number_of_days: numberOfDays,
        },
      ],
    };

    this.props.dispatch(editCancellationPolicies(beatId, payload));
  }

  handleBeatChange(e) {
    this.props.dispatch(selectBeat(e));
    this.setState({ beat_id: e }, () => {
      this.handleFetchBeatOptions();
      this.handleFetchCancellationPolicies();
    });
  }

  handleFetchBeatOptions() {
    this.props.dispatch(fetchBeatOptions(this.state.beat_id));
  }

  handleFetchCancellationPolicies() {
    this.props.dispatch(fetchCancellationPolicies(this.state.beat_id));
  }

  getRefundNumberOfDays(props) {
    if (!props.cancellation_policies.data) return 0
    const policies = props.cancellation_policies.data
    return policies.length > 0 && (policies[0].number_of_days || 0)
  }

  render() {
    const { intl } = this.props;

    if (this.props.beats.loading || !this.props.beats.data) {
      return <Spinner />;
    }

    const selectBeats = beatOptions(
      this.props.beats,
      this.props.authentication
    );

    const cancellationPolicyElement = (cancellationPolicies) => {
      const beatName = selectBeats.find(
        (i) => i.value === this.state.beat_id
      ).label;
      const policyElement = (
        <div>
          <p>
            <b>{this.props.intl.formatMessage({ id: "cancellation_policy_current_terms"})} {beatName}</b>
          </p>
          {cancellationPolicies.data && cancellationPolicies.data.length > 0 ? (
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th>
                    <b>{this.props.intl.formatMessage({ id: "cancellation_policy_product" })}</b>
                  </th>
                  <th>
                    <b>{this.props.intl.formatMessage({ id: "cancellation_policy_refund" })}</b>
                  </th>
                  <th>
                    <b>{this.props.intl.formatMessage({ id: "cancellation_policy_number_of_days" })}</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {cancellationPolicies.data.map((policy) => {
                  return (
                    <tr>
                      <td>
                        {policy.product_type === "FishingCard"
                          // todo: Change when refund policy can be specific to product.
                          ? this.props.intl.formatMessage({ id: "fishing_card" })
                          : policy.product_type}
                      </td>
                      <td>{parseInt(policy.percentage)} %</td>
                      <td>{policy.number_of_days}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>{this.props.intl.formatMessage({ id: "cancellation_policy_no_policy_set" })}</p>
          )}
        </div>
      );
      return policyElement;
    };

    return (
      <div>
        {selectBeats.length > 1 ? (
          <div style={{ marginBottom: 30 }} className="panel">
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row style={{ marginBottom: 2 }}>
                    <Label for="select_beat" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({
                        id: "select_beat",
                      })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <SelectInput
                        id="select_beat"
                        name="select_beat"
                        intl={this.props.intl}
                        value={selectBeats.filter(
                          (i) => i.value === this.state.beat_id
                        )}
                        onChange={(e) => this.handleBeatChange(e.value)}
                        options={selectBeats}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>
        ) : null}
        {this.props.beat_options.loading ? (
          <Spinner />
        ) : (
          <div className="panel" style={{ paddingTop: 20 }}>
            <PageHeader
              title={intl.formatMessage({ id: "cancellation_policy" })}
              extra={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => this.handleCancellationPolicySubmit()}
                  size={ANT_CONFIG.size}
                >
                  {this.props.intl.formatMessage({ id: "save" })}
                </Button>,
              ]}
            >
              <br />
              <p>{this.props.intl.formatMessage({ id: "cancellation_policy_description_1" })}</p>
              <p>{this.props.intl.formatMessage({ id: "cancellation_policy_description_2" })}</p>
              <p>{this.props.intl.formatMessage({ id: "cancellation_policy_description_3" })}</p>
              <p>{this.props.intl.formatMessage({ id: "cancellation_policy_description_4" })}</p>
              {cancellationPolicyElement(this.props.cancellation_policies)}
              <br />
              <p>
                <b>{this.props.intl.formatMessage({ id: "cancellation_policy_set_terms" })}</b>
              </p>
              <p>{this.props.intl.formatMessage({ id: "cancellation_policy_full_refund_limit" })}</p>
              <Form>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormGroup row>
                      <Col xs={12} sm={inputWidth}>
                        <Input
                          type="number"
                          name="cancellationPolicy"
                          id="cancellationPolicy"
                          defaultValue={this.getRefundNumberOfDays(this.props)}
                          onChange={(e) =>
                            this.onChangeValue({
                              refund_number_of_days: e.target.value,
                            })
                          }
                          style={{ width: "60%" }}
                          addonAfter={this.props.intl.formatMessage({ id: "days" })}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </PageHeader>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  beats: state.Beats,
  beat_options: state.BeatOptions,
  cancellation_policies: state.CancellationPolicies,
});

export default withRouter(
  injectIntl(connect(mapStateToProps)(CancellationPolicy))
);
