import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";

import UploadAdapterPlugin from "./UploadAdapterPlugin";

const LoadAdapter = (editor) => {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => new UploadAdapterPlugin(loader); // eslint-disable-line
};

class HTMLEditor extends React.Component {
  render() {
    const EDITOR_CONFIG = {
      ...this.props.config,
      extraPlugins: [LoadAdapter],
      mediaEmbed: {
        previewsInData: true,
      },
    };

    return <CKEditor editor={ClassicEditor} config={EDITOR_CONFIG} {...this.props} />;
  }
}

export default HTMLEditor;
