export const FETCH_BEAT_NOTIFICATIONS = "FETCH_BEAT_NOTIFICATIONS";
export const FETCH_BEAT_NOTIFICATIONS_SUCCESS = "FETCH_BEAT_NOTIFICATIONS_SUCCESS";
export const FETCH_BEAT_NOTIFICATIONS_FAILURE = "FETCH_BEAT_NOTIFICATIONS_FAILURE";

export function fetch(id) {
  return {
    type: FETCH_BEAT_NOTIFICATIONS,
    id
  };
}


