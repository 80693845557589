import React from "react";
import { Button, Descriptions, Modal, Tag } from "antd";
import moment from "moment";
import { injectIntl } from "react-intl";

import ANT_CONFIG from "../../../constants/antconfig";

const { Item } = Descriptions;

const CatchInfo = ({ intl, data, closeModal = false }) => {
  const renderYesNo = (q) => {
    return q ? (
      <Tag color="green" className="ant-tag">
        {intl.formatMessage({ id: "yes" })}
      </Tag>
    ) : (
      <Tag color="red" className="ant-tag">
        {intl.formatMessage({ id: "no" })}
      </Tag>
    );
  };

  const renderImage = (i) => {
    return (
      <picture>
        <source media="(min-width: 650px)" srcSet={`${i}?width=800`} />
        <img className="catch-max-width" src={`${i}?width=400`} alt="" />
      </picture>
    );
  };

  return (
    <Modal
      width={800}
      visible={!!data}
      onCancel={closeModal}
      footer={[
        <Button size={ANT_CONFIG.size} key="submit" type="primary" onClick={closeModal}>
          {intl.formatMessage({ id: "ok" })}
        </Button>,
      ]}
    >
      {data && (
        <Descriptions
          bordered
          title={`${intl.formatMessage({ id: "catch" })} ID: ${data.id}`}
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          size={ANT_CONFIG.size}
        >
          <Item label={intl.formatMessage({ id: "date" })}>{moment(data.date).format("DD.MM.YYYY")}</Item>
          <Item label={intl.formatMessage({ id: "time" })}>{data.time_of_day || "-"}</Item>
          <Item label={intl.formatMessage({ id: "week" })}>{data.week || "-"}</Item>
          <Item label={intl.formatMessage({ id: "river" })}>{data.river?.name || "-"}</Item>
          <Item label={intl.formatMessage({ id: "beat" })}>{data.beat?.name || "-"}</Item>
          <Item label={intl.formatMessage({ id: "fisher_name" })}>{data.fisher_name || "-"}</Item>
          <Item label={intl.formatMessage({ id: "fishing_card_id" })}>{data.fishing_card_id || "-"}</Item>
          <Item label={intl.formatMessage({ id: "package_id" })}>{data.package_id || "-"}</Item>
          <Item label={intl.formatMessage({ id: "free_package_id" })}>{data.free_package_id || "-"}</Item>
          <Item label={intl.formatMessage({ id: "season_fishing_card_id" })}>{data.season_fishing_card_id || "-"}</Item>
          <Item label={intl.formatMessage({ id: "equipment" })}>{data.equipment?.name_no || "-"}</Item>
          <Item label={intl.formatMessage({ id: "fish_type" })}>{data.fish_type?.name_no || "-"}</Item>
          <Item label={intl.formatMessage({ id: "sex" })}>{data.sex ? intl.formatMessage({ id: data.sex }) : "-"}</Item>
          <Item label={intl.formatMessage({ id: "length" })}>{data.length || "-"}</Item>
          <Item label={intl.formatMessage({ id: "weight" })}>{data.weight || "-"}</Item>
          <Item label={intl.formatMessage({ id: "lice" })}>{renderYesNo(data.lice)}</Item>
          {data.lice && <Item label={intl.formatMessage({ id: "select_lice_count" })}>{data.lice_count || "0"}</Item>}
          <Item label={intl.formatMessage({ id: "cr" })}>{renderYesNo(data.released_catch)}</Item>
          <Item label={intl.formatMessage({ id: "boat" })}>{renderYesNo(data.boat)}</Item>
          <Item label={intl.formatMessage({ id: "adipose_fin_removed" })}>{renderYesNo(data.adipose_fin_removed)}</Item>
          <Item label={intl.formatMessage({ id: "delivered" })}>{renderYesNo(data.delivered)}</Item>
          <Item label={intl.formatMessage({ id: "scale_sample" })}>{renderYesNo(data.scale_sample)}</Item>
          <Item label={intl.formatMessage({ id: "no_catch" })}>{renderYesNo(data.no_catch)}</Item>
        </Descriptions>
      )}
    </Modal>
  );
};

export default injectIntl(CatchInfo);
