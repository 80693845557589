export const FETCH_PLATFORM_REPORTS = "FETCH_PLATFORM_REPORTS";
export const FETCH_PLATFORM_REPORTS_SUCCESS = "FETCH_PLATFORM_REPORTS_SUCCESS";
export const FETCH_PLATFORM_REPORTS_FAILURE = "FETCH_PLATFORM_REPORTS_FAILURE";

export const DOWNLOAD_BEAT_REPORT = "DOWNLOAD_BEAT_REPORT";
export const DOWNLOAD_BEAT_REPORT_SUCCESS = "DOWNLOAD_BEAT_REPORT_SUCCESS";
export const DOWNLOAD_BEAT_REPORT_FAILURE = "DOWNLOAD_BEAT_REPORT_FAILURE";

export const DOWNLOAD_ALL_BEAT_REPORT = "DOWNLOAD_ALL_BEAT_REPORT";
export const DOWNLOAD_ALL_BEAT_REPORT_SUCCESS = "DOWNLOAD_ALL_BEAT_REPORT_SUCCESS";
export const DOWNLOAD_ALL_BEAT_REPORT_FAILURE = "DOWNLOAD_ALL_BEAT_REPORT_FAILURE";

export const DOWNLOAD_PLATFORM_REPORT = "DOWNLOAD_PLATFORM_REPORT";
export const DOWNLOAD_PLATFORM_REPORT_SUCCESS = "DOWNLOAD_PLATFORM_REPORT_SUCCESS";
export const DOWNLOAD_PLATFORM_REPORT_FAILURE = "DOWNLOAD_PLATFORM_REPORT_FAILURE";

export const FETCH_PRODUCT_COUNT_REPORT = "FETCH_PRODUCT_COUNT_REPORT";
export const FETCH_PRODUCT_COUNT_REPORT_SUCCESS = "FETCH_PRODUCT_COUNT_REPORT_SUCCESS";
export const FETCH_PRODUCT_COUNT_REPORT_FAILURE = "FETCH_PRODUCT_COUNT_REPORT_FAILURE";

export const DOWNLOAD_PRODUCT_COUNT_REPORT = "DOWNLOAD_PRODUCT_COUNT_REPORT";
export const DOWNLOAD_PRODUCT_COUNT_REPORT_SUCCESS = "DOWNLOAD_PRODUCT_COUNT_REPORT_SUCCESS";
export const DOWNLOAD_PRODUCT_COUNT_REPORT_FAILURE = "DOWNLOAD_PRODUCT_COUNT_REPORT_FAILURE";

export const FETCH_PRODUCT_TYPE_AND_PRICE_REPORT = "FETCH_PRODUCT_TYPE_AND_PRICE_REPORT";
export const FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS = "FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS";
export const FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE = "FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE";

export const DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT = "DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT";
export const DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS = "DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS";
export const DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE = "DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE";

export const FETCH_SELLER_PAYMENT_METHOD_REPORT = "FETCH_SELLER_PAYMENT_METHOD_REPORT";
export const FETCH_SELLER_PAYMENT_METHOD_REPORT_SUCCESS = "FETCH_SELLER_PAYMENT_METHOD_REPORT_SUCCESS";
export const FETCH_SELLER_PAYMENT_METHOD_REPORT_FAILURE = "FETCH_SELLER_PAYMENT_METHOD_REPORT_FAILURE";

export const DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT = "DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT";
export const DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_SUCCESS = "DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_SUCCESS";
export const DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_FAILURE = "DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_FAILURE";

export const FETCH_FISHING_CARDS_REPORT = "FETCH_FISHING_CARDS_REPORT";
export const FETCH_FISHING_CARDS_REPORT_SUCCESS = "FETCH_FISHING_CARDS_REPORT_SUCCESS";
export const FETCH_FISHING_CARDS_REPORT_FAILURE = "FETCH_FISHING_CARDS_REPORT_FAILURE";

export const DOWNLOAD_FISHING_CARDS_REPORT = "DOWNLOAD_FISHING_CARDS_REPORT";
export const DOWNLOAD_FISHING_CARDS_REPORT_SUCCESS = "DOWNLOAD_FISHING_CARDS_REPORT_SUCCESS";
export const DOWNLOAD_FISHING_CARDS_REPORT_FAILURE = "DOWNLOAD_FISHING_CARDS_REPORT_FAILURE";

export const FETCH_RIVER_CARDS_REPORT = "FETCH_RIVER_CARDS_REPORT";
export const FETCH_RIVER_CARDS_REPORT_SUCCESS = "FETCH_RIVER_CARDS_REPORT_SUCCESS";
export const FETCH_RIVER_CARDS_REPORT_FAILURE = "FETCH_RIVER_CARDS_REPORT_FAILURE";

export const DOWNLOAD_RIVER_CARDS_REPORT = "DOWNLOAD_RIVER_CARDS_REPORT";
export const DOWNLOAD_RIVER_CARDS_REPORT_SUCCESS = "DOWNLOAD_RIVER_CARDS_REPORT_SUCCESS";
export const DOWNLOAD_RIVER_CARDS_REPORT_FAILURE = "DOWNLOAD_RIVER_CARDS_REPORT_FAILURE";

export const FETCH_SEASON_CARDS_REPORT = "FETCH_SEASON_CARDS_REPORT";
export const FETCH_SEASON_CARDS_REPORT_SUCCESS = "FETCH_SEASON_CARDS_REPORT_SUCCESS";
export const FETCH_SEASON_CARDS_REPORT_FAILURE = "FETCH_SEASON_CARDS_REPORT_FAILURE";

export const DOWNLOAD_SEASON_CARDS_REPORT = "DOWNLOAD_SEASON_CARDS_REPORT";
export const DOWNLOAD_SEASON_CARDS_REPORT_SUCCESS = "DOWNLOAD_SEASON_CARDS_REPORT_SUCCESS";
export const DOWNLOAD_SEASON_CARDS_REPORT_FAILURE = "DOWNLOAD_SEASON_CARDS_REPORT_FAILURE";
export const FILTER_FISHING_CARDS_REPORT = "FILTER_FISHING_CARDS_REPORT";
export const FILTER_SEASON_CARDS_REPORT = "FILTER_SEASON_CARDS_REPORT";

export const FETCH_ALL_ECONOMIC_BEAT_REPORTS = "FETCH_ALL_ECONOMIC_BEAT_REPORTS";
export const FETCH_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS = "FETCH_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS";
export const FETCH_ALL_ECONOMIC_BEAT_REPORTS_FAILURE = "FETCH_ALL_ECONOMIC_BEAT_REPORTS_FAILURE";

export const FETCH_ECONOMY_BEAT_REPORT = "FETCH_ECONOMY_BEAT_REPORT";
export const FETCH_ECONOMY_BEAT_REPORT_SUCCESS = "FETCH_ECONOMY_BEAT_REPORT_SUCCESS";
export const FETCH_ECONOMY_BEAT_REPORT_FAILURE = "FETCH_ECONOMY_BEAT_REPORT_FAILURE";

export const DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS = "DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS";
export const DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS = "DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS";

export const FETCH_EXPORT_BILLING="FETCH_EXPORT_BILLING"
export const FETCH_EXPORT_BILLING_SUCCESS="FETCH_EXPORT_BILLING_SUCCESS"
export const FETCH_EXPORT_BILLING_FAILURE="FETCH_EXPORT_BILLING_FAILURE"

export function fetchAllBeatEconomicReports(payload) {
  return {
    type: FETCH_ALL_ECONOMIC_BEAT_REPORTS,
    payload,
  };
}

export function downloadAllBeatEconomicReports() {
  return {
    type: DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS,
  };
}

export function downloadAllBeatEconomicReportsSuccess() {
  return {
    type: DOWNLOAD_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS,
  };
}

export function fetchPlatformReports(payload) {
  return {
    type: FETCH_PLATFORM_REPORTS,
    payload,
  };
}

export function downloadBeatReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_BEAT_REPORT,
    payload,
    filetype,
  };
}

export function downloadPlatformReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_PLATFORM_REPORT,
    payload,
    filetype,
  };
}

export function fetchProductCountReport(payload) {
  return {
    type: FETCH_PRODUCT_COUNT_REPORT,
    payload,
  };
}

export function downloadProductCountReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_PRODUCT_COUNT_REPORT,
    payload,
    filetype,
  };
}

export function fetchProductTypeAndPriceReport(payload) {
  return {
    type: FETCH_PRODUCT_TYPE_AND_PRICE_REPORT,
    payload,
  };
}

export function downloadProductTypeAndPriceReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT,
    payload,
    filetype,
  };
}

export function fetchSellerPaymentMethodReport(payload) {
  return {
    type: FETCH_SELLER_PAYMENT_METHOD_REPORT,
    payload,
  };
}

export function downloadSellerPaymentMethodReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT,
    payload,
    filetype,
  };
}

export function fetchFishingCardsReport(payload) {
  return {
    type: FETCH_FISHING_CARDS_REPORT,
    payload,
  };
}

export function filterFishingCardsReport(payload) {
  return {
    type: FILTER_FISHING_CARDS_REPORT,
    payload,
  };
}
export function filterSeasonCardsReport(payload) {
  return {
    type: FILTER_SEASON_CARDS_REPORT,
    payload,
  };
}

export function downloadFishingCardsReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_FISHING_CARDS_REPORT,
    payload,
    filetype,
  };
}

export function fetchRiverCardsReport(payload) {
  return {
    type: FETCH_RIVER_CARDS_REPORT,
    payload,
  };
}
export function downloadRiverCardsReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_RIVER_CARDS_REPORT,
    payload,
    filetype,
  };
}

export function fetchSeasonCardsReport(payload) {
  return {
    type: FETCH_SEASON_CARDS_REPORT,
    payload,
  };
}

export function downloadSeasonCardsReport(payload, filetype = null) {
  return {
    type: DOWNLOAD_SEASON_CARDS_REPORT,
    payload,
    filetype,
  };
}

export function fetchEconomyBeatReport(payload) {
  return {
    type: FETCH_ECONOMY_BEAT_REPORT,
    payload,
  };
}

export function fetchExportBilling(payload) {
  return {
    type:FETCH_EXPORT_BILLING,
    payload
  }
}
