import React from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Card, Button } from "antd";

class CollapsedCard extends React.Component {
  state = {
    active: this.props.active || true
  };

  toggleActive = () => {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  };

  render() {
    return (
      <Card
        style={this.props.style ? { ...this.props.style } : { marginTop: 20, marginBottom: 20 }}
        title={this.props.title}
        bodyStyle={{
          padding: !this.state.active && 0
        }}
        extra={<Button type="danger" shape="circle" onClick={this.toggleActive} icon={this.state.active ? <MinusOutlined /> : <PlusOutlined />} />}
      >
        {this.state.active && this.props.children}
      </Card>
    );
  }
}

export default CollapsedCard;
