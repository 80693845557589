export const FETCH_RIVER_CARD_BLUEPRINTS = "FETCH_RIVER_CARD_BLUEPRINTS";
export const FETCH_RIVER_CARD_BLUEPRINTS_SUCCESS = "FETCH_RIVER_CARD_BLUEPRINTS_SUCCESS";
export const FETCH_RIVER_CARD_BLUEPRINTS_FAILURE = "FETCH_RIVER_CARD_BLUEPRINTS_FAILURE";

export const UPDATE_RIVER_CARD_BLUEPRINT = "UPDATE_RIVER_CARD_BLUEPRINT";
export const UPDATE_RIVER_CARD_BLUEPRINT_SUCCESS = "UPDATE_RIVER_CARD_BLUEPRINT_SUCCESS";
export const UPDATE_RIVER_CARD_BLUEPRINT_FAILURE = "UPDATE_RIVER_CARD_BLUEPRINT_FAILURE";

export const STORE_RIVER_CARD_BLUEPRINT = "STORE_RIVER_CARD_BLUEPRINT";
export const STORE_RIVER_CARD_BLUEPRINT_SUCCESS = "STORE_RIVER_CARD_BLUEPRINT_SUCCESS";
export const STORE_RIVER_CARD_BLUEPRINT_FAILURE = "STORE_RIVER_CARD_BLUEPRINT_FAILURE";

export const REMOVE_RIVER_CARD_BLUEPRINT = "REMOVE_RIVER_CARD_BLUEPRINT";
export const REMOVE_RIVER_CARD_BLUEPRINT_SUCCESS = "REMOVE_RIVER_CARD_BLUEPRINT_SUCCESS";
export const REMOVE_RIVER_CARD_BLUEPRINT_FAILURE = "REMOVE_RIVER_CARD_BLUEPRINT_FAILURE";

export function fetch(river_id) {
  return {
    type: FETCH_RIVER_CARD_BLUEPRINTS,
    river_id,
  };
}

export function store(data) {
  return {
    type: STORE_RIVER_CARD_BLUEPRINT,
    data,
  };
}

export function update(data) {
  return {
    type: UPDATE_RIVER_CARD_BLUEPRINT,
    data
  };
}

export function remove(data) {
  return {
    type: REMOVE_RIVER_CARD_BLUEPRINT,
    data,
  };
}
