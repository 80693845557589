import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Alert } from "antd";

import Spinner from "../../../spinner";
import SelectInput from "../../../select";
import { add as addBooking } from "../../../../redux/booking/actions";
import { quiteAdd } from "../../../../redux/customers/actions";
import SaveLabel from "../../../helpers/buttons/save_label";
import { fetchAvailable as fetchAvailablePackages } from "../../../../redux/free_packages/actions";
import groupBy from "../../../../services/group_by";
import transactionFee from "../../../../services/transaction_fee";
import ANT_CONFIG from "../../../../constants/antconfig";

const labelWidth = 4;
const inputWidth = 8;

class ModalOrderFreePackages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      beat_id: props.selectedBeat,
      date_start: props.modalData.date_start || null,
      date_end: props.modalData.date_end || null,
      show_new_user_form: false,
      type: {
        id: null,
      },
      selected_price: "",
      user: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
      },
      extra_service_ids: [],
      payment_method: false,
      payment_method_selected: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers.new_user !== this.props.customers.new_user) {
      this.onComponentUpdate();
    }
  }

  onComponentUpdate() {
    this.setState((prevState) => ({
      user: {
        id: this.props.customers.new_user.id,
        first_name: prevState.user.first_name,
        last_name: prevState.user.last_name,
        email: this.props.customers.new_user.email,
      },
    }));
  }

  onChangeUserFirstName(name) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        first_name: name,
      },
    }));
  }

  onChangeUserLastName(name) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        last_name: name,
      },
    }));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({
      data: { ...prevState.data, ...newState },
    }));
  }

  onSubmit(fee) {
    const data = {
      user_id: this.state.user.id,
      payment_method: this.state.payment_method ? this.state.payment_method_selected : "nets",
      owner_name: `${this.state.user.first_name} ${this.state.user.last_name}`,
      free_packages: [
        {
          id: this.state.type.id,
          price: this.state.selected_price.description,
          extra_service_ids: this.state.extra_service_ids.map((i) => i.id),
        },
      ],
    };

    this.setState({
      submitted: true,
    });

    this.props.dispatch(addBooking(data));
  }

  onChangeType(id) {
    const type = this.props.free_packages.available.data.filter((i) => i.id === id)[0];

    this.setState({
      type,
      selected_price: "",
    });
  }

  onPriceSelect(item) {
    this.setState({
      selected_price: item,
    });
  }

  onSelectBeat(id) {
    this.setState(
      {
        beat_id: id,
        type: {
          id: null,
        },
        selected_price: "",
      },
      () => {
        this.fetchData();
      },
    );
  }

  onSelectUser(e) {
    const customer = this.props.customers.data.filter((i) => i.id === e)[0];

    if (customer) {
      this.setState({
        user: {
          id: customer.id,
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
        },
      });
    }
  }

  onChangeUserInput(newState) {
    this.setState((prevState) => ({
      user: { ...prevState.user, ...newState },
    }));
  }

  onSaveNewUser() {
    this.props.dispatch(
      quiteAdd({
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        email: this.state.user.email,
      }),
    );
  }

  changePaymentMethod(e) {
    this.setState({
      payment_method_selected: e.target.value,
    });
  }

  fetchData() {
    if (this.state.date_start && this.state.date_end && this.state.beat_id) {
      this.props.dispatch(
        fetchAvailablePackages({
          beat_id: this.state.beat_id,
          date_start: this.state.date_start,
          date_end: this.state.date_end,
        }),
      );
    }
  }

  handleChangeDate(e) {
    const { name } = e.target;

    if (name === "date_start") {
      this.setState({ date_start: e.target.value }, () => {
        this.fetchData();
      });
    }

    if (name === "date_end") {
      this.setState({ date_end: e.target.value }, () => {
        this.fetchData();
      });
    }
  }

  addNewUser() {
    this.setState((prevState) => ({
      show_new_user_form: true,
      user: {
        ...prevState.user,
        id: null,
      },
    }));
  }

  setExtraService = (item, value) => {
    if (value) {
      this.setState((prevState) => ({
        extra_service_ids: [...prevState.extra_service_ids, item],
      }));
    } else {
      this.setState((prevState) => ({
        extra_service_ids: [...prevState.extra_service_ids.filter((i) => i.id !== item.id)],
      }));
    }
  };

  toggle() {
    this.props.toggleModal();
  }

  render() {
    const selectCustomers = [
      ...this.props.customers.data.map((i) => {
        return {
          value: i.id,
          label: `${i.first_name} ${i.last_name} (${i.email})`,
        };
      }),
    ];

    if (this.props.booking.loading) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    if (this.props.free_packages.loading || !this.props.free_packages.available.data) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    if (this.props.booking.data && !this.props.booking.loading && !this.props.booking.error && this.state.submitted) {
      if (this.state.payment_method) {
        this.toggle();

        return false;
      }

      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "payment_link" })}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} sm={10}>
                <Input type="text" defaultValue={this.props.booking.data.terminal_urls.url} disabled />
              </Col>
              <Col xs={12} sm={2}>
                <a
                  href={this.props.booking.data.terminal_urls.url}
                  className="btn btn-success"
                  style={{ width: "100%", lineHeight: "24px" }}
                >
                  {this.props.intl.formatMessage({ id: "pay_now" })}
                </a>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      );
    }
    const packages = groupBy(this.props.free_packages.available.data, (item) => {
      return [
        item.publish_date_start,
        item.publish_date_end,
        item.name,
        item.description,
        item.date_start,
        item.date_end,
        item.price_options,
        item.package_id !== null,
      ];
    });
    const selectPackageType = packages.map((i) => {
      const item = i[0];

      return {
        value: item.id,
        label: `${item.name} (${item.date_start} - ${item.date_end})`,
      };
    });

    let totalPrice = 0;
    if (this.state.selected_price) {
      totalPrice = this.state.selected_price.prices.map((i) => i.amount).reduce((a, b) => parseFloat(a) + parseFloat(b));

      if (this.state.extra_service_ids?.length > 0) {
        totalPrice += this.state.extra_service_ids.map((i) => i.price).reduce((a, b) => parseFloat(a) + parseFloat(b));
      }
    }

    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col xs={12}>
                {this.props.free_packages.available.loading ? (
                  <FormGroup row>
                    <Label for="select_package" xs={12} sm={labelWidth}>
                      {`${this.props.intl.formatMessage({
                        id: "select_package",
                      })}`}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Spinner width={30} style={{ height: 53, marginTop: -15 }} />
                    </Col>
                  </FormGroup>
                ) : (
                  <FormGroup row>
                    <Label for="select_package" xs={12} sm={labelWidth}>
                      {`${this.props.intl.formatMessage({
                        id: "select_package",
                      })}`}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <SelectInput
                        id="select_package"
                        name="select_package"
                        intl={this.props.intl}
                        value={selectPackageType.filter((i) => i.value === this.state.type.id)}
                        onChange={(e) => this.onChangeType(e.value)}
                        options={selectPackageType}
                      />
                    </Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Col xs={12} sm={labelWidth} />
                  <Col xs={12} sm={inputWidth}>
                    <Checkbox
                      checked={this.state.payment_method}
                      onChange={(event) => {
                        const checked = event.target.checked;

                        if (checked) this.setState({ payment_method: true, payment_method_selected: "manual" });
                        else this.setState({ payment_method: false, payment_method_selected: null });
                      }}
                    >
                      {this.props.intl.formatMessage({
                        id: "payment_performed_manually",
                      })}
                    </Checkbox>
                  </Col>
                </FormGroup>
                {this.state.type.id ? (
                  <FormGroup row>
                    <Col xs={12} sm={labelWidth} />
                    <Col xs={12} sm={inputWidth}>
                      <h4
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "#5a738e",
                        }}
                      >
                        {this.props.intl.formatMessage({ id: "description" })}
                      </h4>
                      <p style={{ color: "#5a738e" }}>{this.state.type.description}</p>
                    </Col>
                  </FormGroup>
                ) : null}

                <hr />

                {this.state.show_new_user_form ? (
                  <FormGroup row>
                    <Label for="or_add_new_customer" xs={12} sm={labelWidth}>
                      {`${this.props.intl.formatMessage({
                        id: "new_customer",
                      })}`}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <FormGroup row>
                        <Col xs={12} sm={6}>
                          <Input
                            type="text"
                            disabled={this.props.customers.new_user}
                            placeholder={this.props.intl.formatMessage({
                              id: "first_name",
                            })}
                            value={this.state.user.first_name}
                            onChange={(e) =>
                              this.onChangeUserInput({
                                first_name: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <Input
                            type="text"
                            disabled={this.props.customers.new_user}
                            placeholder={this.props.intl.formatMessage({
                              id: "last_name",
                            })}
                            value={this.state.user.last_name}
                            onChange={(e) =>
                              this.onChangeUserInput({
                                last_name: e.target.value,
                              })
                            }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col xs={12}>
                          <Input
                            type="email"
                            disabled={this.props.customers.new_user}
                            placeholder={this.props.intl.formatMessage({
                              id: "email",
                            })}
                            value={this.state.user.email}
                            onChange={(e) => this.onChangeUserInput({ email: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      {this.props.customers.loading ? (
                        <FormGroup row>
                          <Col xs={12}>
                            <Spinner />
                          </Col>
                        </FormGroup>
                      ) : null}
                      {!this.props.customers.loading && !this.props.customers.new_user ? (
                        <FormGroup row>
                          <Col xs={12}>
                            <Button color="secondary" onClick={() => this.onSaveNewUser()} style={{ width: "100%" }}>
                              <SaveLabel intl={this.props.intl} />
                            </Button>
                          </Col>
                        </FormGroup>
                      ) : null}
                    </Col>
                  </FormGroup>
                ) : (
                  <div>
                    <FormGroup row>
                      <Label for="select_customer" xs={12} sm={labelWidth}>
                        {`${this.props.intl.formatMessage({
                          id: "select_customer",
                        })}`}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <SelectInput
                          id="select_customer"
                          name="select_customer"
                          intl={this.props.intl}
                          value={selectCustomers.filter((i) => i.value === this.state.user.id)}
                          onChange={(e) => this.onSelectUser(e.value)}
                          options={selectCustomers}
                        />
                      </Col>
                    </FormGroup>
                    {this.state.user.id ? (
                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={4}>
                          <Input
                            type="text"
                            value={this.state.user.first_name}
                            onChange={(e) => this.onChangeUserFirstName(e.target.value)}
                          />
                        </Col>
                        <Col xs={12} sm={4}>
                          <Input
                            type="text"
                            value={this.state.user.last_name}
                            onChange={(e) => this.onChangeUserLastName(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    ) : null}
                    {!this.state.user.id ? (
                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={inputWidth}>
                          <Button color="success" onClick={() => this.addNewUser()} style={{ width: "100%" }}>
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{
                                fontSize: 15,
                                marginRight: 10,
                              }}
                            />
                            {this.props.intl.formatMessage({
                              id: "add_new_customer",
                            })}
                          </Button>
                        </Col>
                      </FormGroup>
                    ) : null}
                  </div>
                )}

                {this.state.type.price_options ? (
                  <FormGroup row>
                    <Label for="select_price" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "select_price" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <SelectInput
                        id="select_price"
                        name="select_price"
                        intl={this.props.intl}
                        value={this.state.type.price_options
                          .map((i) => {
                            return {
                              value: i.description,
                              label: i.description,
                            };
                          })
                          .filter((i) => i.value === this.state.selected_price.description)}
                        onChange={(e) =>
                          this.onPriceSelect(this.state.type.price_options.filter((i) => i.description === e.value)[0])
                        }
                        options={this.state.type.price_options.map((i) => {
                          return {
                            value: i.description,
                            label: i.description,
                          };
                        })}
                      />
                    </Col>
                  </FormGroup>
                ) : null}

                {this.state.type.extra_services?.length > 0 && (
                  <FormGroup row style={{ marginTop: 35 }}>
                    <Label for="extra_services" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "extra_services" })}
                    </Label>
                    <Col xs={12} sm={inputWidth} style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <Row>
                        {this.state.type.extra_services.map((i) => (
                          <Col xs={6} key={i.id} style={{ paddingBottom: 10 }}>
                            <Checkbox
                              size={ANT_CONFIG.size}
                              checked={this.state.extra_service_ids.map((f) => f.id).filter((f) => f === i.id)?.[0] || false}
                              onChange={(e) => this.setExtraService(i, e.target.checked)}
                            >
                              {`${i.name} - ${i.price} NOK`}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </FormGroup>
                )}

                {this.state.selected_price ? (
                  <React.Fragment>
                    <FormGroup row>
                      <Label for="total_price" xs={12} sm={labelWidth}>
                        {this.props.intl.formatMessage({ id: "total_price" })}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <Input
                          type="text"
                          name="total_price"
                          id="total_price"
                          disabled
                          value={parseFloat(totalPrice).toFixed(2)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="fee" xs={12} sm={labelWidth}>
                        {this.props.intl.formatMessage({ id: "fee" })}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <Input
                          type="text"
                          name="fee"
                          id="fee"
                          disabled
                          value={transactionFee(parseFloat(totalPrice).toFixed(2))}
                        />
                      </Col>
                    </FormGroup>
                    {this.state.payment_method ? (
                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={inputWidth}>
                          <Alert
                            message={`${this.props.intl.formatMessage({
                              id: "remember",
                            })}!`}
                            description={this.props.intl.formatMessage({
                              id: "payment_performed_manually_2",
                            })}
                            type="warning"
                            showIcon
                          />
                        </Col>
                      </FormGroup>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          {this.state.type.id && this.state.user.id && this.state.selected_price ? (
            <ModalFooter>
              <Button color="secondary" onClick={() => this.onSubmit(transactionFee(parseFloat(totalPrice).toFixed(2)))}>
                {this.props.intl.formatMessage({ id: "confirm" })}
              </Button>
            </ModalFooter>
          ) : null}
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.Users,
  authentication: state.Auth,
  accommodation: state.Accommodation,
  booking: state.Booking,
  customers: state.Customers,
  free_packages: state.FreePackages,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalOrderFreePackages)));
