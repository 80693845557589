export const FETCH_DASHBOARD_BEAT_ADMIN_MEMBERS = "FETCH_DASHBOARD_BEAT_ADMIN_MEMBERS";
export const FETCH_DASHBOARD_BEAT_ADMIN_MEMBERS_SUCCESS = "FETCH_DASHBOARD_BEAT_ADMIN_MEMBERS_SUCCESS";
export const FETCH_DASHBOARD_BEAT_ADMIN_MEMBERS_FAILURE = "FETCH_DASHBOARD_BEAT_ADMIN_MEMBERS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_BEAT_ADMIN_MEMBERS,
    payload
  };
}
