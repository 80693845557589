import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, "admin/fishing-cards-all", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_FISHING_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_FISHING_CARDS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_FISHING_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchForBookingCalendar(action) {
  try {
    const response = yield call(API.call, "admin/fishing-cards-all-booking-calendar", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* add(action) {
  try {
    const response = yield call(API.call, `admin/fishing-cards`, "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_FISHING_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.ADD_FISHING_CARDS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_FISHING_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, `admin/fishing-cards`, "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_FISHING_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.EDIT_FISHING_CARDS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_FISHING_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, "admin/fishing-cards", "DELETE", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_FISHING_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.REMOVE_FISHING_CARDS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_FISHING_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchAvailable(action) {
  try {
    const response = yield call(API.call, "admin/fishing-cards-available", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_AVAILABLE_FISHING_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_AVAILABLE_FISHING_CARDS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_AVAILABLE_FISHING_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchTypes(action) {
  try {
    const response = yield call(API.call, `admin/fishing-card-types/${action.payload}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_FISHING_CARDS_TYPES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_FISHING_CARDS_TYPES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_FISHING_CARDS_TYPES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* addType(action) {
  try {
    const response = yield call(API.call, `admin/fishing-card-type`, "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_FISHING_CARDS_TYPE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.ADD_FISHING_CARDS_TYPE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_FISHING_CARDS_TYPE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* editType(action) {
  try {
    const response = yield call(API.call, `admin/fishing-card-type`, "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_FISHING_CARDS_TYPE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.EDIT_FISHING_CARDS_TYPE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_FISHING_CARDS_TYPE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchPrices(action) {
  try {
    const response = yield call(API.call, "fishing-card-prices", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_FISHING_CARD_PRICES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_FISHING_CARD_PRICES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_FISHING_CARD_PRICES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_FISHING_CARDS, fetch),
    takeEvery(ACTION.FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS, fetchForBookingCalendar),
    takeEvery(ACTION.ADD_FISHING_CARDS, add),
    takeEvery(ACTION.EDIT_FISHING_CARDS, edit),
    takeEvery(ACTION.REMOVE_FISHING_CARDS, remove),
    takeEvery(ACTION.FETCH_AVAILABLE_FISHING_CARDS, fetchAvailable),
    takeEvery(ACTION.FETCH_FISHING_CARDS_TYPES, fetchTypes),
    takeEvery(ACTION.ADD_FISHING_CARDS_TYPE, addType),
    takeEvery(ACTION.EDIT_FISHING_CARDS_TYPE, editType),
    takeEvery(ACTION.FETCH_FISHING_CARD_PRICES, fetchPrices),
  ]);
}
