import * as ACTION from './actions';

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  file: undefined,
  file_loading: false,
  file_error: false
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_DISINFECTION:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_DISINFECTION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_DISINFECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_DISINFECTIONS:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_DISINFECTIONS_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_DISINFECTIONS_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.FETCH_DISINFECTION_SA:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_DISINFECTION_SA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_DISINFECTION_SA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.ADD_DISINFECTION:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_DISINFECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.ADD_DISINFECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
