export const FETCH_DASHBOARD_RIVER_ADMIN_CONTROLLERS = "FETCH_DASHBOARD_RIVER_ADMIN_CONTROLLERS";
export const FETCH_DASHBOARD_RIVER_ADMIN_CONTROLLERS_SUCCESS = "FETCH_DASHBOARD_RIVER_ADMIN_CONTROLLERS_SUCCESS";
export const FETCH_DASHBOARD_RIVER_ADMIN_CONTROLLERS_FAILURE = "FETCH_DASHBOARD_RIVER_ADMIN_CONTROLLERS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_RIVER_ADMIN_CONTROLLERS,
    payload
  };
}
