export const FETCH_FREE_PACKAGES = "FETCH_FREE_PACKAGES";
export const FETCH_FREE_PACKAGES_SUCCESS = "FETCH_FREE_PACKAGES_SUCCESS";
export const FETCH_FREE_PACKAGES_FAILURE = "FETCH_FREE_PACKAGES_FAILURE";

export const ADD_FREE_PACKAGE = "ADD_FREE_PACKAGE";
export const ADD_FREE_PACKAGE_SUCCESS = "ADD_FREE_PACKAGE_SUCCESS";
export const ADD_FREE_PACKAGE_FAILURE = "ADD_FREE_PACKAGE_FAILURE";

export const EDIT_FREE_PACKAGE = "EDIT_FREE_PACKAGE";
export const EDIT_FREE_PACKAGE_SUCCESS = "EDIT_FREE_PACKAGE_SUCCESS";
export const EDIT_FREE_PACKAGE_FAILURE = "EDIT_FREE_PACKAGE_FAILURE";

export const REMOVE_FREE_PACKAGE = "REMOVE_FREE_PACKAGE";
export const REMOVE_FREE_PACKAGE_SUCCESS = "REMOVE_FREE_PACKAGE_SUCCESS";
export const REMOVE_FREE_PACKAGE_FAILURE = "REMOVE_FREE_PACKAGE_FAILURE";

export const FETCH_AVAILABLE_FREE_PACKAGES = "FETCH_AVAILABLE_FREE_PACKAGES";
export const FETCH_AVAILABLE_FREE_PACKAGES_SUCCESS = "FETCH_AVAILABLE_FREE_PACKAGES_SUCCESS";
export const FETCH_AVAILABLE_FREE_PACKAGES_FAILURE = "FETCH_AVAILABLE_FREE_PACKAGES_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_FREE_PACKAGES,
    payload
  };
}

export function add(data, payload) {
  return {
    type: ADD_FREE_PACKAGE,
    data,
    payload
  };
}

export function edit(data, payload) {
  return {
    type: EDIT_FREE_PACKAGE,
    data,
    payload
  };
}

export function remove(data, payload) {
  return {
    type: REMOVE_FREE_PACKAGE,
    data,
    payload
  };
}

export function fetchAvailable(payload) {
  return {
    type: FETCH_AVAILABLE_FREE_PACKAGES,
    payload
  };
}
