import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Table, Card, Col, Row, PageHeader, Input, Button, Tag, Select } from "antd";

import Spinner from "../../components/spinner";
import ANT_CONFIG from "../../constants/antconfig";
import { fetch as fetchPages } from "../../redux/pages/actions";
import { DEFAULT_LANGUAGE, LANGUAGES, STATUSES } from "../../constants";
import LanguageDropdown from "../../components/LanguageDropdown/LanguageDropdown";

class Pages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      statusFilter: "",
      language: DEFAULT_LANGUAGE
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchPages());
  }

  onRowClick(id) {
    this.props.history.push(`/content/page/${id}`);
  }

  setLanguage = language => {
    this.setState({
      language
    });
  };

  handleNewPage = () => {
    this.props.history.push(`/content/page/new`);
  };

  handleStatusFilterChange = statusFilter => {
    this.setState({
      statusFilter
    });
  };

  handleFilterChange(filter) {
    this.setState({
      filter
    });
  }

  render() {
    if (!this.props.pages.data) {
      return <Spinner />;
    }

    const { intl, pages } = this.props;
    const { filter, language, statusFilter } = this.state;
    let { data } = pages;

    if (filter) {
      data = data.filter(i => {
        const f = filter.toLowerCase();

        return i.id?.toString().includes(f) || i.name?.toLowerCase().includes(f) || i.publish_date?.toLowerCase().includes(f);
      });
    }

    if (language) {
      data = data.filter(i => i.language === language);
    }

    if (statusFilter) {
      data = data.filter(i => i.status === statusFilter);
    }

    const statusArray = [
      {
        id: "",
        name: intl.formatMessage({ id: "all" })
      },
      ...STATUSES.map(i => ({
        id: i,
        name: intl.formatMessage({ id: i })
      }))
    ];

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key"
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
      },
      {
        title: intl.formatMessage({ id: "title" }),
        dataIndex: "name",
        key: "name"
      },
      {
        title: intl.formatMessage({ id: "language" }),
        dataIndex: "language",
        key: "language"
      },
      {
        title: intl.formatMessage({ id: "status" }),
        dataIndex: "status",
        key: "status"
      },
      {
        title: intl.formatMessage({ id: "publish_date" }),
        dataIndex: "publish_date",
        key: "publish_date"
      }
    ];

    const tableData = data.map((i, key) => {
      let color = "green";

      if (i.status === "draft") {
        color = "orange";
      }

      if (i.status === "trash") {
        color = "red";
      }

      if (i.status === "future") {
        color = "blue";
      }

      return {
        key: key + 1,
        id: i.id,
        name: i.name,
        language: LANGUAGES.filter(l => l.value === i.language)[0].label,
        status: <Tag color={color}>{intl.formatMessage({ id: i.status })}</Tag>,
        publish_date: i.publish_date
      };
    });

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "pages" })}
          extra={[
            <Button onClick={this.handleNewPage} type="primary" key="1" size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "add_new_page" })}
            </Button>
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search" className="ant-label">
                    {intl.formatMessage({ id: "search" })}
                  </label>
                  <Input
                    placeholder={intl.formatMessage({ id: "search" })}
                    id="search"
                    size={ANT_CONFIG.size}
                    value={filter}
                    onChange={e => this.handleFilterChange(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="select_status" className="ant-label">
                    {intl.formatMessage({ id: "status" })}
                  </label>
                  <Select
                    id="select_status"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    showSearch
                    defaultValue={intl.formatMessage({ id: "all" })}
                    optionFilterProp="children"
                    onChange={this.handleStatusFilterChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {statusArray.map(i => (
                      <Select.Option key={i.id} value={i.id}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={12} sm={2} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search" className="ant-label">
                    {intl.formatMessage({ id: "language" })}
                  </label>
                  <LanguageDropdown setLanguage={this.setLanguage} />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
                onRow={record => {
                  return {
                    onClick: () => this.onRowClick(record.id)
                  };
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pages: state.Pages
});

export default withRouter(injectIntl(connect(mapStateToProps)(Pages)));
