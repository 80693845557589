import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";
import {
  FETCH_RIVER_CARD_SURVEY_SUMMARY_FAILURE,
  SEND_RIVER_CARD_SURVEY_FAILURE
} from "./actions";

function* send(action) {
  try {
    const response = yield call(API.call, `admin/send-river-card-survey/${action.data.id}/${action.data.final}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: SEND_RIVER_CARD_SURVEY_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.SEND_RIVER_CARD_SURVEY_SUCCESS,
        payload: responseBody
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.SEND_RIVER_CARD_SURVEY_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchSummary(action) {
  try {
    const response = yield call(API.call, `admin/river-card-survey-summary/${action.data.id}/${action.data.final}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: FETCH_RIVER_CARD_SURVEY_SUMMARY_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_RIVER_CARD_SURVEY_SUMMARY_SUCCESS,
        payload: responseBody
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_RIVER_CARD_SURVEY_SUMMARY_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.SEND_RIVER_CARD_SURVEY, send),
    takeEvery(ACTION.FETCH_RIVER_CARD_SURVEY_SUMMARY, fetchSummary),
  ]);
}
