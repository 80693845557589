import * as ACTION from './actions';

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_DASHBOARD_CONTROLLER_ADMIN:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_DASHBOARD_CONTROLLER_ADMIN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_DASHBOARD_CONTROLLER_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
