export const FETCH_BEAT = "FETCH_BEAT";
export const FETCH_BEAT_SUCCESS = "FETCH_BEAT_SUCCESS";
export const FETCH_BEAT_FAILURE = "FETCH_BEAT_FAILURE";

export const UPDATE_BEAT = "UPDATE_BEAT";
export const UPDATE_BEAT_SUCCESS = "UPDATE_BEAT_SUCCESS";
export const UPDATE_BEAT_FAILURE = "UPDATE_BEAT_FAILURE";

export const ADD_SELLING_BEAT = "ADD_SELLING_BEAT";
export const ADD_SELLING_BEAT_SUCCESS = "ADD_SELLING_BEAT_SUCCESS";
export const ADD_SELLING_BEAT_FAILURE = "ADD_SELLING_BEAT_FAILURE";

export const REMOVE_SELLING_BEAT = "REMOVE_SELLING_BEAT";
export const REMOVE_SELLING_BEAT_SUCCESS = "REMOVE_SELLING_BEAT_SUCCESS";
export const REMOVE_SELLING_BEAT_FAILURE = "REMOVE_SELLING_BEAT_FAILURE";

export function fetch(id) {
  return {
    type: FETCH_BEAT,
    id,
  };
}

export function update(data, id) {
  return {
    type: UPDATE_BEAT,
    data,
    id,
  };
}

export function addSellingBeat(data) {
  return {
    type: ADD_SELLING_BEAT,
    payload: { data },
  };
}

export function removeSellingBeat(data) {
  return {
    type: REMOVE_SELLING_BEAT,
    payload: { data },
  }
}
