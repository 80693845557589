import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faInfo,
  faUser,
  faUserCog,
  faCogs,
  faBullhorn,
  faFile,
  faSignOutAlt,
  faArchive,
  faWrench,
  faClock,
  faFish,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";

import Logo from "../../components/logo";
import { authLogout } from "../../redux/authentication/actions";
import RolesContext from "../../context/RolesContext";

export class Navigation extends React.Component {
  static contextType = RolesContext;

  state = {
    productsDropdown: this.props.location.pathname.includes("/product"),
    settingsDropdown: this.props.location.pathname.includes("/settings"),
    reportsDropdown: this.props.location.pathname.includes("/reports"),
    contentDropdown: this.props.location.pathname.includes("/content"),
    ordersDropdown: this.props.location.pathname.includes("/orders"),
  };

  logout() {
    this.props.dispatch(authLogout());
  }

  hideDropdowns() {
    this.setState({
      productsDropdown: false,
      settingsDropdown: false,
      reportsDropdown: false,
      contentDropdown: false,
      ordersDropdown: false,
    });
  }

  toggleProductMenu() {
    this.setState((prevState) => ({
      settingsDropdown: false,
      reportsDropdown: false,
      contentDropdown: false,
      productsDropdown: !prevState.productsDropdown,
      ordersDropdown: false,
    }));
  }

  toggleSettingsMenu() {
    this.setState((prevState) => ({
      productsDropdown: false,
      reportsDropdown: false,
      contentDropdown: false,
      settingsDropdown: !prevState.settingsDropdown,
      ordersDropdown: false,
    }));
  }

  toggleReportsMenu() {
    this.setState((prevState) => ({
      productsDropdown: false,
      settingsDropdown: false,
      contentDropdown: false,
      reportsDropdown: !prevState.reportsDropdown,
      ordersDropdown: false,
    }));
  }

  toggleContentMenu() {
    this.setState((prevState) => ({
      productsDropdown: false,
      settingsDropdown: false,
      reportsDropdown: false,
      contentDropdown: !prevState.contentDropdown,
      ordersDropdown: false,
    }));
  }

  toggleOrdersMenu() {
    this.setState((prevState) => ({
      productsDropdown: false,
      settingsDropdown: false,
      reportsDropdown: false,
      contentDropdown: false,
      ordersDropdown: !prevState.ordersDropdown,
    }));
  }

  render() {
    const { isAdmin, isRiverAdmin, isBeatAdmin, isStationAdmin, isAccountant, isSeller, isSupervisorAdmin } = this.context;

    return (
      <nav className={`nav-sidebar ${this.props.isMenuHidden ? "hidden" : ""}`} role="navigation">
        <NavLink exact to="/">
          <Logo />
        </NavLink>
        <ul
          className={`level-1 ${this.state.productsDropdown || this.state.settingsDropdown ? "active" : ""}`}
          style={{ marginTop: 30 }}
        >
          {isAdmin || isBeatAdmin || isSupervisorAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                exact
                to="/"
                title={this.props.intl.formatMessage({ id: "dashboard" })}
              >
                <i>
                  <FontAwesomeIcon icon={faHome} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "dashboard" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isBeatAdmin || isSeller ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/booking"
                title={this.props.intl.formatMessage({ id: "booking" })}
              >
                <i>
                  <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "booking" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isBeatAdmin || isRiverAdmin ? (
            <li>
              <button
                title={this.props.intl.formatMessage({ id: "products" })}
                type="button"
                className={this.state.productsDropdown ? "active" : ""}
                onClick={() => this.toggleProductMenu()}
              >
                <i>
                  <FontAwesomeIcon icon={faArchive} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "products" })}
              </button>
              {this.state.productsDropdown && isAdmin ? (
                <ul className="level-2">
                  <li>
                    <NavLink to="/product/fishing-cards">{this.props.intl.formatMessage({ id: "menu_fishing_card" })}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/accommodation">{this.props.intl.formatMessage({ id: "menu_accommodation" })}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/fishing-packages">
                      {this.props.intl.formatMessage({ id: "fishing_packages" })}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/packages">{this.props.intl.formatMessage({ id: "packs" })}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/season-cards">{this.props.intl.formatMessage({ id: "season_cards" })}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/river-cards">{this.props.intl.formatMessage({ id: "river_cards" })}</NavLink>
                  </li>
                </ul>
              ) : null}
              {this.state.productsDropdown && isBeatAdmin && !isAdmin ? (
                <ul className="level-2">
                  <li>
                    <NavLink to="/product/fishing-cards">{this.props.intl.formatMessage({ id: "menu_fishing_card" })}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/accommodation">{this.props.intl.formatMessage({ id: "menu_accommodation" })}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/fishing-packages">
                      {this.props.intl.formatMessage({ id: "fishing_packages" })}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/packages">{this.props.intl.formatMessage({ id: "packs" })}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/product/season-cards">{this.props.intl.formatMessage({ id: "season_cards" })}</NavLink>
                  </li>
                </ul>
              ) : null}
              {this.state.productsDropdown && isRiverAdmin && !isAdmin ? (
                <ul className="level-2">
                  <li>
                    <NavLink to="/product/river-cards">{this.props.intl.formatMessage({ id: "river_cards" })}</NavLink>
                  </li>
                </ul>
              ) : null}
            </li>
          ) : null}

          {isAdmin || isBeatAdmin || isRiverAdmin || isSeller || isSupervisorAdmin ? (
            <li>
              <button
                title={this.props.intl.formatMessage({ id: "order" })}
                type="button"
                className={this.state.ordersDropdown ? "active" : ""}
                onClick={() => this.toggleOrdersMenu()}
              >
                <i>
                  <FontAwesomeIcon icon={faInfo} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "orders" })}
              </button>
              <ul className="level-2">
                {this.state.ordersDropdown && (isAdmin || isBeatAdmin || isSeller) ? (
                  <li>
                    <NavLink to="/orders" title={this.props.intl.formatMessage({ id: "beat_orders" })}>
                      {this.props.intl.formatMessage({ id: "beat_orders" })}
                    </NavLink>
                  </li>
                ) : null}
                {this.state.ordersDropdown && (isAdmin || isRiverAdmin || isSupervisorAdmin) ? (
                  <li>
                    <NavLink to="/orders-river" title={this.props.intl.formatMessage({ id: "river_orders" })}>
                      {this.props.intl.formatMessage({ id: "river_orders" })}
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </li>
          ) : null}

          {isAdmin || isRiverAdmin || isStationAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/disinfection"
                title={this.props.intl.formatMessage({ id: "disinfection" })}
              >
                <i>
                  <FontAwesomeIcon icon={faCogs} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "disinfection" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isRiverAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/stations"
                title={this.props.intl.formatMessage({ id: "stations" })}
              >
                <i>
                  <FontAwesomeIcon icon={faBullhorn} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "stations" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isRiverAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/station-users"
                title={this.props.intl.formatMessage({ id: "station_admins" })}
              >
                <i>
                  <FontAwesomeIcon icon={faUserCog} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "station_admins" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isRiverAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/catches"
                title={this.props.intl.formatMessage({ id: "catches" })}
              >
                <i>
                  <FontAwesomeIcon icon={faClock} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "catches" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isBeatAdmin || isRiverAdmin || isSeller || isSupervisorAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/customers"
                title={this.props.intl.formatMessage({ id: "registered_clients" })}
              >
                <i>
                  <FontAwesomeIcon icon={faUser} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "registered_clients" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isRiverAdmin || isBeatAdmin ? (
            <li>
              <button
                title={this.props.intl.formatMessage({ id: "content" })}
                type="button"
                className={this.state.contentDropdown ? "active" : ""}
                onClick={() => this.toggleContentMenu()}
              >
                <i>
                  <FontAwesomeIcon icon={faWrench} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "content" })}
              </button>
              {this.state.contentDropdown ? (
                <ul className="level-2">
                  {isAdmin || isRiverAdmin ? (
                    <li>
                      <NavLink to="/content/river" title={this.props.intl.formatMessage({ id: "rivers" })}>
                        {this.props.intl.formatMessage({ id: "rivers" })}
                      </NavLink>
                    </li>
                  ) : null}

                  {isAdmin || isBeatAdmin ? (
                    <li>
                      <NavLink to="/content/beat" title={this.props.intl.formatMessage({ id: "beats" })}>
                        {this.props.intl.formatMessage({ id: "beats" })}
                      </NavLink>
                    </li>
                  ) : null}

                  {isAdmin ? (
                    <li>
                      <NavLink to="/content/articles" title={this.props.intl.formatMessage({ id: "articles" })}>
                        {this.props.intl.formatMessage({ id: "articles" })}
                      </NavLink>
                    </li>
                  ) : null}

                  {isAdmin ? (
                    <li>
                      <NavLink to="/content/pages" title={this.props.intl.formatMessage({ id: "pages" })}>
                        {this.props.intl.formatMessage({ id: "pages" })}
                      </NavLink>
                    </li>
                  ) : null}
                  {isAdmin ? (
                    <li>
                      <NavLink to="/content/newsfeed" title={this.props.intl.formatMessage({ id: "newsfeed" })}>
                        {this.props.intl.formatMessage({ id: "newsfeed" })}
                      </NavLink>
                    </li>
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null}

          {isAdmin || isBeatAdmin || isAccountant || isRiverAdmin ? (
            <li>
              <button
                title={this.props.intl.formatMessage({ id: "reports" })}
                type="button"
                className={this.state.reportsDropdown ? "active" : ""}
                onClick={() => this.toggleReportsMenu()}
              >
                <i>
                  <FontAwesomeIcon icon={faFile} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "reports" })}
              </button>
              {this.state.reportsDropdown ? (
                <ul className="level-2">
                  {isAdmin || isBeatAdmin ? (
                    <li>
                      <NavLink to="/reports/my-billings">{this.props.intl.formatMessage({ id: "my_billings" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isAccountant ? (
                    <>
                      <li>
                        <NavLink to="/reports/platform-reports">
                          {this.props.intl.formatMessage({ id: "platform_reports" })}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reports/all-beats-economy-report">
                          {this.props.intl.formatMessage({ id: "all_beats_economy_report" })}
                        </NavLink>
                      </li>
                    </>
                  ) : null}
                  {isAdmin || isBeatAdmin || isAccountant ? (
                    <>
                      <li>
                        <NavLink to="/reports/beat-economy-report">
                          {this.props.intl.formatMessage({ id: "economy_beat_reports" })}
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/reports/beat-product-type-price">
                          {this.props.intl.formatMessage({ id: "beat_product_type_price" })}
                        </NavLink>
                      </li>
                    </>
                  ) : null}

                  {isAdmin || isBeatAdmin ? (
                    <li style={{ textTransform: "capitalize" }}>
                      <NavLink to="/reports/fishing-cards">{this.props.intl.formatMessage({ id: "fishing_cards" })}</NavLink>
                    </li>
                  ) : null}

                  {isAdmin || isBeatAdmin ? (
                    <li style={{ textTransform: "capitalize" }}>
                      <NavLink to="/reports/season-cards">{this.props.intl.formatMessage({ id: "season_cards" })}</NavLink>
                    </li>
                  ) : null}

                  {isAdmin || isAccountant || isRiverAdmin ? (
                    <li style={{ textTransform: "capitalize" }}>
                      <NavLink to="/reports/river-cards">{this.props.intl.formatMessage({ id: "river_cards" })}</NavLink>
                    </li>
                  ) : null}

                  {/* Temporarily adding these reports only for super admins.
                  They have some problems that must be fixed without all other admin being able to see them,
                  but super admins need them for debugging purposes*/}
                  {isAdmin ? (
                    <>
                      <li>
                        <NavLink to="/reports/beat-product-count">
                          {this.props.intl.formatMessage({ id: "beat_product_count" })}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reports/seller-payment-method">
                          {this.props.intl.formatMessage({ id: "seller_payment_method" })}
                        </NavLink>
                      </li>
                    </>
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null}

          {isAdmin || isBeatAdmin || isRiverAdmin ? (
            <li>
              <button
                title={this.props.intl.formatMessage({ id: "settings" })}
                type="button"
                className={this.state.settingsDropdown ? "active" : ""}
                onClick={() => this.toggleSettingsMenu()}
              >
                <i>
                  <FontAwesomeIcon icon={faWrench} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "settings" })}
              </button>
              {this.state.settingsDropdown ? (
                <ul className="level-2">
                  {isAdmin ? (
                    <li>
                      <NavLink to="/settings/catch-limits">{this.props.intl.formatMessage({ id: "catch_limits" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin ? (
                    <li>
                      <NavLink to="/settings/taxes">{this.props.intl.formatMessage({ id: "taxes" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isBeatAdmin ? (
                    <li>
                      <NavLink to="/settings/beats">{this.props.intl.formatMessage({ id: "beat" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isBeatAdmin ? (
                    <li>
                      <NavLink to="/settings/cancellation-policy">
                        {this.props.intl.formatMessage({ id: "cancellation_policy" })}
                      </NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isRiverAdmin ? (
                    <li>
                      <NavLink to="/settings/rivers">{this.props.intl.formatMessage({ id: "rivers" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isBeatAdmin || isRiverAdmin ? (
                    <li>
                      <NavLink to="/settings/card-limits">{this.props.intl.formatMessage({ id: "card_limit" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin ? (
                    <li>
                      <NavLink to="/settings/catch-report">
                        {this.props.intl.formatMessage({ id: "catch_report_settings" })}
                      </NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isRiverAdmin ? (
                    <li>
                      <NavLink to="/settings/required-catch-fields">
                        {this.props.intl.formatMessage({ id: "required_catch_fields" })}
                      </NavLink>
                    </li>
                  ) : null}
                  {isAdmin ? (
                    <li>
                      <NavLink to="/settings/fish-types">{this.props.intl.formatMessage({ id: "fish_types" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin ? (
                    <li>
                      <NavLink to="/settings/equipment">{this.props.intl.formatMessage({ id: "equipment" })}</NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isRiverAdmin ? (
                    <li>
                      <NavLink to="/settings/equipment-configuration">
                        {this.props.intl.formatMessage({ id: "equipment_configuration" })}
                      </NavLink>
                    </li>
                  ) : null}
                  {isAdmin || isRiverAdmin ? (
                    <li>
                      <NavLink to="/settings/zones">{this.props.intl.formatMessage({ id: "zones" })}</NavLink>
                    </li>
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null}

          {isAdmin || isBeatAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/card-changes"
                title={this.props.intl.formatMessage({ id: "card_changes" })}
              >
                <i>
                  <FontAwesomeIcon icon={faInfo} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "card_changes" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin || isRiverAdmin || isBeatAdmin ? (
            <li>
              <NavLink
                onClick={() => this.hideDropdowns()}
                to="/send-email"
                title={this.props.intl.formatMessage({ id: "send_email" })}
              >
                <i>
                  <FontAwesomeIcon icon={faMailBulk} style={{ fontSize: 18 }} />
                </i>
                {this.props.intl.formatMessage({ id: "send_email" })}
              </NavLink>
            </li>
          ) : null}

          {isAdmin ? (
            <li>
              <NavLink onClick={() => this.hideDropdowns()} to="/fangstrapp" title="Fangstrapp">
                <i>
                  <FontAwesomeIcon icon={faFish} style={{ fontSize: 18 }} />
                </i>
                Fangstrapp
              </NavLink>
            </li>
          ) : null}

          <li>
            <button type="button" onClick={() => this.logout()} title={this.props.intl.formatMessage({ id: "logout" })}>
              <i>
                <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: 18 }} />
              </i>
              {this.props.intl.formatMessage({ id: "logout" })}
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Navigation)));
