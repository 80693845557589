import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  customer: undefined,
  user: undefined,
  types: undefined,
  new_user: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.FETCH_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.FETCH_STATION_ADMIN:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_STATION_ADMIN_SUCCESS:
      return {
        ...state,
        customer: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_STATION_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.ADD_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        user: action.payload.data.user,
        loading: false,
        error: false,
      };

    case ACTION.ADD_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.EDIT_CUSTOMER:
      return {
        ...state,
        loading: true,
      };

    case ACTION.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.EDIT_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.QUITE_ADD:
      return {
        ...state,
        loading: true,
      };

    case ACTION.QUITE_ADD_SUCCESS:
      return {
        ...state,
        new_user: action.payload.data.user,
        loading: false,
        error: false,
      };

    case ACTION.QUITE_ADD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.QUITE_REMOVE:
      return {
        ...state,
        new_user: undefined,
        loading: false,
        error: false,
      };

    case ACTION.UNBLOCK_CUSTOMER:
      return {
        ...state,
        loading: true,
      };
    
    case ACTION.UNBLOCK_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UNBLOCK_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
