export const FETCH_BILLINGS = "FETCH_BILLINGS";
export const FETCH_BILLINGS_SUCCESS = "FETCH_BILLINGS_SUCCESS";
export const FETCH_BILLINGS_FAILURE = "FETCH_BILLINGS_FAILURE";

export function fetch(data) {
  return {
    type: FETCH_BILLINGS,
    data,
  };
}
