export const FETCH_STATION_ADMINS = "FETCH_STATION_ADMINS";
export const FETCH_STATION_ADMINS_SUCCESS = "FETCH_STATION_ADMINS_SUCCESS";
export const FETCH_STATION_ADMINS_FAILURE = "FETCH_STATION_ADMINS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_STATION_ADMINS,
    payload
  };
}
