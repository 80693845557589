import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_CATCH_LIMITS:
      return {
        ...state,
        loading: true,
        articleId: null,
      };

    case ACTION.FETCH_CATCH_LIMITS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_CATCH_LIMITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.STORE_CATCH_LIMITS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.STORE_CATCH_LIMITS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.STORE_CATCH_LIMITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
