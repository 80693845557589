import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* add(action) {
  try {
    const response = yield call(API.call, "admin/customer/station", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_STATION_ROLE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.ADD_STATION_ROLE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_STATION_ROLE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, `admin/customer/station/${action.userId}/${action.stationId}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_STATION_ROLE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.REMOVE_STATION_ROLE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_STATION_ROLE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* massRemove(action) {
  try {
    const response = yield call(API.call, `admin/customer/station/remove-all/${action.userId}`, "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.MASS_REMOVE_STATION_ROLE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.MASS_REMOVE_STATION_ROLE_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.MASS_REMOVE_STATION_ROLE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.ADD_STATION_ROLE, add), takeEvery(ACTION.REMOVE_STATION_ROLE, remove), takeEvery(ACTION.MASS_REMOVE_STATION_ROLE, massRemove)]);
}
