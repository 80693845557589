import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Alert } from "antd";

import Spinner from "../../../spinner";
import SelectInput from "../../../select";
import { add as addBooking } from "../../../../redux/booking/actions";
import { quiteAdd } from "../../../../redux/customers/actions";
import APIMessage from "../../../../services/api_messages";
import SaveLabel from "../../../helpers/buttons/save_label";
import { fetchAvailable as fetchAccommodations, fetchBlueprint, fetchPrices } from "../../../../redux/accommodation/actions";
import transactionFee from "../../../../services/transaction_fee";
import { DEFAULT_LANGUAGE } from "../../../../constants";
import translations from "../../../../constants/strings";

const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;

const labelWidth = 4;
const inputWidth = 8;

class ModalOrderAccommodation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      beat_id: props.selectedBeat,
      date_start: props.modalData.date_start || null,
      date_end: props.modalData.date_end || null,
      quantity: 1,
      currentPage: 0,
      show_new_user_form: false,
      type_id: null,
      prices: null,
      price_type: null,
      user: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
      },
      additional_user: null,
      payment_method: false,
      payment_method_selected: null,
    };
  }

  componentDidMount() {
    this.fetchData();
    if (!this.props.accommodation.blueprint) {
      this.props.dispatch(
        fetchBlueprint({
          beat_ids: this.props.authentication.data.beat,
        }),
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers.new_user !== this.props.customers.new_user) {
      this.onComponentUpdate();
    }

    if (prevProps.accommodation.available !== this.props.accommodation.available && this.state.type_id) {
      this.fetchPrices();
    }
  }

  onComponentUpdate() {
    this.setState({
      user: {
        id: this.props.customers.new_user.id,
        first_name: this.props.customers.new_user.first_name,
        last_name: this.props.customers.new_user.last_name,
        email: this.props.customers.new_user.email,
      },
    });
  }

  onChangeQuantity(quantity) {
    this.setState({ quantity });
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({
      data: { ...prevState.data, ...newState },
    }));
  }

  onSubmit(fee) {
    if (!this.state.user.first_name || !this.state.user.last_name) {
      return APIMessage.error(translations.error[lang].select_user);
    }

    const freeAccommodations = this.props.accommodation.available
      .filter((i) => i.accommodations.length > 0 && i.accommodations[0].blueprint_id === this.state.type_id)
      .map((i) => i.accommodations)[0];

    const items = freeAccommodations.map((i) => {
      const id = parseInt(i.id, 10);

      return {
        id,
        number_of_guests: parseInt(this.state.quantity, 10),
        price_amount_id: i.price_options.filter((i) => i.price_type_id === this.state.price_type)[0].id,
      };
    });

    const data = {
      user_id: this.state.user.id,
      payment_method: this.state.payment_method ? this.state.payment_method_selected : "nets",
      accommodations: items,
    };

    this.setState({
      submitted: true,
    });

    this.props.dispatch(addBooking(data));
  }

  onChangeType(id) {
    this.setState({ type_id: id }, () => {
      this.fetchPrices();
    });
  }

  onPriceSelect(e, item) {
    const price = item.prices.filter((i) => i.id === e);

    this.setState((prevState) => ({
      price_type: price[0].price_type_id,
      prices: {
        ...prevState.prices,
        [item.id]: {
          ...price[0],
          price_amount_id: price[0].id,
        },
      },
    }));
  }

  onSelectUser(e) {
    const customer = this.props.customers.data.filter((i) => i.id === e)[0];

    if (customer) {
      this.setState({
        user: {
          id: customer.id,
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
        },
      });
    }
  }

  onChangeUserInput(newState) {
    this.setState((prevState) => ({
      user: { ...prevState.user, ...newState },
    }));
  }

  onSaveNewUser() {
    this.props.dispatch(
      quiteAdd({
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        email: this.state.user.email,
      }),
    );
  }

  changePaymentMethod(e) {
    this.setState({
      payment_method_selected: e.target.value,
    });
  }

  fetchData() {
    if (this.state.date_start && this.state.date_end && this.state.beat_id) {
      this.props.dispatch(
        fetchAccommodations({
          beat_id: this.state.beat_id,
          date_start: this.state.date_start,
          date_end: this.state.date_end,
        }),
      );
    }
  }

  fetchPrices() {
    this.setState({
      prices: null,
    });

    if (this.props.accommodation.available) {
      let ids = this.props.accommodation.available.filter((i) => {
        if (i.accommodations.length > 0) {
          return i.accommodations[0].blueprint_id === this.state.type_id;
        }

        return true;
      });
      if (ids.length > 0) {
        ids = ids
          .filter((i) => i.id === this.state.type_id)
          .map((i) => i.accommodations)[0]
          .map((i) => i.id);
      }
      if (ids.length > 0) {
        this.props.dispatch(fetchPrices({ ids }));
      }
    }
  }

  handleChangeDate(e) {
    const { name } = e.target;

    if (name === "date_start") {
      this.setState({ date_start: e.target.value }, () => {
        this.fetchData();
      });
    }

    if (name === "date_end") {
      this.setState({ date_end: e.target.value }, () => {
        this.fetchData();
      });
    }
  }

  addNewUser() {
    this.setState((prevState) => ({
      show_new_user_form: true,
      user: {
        ...prevState.user,
        id: null,
      },
    }));
  }

  toggle() {
    this.props.toggleModal();
  }

  render() {
    const selectCustomers = [
      ...this.props.customers.data.map((i) => {
        return {
          value: i.id,
          label: `${i.first_name} ${i.last_name} (${i.email})`,
        };
      }),
    ];

    if (this.props.booking.loading) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    if (this.props.accommodation.loading || !this.props.accommodation.available || !this.props.accommodation.blueprint) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    const selectAccommodationTypes = this.props.accommodation.blueprint[this.state.beat_id].map((i) => {
      return {
        value: i.id,
        label: i.name,
      };
    });

    if (this.props.booking.data && !this.props.booking.loading && !this.props.booking.error && this.state.submitted) {
      if (this.state.payment_method) {
        this.toggle();

        return false;
      }

      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "payment_link" })}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} sm={10}>
                <Input type="text" defaultValue={this.props.booking.data.terminal_urls.url} disabled />
              </Col>
              <Col xs={12} sm={2}>
                <a
                  href={this.props.booking.data.terminal_urls.url}
                  className="btn btn-success"
                  style={{ width: "100%", lineHeight: "24px" }}
                >
                  {this.props.intl.formatMessage({ id: "pay_now" })}
                </a>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      );
    }

    let itemPrices = [];
    let accommodations = [];

    if (this.props.accommodation.prices.data) {
      itemPrices = this.props.accommodation.prices.data.slice(0, 1);
    }

    const error = {};

    if (this.props.accommodation.available) {
      accommodations = this.props.accommodation.available.filter((i) => {
        if (i.accommodations.length > 0) {
          return i.accommodations[0].blueprint_id === this.state.type_id;
        }

        return false;
      });
    }

    const capacityCheck = accommodations[0] ? accommodations[0].accommodations.length : 0;
    const daysBetween = moment(this.state.date_end).diff(moment(this.state.date_start), "days") + 1;

    if (daysBetween !== capacityCheck) {
      error.capacity = true;
    }
    if (this.state.quantity < 1) {
      error.quantity = true;
    }
    if (accommodations[0]) {
      if (accommodations[0].capacity < this.state.quantity) {
        error.quantity = true;
      }
    }

    let totalPrice = 0;

    if (this.state.prices) {
      const prices = this.props.accommodation.prices.data
        .map((i) => i.prices)
        .flat(1)
        .filter((i) => i.price_type_id === this.state.price_type);

      prices.map((i) => {
        for (let j = 0; j < this.state.quantity; j += 1) {
          if (j === 0) {
            totalPrice += parseFloat(i.amount);
          }

          if (j > 0 && (i.extra || i.extra_per_person)) {
            totalPrice += parseFloat(i.extra || i.extra_per_person);
          }
        }

        return totalPrice;
      });
    }

    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col xs={12}>
                {this.props.accommodation.loading ? (
                  <FormGroup row>
                    <Label for="select_accommodation" xs={12} sm={labelWidth}>
                      {`${this.props.intl.formatMessage({
                        id: "select_accommodation",
                      })}`}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Spinner width={30} style={{ height: 53, marginTop: -15 }} />
                    </Col>
                  </FormGroup>
                ) : (
                  <FormGroup row>
                    <Label for="select_accommodation" xs={12} sm={labelWidth}>
                      {`${this.props.intl.formatMessage({
                        id: "select_accommodation",
                      })}`}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <SelectInput
                        id="select_accommodation"
                        name="select_accommodation"
                        intl={this.props.intl}
                        value={selectAccommodationTypes.filter((i) => i.value === this.state.type_id)}
                        onChange={(e) => this.onChangeType(e.value)}
                        options={selectAccommodationTypes}
                      />
                    </Col>
                  </FormGroup>
                )}

                <FormGroup row>
                  <Label for="date_start" xs={12} sm={labelWidth}>
                    {this.props.intl.formatMessage({ id: "date_start" })}
                  </Label>
                  <Col xs={12} sm={inputWidth}>
                    <Input
                      type="date"
                      name="date_start"
                      id="date_start"
                      value={this.state.date_start}
                      onChange={(e) => this.handleChangeDate(e)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="date_end" xs={12} sm={labelWidth}>
                    {this.props.intl.formatMessage({ id: "date_end" })}
                  </Label>
                  <Col xs={12} sm={inputWidth}>
                    <Input
                      type="date"
                      name="date_end"
                      id="date_end"
                      value={this.state.date_end}
                      onChange={(e) => this.handleChangeDate(e)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12} sm={labelWidth} />
                  <Col xs={12} sm={inputWidth}>
                    <Checkbox
                      checked={this.state.payment_method}
                      onChange={(event) => {
                        const checked = event.target.checked;

                        if (checked) this.setState({ payment_method: true, payment_method_selected: "manual" });
                        else this.setState({ payment_method: false, payment_method_selected: null });
                      }}
                    >
                      {this.props.intl.formatMessage({
                        id: "payment_performed_manually",
                      })}
                    </Checkbox>
                  </Col>
                </FormGroup>
                {error.capacity && this.state.type_id ? (
                  <FormGroup row>
                    <Col xs={12} sm={labelWidth} />
                    <Col xs={12} sm={inputWidth} className="col-error">
                      <div>{translations.error[lang].dates_unavailable}</div>
                    </Col>
                  </FormGroup>
                ) : null}

                {this.state.type_id && !error.capacity ? (
                  <div>
                    <hr />
                    <FormGroup row>
                      <Label for="quantity" xs={12} sm={labelWidth}>
                        {this.props.intl.formatMessage({ id: "quantity" })}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <Input
                          type="number"
                          name="quantity"
                          id="quantity"
                          value={this.state.quantity}
                          onChange={(e) => this.onChangeQuantity(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    {this.state.show_new_user_form ? (
                      <FormGroup row>
                        <Label for="or_add_new_customer" xs={12} sm={labelWidth}>
                          {`${this.props.intl.formatMessage({
                            id: "new_customer",
                          })}`}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <FormGroup row>
                            <Col xs={12} sm={6}>
                              <Input
                                type="text"
                                disabled={this.props.customers.new_user}
                                placeholder={this.props.intl.formatMessage({
                                  id: "first_name",
                                })}
                                value={this.state.user.first_name}
                                onChange={(e) =>
                                  this.onChangeUserInput({
                                    first_name: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col xs={12} sm={6}>
                              <Input
                                type="text"
                                disabled={this.props.customers.new_user}
                                placeholder={this.props.intl.formatMessage({
                                  id: "last_name",
                                })}
                                value={this.state.user.last_name}
                                onChange={(e) =>
                                  this.onChangeUserInput({
                                    last_name: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col xs={12}>
                              <Input
                                type="email"
                                disabled={this.props.customers.new_user}
                                placeholder={this.props.intl.formatMessage({
                                  id: "email",
                                })}
                                value={this.state.user.email}
                                onChange={(e) =>
                                  this.onChangeUserInput({
                                    email: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          </FormGroup>
                          {this.props.customers.loading ? (
                            <FormGroup row>
                              <Col xs={12}>
                                <Spinner />
                              </Col>
                            </FormGroup>
                          ) : null}
                          {!this.props.customers.loading && !this.props.customers.new_user ? (
                            <FormGroup row>
                              <Col xs={12}>
                                <Button color="secondary" onClick={() => this.onSaveNewUser()} style={{ width: "100%" }}>
                                  <SaveLabel intl={this.props.intl} />
                                </Button>
                              </Col>
                            </FormGroup>
                          ) : null}
                        </Col>
                      </FormGroup>
                    ) : (
                      <div>
                        <FormGroup row>
                          <Label for="select_customer" xs={12} sm={labelWidth}>
                            {`${this.props.intl.formatMessage({
                              id: "select_customer",
                            })}`}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <SelectInput
                              id="select_customer"
                              name="select_customer"
                              intl={this.props.intl}
                              value={selectCustomers.filter((i) => i.value === this.state.user.id)}
                              onChange={(e) => this.onSelectUser(e.value)}
                              options={selectCustomers}
                            />
                          </Col>
                        </FormGroup>
                        {!this.state.user.id ? (
                          <FormGroup row>
                            <Col xs={12} sm={labelWidth} />
                            <Col xs={12} sm={inputWidth}>
                              <Button color="success" onClick={() => this.addNewUser()} style={{ width: "100%" }}>
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  style={{
                                    fontSize: 15,
                                    marginRight: 10,
                                  }}
                                />
                                {this.props.intl.formatMessage({
                                  id: "add_new_customer",
                                })}
                              </Button>
                            </Col>
                          </FormGroup>
                        ) : null}
                      </div>
                    )}
                    {error.quantity ? (
                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={inputWidth} className="col-error">
                          <div>{translations.error[lang].quantity_unavailable}</div>
                        </Col>
                      </FormGroup>
                    ) : null}
                  </div>
                ) : null}

                {this.props.accommodation.prices.loading ? (
                  <Spinner />
                ) : (
                  <div>
                    {itemPrices.length > 0 && !error.quantity && !error.capacity && (
                      <FormGroup row>
                        <Label for="select_price" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({
                            id: "select_price",
                          })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <SelectInput
                            id="select_price"
                            name="select_price"
                            intl={this.props.intl}
                            value={itemPrices[0].prices
                              .map((i) => {
                                return {
                                  value: i.id,
                                  label: `${i.description} - ${i.amount}`,
                                };
                              })
                              .filter(
                                (i) =>
                                  i.value ===
                                  (this.state.prices && this.state.prices[itemPrices[0].id]
                                    ? this.state.prices[itemPrices[0].id].id
                                    : null),
                              )}
                            onChange={(e) => this.onPriceSelect(e.value, itemPrices[0])}
                            options={itemPrices[0].prices.map((i) => {
                              return {
                                value: i.id,
                                label: `${i.description} - ${i.amount}`,
                              };
                            })}
                          />
                        </Col>
                      </FormGroup>
                    )}
                  </div>
                )}

                {this.state.prices && !error.quantity && !error.capacity ? (
                  <React.Fragment>
                    <FormGroup row>
                      <Label for="total_price" xs={12} sm={labelWidth}>
                        {this.props.intl.formatMessage({ id: "total_price" })}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <Input
                          type="text"
                          name="total_price"
                          id="total_price"
                          disabled
                          value={parseFloat(totalPrice).toFixed(2)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="fee" xs={12} sm={labelWidth}>
                        {this.props.intl.formatMessage({ id: "fee" })}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <Input
                          type="text"
                          name="fee"
                          id="fee"
                          disabled
                          value={transactionFee(parseFloat(totalPrice).toFixed(2))}
                        />
                      </Col>
                    </FormGroup>
                    {this.state.payment_method ? (
                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={inputWidth}>
                          <Alert
                            message={`${this.props.intl.formatMessage({
                              id: "remember",
                            })}!`}
                            description={this.props.intl.formatMessage({
                              id: "payment_performed_manually_2",
                            })}
                            type="warning"
                            showIcon
                          />
                        </Col>
                      </FormGroup>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          {this.state.type_id &&
          this.state.user.id &&
          this.state.quantity > 0 &&
          this.state.price_type &&
          !error.quantity &&
          !error.capacity ? (
            <ModalFooter>
              <Button color="secondary" onClick={() => this.onSubmit(transactionFee(parseFloat(totalPrice).toFixed(2)))}>
                {this.props.intl.formatMessage({ id: "confirm" })}
              </Button>
            </ModalFooter>
          ) : null}
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.Users,
  authentication: state.Auth,
  accommodation: state.Accommodation,
  booking: state.Booking,
  customers: state.Customers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalOrderAccommodation)));
