export const FETCH_ACCOMMODATION_BLEUPRINT = "FETCH_ACCOMMODATION_BLEUPRINT";
export const FETCH_ACCOMMODATION_BLEUPRINT_SUCCESS = "FETCH_ACCOMMODATION_BLEUPRINT_SUCCESS";
export const FETCH_ACCOMMODATION_BLEUPRINT_FAILURE = "FETCH_ACCOMMODATION_BLEUPRINT_FAILURE";

export const ADD_ACCOMMODATION_BLEUPRINT = "ADD_ACCOMMODATION_BLEUPRINT";
export const ADD_ACCOMMODATION_BLEUPRINT_SUCCESS = "ADD_ACCOMMODATION_BLEUPRINT_SUCCESS";
export const ADD_ACCOMMODATION_BLEUPRINT_FAILURE = "ADD_ACCOMMODATION_BLEUPRINT_FAILURE";

export const EDIT_ACCOMMODATION_BLEUPRINT = "EDIT_ACCOMMODATION_BLEUPRINT";
export const EDIT_ACCOMMODATION_BLEUPRINT_SUCCESS = "EDIT_ACCOMMODATION_BLEUPRINT_SUCCESS";
export const EDIT_ACCOMMODATION_BLEUPRINT_FAILURE = "EDIT_ACCOMMODATION_BLEUPRINT_FAILURE";

export const FETCH_ACCOMMODATIONS = "FETCH_ACCOMMODATIONS";
export const FETCH_ACCOMMODATIONS_SUCCESS = "FETCH_ACCOMMODATIONS_SUCCESS";
export const FETCH_ACCOMMODATIONS_FAILURE = "FETCH_ACCOMMODATIONS_FAILURE";

export const ADD_ACCOMMODATION = "ADD_ACCOMMODATION";
export const ADD_ACCOMMODATION_SUCCESS = "ADD_ACCOMMODATION_SUCCESS";
export const ADD_ACCOMMODATION_FAILURE = "ADD_ACCOMMODATION_FAILURE";

export const EDIT_ACCOMMODATION = "EDIT_ACCOMMODATION";
export const EDIT_ACCOMMODATION_SUCCESS = "EDIT_ACCOMMODATION_SUCCESS";
export const EDIT_ACCOMMODATION_FAILURE = "EDIT_ACCOMMODATION_FAILURE";

export const REMOVE_ACCOMMODATION = "REMOVE_ACCOMMODATION";
export const REMOVE_ACCOMMODATION_SUCCESS = "REMOVE_ACCOMMODATION_SUCCESS";
export const REMOVE_ACCOMMODATION_FAILURE = "REMOVE_ACCOMMODATION_FAILURE";

export const FETCH_AVAILABLE_ACCOMMODATIONS = "FETCH_AVAILABLE_ACCOMMODATIONS";
export const FETCH_AVAILABLE_ACCOMMODATIONS_SUCCESS = "FETCH_AVAILABLE_ACCOMMODATIONS_SUCCESS";
export const FETCH_AVAILABLE_ACCOMMODATIONS_FAILURE = "FETCH_AVAILABLE_ACCOMMODATIONS_FAILURE";

export const FETCH_ACCOMMODATION_PRICES = "FETCH_ACCOMMODATION_PRICES";
export const FETCH_ACCOMMODATION_PRICES_SUCCESS = "FETCH_ACCOMMODATION_PRICES_SUCCESS";
export const FETCH_ACCOMMODATION_PRICES_FAILURE = "FETCH_ACCOMMODATION_PRICES_FAILURE";

export function fetchBlueprint(payload) {
  return {
    type: FETCH_ACCOMMODATION_BLEUPRINT,
    payload
  };
}

export function addBlueprint(data, payload) {
  return {
    type: ADD_ACCOMMODATION_BLEUPRINT,
    data,
    payload
  };
}

export function editBlueprint(data, payload) {
  return {
    type: EDIT_ACCOMMODATION_BLEUPRINT,
    data,
    payload
  };
}

export function fetch(payload) {
  return {
    type: FETCH_ACCOMMODATIONS,
    payload
  };
}

export function add(data, payload) {
  return {
    type: ADD_ACCOMMODATION,
    data,
    payload
  };
}

export function edit(data, payload) {
  return {
    type: EDIT_ACCOMMODATION,
    data,
    payload
  };
}

export function remove(data, payload) {
  return {
    type: REMOVE_ACCOMMODATION,
    data,
    payload
  };
}

export function fetchAvailable(payload) {
  return {
    type: FETCH_AVAILABLE_ACCOMMODATIONS,
    payload
  };
}

export function fetchPrices(payload) {
  return {
    type: FETCH_ACCOMMODATION_PRICES,
    payload
  };
}
