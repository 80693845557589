import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Form, Col, Row, FormGroup, Input, Label, Button } from "reactstrap";

import { editUser } from "../../redux/authentication/actions";
import Spinner from "../../components/spinner";
import { COUNTRY_CODES } from "../../constants";
import ModalChangePassword from "../../components/modal/user/password/edit";
import SelectInput from "../../components/select";
import SaveLabel from "../../components/helpers/buttons/save_label";

const labelWidth = 3;
const inputWidth = 9;

export class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      country: COUNTRY_CODES.filter((country) => country.value === props.authentication.data.country)[0],
      data: {
        ...props.authentication.data,
      },
    };
  }

  onSave(e) {
    e.preventDefault();
    this.props.dispatch(editUser(this.state.data));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onSelectChange(newSelectState, newState) {
    this.setState({
      ...newSelectState,
    });
    this.onChangeValue(newState);
  }

  onChangePostalCode(newState) {
    this.onChangeValue({ post_code: newState });
  }

  toggleModal() {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  }

  render() {
    if (this.props.authentication.loading && !this.state.showModal) {
      return (
        <div className="panel">
          <h2 className="title">{this.props.intl.formatMessage({ id: "my_information" })}</h2>
          <Spinner />
        </div>
      );
    }

    return (
      <div className="panel">
        {this.state.showModal ? <ModalChangePassword intl={this.props.intl} toggleModal={() => this.toggleModal()} /> : null}
        <h2 className="title">{this.props.intl.formatMessage({ id: "my_information" })}</h2>
        <Form>
          <Row>
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Label for="first_name" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "first_name" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={this.state.data.first_name || ""}
                        onChange={(e) => this.onChangeValue({ first_name: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="last_name" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "last_name" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={this.state.data.last_name || ""}
                        onChange={(e) => this.onChangeValue({ last_name: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="phone" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "phone" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="text"
                        name="phone"
                        id="phone"
                        value={this.state.data.phone || ""}
                        onChange={(e) => this.onChangeValue({ phone: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="email" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "email" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input disabled type="email" name="email" id="email" defaultValue={this.props.authentication.data.email || ""} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={6}>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Label for="address" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "address" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        value={this.state.data.address || ""}
                        onChange={(e) => this.onChangeValue({ address: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label xs={12} sm={labelWidth}>
                      {`${this.props.intl.formatMessage({ id: "post_code" })} / ${this.props.intl.formatMessage({ id: "city" })}`}
                    </Label>
                    <Col xs={12} sm={labelWidth}>
                      <Input
                        style={{ marginBottom: 10 }}
                        max={5}
                        type="text"
                        name="post_code"
                        value={this.state.data.post_code || ""}
                        onChange={(e) => this.onChangePostalCode(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} sm={inputWidth - 3}>
                      <Input type="text" name="city" value={this.state.data.city || ""} onChange={(e) => this.onChangeValue({ city: e.target.value })} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="country" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "country" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <SelectInput
                        name="country"
                        id="country"
                        intl={this.props.intl}
                        value={COUNTRY_CODES.map((i) => {
                          return {
                            label: i.value,
                            value: i.value,
                          };
                        }).filter((i) => (this.state.country && i.value === this.state.country.value ? this.state.country.value : null))}
                        onChange={(e) => this.onSelectChange({ country: { ...e } }, { country: e.value })}
                        options={COUNTRY_CODES.map((i) => {
                          return {
                            label: i.value,
                            value: i.value,
                          };
                        })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="birth_date" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "birth_date" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="date"
                        name="birth_date"
                        id="birth_date"
                        value={this.state.data.birth_date || ""}
                        onChange={(e) => this.onChangeValue({ birth_date: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <hr />
        <Row>
          <Col xs={12} className="button-container">
            <Button color="secondary" onClick={(e) => this.onSave(e)}>
              <SaveLabel intl={this.props.intl} />
            </Button>
            <Button color="success" onClick={() => this.toggleModal()}>
              {this.props.intl.formatMessage({ id: "change_password" })}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Profile)));
