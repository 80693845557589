import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, DatePicker, Card, Col, Input, InputNumber, Row, PageHeader, Select, Checkbox, TimePicker } from "antd";
import moment from "moment";

import { add as addCatch } from "../../redux/catches/actions";
import Spinner from "../../components/spinner";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import { fetch as fetchBeats } from "../../redux/beats/actions";
import { fetch as fetchEquipment } from "../../redux/equipment/actions";
import { fetch as fetchFishTypes } from "../../redux/fishtypes/actions";
import { fetch as fetchCustomers } from "../../redux/customers/actions";
import ANT_CONFIG from "../../constants/antconfig";
import riverOptions from "../../services/select_river_options";
import { DEFAULT_LANGUAGE } from "../../constants";

const DATE_FORMAT = "YYYY-MM-DD";

export class NewCatch extends React.Component {
  constructor(props) {
    super(props);
    const URL_PARAMS = new URLSearchParams(window.location.search);

    this.state = {
      data: {
        beat_id: URL_PARAMS.get("b") ? parseInt(URL_PARAMS.get("b"), 10) : null,
        river_id: URL_PARAMS.get("r") ? parseInt(URL_PARAMS.get("r"), 10) : null,
        user_id: URL_PARAMS.get("u") ? parseInt(URL_PARAMS.get("u"), 10) : null,
        fishing_card_id: URL_PARAMS.get("fc"),
        package_id: URL_PARAMS.get("fp"),
        free_package_id: URL_PARAMS.get("p"),
        season_fishing_card_id: URL_PARAMS.get("sc"),
        date: moment().format(DATE_FORMAT),
        external_id: null,
        anonymous: 0,
        adipose_fin_removed: 0,
        boat: 0,
        lice: 0,
        delivered: 0,
        scale_sample: 0,
        removed: 0,
        released_catch: 0,
        no_catch: 0,
        platform_reported_from: "admin"
      },
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (!this.props.equipment.data) {
      this.props.dispatch(fetchEquipment());
    }

    if (!this.props.fishtypes.data) {
      this.props.dispatch(fetchFishTypes());
    }

    if (!this.props.customers.data) {
      this.props.dispatch(fetchCustomers());
    }
  }

  onSubmit() {
    this.props.dispatch(addCatch(this.state.data));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onChangeFishingSpot(spotName) {
    this.onChangeValue({
      fishing_spot: spotName,
      fishing_spot_name: spotName
    });
  }

  weightIsValid() {
    const weight = this.state.data.weight;
    return !weight || (weight >= 0 && weight <= 30)
  }

  formIsValid() {
    return this.weightIsValid()
  }

  onGoBack = () => {
    this.props.history.goBack();
  };

  handleLiceCheckbox(e) {
    this.onChangeValue({ lice: e.target.checked ? 1 : 0 });
    if (!e.target.checked) {
      this.onChangeValue({ lice_count: null });
    }
  }

  handleCheckbox(key, e) {
    this.onChangeValue({ [key]: e.target.checked ? 1 : 0 });
  }

  handleChangeDate(e, key) {
    this.setState((prevState) => ({
      data: { ...prevState.data, [key]: moment(e).format(`${DATE_FORMAT}`) },
    }));
  }

  handleChangeTime(e, key) {
    this.setState((prevState) => ({
      data: { ...prevState.data, [key]: moment(e).format(`HH:mm`) },
    }));
  }

  render() {
    if (!this.state.data || !this.props.rivers.data || !this.props.beats.data) {
      return <Spinner />;
    }

    const customers = this.props.customers?.data?.map((user) => {
      return {
        id: user.id,
        name: `${user.first_name || ""} ${user.last_name || ""}`,
      };
    });
    const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;
    const selectRivers = riverOptions(this.props.rivers, this.props.authentication);
    let filteredBeats = this.props.rivers.data.map((i) => i.beats).reduce((acc, val) => acc.concat(val), []);

    if (this.state.data.river_id) {
      filteredBeats = filteredBeats.filter((beat) => beat.river_id === this.state.data.river_id);
    }

    const selectBeats = filteredBeats?.map((beat) => {
      return {
        value: beat.id,
        label: `${beat.name} (${this.props.rivers.data?.filter((i) => i.id === beat.river_id)?.[0]?.name})`,
      };
    });

    const availableFishingSpots = this.props?.beats?.data?.find((beat) => {
      return beat.id === this.state.data.beat_id;
    })?.fishing_spots;


    const selectFishSex = [
      {
        value: "male",
        label: this.props.intl.formatMessage({ id: "male" }),
      },
      {
        value: "female",
        label: this.props.intl.formatMessage({ id: "female" }),
      },
      {
        value: "unknown",
        label: this.props.intl.formatMessage({ id: "unknown" }),
      },
    ];
    const selectLiceCount = [
      {
        value: "0-10",
        label: "0-10",
      },
      {
        value: "10-20",
        label: "10-20",
      },
      {
        value: "20+",
        label: "20+",
      },
    ];

    return (
      <React.Fragment>
        <PageHeader title={this.props.intl.formatMessage({ id: "new_catch" })} />

        <Row>
          <Col xs={24}>
            <Card style={{ marginTop: 20 }}>
              <div className="container-600">
                {selectRivers?.length > 0 && (
                  <Row>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="select_river">
                        {this.props.intl.formatMessage({ id: "select_river" })}
                      </label>
                      <Select
                        showSearch
                        id="select_river"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_river" })}`}
                        optionFilterProp="children"
                        onChange={(e) => this.onChangeValue({ river_id: e, beat_id: null })}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        value={parseInt(this.state.data.river_id, 10) || ""}
                      >
                        {selectRivers.map((i, key) => (
                          <Select.Option key={key} value={i.value}>
                            {i.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                )}

                {selectBeats?.length > 0 && (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="select_beat">
                        {this.props.intl.formatMessage({ id: "select_beat" })}
                      </label>
                      <Select
                        showSearch
                        id="select_beat"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                        optionFilterProp="children"
                        onChange={(e) => this.onChangeValue({ beat_id: e })}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        value={parseInt(this.state.data.beat_id, 10) || ""}
                      >
                        {selectBeats.map((i, key) => (
                          <Select.Option key={key} value={i.value}>
                            {i.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                )}


                {availableFishingSpots?.length > 0 && (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="select_beat">
                        {this.props.intl.formatMessage({
                          id: "select_fishing_spot",
                        })}
                      </label>
                      <Select
                        showSearch
                        id="select_beat"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({
                          id: "select_fishing_spot",
                        })}`}
                        optionFilterProp="children"
                        onChange={(e) => this.onChangeFishingSpot(e)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.data.fishing_spot}
                      >
                        {availableFishingSpots?.map((spot, key) => (
                          <Select.Option key={key} value={spot.name}>
                            {spot.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                )}

                {this.props.customers.loading ? (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <Spinner />
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      {customers && (
                        <React.Fragment>
                          <label className="ant-label" htmlFor="user_name">
                            {this.props.intl.formatMessage({ id: "user_name" })}
                          </label>
                          <Select
                            showSearch
                            id="user_name"
                            size={ANT_CONFIG.size}
                            style={{ width: "100%" }}
                            placeholder={`${this.props.intl.formatMessage({ id: "user" })}`}
                            optionFilterProp="children"
                            onChange={(e) => this.onChangeValue({ user_id: e })}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={parseInt(this.state.data.user_id, 10) || ""}
                          >
                            {customers.map((customer) => (
                              <Select.Option key={customer.id} value={customer.id}>
                                {`${customer.name} (ID: ${customer.id})`}
                              </Select.Option>
                            ))}
                          </Select>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                )}

                <Row style={{ marginTop: 25 }}>
                  <Col xs={24}>
                    <label className="ant-label" htmlFor="fisher_name">
                      {this.props.intl.formatMessage({ id: "fisher_name" })}
                    </label>
                    <Input
                      id="fisher_name"
                      size={ANT_CONFIG.size}
                      value={this.state.data.external_id || ""}
                      onChange={(e) => this.onChangeValue({ external_id: e.target.value })}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 25 }} gutter={16}>
                  <Col xs={12}>
                    <label className="ant-label" htmlFor="fishing_card_id">
                      {this.props.intl.formatMessage({ id: "fishing_card_id" })}
                    </label>
                    <Input
                      id="fishing_card_id"
                      size={ANT_CONFIG.size}
                      value={this.state.data.fishing_card_id || ""}
                      onChange={(e) => this.onChangeValue({ fishing_card_id: e.target.value })}
                      style={{ width: "100%" }}
                    />
                  </Col>

                  <Col xs={12}>
                    <label className="ant-label" htmlFor="package_id">
                      {this.props.intl.formatMessage({ id: "package_id" })}
                    </label>
                    <Input
                      id="package_id"
                      size={ANT_CONFIG.size}
                      value={this.state.data.package_id || ""}
                      onChange={(e) => this.onChangeValue({ package_id: e.target.value })}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 25 }} gutter={16}>
                  <Col xs={12}>
                    <label className="ant-label" htmlFor="free_package_id">
                      {this.props.intl.formatMessage({ id: "free_package_id" })}
                    </label>
                    <Input
                      id="free_package_id"
                      size={ANT_CONFIG.size}
                      value={this.state.data.free_package_id || ""}
                      onChange={(e) => this.onChangeValue({ free_package_id: e.target.value })}
                      style={{ width: "100%" }}
                    />
                  </Col>

                  <Col xs={12}>
                    <label className="ant-label" htmlFor="season_fishing_card_id">
                      {this.props.intl.formatMessage({ id: "season_fishing_card_id" })}
                    </label>
                    <Input
                      id="season_fishing_card_id"
                      size={ANT_CONFIG.size}
                      value={this.state.data.season_fishing_card_id || ""}
                      onChange={(e) => this.onChangeValue({ season_fishing_card_id: e.target.value })}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 25 }} gutter={16}>
                  <Col xs={12}>
                    <label className="ant-label" htmlFor="date">
                      {this.props.intl.formatMessage({ id: "date" })}
                    </label>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="date"
                      size={ANT_CONFIG.size}
                      allowClear={false}
                      format={ANT_CONFIG.datepicker_format}
                      value={moment(this.state.data.date)}
                      onChange={(e) => this.handleChangeDate(e, "date")}
                    />
                  </Col>
                  <Col xs={12}>
                    <label className="ant-label" htmlFor="time">
                      {this.props.intl.formatMessage({ id: "time" })}
                    </label>
                    <TimePicker
                      allowClear={false}
                      style={{ width: "100%" }}
                      onChange={(e) => this.handleChangeTime(e, "time_of_day")}
                      defaultOpenValue={moment(this.state.data.time_of_day || "00:00:00", "HH:mm:ss")}
                      size={ANT_CONFIG.size}
                      format="HH:mm"
                      value={moment(this.state.data.time_of_day || "00:00:00", "HH:mm:ss")}
                      placeholder=""
                    />
                  </Col>
                </Row>

                {this.props.equipment.loading ? (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <Spinner />
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      {this.props.equipment.data && (
                        <React.Fragment>
                          <label className="ant-label" htmlFor="select_equipment">
                            {this.props.intl.formatMessage({ id: "select_equipment" })}
                          </label>
                          <Select
                            showSearch
                            id="select_equipment"
                            size={ANT_CONFIG.size}
                            style={{ width: "100%" }}
                            placeholder={`${this.props.intl.formatMessage({ id: "select_equipment" })}`}
                            optionFilterProp="children"
                            onChange={(e) => this.onChangeValue({ equipment_id: e })}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={parseInt(this.state.data.equipment_id, 10) || ""}
                          >
                            {this.props.equipment.data.map((i, key) => (
                              <Select.Option key={key} value={i.id}>
                                {lang === "nb" ? i.name_no : i.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                )}

                {this.props.fishtypes.loading ? (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <Spinner />
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      {this.props.fishtypes.data && (
                        <React.Fragment>
                          <label className="ant-label" htmlFor="select_fish_type">
                            {this.props.intl.formatMessage({ id: "select_fish_type" })}
                          </label>
                          <Select
                            showSearch
                            id="select_type"
                            size={ANT_CONFIG.size}
                            style={{ width: "100%" }}
                            placeholder={`${this.props.intl.formatMessage({ id: "select_fish_type" })}`}
                            optionFilterProp="children"
                            onChange={(e) => this.onChangeValue({ fish_type_id: e })}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            value={parseInt(this.state.data.fish_type_id, 10) || ""}
                          >
                            {this.props.fishtypes.data
                              .filter((i) => i.active)
                              .map((i, key) => (
                                <Select.Option key={key} value={i.id}>
                                  {lang === "nb" ? i.name_no : i.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                )}

                <Row style={{ marginTop: 25 }}>
                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <label className="ant-label" htmlFor="select_fish_sex">
                      {this.props.intl.formatMessage({ id: "select_fish_sex" })}
                    </label>
                    <Select
                      showSearch
                      id="select_fish_sex"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({ id: "select_fish_sex" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.onChangeValue({ sex: e })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.data.sex}
                    >
                      {selectFishSex.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>

                <Row style={{ marginTop: 25 }}>
                  <Col xs={24}>
                    <label className="ant-label" htmlFor="length">
                      {this.props.intl.formatMessage({ id: "length" })}
                    </label>
                    <InputNumber
                      placeholder={this.props.intl.formatMessage({ id: "length" })}
                      id="length"
                      size={ANT_CONFIG.size}
                      value={this.state.data.length || ""}
                      onChange={(e) => this.onChangeValue({ length: e })}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: 25 }}>
                  <Col xs={24}>
                    <label className="ant-label" htmlFor="weight">
                      {this.props.intl.formatMessage({ id: "weight" })}
                    </label>
                    <Input
                      placeholder={this.props.intl.formatMessage({ id: "weight" })}
                      id="length"
                      size={ANT_CONFIG.size}
                      value={this.state.data.weight || ""}
                      onChange={(e) => {
                        const cleanWeight = e.target.value.replace(/,/g, ".");
                        this.onChangeValue({ weight: cleanWeight });
                      }}
                      style={{ width: "100%" }}
                    />
                    {!this.weightIsValid() ? (
                      <p style={{color: "red"}}>{this.props.intl.formatMessage({ id: "weight_constraints" })}</p>
                    ) : null}
                  </Col>
                </Row>

                <Row style={{ marginTop: 25 }}>
                  <Col xs={24}>
                    <Checkbox checked={parseInt(this.state.data.lice, 10)} onChange={(e) => this.handleLiceCheckbox(e)}>
                      {this.props.intl.formatMessage({ id: "lice" })}
                    </Checkbox>
                  </Col>
                </Row>

                {parseInt(this.state.data.lice, 10) ? (
                  <Row style={{ marginTop: 25 }}>
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="select_lice_count">
                        {this.props.intl.formatMessage({ id: "select_lice_count" })}
                      </label>
                      <Select
                        showSearch
                        id="select_lice_count"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_lice_count" })}`}
                        optionFilterProp="children"
                        onChange={(e) => this.onChangeValue({ lice_count: e })}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        value={this.state.data.lice_count || ""}
                      >
                        {selectLiceCount.map((i, key) => (
                          <Select.Option key={key} value={i.value}>
                            {i.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                ) : null}

                <Row style={{ marginTop: 25 }} gutter={8}>
                  <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.released_catch, 10)} onChange={(e) => this.handleCheckbox("released_catch", e)}>
                      {this.props.intl.formatMessage({ id: "released_catch" })}
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.boat, 10)} onChange={(e) => this.handleCheckbox("boat", e)}>
                      {this.props.intl.formatMessage({ id: "boat" })}
                    </Checkbox>
                  </Col>

                </Row>

                <Row gutter={8}>
                  <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.adipose_fin_removed, 10)} onChange={(e) => this.handleCheckbox("adipose_fin_removed", e)}>
                      {this.props.intl.formatMessage({ id: "adipose_fin_removed" })}
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.delivered, 10)} onChange={(e) => this.handleCheckbox("delivered", e)}>
                      {this.props.intl.formatMessage({ id: "delivered" })}
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.scale_sample, 10)} onChange={(e) => this.handleCheckbox("scale_sample", e)}>
                      {this.props.intl.formatMessage({ id: "scale_sample" })}
                    </Checkbox>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.no_catch, 10)} onChange={(e) => this.handleCheckbox("no_catch", e)}>
                      {this.props.intl.formatMessage({ id: "no_catch" })}
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={8} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.anonymous, 10)} onChange={(e) => this.handleCheckbox("anonymous", e)}>
                      {this.props.intl.formatMessage({ id: "anonymous" })}
                    </Checkbox>
                  </Col>
                </Row>

                <Row gutter={8} style={{ marginTop: 25 }}>
                  <Col xs={24} style={{ marginTop: 10, marginBottom: 10 }}>
                    <Checkbox checked={parseInt(this.state.data.removed, 10)} onChange={(e) => this.handleCheckbox("removed", e)}>
                      {this.props.intl.formatMessage({ id: "removed" })}
                    </Checkbox>
                    <div style={{ marginTop: 7, fontSize: 13 }}>{this.props.intl.formatMessage({ id: "remove_field_description" })}</div>
                  </Col>
                </Row>

                <Row style={{ marginTop: 25 }}>
                  <Col xs={24} className="text-right">
                    <Button type="danger" size={ANT_CONFIG.size} onClick={this.onGoBack}>
                      {this.props.intl.formatMessage({ id: "cancel" })}
                    </Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: 12 }}
                      disabled={!this.formIsValid()}
                      onClick={(e) => this.onSubmit(e)}
                      loading={this.props.catches.loading}
                      size={ANT_CONFIG.size}
                    >
                      {this.props.intl.formatMessage({ id: "save" })}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  catches: state.Catches,
  rivers: state.Rivers,
  beats: state.Beats,
  equipment: state.Equipment,
  fishtypes: state.FishTypes,
  customers: state.Customers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(NewCatch)));
