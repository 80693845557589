import React from "react";
import { connect } from "react-redux";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import "moment/locale/nb";
import { IntlProvider } from "react-intl";
import _ from "lodash";
import { Container, Row, Col } from "reactstrap";

import strings from "./constants/strings";
import Login from "./containers/user/login";
import PasswordReset from "./containers/user/password_reset";
import FishingCards from "./containers/fishing_cards";
import FishingPackages from "./containers/fishing_packages";
import FreePackages from "./containers/free_packages";
import Accommodation from "./containers/accommodation";
import SeasonCards from "./containers/season_cards";
import RiverCards from "./containers/river_cards";
import { authCheck, authRefreshToken } from "./redux/authentication/actions";
import { fetch as fetchTaxes } from "./redux/taxes/actions";
import { fetchTypes } from "./redux/fishing_packages/actions";
import Navigation from "./containers/navigation";
import Header from "./containers/header";
import IEWarning from "./components/IEWarning";
import Profile from "./containers/user/profile";
import Booking from "./containers/booking";
import Customers from "./containers/customers";
import StationUsers from "./containers/station_users";
import Customer from "./containers/customers/customer";
import Orders from "./containers/orders";
import OrdersRiver from "./containers/orders/river";
import Catches from "./containers/catches";
import SingleCatch from "./containers/catches/single";
import NewCatch from "./containers/catches/new";
import Disinfection from "./containers/disinfection";
import DisinfectionAdd from "./containers/disinfection/add";
import DisinfectionStationAdmin from "./containers/disinfection/stationAdmin";
import Stations from "./containers/stations";
import Station from "./containers/stations/station";
import Fangstrapp from "./containers/Fangstrapp";
import Rivers from "./containers/river";
import SingleRiver from "./containers/river/single";
import Beats from "./containers/beat";
import SingleBeat from "./containers/beat/single";
import Articles from "./containers/article";
import SingleArticle from "./containers/article/single";
import Pages from "./containers/page";
import SinglePage from "./containers/page/single";
import CardChange from "./containers/CardChange";
import ProductCountReport from "./containers/reports/productCount";
import ProductTypePrice from "./containers/reports/productTypePrice";
import SellerPaymentMethod from "./containers/reports/sellerPaymentMethod";
import FishingCardsReport from "./containers/reports/fishingCards";
import SeasonCardsReport from "./containers/reports/seasonCards";
import RiverCardsReport from "./containers/reports/riverCards";
import PlatformReports from "./containers/reports/platformReport";
import EconomyBeatReport from "./containers/reports/economyBeatReport";
import AllBeatsEconomyReport  from "./containers/reports/allBeatsEconomyReport";
import Dashboard from "./containers/dashboard";
import SupervisorDashboard from "./containers/dashboard/SupervisorDashboard";
import Taxes from "./containers/settings/taxes";
import BeatOptions from "./containers/settings/beats";
import CancellationPolicy from "./containers/settings/CancellationPolicy";
import RiverOptions from "./containers/settings/rivers";
import Commissions from "./containers/settings/commissions";
import CatchLimits from "./containers/settings/CatchLimits";
import CardLimits from "./containers/settings/cardlimits";
import UserBlock from "./containers/settings/catchReportSettings";
import FishTypes from "./containers/settings/fishtypes";
import Equipment from "./containers/settings/equipment";
import RequiredCatchFields from "./containers/settings/required-catch-fields";
import EquipmentConfiguration from "./containers/settings/equipment-configuration";
import GenericError from "./containers/Errors/GenericError";
import PaymentSuccess from "./containers/PaymentRedirects/PaymentSuccess";
import PaymentError from "./containers/PaymentRedirects/PaymentError";
import Billings from "./containers/Billings";
import Zones from "./containers/zones";
import SendEmail from "./containers/SendEmail";
import Logo from "./components/logo";
import { ADMIN, RIVER_ADMIN, STATION_ADMIN, BEAT_ADMIN, ACCOUNTANT, SELLER, SUPERVISOR } from "./constants";
import RolesContext from "./context/RolesContext";
import NewsfeedActivities from "./containers/newsfeed_activities";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const restAllowed = rest.permissions.roles.map((r) => {
    return _.includes(rest.allowedRoles, r);
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        return restAllowed.filter((r) => r).length > 0 ? <Component {...props} /> : <Redirect to={{ pathname: "/404" }} />;
      }}
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);

    let browserLanguage;
    switch (window.navigator.languages[1]) {
      case "nb":
        browserLanguage = "nb";
        break;
      case "sv":
        browserLanguage = "sv";
        break;
      default:
        browserLanguage = "en";
    }

    this.state = {
      isAdmin: false,
      isRiverAdmin: false,
      isBeatAdmin: false,
      isAccountant: false,
      isStationAdmin: false,
      isSeller: false,
      isSupervisorAdmin: false,
      locale: window.localStorage.getItem("language") || browserLanguage,
      openedMenu: window.localStorage.getItem("theme") !== "hidden",
    };

    this.props.dispatch(authCheck());

    setInterval(() => {
      if (this.props.authentication.isAuthenticated) {
        this.props.dispatch(authRefreshToken());
      }
    }, 1000 * 60 * 100);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }

    if (
      this.props.authentication.isAuthenticated !== prevProps.authentication.isAuthenticated &&
      this.props.authentication.isAuthenticated
    ) {
      this.props.dispatch(authCheck());
      this.props.dispatch(fetchTypes());
      this.props.dispatch(fetchTaxes());
    }

    if (this.props.authentication.data && this.props.authentication) {
      const { roles } = this.props.authentication.data;

      if (!this.state.isAdmin) {
        roles.map((role) => (role === ADMIN ? this.setState({ isAdmin: true }) : ""));
      }

      if (!this.state.isRiverAdmin) {
        roles.map((role) => (role === RIVER_ADMIN ? this.setState({ isRiverAdmin: true }) : ""));
      }

      if (!this.state.isBeatAdmin) {
        roles.map((role) => (role === BEAT_ADMIN ? this.setState({ isBeatAdmin: true }) : ""));
      }

      if (!this.state.isAccountant) {
        roles.map((role) => (role === ACCOUNTANT ? this.setState({ isAccountant: true }) : ""));
      }

      if (!this.state.isStationAdmin) {
        roles.map((role) => (role === STATION_ADMIN ? this.setState({ isStationAdmin: true }) : ""));
      }

      if (!this.state.isSeller) {
        roles.map((role) => (role === SELLER ? this.setState({ isSeller: true }) : ""));
      }

      if (!this.state.isSupervisorAdmin) {
        roles.map((role) => (role === SUPERVISOR ? this.setState({ isSupervisorAdmin: true }) : ""));
      }
    }
  }

  toggleMenu() {
    this.setState(
      (prevState) => ({ openedMenu: !prevState.openedMenu }),
      () => {
        localStorage.setItem("theme", this.state.openedMenu ? "opened" : "hidden");
      },
    );
  }

  changeLanguage() {
    this.setState(() => ({ locale: localStorage.getItem("language") }));
  }

  detectIE(userAgent) {
    const ua = userAgent;
    const msie = ua.indexOf("MSIE ");
    const trident = ua.indexOf("Trident/");
    if (msie > 0) {
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    if (trident > 0) {
      const rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    return false;
  }

  render() {
    const { userAgent } = window.navigator;
    const auth = this.props.authentication.isAuthenticated;
    const authData = this.props.authentication.data;
    const localizationMessages = {
      ...strings[this.state.locale],
    };
    const { isAdmin, isRiverAdmin, isBeatAdmin, isAccountant, isStationAdmin, isSeller, isSupervisorAdmin } = this.state;

    if (this.detectIE(userAgent)) {
      return <IEWarning />;
    }

    return auth && authData && this.props.fishing_packages.types.data && this.props.taxes.data ? (
      <RolesContext.Provider
        value={{
          isAdmin,
          isRiverAdmin,
          isBeatAdmin,
          isAccountant,
          isStationAdmin,
          isSeller,
          isSupervisorAdmin,
        }}
      >
        <IntlProvider textComponent="span" locale={this.state.locale} messages={localizationMessages}>
          <Container fluid>
            <Row className={`menu-row ${!this.state.openedMenu ? "hidden-menu" : ""}`}>
              <Col xs={12} className={`nav-container ${this.state.openedMenu ? "opened-menu" : "hidden-menu"}`}>
                <Navigation className={this.state.openedMenu ? "opened" : "closed"} isMenuHidden={!this.state.openedMenu} />
              </Col>
              <Col xs={12} className={`content-container ${this.state.openedMenu ? "opened-menu" : "hidden-menu"}`}>
                <Row className="main-header-container">
                  <Col xs={12} className="col">
                    <Header changeLanguage={() => this.changeLanguage()} toggleMenu={() => this.toggleMenu()} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Switch>
                      <PrivateRoute
                        permissions={authData}
                        path="/booking"
                        allowedRoles={[ADMIN, BEAT_ADMIN, SELLER]}
                        component={Booking}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/customers"
                        allowedRoles={[ADMIN, BEAT_ADMIN, RIVER_ADMIN, SELLER, SUPERVISOR]}
                        component={Customers}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/customer/:id"
                        allowedRoles={[ADMIN, BEAT_ADMIN, RIVER_ADMIN, SELLER, SUPERVISOR]}
                        component={Customer}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/sadmin/:id"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={Customer}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/product/fishing-cards"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={FishingCards}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/product/accommodation"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={Accommodation}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/product/fishing-packages"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={FishingPackages}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/product/packages"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={FreePackages}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/product/season-cards"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={SeasonCards}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/product/river-cards"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={RiverCards}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/orders"
                        allowedRoles={[ADMIN, BEAT_ADMIN, SELLER]}
                        component={Orders}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/orders-river"
                        allowedRoles={[ADMIN, RIVER_ADMIN, SUPERVISOR]}
                        component={OrdersRiver}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/stations"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={Stations}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/station-users"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={StationUsers}
                      />
                      <PrivateRoute
                        permissions={authData}
                        exact
                        path="/disinfection"
                        allowedRoles={[ADMIN, RIVER_ADMIN, STATION_ADMIN]}
                        component={this.state.isStationAdmin ? DisinfectionStationAdmin : Disinfection}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/disinfection/add"
                        allowedRoles={[STATION_ADMIN]}
                        component={DisinfectionAdd}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/station/:id"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={Station}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/my-billings"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={Billings}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/platform-reports"
                        allowedRoles={[ADMIN, ACCOUNTANT]}
                        component={PlatformReports}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/all-beats-economy-report"
                        allowedRoles={[ADMIN, ACCOUNTANT]}
                        component={AllBeatsEconomyReport}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/beat-economy-report"
                        allowedRoles={[ADMIN, BEAT_ADMIN, ACCOUNTANT]}
                        component={EconomyBeatReport}
                      />

                      <PrivateRoute
                        permissions={authData}
                        path="/reports/beat-product-count"
                        allowedRoles={[ADMIN, BEAT_ADMIN, ACCOUNTANT]}
                        component={ProductCountReport}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/beat-product-type-price"
                        allowedRoles={[ADMIN, BEAT_ADMIN, ACCOUNTANT]}
                        component={ProductTypePrice}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/seller-payment-method"
                        allowedRoles={[ADMIN, BEAT_ADMIN, ACCOUNTANT]}
                        component={SellerPaymentMethod}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/fishing-cards"
                        allowedRoles={[ADMIN, BEAT_ADMIN, ACCOUNTANT]}
                        component={FishingCardsReport}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/season-cards"
                        allowedRoles={[ADMIN, BEAT_ADMIN, ACCOUNTANT]}
                        component={SeasonCardsReport}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/reports/river-cards"
                        allowedRoles={[ADMIN, ACCOUNTANT, RIVER_ADMIN]}
                        component={RiverCardsReport}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/catch-limits"
                        allowedRoles={[ADMIN]}
                        component={CatchLimits}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/commissions"
                        allowedRoles={[ADMIN]}
                        component={Commissions}
                      />
                      <PrivateRoute permissions={authData} path="/settings/taxes" allowedRoles={[ADMIN]} component={Taxes} />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/beats"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={BeatOptions}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/cancellation-policy"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={CancellationPolicy}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/rivers"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={RiverOptions}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/card-limits"
                        allowedRoles={[ADMIN, BEAT_ADMIN, RIVER_ADMIN]}
                        component={CardLimits}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/catch-report"
                        allowedRoles={[ADMIN]}
                        component={UserBlock}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/required-catch-fields"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={RequiredCatchFields}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/fish-types"
                        allowedRoles={[ADMIN]}
                        component={FishTypes}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/equipment-configuration"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={EquipmentConfiguration}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/equipment"
                        allowedRoles={[ADMIN]}
                        component={Equipment}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/settings/zones"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={Zones}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/catches"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={Catches}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/catch/:id"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={SingleCatch}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/new-catch"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={NewCatch}
                      />
                      <PrivateRoute
                        permissions={authData}
                        exact
                        path="/content/river"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={Rivers}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/content/river/:id"
                        allowedRoles={[ADMIN, RIVER_ADMIN]}
                        component={SingleRiver}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/content/beat"
                        exact
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={Beats}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/content/beat/:id"
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={SingleBeat}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/content/articles"
                        exact
                        allowedRoles={[ADMIN]}
                        component={Articles}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/content/article/:id"
                        allowedRoles={[ADMIN]}
                        component={SingleArticle}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/content/pages"
                        exact
                        allowedRoles={[ADMIN]}
                        component={Pages}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/content/page/:id"
                        allowedRoles={[ADMIN]}
                        component={SinglePage}
                      />
                      <PrivateRoute
                          permissions={authData}
                          path="/content/newsfeed"
                          exact
                          allowedRoles={[ADMIN]}
                          component={NewsfeedActivities}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/card-changes"
                        exact
                        allowedRoles={[ADMIN, BEAT_ADMIN]}
                        component={CardChange}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/supervisor-dashboard"
                        exact
                        allowedRoles={[ADMIN, SUPERVISOR]}
                        component={SupervisorDashboard}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/fangstrapp"
                        exact
                        allowedRoles={[ADMIN]}
                        component={Fangstrapp}
                      />
                      <PrivateRoute
                        permissions={authData}
                        path="/send-email"
                        allowedRoles={[ADMIN, BEAT_ADMIN, RIVER_ADMIN]}
                        component={SendEmail}
                      />
                      <Route path="/profile" exact component={Profile} />
                      <Route path="/payment-error/:id" component={PaymentError} />
                      <Route path="/payment-success" exact component={PaymentSuccess} />
                      <Route path="/" exact component={Dashboard} />
                      <Route component={GenericError} />
                      {/* <PrivateRoute permissions={authData} exact path="/control-customer" allowedRoles={[ADMIN, SUPERVISOR]} component={ControlCustomer} /> */}
                    </Switch>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </IntlProvider>
      </RolesContext.Provider>
    ) : (
      <IntlProvider textComponent="span" locale={this.state.locale} messages={localizationMessages}>
        <Container fluid className="form-signin-page">
          <Row style={{ margin: 0 }}>
            <Col xs={12}>
              <Logo />
            </Col>
            <Col xs={12}>
              <Switch>
                <Route path="/password/reset/:hash" component={PasswordReset} />
                <Route path="/" component={Login} />
              </Switch>
            </Col>
          </Row>
        </Container>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  customers: state.Customers,
  rivers: state.Rivers,
  beats: state.Beats,
  fishing_packages: state.FishingPackages,
  taxes: state.Taxes,
});

export default withRouter(connect(mapStateToProps)(App));
