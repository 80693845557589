export const FETCH_DASHBOARD_RIVER_ADMIN_FISHING_PRESSURE = "FETCH_DASHBOARD_RIVER_ADMIN_FISHING_PRESSURE";
export const FETCH_DASHBOARD_RIVER_ADMIN_FISHING_PRESSURE_SUCCESS = "FETCH_DASHBOARD_RIVER_ADMIN_FISHING_PRESSURE_SUCCESS";
export const FETCH_DASHBOARD_RIVER_ADMIN_FISHING_PRESSURE_FAILURE = "FETCH_DASHBOARD_RIVER_ADMIN_FISHING_PRESSURE_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_RIVER_ADMIN_FISHING_PRESSURE,
    payload
  };
}
