import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import {Button, Card, Col, Input, PageHeader, Row, Table} from "antd";

import Spinner from "../../components/spinner";
import ANT_CONFIG from "../../constants/antconfig";
import RolesContext from "../../context/RolesContext";
import {
  fetch as fetchNewsfeedActivities,
  remove as removeNewsfeedActivity
} from "../../redux/newsfeed_activities/actions";
import {DEFAULT_LANGUAGE, LANGUAGES} from "../../constants";
import LanguageDropdown from "../../components/LanguageDropdown/LanguageDropdown";

const { size, pagination } = ANT_CONFIG;

class NewsfeedActivities extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      language: DEFAULT_LANGUAGE
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchNewsfeedActivities());
  }

  handleDelete = (event, id) => {
    event.stopPropagation();
    const { dispatch } = this.props;

    dispatch(removeNewsfeedActivity({ id: id }));
  };

  setLanguage = language => {
    this.setState({
      language
    });
  };

  handleFilterChange(filter) {
    this.setState({
      filter
    });
  }

  filterData(value) {
    const { filter, language } = this.state;
    let data = value;

    console.log('data value ' + JSON.stringify(value));
    if (filter) {
      data = data?.filter(i => {
        const f = filter.toLowerCase();

        return i.id?.toString().includes(f)
            || i.header?.toLowerCase().includes(f)
            || i.activity_title?.toLowerCase().includes(f)
            || i.preamble?.toLowerCase().includes(f);
      });
    }

    if (language) {
      data = data?.filter(i => i.language === language);
    }

    return data;
  }

  render() {
    if (!this.props.newsfeed_activities.data) {
      return <Spinner />;
    }

    const { isAdmin } = this.context;
    const { filter } = this.state;
    const { intl, newsfeed_activities } = this.props;

    const data = this.filterData(newsfeed_activities.data);
    console.log('updated data ' + JSON.stringify(data));

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key"
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
      },
      {
        title: intl.formatMessage({ id: "created" }),
        dataIndex: "created_at",
        key: "created_at"
      },
      {
        title: intl.formatMessage({ id: "header" }),
        dataIndex: "header",
        key: "header"
      },
      {
        title: intl.formatMessage({ id: "image" }),
        dataIndex: "image",
        key: "image"
      },
      {
        title: intl.formatMessage({ id: "activity_title" }),
        dataIndex: "activity_title",
        key: "activity_title"
      },
      {
        title: intl.formatMessage({ id: "preamble" }),
        dataIndex: "preamble",
        key: "preamble"
      },
      {
        title: intl.formatMessage({ id: "sender" }),
        dataIndex: "is_sender_elveguiden",
        key: "is_sender_elveguiden"
      },
      {
        title: intl.formatMessage({ id: "language" }),
        dataIndex: "language",
        key: "language"
      },
      {
        title: intl.formatMessage({ id: "redirect_link" }),
        dataIndex: "redirect_link",
        key: "redirect_link"
      },
        isAdmin ?
            {
              title: "",
              dataIndex: "action",
              key: "action",
            }
            : {},

    ];

    const tableData = data.map((i, key) => {

      return {
        key: key + 1,
        id: i.id,
        created_at: i.created_at,
        header: i.header,
        image: i.image ? <a href={i.image} target="_blank" rel="noopener noreferrer">{'image'}</a>: null ,
        activity_title: i.activity_title,
        preamble: i.preamble ? i.preamble.substring(0,50) + '...' : null,
        is_sender_elveguiden: i.is_sender_elveguiden ? 'Elveguiden' : null,
        language: LANGUAGES.filter(l => l.value === i.language)[0].label,
        redirect_link: i.redirect_link,
        action:
            isAdmin ? (
                <Button type="danger" size={size} onClick={(event) => this.handleDelete(event, i.id)}>
                  {intl.formatMessage({ id: "delete" })}
                </Button>
            ) : null,
      };
    });

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "newsfeed" })}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search" className="ant-label">
                    {intl.formatMessage({ id: "search" })}
                  </label>
                  <Input
                    placeholder={intl.formatMessage({ id: "search" })}
                    id="search"
                    size={ANT_CONFIG.size}
                    value={filter}
                    onChange={e => this.handleFilterChange(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col xs={12} sm={2} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search" className="ant-label">
                    {intl.formatMessage({ id: "language" })}
                  </label>
                  <LanguageDropdown setLanguage={this.setLanguage} />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={pagination}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  newsfeed_activities: state.NewsfeedActivities
});

export default withRouter(injectIntl(connect(mapStateToProps)(NewsfeedActivities)));
