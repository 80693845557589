import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  type: {
    data: undefined,
    loading: false,
    error: false,
  },
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_SEASON_CARDS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_SEASON_CARDS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_SEASON_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.POST_SEASON_CARDS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.POST_SEASON_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.POST_SEASON_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.EDIT_SEASON_CARDS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.EDIT_SEASON_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.EDIT_SEASON_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.REMOVE_SEASON_CARDS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.REMOVE_SEASON_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.REMOVE_SEASON_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.ADD_SEASON_CARDS_TYPE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: true,
        },
      };

    case ACTION.ADD_SEASON_CARDS_TYPE_SUCCESS:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: false,
        },
      };

    case ACTION.ADD_SEASON_CARDS_TYPE_FAILURE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: true,
        },
      };

    case ACTION.EDIT_SEASON_CARDS_TYPE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: true,
        },
      };

    case ACTION.EDIT_SEASON_CARDS_TYPE_SUCCESS:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: false,
        },
      };

    case ACTION.EDIT_SEASON_CARDS_TYPE_FAILURE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: true,
        },
      };

    case ACTION.FETCH_SEASON_CARDS_TYPES:
      return {
        ...state,
        type: {
          ...state.type,
          loading: true,
        },
      };

    case ACTION.FETCH_SEASON_CARDS_TYPES_SUCCESS:
      return {
        ...state,
        type: {
          data: action.payload.data,
          loading: false,
          error: false,
        },
      };

    case ACTION.FETCH_SEASON_CARDS_TYPES_FAILURE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: true,
        },
      };

    default:
      return state;
  }
}
