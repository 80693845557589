const beatOptions = (props, auth) => {
  if (!props.data) {
    return false;
  }

  return props.data
    .filter(beat => auth.data.beat.includes(beat.id))
    .filter(beat => beat.river)
    .map(beat => {
      return {
        value: beat.id,
        river_id: beat.river.id,
        label: `${beat.name ? beat.name : 'EMPTY BEAT NAME'}  (${beat.river?.name ? beat.river.name : 'EMPTY RIVER NAME'})`,
      };
    });
};

export default beatOptions;
