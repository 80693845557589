import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  articleId: null
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_ARTICLE:
      return {
        ...state,
        loading: true,
        articleId: null
      };

    case ACTION.FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.STORE_ARTICLE:
      return {
        ...state,
        articleId: null,
        loading: true
      };

    case ACTION.STORE_ARTICLE_SUCCESS:
      return {
        ...state,
        articleId: action.payload.data.articleId,
        loading: false,
        error: false
      };

    case ACTION.STORE_ARTICLE_FAILURE:
      return {
        ...state,
        articleId: null,
        loading: false,
        error: true
      };

    case ACTION.UPDATE_ARTICLE:
      return {
        ...state,
        loading: true
      };

    case ACTION.UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.UPDATE_IMAGE_ARTICLE:
      return {
        ...state,
        loading: true
      };

    case ACTION.UPDATE_IMAGE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.UPDATE_IMAGE_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}
