import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  data_customer: undefined
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_ORDERS:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_ORDERS_BY_PRODUCT_DATE:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_ORDERS_BY_PRODUCT_DATE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_ORDERS_BY_PRODUCT_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_CUSTOMER_ORDERS:
      return {
        ...state,
        loading: true,
        error: false,
        data_customer: undefined
      };

    case ACTION.FETCH_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        data_customer: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_CUSTOMER_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.SEND_PAYMENT_LINK:
      return {
        ...state
      };

    case ACTION.SEND_PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.SEND_PAYMENT_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.CANCEL_ORDER:
      return {
        ...state,
        loading: true
      };

    case ACTION.CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.CANCEL_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.CANCEL_ORDER_FOR_USER:
      return {
        ...state,
        loading: true
      };

    case ACTION.CANCEL_ORDER_FOR_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.CANCEL_ORDER_FOR_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}
