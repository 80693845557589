/* eslint-disable linebreak-style */
import React from "react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Divider, Select, Table, Tabs } from "antd";

import { fetch as fetchBeats } from "../../redux/beats/actions";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import { fetch as fetchControllerRivers } from "../../redux/controller_rivers/actions";
import { fetch as fetchDashboard } from "../../redux/dashboard/actions";
import { fetch as fetchNotifications } from "../../redux/notifications/actions";
import { fetch as fetchDashboardAdmin } from "../../redux/dashboard_admin/actions";
import { fetch as fetchDashboardAdminCounties } from "../../redux/dashboard_admin_counties/actions";
import { fetch as fetchDashboardAdminRivers } from "../../redux/dashboard_admin_rivers/actions";
import { fetch as fetchDashboardRiverAdmin } from "../../redux/dashboard_river_admin/actions";
import { fetch as fetchDashboardRiverAdminCatches } from "../../redux/dashboard_river_admin_catches/actions";
import { fetch as fetchDashboardRiverAdminCatchStatistics } from "../../redux/dashboard_river_admin_catch_statistics/actions";
import { fetch as fetchDashboardRiverAdminAdmins } from "../../redux/dashboard_river_admin_admins/actions";
import { fetch as fetchDashboardRiverAdminFishingPressure } from "../../redux/dashboard_river_admin_fishing_pressure/actions";
import { fetch as fetchDashboardRiverAdminControllers } from "../../redux/dashboard_river_admin_controllers/actions";
import { fetch as fetchDashboardBeatAdmin } from "../../redux/dashboard_beat_admin/actions";
import { fetch as fetchDashboardBeatAdminAdmins } from "../../redux/dashboard_beat_admin_admins/actions";
import { fetch as fetchDashboardBeatAdminMembers } from "../../redux/dashboard_beat_admin_members/actions";
import { fetch as fetchDashboardBeatAdminDailyCards } from "../../redux/dashboard_beat_admin_daily_cards/actions";
import { fetch as fetchDashboardBeatAdminDailyAccommodation } from "../../redux/dashboard_beat_admin_daily_accommodation/actions";
import { fetch as fetchDashboardControllerAdmin } from "../../redux/dashboard_controller_admin/actions";
import { fetch as fetchDashboardControllerStatistics } from "../../redux/dashboard_controller_statistics/actions";

import Spinner from "../../components/spinner";
import SelectInput from "../../components/select";
import Notification from "../../components/Notification/Notification";
import beatOptions from "../../services/select_beat_options";
import riverOptions from "../../services/select_river_options";
import riverOptionsFromControllerRivers from "../../services/select_river_options_from_controller_rivers";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";
class Dashboard extends React.Component {
  static contextType = RolesContext;

  state = {
    superAdminYear: moment().format("YYYY"),
    riverAdminYear: moment().format("YYYY"),
    beatAdminYear: moment().format("YYYY"),
    controllerYear: moment().format("YYYY"),
    date_start: moment().startOf("year").format("YYYY-MM-DD"),
    date_end: moment().format("YYYY-MM-DD"),
    daily_cards_date: moment().format("YYYY-MM-DD"),
    daily_controller_date: moment().format("YYYY-MM-DD"),
    river_id: null,
    beat_id: null,
    controller_river_id: null,
    year: moment().format("YYYY"),
  };

  componentDidMount() {
    const { isAdmin, isBeatAdmin } = this.context;

    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    if (!this.props.controllerRivers.data) {
      this.props.dispatch(fetchControllerRivers());
    }

    if (isBeatAdmin && !isAdmin) {
      this.props.dispatch(fetchNotifications());
    }

    if (isAdmin) {
      this.handleFetchDashboardAdmin();
    }
  }

  handleRiverChange(e) {
    this.setState(
      {
        river_id: e,
        riverAdminYear: this.state.year,
      },
      () => {
        this.handleFetchDashboardRiverAdmin();
      },
    );
  }

  handleRiverYearChange(e) {
    this.setState(
      {
        year: e,
        riverAdminYear: e,
      },
      () => {
        this.handleFetchYearDashboardRiverAdmin();
      },
    );
  }

  handleControllerRiverChange(e) {
    this.setState(
      {
        controller_river_id: e,
        controllerYear: moment(this.state.date_start).startOf("year").format("YYYY"),
      },
      () => {
        this.handleFetchDashboardControllerAdmin();
      },
    );
  }

  handleBeatChange(e) {
    this.setState(
      {
        beat_id: e,
        beatAdminYear: moment(this.state.date_start).startOf("year").format("YYYY"),
      },
      () => {
        this.handleFetchDashboardBeatAdmin();
      },
    );
  }

  handleYearChange(e) {
    const dateStart = moment(e.value, "YYYY").startOf("year");
    let dateEnd = moment(e.value, "YYYY").endOf("year");

    if (dateEnd > moment()) dateEnd = moment();

    this.setState(
      {
        superAdminYear: e.value,
        date: e.value,
        date_start: dateStart.format("YYYY-MM-DD"),
        date_end: dateEnd.format("YYYY-MM-DD"),
      },
      () => {
        this.handleFetchDashboardAdmin();
      },
    );
  }

  handleBeatDailyCardsDateChange(e) {
    let newdate;

    if (e === "next") {
      newdate = moment(this.state.daily_cards_date).add(1, "days").format("YYYY-MM-DD");
    } else if (e === "prev") {
      newdate = moment(this.state.daily_cards_date).subtract(1, "days").format("YYYY-MM-DD");
    }

    this.setState(
      {
        daily_cards_date: newdate,
      },
      () => {
        this.props.dispatch(
          fetchDashboardBeatAdminDailyCards({
            date_start: this.state.daily_cards_date,
            date_end: this.state.daily_cards_date,
            beat_id: this.state.beat_id,
          }),
        );
        this.props.dispatch(
          fetchDashboardBeatAdminDailyAccommodation({
            date_start: this.state.daily_cards_date,
            date_end: this.state.daily_cards_date,
            beat_id: this.state.beat_id,
          }),
        );
      },
    );
  }

  handleBeatDailyAccommodationDateChange(e) {
    let newdate;

    if (e === "next") {
      newdate = moment(this.state.daily_cards_date).add(1, "days").format("YYYY-MM-DD");
    } else if (e === "prev") {
      newdate = moment(this.state.daily_cards_date).subtract(1, "days").format("YYYY-MM-DD");
    }

    this.setState(
      {
        daily_cards_date: newdate,
      },
      () => {
        this.props.dispatch(
          fetchDashboardBeatAdminDailyCards({
            date_start: this.state.daily_cards_date,
            date_end: this.state.daily_cards_date,
            beat_id: this.state.beat_id,
          }),
        );
        this.props.dispatch(
          fetchDashboardBeatAdminDailyAccommodation({
            date_start: this.state.daily_cards_date,
            date_end: this.state.daily_cards_date,
            beat_id: this.state.beat_id,
          }),
        );
      },
    );
  }

  handleControllerDailyCardsDateChange(e) {
    let newdate;

    if (e === "next") {
      newdate = moment(this.state.daily_controller_date).add(1, "days").format("YYYY-MM-DD");
    } else if (e === "prev") {
      newdate = moment(this.state.daily_controller_date).subtract(1, "days").format("YYYY-MM-DD");
    }

    this.setState(
      {
        daily_controller_date: newdate,
      },
      () => {
        this.props.dispatch(
          fetchDashboardControllerAdmin({
            date_start: this.state.daily_controller_date,
            date_end: this.state.daily_controller_date,
            river_id: this.state.controller_river_id,
          }),
        );
      },
    );
  }

  handleFetchDashboardAdmin() {
    if (this.context.isAdmin) {
      const filter = {
        date_start: this.state.date_start,
        date_end: this.state.date_end,
      };
      this.props.dispatch(fetchDashboardAdmin(filter));
      this.props.dispatch(fetchDashboardAdminCounties(filter));
      this.props.dispatch(fetchDashboardAdminRivers(filter));
    }
  }

  handleFetchDashboardRiverAdmin() {
    if (this.state.river_id) {
      const filter = {
        date_start: this.state.date_start,
        date_end: this.state.date_end,
        river_id: this.state.river_id,
      };
      const fishing_filter = {
        river_id: this.state.river_id,
        year: this.state.year,
      };
      this.props.dispatch(fetchDashboardRiverAdmin(fishing_filter));
      this.props.dispatch(fetchDashboardRiverAdminCatches(filter));
      this.props.dispatch(fetchDashboardRiverAdminCatchStatistics(fishing_filter));
      this.props.dispatch(fetchDashboardRiverAdminAdmins(filter));
      this.props.dispatch(fetchDashboardRiverAdminFishingPressure(fishing_filter));
      this.props.dispatch(fetchDashboardRiverAdminControllers(filter));
    }
  }

  handleFetchYearDashboardRiverAdmin() {
    if (this.state.river_id) {
      const fishing_filter = {
        river_id: this.state.river_id,
        year: this.state.year,
      };
      this.props.dispatch(fetchDashboardRiverAdmin(fishing_filter));
      this.props.dispatch(fetchDashboardRiverAdminCatchStatistics(fishing_filter));
      this.props.dispatch(fetchDashboardRiverAdminFishingPressure(fishing_filter));
    }
  }
  handleFetchDashboardBeatAdmin() {
    if (this.state.beat_id) {
      const filter = {
        date_start: this.state.date_start,
        date_end: this.state.date_end,
        beat_id: this.state.beat_id,
      };
      this.props.dispatch(fetchDashboardBeatAdmin(filter));
      this.props.dispatch(fetchDashboard(filter));
      this.props.dispatch(fetchDashboardBeatAdminAdmins(filter));
      this.props.dispatch(fetchDashboardBeatAdminMembers(filter));

      filter.date_start = this.state.daily_cards_date;
      filter.date_end = this.state.daily_cards_date;

      this.props.dispatch(fetchDashboardBeatAdminDailyCards(filter));
      this.props.dispatch(fetchDashboardBeatAdminDailyAccommodation(filter));
    }
  }

  handleFetchDashboardControllerAdmin() {
    if (this.state.controller_river_id) {
      let filter = {
        date_start: moment().format("YYYY-MM-DD"),
        date_end: moment().format("YYYY-MM-DD"),
        river_id: this.state.controller_river_id,
      };
      this.props.dispatch(fetchDashboardControllerAdmin(filter));

      filter = {
        date_start: this.state.date_start,
        date_end: this.state.date_end,
        river_id: this.state.controller_river_id,
      };
      this.props.dispatch(fetchDashboardControllerStatistics(filter));
    }
  }

  render() {
    const { isAdmin, isBeatAdmin, isStationAdmin, isAccountant, isRiverAdmin, isSeller, isSupervisorAdmin } = this.context;
    const allRoles = [isAdmin, isBeatAdmin, isStationAdmin, isAccountant, isRiverAdmin, isSeller, isSupervisorAdmin];
    const { beats, rivers, controllerRivers, authentication } = this.props;

    const onlyRole = (isRole) => {
      return isRole && allRoles.filter((r) => r).length === 1;
    };

    if (!isAdmin && !isBeatAdmin) {
      if (onlyRole(isStationAdmin)) {
        return <Redirect to="/disinfection" />;
      }

      if (onlyRole(isAccountant)) {
        return <Redirect to="/platform-reports" />;
      }

      if (onlyRole(isSeller)) {
        return <Redirect to="/booking" />;
      }
    }

    const selectBeats = beatOptions(beats, authentication);
    const selectRivers = riverOptions(rivers, authentication);
    const selectControllerRivers = riverOptionsFromControllerRivers(controllerRivers, authentication, isAdmin);
    const selectYears = Array.from({ length: moment().year() - 2019 + 1 }, (_, index) => 2019 + index)
      .map((e) => {
        return { value: e, label: e.toString() };
      })
      .reverse();

    const selectRiverYears = Array.from({ length: moment().year() - 2019 + 1 }, (_, index) => 2019 + index)
      .map((e) => {
        return { value: e, label: e.toString() };
      })
      .reverse();

    const htmlSelectBeat =
      !this.props.beats.loading && this.props.beats.data ? (
        selectBeats.length > 0 ? (
          <div style={{ marginBottom: 30 }}>
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row>
                    <Label for="select_beat" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <SelectInput
                        id="select_beat"
                        name="select_beat"
                        intl={this.props.intl}
                        value={selectBeats.filter((i) => i.value === this.state.beat_id)}
                        onChange={(e) => this.handleBeatChange(e.value)}
                        options={selectBeats}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <hr style={{ marginTop: 2 }} />
          </div>
        ) : null
      ) : (
        <Spinner />
      );

    const htmlSelectRiver =
      selectRivers.length > 0 ? (
        <div>
          <Row>
            <Col xs={12}>
              <Form>
                <FormGroup row>
                  <Label for="select_river" xs={12} sm={2}>
                    {`${this.props.intl.formatMessage({ id: "select_river" })}`}
                  </Label>
                  <Col xs={12} sm={10}>
                    <SelectInput
                      id="select_river"
                      name="select_river"
                      intl={this.props.intl}
                      value={selectRivers.filter((i) => i.value === this.state.river_id)}
                      onChange={(e) => this.handleRiverChange(e.value)}
                      options={selectRivers}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <hr style={{ marginTop: 2 }} />
        </div>
      ) : null;

    const htmlSelectControllerRiver =
      selectControllerRivers.length > 0 ? (
        <div style={{ marginBottom: 30 }}>
          <Row>
            <Col xs={12}>
              <Form>
                <FormGroup row>
                  <Label for="select_controller_river" xs={12} sm={2}>
                    {`${this.props.intl.formatMessage({ id: "select_river" })}`}
                  </Label>
                  <Col xs={12} sm={10}>
                    <SelectInput
                      id="select_controller_river"
                      name="select_controller_river"
                      intl={this.props.intl}
                      value={selectControllerRivers.filter((i) => i.value === this.state.controller_river_id)}
                      onChange={(e) => this.handleControllerRiverChange(e.value)}
                      options={selectControllerRivers}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <hr style={{ marginTop: 2 }} />
        </div>
      ) : null;

    const htmlSelectYear =
      selectYears.length > 0 ? (
        <div style={{ marginBottom: 30 }}>
          <Row>
            <Col xs={12}>
              <Form>
                <FormGroup row>
                  <Label for="select_year" xs={12} sm={2}>
                    {`${this.props.intl.formatMessage({ id: "select_year" })}`}
                  </Label>
                  <Col xs={12} sm={10}>
                    <SelectInput
                      id="select_year"
                      name="select_year"
                      intl={this.props.intl}
                      value={selectYears.filter((i) => i.value === this.state.superAdminYear)}
                      onChange={(e) => this.handleYearChange(e)}
                      options={selectYears}
                    />
                  </Col>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <hr style={{ marginTop: 2 }} />
        </div>
      ) : null;

    const countyTableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "county" }),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "rivers" }),
        dataIndex: "rivers",
        key: "rivers",
        sorter: (a, b) => a.rivers - b.rivers,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "beats" }),
        dataIndex: "beats",
        key: "beats",
        sorter: (a, b) => a.beats - b.beats,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "net_sales" }),
        dataIndex: "net_revenue",
        key: "net_revenue",
        sorter: (a, b) => a.net_revenue_num - b.net_revenue_num,
        sortDirections: ["descend", "ascend", "descend"],
        defaultSortOrder: "descend",
      },
      {
        title: this.props.intl.formatMessage({ id: "net_provision" }),
        dataIndex: "net_provision",
        key: "net_provision",
        sorter: (a, b) => a.net_provision_num - b.net_provision_num,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "transaction_fees" }),
        dataIndex: "transaction_fees",
        key: "transaction_fees",
        sorter: (a, b) => a.transaction_fees_num - b.transaction_fees_num,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "products_sold" }),
        dataIndex: "products_sold",
        key: "products_sold",
        sorter: (a, b) => a.products_sold - b.products_sold,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "number_of_salmon" }),
        dataIndex: "number_of_salmon",
        key: "number_of_salmon",
        sorter: (a, b) => a.number_of_salmon - b.number_of_salmon,
        sortDirections: ["descend", "ascend", "descend"],
      },
    ];

    const countyTableData = this.props.dashboardAdminCounties.data?.map((county, row) => {
      const key = row + 1;
      const {
        name,
        rivers,
        beats,
        net_revenue,
        net_provision,
        transaction_fees,
        products_sold,
        number_of_salmon,
        net_revenue_num,
        net_provision_num,
        transaction_fees_num,
      } = county;
      return {
        key,
        name,
        rivers,
        beats,
        net_revenue,
        net_provision,
        transaction_fees,
        products_sold,
        number_of_salmon,
        net_revenue_num,
        net_provision_num,
        transaction_fees_num,
      };
    });

    const riverTableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "county" }),
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "beats" }),
        dataIndex: "beat_count",
        key: "beat_count",
        sorter: (a, b) => a.beats - b.beats,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "net_sales" }),
        dataIndex: "net_revenue",
        key: "net_revenue",
        sorter: (a, b) => a.net_revenue_num - b.net_revenue_num,
        sortDirections: ["descend", "ascend", "descend"],
        defaultSortOrder: "descend",
      },
      {
        title: this.props.intl.formatMessage({ id: "net_provision" }),
        dataIndex: "net_provision",
        key: "net_provision",
        sorter: (a, b) => a.net_provision_num - b.net_provision_num,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "transaction_fees" }),
        dataIndex: "transaction_fees",
        key: "transaction_fees",
        sorter: (a, b) => a.transaction_fees_num - b.transaction_fees_num,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "products_sold" }),
        dataIndex: "products_sold",
        key: "products_sold",
        sorter: (a, b) => a.products_sold - b.products_sold,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: this.props.intl.formatMessage({ id: "number_of_salmon" }),
        dataIndex: "number_of_salmon",
        key: "number_of_salmon",
        sorter: (a, b) => a.number_of_salmon - b.number_of_salmon,
        sortDirections: ["descend", "ascend", "descend"],
      },
    ];

    const riverTableData = this.props.dashboardAdminRivers.data?.map((river, row) => {
      const key = row + 1;
      const {
        name,
        beats,
        net_revenue,
        net_provision,
        transaction_fees,
        products_sold,
        number_of_salmon,
        net_revenue_num,
        net_provision_num,
        transaction_fees_num,
      } = river;
      return {
        key,
        name,
        beats,
        net_revenue,
        net_provision,
        transaction_fees,
        products_sold,
        number_of_salmon,
        net_revenue_num,
        net_provision_num,
        transaction_fees_num,
      };
    });

    const latestCatchTableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: this.props.intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
        defaultSortOrder: "descend",
      },
      {
        title: this.props.intl.formatMessage({ id: "fisherman_id" }),
        dataIndex: "user_id",
        key: "user_id",
      },
      {
        title: this.props.intl.formatMessage({ id: "fishing_card_id" }),
        dataIndex: "fishing_card_id",
        key: "fishing_card_id",
      },
      {
        title: this.props.intl.formatMessage({ id: "length" }),
        dataIndex: "length",
        key: "length",
      },
      {
        title: this.props.intl.formatMessage({ id: "weight" }),
        dataIndex: "weight",
        key: "weight",
      },
      {
        title: this.props.intl.formatMessage({ id: "edit" }),
        dataIndex: "edit",
        key: "edit",
      },
    ];

    const latestCatchTableData = this.props.dashboardRiverAdminCatches.data?.map((catches, row) => {
      const key = row + 1;
      const { id, date, user_id, fishing_card_id, length, weight } = catches;
      const edit = <Link to={`/catch/${id}`}>{this.props.intl.formatMessage({ id: "edit" })}</Link>;
      const dateText = moment(date, "YYYY-MM-DD").format("D. MMM YY");
      return {
        key,
        id,
        dateText,
        user_id,
        fishing_card_id,
        length,
        weight,
        edit,
      };
    });

    const adminUserTableColumns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: this.props.intl.formatMessage({ id: "first_name" }),
        dataIndex: "first_name",
        key: "firstname",
      },
      {
        title: this.props.intl.formatMessage({ id: "last_name" }),
        dataIndex: "last_name",
        key: "lastname",
      },
      {
        title: this.props.intl.formatMessage({ id: "email" }),
        dataIndex: "email",
        key: "email",
      },
      {
        title: this.props.intl.formatMessage({ id: "phone" }),
        dataIndex: "phone",
        key: "phone",
      },
    ];

    const FishingPressureTableColumns = [
      {
        title: "",
        dataIndex: "name",
        key: "name",
        render: (data, record) => <div>{this.props.intl.formatMessage({ id: data })}</div>,
      },
      {
        title: this.props.intl.formatMessage({ id: "day" }),
        dataIndex: "day",
        key: "day",
      },
      {
        title: this.props.intl.formatMessage({ id: "week" }),
        dataIndex: "week",
        key: "week",
      },
      {
        title: this.props.intl.formatMessage({ id: "season" }),
        dataIndex: "season",
        key: "season",
      },
    ];

    const riverAdminsTableData = this.props.dashboardRiverAdminAdmins.data?.map((user, row) => {
      const key = row + 1;
      const { id, first_name, last_name, email, phone } = user;
      return {
        key,
        id,
        first_name,
        last_name,
        email,
        phone,
      };
    });

    let riverFishingPressureTableData = [];
    const rawData = this.props?.dashboardRiverAdminFishingPressure?.data;
    if (rawData) {
      riverFishingPressureTableData = Object.entries(rawData)
        .filter(([name]) => name !== "not_used")
        .map(([name, values], index) => ({ key: index, name, ...values }));
    }

    const riverControllerAdminTableData = this.props.dashboardRiverAdminControllers.data?.map((user, row) => {
      const key = row + 1;
      const { id, first_name, last_name, email, phone } = user;
      return {
        key,
        id,
        first_name,
        last_name,
        email,
        phone,
      };
    });

    const beatAdminsTableData = this.props.dashboardBeatAdminAdmins.data?.map((user, row) => {
      const key = row + 1;
      const { id, first_name, last_name, email, phone } = user;
      return {
        key,
        id,
        first_name,
        last_name,
        email,
        phone,
      };
    });

    const beatMembersTableData = this.props.dashboardBeatAdminMembers.data?.map((user, row) => {
      const key = row + 1;
      const { id, first_name, last_name, email, phone } = user;
      return {
        key,
        id,
        first_name,
        last_name,
        email,
        phone,
      };
    });

    const ordersTableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "from" }),
        dataIndex: "dateFromText",
        key: "dateFromText",
      },
      {
        title: this.props.intl.formatMessage({ id: "to" }),
        dataIndex: "dateToText",
        key: "dateToText",
      },
      {
        title: this.props.intl.formatMessage({ id: "name" }),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this.props.intl.formatMessage({ id: "price" }),
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: this.props.intl.formatMessage({ id: "fishing_card" }),
        dataIndex: "fishingCardText",
        key: "fishingCardText",
      },
      {
        title: this.props.intl.formatMessage({ id: "accommodation" }),
        dataIndex: "accommodationText",
        key: "accommodationText",
      },
    ];

    const ordersTableData = this.props.dashboard.data?.last_payed_orders?.map((order, row) => {
      const key = row + 1;

      let dateFrom = "";
      let dateTo = "";
      let dateFromText = "";
      let dateToText = "";
      let name = "";
      let amount = 0;
      let fishingCard = false;
      let fishingCardText = "";
      let accommodation = false;
      let accommodationText = "";
      let dates = [];

      if (order.packages && order.packages[0]) {
        if (order.packages[0].fishing_cards.length > 0) {
          fishingCard = true;

          order.packages[0].fishing_cards.forEach((i) => {
            dates = [...dates, i.date];
          });
        }

        if (order.packages[0].accommodations.length > 0) {
          accommodation = true;

          order.packages[0].accommodations.forEach((i) => {
            dates = [...dates, i.date];
          });
        }

        if (order.packages[0].free_packages.length > 0) {
          fishingCard = true;
          accommodation = true;

          order.packages[0].free_packages.forEach((i) => {
            dates = [...dates, i.date_start, i.date_end];
          });
        }

        if (order.packages[0].season_fishing_cards.length > 0) {
          fishingCard = true;

          order.packages[0].season_fishing_cards.forEach((i) => {
            dates = [...dates, i.season_start_date, i.season_end_date];
          });
        }

        if (dates && dates.length > 0) {
          dateFrom = dates.reduce((pre, cur) => (Date.parse(pre) > Date.parse(cur) ? cur : pre));
          dateTo = dates.reduce((pre, cur) => (Date.parse(pre) < Date.parse(cur) ? cur : pre));
        }

        if (order.user) {
          name = order.user.first_name + " " + order.user.last_name;
        }

        if (order.total) {
          amount = order.total;
        }

        fishingCard
          ? (fishingCardText = this.props.intl.formatMessage({ id: "yes" }))
          : (fishingCardText = this.props.intl.formatMessage({ id: "no" }));
        accommodation
          ? (accommodationText = this.props.intl.formatMessage({ id: "yes" }))
          : (accommodationText = this.props.intl.formatMessage({ id: "no" }));

        dateFromText = moment(dateFrom).format("DD.MM.YYYY");
        dateToText = moment(dateTo).format("DD.MM.YYYY");
      }

      return {
        key,
        dateFromText,
        dateToText,
        name,
        amount,
        fishingCardText,
        accommodationText,
      };
    });

    const beatDailyCardsTableData = this.props.dashboardBeatAdminDailyCards.data?.fishing_cards?.map((card, i) => {
      const key = i + 1;
      const { id, date, owner_name } = card;
      const price_amount = card?.sold_for_price?.amount || 0;
      const price_name = card?.sold_for_price?.name || "-";
      const card_type = card?.fishing_card_type?.name || "-";
      const dateText = moment(date, "YYYY-MM-DD").format("D. MMM YY");
      return {
        key,
        id,
        dateText,
        owner_name,
        price_amount,
        price_name,
        card_type,
      };
    });

    const beatDailyCardsTableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "owner_name" }),
        dataIndex: "owner_name",
        key: "owner_name",
      },
      {
        title: this.props.intl.formatMessage({ id: "card_type" }),
        dataIndex: "card_type",
        key: "card_type",
      },
      {
        title: this.props.intl.formatMessage({ id: "price" }),
        dataIndex: "price_amount",
        key: "price_amount",
      },
      {
        title: this.props.intl.formatMessage({ id: "price_type" }),
        dataIndex: "price_name",
        key: "price_name",
      },
    ];

    let controllerDailyCardsTableData = null;

    if (this.props.dashboardControllerAdmin.data) {
      const beats = this.props.dashboardControllerAdmin.data;
      const cards = [];

      Object.keys(this.props.dashboardControllerAdmin.data).map((keyName) => {
        if (beats[keyName].fishing_cards.length > 0) {
          beats[keyName].fishing_cards.map((card) => {
            cards.push(card);
          });
        }
      });

      controllerDailyCardsTableData = cards.map((card, i) => {
        const key = i + 1;
        const { id, date, owner_name } = card;
        const price_amount = card.sold_for_price.amount;
        const price_name = card.sold_for_price.name;
        const card_type = card.fishing_card_type.name;
        const dateText = moment(date, "YYYY-MM-DD").format("D. MMM YY");
        return {
          key,
          id,
          dateText,
          owner_name,
          price_amount,
          price_name,
          card_type,
        };
      });
    }

    const controllerDailyCardsTableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "owner_name" }),
        dataIndex: "owner_name",
        key: "owner_name",
      },
      {
        title: this.props.intl.formatMessage({ id: "card_type" }),
        dataIndex: "card_type",
        key: "card_type",
      },
      {
        title: this.props.intl.formatMessage({ id: "price" }),
        dataIndex: "price_amount",
        key: "price_amount",
      },
      {
        title: this.props.intl.formatMessage({ id: "price_type" }),
        dataIndex: "price_name",
        key: "price_name",
      },
    ];
    let controllerDailySeasonCardsTableData = null;
    if (this.props.dashboardControllerAdmin.data) {
      const productsPerBeat = this.props.dashboardControllerAdmin.data;
      controllerDailySeasonCardsTableData = Object.values(productsPerBeat)
        .filter((productList) => this.hasSeasonCards(productList))
        .flatMap((allCards) => allCards.season_fishing_cards)
        .map((seasonCard, index) => {
          const key = index + 1;
          const { id, owner_name, year } = seasonCard;
          const price_amount = seasonCard.sold_for_price.amount;
          const price_name = seasonCard.sold_for_price.name;
          const card_type = seasonCard.season_fishing_card_type.name;

          return {
            key,
            id,
            year,
            owner_name,
            price_amount,
            price_name,
            card_type,
          };
        });
    }

    const controllerDailySeasonCardsTableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "owner_name" }),
        dataIndex: "owner_name",
        key: "owner_name",
      },
      {
        title: this.props.intl.formatMessage({ id: "card_type" }),
        dataIndex: "card_type",
        key: "card_type",
      },
      {
        title: this.props.intl.formatMessage({ id: "price_type" }),
        dataIndex: "price_name",
        key: "price_name",
      },
      {
        title: this.props.intl.formatMessage({ id: "price" }),
        dataIndex: "price_amount",
        key: "price_amount",
      },
    ];

    const htmlSuperAdmin = (
      <Tabs.TabPane tab="Superdash" key="1">
        <Row>
          <Col xs={12} md={6}>
            {htmlSelectYear}
          </Col>
        </Row>

        {this.state.superAdminYear ? (
          <div>
            <Row className="widgets-container">
              <Col xs={12} md={6} xl={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "net_sales" })}</h2>
                  {this.props.dashboardAdmin.loading && <Spinner />}
                  {!this.props.dashboardAdmin.loading && this.props.dashboardAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.superAdminYear}</small>
                      </h6>
                      <h2 className="value">{this.props.dashboardAdmin.data.net_revenue}</h2>
                    </div>
                  ) : null}
                  {!this.props.dashboardAdmin.data && !this.props.dashboardAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>

              <Col xs={12} md={6} xl={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "net_provision" })}</h2>
                  {this.props.dashboardAdmin.loading && <Spinner />}
                  {!this.props.dashboardAdmin.loading && this.props.dashboardAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.superAdminYear}</small>
                      </h6>
                      <h2 className="value">{this.props.dashboardAdmin.data.net_provision}</h2>
                    </div>
                  ) : null}
                  {!this.props.dashboardAdmin.data && !this.props.dashboardAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>

              <Col xs={12} md={6} xl={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "transaction_fees" })}</h2>
                  {this.props.dashboardAdmin.loading && <Spinner />}
                  {!this.props.dashboardAdmin.loading && this.props.dashboardAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.superAdminYear}</small>
                      </h6>
                      <h2 className="value">{this.props.dashboardAdmin.data.transaction_fees}</h2>
                    </div>
                  ) : null}
                  {!this.props.dashboardAdmin.data && !this.props.dashboardAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>

              <Col xs={12} md={6} xl={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "products_sold" })}</h2>
                  {this.props.dashboardAdmin.loading && <Spinner />}
                  {!this.props.dashboardAdmin.loading && this.props.dashboardAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.superAdminYear}</small>
                      </h6>
                      <h2 className="value">{this.props.dashboardAdmin.data.products_sold}</h2>
                    </div>
                  ) : null}
                  {!this.props.dashboardAdmin.data && !this.props.dashboardAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={6} style={{ marginTop: 30 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "counties" })}</h2>
                  {this.props.dashboardAdminCounties.loading && <Spinner />}
                  {!this.props.dashboardAdminCounties.loading && this.props.dashboardAdminCounties.data ? (
                    <Col xs={24}>
                      <Table columns={countyTableColumns} dataSource={countyTableData} size="small" />
                    </Col>
                  ) : null}
                  {!this.props.dashboardAdminCounties.data && !this.props.dashboardAdminCounties.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>

              <Col xs={12} xl={6} style={{ marginTop: 30 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "rivers" })}</h2>
                  {this.props.dashboardAdminRivers.loading && <Spinner />}
                  {!this.props.dashboardAdminRivers.loading && this.props.dashboardAdminRivers.data ? (
                    <Col xs={24}>
                      <Table columns={riverTableColumns} dataSource={riverTableData} size="small" />
                    </Col>
                  ) : null}
                  {!this.props.dashboardAdminRivers.data && !this.props.dashboardAdminRivers.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            <hr style={{ marginTop: 20 }} />
          </div>
        ) : null}
      </Tabs.TabPane>
    );

    const htmlRiverAdmin = (
      <Tabs.TabPane tab={this.props.intl.formatMessage({ id: "river_administrator" })} key="2" style={{ minHeight: "500px" }}>
        <Row style={{ marginBottom: 30 }}>
          <Col xs={12} md={6}>
            {htmlSelectRiver}
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row>
                    <Label for="select_river" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({ id: "select_year" })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        defaultValue={this.state.year}
                        value={this.state.year}
                        onChange={(e) => this.handleRiverYearChange(e)}
                        options={selectRiverYears}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <hr style={{ marginTop: 2 }} />
          </Col>
        </Row>

        {this.state.river_id ? (
          <Row className="widgets-container">
            <Col xs={12} md={3}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "number_of_catches" })}</h2>
                {this.props.dashboardRiverAdmin.loading && <Spinner />}
                {!this.props.dashboardRiverAdmin.loading && this.props.dashboardRiverAdmin.data ? (
                  <div>
                    <h6>
                      <small>{this.state.riverAdminYear}</small>
                    </h6>
                    <h2 className="value">{this.props.dashboardRiverAdmin.data.number_of_catches}</h2>
                    <h6>
                      <Link to="/catches">{this.props.intl.formatMessage({ id: "view_all" })}</Link>
                    </h6>
                  </div>
                ) : null}
                {!this.props.dashboardRiverAdmin.data && !this.props.dashboardRiverAdmin.loading ? (
                  <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                ) : null}
              </div>
            </Col>

            {!this.props.dashboardRiverAdmin.loading &&
            this.props.dashboardRiverAdmin.data &&
            this.props.dashboardRiverAdmin.data?.has_river_card ? (
              <Col xs={12} md={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "river_card_stats" })}</h2>
                  {!this.props.dashboardRiverAdmin.loading && this.props.dashboardRiverAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.riverAdminYear}</small>
                      </h6>
                      <p className="value">
                        <b>{`${this.props.intl.formatMessage({ id: "number_of_cards_total" })}: `}</b>
                        {this.props.dashboardRiverAdmin.data.river_card_buyers_total}
                      </p>
                      <p className="value">
                        <b>{`${this.props.intl.formatMessage({ id: "number_of_cards_norway" })}: `}</b>
                        {this.props.dashboardRiverAdmin.data.river_card_buyers_norway}
                      </p>
                      <p className="value">
                        <b>{`${this.props.intl.formatMessage({ id: "number_of_cards_abroad" })}: `}</b>
                        {this.props.dashboardRiverAdmin.data.river_card_buyers_abroad}
                      </p>
                    </div>
                  ) : null}
                  {!this.props.dashboardRiverAdmin.data && !this.props.dashboardRiverAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
            ) : null}

            <Col xs={12} md={3}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "number_of_disinfections" })}</h2>
                {this.props.dashboardRiverAdmin.loading && <Spinner />}
                {!this.props.dashboardRiverAdmin.loading && this.props.dashboardRiverAdmin.data ? (
                  <div>
                    <h6>
                      <small>{this.state.riverAdminYear}</small>
                    </h6>
                    <h2 className="value">{this.props.dashboardRiverAdmin.data.number_of_disinfections}</h2>
                    <h6>
                      <Link to="/disinfection">{this.props.intl.formatMessage({ id: "view_all" })}</Link>
                    </h6>
                  </div>
                ) : null}
                {!this.props.dashboardRiverAdmin.data && !this.props.dashboardRiverAdmin.loading ? (
                  <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                ) : null}
              </div>
            </Col>

            <Col xs={12} md={3}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "controlled_by_controller" })}</h2>
                {this.props.dashboardRiverAdmin.loading && <Spinner />}
                {!this.props.dashboardRiverAdmin.loading && this.props.dashboardRiverAdmin.data ? (
                  <div>
                    <h6>
                      <small>{this.state.riverAdminYear}</small>
                    </h6>
                    <Row>
                      <Col xs={6} className="ok">
                        <h2 className="value">{this.props.dashboardRiverAdmin.data.controlled.ok}</h2>
                        <small className="value-suffix">{this.props.intl.formatMessage({ id: "ok" })}</small>
                      </Col>
                      <Col xs={6} className="not-ok">
                        <h2 className="value">{this.props.dashboardRiverAdmin.data.controlled.not_ok}</h2>
                        <small className="value-suffix">{this.props.intl.formatMessage({ id: "not_ok" })}</small>
                      </Col>
                    </Row>
                  </div>
                ) : null}
                {!this.props.dashboardRiverAdmin.data && !this.props.dashboardRiverAdmin.loading ? (
                  <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                ) : null}
              </div>
            </Col>

            <Col xs={12} xl={6} style={{ marginTop: 30 }}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "recent_catches" })}</h2>
                {this.props.dashboardRiverAdminCatches.loading && <Spinner />}
                {!this.props.dashboardRiverAdminCatches.loading && this.props.dashboardRiverAdminCatches.data ? (
                  <div>
                    <Col xs={24}>
                      <Table
                        columns={latestCatchTableColumns}
                        dataSource={latestCatchTableData}
                        size="medium"
                        pagination={false}
                      />
                    </Col>
                    <h6 style={{ marginTop: "15px" }}>
                      <Link to="/catches">{this.props.intl.formatMessage({ id: "view_all" })}</Link>
                    </h6>
                  </div>
                ) : null}
                {!this.props.dashboardRiverAdminCatches.data && !this.props.dashboardRiverAdminCatches.loading ? (
                  <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                ) : null}
              </div>
            </Col>

            <Col xs={12} xl={6} style={{ marginTop: 30 }}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "catch_statistics" })}</h2>

                {this.props.dashboardRiverAdminCatchStatistics.loading && <Spinner />}
                {!this.props.dashboardRiverAdminCatchStatistics.loading &&
                this.props.dashboardRiverAdminCatchStatistics.data ? (
                  <div className="catch-statistics">
                    <Row>
                      <Col xs={12} md={4}>
                        <h5>{this.props.intl.formatMessage({ id: "all_species" })}</h5>
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <b>{this.props.dashboardRiverAdminCatchStatistics.data.all.catched} </b>
                              </td>
                              <td> {this.props.intl.formatMessage({ id: "killed" }).toLowerCase()}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>{this.props.dashboardRiverAdminCatchStatistics.data.all.released} </b>
                              </td>
                              <td> {this.props.intl.formatMessage({ id: "released_catch" }).toLowerCase()}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>{this.props.dashboardRiverAdminCatchStatistics.data.all.total} </b>
                              </td>
                              <td> {this.props.intl.formatMessage({ id: "kg_total" }).toLowerCase()}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>{this.props.dashboardRiverAdminCatchStatistics.data.all.avg} </b>
                              </td>
                              <td> {this.props.intl.formatMessage({ id: "kg_avg_weight" }).toLowerCase()}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "30px" }}>
                      {this.props.dashboardRiverAdminCatchStatistics.data.salmon && (
                        <Col xs={6} md={4}>
                          <h5>{this.props.intl.formatMessage({ id: "fish_type-salmon" })}</h5>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.salmon.catched} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "killed" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.salmon.released} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "released_catch" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.salmon.total} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "kg_total" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.salmon.avg} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "kg_avg_weight" }).toLowerCase()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      )}

                      {this.props.dashboardRiverAdminCatchStatistics.data.seatrout && (
                        <Col xs={6} md={4}>
                          <h5>{this.props.intl.formatMessage({ id: "fish_type-seatrout" })}</h5>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.seatrout.catched} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "killed" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.seatrout.released} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "released_catch" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.seatrout.total} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "kg_total" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data.seatrout.avg} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "kg_avg_weight" }).toLowerCase()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      )}
                    </Row>

                    <Row style={{ marginTop: "30px" }}>
                      {this.props.dashboardRiverAdminCatchStatistics.data["0_3"] && (
                        <Col xs={4} md={4}>
                          <h5>0-3 kg</h5>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data["0_3"].salmon} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "fish_type-salmon" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data["0_3"].seatrout} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "fish_type-seatrout" }).toLowerCase()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      )}

                      {this.props.dashboardRiverAdminCatchStatistics.data["3_7"] && (
                        <Col xs={4} md={4}>
                          <h5>3-7 kg</h5>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data["3_7"].salmon} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "fish_type-salmon" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data["3_7"].seatrout} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "fish_type-seatrout" }).toLowerCase()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      )}

                      {this.props.dashboardRiverAdminCatchStatistics.data["7_and_more"] && (
                        <Col xs={4} md={4}>
                          <h5>7+ kg</h5>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data["7_and_more"].salmon} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "fish_type-salmon" }).toLowerCase()}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b>{this.props.dashboardRiverAdminCatchStatistics.data["7_and_more"].seatrout} </b>
                                </td>
                                <td> {this.props.intl.formatMessage({ id: "fish_type-seatrout" }).toLowerCase()}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      )}
                    </Row>
                  </div>
                ) : null}
                {!this.props.dashboardRiverAdminCatchStatistics.data &&
                !this.props.dashboardRiverAdminCatchStatistics.loading ? (
                  <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                ) : null}
              </div>
            </Col>
            <Col xs={12} xl={6} style={{ marginTop: 30 }}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "river_admin_access" })}</h2>
                {this.props.dashboardRiverAdminAdmins.loading && <Spinner />}
                {!this.props.dashboardRiverAdminAdmins.loading && this.props.dashboardRiverAdminAdmins.data ? (
                  <div>
                    <Col cs={24}>
                      <Table
                        columns={adminUserTableColumns}
                        dataSource={riverAdminsTableData}
                        size="medium"
                        pagination={false}
                      />
                    </Col>
                  </div>
                ) : null}
                {!this.props.dashboardRiverAdminAdmins.data && !this.props.dashboardRiverAdminAdmins.loading ? (
                  <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                ) : null}
              </div>
            </Col>

            <Col xs={12} xl={6} style={{ marginTop: 30 }}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "controller_user_access" })}</h2>
                {this.props.dashboardRiverAdminControllers.loading && <Spinner />}
                {!this.props.dashboardRiverAdminControllers.loading && this.props.dashboardRiverAdminControllers.data ? (
                  <div>
                    <Col cs={24}>
                      <Table
                        columns={adminUserTableColumns}
                        dataSource={riverControllerAdminTableData}
                        size="medium"
                        pagination={false}
                      />
                    </Col>
                  </div>
                ) : null}
                {!this.props.dashboardRiverAdminControllers.loading && !this.props.dashboardRiverAdminControllers.data ? (
                  <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                ) : null}
              </div>
            </Col>

            {this.props.rivers?.data?.find((e) => e.id === this.state.river_id)?.catch_reporting_type == "CARD_CONNECTED" ? (
              <Col xs={12} xl={6} style={{ marginTop: 30 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "fishing_pressure" })}</h2>
                  {this.props.dashboardRiverAdminFishingPressure.loading && <Spinner />}
                  {!this.props.dashboardRiverAdminFishingPressure.loading &&
                  this.props.dashboardRiverAdminFishingPressure.data ? (
                    <div>
                      <Col cs={24}>
                        <Table
                          columns={FishingPressureTableColumns}
                          dataSource={riverFishingPressureTableData}
                          size="medium"
                          pagination={false}
                        />
                      </Col>
                    </div>
                  ) : null}
                  {!this.props.dashboardRiverAdminFishingPressure.data &&
                  !this.props.dashboardRiverAdminFishingPressure.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
            ) : null}
            <br />
            <br />
          </Row>
        ) : null}
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Dashboard__OYsaLFpjQVq8-x1s3kHjFw" />
        {}
      </Tabs.TabPane>
    );

    const htmlBeatAdmin = (
      <Tabs.TabPane tab={this.props.intl.formatMessage({ id: "beat_administrator" })} key="3" style={{ minHeight: "500px" }}>
        <Row>
          <Col xs={12} md={6}>
            {htmlSelectBeat}
          </Col>
        </Row>

        {this.state.beat_id ? (
          <div>
            <Row className="widgets-container">
              <Col xs={12} md={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "report_salesman_total_sale" })}</h2>
                  {this.props.dashboardBeatAdmin.loading && <Spinner />}
                  {!this.props.dashboardBeatAdmin.loading && this.props.dashboardBeatAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.beatAdminYear}</small>
                      </h6>
                      <h2 className="value">{this.props.dashboardBeatAdmin.data.revenue}</h2>
                    </div>
                  ) : null}
                  {!this.props.dashboardBeatAdmin.data && !this.props.dashboardBeatAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
              <Col xs={12} md={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "number_of_cards_sold" })}</h2>
                  {this.props.dashboardBeatAdmin.loading && <Spinner />}
                  {!this.props.dashboardBeatAdmin.loading && this.props.dashboardBeatAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.beatAdminYear}</small>
                      </h6>
                      <h2 className="value">{this.props.dashboardBeatAdmin.data.number_of_cards_sold}</h2>
                    </div>
                  ) : null}
                  {!this.props.dashboardBeatAdmin.data && !this.props.dashboardBeatAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>

              <Col xs={12} md={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "number_of_season_cards_sold" })}</h2>
                  {this.props.dashboardBeatAdmin.loading && <Spinner />}
                  {!this.props.dashboardBeatAdmin.loading && this.props.dashboardBeatAdmin.data ? (
                    <div>
                      <h6>
                        <small>{this.state.beatAdminYear}</small>
                      </h6>
                      <h2 className="value">{this.props.dashboardBeatAdmin.data.number_of_season_cards_sold}</h2>
                    </div>
                  ) : null}
                  {!this.props.dashboardBeatAdmin.data && !this.props.dashboardBeatAdmin.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "30px" }} className="widgets-container">
              <Col sm={12} md={6}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "todays_fisherman" })}</h2>

                  {!this.props.dashboardBeatAdminDailyCards.loading && this.props.dashboardBeatAdminDailyCards.data ? (
                    <div>
                      <Row style={{ textAlign: "center" }}>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleBeatDailyCardsDateChange("prev")}
                          />
                        </Col>
                        <Col sm={6}>
                          <p>
                            <b>{moment(this.state.daily_cards_date).format("DD.MM.YYYY")}</b>
                          </p>
                        </Col>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleBeatDailyCardsDateChange("next")}
                          />
                        </Col>
                      </Row>

                      <div>
                        {this.props.dashboardBeatAdminDailyCards.data &&
                        this.props.dashboardBeatAdminDailyCards.data.fishing_cards.length > 0 ? (
                          <div>
                            <Col xs={24}>
                              <Table columns={beatDailyCardsTableColumns} dataSource={beatDailyCardsTableData} size="small" />
                            </Col>
                          </div>
                        ) : (
                          <div className="text-center no-orders-text">
                            <h4>{this.props.intl.formatMessage({ id: "nothing_found" })}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "todays_accommodation" })}</h2>

                  {!this.props.dashboardBeatAdminDailyAccommodation.loading &&
                  this.props.dashboardBeatAdminDailyAccommodation.data ? (
                    <div>
                      <Row style={{ textAlign: "center" }}>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleBeatDailyAccommodationDateChange("prev")}
                          />
                        </Col>
                        <Col sm={6}>
                          <p>
                            <b>{moment(this.state.daily_cards_date).format("DD.MM.YYYY")}</b>
                          </p>
                        </Col>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleBeatDailyAccommodationDateChange("next")}
                          />
                        </Col>
                      </Row>

                      <div>
                        {this.props.dashboardBeatAdminDailyAccommodation.data &&
                        this.props.dashboardBeatAdminDailyAccommodation.data.accommodation.length > 0 ? (
                          <ul className="dashboard-users-list">
                            {this.props.dashboardBeatAdminDailyAccommodation.data.accommodation.map((i, key) => (
                              <li key={key}>
                                <h4>
                                  {i.package?.order?.user?.first_name} {i.package?.order?.user?.last_name}
                                </h4>
                                <small>
                                  {i.sold_for_price?.name} - {i.sold_for_price?.price}
                                </small>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div className="text-center no-orders-text">
                            <h4>{this.props.intl.formatMessage({ id: "nothing_found" })}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>

              <Col sm={12} style={{ marginTop: "30px" }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "last_orders" })}</h2>
                  {!this.props.dashboard.loading && this.props.dashboard.data ? (
                    <div>
                      {this.props.dashboard.data && this.props.dashboard.data.last_payed_orders.length > 0 ? (
                        <div>
                          <Col xs={24}>
                            <Table columns={ordersTableColumns} dataSource={ordersTableData} size="small" />
                          </Col>

                          <Link to={`/orders?status=paid&beat_id=${this.state.beat_id}`}>
                            {this.props.intl.formatMessage({ id: "all_paid_orders" })}
                          </Link>
                        </div>
                      ) : (
                        <div className="text-center no-orders-text">
                          <h4>{this.props.intl.formatMessage({ id: "no_paid_orders_found" })}</h4>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>

              <Col xs={12} xl={6} style={{ marginTop: 30 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "beat_admin_access" })}</h2>
                  {this.props.dashboardBeatAdminAdmins.loading && <Spinner />}
                  {!this.props.dashboardBeatAdminAdmins.loading && this.props.dashboardBeatAdminAdmins.data ? (
                    <div>
                      <Col cs={24}>
                        <Table
                          columns={adminUserTableColumns}
                          dataSource={beatAdminsTableData}
                          size="medium"
                          pagination={false}
                        />
                      </Col>
                    </div>
                  ) : null}
                  {!this.props.dashboardBeatAdminAdmins.data && !this.props.dashboardBeatAdminAdmins.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>

              <Col xs={12} xl={6} style={{ marginTop: 30 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "members_of_beat" })}</h2>
                  {this.props.dashboardBeatAdminMembers.loading && <Spinner />}
                  {!this.props.dashboardBeatAdminMembers.loading && this.props.dashboardBeatAdminMembers.data ? (
                    <div>
                      <Col cs={24}>
                        <Table
                          columns={adminUserTableColumns}
                          dataSource={beatMembersTableData}
                          size="medium"
                          pagination={false}
                        />
                      </Col>
                    </div>
                  ) : null}
                  {!this.props.dashboardBeatAdminMembers.loading && !this.props.dashboardBeatAdminMembers.data ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Dashboard__Hy-qIA5-ShacxaZPWXm-Cg" />
      </Tabs.TabPane>
    );

    const htmlControllerAdmin = (
      <Tabs.TabPane tab={this.props.intl.formatMessage({ id: "controller_administrator" })} key="4">
        <Row>
          <Col xs={12} md={6}>
            {htmlSelectControllerRiver}
          </Col>
        </Row>

        {this.state.controller_river_id ? (
          <div>
            <Row className="widgets-container">
              <Col xs={12} md={3}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "controlled_by_controller" })}</h2>
                  {this.props.dashboardControllerStatistics.loading && <Spinner />}
                  {!this.props.dashboardControllerStatistics.loading && this.props.dashboardControllerStatistics.data ? (
                    <div>
                      <h6>
                        <small>{this.state.controllerYear}</small>
                      </h6>
                      <Row>
                        <Col xs={6} className="ok">
                          <h2 className="value">{this.props.dashboardControllerStatistics.data.ok}</h2>
                          <small className="value-suffix">{this.props.intl.formatMessage({ id: "ok" })}</small>
                        </Col>
                        <Col xs={6} className="not-ok">
                          <h2 className="value">{this.props.dashboardControllerStatistics.data.not_ok}</h2>
                          <small className="value-suffix">{this.props.intl.formatMessage({ id: "not_ok" })}</small>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {!this.props.dashboardControllerStatistics.data && !this.props.dashboardControllerStatistics.loading ? (
                    <p>{this.props.intl.formatMessage({ id: "no_data_found" })}</p>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: 30 }}>
              <Col sm={12} md={6}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "active_fishing_cards" })}</h2>

                  {!this.props.dashboardControllerAdmin.loading ? (
                    <div>
                      <Row style={{ textAlign: "center" }}>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleControllerDailyCardsDateChange("prev")}
                          />
                        </Col>
                        <Col sm={6}>
                          <p>
                            <b>{moment(this.state.daily_controller_date).format("DD.MM.YYYY")}</b>
                          </p>
                        </Col>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleControllerDailyCardsDateChange("next")}
                          />
                        </Col>
                      </Row>

                      <div>
                        {this.props.dashboardControllerAdmin.data ? (
                          <div>
                            <Col xs={24}>
                              <Table
                                columns={controllerDailyCardsTableColumns}
                                dataSource={controllerDailyCardsTableData}
                                size="small"
                              />
                            </Col>
                          </div>
                        ) : (
                          <div className="text-center no-orders-text">
                            <h4>{this.props.intl.formatMessage({ id: "nothing_found" })}</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "active_season_cards" })}</h2>

                  {!this.props.dashboardControllerAdmin.loading ? (
                    <div>
                      <Row style={{ textAlign: "center" }}>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleControllerDailyCardsDateChange("prev")}
                          />
                        </Col>
                        <Col sm={6}>
                          <p>
                            <b>{moment(this.state.daily_controller_date).format("DD.MM.YYYY")}</b>
                          </p>
                        </Col>
                        <Col sm={3}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 13 }} />}
                            onClick={() => this.handleControllerDailyCardsDateChange("next")}
                          />
                        </Col>
                      </Row>

                      {this.props.dashboardControllerAdmin.data ? (
                        <div>
                          <Col xs={24}>
                            <Table
                              columns={controllerDailySeasonCardsTableColumns}
                              dataSource={controllerDailySeasonCardsTableData}
                              size="small"
                            />
                          </Col>
                        </div>
                      ) : (
                        <div className="text-center no-orders-text">
                          <h4>{this.props.intl.formatMessage({ id: "nothing_found" })}</h4>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Spinner />
                  )}

                  {!this.props.dashboardControllerAdmin.loading && this.props.dashboardControllerAdmin.data ? (
                    <div className="text-center no-orders-text">
                      <h4>{this.props.intl.formatMessage({ id: "nothing_found" })}</h4>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
      </Tabs.TabPane>
    );

    let htmlSuperDash;
    if (isAdmin) {
      htmlSuperDash = (
        <div className="super-dash">
          <Tabs>
            {htmlSuperAdmin}
            {htmlRiverAdmin}
            {htmlBeatAdmin}
            {htmlControllerAdmin}
          </Tabs>
        </div>
      );
    } else {
      htmlSuperDash = (
        <div className="super-dash">
          <Tabs>
            {isRiverAdmin && htmlRiverAdmin}
            {isBeatAdmin && htmlBeatAdmin}
            {isSupervisorAdmin && htmlControllerAdmin}
          </Tabs>
        </div>
      );
    }

    return (
      <div className="dashboard-page">
        {this.props.notifications.data && (
          <div>
            {this.props.notifications.data.map((n, key) => (
              <Notification
                beat_names={n.beat_names}
                key={key}
                text={n.message}
                type={n.type}
                conditions_met={n.conditions_met}
              ></Notification>
            ))}
          </div>
        )}
        <div>{htmlSuperDash}</div>
      </div>
    );
  }

  hasSeasonCards(productList) {
    return productList.season_fishing_cards && productList.season_fishing_cards.length > 0;
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  beats: state.Beats,
  rivers: state.Rivers,
  controllerRivers: state.ControllerRivers,
  dashboard: state.Dashboard,
  laksebors: state.Laksebors,
  notifications: state.Notifications,
  dashboardAdmin: state.DashboardAdmin,
  dashboardAdminCounties: state.DashboardAdminCounties,
  dashboardAdminRivers: state.DashboardAdminRivers,
  dashboardRiverAdmin: state.DashboardRiverAdmin,
  dashboardRiverAdminCatches: state.DashboardRiverAdminCatches,
  dashboardRiverAdminCatchStatistics: state.DashboardRiverAdminCatchStatistics,
  dashboardRiverAdminAdmins: state.DashboardRiverAdminAdmins,
  dashboardRiverAdminFishingPressure: state.DashboardRiverAdminFishingPressure,
  dashboardRiverAdminControllers: state.DashboardRiverAdminControllers,
  dashboardBeatAdmin: state.DashboardBeatAdmin,
  dashboardBeatAdminAdmins: state.DashboardBeatAdminAdmins,
  dashboardBeatAdminMembers: state.DashboardBeatAdminMembers,
  dashboardBeatAdminDailyCards: state.DashboardBeatAdminDailyCards,
  dashboardBeatAdminDailyAccommodation: state.DashboardBeatAdminDailyAccommodation,
  dashboardControllerAdmin: state.DashboardControllerAdmin,
  dashboardControllerStatistics: state.DashboardControllerStatistics,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Dashboard)));
