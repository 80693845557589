import moment from "moment";

const timestamp = moment().format("x");

const downloadFile = (blob, format = "pdf", name = timestamp) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.download = `${name}.${format}`;
  link.target = "_blank";
  link.href = url;
  link.click();
};

export default downloadFile;
