import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import '@formatjs/intl-pluralrules/locale-data/en' 
import '@formatjs/intl-pluralrules/locale-data/nb' 
import '@formatjs/intl-pluralrules/locale-data/sv' 
import antNB from "antd/lib/locale-provider/nb_NO";
import antEN from "antd/lib/locale-provider/en_GB";
import antSV from "antd/lib/locale-provider/sv_SE";
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/nb";
import "moment/locale/en-gb";
import "./ant.css";
import "./app.css";
import "./resources/less/ant.less"

import { store, history } from "./redux/store";
import { DEFAULT_LANGUAGE } from "./constants";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundry/ErrorBoundry";

import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

moment.locale(window.localStorage.getItem("language") || DEFAULT_LANGUAGE);

const antLangProvider = {
    nb: antNB,
    en: antEN,
    sv: antSV
};

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: process.env.SENTRY_DSN_KEY,
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
    })
}

render(
    <ErrorBoundary>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <main>
                    <ConfigProvider locale={antLangProvider[window.localStorage.getItem("language") || DEFAULT_LANGUAGE]}>
                        <App />
                    </ConfigProvider>
                </main>
            </ConnectedRouter>
        </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
);
