export const FETCH_ADMIN_EQUIPMENT = "FETCH_ADMIN_EQUIPMENT";
export const FETCH_ADMIN_EQUIPMENT_SUCCESS = "FETCH_ADMIN_EQUIPMENT_SUCCESS";
export const FETCH_ADMIN_EQUIPMENT_FAILURE = "FETCH_ADMIN_EQUIPMENT_FAILURE";

export const ADD_ADMIN_EQUIPMENT = "ADD_ADMIN_EQUIPMENT";
export const ADD_ADMIN_EQUIPMENT_SUCCESS = "ADD_ADMIN_EQUIPMENT_SUCCESS";
export const ADD_ADMIN_EQUIPMENT_FAILURE = "ADD_ADMIN_EQUIPMENT_FAILURE";

export const UPDATE_ADMIN_EQUIPMENT = "UPDATE_ADMIN_EQUIPMENT";
export const UPDATE_ADMIN_EQUIPMENT_SUCCESS = "UPDATE_ADMIN_EQUIPMENT_SUCCESS";
export const UPDATE_ADMIN_EQUIPMENT_FAILURE = "UPDATE_ADMIN_EQUIPMENT_FAILURE";

export const DELETE_ADMIN_EQUIPMENT = "DELETE_ADMIN_EQUIPMENT";
export const DELETE_ADMIN_EQUIPMENT_SUCCESS = "DELETE_ADMIN_EQUIPMENT_SUCCESS";
export const DELETE_ADMIN_EQUIPMENT_FAILURE = "DELETE_ADMIN_EQUIPMENT_FAILURE";

export function fetch() {
  return {
    type: FETCH_ADMIN_EQUIPMENT
  };
}

export function add(data) {
  return {
    type: ADD_ADMIN_EQUIPMENT,
    data
  };
}

export function update(data) {
  return {
    type: UPDATE_ADMIN_EQUIPMENT,
    data
  };
}

export function remove(id) {
  return {
    type: DELETE_ADMIN_EQUIPMENT,
    id
  };
}
