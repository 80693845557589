export const FETCH_BEAT_OPTIONS = "FETCH_BEAT_OPTIONS";
export const FETCH_BEAT_OPTIONS_SUCCESS = "FETCH_BEAT_OPTIONS_SUCCESS";
export const FETCH_BEAT_OPTIONS_FAILURE = "FETCH_BEAT_OPTIONS_FAILURE";

export const EDIT_BEAT_OPTIONS = "EDIT_BEAT_OPTIONS";
export const EDIT_BEAT_OPTIONS_SUCCESS = "EDIT_BEAT_OPTIONS_SUCCESS";
export const EDIT_BEAT_OPTIONS_FAILURE = "EDIT_BEAT_OPTIONS_FAILURE";

export const EDIT_BEAT_CARD_LIMIT = "EDIT_BEAT_CARD_LIMIT";
export const EDIT_BEAT_CARD_LIMIT_SUCCESS = "EDIT_BEAT_CARD_LIMIT_SUCCESS";
export const EDIT_BEAT_CARD_LIMIT_FAILURE = "EDIT_BEAT_CARD_LIMIT_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_BEAT_OPTIONS,
    payload
  };
}

export function edit(data, payload) {
  return {
    type: EDIT_BEAT_OPTIONS,
    data,
    payload
  };
}

export function editCardLimit(data, payload) {
  return {
    type: EDIT_BEAT_CARD_LIMIT,
    data,
    payload
  };
}
