import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, Row, Col, Input, Table, Select } from "antd";
import uuidv1 from "uuid";

import { editBlueprint as editAccommodationBlueprint } from "../../../../redux/accommodation/actions";
import ANT_CONFIG from "../../../../constants/antconfig";
import ImageUpload from "../../../ImageUpload/ImageUpload";

class ModalEditAccommodationBluePrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      beat_id: props.selectedBeat,
      data: {
        id: null,
        name: "",
        description: "",
        capacity: "",
      },
      price_types: [],
      current_price_types: [],
      priceTypeModalVisible: false,
      temporary_price: null,
      gallery_images: [],
      add_gallery_image: [],
      remove_gallery_image: [],
    };
  }

  componentDidUpdate() {
    if (!this.props.accommodation.loading && !this.props.accommodation.error && this.state.submitted) {
      this.onCancel();
    }
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onAccommodationTypeChange(id) {
    const beatId = this.state.beat_id;
    const accommodationType = this.props.selectBlueprint[beatId].filter((i) => i.id === id)[0];

    this.setState({
      data: {
        id,
        name: accommodationType.name,
        description: accommodationType.description,
        flexible_time: accommodationType.flexible_time,
        capacity: accommodationType.capacity,
      },
      gallery_images: accommodationType.gallery_images,
      price_types: accommodationType.price_types,
      current_price_types: accommodationType.price_types,
    });
  }

  onChangeTemporaryPrice(newState) {
    this.setState((prevState) => ({ temporary_price: { ...prevState.temporary_price, ...newState } }));
  }

  onSubmit = () => {
    if (!this.state.data.id) {
      return false;
    }

    this.setState({
      submitted: true,
    });

    const currentPriceTypes = this.state.current_price_types.map((i) => i.id);
    const updatedPriceTypes = this.state.price_types.map((i) => i.id);
    const data = {
      ...this.state.data,
    };

    // Add new price types
    if (this.state.price_types.filter((i) => i.new).length > 0) {
      data.add_price_types = [...this.state.price_types.filter((i) => i.new)];
    }

    // Update old price types
    if (this.state.price_types.filter((i) => !i.new).length > 0) {
      data.update_price_types = [...this.state.price_types.filter((i) => !i.new)];
    }

    // Remove price types
    if (currentPriceTypes.filter((i) => !updatedPriceTypes.includes(i)).length > 0) {
      data.remove_price_type_ids = [...currentPriceTypes.filter((i) => !updatedPriceTypes.includes(i))];
    }

    if (this.state.add_gallery_image) {
      data.add_gallery_image = this.state.add_gallery_image;
    }

    if (this.state.remove_gallery_image) {
      data.remove_gallery_image = this.state.remove_gallery_image;
    }

    this.props.dispatch(editAccommodationBlueprint(data, this.props.calendarData));
  };

  onSubmitPriceType = () => {
    if (!this.state.temporary_price.tax_option_id || !this.state.temporary_price.name) {
      return false;
    }

    this.setState(
      (prevState) => ({
        price_types: [
          ...prevState.price_types,
          {
            new: true,
            id: uuidv1(),
            ...prevState.temporary_price,
          },
        ],
      }),
      () => {
        this.onCancelAddPriceType();
      }
    );
  };

  onAddPriceType = () => {
    this.setState({
      priceTypeModalVisible: true,
    });
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  onCancelAddPriceType = () => {
    this.setState({
      temporary_price: null,
      priceTypeModalVisible: false,
    });
  };

  addGalleryImage = (file) => {
    if (file.response?.data?.default) {
      this.setState((prevState) => ({
        add_gallery_image: [...prevState.add_gallery_image, { url: file.response.data.default }],
      }));
    }
  };

  removeGalleryImage = (file) => {
    this.setState((prevState) => ({
      remove_gallery_image: [...prevState.remove_gallery_image, { id: file.uid * -1, url: file.file }],
    }));
  };

  render() {
    return (
      <Modal
        width={800}
        title={this.props.intl.formatMessage({ id: "edit_accommodation" })}
        visible={this.state.modal}
        onCancel={this.onCancel}
        footer={
          this.state.data?.id && [
            <Button size={ANT_CONFIG.size} key="price_type" disabled={this.props.accommodation.loading} type="secondary" onClick={this.onAddPriceType}>
              {this.props.intl.formatMessage({ id: "add_price_type" })}
            </Button>,
            <Button size={ANT_CONFIG.size} key="submit" loading={this.props.accommodation.loading} type="primary" onClick={this.onSubmit}>
              {this.props.intl.formatMessage({ id: "save" })}
            </Button>,
          ]
        }
      >
        <Row>
          <Col xs={24}>
            <label className="ant-label" htmlFor="select_accommodation">
              {this.props.intl.formatMessage({ id: "select_accommodation" })}
            </label>
            <Select
              id="select_accommodation"
              size={ANT_CONFIG.size}
              style={{ width: "100%" }}
              placeholder={`${this.props.intl.formatMessage({ id: "select_accommodation" })}`}
              onChange={(e) => this.onAccommodationTypeChange(e)}
            >
              {this.props.selectBlueprint[this.state.beat_id].map((i) => (
                <Select.Option key={i.id} value={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        {this.state.data.id && (
          <Row>
            <Col xs={24} style={{ paddingTop: 20 }}>
              <label className="ant-label" htmlFor="name">
                {this.props.intl.formatMessage({ id: "name" })}
              </label>
              <Input
                type="text"
                name="name"
                id="name"
                size={ANT_CONFIG.size}
                value={this.state.data.name}
                onChange={(e) => this.onChangeValue({ name: e.target.value })}
              />
            </Col>
            <Col xs={24} style={{ paddingTop: 20 }}>
              <label className="ant-label" htmlFor="description">
                {this.props.intl.formatMessage({ id: "description" })}
              </label>
              <Input.TextArea
                name="description"
                id="description"
                size={ANT_CONFIG.size}
                autoSize={{ minRows: 3 }}
                value={this.state.data.description}
                onChange={(e) => this.onChangeValue({ description: e.target.value })}
              />
            </Col>
            <Col xs={24} style={{ paddingTop: 20 }}>
              <label className="ant-label" htmlFor="name">
                {this.props.intl.formatMessage({ id: "capacity" })}
              </label>
              <Input
                type="number"
                name="capacity"
                id="capacity"
                size={ANT_CONFIG.size}
                value={this.state.data.capacity}
                onChange={(e) => this.onChangeValue({ capacity: e.target.value })}
              />
            </Col>

            <Col xs={24} style={{ paddingTop: 20 }}>
              <label className="ant-label">{this.props.intl.formatMessage({ id: "price_types" })}</label>
              <Table
                className="ant-design-table-border"
                columns={[
                  {
                    title: "#",
                    dataIndex: "key",
                    key: "key",
                  },
                  {
                    title: this.props.intl.formatMessage({ id: "name" }),
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: this.props.intl.formatMessage({ id: "description" }),
                    dataIndex: "description",
                    key: "description",
                  },
                  {
                    title: this.props.intl.formatMessage({ id: "tax" }),
                    dataIndex: "tax",
                    key: "tax",
                  },
                  {
                    title: "",
                    dataIndex: "action",
                    key: "action",
                  },
                ]}
                dataSource={this.state.price_types.map((i, key) => {
                  const tax = this.props.taxes?.data?.filter((p) => p.id === i.tax_option_id)?.[0];

                  return {
                    key: key + 1,
                    name: i.name || "-",
                    description: i.description || "-",
                    tax: tax ? `${tax.key} (${tax.value}%)` : "-",
                    action: (
                      <div style={{ textAlign: "right", width: "100%" }}>
                        <Button
                          type="danger"
                          size={ANT_CONFIG.size}
                          onClick={() =>
                            this.setState((prevState) => ({
                              price_types: prevState.price_types.filter((p) => p.id !== i.id),
                            }))
                          }
                        >
                          {this.props.intl.formatMessage({ id: "remove" })}
                        </Button>
                      </div>
                    ),
                  };
                })}
                pagination={ANT_CONFIG.pagination}
              />
            </Col>

            <Col xs={24} style={{ paddingTop: 20 }}>
              <label className="ant-label" htmlFor="image_gallery">
                {this.props.intl.formatMessage({ id: "image_gallery" })}
              </label>
              <ImageUpload multiple data={this.state.gallery_images} onFileRemove={this.removeGalleryImage} onFileAdd={this.addGalleryImage} />
            </Col>

            <Modal
              title={this.props.intl.formatMessage({ id: "add_price_type" })}
              visible={this.state.priceTypeModalVisible}
              onCancel={this.onCancelAddPriceType}
              footer={[
                <Button
                  disabled={!this.state.temporary_price?.tax_option_id || !this.state.temporary_price?.name || !this.state.temporary_price?.description}
                  key="submit"
                  type="primary"
                  size={ANT_CONFIG.size}
                  onClick={this.onSubmitPriceType}
                >
                  {this.props.intl.formatMessage({ id: "save" })}
                </Button>,
              ]}
            >
              <Row>
                <Col xs={24}>
                  <label className="ant-label" htmlFor="price_name">
                    {this.props.intl.formatMessage({ id: "name" })}
                  </label>
                  <Input
                    type="text"
                    name="price_name"
                    id="price_name"
                    size={ANT_CONFIG.size}
                    value={this.state.temporary_price?.name || ""}
                    onChange={(e) => this.onChangeTemporaryPrice({ name: e.target.value })}
                  />
                </Col>
                <Col xs={24} style={{ paddingTop: 20 }}>
                  <label className="ant-label" htmlFor="price_description">
                    {this.props.intl.formatMessage({ id: "description" })}
                  </label>
                  <Input.TextArea
                    name="price_description"
                    id="price_description"
                    autoSize={{ minRows: 2 }}
                    size={ANT_CONFIG.size}
                    value={this.state.temporary_price?.description || ""}
                    onChange={(e) => this.onChangeTemporaryPrice({ description: e.target.value })}
                  />
                </Col>
                <Col xs={24} style={{ paddingTop: 20 }}>
                  {this.props.taxes.data && (
                    <React.Fragment>
                      <label className="ant-label" htmlFor="select_tax">
                        {this.props.intl.formatMessage({ id: "tax" })}
                      </label>
                      <Select
                        id="select_tax"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_tax" })}`}
                        onChange={(e) => this.onChangeTemporaryPrice({ tax_option_id: e })}
                        value={this.state.temporary_price?.tax_option_id}
                      >
                        {this.props.taxes.data.map((i) => (
                          <Select.Option key={i.id} value={i.id}>
                            {`${i.key} (${i.value}%)`}
                          </Select.Option>
                        ))}
                      </Select>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </Modal>
          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  taxes: state.Taxes,
  accommodation: state.Accommodation,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalEditAccommodationBluePrint)));
