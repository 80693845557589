import strings from "./strings";
import calendar from "./calendar";
import packageTypes from "./package_types";
import response from "./response";
import error from "./error";

export default {
  strings: {
    ...strings,
  },
  calendar: {
    ...calendar,
  },
  package_types: {
    ...packageTypes,
  },
  response: {
    ...response,
  },
  error: {
    ...error,
  },
};
