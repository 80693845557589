export const FETCH_BEATS = "FETCH_BEATS";
export const FETCH_BEATS_SUCCESS = "FETCH_BEATS_SUCCESS";
export const FETCH_BEATS_FAILURE = "FETCH_BEATS_FAILURE";

export const FETCH_BEATS_FOR_EMAILS = "FETCH_BEATS_FOR_EMAILS";
export const FETCH_BEATS_FOR_EMAILS_SUCCESS = "FETCH_BEATS_FOR_EMAILS_SUCCESS";
export const FETCH_BEATS_FOR_EMAILS_FAILURE = "FETCH_BEATS_FOR_EMAILS_FAILURE";

export const STORE_BEAT = "STORE_BEAT";
export const STORE_BEAT_SUCCESS = "STORE_BEAT_SUCCESS";
export const STORE_BEAT_FAILURE = "STORE_BEAT_FAILURE";

export const SELECT_BEAT = "SELECT_BEAT";

export function fetch() {
  return {
    type: FETCH_BEATS,
  };
}

export function fetchForEmails() {
  return {
    type: FETCH_BEATS_FOR_EMAILS,
  };
}

export function store(payload) {
  return {
    type: STORE_BEAT,
    payload,
  };
}

export function select(payload) {
  return {
    type: SELECT_BEAT,
    payload,
  };
}
