import React from 'react';

class IEWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      i18n: {
        error: {
          heading: 'Denne nettsiden er ikke kompatibel med din nettleser',
          details:
            'Du burde oppgradere din nettleser til den nyeste versjonen. Den du benytter i dag støttes ikke lenger og kan inneholde potensielle sikkerhetshull. Du kan også prøve en av disse:',
        },
      },
    };
  }

  render() {
    const modalcss = (
      <style jsx="true" global="true">
        {`
          .body-overlay {
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.8;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1000;
          }
          .ie-warning {
            position: relative;
            border-radius: 5px;
            z-index: 1001;
            padding: 50px 50px 100px 50px;
            width: auto;
            min-height: 250px;
            background-color: #fff;
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid #666;
          }
          .ie-warning-heading {
            font-size: 22px;
            text-transform: uppercase;
            font-weight: bold;
            display: block;
            padding: 0 0 25px 0;
            text-align: center;
          }
          .ie-warning-details {
            display: block;
            padding: 0 0 20px 0;
            text-align: center;
            font-size: 18px;
          }
          .ie-warning-browsers {
            display: block;
            margin: 0 auto;
            text-align: center;
          }
          .ie-warning-browsers a {
            width: 150px;
            background-color: #f5f5f5;
            border-radius: 5px;
            display: inline-block;
            box-sizing: border-box;
            border: 1px solid #cecece;
            margin: 0 10px 20px;
          }
          .ie-warning-browsers a .ie-warning-browser-img {
            border-bottom: 1px solid #cecece;
            padding: 20px;
            background-image: url('https://raw.githubusercontent.com/alrra/browser-logos/master/src/main-desktop-browser-logos.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 75px;
            width: 75px;
            margin: 0 auto;
          }
          .ie-warning-browsers a span {
            color: #222;
            text-decoration: none;
            display: inline-block;
            padding: 5px 20px;
          }
          .ie-warning-browsers a:hover span {
            text-decoration: underline;
          }
          .ie-warning-ff {
            background-position-x: 50%;
          }
          .ie-warning-ch {
            background-position-x: 0%;
          }
          .ie-warning-op {
            background-position-x: 75%;
          }
          .ie-warning-ed {
            background-position-x: 25%;
          }
          .ie-warning-disregard {
            position: absolute;
            bottom: 9px;
            right: -1px;
          }
          .ie-warning-disregard a {
            border-bottom-right-radius: 5px;
            border-top-left-radius: 5px;
            background-color: #222;
            padding: 10px;
            color: #fff;
            text-decoration: none;
          }
        `}
      </style>
    );
    const modal = (
      <div>
        <div className="body-overlay" id="ie-body-overlay" />
        <div className="ie-warning" id="ie-warning">
          <span className="ie-warning-heading">{this.state.i18n.error.heading}</span>
          <span className="ie-warning-details">{this.state.i18n.error.details}</span>
          <div className="ie-warning-browsers">
            <a href="https://www.google.com/chrome/">
              <div className="ie-warning-browser-img ie-warning-ch" />
              <span>Chrome</span>
            </a>
            <a href="https://www.firefox.com">
              <div className="ie-warning-browser-img ie-warning-ff" />
              <span>Firefox</span>
            </a>
            <a href="https://www.opera.com">
              <div className="ie-warning-browser-img ie-warning-op" />
              <span>Opera</span>
            </a>
            <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
              <div className="ie-warning-browser-img ie-warning-ed" />
              <span>Edge</span>
            </a>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {modalcss}
        {modal}
      </div>
    );
  }
}

export default IEWarning;
