import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { PageHeader, Row, Col, Select, Card, Button } from "antd";

import translations from "../../constants/strings";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import Spinner from "../../components/spinner";
import ModalAddFreePackage from "../../components/modal/free_packages/add";
import ModalRemoveFreePackage from "../../components/modal/free_packages/remove";
import groupBy from "../../services/group_by";
import { fetch as fetchFreePackages } from "../../redux/free_packages/actions";
import beatOptions from "../../services/select_beat_options";
import ANT_CONFIG from "../../constants/antconfig";
import ModalAddExtraService from "../../components/modal/free_packages/extra_service/add";
import ModalEditExtraService from "../../components/modal/free_packages/extra_service/edit";
import { fetch as fetchExtraServices } from "../../redux/extra_services/actions";
import { fetch as fetchBeatNotifications } from "../../redux/beat_notifications/actions";
import Notification from "../../components/Notification/Notification";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";

class FreePackages extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.localizer = momentLocalizer(moment);
    this.state = {
      today: moment().format(this.dateFormat),
      data: {
        beat_ids: this.props.beats.selected ? [this.props.beats.selected] : [this.props.authentication.data.beat[0]],
        date_start: moment().subtract(1, "months").startOf("month").format(`${this.dateFormat}`),
        date_end: moment().add(1, "months").endOf("month").format(`${this.dateFormat}`),
      },
      showModal: false,
      modalData: {
        startDate: null,
        endDate: null,
        items: [],
      },
      delete_events: false,
      selected_events: [],
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.props.dispatch(
      fetchExtraServices({
        beat_id: this.state.data.beat_ids[0],
      })
    );

    this.props.dispatch(fetchFreePackages(this.state.data));

    this.props.dispatch(fetchBeatNotifications(this.state.data.beat_ids[0]));
  }

  onSelectEvent(e) {
    if (!e.reserved) {
      const selectedEvents = this.state.selected_events;

      if (this.state.delete_events) {
        if (selectedEvents.map((i) => i.id).includes(e.id)) {
          this.setState({
            selected_events: selectedEvents.filter((i) => i.id !== e.id),
          });
        } else {
          this.setState((prevState) => ({
            selected_events: [...prevState.selected_events, e],
          }));
        }

        return true;
      }

      this.toggleModal("add_availability", e.start, e.end, e.items);
    }

    return true;
  }

  onSelectSlot({ start, end }) {
    const startDate = moment(start).format(this.dateFormat);
    const endDate = moment(end).format(this.dateFormat);
    this.toggleModal("add_availability", startDate, endDate);
  }

  onNavigate(e) {
    const date = moment(e).format(this.dateFormat);
    const startOfMonth = moment(date).subtract(1, "months").startOf("month").format(this.dateFormat);
    const endOfMonth = moment(date).add(1, "months").endOf("month").format(this.dateFormat);
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          date_start: startOfMonth,
          date_end: endOfMonth,
        },
      }),
      () => {
        this.props.dispatch(fetchFreePackages(this.state.data));
      }
    );
  }

  toggleModal(modalName, startDate, endDate, items) {
    if (startDate && endDate) {
      this.setState({
        modalData: {
          startDate,
          endDate,
          items: items || [],
        },
      });
    }

    this.setState({
      showModal: modalName,
    });
  }

  handleBeatChange(e) {
    this.props.dispatch(selectBeat(e));
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          beat_ids: [e],
        },
      }),
      () => {
        this.props.dispatch(
          fetchExtraServices({
            beat_id: e,
          })
        );
        this.props.dispatch(fetchFreePackages(this.state.data));
        this.props.dispatch(fetchBeatNotifications(this.state.data.beat_ids[0]));
      }
    );
  }

  hideModal() {
    this.setState({
      showModal: null,
    });
  }

  hideModalAfterDelete() {
    this.setState({
      showModal: null,
      delete_events: false,
      selected_events: [],
    });
  }

  render() {

    if (!this.props.beats.data || !this.props.authentication.data) {
      return <Spinner />;
    }

    let freePackages = [];
    const Event = (event) => {
      let color = this.state.selected_events.map((i) => i.id).includes(event.event.id) ? "yellow" : "blue";

      if (event.event.reserved) {
        color = "green";
      }

      return <div className={`event-item color-${color}`}>{event.title}</div>;
    };
    const selectBeats = beatOptions(this.props.beats, this.props.authentication);

    if (this.props.free_packages.data) {
      this.state.data.beat_ids.map((beatId) => {
        const freePacks = this.props.free_packages.data[beatId];

        if (freePacks) {
          const results = groupBy(freePacks, (item) => {
            return [
              item.publish_date_start,
              item.publish_date_end,
              item.name,
              item.description,
              item.date_start,
              item.date_end,
              item.price_options,
              item.package_id !== null,
            ];
          });

          results.map((items) => {
            const joinedIds = items.map((i) => i.id).join("");
            const reserved = items[0].package_id !== null;
            const id = `${beatId}${joinedIds}`;
            const titleText = reserved ? this.props.intl.formatMessage({ id: "reserved" }) : this.props.intl.formatMessage({ id: "available" });
            const title = `${items.length} ${titleText}: ${items[0].name}`;
            freePackages = [...freePackages, { id, title, items, start: items[0].date_start, end: items[0].date_end, reserved }];

            return freePackages;
          });
        }

        return true;
      });
    }

    return (
      <div>
        {this.props.beat_notifications.data && this.context.isAdmin && (
          <div>
            {this.props.beat_notifications.data.map((n, key) => {
              return <Notification key={key} text={n.message} type={n.type} conditions_met={n.conditions_met}></Notification>  
            })}
          </div>
        )}
        {this.state.delete_events && (
          <div className="panel panel-delete">
            {this.props.intl.formatMessage({ id: "delete_multiple_products_alert" })}

            <Button type="danger" onClick={() => this.toggleModal("remove_multiple")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "confirm_deletion" })}
            </Button>
          </div>
        )}

        <PageHeader
          title={this.props.intl.formatMessage({ id: "packs" })}
          extra={[
            <Button key="1" onClick={() => this.toggleModal("add_extra_service")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_extra_service" })}
            </Button>,
            <Button
              key="2"
              loading={this.props.extra_services.loading}
              disabled={this.props.extra_services.loading}
              onClick={() => this.toggleModal("edit_extra_service")}
              size={ANT_CONFIG.size}
            >
              {this.props.intl.formatMessage({ id: "edit_extra_service" })}
            </Button>,
            <Button key="3" type="primary" onClick={() => this.toggleModal("add_availability", this.state.today, this.state.today)} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_package" })}
            </Button>,
            <Button
              size={ANT_CONFIG.size}
              key="4"
              type="danger"
              onClick={() => this.setState((prevState) => ({ delete_events: !prevState.delete_events, selected_events: [] }))}
            >
              {this.props.intl.formatMessage({ id: "remove" })}
            </Button>,
          ]}
        >
          {selectBeats.length > 1 && (
            <Row style={{ marginTop: 15 }}>
              <Col xs={24} sm={10} md={10} style={{ marginTop: 10, marginBottom: 10, maxWidth: 500 }}>
                <label htmlFor="select_beat" className="ant-label">
                  {this.props.intl.formatMessage({ id: "select_beat" })}
                </label>
                <Select
                  id="select_beat"
                  showSearch
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                  optionFilterProp="children"
                  onChange={(e) => this.handleBeatChange(e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={this.state.data.beat_ids}
                >
                  {selectBeats.map((i, key) => (
                    <Select.Option key={key} value={i.value}>
                      {i.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
        </PageHeader>

        <Card className="booking-calendar-card">
          {this.state.showModal === "add_extra_service" && (
            <ModalAddExtraService selectedBeat={this.state.data.beat_ids[0]} toggleModal={() => this.hideModal()} />
          )}

          {this.state.showModal === "edit_extra_service" && (
            <ModalEditExtraService selectedBeat={this.state.data.beat_ids[0]} toggleModal={() => this.hideModal()} />
          )}

          {this.state.showModal === "add_availability" ? (
            <ModalAddFreePackage
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              modalData={this.state.modalData}
              calendarData={this.state.data}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "remove_multiple" ? (
            <ModalRemoveFreePackage
              intl={this.props.intl}
              items={this.state.selected_events}
              calendarData={this.state.data}
              toggleModal={() => this.hideModalAfterDelete()}
            />
          ) : null}

          {this.props.free_packages.loading && !this.state.showModal && (
            <div className="booking-panel-preloader">
              <Spinner />
            </div>
          )}

          <Row>
            <Col xs={24} className="rc-calendar-container">
              <Calendar
                popup
                selectable
                localizer={this.localizer}
                culture={this.props.intl.locale}
                events={freePackages}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={(e) => this.onSelectSlot(e)}
                views={["month"]}
                messages={{
                  ...translations.calendar[this.props.intl.locale],
                  showMore: (total) => `+${total} ${this.props.intl.formatMessage({ id: "more" })}`,
                }}
                onNavigate={(e) => this.onNavigate(e)}
                onSelectEvent={(e) => this.onSelectEvent(e)}
                components={{
                  event: Event,
                }}
              />
            </Col>
          </Row>
        </Card>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Legge_ut_en_pakke_fiskekort__overnatting__Ku7bnHxQTc-XYftD3qU7MA"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  extra_services: state.ExtraServices,
  authentication: state.Auth,
  beats: state.Beats,
  free_packages: state.FreePackages,
  beat_notifications: state.BeatNotifications,
});

export default withRouter(injectIntl(connect(mapStateToProps)(FreePackages)));
