export const FETCH_DASHBOARD_RIVER_ADMIN_CATCHES = "FETCH_DASHBOARD_RIVER_ADMIN_CATCHES";
export const FETCH_DASHBOARD_RIVER_ADMIN_CATCHES_SUCCESS = "FETCH_DASHBOARD_RIVER_ADMIN_CATCHES_SUCCESS";
export const FETCH_DASHBOARD_RIVER_ADMIN_CATCHES_FAILURE = "FETCH_DASHBOARD_RIVER_ADMIN_CATCHES_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_RIVER_ADMIN_CATCHES,
    payload
  };
}
