import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  isAuthenticated: false,
  error: false,
  user: false,
  data: undefined
  // token: window.localStorage.getItem("jwt_token")
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    // LOGIN

    case ACTION.AUTH_LOGIN:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        error: false
      };

    case ACTION.AUTH_LOGIN_SUCCESS:
      // window.localStorage.setItem("jwt_token", action.payload.data.access_token);

      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: false
        // token: action.payload.data.access_token
      };

    case ACTION.AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: true
      };

    // CHECK

    case ACTION.AUTH_CHECK:
      return {
        ...state,
        loading: true
      };

    case ACTION.AUTH_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        isAuthenticated: true
      };

    case ACTION.AUTH_CHECK_FAILURE:
      // window.localStorage.removeItem("jwt_token");

      return {
        ...state,
        loading: false,
        error: true
      };

    // REFRESH

    case ACTION.AUTH_REFRESH_TOKEN:
      return state;

    case ACTION.AUTH_REFRESH_TOKEN_SUCCESS:
      // window.localStorage.setItem("jwt_token", action.payload.data.access_token);

      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        error: false
        // token: action.payload.data.access_token
      };

    case ACTION.AUTH_REFRESH_TOKEN_FAILURE:
      // window.localStorage.removeItem("jwt_token");

      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: false
      };

    // LOG OUT

    case ACTION.AUTH_LOGOUT:
      return {
        ...state,
        loading: true
      };

    case ACTION.AUTH_LOGOUT_SUCCESS:
      // window.localStorage.removeItem("jwt_token");
      window.location.reload();
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: false
      };

    case ACTION.AUTH_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    // FORGOT PASSWORD

    case ACTION.AUTH_FORGOT_PASSWORD:
      return {
        ...state,
        loading: true
      };

    case ACTION.AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.AUTH_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    // RESET PASSWORD

    case ACTION.AUTH_RESET_PASSWORD:
      return {
        ...state,
        loading: true
      };

    case ACTION.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.AUTH_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    // EDIT PROFILE

    case ACTION.EDIT_USER:
      return {
        ...state,
        loading: true
      };

    case ACTION.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.EDIT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    // CHANGE PASSWORD

    case ACTION.CHANGE_PASSWORD:
      return {
        ...state,
        loading: true
      };

    case ACTION.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    // DEFAULT ACTION

    default:
      return state;
  }
}
