export const FETCH_TAXES = "FETCH_TAXES";
export const FETCH_TAXES_SUCCESS = "FETCH_TAXES_SUCCESS";
export const FETCH_TAXES_FAILURE = "FETCH_TAXES_FAILURE";

export const ADD_TAX = "ADD_TAX";
export const ADD_TAX_SUCCESS = "ADD_TAX_SUCCESS";
export const ADD_TAX_FAILURE = "ADD_TAX_FAILURE";

export const EDIT_TAX = "EDIT_TAX";
export const EDIT_TAX_SUCCESS = "EDIT_TAX_SUCCESS";
export const EDIT_TAX_FAILURE = "EDIT_TAX_FAILURE";

export const REMOVE_TAX = "REMOVE_TAX";
export const REMOVE_TAX_SUCCESS = "REMOVE_TAX_SUCCESS";
export const REMOVE_TAX_FAILURE = "REMOVE_TAX_FAILURE";

export function fetch() {
  return {
    type: FETCH_TAXES,
  };
}

export function add(payload) {
  return {
    type: ADD_TAX,
    payload
  };
}

export function edit(payload) {
  return {
    type: EDIT_TAX,
    payload
  };
}

export function remove(payload) {
  return {
    type: REMOVE_TAX,
    payload
  };
}