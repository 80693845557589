import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  available: undefined,
  data: undefined,
  type: {
    data: undefined,
    loading: false,
    error: false
  },
  prices: {
    data: undefined,
    extra: undefined,
    loading: false,
    error: false
  }
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_FISHING_CARDS:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_FISHING_CARDS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_FISHING_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_FOR_BOOKING_CALENDAR_FISHING_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.ADD_FISHING_CARDS:
      return {
        ...state,
        loading: true
      };

    case ACTION.ADD_FISHING_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.ADD_FISHING_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.EDIT_FISHING_CARDS:
      return {
        ...state,
        loading: true
      };

    case ACTION.EDIT_FISHING_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.EDIT_FISHING_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.REMOVE_FISHING_CARDS:
      return {
        ...state,
        loading: true
      };

    case ACTION.REMOVE_FISHING_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.REMOVE_FISHING_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_AVAILABLE_FISHING_CARDS:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_AVAILABLE_FISHING_CARDS_SUCCESS:
      return {
        ...state,
        available: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_AVAILABLE_FISHING_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_FISHING_CARDS_TYPES:
      return {
        ...state,
        type: {
          ...state.type,
          loading: true
        }
      };

    case ACTION.FETCH_FISHING_CARDS_TYPES_SUCCESS:
      return {
        ...state,
        type: {
          data: action.payload.data,
          loading: false,
          error: false
        }
      };

    case ACTION.FETCH_FISHING_CARDS_TYPES_FAILURE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: true
        }
      };

    case ACTION.ADD_FISHING_CARDS_TYPE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: true
        }
      };

    case ACTION.ADD_FISHING_CARDS_TYPE_SUCCESS:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: false
        }
      };

    case ACTION.ADD_FISHING_CARDS_TYPE_FAILURE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: true
        }
      };

    case ACTION.EDIT_FISHING_CARDS_TYPE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: true
        }
      };

    case ACTION.EDIT_FISHING_CARDS_TYPE_SUCCESS:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: false
        }
      };

    case ACTION.EDIT_FISHING_CARDS_TYPE_FAILURE:
      return {
        ...state,
        type: {
          ...state.type,
          loading: false,
          error: true
        }
      };

    case ACTION.FETCH_FISHING_CARD_PRICES:
      return {
        ...state,
        prices: {
          ...state.prices,
          loading: true
        }
      };

    case ACTION.FETCH_FISHING_CARD_PRICES_SUCCESS:
      return {
        ...state,
        prices: {
          data: action.payload.data,
          extra: action.payload.extra,
          loading: false,
          error: false
        }
      };

    case ACTION.FETCH_FISHING_CARD_PRICES_FAILURE:
      return {
        ...state,
        prices: {
          ...state.prices,
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
}
