import { notification } from "antd";

import capitilize from "./capitilize";

const APIMessage = {
  construct(type, messages) {
    if (typeof messages === "object" && !Array.isArray(messages)) {
      Object.values(messages).forEach((message) => {
        const msg = Array.isArray(message) ? message[0] : message;

        return notification[type]({
          key: msg,
          message: capitilize(msg),
        });
      });

      return true;
    }

    if (typeof messages === "object" && Array.isArray(messages)) {
      messages.forEach((msg) => {
        return notification[type]({
          key: msg,
          message: capitilize(msg),
        });
      });

      return true;
    }

    if (typeof messages === "string") {
      return notification[type]({
        key: messages,
        message: capitilize(messages),
      });
    }

    return true;
  },
  error(messages) {
    APIMessage.construct("error", messages);
  },
  success(messages) {
    APIMessage.construct("success", messages);
  },
};

export default APIMessage;
