export const FETCH_SEASON_CARDS_PRICES = "FETCH_SEASON_CARDS_PRICES";
export const FETCH_SEASON_CARDS_PRICES_SUCCESS = "FETCH_SEASON_CARDS_PRICES_SUCCESS";
export const FETCH_SEASON_CARDS_PRICES_FAILURE = "FETCH_SEASON_CARDS_PRICES_FAILURE";

export const POST_SEASON_CARDS_PRICES = "POST_SEASON_CARDS_PRICES";
export const POST_SEASON_CARDS_PRICES_SUCCESS = "POST_SEASON_CARDS_PRICES_SUCCESS";
export const POST_SEASON_CARDS_PRICES_FAILURE = "POST_SEASON_CARDS_PRICES_FAILURE";

export function fetch(beatId) {
  return {
    type: FETCH_SEASON_CARDS_PRICES,
    beatId
  };
}

export function post(payload, beatId) {
  return {
    type: POST_SEASON_CARDS_PRICES,
    payload,
    beatId
  };
}
