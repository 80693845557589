export const FETCH_PACKAGES_TYPES = "FETCH_PACKAGES_TYPES";
export const FETCH_PACKAGES_TYPES_SUCCESS = "FETCH_PACKAGES_TYPES_SUCCESS";
export const FETCH_PACKAGES_TYPES_FAILURE = "FETCH_PACKAGES_TYPES_FAILURE";

export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";
export const FETCH_PACKAGES_FAILURE = "FETCH_PACKAGES_FAILURE";

export const ADD_PACKAGE = "ADD_PACKAGE";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAILURE = "ADD_PACKAGE_FAILURE";

export const EDIT_PACKAGE = "EDIT_PACKAGE";
export const EDIT_PACKAGE_SUCCESS = "EDIT_PACKAGE_SUCCESS";
export const EDIT_PACKAGE_FAILURE = "EDIT_PACKAGE_FAILURE";

export const REMOVE_PACKAGE = "REMOVE_PACKAGE";
export const REMOVE_PACKAGE_SUCCESS = "REMOVE_PACKAGE_SUCCESS";
export const REMOVE_PACKAGE_FAILURE = "REMOVE_PACKAGE_FAILURE";

export function fetchTypes() {
  return {
    type: FETCH_PACKAGES_TYPES,
  }
}

export function fetch(payload) {
  return {
    type: FETCH_PACKAGES,
    payload
  };
}

export function add(data, payload) {
  return {
    type: ADD_PACKAGE,
    data,
    payload
  };
}

export function edit(data, payload) {
  return {
    type: EDIT_PACKAGE,
    data,
    payload
  };
}

export function remove(data, payload) {
  return {
    type: REMOVE_PACKAGE,
    data,
    payload
  };
}
