export const ADD_CONTROLER_COMMENT = "ADD_CONTROLER_COMMENT";
export const ADD_CONTROLER_COMMENT_SUCCESS = "ADD_CONTROLER_COMMENT_SUCCESS";
export const ADD_CONTROLER_COMMENT_FAILURE = "ADD_CONTROLER_COMMENT_FAILURE";

export function add(payload) {
  return {
    type: ADD_CONTROLER_COMMENT,
    payload,
  };
}
