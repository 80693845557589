import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_EXTRA_SERVICE:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_EXTRA_SERVICE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false
      };

    case ACTION.FETCH_EXTRA_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.ADD_EXTRA_SERVICE:
      return {
        ...state,
        loading: true
      };

    case ACTION.ADD_EXTRA_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.ADD_EXTRA_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.UPDATE_EXTRA_SERVICE:
      return {
        ...state,
        loading: true
      };

    case ACTION.UPDATE_EXTRA_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.UPDATE_EXTRA_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.REMOVE_EXTRA_SERVICE:
      return {
        ...state,
        loading: true
      };

    case ACTION.REMOVE_EXTRA_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.REMOVE_EXTRA_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}
