const riverOptions = (props, auth) => {
  if (!props.data) {
    return false;
  }

  return props.data
    .filter(river => auth.data.river.includes(river.id))
    .map(river => {
      return {
        value: river.id,
        label: river.name
      };
    });
};

export default riverOptions;
