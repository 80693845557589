import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { Table, DatePicker, Card, Col, Row, PageHeader, Modal, Select, Button, Tag, Input } from "antd";

import { fetch as fetchOrders, fetchByProductDate, sendLink, cancel as cancelOrder } from "../../redux/orders/actions";
import Spinner from "../../components/spinner";
import beatOptions from "../../services/select_beat_options";
import { fetch as fetchBeats } from "../../redux/beats/actions";
import ANT_CONFIG from "../../constants/antconfig";
import Scribe from "../../components/Scribe";

const { confirm } = Modal;

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.status = ["PAID", "PENDING", "CANCELLED"];
    this.getParams = new URLSearchParams(window.location.search);
    this.state = {
      dateFilter: "product",
      filter: "",
      isAdmin: false,
      sendLinkLoading: [],
      status_filter:
        this.getParams.get("status") && this.status.includes(this.getParams.get("status").toUpperCase()) ? this.getParams.get("status").toUpperCase() : "",
      data: {
        beat_id: this.getParams.get("beat_id") ? parseInt(this.getParams.get("beat_id"), 10) : this.props.authentication.data.beat[0],
        status: this.status,
        date_start: moment().subtract(7, "days").format(`${this.dateFormat}`),
        date_end: moment().format(`${this.dateFormat}`),
      },
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    const { roles } = this.props.authentication.data;

    if (!this.state.isAdmin) {
      roles.map((role) => (role === "admin" ? this.setState({ isAdmin: true }) : ""));
    }

    this.handleDataChange();
  }

  handleDataChange() {
    if (this.state.dateFilter === "product") {
      this.props.dispatch(fetchByProductDate(this.state.data));
    }

    if (this.state.dateFilter === "order") {
      this.props.dispatch(fetchOrders(this.state.data));
    }
  }

  handleChangeDate(e, key) {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, [key]: moment(e).format(`${this.dateFormat}`) },
      }),
      () => {
        this.handleDataChange();
      }
    );
  }

  handleLinkSend(orderId) {
    this.setState(
      (prevState) => ({
        sendLinkLoading: [...prevState.sendLinkLoading, orderId],
      }),
      () => {
        return new Promise((resolve, reject) => {
          this.props
            .dispatch(sendLink(parseInt(orderId, 10)))
            .then(() => {
              this.setState((prevState) => ({
                sendLinkLoading: prevState.sendLinkLoading.filter((i) => i !== orderId),
              }));
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      }
    );
  }

  handleBeatChange(e) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          beat_id: e,
        },
      }),
      () => {
        this.handleDataChange();
      }
    );
  }

  handleDateFilterChange(e) {
    this.setState(
      {
        dateFilter: e,
      },
      () => {
        this.handleDataChange();
      }
    );
  }

  handleStatusFilterChange(e) {
    this.setState({ status_filter: e });
  }

  handleFilterChange(filter) {
    this.setState({
      filter,
    });
  }

  showConfirm(order) {
    const { id, river_ids } = order;
    const { intl, orders } = this.props;

    confirm({
      confirmLoading: orders.loading,
      title: `${river_ids?.length > 0 ? `${intl.formatMessage({ id: "this_order_has_other_products_besides_beat_products" })} ` : ""}
        ${intl.formatMessage({ id: "only_fishing_cards_refunds",})}
        ${intl.formatMessage({ id: "confirm_cancel_order_question",})}`,
      okText: intl.formatMessage({ id: "yes" }),
      okType: "danger",
      onOk: () => this.handleOk(id),
      onCancel() {},
    });
  }

  handleOk(orderId) {
    return new Promise((resolve, reject) => {
      this.props
        .dispatch(cancelOrder(orderId, this.state.data))
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  render() {
    if (!this.props.orders.data || (this.props.orders.loading && !this.state.showModal)) {
      return <Spinner />;
    }

    let selectBeats = [];

    if (this.props.beats.data && this.props.beats.data.length > 0) {
      selectBeats = [...selectBeats, ...beatOptions(this.props.beats, this.props.authentication)];
    }

    const statusFilter = [
      {
        value: "",
        label: this.props.intl.formatMessage({ id: "ALL" }),
      },
      {
        value: "PAID",
        label: this.props.intl.formatMessage({ id: "PAID" }),
      },
      {
        value: "PENDING",
        label: this.props.intl.formatMessage({ id: "PENDING" }),
      },
      {
        value: "CANCELLED",
        label: this.props.intl.formatMessage({ id: "CANCELLED" }),
      },
    ];

    const dateFitler = [
      {
        value: "product",
        label: this.props.intl.formatMessage({ id: "product_date" }),
      },
      {
        value: "order",
        label: this.props.intl.formatMessage({ id: "order_date" }),
      },
    ];

    let { data } = this.props.orders;

    if (this.state.status_filter) {
      data = data.filter((i) => i.status === this.state.status_filter);
    }

    if (this.state.filter) {
      data = data.filter((i) => {
        const filter = this.state.filter.toLowerCase();
        const fullName = `${i.user.first_name} ${i.user.last_name}`;

        return (
          (i.id && i.id.toString().includes(filter)) ||
          (i.user.first_name && i.user.first_name.toLowerCase().includes(filter)) ||
          (i.user.last_name && i.user.last_name.toLowerCase().includes(filter)) ||
          (fullName && fullName.toLowerCase().includes(filter)) ||
          (i.user.email && i.user.email.toLowerCase().includes(filter))
        );
      });
    }

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: this.props.intl.formatMessage({ id: "product_type" }),
        dataIndex: "product_type",
        key: "product_type",
      },
      {
        title: this.props.intl.formatMessage({ id: "customer" }),
        dataIndex: "customer",
        key: "customer",
      },
      {
        title: this.props.intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: this.props.intl.formatMessage({ id: "from_date" }),
        dataIndex: "from_date",
        key: "from_date",
      },
      {
        title: this.props.intl.formatMessage({ id: "to_date" }),
        dataIndex: "to_date",
        key: "to_date",
      },
      {
        title: this.props.intl.formatMessage({ id: "price" }),
        dataIndex: "price",
        key: "price",
      },
      {
        title: `${this.props.intl.formatMessage({ id: "download" })} ${this.props.intl.formatMessage({ id: "fishing_cards" })}`,
        dataIndex: "download",
        key: "download",
      },
      {
        title: this.props.intl.formatMessage({ id: "send_link" }),
        dataIndex: "send_link",
        key: "send_link",
      },
      {
        title: this.props.intl.formatMessage({ id: "order_status" }),
        dataIndex: "order_status",
        key: "order_status",
      },
    ];
    if (this.state.isAdmin) {
      tableColumns.push({
        title: this.props.intl.formatMessage({ id: "cancel_order" }),
        dataIndex: "cancel_order",
        key: "cancel_order",
      });
    }

    const tableData = data.map((order) => {
      let color;
      const type = order.has_type?.length > 0 ? order.has_type.map((i) => this.props.intl.formatMessage({ id: i })).join(", ") : "-";
      let dates = [];
      let dateFrom = "";
      let dateTo = "";

      if (order.status === "PAID") {
        color = "green";
      }
      if (order.status === "PENDING") {
        color = "orange";
      }
      if (order.status === "CANCELLED") {
        color = "red";
      }

      if (order.packages?.length > 0) {
        order.packages.forEach((pack) => {
          if (pack.fishing_cards?.length > 0) {
            pack.fishing_cards.forEach((i) => {
              dates = [...dates, i.date];
            });
          }

          if (pack.accommodations?.length > 0) {
            pack.accommodations.forEach((i) => {
              dates = [...dates, i.date];
            });
          }

          if (pack.free_packages?.length > 0) {
            pack.free_packages.forEach((i) => {
              dates = [...dates, i.date_start, i.date_end];
            });
          }

          if (pack.season_fishing_cards.length > 0) {
            pack.season_fishing_cards.forEach((i) => {
              dates = [...dates, i.season_start_date, i.season_end_date];
            });
          }
        });
      }

      if (dates?.length > 0) {
        dateFrom = dates.reduce((pre, cur) => (Date.parse(pre) > Date.parse(cur) ? cur : pre));
        dateTo = dates.reduce((pre, cur) => (Date.parse(pre) < Date.parse(cur) ? cur : pre));
      }

      return {
        key: order.id,
        product_type: type || "-",
        customer: `${order.user.first_name} ${order.user.last_name}`,
        beat: `${order.beats.length > 0 ? order.beats.join(", ") : "-"}`,
        from_date: dateFrom ? moment(dateFrom).format("DD.MM.YYYY") : "-",
        to_date: dateTo ? moment(dateTo).format("DD.MM.YYYY") : "-",
        price: `${order.total} NOK`,
        download:
          order.status === "PAID" && type !== this.props.intl.formatMessage({ id: "accommodation" }) ? (
            <a href={`${order.fishing_cards_pdf_url}`} rel="noopener noreferrer" target="_blank">
              <Button icon={<DownloadOutlined />} type="default">
                {this.props.intl.formatMessage({ id: "download" })}
              </Button>
            </a>
          ) : (
            <span>&nbsp;</span>
          ),
        send_link:
          order.status === "PENDING" ? (
            <Button type="primary" onClick={() => this.handleLinkSend(order.id)} loading={this.state.sendLinkLoading.includes(order.id)}>
              {this.props.intl.formatMessage({ id: "send_link" })}
            </Button>
          ) : (
            <span>&nbsp;</span>
          ),
        order_status: (
          <Tag color={color} className="ant-tag">
            {this.props.intl.formatMessage({ id: order.status })}
          </Tag>
        ),
        cancel_order: this.state.isAdmin && (
          <React.Fragment>
            {order.status !== "CANCELLED" ? (
              <Button type="danger" onClick={() => this.showConfirm(order)} icon={<DeleteOutlined />}>
                {this.props.intl.formatMessage({ id: "cancel" })}
              </Button>
            ) : (
              <span>&nbsp;</span>
            )}
          </React.Fragment>
        ),
      };
    });

    return (
      <div>
        <PageHeader title={this.props.intl.formatMessage({ id: "orders" })}>
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                  <label htmlFor="search_by_id_name_or_email" className="ant-label">
                    {this.props.intl.formatMessage({ id: "search_by_id_name_or_email" })}
                  </label>
                  <Input
                    placeholder={this.props.intl.formatMessage({ id: "search_by_id_name_or_email" })}
                    id="search_by_id_name_or_email"
                    size={ANT_CONFIG.size}
                    value={this.state.filter}
                    onChange={(e) => this.handleFilterChange(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>

                {selectBeats.length > 1 && (
                  <Col xs={24} sm={4} md={4} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                    <label htmlFor="select_beat" className="ant-label">
                      {this.props.intl.formatMessage({ id: "select_beat" })}
                    </label>
                    <Select
                      id="select_beat"
                      showSearch
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleBeatChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.data.beat_id}
                    >
                      {selectBeats.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col xs={24} sm={4} md={4} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                  <label htmlFor="select_orders_by" className="ant-label">
                    {this.props.intl.formatMessage({ id: "select_orders_by" })}
                  </label>
                  <Select
                    id="select_orders_by"
                    showSearch
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({ id: "select_orders_by" })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.handleDateFilterChange(e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={this.state.dateFilter}
                  >
                    {dateFitler.map((i, key) => (
                      <Select.Option key={key} value={i.value}>
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_start" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_start" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_start)}
                    onChange={(e) => this.handleChangeDate(e, "date_start")}
                  />
                </Col>

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_end" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_end)}
                    onChange={(e) => this.handleChangeDate(e, "date_end")}
                  />
                </Col>

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="select_order_status" className="ant-label">
                    {this.props.intl.formatMessage({ id: "order_status" })}
                  </label>
                  <Select
                    id="select_order_status"
                    showSearch
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({ id: "order_status" })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.handleStatusFilterChange(e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={this.state.status_filter}
                  >
                    {statusFilter.map((i, key) => (
                      <Select.Option key={key} value={i.value}>
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table columns={tableColumns} dataSource={tableData} pagination={ANT_CONFIG.pagination} />
            </Col>
          </Row>
        </Card>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Ordrelista__se_og_soke_etter_ordre__DhwzRGSxTDGlZGcwq_1fbg"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.Orders,
  authentication: state.Auth,
  beats: state.Beats,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Orders)));
