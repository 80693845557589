const ANT_CONFIG = {
  size: "large",
  date_format: "YYYY-MM-DD",
  datetime_format: "YYYY-MM-DD HH:mm",
  datepicker_format: "DD.MM.YYYY",
  datepicker_time_format: "DD.MM.YYYY HH:mm",
  isMobile: window.outerWidth < 768,
  screenWidth: window.outerWidth,
  pagination: {
    pageSizeOptions: ["20"],
    defaultPageSize: 20,
    hideOnSinglePage: true,
  },
};

export default ANT_CONFIG;
