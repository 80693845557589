import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, Row, Col, Input, Checkbox } from "antd";

import ANT_CONFIG from "../../../constants/antconfig";
import { store } from "../../../redux/rivers/actions";

const DEFAULT_STATE = {
  name: "",
  submitted: false,
  active_on_web: 1,
  active_on_laksebors: 1,
};

class ModalAddNewRiver extends React.Component {
  state = DEFAULT_STATE;

  componentDidUpdate() {
    if (!this.props.rivers.loading && !this.props.rivers.error && this.state.name && this.state.submitted) {
      this.onCancel();
    }
  }

  onCancel = () => {
    this.setState(DEFAULT_STATE);

    this.props.cancel();
  };

  onSave = () => {
    this.setState({
      submitted: true,
    });

    this.props.dispatch(store(this.state));
  };

  setName(name) {
    this.setState({
      name,
    });
  }

  setActiveOnWeb(value) {
    this.setState({
      active_on_web: value,
    });
  }

  setActiveOnLaksebors(value) {
    this.setState({
      active_on_laksebors: value,
    });
  }

  render() {
    const { visible, intl, rivers } = this.props;
    const { name, active_on_web, active_on_laksebors } = this.state;

    return (
      <Modal
        width={800}
        title={intl.formatMessage({ id: "add_new_river" })}
        visible={visible}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="cancel" disabled={rivers.loading} type="default" onClick={this.onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" loading={rivers.loading} type="primary" onClick={this.onSave}>
            {intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        <Row>
          <Col xs={24}>
            <label className="ant-label" htmlFor="name">
              {intl.formatMessage({ id: "name" })}
            </label>
            <Input size={ANT_CONFIG.size} type="text" name="name" id="name" value={name} onChange={(e) => this.setName(e.target.value)} />
          </Col>
          <Col xs={24} style={{ paddingTop: 20 }}>
            <Checkbox size={ANT_CONFIG.size} checked={active_on_web} onChange={(e) => this.setActiveOnWeb(e.target.checked ? 1 : 0)}>
              {intl.formatMessage({ id: "active_on_web" })}
            </Checkbox>
          </Col>
          <Col xs={24} style={{ paddingTop: 20 }}>
            <Checkbox size={ANT_CONFIG.size} checked={active_on_laksebors} onChange={(e) => this.setActiveOnLaksebors(e.target.checked ? 1 : 0)}>
              {intl.formatMessage({ id: "active_on_laksebors" })}
            </Checkbox>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  rivers: state.Rivers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddNewRiver)));
