import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const SaveLabel = props => {
  return (
    <div>
      <FontAwesomeIcon
        icon={faCheck}
        style={{
          fontSize: 14,
          marginRight: 10,
        }}
      />
      {props.intl.formatMessage({ id: 'save' })}
    </div>
  );
};

export default SaveLabel;
