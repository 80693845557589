export const GET_LATEST_CATCH = "GET_LATEST_CATCH";
export const GET_LATEST_CATCH_SUCCESS = "GET_LATEST_CATCH_SUCCESS";
export const GET_LATEST_CATCH_FAILURE = "GET_LATEST_CATCH_FAILURE";

export function getLatestCatch(id) {
  return {
    type: GET_LATEST_CATCH,
    id
  };
}
