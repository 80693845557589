import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Spinner = ({ width = 40, color = "green", style }) => (
  <div className={`eg-spinner eg-color--${color}`} style={{ ...style }}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: width }} spin />} />
  </div>
);

export default Spinner;
