import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, DatePicker, Card, Col, Row, PageHeader, Select, Table } from "antd";

import { fetchStationAdmin } from "../../redux/disinfection/actions";
import Spinner from "../../components/spinner";
import riverOptionsFromStations from "../../services/select_river_options_from_stations";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import ANT_CONFIG from "../../constants/antconfig";

const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class DisinfectionStationAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      slice: 50,
      data: {
        river_id: this.props.authentication.data.stations[0] ? this.props.authentication.data.stations[0].river_id : null,
        station_ids: this.props.authentication.data.station,
        date_start: moment().startOf("month").format(`${this.dateFormat}`),
        date_end: moment().endOf("month").format(`${this.dateFormat}`),
      },
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orders !== this.props.orders && this.props.orders.data) {
      this.resetSliceCounter();
      this.recursive();
    }
  }

  handleDataChange() {
    this.props.dispatch(fetchStationAdmin(this.state.data));
  }

  handleChangeDate(e, key) {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, [key]: moment(e).format(`${this.dateFormat}`) },
      }),
      () => {
        this.handleDataChange();
      }
    );
  }

  handleRiverChange(e) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          river_id: e,
        },
      }),
      () => {
        this.props.dispatch(selectRiver(e));
        this.handleDataChange();
      }
    );
  }

  resetSliceCounter() {
    this.setState({
      slice: 50,
    });
  }

  recursive() {
    setTimeout(() => {
      const hasMore = this.state.slice + 50 < this.props.disinfection.data.length;
      this.setState((prevState) => ({
        slice: prevState.slice + 50,
      }));
      if (hasMore) {
        this.recursive();
      }
    }, 1);
  }

  render() {
    if (!this.props.rivers.data) {
      return <Spinner />;
    }

    const {
      intl,
      rivers,
      authentication,
      disinfection: { loading, data },
    } = this.props;
    const selectRiver = riverOptionsFromStations(rivers, authentication);
    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
      },
      {
        title: intl.formatMessage({ id: "station" }),
        dataIndex: "station",
        key: "station",
      },
      {
        title: intl.formatMessage({ id: "customer" }),
        dataIndex: "customer",
        key: "customer",
      },
      {
        title: intl.formatMessage({ id: "customer_id" }),
        dataIndex: "customer_id",
        key: "customer_id",
      },
    ];
    const tableData = data?.map((item) => ({
      key: item.id,
      date: moment(item.created_at).format("DD.MM.YYYY"),
      station: item.station ? `${item.station.name} (ID: ${item.station_id})` : item.station_id,
      customer: item.username || "-",
      customer_id: item.user_id,
    }));

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "disinfection" })}
          extra={[
            <Button key="1" loading={loading} type="primary" onClick={() => this.props.history.push("/disinfection/add")} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "new_disinfection" })}
            </Button>,
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                {selectRiver.length > 1 && (
                  <Col xs={24} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label className="ant-label">{intl.formatMessage({ id: "select_river" })}</label>
                    <Select
                      showSearch
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({ id: "select_river" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleRiverChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.data.river_id}
                    >
                      {selectRiver.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label className="ant-label">{intl.formatMessage({ id: "date_start" })}</label>
                  <DatePicker
                    id="date_start"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_start)}
                    onChange={(e) => this.handleChangeDate(e, "date_start")}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label className="ant-label">{this.props.intl.formatMessage({ id: "date_end" })}</label>
                  <DatePicker
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_end)}
                    onChange={(e) => this.handleChangeDate(e, "date_end")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table loading={{ spinning: loading, indicator: SPIN_ICON }} columns={tableColumns} dataSource={tableData} pagination={ANT_CONFIG.pagination} />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  disinfection: state.Disinfection,
  rivers: state.Rivers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(DisinfectionStationAdmin)));
