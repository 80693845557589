import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { changePassword } from "../../../../redux/authentication/actions";
import Spinner from "../../../spinner";
import SaveLabel from "../../../helpers/buttons/save_label";

const labelWidth = 4;
const inputWidth = 8;

class ModalChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      data: {
        old_password: "",
        new_password: "",
        new_password_repeat: ""
      }
    };
  }

  componentDidUpdate() {
    if (!this.props.authentication.loading && !this.props.authentication.error && this.state.submitted) {
      this.toggle();
    }
  }

  onChangeValue(newState) {
    this.setState(prevState => ({ data: { ...prevState.data, ...newState } }));
  }

  onPasswordChange() {
    this.setState({
      submitted: true
    });
    this.props.dispatch(changePassword(this.state.data));
  }

  toggle() {
    this.props.toggleModal();
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "change_password" })}</ModalHeader>
        {this.props.authentication.loading ? (
          <Spinner />
        ) : (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Label for="old_password" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "old_password" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="password"
                        name="old_password"
                        id="old_password"
                        value={this.state.data.old_password}
                        onChange={e => this.onChangeValue({ old_password: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="new_password" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "new_password" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="password"
                        name="new_password"
                        id="new_password"
                        value={this.state.data.new_password}
                        onChange={e => this.onChangeValue({ new_password: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="new_password_repeat" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "new_password_repeat" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="password"
                        name="new_password_repeat"
                        id="new_password_repeat"
                        value={this.state.data.new_password_repeat}
                        onChange={e => this.onChangeValue({ new_password_repeat: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.onPasswordChange()} type="submit">
                <SaveLabel intl={this.props.intl} />
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalChangePassword)));
