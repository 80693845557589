import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Col, Row, PageHeader, Button, Input, Tabs, Checkbox, Select, DatePicker, Empty } from "antd";
import moment from "moment";

import { fetch, store, update, updateImage } from "../../redux/article/actions";
import { fetch as fetchCategories, store as storeCategory } from "../../redux/categories/actions";
import Spinner from "../../components/spinner";
import GenericError from "../Errors/GenericError";
import CollapsedCard from "../../components/Card/CollapsedCard";
import ANT_CONFIG from "../../constants/antconfig";
import { DEFAULT_LANGUAGE, LANGUAGES, STATUSES } from "../../constants";
import HTMLEditor from "../../components/HTMLEditor/HTMLEditor";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import CategoryModal from "../../components/modal/Article/Category";

class Article extends React.Component {
  state = {
    showModal: false,
    activeTab: "1",
    isNew: this.props.match.params.id === "new",
    data: null,
  };

  componentDidMount() {
    const { isNew, data } = this.state;
    const {
      dispatch,
      articleCategories,
      match: {
        params: { id },
      },
    } = this.props;

    if (!isNew) {
      dispatch(fetch(id));
    } else {
      this.setData({
        language: DEFAULT_LANGUAGE,
        status: "draft",
        header_type: "image",
        objects_translations: [],
      });
    }

    if (!articleCategories.data) {
      dispatch(fetchCategories(data?.language || DEFAULT_LANGUAGE));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.article.data !== this.props.article.data && this.props.article.data) {
      this.setFetchedData(this.props.article.data);
    }

    if (prevProps.article.articleId !== this.props.article.articleId && this.props.article.articleId && this.state.isNew) {
      this.props.dispatch(fetch(this.props.article.articleId));
    }
  }

  onSingleCategoryChange(item) {
    const selectedCategories = this.state.data?.article_categories?.filter((i) => i.id === item.id);

    if (selectedCategories.length > 0) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          article_categories: [...prevState.data?.article_categories?.filter((i) => i.id !== item.id)],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          article_categories: [...prevState.data?.article_categories, item],
        },
      }));
    }
  }

  onSave = () => {
    const { isNew, data } = this.state;
    const { dispatch } = this.props;
    const { id } = this.props.match.params;
    const publishDate = data.publish_date ?? moment().format("DD.MM.YYYY");
    const postData = {
      ...data,
      publish_date: moment(publishDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
    };

    dispatch(isNew ? store(postData) : update(postData, id));
  };

  onChangeValue(data) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        ...data,
      },
    }));
  }

  setLanguage(language) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          language,
        },
      }),
      () => {
        if (this.state.isNew) {
          this.props.dispatch(fetchCategories(this.state.data.language));
        }
      }
    );
  }

  onTabChange = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  setData(data) {
    this.setState({
      data,
    });
  }

  setFetchedData(data) {
    const { dispatch } = this.props;

    this.setState(
      {
        isNew: false,
        activeTab: "1",
        data,
      },
      () => {
        const { data } = this.state;

        if (!data?.objects_translations) {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              objects_translations: [],
            },
          }));
        }

        dispatch(fetchCategories(data?.language || DEFAULT_LANGUAGE));
      }
    );
  }

  setObjectTranslations(data, context, language) {
    const dataToChange = this.state.data?.objects_translations?.filter((i) => i.language === language && i.key === context)?.[0];

    if (!dataToChange) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              key: context,
              value: data,
              language,
            },
          ],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              ...dataToChange,
              value: data,
            },
          ],
        },
      }));
    }
  }

  addFeatureImage = (file) => {
    if (file?.response?.data?.default) {
      this.onChangeValue({ featured_image: file.response.data.default });

      if (!this.state.isNew) {
        this.props.dispatch(
          updateImage(
            {
              featured_image: file.response.data.default,
              fic: true,
            },
            this.props.match.params.id
          )
        );
      }
    }
  };

  addSeoFeatureImage = (file) => {
    if (file?.response?.data?.default) {
      this.setObjectTranslations(file.response.data.default, "seo_featured_image", this.state.data.language);

      if (!this.state.isNew) {
        this.props.dispatch(
          updateImage(
            {
              seo_featured_image: {
                key: "seo_featured_image",
                value: file.response.data.default,
                language: this.state.data.language,
              },
            },
            this.props.match.params.id
          )
        );
      }
    }
  };

  removeFeatureImage = () => {
    this.onChangeValue({ featured_image: null });

    if (!this.state.isNew) {
      this.props.dispatch(
        updateImage(
          {
            featured_image_delete: true,
          },
          this.props.match.params.id
        )
      );
    }
  };

  removeSeoFeatureImage = () => {
    this.setObjectTranslations("", "seo_featured_image", this.state.data.language);

    if (!this.state.isNew) {
      this.props.dispatch(
        updateImage(
          {
            seo_featured_image: {
              key: "seo_featured_image",
              value: "",
              language: this.state.data.language,
            },
          },
          this.props.match.params.id
        )
      );
    }
  };

  showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  addCategory = (name) => {
    this.props.dispatch(
      storeCategory(
        {
          language: this.state.data?.language || DEFAULT_LANGUAGE,
          name,
        },
        this.state.data?.language || DEFAULT_LANGUAGE
      )
    );

    setTimeout(() => {
      this.hideModal();
    }, 1000);
  };

  render() {
    const { intl, article, articleCategories } = this.props;
    const { data, activeTab, isNew, showModal } = this.state;
    const content = isNew ? data?.content ?? "" : data?.content ?? article?.data?.content ?? "";

    if (article.articleId) {
      return <Redirect to={`/content/article/${article.articleId}`} />;
    }

    if (!article.loading && article.error && !article.data && !isNew) {
      return <GenericError />;
    }

    if (!data && article.loading && !isNew) {
      return <Spinner />;
    }

    const tabs = (
      <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onChange={this.onTabChange}>
        <Tabs.TabPane tab={intl.formatMessage({ id: "general" })} key="1" />
        <Tabs.TabPane tab={intl.formatMessage({ id: "image_and_video" })} key="2" />
        <Tabs.TabPane tab={intl.formatMessage({ id: "status" })} key="3" />
        <Tabs.TabPane tab={intl.formatMessage({ id: "categories" })} key="4" />
        <Tabs.TabPane tab={intl.formatMessage({ id: "seo" })} key="5" />
      </Tabs>
    );

    const HEADER_TYPES = [
      {
        value: "image",
        label: intl.formatMessage({ id: "image" }),
      },
      {
        value: "vimeo",
        label: "Vimeo",
      },
      {
        value: "youtube",
        label: "YouTube",
      },
    ];

    return (
      <div>
        <PageHeader
          title={data?.name || intl.formatMessage({ id: "add_new_article" })}
          footer={tabs}
          extra={[
            <Button onClick={this.onSave} loading={article.loading} type="primary" key="save" size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
          ]}
        />

        <CategoryModal cancel={this.hideModal} show={showModal} save={this.addCategory} />

        <div style={activeTab !== "1" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "name" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    type="text"
                    id="name"
                    size={ANT_CONFIG.size}
                    value={data?.name}
                    style={{ width: "100%" }}
                    onChange={(e) => this.onChangeValue({ name: e.target.value })}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          {isNew && (
            <CollapsedCard title={intl.formatMessage({ id: "language" })}>
              <div className="container-900">
                <Row>
                  <Col xs={24}>
                    <Select
                      id="select_language"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      showSearch
                      value={data?.language || DEFAULT_LANGUAGE}
                      optionFilterProp="children"
                      onChange={(language) => this.setLanguage(language)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {LANGUAGES.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </div>
            </CollapsedCard>
          )}

          <CollapsedCard title={intl.formatMessage({ id: "content" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor data={content} onChange={(event, editor) => this.onChangeValue({ content: editor.getData() })} />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "excerpt" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input.TextArea
                    name="excerpt"
                    id="excerpt"
                    size={ANT_CONFIG.size}
                    autoSize={{ minRows: 3 }}
                    value={data?.excerpt}
                    onChange={(e) => this.onChangeValue({ excerpt: e.target.value })}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={activeTab !== "2" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "header_type" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Select
                    id="select_header_type"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    showSearch
                    value={data?.header_type || HEADER_TYPES[0].value}
                    optionFilterProp="children"
                    onChange={(e) => this.onChangeValue({ header_type: e })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {HEADER_TYPES.map(({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "featured_image" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  data={
                    data?.featured_image
                      ? [
                          {
                            id: 1,
                            url: data.featured_image,
                          },
                        ]
                      : []
                  }
                  limit={1}
                  onFileAdd={this.addFeatureImage}
                  onFileRemove={this.removeFeatureImage}
                />
              </Col>
            </Row>
          </CollapsedCard>

          {data?.header_type === "vimeo" && (
            <CollapsedCard title={intl.formatMessage({ id: "vimeo_link" })}>
              <div className="container-900">
                <Row>
                  <Col xs={24}>
                    <Input
                      type="text"
                      id="vimeo"
                      size={ANT_CONFIG.size}
                      value={data?.vimeo_link}
                      style={{ width: "100%" }}
                      onChange={(e) => this.onChangeValue({ vimeo_link: e.target.value })}
                    />
                  </Col>
                </Row>
              </div>
            </CollapsedCard>
          )}

          {data?.header_type === "youtube" && (
            <CollapsedCard title={intl.formatMessage({ id: "youtube_link" })}>
              <div className="container-900">
                <Row>
                  <Col xs={24}>
                    <Input
                      type="text"
                      id="youtube"
                      size={ANT_CONFIG.size}
                      value={data?.youtube_link}
                      style={{ width: "100%" }}
                      onChange={(e) => this.onChangeValue({ youtube_link: e.target.value })}
                    />
                  </Col>
                </Row>
              </div>
            </CollapsedCard>
          )}
        </div>

        <div style={activeTab !== "3" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "status" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Select
                    id="select_status"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    showSearch
                    value={data?.status || STATUSES[0]}
                    optionFilterProp="children"
                    onChange={(status) => this.onChangeValue({ status })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {STATUSES.map((i) => (
                      <Select.Option key={i} value={i}>
                        {intl.formatMessage({ id: i })}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col xs={24}>
                  <label htmlFor="publish_date" className="ant-label">
                    {intl.formatMessage({ id: "publish_date" })}
                  </label>
                  <DatePicker
                    style={{ width: "100%" }}
                    id="publish_date"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={data?.publish_date ? moment(data.publish_date, "DD.MM.YYYY") : moment()}
                    onChange={(e) => this.onChangeValue({ publish_date: e.format("DD.MM.YYYY") })}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={activeTab !== "4" ? { display: "none" } : {}}>
          <PageHeader
            className="ant-page-header-for-maps"
            extra={[
              <Button onClick={this.showModal} disabled={articleCategories.loading} key="add_category" size={ANT_CONFIG.size}>
                {this.props.intl.formatMessage({ id: "add_category" })}
              </Button>,
            ]}
          />
          <CollapsedCard title={intl.formatMessage({ id: "categories" })}>
            <div className="container-900">
              {articleCategories.loading ? (
                <Row>
                  <Col xs={24}>
                    <Spinner />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={24}>
                    <Row gutter={[16, 24]}>
                      {articleCategories.data?.length > 0 ? (
                        articleCategories.data?.map((i) => (
                          <Col xs={24} md={8} key={i.id} style={{ paddingTop: 10 }}>
                            <Checkbox
                              size={ANT_CONFIG.size}
                              value={i.id}
                              onChange={() => this.onSingleCategoryChange(i)}
                              checked={data?.article_categories?.filter((j) => j.id === i.id).length > 0}
                            >
                              {i.name}
                            </Checkbox>
                          </Col>
                        ))
                      ) : (
                        <Empty style={{ width: "100%" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          </CollapsedCard>
        </div>

        <div style={activeTab !== "5" ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "title" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    type="text"
                    id="title"
                    size={ANT_CONFIG.size}
                    value={data?.objects_translations?.filter((i) => i.key === "seo_title" && i.language === data?.language)?.[0]?.value || ""}
                    style={{ width: "100%" }}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_title", data?.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "description" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input.TextArea
                    name="description"
                    id="description"
                    autoSize={{ minRows: 3 }}
                    size={ANT_CONFIG.size}
                    value={data?.objects_translations?.filter((i) => i.key === "seo_description" && i.language === data?.language)?.[0]?.value || ""}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_description", data?.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "featured_image" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  data={
                    data?.objects_translations?.filter((i) => i.key === "seo_featured_image" && i.language === data?.language)?.[0]?.value
                      ? [
                          {
                            id: 1,
                            url: data?.objects_translations?.filter((i) => i.key === "seo_featured_image" && i.language === data?.language)[0].value,
                          },
                        ]
                      : []
                  }
                  limit={1}
                  onFileAdd={this.addSeoFeatureImage}
                  onFileRemove={this.removeSeoFeatureImage}
                />
              </Col>
            </Row>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "additional_information" })}>
            <Row>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={
                    data?.objects_translations?.filter((i) => i.key === "seo_noindex" && i.language === data?.language && parseInt(i.value, 10)).length > 0
                      ? parseInt(
                          data.objects_translations.filter((i) => i.key === "seo_noindex" && i.language === data.language && parseInt(i.value, 10))[0].value,
                          10
                        )
                      : 0
                  }
                  onChange={(e) => this.setObjectTranslations(e.target.checked ? 1 : 0, "seo_noindex", data?.language)}
                >
                  {intl.formatMessage({ id: "hide_from_crawlers" })}
                </Checkbox>
              </Col>
            </Row>
          </CollapsedCard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  article: state.Article,
  articleCategories: state.ArticleCategories,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Article)));
