export const FETCH_ZONES = "FETCH_ZONES";
export const FETCH_ZONES_SUCCESS = "FETCH_ZONES_SUCCESS";
export const FETCH_ZONES_FAILURE = "FETCH_ZONES_FAILURE";

export const STORE_ZONE = "STORE_ZONE";
export const STORE_ZONE_SUCCESS = "STORE_ZONE_SUCCESS";
export const STORE_ZONE_FAILURE = "STORE_ZONE_FAILURE";

export const UPDATE_ZONE = "UPDATE_ZONE";
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS";
export const UPDATE_ZONE_FAILURE = "UPDATE_ZONE_FAILURE";

export const DESTROY_ZONE = "DESTROY_ZONE";
export const DESTROY_ZONE_SUCCESS = "DESTROY_ZONE_SUCCESS";
export const DESTROY_ZONE_FAILURE = "DESTROY_ZONE_FAILURE";

export function fetch() {
  return {
    type: FETCH_ZONES
  };
}

export function store(payload) {
  return {
    type: STORE_ZONE,
    payload
  };
}

export function update(payload, id) {
  return {
    type: UPDATE_ZONE,
    payload,
    id
  };
}

export function destroy(id) {
  return {
    type: DESTROY_ZONE,
    id
  };
}

