import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, "admin/customers", "GET", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_CUSTOMERS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_CUSTOMERS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_CUSTOMERS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* show(action) {
  try {
    const response = yield call(API.call, `admin/customers/${action.id}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_CUSTOMER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_CUSTOMER_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_CUSTOMER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* showStationAdmin(action) {
  try {
    const response = yield call(API.call, `admin/station-admins/${action.id}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_STATION_ADMIN_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_STATION_ADMIN_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_STATION_ADMIN_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* add(action) {
  try {
    const response = yield call(API.call, `admin/customers`, "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_CUSTOMER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.ADD_CUSTOMER_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_CUSTOMER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, `admin/customers/${action.id}`, "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_CUSTOMER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.EDIT_CUSTOMER_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_CUSTOMER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* quiteAdd(action) {
  try {
    const response = yield call(API.call, `admin/quietly-create`, "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.QUITE_ADD_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.QUITE_ADD_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.QUITE_ADD_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* unblock(action) {
  try {
    const response = yield call(API.call, "admin/customer-unblock", "PUT", action.payload, '/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UNBLOCK_CUSTOMER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.UNBLOCK_CUSTOMER_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UNBLOCK_CUSTOMER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_CUSTOMERS, fetch),
    takeEvery(ACTION.FETCH_CUSTOMER, show),
    takeEvery(ACTION.FETCH_STATION_ADMIN, showStationAdmin),
    takeEvery(ACTION.ADD_CUSTOMER, add),
    takeEvery(ACTION.EDIT_CUSTOMER, edit),
    takeEvery(ACTION.QUITE_ADD, quiteAdd),
    takeEvery(ACTION.UNBLOCK_CUSTOMER, unblock),
  ]);
}
