import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch() {
  try {
    const response = yield call(API.call, "admin/stations", "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_STATIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_STATIONS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_STATIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* show(action) {
  try {
    const response = yield call(API.call, `admin/station/${action.id}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_STATION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_STATION_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_STATION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* add(action) {
  try {
    const response = yield call(API.call, `admin/station`, "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_STATION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch);
      yield put({
        type: ACTION.ADD_STATION_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_STATION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, `admin/station/${action.id}`, "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_STATION_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.EDIT_STATION_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_STATION_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, `admin/station/${action.id}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_STATION_FAILURE,
        message: responseBody.errors,
        meta: action.meta,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch);
      yield put({
        type: ACTION.REMOVE_STATION_SUCCESS,
        payload: responseBody,
        meta: action.meta,
      });
      APIMessage.success(responseBody.data.message);

      return {
        payload: responseBody,
      };
    }
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_STATION_FAILURE,
      message: e.errors,
      meta: action.meta,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_STATIONS, fetch),
    takeEvery(ACTION.FETCH_STATION, show),
    takeEvery(ACTION.ADD_STATION, add),
    takeEvery(ACTION.EDIT_STATION, edit),
    takeEvery(ACTION.REMOVE_STATION, remove),
  ]);
}
