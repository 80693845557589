import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";
import {
  FETCH_RIVER_CARDS_SUCCESS,
  FETCH_RIVER_CARDS_FAILURE,
  FETCH_BUY_RIVER_CARD_BUTTON_TEXT_SUCCESS,
  FETCH_BUY_RIVER_CARD_BUTTON_TEXT_FAILURE,
  EDIT_BUY_RIVER_CARD_BUTTON_SUCCESS,
  EDIT_BUY_RIVER_CARD_BUTTON_FAILURE,
} from "./actions";

function* fetchRiverCards(action) {
  try {
    const response = yield call(
      API.call,
      `admin/sold-river-cards/${action.id}`,
      "GET"
    );
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: FETCH_RIVER_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: FETCH_RIVER_CARDS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_RIVER_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchBuyRiverCardButtonText(action) {
  try {
    const response = yield call(
      API.call,
      `buy-river-card-button-text/${action.id}`,
      "GET",
    );
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: FETCH_BUY_RIVER_CARD_BUTTON_TEXT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: FETCH_BUY_RIVER_CARD_BUTTON_TEXT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_BUY_RIVER_CARD_BUTTON_TEXT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* editBuyRiverCardButton(action) {
  try {
    const response = yield call(
      API.call,
      `admin/buy-river-card-button-text/`,
      "POST",
      action.payload
    );
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: EDIT_BUY_RIVER_CARD_BUTTON_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: EDIT_BUY_RIVER_CARD_BUTTON_SUCCESS,
        payload: responseBody?.data?.message,
      });
      APIMessage.success(responseBody?.data?.message);
    }
  } catch (e) {
    yield put({
      type: EDIT_BUY_RIVER_CARD_BUTTON_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_RIVER_CARDS, fetchRiverCards),
    takeEvery(ACTION.FETCH_BUY_RIVER_CARD_BUTTON_TEXT, fetchBuyRiverCardButtonText),
    takeEvery(ACTION.EDIT_BUY_RIVER_CARD_BUTTON, editBuyRiverCardButton),
  ]);
}
