import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  currencies: undefined,
  timezones: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_CURRENCIES:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

      case ACTION.FETCH_TIMEZONES:
        return {
          ...state,
          loading: true,
        };
  
      case ACTION.FETCH_TIMEZONES_SUCCESS:
        return {
          ...state,
          timezones: action.payload.data,
          loading: false,
          error: false,
        };
  
      case ACTION.FETCH_TIMEZONES_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
        };

    case ACTION.UPDATE_RIVER_SETTINGS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.UPDATE_RIVER_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UPDATE_RIVER_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };


    case ACTION.UPDATE_BEAT_SETTINGS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.UPDATE_BEAT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UPDATE_BEAT_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };



    default:
      return state;
  }
}
