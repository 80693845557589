export const FETCH_RIVERS = "FETCH_RIVERS";
export const FETCH_RIVERS_SUCCESS = "FETCH_RIVERS_SUCCESS";
export const FETCH_RIVERS_FAILURE = "FETCH_RIVERS_FAILURE";

export const STORE_RIVER = "STORE_RIVER";
export const STORE_RIVER_SUCCESS = "STORE_RIVER_SUCCESS";
export const STORE_RIVER_FAILURE = "STORE_RIVER_FAILURE";

export const SET_CATCH_REPORT_SETTINGS = "SET_CATCH_REPORT_SETTINGS";
export const SET_CATCH_REPORT_SETTINGS_SUCCESS = "SET_CATCH_REPORT_SETTINGS_SUCCESS";
export const SET_CATCH_REPORT_SETTINGS_FAILURE = "SET_CATCH_REPORT_SETTINGS_FAILURE";

export const SELECT_RIVER = "SELECT_RIVER";

export function fetch() {
  return {
    type: FETCH_RIVERS
  };
}

export function store(payload) {
  return {
    type: STORE_RIVER,
    payload
  };
}

export function setCatchReportSettings(payload) {
  return {
    type: SET_CATCH_REPORT_SETTINGS,
    payload
  };
}

export function select(payload) {
  return {
    type: SELECT_RIVER,
    payload
  };
}
