export const FETCH_DASHBOARD_ADMIN_COUNTIES = "FETCH_DASHBOARD_ADMIN_COUNTIES";
export const FETCH_DASHBOARD_ADMIN_COUNTIES_SUCCESS = "FETCH_DASHBOARD_ADMIN_COUNTIES_SUCCESS";
export const FETCH_DASHBOARD_ADMIN_COUNTIES_FAILURE = "FETCH_DASHBOARD_ADMIN_COUNTIES_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_ADMIN_COUNTIES,
    payload
  };
}
