import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Modal, ModalHeader, ModalBody, Form, Col, FormGroup, Label } from "reactstrap";
import SelectInput from "../../select";
import { fetch as fetchCustomers } from "../../../redux/customers/actions";

const labelWidth = 4;
const inputWidth = 8;

class ModalChooseBookingType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      order_type: null,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchCustomers());
  }

  toggle(e) {
    this.setState(
      {
        order_type: e,
      },
      () => {
        this.props.toggleModal(this.state.order_type);
      }
    );
  }

  render() {
    const selectOrderType = [
      {
        value: "fishing_cards",
        label: this.props.intl.formatMessage({ id: "fishing_card" }),
      },
      {
        value: "accommodation",
        label: this.props.intl.formatMessage({ id: "accommodation" }),
      },
      {
        value: "fishing_packages",
        label: this.props.intl.formatMessage({ id: "fishing_package" }),
      },
      {
        value: "free_packages",
        label: this.props.intl.formatMessage({ id: "pack" }),
      },
      {
        value: "season_cards",
        label: this.props.intl.formatMessage({ id: "season_cards" }),
      },
    ];

    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
        <Form>
          <ModalBody>
            <FormGroup row>
              <Label for="select_order_type" xs={12} sm={labelWidth}>
                {`${this.props.intl.formatMessage({ id: "select_order_type" })}`}
              </Label>
              <Col xs={12} sm={inputWidth}>
                <SelectInput
                  id="select_order_type"
                  name="select_order_type"
                  intl={this.props.intl}
                  value={selectOrderType.filter((i) => i.value === this.state.order_type)}
                  onChange={(e) => this.toggle(e.value)}
                  options={selectOrderType}
                />
              </Col>
            </FormGroup>
          </ModalBody>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalChooseBookingType)));
