import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Table, DatePicker, Card, Col, Row, PageHeader, Select, Typography, Checkbox } from "antd";
import Spinner from "../../components/spinner";
import {
  fetchAllBeatEconomicReports,
  downloadAllBeatEconomicReports,
  downloadAllBeatEconomicReportsSuccess,
  fetchExportBilling,
} from "../../redux/reports/actions";
import { fetch as fetchBeats } from "../../redux/beats/actions";
import ANT_CONFIG from "../../constants/antconfig";
import { currencyOptions } from "../../services/select_settings_options";
import { fetch as fetchCurrencies } from "../../redux/settings/actions";
import EconomyPDF from "../../components/BeatEconomyReportPDFDownload";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import JSZip from "jszip";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

class AllBeatsEconomyReport extends Component {
  state = {
    allowExport: false,
    fileLoading: false,
    data: {
      date_start: moment().startOf("month").format(`YYYY-MM-DD`),
      date_end: moment().subtract(1, "day").format(`YYYY-MM-DD`),
      currency_id: 1,
    },
  };

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (!this.props.settings.currencies) {
      this.props.dispatch(fetchCurrencies());
    }

    this.handleDataChange();
  }

  onCheckboxClick = (e) => {
    this.setState({
      allowExport: e.target.checked,
    });
  };
  handleDataChange() {
    const { dispatch } = this.props;
    const { data } = this.state;

    dispatch(fetchAllBeatEconomicReports(data));
  }

  handleChangeDate(e, key) {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, [key]: moment(e).format(`YYYY-MM-DD`) },
      }),
      () => {
        this.handleDataChange();
      },
    );
  }

  handleCurrencyChange(e) {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, currency_id: e },
      }),
      () => {
        this.handleDataChange();
      },
    );
  }

  handleExportBilling() {
    if (this.state.allowExport) {
      let payload = {
        filters: {
          date_start: moment(this.state.data.date_start).format("YYYY-MM-DD"),
          date_end: moment(this.state.data.date_end).format("YYYY-MM-DD"),
        },
        reports: this.props.reports.data,
      };
      this.props.dispatch(fetchExportBilling(payload));
    }
  }

  render() {
    const { Text } = Typography;
    const {
      reports: { data, loading, file_loading },
      intl,
    } = this.props;

    if (!data || loading) {
      return <Spinner />;
    }

    function isObject(obj) {
      return obj === Object(obj);
    }

    let EconomyBeatReportData = data && Array.isArray(Object.values(data)) ? Object.values(data) : [];
    if (!EconomyBeatReportData.every(
      (e) => Array.isArray(e.organization) && isObject(e.online_payments) && isObject(e.manual_payments),
    )){
      EconomyBeatReportData=[]
    }


    if (!this.props.settings.currencies || this.props.settings.currencies.loading) {
      return <Spinner />;
    }
    const selectCurrencies = currencyOptions(this.props.settings);

    const TitleWrapper = ({ title }) => <div style={{ fontWeight: "bold" }}>{title}</div>;

    const orgColumns = [
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "beat" })} />,
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "river" })} />,
        dataIndex: "river",
        key: "river",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_name" })} />,
        dataIndex: "organization_name",
        key: "organization_name",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_number" })} />,
        dataIndex: "organization_number",
        key: "organization_number",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_account_number" })} />,
        dataIndex: "organization_account_number",
        key: "organization_account_number",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_address" })} />,
        dataIndex: "organization_address",
        key: "organization_address",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_phone" })} />,
        dataIndex: "organization_phone",
        key: "organization_phone",
      },
    ];

    const online_manual_Columns = [
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "tax_percentage" })} />,
        dataIndex: "tax_percentage",
        render: (data, _) => <div style={{ textAlign: "right" }}>{data}</div>,
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "revenue_gross" })} />,
        dataIndex: "revenue",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "refunds" })} />,
        dataIndex: "refunds",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "net_revenue" })} />,
        dataIndex: "net_revenue",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "revenue_tax" })} />,
        dataIndex: "revenue_tax",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "provision_percentage" })} />,
        dataIndex: "provision_percentage",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "provision" })} />,
        dataIndex: "provision",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "provision_tax" })} />,
        dataIndex: "provision_tax",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "payment_to_customer" })} />,
        dataIndex: "payment_to_customer",
        align: "right",
      },
    ];

    const SummaryRenderer = ({ data, record }) => {
      let payment_to_customer_message = intl.formatMessage({ id: "payment_to_customer_online" });
      let transaction_fee_manual = intl.formatMessage({ id: "economic_transaction_fee_manual" });
      let invoice_to_customer = intl.formatMessage({ id: "invoice_to_customer" });
      let total_payout = intl.formatMessage({ id: "economic_total_payout" });
      let currency = intl.formatMessage({ id: "currency" });
      return data === "payment_to_customer" ? (
        <>{payment_to_customer_message}</>
      ) : data === "transaction_fee_manual" ? (
        <>{transaction_fee_manual}</>
      ) : data === "invoice_to_customer" ? (
        <>{invoice_to_customer}</>
      ) : data === "total_payout" ? (
        <>{total_payout}</>
      ) : data === "currency" ? (
        <>{currency}</>
      ) : (
        ""
      );
    };
    const summaryColumn = [
      {
        dataIndex: "title",
        render: (data, record) => <SummaryRenderer data={data} record={record} />,
      },
      {
        dataIndex: "amount",
        align: "right",
      },
    ];

    const generateZipFile = async () => {
      const zip = new JSZip();
      let count = 0;

      for (const data of EconomyBeatReportData) {
        count++;
        const summaryData = [];

        if (data.summary) {
          Object.keys(data.summary).forEach((key, index) => {
            let compData = {};
            compData["title"] = key;
            compData["amount"] = data.summary[key];
            summaryData.push(compData);
          });
        }

        zip.file(
          this.getFileName(data.organization[0]),
          await pdf(
            <EconomyPDF
              key={count}
              onlinePaymentData={data.online_payments}
              manualPaymentData={data.manual_payments}
              organizationData={data.organization}
              summaryColumn={summaryColumn}
              summaryData={summaryData}
              intl={intl}
              startDate={this.state.data.date_start}
              endDate={this.state.data.date_end}
            />,
          ).toBlob(),
        );
      }

      let finalBlob = await zip.generateAsync({ type: "blob" });
      return finalBlob;
    };

    const handleFileDownload = async () => {
      this.handleExportBilling();
      this.props.dispatch(downloadAllBeatEconomicReports());

      setTimeout(() => {
        generateZipFile()
          .then((blob) => {
            saveAs(
              blob,
              `reports-${moment(this.state.data.date_start).format("YYYY-MM-DD")}-${moment(this.state.data.date_end).format(
                "YYYY-MM-DD",
              )}.zip`,
            );
            this.props.dispatch(downloadAllBeatEconomicReportsSuccess());
          })
          .catch(() => {});
      }, 1000);
    };

    const summarySectionTableColumn = [
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "beat" })} />,
        dataIndex: "beat",
        key: "beat",
        render: (data, _) => (
          <div
            style={{ cursor: "pointer", color: "blueviolet" }}
            onClick={() => {
              let element = document.getElementById(data.replace(" ", "_"));
              element.scrollIntoView({ behavior: "smooth" });
            }}
          >
            {data}
          </div>
        ),
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "river" })} />,
        dataIndex: "river",
        key: "river",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "payment_to_customer" })} />,
        dataIndex: "payment_to_customer",
        key: "payment_to_customer",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "economic_transaction_fee_manual" })} />,
        dataIndex: "transaction_fee_manual",
        key: "transaction_fee_manual",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "invoice_to_customer" })} />,
        dataIndex: "invoice_to_customer",
        key: "invoice_to_customer",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "economic_total_payout" })} />,
        dataIndex: "total_payout",
        key: "total_payout",
      },
    ];

    const summarySectionTableData = EconomyBeatReportData.map((data, i) => {
      return {
        key: i,
        beat: data.organization[0].beat || "",
        river: data.organization[0].river || "",
        payment_to_customer: data?.summary?.payment_to_customer || "",
        transaction_fee_manual: data?.summary?.transaction_fee_manual || "",
        invoice_to_customer: data?.summary?.invoice_to_customer || "",
        total_payout: data?.summary?.total_payout || "",
      };
    });

    let manual_Columns = [...online_manual_Columns];
    manual_Columns.pop();
    manual_Columns.push({
      title: <TitleWrapper title={intl.formatMessage({ id: "payment_to_customer_manual" })} />,
      dataIndex: "payment_to_customer",
      align: "right",
    });

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "all_beats_economy_report" })}
          extra={[
            <Checkbox key="allow-export-checkbox" size={ANT_CONFIG.size} onChange={this.onCheckboxClick}>
              {intl.formatMessage({ id: "add_attachments_to_billings" })}
            </Checkbox>,

            <FileDownloadDropdown
              key="all-economic-file-handler"
              handleClick={handleFileDownload}
              loading={file_loading}
              options={[{ label: "PDF", value: "pdf" }]}
            />,
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                {this.props.settings.currencies.length ? (
                  <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                    <Typography className="ant-label">{this.props.intl.formatMessage({ id: "currency" })}</Typography>
                    <Select
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      onChange={(e) => this.handleCurrencyChange(e)}
                      value={this.state.data.currency_id}
                      placeholder={this.props.intl.formatMessage({ id: "currency" })}
                    >
                      {selectCurrencies?.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                ) : null}
                <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <Typography className="ant-label">{intl.formatMessage({ id: "date_start" })}</Typography>
                  <DatePicker
                    id="date_start"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_start)}
                    onChange={(e) => this.handleChangeDate(e, "date_start")}
                    showToday={false}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <Typography className="ant-label">{intl.formatMessage({ id: "date_end" })}</Typography>
                  <DatePicker
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_end)}
                    onChange={(e) => this.handleChangeDate(e, "date_end")}
                    disabledDate={(d) => !d || d.isAfter(moment().subtract(1, "day"))}
                    showToday={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>
        <>
          {EconomyBeatReportData.length > 0 ? (
            <Card>
              <Table
                dataSource={summarySectionTableData}
                columns={summarySectionTableColumn}
                title={() => (
                  <div style={{ fontSize: 28, color: "black", textAlign: "left", fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "summary" })}
                  </div>
                )}
                pagination={ANT_CONFIG.pagination}
              />
            </Card>
          ) : (
            <></>
          )}
          {EconomyBeatReportData.map((e, i) => {
            const organizationData = e.organization && Array.isArray(e.organization) ? e.organization : [];
            const onlinePaymentData = e.online_payments || [];
            const manualPaymentData = e.manual_payments || [];
            const summaryData = [];
            if (e.summary) {
              Object.keys(e.summary).forEach((key, index) => {
                let compData = {};
                compData["title"] = key;
                compData["amount"] = e.summary[key];
                summaryData.push(compData);
                if (index === 2) {
                  summaryData.push({ title: "", amount: "" });
                }
              });
            }
            return (
              <div key={i} id={`${organizationData[0]?.beat.replace(" ", "_")}`}>
                <Card key={i} style={{ marginTop: 20, marginBottom: 20 }}>
                  <h1 style={{ fontSize: 28, fontWeight: "bold" }}>{organizationData[0]?.beat}</h1>
                  <Row>
                    <Col xs={24}>
                      <Table
                        className="ant-no-prewrap"
                        size="small"
                        columns={orgColumns}
                        dataSource={organizationData}
                        pagination={ANT_CONFIG.pagination}
                      />
                    </Col>
                  </Row>
                  <br />
                  <br />

                  <Row>
                    <Col xs={24}>
                      <Table
                        className="ant-table-thead"
                        columns={online_manual_Columns}
                        dataSource={onlinePaymentData.rows || []}
                        size="small"
                        title={() => (
                          <div style={{ backgroundColor: "#598c51", textAlign: "center", padding: 2, fontWeight: "bold" }}>
                            {intl.formatMessage({ id: "revenue_online_payment" })}
                          </div>
                        )}
                        pagination={ANT_CONFIG.pagination}
                        bordered={false}
                        summary={() => {
                          let summary = onlinePaymentData.summary || [];
                          return summary.length === 0 ? (
                            <></>
                          ) : (
                            <Table.Summary.Row style={{ textAlign: "right", border: "5px solid #fe213ee" }}>
                              <Table.Summary.Cell index={0}>
                                <div style={{ textAlign: "left", fontWeight: "bold" }}>SUM</div>
                              </Table.Summary.Cell>

                              {[
                                summary["revenue"],
                                summary["refunds"],
                                summary["net_revenue"],
                                summary["revenue_tax"],
                                "",
                                summary["provision"],
                                summary["provision_tax"],
                                summary["payment_to_customer"],
                              ].map((ele, index) => (
                                <Table.Summary.Cell index={index + 1} key={index}>
                                  <Text strong>{ele}</Text>
                                </Table.Summary.Cell>
                              ))}
                            </Table.Summary.Row>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col xs={24}>
                      <Table
                        className="ant-table-thead"
                        columns={manual_Columns || []}
                        dataSource={manualPaymentData.rows}
                        title={() => (
                          <div style={{ backgroundColor: "#9dbf95", color: "black", textAlign: "center", fontWeight: "bold" }}>
                            {intl.formatMessage({ id: "revenue_manual_payment" })}
                          </div>
                        )}
                        size="small"
                        bordered={false}
                        pagination={ANT_CONFIG.pagination}
                        summary={() => {
                          let summary = manualPaymentData.summary || [];
                          return summary.length === 0 ? (
                            <></>
                          ) : (
                            <Table.Summary.Row style={{ textAlign: "right", border: "5px solid #fe213ee" }}>
                              <Table.Summary.Cell index={0}>
                                <div style={{ textAlign: "left", fontWeight: "bold" }}>SUM</div>
                              </Table.Summary.Cell>

                              {[
                                summary["revenue"],
                                summary["refunds"],
                                summary["net_revenue"],
                                summary["revenue_tax"],
                                "-",
                                summary["provision"],
                                summary["provision_tax"],
                                summary["payment_to_customer"],
                              ].map((ele, index) => (
                                <Table.Summary.Cell index={index + 1} key={index}>
                                  <Text strong>{ele}</Text>
                                </Table.Summary.Cell>
                              ))}
                            </Table.Summary.Row>
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col xs={10}>
                      <Table
                        className="ant-no-prewrap"
                        size="small"
                        title={() => (
                          <div style={{ backgroundColor: "#456e99", color: "white", padding: 2, fontWeight: "bold" }}>
                            {intl.formatMessage({ id: "summary" })}
                          </div>
                        )}
                        showHeader={false}
                        columns={summaryColumn}
                        dataSource={summaryData}
                        pagination={ANT_CONFIG.pagination}
                      />
                    </Col>
                  </Row>
                </Card>
              </div>
            );
          })}
        </>
      </div>
    );
  }

  getFileName(organizationData) {
    return `${organizationData.beat.replace("/", "_")}_${organizationData.river.replace("/", "_")}.pdf`;
  }
}

const mapStateToProps = (state) => ({
  reports: state.Reports,
  authentication: state.Auth,
  beats: state.Beats,
  settings: state.Settings,
});

export default withRouter(injectIntl(connect(mapStateToProps)(AllBeatsEconomyReport)));
