export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_CHECK_SUCCESS = "AUTH_CHECK_SUCCESS";
export const AUTH_CHECK_FAILURE = "AUTH_CHECK_FAILURE";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAILURE = "AUTH_LOGOUT_FAILURE";

export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
export const AUTH_REFRESH_TOKEN_SUCCESS = "AUTH_REFRESH_TOKEN_SUCCESS";
export const AUTH_REFRESH_TOKEN_FAILURE = "AUTH_REFRESH_TOKEN_FAILURE";

export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS ";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

export const AUTH_FORGOT_PASSWORD = "AUTH_FORGOT_PASSWORD";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAILURE = "AUTH_FORGOT_PASSWORD_FAILURE";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export function authCheck() {
  return {
    type: AUTH_CHECK
  };
}

export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT
  };
}

export function authRefreshToken() {
  return {
    type: AUTH_REFRESH_TOKEN
  };
}

export function authResetPassword(payload) {
  return {
    type: AUTH_RESET_PASSWORD,
    payload
  };
}

export function authForgotPassword(payload) {
  return {
    type: AUTH_FORGOT_PASSWORD,
    payload
  };
}

export function editUser(payload) {
  return {
    type: EDIT_USER,
    payload
  };
}

export function changePassword(payload) {
  return {
    type: CHANGE_PASSWORD,
    payload
  };
}
