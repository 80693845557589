export const FETCH_BEAT_COMMISSIONS = "FETCH_BEAT_COMMISSIONS";
export const FETCH_BEAT_COMMISSIONS_SUCCESS = "FETCH_BEAT_COMMISSIONS_SUCCESS";
export const FETCH_BEAT_COMMISSIONS_FAILURE = "FETCH_BEAT_COMMISSIONS_FAILURE";

export const FETCH_RIVER_COMMISSIONS = "FETCH_RIVER_COMMISSIONS";
export const FETCH_RIVER_COMMISSIONS_SUCCESS = "FETCH_RIVER_COMMISSIONS_SUCCESS";
export const FETCH_RIVER_COMMISSIONS_FAILURE = "FETCH_RIVER_COMMISSIONS_FAILURE";

export const ADD_BEAT_COMMISSIONS = "ADD_BEAT_COMMISSIONS";
export const ADD_BEAT_COMMISSIONS_SUCCESS = "ADD_BEAT_COMMISSIONS_SUCCESS";
export const ADD_BEAT_COMMISSIONS_FAILURE = "ADD_BEAT_COMMISSIONS_FAILURE";

export const ADD_RIVER_COMMISSIONS = "ADD_RIVER_COMMISSIONS";
export const ADD_RIVER_COMMISSIONS_SUCCESS = "ADD_RIVER_COMMISSIONS_SUCCESS";
export const ADD_RIVER_COMMISSIONS_FAILURE = "ADD_RIVER_COMMISSIONS_FAILURE";

export function fetchBeat(payload) {
  return {
    type: FETCH_BEAT_COMMISSIONS,
    payload
  };
}

export function fetchRiver(payload) {
  return {
    type: FETCH_RIVER_COMMISSIONS,
    payload
  };
}

export function addBeat(data, payload) {
  return {
    type: ADD_BEAT_COMMISSIONS,
    data,
    payload
  };
}

export function addRiver(data, payload) {
  return {
    type: ADD_RIVER_COMMISSIONS,
    data,
    payload
  };
}
