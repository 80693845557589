import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input, Table } from "reactstrap";

import { add as addFreePackage, edit as editFreePackage, remove as removeFreePackages } from "../../../redux/free_packages/actions";
import Spinner from "../../spinner";
import SelectInput from "../../select";
import SaveLabel from "../../helpers/buttons/save_label";
import APIMessage from "../../../services/api_messages";
import { DEFAULT_LANGUAGE } from "../../../constants";
import translations from "../../../constants/strings";
import { fetch as fetchExtraServices } from "../../../redux/extra_services/actions";
import { Checkbox } from "antd";
import ANT_CONFIG from "../../../constants/antconfig";
import { UTCDateToLocalTime, UTCConverter } from '../../../services/utc_converter';

const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;

const labelWidth = 4;
const inputWidth = 8;

class ModalAddFreePackage extends React.Component {
  constructor(props) {
    super(props);

    const isNew = props.modalData.items.length === 0;
    let publishDateStart = "";
    let publishDateEnd = "";
    if (!isNew) {
      const startDateToLocalDate = UTCDateToLocalTime(props.modalData.items[0].publish_date_start)
      const formatedStartDate = startDateToLocalDate.format('YYYY-MM-DD HH:mm:ss')

      const endDateToLocalDate = UTCDateToLocalTime(props.modalData.items[0].publish_date_end)
      const formatedEndDate = endDateToLocalDate.format('YYYY-MM-DD HH:mm:ss')

      publishDateStart =
        props.modalData.items[0].publish_date_start !== "1970-01-01 00:00:00" ? formatedStartDate.replace(" ", "T") || "" : "";
      publishDateEnd =
        props.modalData.items[0].publish_date_end !== "3999-12-31 00:00:00" ? formatedEndDate.replace(" ", "T") || "" : "";
    }

    this.state = {
      modal: true,
      submitted: false,
      isNew,
      confirmDelete: false,
      edit_price: {},
      prices: {
        description: "",
      },
      price_fishing_cards: {
        description: "Fishing cards",
        amount: "",
        tax_option_id: null,
      },
      price_accommodation: {
        description: "Accommodation",
        amount: "",
        tax_option_id: null,
      },
      data: {
        allow_booking_after_start_date: !isNew ? props.modalData.items[0].allow_booking_after_start_date : false,
        beat_id: !isNew ? props.modalData.items[0].beat_id : props.selectedBeat,
        name: !isNew ? props.modalData.items[0].name : "",
        description: !isNew ? props.modalData.items[0].description : "",
        quantity: "",
        date_start: props.modalData.startDate,
        date_end: props.modalData.endDate,
        publish_date_start: publishDateStart,
        publish_date_end: publishDateEnd,
        updated_date_start: null,
        updated_date_end: null,
        price_options: !isNew ? [...props.modalData.items[0].price_options] : [],
        extra_service_ids: !isNew ? [...props.modalData.items[0].extra_services?.map((i) => i.id)] : [],
      },
    };
  }

  componentDidMount() {
    const { extra_services } = this.props;
    const { beat_id } = this.state.data;

    if (!extra_services.data) {
      this.props.dispatch(
        fetchExtraServices({
          beat_id,
        })
      );
    }
  }

  componentDidUpdate() {
    if (!this.props.free_packages.loading && !this.props.free_packages.error && this.state.submitted) {
      this.toggle();
    }
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onChangePrices(newState) {
    this.setState((prevState) => ({ prices: { ...prevState.prices, ...newState } }));
  }

  onChangeCardsPrices(newState) {
    this.setState((prevState) => ({ price_fishing_cards: { ...prevState.price_fishing_cards, ...newState } }));
  }

  onChangeAccommodationPrices(newState) {
    this.setState((prevState) => ({ price_accommodation: { ...prevState.price_accommodation, ...newState } }));
  }

  onDelete() {
    this.setState({
      submitted: true,
    });

    const ids = this.props.modalData.items.map((i) => i.id);
    this.props.dispatch(removeFreePackages({ package_ids: ids }, this.props.calendarData));
  }

  onSubmit() {
    this.setState({
      submitted: true,
    });

    if (this.state.isNew) {
      const data = {...this.state.data, 
        updated_date_start: UTCConverter(this.state.data.updated_date_start), 
        publish_date_start: UTCConverter(this.state.data.publish_date_start), 
        updated_date_end: UTCConverter(this.state.data.updated_date_end),
        publish_date_end: UTCConverter(this.state.data.publish_date_end),
    }
      this.props.dispatch(addFreePackage(data, this.props.calendarData));
    } else {
      const { items } = this.props.modalData;
      const data = items.map((item) => {
        return {
          id: item.id,
          allow_booking_after_start_date: this.state.data.allow_booking_after_start_date,
          name: this.state.data.name,
          description: this.state.data.description,
          date_start: this.state.data.date_start,
          date_end: this.state.data.date_end,
          price_options: this.state.data.price_options,
          publish_date_start: this.state.data.updated_date_start !== null ? UTCConverter(this.state.data.updated_date_start) : item.publish_date_start,
          publish_date_end: this.state.data.updated_date_end !== null ? UTCConverter(this.state.data.updated_date_end) : item.publish_date_end,
          extra_service_ids: this.state.data.extra_service_ids,
        };
      });
      this.props.dispatch(editFreePackage(data, this.props.calendarData));
    }
  }

  onChangeEditPrice(newState) {
    this.setState((prevState) => ({ edit_price: { ...prevState.edit_price, ...newState } }));
  }

  handleRemovePrice(item) {
    const removed = this.state.data.price_options.filter((i) => i.description !== item.description);

    this.setState((prevState) => ({ data: { ...prevState.data, price_options: [...removed] } }));
  }

  handleAddPrice() {
    if (
      !this.state.prices.description ||
      !this.state.price_accommodation.amount ||
      !this.state.price_accommodation.tax_option_id ||
      !this.state.price_fishing_cards.amount ||
      !this.state.price_fishing_cards.tax_option_id
    ) {
      return APIMessage.error(translations.error[lang].fill_all_fields);
    }

    const checkIfExists = this.state.data.price_options.filter((i) => i.description === this.state.prices.description);

    if (checkIfExists.length > 0) {
      return APIMessage.error(translations.error[lang].price_already_exists);
    }

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          price_options: [
            ...prevState.data.price_options,
            {
              description: prevState.prices.description,
              prices: [prevState.price_fishing_cards, prevState.price_accommodation],
            },
          ],
        },
      }),
      () => {
        this.setState({
          prices: {
            description: "",
          },
          price_fishing_cards: {
            description: "Fishing cards",
            amount: "",
            tax_option_id: null,
          },
          price_accommodation: {
            description: "Accommodation",
            amount: "",
            tax_option_id: null,
          },
        });
      }
    );

    return true;
  }

  handleEditPrice(item, key) {
    const cards = item.prices.filter((i) => i.description === "Fishing cards")[0];
    const accommodation = item.prices.filter((i) => i.description === "Accommodation")[0];

    this.setState({
      edit_price: {
        description: item.description,
        cards_amount: cards.amount,
        cards_tax_option_id: cards.tax_option_id,
        accommodation_amount: accommodation.amount,
        accommodation_tax_option_id: accommodation.tax_option_id,
        key,
      },
    });
  }

  toggle() {
    this.props.toggleModal();
  }

  confirmDelete(confirmDelete) {
    this.setState({
      confirmDelete,
    });
  }

  handleSaveEditPrice(key, item) {
    if (
      !this.state.edit_price.description ||
      !this.state.edit_price.cards_amount ||
      !this.state.edit_price.cards_tax_option_id ||
      !this.state.edit_price.accommodation_amount ||
      !this.state.edit_price.accommodation_tax_option_id
    ) {
      return APIMessage.error(translations.error[lang].fill_all_fields);
    }

    const checkIfExists = this.state.data.price_options
      .filter((i) => i.description !== item.description)
      .filter((i) => i.description === this.state.edit_price.description);

    if (checkIfExists.length > 0) {
      return APIMessage.error(translations.error[lang].price_already_exists);
    }

    const prices = this.state.data.price_options;
    prices[key] = {
      description: this.state.edit_price.description,
      prices: [
        {
          description: "Fishing cards",
          amount: this.state.edit_price.cards_amount,
          tax_option_id: this.state.edit_price.cards_tax_option_id,
        },
        {
          description: "Accommodation",
          amount: this.state.edit_price.accommodation_amount,
          tax_option_id: this.state.edit_price.accommodation_tax_option_id,
        },
      ],
    };

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          price_options: [...prices],
        },
      }),
      () => {
        this.setState({
          edit_price: {},
        });
      }
    );

    return true;
  }

  setExtraService = (item, value) => {
    if (value) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          extra_service_ids: [...prevState.data.extra_service_ids, item],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          extra_service_ids: [...prevState.data.extra_service_ids.filter((i) => i !== item)],
        },
      }));
    }
  };

  render() {
    const selectTaxes = this.props.taxes.data.map((i) => ({
      value: i.id,
      label: `${i.key} (${i.value}%)`,
    }));

    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "add_edit_package" })}</ModalHeader>
        {this.props.free_packages.loading ? (
          <Spinner />
        ) : (
          <Form>
            {this.state.confirmDelete ? (
              <div>
                <ModalBody>
                  <h2>{this.props.intl.formatMessage({ id: "confirm_delete_question" })}</h2>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={() => this.onDelete()}>
                    {this.props.intl.formatMessage({ id: "remove" })}
                  </Button>
                  <Button color="secondary" onClick={() => this.confirmDelete(false)}>
                    {this.props.intl.formatMessage({ id: "back" })}
                  </Button>
                </ModalFooter>
              </div>
            ) : (
              <div>
                <ModalBody>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row>
                        <Label for="name" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "name" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            required
                            value={this.state.data.name}
                            onChange={(e) => this.onChangeValue({ name: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="description" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "description" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="textarea"
                            name="description"
                            id="description"
                            required
                            value={this.state.data.description}
                            onChange={(e) => this.onChangeValue({ description: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      {this.state.isNew ? (
                        <FormGroup row>
                          <Label for="quantity" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "quantity" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              type="number"
                              name="quantity"
                              id="quantity"
                              required
                              value={this.state.data.quantity}
                              onChange={(e) => this.onChangeValue({ quantity: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                      ) : null}
                      <FormGroup row>
                        <Label for="date_start" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "date_start" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="date"
                            name="date_start"
                            id="date_start"
                            value={this.state.data.date_start}
                            onChange={(e) => this.onChangeValue({ date_start: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="date_end" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "date_end" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="date"
                            name="date_end"
                            id="date_end"
                            value={this.state.data.date_end}
                            onChange={(e) => this.onChangeValue({ date_end: e.target.value })}
                          />
                        </Col>
                      </FormGroup>

                      <hr />

                      <FormGroup row style={{ marginBottom: 0 }}>
                        <Label for="price_fishing_cards" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "add_price" })}
                        </Label>
                        <Col xs={12} sm={5} style={{ marginTop: 10 }}>
                          <Input
                            style={{ marginBottom: 5 }}
                            type="number"
                            name="price_fishing_cards"
                            id="price_fishing_cards"
                            placeholder={this.props.intl.formatMessage({ id: "price_for_fishing_cards" })}
                            required
                            value={this.state.price_fishing_cards.amount}
                            onChange={(e) => this.onChangeCardsPrices({ amount: e.target.value })}
                          />
                        </Col>
                        <Col xs={12} sm={3} style={{ marginTop: 10 }}>
                          <SelectInput
                            id="fishing_cards_select_tax_value"
                            name="fishing_cards_select_tax_value"
                            intl={this.props.intl}
                            placeholder={this.props.intl.formatMessage({ id: "select_tax" })}
                            value={selectTaxes.filter((i) => i.value === this.state.price_fishing_cards.tax_option_id)}
                            onChange={(e) => this.onChangeCardsPrices({ tax_option_id: e.value })}
                            options={selectTaxes}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={5} style={{ marginTop: 10 }}>
                          <Input
                            style={{ marginBottom: 5 }}
                            type="number"
                            name="price_accommodation"
                            id="price_accommodation"
                            placeholder={this.props.intl.formatMessage({ id: "price_for_accommodation" })}
                            required
                            value={this.state.price_accommodation.amount}
                            onChange={(e) => this.onChangeAccommodationPrices({ amount: e.target.value })}
                          />
                        </Col>
                        <Col xs={12} sm={3} style={{ marginTop: 10 }}>
                          <SelectInput
                            id="accommodation_select_tax_value"
                            name="accommodation_select_tax_value"
                            intl={this.props.intl}
                            placeholder={this.props.intl.formatMessage({ id: "select_tax" })}
                            value={selectTaxes.filter((i) => i.value === this.state.price_accommodation.tax_option_id)}
                            onChange={(e) => this.onChangeAccommodationPrices({ tax_option_id: e.value })}
                            options={selectTaxes}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Col xs={12} sm={labelWidth} />
                        <Col xs={12} sm={8} className="price-description-input-btn">
                          <Input
                            type="text"
                            name="price_description"
                            id="price_description"
                            required
                            placeholder={this.props.intl.formatMessage({ id: "description" })}
                            value={this.state.prices.description}
                            onChange={(e) => this.onChangePrices({ description: e.target.value })}
                          />
                          <Button color="outline-secondary" onClick={() => this.handleAddPrice()}>
                            {this.props.intl.formatMessage({ id: "add_price" })}
                          </Button>
                        </Col>
                      </FormGroup>

                      {this.state.data.price_options.length > 0 && (
                        <div>
                          <hr style={{ marginBottom: 0 }} />

                          <FormGroup row>
                            <Col xs={12}>
                              <Table bordered responsive style={{ marginBottom: 50 }}>
                                <thead>
                                  <tr>
                                    <th>{this.props.intl.formatMessage({ id: "description" })}</th>
                                    <th>{this.props.intl.formatMessage({ id: "price_for_fishing_cards" })}</th>
                                    <th>{this.props.intl.formatMessage({ id: "tax" })}</th>
                                    <th>{this.props.intl.formatMessage({ id: "price_for_accommodation" })}</th>
                                    <th>{this.props.intl.formatMessage({ id: "tax" })}</th>
                                    <th>&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.data.price_options.map((item, key) => {
                                    const cards = item.prices.filter((i) => i.description === "Fishing cards")[0];
                                    const accommodation = item.prices.filter((i) => i.description === "Accommodation")[0];

                                    return (
                                      <React.Fragment key={key}>
                                        {"key" in this.state.edit_price && this.state.edit_price.key === key ? (
                                          <tr>
                                            <td>
                                              <Input
                                                style={{ width: 65 }}
                                                type="text"
                                                name="price_description"
                                                id="price_description"
                                                value={this.state.edit_price.description}
                                                onChange={(e) => this.onChangeEditPrice({ description: e.target.value })}
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                style={{ width: 65 }}
                                                type="text"
                                                name="price_amount"
                                                id="price_amount"
                                                value={this.state.edit_price.cards_amount}
                                                onChange={(e) => this.onChangeEditPrice({ cards_amount: e.target.value })}
                                              />
                                            </td>
                                            <td style={{ minWidth: "120px" }}>
                                              <SelectInput
                                                id="select_tax_value"
                                                name="select_tax_value"
                                                intl={this.props.intl}
                                                placeholder={this.props.intl.formatMessage({ id: "select_tax" })}
                                                value={selectTaxes.filter((i) => i.value === this.state.edit_price.cards_tax_option_id)}
                                                onChange={(e) => this.onChangeEditPrice({ cards_tax_option_id: e.value })}
                                                options={selectTaxes}
                                              />
                                            </td>
                                            <td>
                                              <Input
                                                style={{ width: 65 }}
                                                type="text"
                                                name="accommodation_price_amount"
                                                id="accommodation_price_amount"
                                                value={this.state.edit_price.accommodation_amount}
                                                onChange={(e) => this.onChangeEditPrice({ accommodation_amount: e.target.value })}
                                              />
                                            </td>
                                            <td style={{ minWidth: "120px" }}>
                                              <SelectInput
                                                id="accommodation_select_tax_value"
                                                name="accommodation_select_tax_value"
                                                intl={this.props.intl}
                                                placeholder={this.props.intl.formatMessage({ id: "select_tax" })}
                                                value={selectTaxes.filter((i) => i.value === this.state.edit_price.accommodation_tax_option_id)}
                                                onChange={(e) => this.onChangeEditPrice({ accommodation_tax_option_id: e.value })}
                                                options={selectTaxes}
                                              />
                                            </td>
                                            <td className="text-right" style={{ minWidth: "180px" }}>
                                              <Button color="secondary" onClick={() => this.handleSaveEditPrice(key, item)}>
                                                <SaveLabel intl={this.props.intl} />
                                              </Button>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td>{item.description}</td>
                                            <td>{cards.amount}</td>
                                            <td>{`${this.props.taxes.data.filter((i) => i.id === cards.tax_option_id)[0].value}%`}</td>
                                            <td>{accommodation.amount}</td>
                                            <td>{`${this.props.taxes.data.filter((i) => i.id === accommodation.tax_option_id)[0].value}%`}</td>
                                            <td className="text-right" style={{ minWidth: "180px" }}>
                                              <Button color="outline-secondary" onClick={() => this.handleEditPrice(item, key)} style={{ marginRight: 5 }}>
                                                {this.props.intl.formatMessage({ id: "edit" })}
                                              </Button>
                                              <Button color="danger" onClick={() => this.handleRemovePrice(item)}>
                                                {this.props.intl.formatMessage({ id: "remove" })}
                                              </Button>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </Col>
                          </FormGroup>
                        </div>
                      )}

                      <hr />

                      {this.props.extra_services.data?.data && this.props.extra_services.data.data.length > 0 && (
                        <div>
                          <FormGroup row style={{ marginTop: 35 }}>
                            <Label for="extra_services" xs={12} sm={labelWidth}>
                              {this.props.intl.formatMessage({ id: "extra_services" })}
                            </Label>
                            <Col xs={12} sm={inputWidth} style={{ paddingTop: 10, paddingBottom: 10 }}>
                              <Row>
                                {this.props.extra_services.data.data.map((i) => (
                                  <Col xs={6} key={i.id} style={{ paddingBottom: 10 }}>
                                    <Checkbox
                                      size={ANT_CONFIG.size}
                                      checked={this.state.data.extra_service_ids?.filter((f) => f === i.id)?.[0] || false}
                                      onChange={(e) => this.setExtraService(i.id, e.target.checked)}
                                    >
                                      {`${i.name} - ${i.price} NOK`}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          </FormGroup>

                          <hr />
                        </div>
                      )}

                      <FormGroup row style={{ marginTop: 35 }}>
                        <Label for="publish_date_start" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "publish_date_start" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="datetime-local"
                            name="publish_date_start"
                            id="publish_date_start"
                            value={this.state.data.publish_date_start}
                            onChange={(e) => this.onChangeValue({ publish_date_start: e.target.value, updated_date_start: e.target.value })}
                            onSelect={() =>
                              !this.state.data.publish_date_start
                                ? this.onChangeValue({
                                    publish_date_start: moment().format("YYYY-MM-DDT00:00"),
                                    updated_date_start: moment().format("YYYY-MM-DDT00:00"),
                                  })
                                : null
                            }
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="publish_date_end" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "publish_date_end" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="datetime-local"
                            name="publish_date_end"
                            id="publish_date_end"
                            value={this.state.data.publish_date_end}
                            onChange={(e) => this.onChangeValue({ publish_date_end: e.target.value, updated_date_end: e.target.value })}
                            onSelect={() =>
                              !this.state.data.publish_date_end
                                ? this.onChangeValue({
                                    publish_date_end: moment().format("YYYY-MM-DDT00:00"),
                                    updated_date_end: moment().format("YYYY-MM-DDT00:00"),
                                  })
                                : null
                            }
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  {!this.state.isNew ? (
                    <Button color="danger" onClick={() => this.confirmDelete(true)}>
                      {this.props.intl.formatMessage({ id: "remove" })}
                    </Button>
                  ) : null}
                  <Button color="secondary" onClick={() => this.onSubmit()} type="submit">
                    <SaveLabel intl={this.props.intl} />
                  </Button>
                </ModalFooter>
              </div>
            )}
          </Form>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  free_packages: state.FreePackages,
  extra_services: state.ExtraServices,
  taxes: state.Taxes,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddFreePackage)));
