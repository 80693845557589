import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch() {
  try {
    const response = yield call(API.call, "admin/main-taxes", "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_TAXES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_TAXES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_TAXES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* add(action) {
  try {
    const response = yield call(API.call, `admin/main-taxes`, "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_TAX_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch);
      yield put({
        type: ACTION.ADD_TAX_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_TAX_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, `admin/main-tax`, "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_TAX_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch);
      yield put({
        type: ACTION.EDIT_TAX_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_TAX_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, `admin/main-tax/${action.payload}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_TAX_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch);
      yield put({
        type: ACTION.REMOVE_TAX_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_TAX_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_TAXES, fetch), takeEvery(ACTION.ADD_TAX, add), takeEvery(ACTION.EDIT_TAX, edit), takeEvery(ACTION.REMOVE_TAX, remove)]);
}
