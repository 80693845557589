const riverOptionsFromStations = (props, auth) => {
  const riversFromStations = auth.data.stations.map(i => i.river_id);

  return props.data
    .filter(river => riversFromStations.includes(river.id))
    .map(river => {
      return {
        value: river.id,
        label: river.name
      };
    });
};

export default riverOptionsFromStations;
