export const ADD_BOOKING = "ADD_BOOKING";
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_FAILURE = "ADD_BOOKING_FAILURE";

export function add(payload) {
  return {
    type: ADD_BOOKING,
    payload
  };
}
