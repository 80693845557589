import React from "react";
import { connect } from "react-redux";
import { Button, Col, Input, Modal, Row } from "antd";
import { injectIntl } from "react-intl";

import ANT_CONFIG from "../../../constants/antconfig";

class CategoryModal extends React.Component {
  state = {
    name: this.props.name || ""
  };

  setName(name) {
    this.setState({
      name
    });
  }

  onSubmit = () => {
    const { save } = this.props;
    const { name } = this.state;

    save(name);
  };

  onCancel = () => {
    const { cancel } = this.props;

    cancel();
  };

  render() {
    const { intl, show, articleCategories } = this.props;
    const { name } = this.state;

    return (
      <Modal
        title={this.props.name ? intl.formatMessage({ id: "edit_category" }) : intl.formatMessage({ id: "add_category" })}
        visible={show}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="cancel" disabled={articleCategories.loading} type="default" onClick={this.onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" disabled={articleCategories.loading} type="primary" onClick={this.onSubmit}>
            {intl.formatMessage({ id: "save" })}
          </Button>
        ]}
      >
        {show && (
          <Row>
            <Col xs={24}>
              <label className="ant-label" htmlFor="name">
                {intl.formatMessage({ id: "name" })}
              </label>
              <Input type="text" id="name" size={ANT_CONFIG.size} value={name} style={{ width: "100%" }} onChange={e => this.setName(e.target.value)} />
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  articleCategories: state.ArticleCategories
});

export default injectIntl(connect(mapStateToProps)(CategoryModal));
