import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  file: undefined,
  file_loading: false,
  file_error: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_RIVER_ORDERS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_RIVER_ORDERS_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_RIVER_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.DOWNLOAD_RIVER_ORDERS:
      return {
        ...state,
        file: undefined,
        file_loading: true,
      };

    case ACTION.DOWNLOAD_RIVER_ORDERS_SUCCESS:
      return {
        ...state,
        file: action.payload,
        file_loading: false,
        file_error: false,
      };

    case ACTION.DOWNLOAD_RIVER_ORDERS_FAILURE:
      return {
        ...state,
        file_loading: false,
        file_error: true,
      };

    case ACTION.CANCEL_RIVER_ORDER:
      return {
        ...state,
        loading: true,
      };

    case ACTION.CANCEL_RIVER_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.CANCEL_RIVER_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
