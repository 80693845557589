export const FETCH_DASHBOARD_BEAT_ADMIN_ADMINS = "FETCH_DASHBOARD_BEAT_ADMIN_ADMINS";
export const FETCH_DASHBOARD_BEAT_ADMIN_ADMINS_SUCCESS = "FETCH_DASHBOARD_BEAT_ADMIN_ADMINS_SUCCESS";
export const FETCH_DASHBOARD_BEAT_ADMIN_ADMINS_FAILURE = "FETCH_DASHBOARD_BEAT_ADMIN_ADMINS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_BEAT_ADMIN_ADMINS,
    payload
  };
}
