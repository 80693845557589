import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimePicker, Checkbox, Alert } from "antd";

import Spinner from "../../../spinner";
import SelectInput from "../../../select";
import {
  fetchAvailable as fetchFishingCards,
  fetchTypes as fetchCardTypes,
  fetchPrices,
} from "../../../../redux/fishing_cards/actions";
import { add as addBooking } from "../../../../redux/booking/actions";
import { quiteAdd } from "../../../../redux/customers/actions";
import APIMessage from "../../../../services/api_messages";
import SaveLabel from "../../../helpers/buttons/save_label";
import { DEFAULT_LANGUAGE } from "../../../../constants";
import transactionFee from "../../../../services/transaction_fee";
import ANT_CONFIG from "../../../../constants/antconfig";
import translations from "../../../../constants/strings";

const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;

const labelWidth = 4;
const inputWidth = 8;

class ModalOrderFishingCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flexible_time: false,
      modal: true,
      submitted: false,
      beat_id: props.selectedBeat,
      date_start: props.modalData.date_start || null,
      date_end: props.modalData.date_end || null,
      quantity: 1,
      currentPage: 0,
      fishing_cards: {},
      show_new_user_form: false,
      fishing_card_type_id: null,
      prices: null,
      times: null,
      user: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
      },
      additional_user: [],
      payment_method: false,
      payment_method_selected: null,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.props.dispatch(fetchCardTypes(this.state.beat_id));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers.new_user !== this.props.customers.new_user) {
      this.onComponentUpdate();
    }
  }

  onComponentUpdate() {
    this.setState((prevState) => ({
      user: {
        id: this.props.customers.new_user.id,
        first_name: prevState.user.first_name,
        last_name: prevState.user.last_name,
        email: this.props.customers.new_user.email,
      },
    }));
  }

  onChangeAdditionalUser(name) {
    this.setState((prevState) => ({
      additional_user: {
        ...prevState.additional_user,
        [prevState.currentPage]: {
          name,
          send_to_email: prevState.additional_user?.[prevState.currentPage]?.send_to_email || "",
        },
      },
    }));
  }

  onChangeAdditionalUserEmail(send_to_email) {
    this.setState((prevState) => ({
      additional_user: {
        ...prevState.additional_user,
        [prevState.currentPage]: {
          send_to_email,
          name: prevState.additional_user?.[prevState.currentPage]?.name || "",
        },
      },
    }));
  }

  onChangeUserFirstName(name) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        first_name: name,
      },
    }));
  }

  onChangeUserLastName(name) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        last_name: name,
      },
    }));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({
      data: { ...prevState.data, ...newState },
    }));
  }

  onSubmit(fee) {
    this.setState({
      submitted: true,
    });

    const data = {
      user_id: this.state.user.id,
      fishing_cards: [],
      payment_method: this.state.payment_method ? this.state.payment_method_selected : "nets",
    };

    Object.values(this.state.fishing_cards).map((i) => {
      return Object.values(i).map((t) => {
        return data.fishing_cards.push(t);
      });
    });

    this.props.dispatch(addBooking(data));
  }

  onChangeCardType(id) {
    this.setState({ fishing_card_type_id: id }, () => {
      this.handleFlexTime();
      this.fetchData();
    });
  }

  onTimeSelect(e, item) {
    if (!e) {
      return;
    }

    this.setState((prevState) => ({
      times: {
        ...prevState.times,
        [item.id]: {
          t: e,
        },
      },
    }));
  }

  onFirstTimeslotSelect(e, item, items) {
    if (!e) {
      return;
    }

    items.forEach((i) => {
      this.setState((prevState) => ({
        times: {
          ...prevState.times,
          [i.id]: {
            t: e,
          },
        },
      }));
    });
  }

  onPriceSelect(e, item) {
    const price = item.prices.filter((i) => i.id === e);

    this.setState((prevState) => ({
      prices: {
        ...prevState.prices,
        [item.id]: {
          ...price[0],
          price_amount_id: price[0].id,
        },
      },
    }));
  }

  onSelectUser(e) {
    const customer = this.props.customers.data.filter((i) => i.id === e)[0];

    if (customer) {
      this.setState({
        user: {
          id: customer.id,
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
        },
      });
    }
  }

  onSelectPreviousPage() {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage - 1,
      }),
      () => {
        if (this.state.currentPage > 0 && this.state.currentPage <= this.state.quantity) {
          const ids = Object.values(this.props.fishing_cards.available)
            .map((items) => items[this.state.currentPage - 1])
            .map((i) => i.id);
          this.props.dispatch(fetchPrices({ card_ids: ids }));
        }
      },
    );
  }

  onSelectNextPage() {
    let name;
    let send_to_email;

    if (this.state.currentPage === 1) {
      if (!this.state.user.first_name || !this.state.user.last_name || !this.state.user.id) {
        return APIMessage.error(translations.error[lang].user_field_required);
      }

      name = `${this.state.user.first_name} ${this.state.user.last_name}`;
    }

    if (this.state.currentPage > 1 && this.state.currentPage <= this.state.quantity) {
      if (!this.state.additional_user || !this.state.additional_user[this.state.currentPage]) {
        return APIMessage.error(translations.error[lang].user_field_required);
      }

      name = this.state.additional_user?.[this.state.currentPage]?.name || "";
      send_to_email = this.state.additional_user?.[this.state.currentPage]?.send_to_email || "";
    }

    const ids = this.state.currentPage > 0 ? this.props.fishing_cards.prices.data.map((i) => i.id) : [];
    let items = [];

    if (this.state.prices) {
      if (this.props.fishing_cards.prices.extra.same) {
        items = ids.map((id) => {
          return {
            id: parseInt(id, 10),
            price: {
              ...this.state.prices[ids[0]],
            },
            name,
            send_to_email,
          };
        });
      } else {
        items = ids.map((key) => {
          return {
            id: parseInt(key, 10),
            price: {
              ...this.state.prices[key],
            },
            name,
            send_to_email,
          };
        });
      }
    }

    if (this.state.flexible_time && this.state.times) {
      items = items.map((item) => {
        if (!this.state.times[item.id]) {
          return APIMessage.error(translations.error[lang].select_all_times);
        }

        return {
          ...item,
          flexible_time: this.state.times[item.id] ? moment(this.state.times[item.id].t).format("HH:mm") : "",
        };
      });
    }

    if (items.length < ids.length) {
      return APIMessage.error(translations.error[lang].select_all_prices);
    }

    if (this.state.flexible_time && items.filter((i) => i.flexible_time).length < ids.length) {
      return APIMessage.error(translations.error[lang].select_all_times);
    }

    if (this.state.currentPage > 0 && Object.values(this.state.prices).length < this.state.currentPage) {
      return APIMessage.error(translations.error[lang].select_all_prices);
    }

    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        fishing_cards: {
          ...prevState.fishing_cards,
          [prevState.currentPage]: {
            ...items,
          },
        },
      }),
      () => {
        if (this.state.currentPage > 0 && this.state.currentPage <= this.state.quantity) {
          const ids = Object.values(this.props.fishing_cards.available)
            .map((items) => items[this.state.currentPage - 1])
            .map((i) => i.id);

          this.props.dispatch(fetchPrices({ card_ids: ids }));
        }
      },
    );

    return true;
  }

  onChangeUserInput(newState) {
    this.setState((prevState) => ({
      user: { ...prevState.user, ...newState },
    }));
  }

  onSaveNewUser() {
    this.props.dispatch(
      quiteAdd({
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        email: this.state.user.email,
      }),
    );
  }

  changePaymentMethod(e) {
    this.setState({
      payment_method_selected: e.target.value,
    });
  }

  handleFlexTime() {
    const item = this.props.fishing_cards.type.data.filter((i) => i.id === this.state.fishing_card_type_id);

    this.setState({
      flexible_time: item[0].flexible_time,
    });
  }

  fetchData() {
    if (this.state.date_start && this.state.date_end && this.state.beat_id) {
      this.props.dispatch(
        fetchFishingCards({
          beat_id: this.state.beat_id,
          date_start: this.state.date_start,
          date_end: this.state.date_end,
          fishing_card_type_id: this.state.fishing_card_type_id,
        }),
      );
    }
  }

  handleChangeDate(e) {
    const { name } = e.target;

    if (name === "date_start") {
      this.setState({ date_start: e.target.value }, () => {
        this.fetchData();
      });
    }

    if (name === "date_end") {
      this.setState({ date_end: e.target.value }, () => {
        this.fetchData();
      });
    }
  }

  addNewUser() {
    this.setState((prevState) => ({
      show_new_user_form: true,
      user: {
        ...prevState.user,
        id: null,
      },
    }));
  }

  toggle() {
    this.props.toggleModal();
  }

  render() {
    const selectCustomers = [
      ...this.props.customers.data.map((i) => {
        return {
          value: i.id,
          label: `${i.first_name} ${i.last_name} (${i.email})`,
        };
      }),
    ];
    const selectCardTypes = this.props.fishing_cards.type.data
      ? this.props.fishing_cards.type.data.map((i) => {
          return {
            value: i.id,
            label: i.name,
          };
        })
      : null;

    if (this.props.booking.loading) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    if (this.props.booking.data && !this.props.booking.loading && !this.props.booking.error && this.state.submitted) {
      if (this.state.payment_method) {
        this.toggle();

        return false;
      }

      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "payment_link" })}</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} sm={10}>
                <Input type="text" defaultValue={this.props.booking.data.terminal_urls.url} disabled />
              </Col>
              <Col xs={12} sm={2}>
                <a
                  href={this.props.booking.data.terminal_urls.url}
                  className="btn btn-success"
                  style={{ width: "100%", lineHeight: "24px" }}
                >
                  {this.props.intl.formatMessage({ id: "pay_now" })}
                </a>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      );
    }

    if (this.props.fishing_cards.loading || !this.props.fishing_cards.available) {
      return (
        <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
          <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
          <Spinner />
        </Modal>
      );
    }

    let cardPrices = [];
    let cardsForTimepicker = [];

    if (this.props.fishing_cards.prices.data) {
      cardPrices = this.props.fishing_cards.prices.data;
      cardsForTimepicker = this.props.fishing_cards.prices.data;
    }

    if (this.props.fishing_cards.prices.extra && this.props.fishing_cards.prices.extra.same) {
      cardPrices = cardPrices.slice(0, 1);
    }

    const error = {};
    const beatCards = this.props.fishing_cards.available;
    const capacityCheck = Object.values(beatCards).length;
    const daysBetween = moment(this.state.date_end).diff(moment(this.state.date_start), "days") + 1;
    if (daysBetween !== capacityCheck) {
      error.capacity = true;
    }
    if (this.state.quantity < 1) {
      error.quantity = true;
    }
    Object.values(beatCards).map((items) => {
      if (items.length < this.state.quantity) {
        error.quantity = true;
      }

      return true;
    });
    let totalPrice;

    if (this.state.currentPage > this.state.quantity) {
      totalPrice = Object.values(this.state.fishing_cards)
        .slice(1, this.state.fishing_cards.length)
        .map((i) =>
          Object.values(i)
            .map((i) => i.price)
            .map((i) => i.amount)
            .reduce((a, b) => parseFloat(a) + parseFloat(b)),
        )
        .reduce((a, b) => parseFloat(a) + parseFloat(b));
    }

    return (
      <Modal isOpen={this.state.modal}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "new_booking" })}</ModalHeader>
        {this.state.currentPage === 0 ? (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  {this.props.fishing_cards.type.loading ? (
                    <FormGroup row>
                      <Label for="select_card_type" xs={12} sm={labelWidth}>
                        {`${this.props.intl.formatMessage({
                          id: "fishing_card_type",
                        })}`}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <Spinner width={30} style={{ height: 53, marginTop: -15 }} />
                      </Col>
                    </FormGroup>
                  ) : (
                    <FormGroup row>
                      <Label for="select_card_type" xs={12} sm={labelWidth}>
                        {`${this.props.intl.formatMessage({
                          id: "fishing_card_type",
                        })}`}
                      </Label>
                      <Col xs={12} sm={inputWidth}>
                        <SelectInput
                          id="select_card_type"
                          name="select_card_type"
                          intl={this.props.intl}
                          value={selectCardTypes.filter((i) => i.value === this.state.fishing_card_type_id)}
                          onChange={(e) => this.onChangeCardType(e.value)}
                          options={selectCardTypes}
                        />
                      </Col>
                    </FormGroup>
                  )}

                  <FormGroup row>
                    <Label for="date_start" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "date_start" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="date"
                        name="date_start"
                        id="date_start"
                        value={this.state.date_start}
                        onChange={(e) => this.handleChangeDate(e)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="date_end" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "date_end" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="date"
                        name="date_end"
                        id="date_end"
                        value={this.state.date_end}
                        onChange={(e) => this.handleChangeDate(e)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xs={12} sm={labelWidth} />
                    <Col xs={12} sm={inputWidth}>
                      <Checkbox
                        checked={this.state.payment_method}
                        onChange={(event) => {
                          const checked = event.target.checked;

                          if (checked) this.setState({ payment_method: true, payment_method_selected: "manual" });
                          else this.setState({ payment_method: false, payment_method_selected: null });
                        }}
                      >
                        {this.props.intl.formatMessage({
                          id: "payment_performed_manually",
                        })}
                      </Checkbox>
                    </Col>
                  </FormGroup>
                  {error.capacity && this.state.fishing_card_type_id ? (
                    <FormGroup row>
                      <Col xs={12} sm={labelWidth} />
                      <Col xs={12} sm={inputWidth} className="col-error">
                        <div>{translations.error[lang].dates_unavailable}</div>
                      </Col>
                    </FormGroup>
                  ) : null}

                  {this.state.fishing_card_type_id ? (
                    <div>
                      <hr />
                      <FormGroup row>
                        <Label for="quantity" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "quantity" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="number"
                            name="quantity"
                            id="quantity"
                            value={this.state.quantity}
                            onChange={(e) => this.setState({ quantity: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      {error.quantity ? (
                        <FormGroup row>
                          <Col xs={12} sm={labelWidth} />
                          <Col xs={12} sm={inputWidth} className="col-error">
                            <div>{translations.error[lang].quantity_unavailable}</div>
                          </Col>
                        </FormGroup>
                      ) : null}
                    </div>
                  ) : null}
                </Col>
              </Row>
            </ModalBody>
            {this.state.fishing_card_type_id && this.state.quantity > 0 && !error.quantity && !error.capacity ? (
              <ModalFooter>
                <Button color="secondary" onClick={() => this.onSelectNextPage()}>
                  {this.props.intl.formatMessage({ id: "next" })}
                </Button>
              </ModalFooter>
            ) : null}
          </Form>
        ) : null}

        {this.state.currentPage > 0 && this.state.currentPage <= this.state.quantity ? (
          <React.Fragment>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  {this.state.currentPage === 1 ? (
                    <div>
                      {this.state.show_new_user_form ? (
                        <FormGroup row>
                          <Label for="or_add_new_customer" xs={12} sm={labelWidth}>
                            {`${this.props.intl.formatMessage({
                              id: "new_customer",
                            })}`}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <FormGroup row>
                              <Col xs={12} sm={6}>
                                <Input
                                  type="text"
                                  disabled={this.props.customers.new_user}
                                  placeholder={this.props.intl.formatMessage({
                                    id: "first_name",
                                  })}
                                  value={this.state.user.first_name}
                                  onChange={(e) =>
                                    this.onChangeUserInput({
                                      first_name: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                              <Col xs={12} sm={6}>
                                <Input
                                  type="text"
                                  disabled={this.props.customers.new_user}
                                  placeholder={this.props.intl.formatMessage({
                                    id: "last_name",
                                  })}
                                  value={this.state.user.last_name}
                                  onChange={(e) =>
                                    this.onChangeUserInput({
                                      last_name: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col xs={12}>
                                <Input
                                  type="email"
                                  disabled={this.props.customers.new_user}
                                  placeholder={this.props.intl.formatMessage({
                                    id: "email",
                                  })}
                                  value={this.state.user.email}
                                  onChange={(e) =>
                                    this.onChangeUserInput({
                                      email: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </FormGroup>
                            {this.props.customers.loading ? (
                              <FormGroup row>
                                <Col xs={12}>
                                  <Spinner />
                                </Col>
                              </FormGroup>
                            ) : null}
                            {!this.props.customers.loading && !this.props.customers.new_user ? (
                              <FormGroup row>
                                <Col xs={12}>
                                  <Button color="secondary" onClick={() => this.onSaveNewUser()} style={{ width: "100%" }}>
                                    <SaveLabel intl={this.props.intl} />
                                  </Button>
                                </Col>
                              </FormGroup>
                            ) : null}
                          </Col>
                        </FormGroup>
                      ) : (
                        <div>
                          <FormGroup row>
                            <Label for="select_customer" xs={12} sm={labelWidth}>
                              {`${this.props.intl.formatMessage({
                                id: "select_customer",
                              })}`}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <SelectInput
                                id="select_customer"
                                name="select_customer"
                                intl={this.props.intl}
                                value={selectCustomers.filter((i) => i.value === this.state.user.id)}
                                onChange={(e) => this.onSelectUser(e.value)}
                                options={selectCustomers}
                              />
                            </Col>
                          </FormGroup>
                          {this.state.user.id ? (
                            <FormGroup row>
                              <Col xs={12} sm={labelWidth} />
                              <Col xs={12} sm={4}>
                                <Input
                                  type="text"
                                  value={this.state.user.first_name}
                                  onChange={(e) => this.onChangeUserFirstName(e.target.value)}
                                />
                              </Col>
                              <Col xs={12} sm={4}>
                                <Input
                                  type="text"
                                  value={this.state.user.last_name}
                                  onChange={(e) => this.onChangeUserLastName(e.target.value)}
                                />
                              </Col>
                            </FormGroup>
                          ) : null}
                          {!this.state.user.id ? (
                            <FormGroup row>
                              <Col xs={12} sm={labelWidth} />
                              <Col xs={12} sm={inputWidth}>
                                <Button color="success" onClick={() => this.addNewUser()} style={{ width: "100%" }}>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{
                                      fontSize: 15,
                                      marginRight: 10,
                                    }}
                                  />
                                  {this.props.intl.formatMessage({
                                    id: "add_new_customer",
                                  })}
                                </Button>
                              </Col>
                            </FormGroup>
                          ) : null}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <FormGroup row>
                        <Label for="first_and_last_name" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({
                            id: "first_and_last_name",
                          })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            value={
                              this.state.additional_user?.[this.state.currentPage]?.name
                                ? this.state.additional_user[this.state.currentPage].name
                                : ""
                            }
                            onChange={(e) => this.onChangeAdditionalUser(e.target.value)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="email" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "email" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="email"
                            value={
                              this.state.additional_user?.[this.state.currentPage]?.send_to_email
                                ? this.state.additional_user[this.state.currentPage].send_to_email
                                : ""
                            }
                            onChange={(e) => this.onChangeAdditionalUserEmail(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </Col>
                {this.props.fishing_cards.prices.loading ? (
                  <Spinner />
                ) : (
                  <Col xs={12}>
                    {cardPrices.length > 0
                      ? cardPrices.map((item) => (
                          <FormGroup row key={item.id}>
                            <Label for="select_price" xs={12} sm={labelWidth}>
                              {this.props.fishing_cards.prices.extra.same
                                ? `${this.props.intl.formatMessage({
                                    id: "select_price",
                                  })}`
                                : `${item.date}`}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <SelectInput
                                id="select_price"
                                name="select_price"
                                intl={this.props.intl}
                                value={item.prices
                                  .map((i) => {
                                    return {
                                      value: i.id,
                                      label: `${i.description} - ${i.amount}`,
                                    };
                                  })
                                  .filter(
                                    (i) =>
                                      i.value ===
                                      (this.state.prices && this.state.prices[item.id]
                                        ? this.state.prices[item.id].id
                                        : this.state.fishing_cards[this.state.currentPage] &&
                                          Object.values(this.state.fishing_cards[this.state.currentPage]).filter(
                                            (i) => i.id === item.id,
                                          )[0] &&
                                          Object.values(this.state.fishing_cards[this.state.currentPage]).filter(
                                            (i) => i.id === item.id,
                                          )[0].price
                                        ? Object.values(this.state.fishing_cards[this.state.currentPage]).filter(
                                            (i) => i.id === item.id,
                                          )[0].price.id
                                        : null),
                                  )}
                                onChange={(e) => this.onPriceSelect(e.value, item)}
                                options={item.prices.map((i) => {
                                  return {
                                    value: i.id,
                                    label: `${i.description} - ${i.amount}`,
                                  };
                                })}
                              />
                            </Col>
                          </FormGroup>
                        ))
                      : null}
                  </Col>
                )}
                {this.state.flexible_time && cardsForTimepicker.length > 0 ? (
                  <React.Fragment>
                    <Col xs={12} sm={labelWidth} />
                    <Col xs={12} sm={inputWidth}>
                      <strong style={{ display: "block", margin: "15px 0 10px" }}>
                        {this.props.intl.formatMessage({
                          id: "select_start_dates",
                        })}
                      </strong>
                    </Col>
                    <Col xs={12}>
                      {cardsForTimepicker.map((item, key) => (
                        <FormGroup row key={item.id}>
                          <Label xs={12} sm={labelWidth}>
                            {item.date}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <TimePicker
                              allowClear={false}
                              style={{ width: "100%" }}
                              onChange={
                                key === 0
                                  ? (e) => this.onFirstTimeslotSelect(e, item, cardsForTimepicker)
                                  : (e) => this.onTimeSelect(e, item)
                              }
                              defaultOpenValue={moment("00:00", "HH:mm")}
                              size={ANT_CONFIG.size}
                              format="HH:mm"
                              minuteStep={30}
                              value={this.state.times && this.state.times[item.id] ? this.state.times[item.id].t : null}
                              placeholder=""
                            />
                          </Col>
                        </FormGroup>
                      ))}
                    </Col>
                  </React.Fragment>
                ) : null}
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => this.onSelectPreviousPage()}>
                {this.props.intl.formatMessage({ id: "back" })}
              </Button>
              <Button color="secondary" onClick={() => this.onSelectNextPage()}>
                {this.props.intl.formatMessage({ id: "next" })}
              </Button>
            </ModalFooter>
          </React.Fragment>
        ) : null}

        {this.state.currentPage > this.state.quantity ? (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Label for="total_price" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "total_price" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input type="text" name="total_price" id="total_price" disabled defaultValue={totalPrice} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="fee" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "fee" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input type="text" name="fee" id="fee" disabled defaultValue={transactionFee(totalPrice)} />
                    </Col>
                  </FormGroup>
                  {this.state.payment_method ? (
                    <FormGroup row>
                      <Col xs={12} sm={labelWidth} />
                      <Col xs={12} sm={inputWidth}>
                        <Alert
                          message={`${this.props.intl.formatMessage({
                            id: "remember",
                          })}!`}
                          description={this.props.intl.formatMessage({
                            id: "payment_performed_manually_2",
                          })}
                          type="warning"
                          showIcon
                        />
                      </Col>
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => this.onSelectPreviousPage()}>
                {this.props.intl.formatMessage({ id: "back" })}
              </Button>
              <Button color="secondary" onClick={() => this.onSubmit(transactionFee(totalPrice))}>
                {this.props.intl.formatMessage({ id: "confirm" })}
              </Button>
            </ModalFooter>
          </Form>
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.Users,
  authentication: state.Auth,
  fishing_cards: state.FishingCards,
  booking: state.Booking,
  customers: state.Customers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalOrderFishingCards)));
