export const FETCH_RIVER_OPTIONS = "FETCH_RIVER_OPTIONS";
export const FETCH_RIVER_OPTIONS_SUCCESS = "FETCH_RIVER_OPTIONS_SUCCESS";
export const FETCH_RIVER_OPTIONS_FAILURE = "FETCH_RIVER_OPTIONS_FAILURE";

export const EDIT_RIVER_CARD_LIMIT = "EDIT_RIVER_CARD_LIMIT";
export const EDIT_RIVER_CARD_LIMIT_SUCCESS = "EDIT_RIVER_CARD_LIMIT_SUCCESS";
export const EDIT_RIVER_CARD_LIMIT_FAILURE = "EDIT_RIVER_CARD_LIMIT_FAILURE";

export const EDIT_RIVER_OPTIONS = "EDIT_RIVER_OPTIONS";
export const EDIT_RIVER_OPTIONS_SUCCESS = "EDIT_RIVER_OPTIONS_SUCCESS";
export const EDIT_RIVER_OPTIONS_FAILURE = "EDIT_RIVER_OPTIONS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_RIVER_OPTIONS,
    payload
  };
}

export function editCardLimit(data, payload) {
  return {
    type: EDIT_RIVER_CARD_LIMIT,
    data,
    payload
  };
}

export function edit(data, payload) {
  return {
    type: EDIT_RIVER_OPTIONS,
    data,
    payload
  };
}
