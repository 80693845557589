export default {
  nb: {
    send_link: "Send lenken",
    password_forgot: "Mistet passordet ditt?",
    back_to_login: "Tilbake til logg inn side",
    old_password: "Gammelt passord",
    new_password: "Nytt passord",
    new_password_repeat: "Gjenta nytt passord",
    menu: "Meny",
    dashboard: "Dashboard",
    booking: "Booking",
    products: "Produkter",
    order: "Ordre",
    orders: "Ordre",
    registered_clients: "Kunderegister",
    beat: "Vald",
    all_beats: "Alle vald",
    edit: "Rediger",
    edit_beat: "Rediger vald",
    adverts: "Annonsering",
    reports: "Rapporter",
    fishing: "fiskekort",
    fishing_card: "Fiskekort",
    accommodation: "Overnatting",
    fishing_card_and_accommodation: "Fiskekort + Overnatting",
    menu_fishing_card: "Fiskekort",
    homepage: "Hjemmeside",
    back_to_dashboard: "Tilbake til hjemmeside",
    menu_accommodation: "Overnatting",
    my_information: "Mine opplysninger",
    first_name: "Fornavn",
    last_name: "Etternavn",
    phone: "Telefon",
    email: "E-post",
    address: "Adresse",
    post_code: "Postnummer",
    city: "Sted",
    country: "Land",
    birth_date: "Fødselsdato",
    cancel: "Avbryt",
    save: "Lagre",
    remove: "Slette",
    latitude: "Latitude",
    longitude: "Longitude",
    icon: "Ikon",
    delete: "Slette",
    change_password: "Endre passord",
    login: "Logg inn",
    send: "Send",
    username: "Brukernavn",
    password: "Passord",
    no_customers_found: "Ingen kunder funnet",
    no_packages_found: "Ingen pakker funnet",
    update_user: "Oppdater brukeren",
    logout: "Logg ut",
    is_user_active: "Brukeren er aktiv",
    yes: "Ja",
    no: "Nei",
    active: "Aktiv",
    user_role: "Brukerrolle",
    river_admin: "Elv admin",
    river_admin_access: "Administratorer for elva",
    river_administrator: "Elveadmin",
    beat_admin: "Vald admin",
    beat_administrator: "Valdadmin",
    beat_admin_access: "Administratorer for valdet",
    controller_administrator: "Oppsyn",
    station_admin: "Stasjon admin",
    member: "Medlem",
    more: "Mer",
    new_booking: "Ny booking",
    no_results: "Ingen resultater",
    new_user: "Ny bruker",
    user: "Bruker",
    new_package: "Ny pakke",
    select: "Velg",
    select_tax: "Velg MVA",
    user_not_exists: "Bruker finnes ikke",
    river_beat_panel: "Elv og Vald panel",
    beat_panel: "Vald panel",
    seller_panel: "Selger panel",
    select_river: "Velg elv",
    river: "Elv",
    rivers: "Elver",
    beats: "Vald",
    select_beat: "Velg vald",
    select_beat_from_fangstrapp: "Velg vald fra fangstrapp",
    select_fishing_spot: "Velg fiskeplass",
    select_filter: "Velg filter",
    fishing_spot_title: "Her kan du legge til, fjerne og endre på fiskeplassene i valdet.",
    fishing_spot_description: 'Trykk "Legg til", fyll inn navn, og trykke "Lagre" når du har lagt til alle fiskeplassene.',
    product_name: "Navn",
    name: "Navn",
    value: "Verdi",
    product_type: "Type",
    product_total_number: "Antall totalt",
    product_total_sold: "Antall solgte",
    no_products_found: "Ingen produkter funnet",
    new_product: "Ny produkt",
    add: "Legg til",
    add_availability: "Legg til tilgjengelighet",
    add_edit_availability: "Legg til / redigere tilgjengelighet",
    add_edit_package: "Legg til / redigere pakke",
    add_edit_fishing_package: "Legg til / redigere fiskekortpakke",
    add_edit_fishing_cards: "Legg til / redigere fiskekort",
    add_fishing_cards: "Legg til fiskekort",
    fishing_cards: "fiskekort",
    add_accommodation: "Legg til overnatting",
    edit_accommodation: "Redigere overnatting",
    available: "Tilgjengelige",
    reserved: "Reserverte",
    price: "Pris",
    extra_per_person: "Pris for ekstra personer",
    extra_per_person_short: "Ekstra personer",
    quantity: "Antall",
    date: "Dato",
    date_start: "Startdato",
    fishing_card_from_date: "Fra dato",
    fishing_card_to_date: "Til dato",
    date_end: "Sluttdato",
    publish_type_now_radiotext: "Legg ut for salg umiddelbart",
    publish_type_ondate_radiotext: "Legg ut for salg på en dato",
    publish_type_gradual_radiotext: "Gradvis publisering",
    publish_type_gradual_description: "Gradvis publisering",
    publish_type_header: "Publisering - Når skal produktene legges ut for salg?",
    publish_type_subtitle: "Når skal produktet legges ut for salg?",
    publish_type_day_count_label: "Antall dager",
    publish_type_hour_count_label: "Antall timer",
    publish_date_start: "Start salg",
    publish_date_end: "Stopp salg (valgfritt)",
    description: "Beskrivelse",
    capacity: "Kapasitet",
    select_accommodation: "Velg overnatting",
    back: "Tilbake",
    prices: "Priser",
    confirm_delete_question: "Er du sikker på at du vil slette dette?",
    confirm_cancel_order_question: "Er du sikker på at du vil avbryte denne ordre?",
    menu_packages: "Pakke",
    pack: "Pakke",
    packs: "Pakker",
    package_type: "Pakker typer",
    package_types: "Pakker typer",
    select_package_type: "Velg pakketype",
    select_package: "Velg pakke",
    add_package: "Legg til pakke",
    add_edit_packages: "Legg til / redigere pakker",
    remove_package: "Slette pakke",
    package_number_of_guests: "Antall gjester",
    package_fishing_cards_price: "Fiskekort pris",
    package_accommodation_price: "Overnatting pris",
    add_price: "Legg til pris",
    select_order_type: "Velg bestillingstype",
    pay_now: "Betal nå",
    payment_link: "Betalingskobling",
    no_orders_found: "Ingen ordre funnet",
    select_customer: "Velg kunde",
    add_new_customer: "Legg til ny kunde",
    new_customer: "Ny kunde",
    other_customers: "Andre kunder",
    first_and_last_name: "Fornavn og etternavn",
    order_status: "Ordre status",
    user_name: "Brukernavn",
    all_catches: "Alle fangster",
    report_submitted: "Fangstrapport levert",
    report_missing: "Mangler fangstrapport",
    filter_catch_reporting: "Filter - fangstrapportering",
    ALL: "Alle",
    PAID: "Betalt",
    PENDING: "Ubetalt",
    CANCELLED: "Avbrutt",
    customer: "Kunde",
    angler_name: "Navn på fisker",
    from_date: "Fra dato",
    to_date: "Til dato",
    todays_fisherman: "Dagens fiskere",
    todays_accommodation: "Dagens overnatting",
    last_five_catches: "Siste 5 fangster",
    catches: "Fangster",
    edit_catch: "Rediger fangst",
    catch_edit_history: "Redigeringshistorikk",
    catch_report_version: "Fangstrapportversjon",
    catch_report_edit_time: "Endringstidspunkt",
    all_versions_catch_edit_history: "Alle versjoner av rapport gitt av bruker",
    last_year: "i fjor",
    turnover: "Omsetning",
    number_of_salmon: "Antall laks",
    salmon_per_rod: "Laks per stang",
    last_orders: "Siste ordre",
    time: "Tid",
    fisherman: "Fisker",
    fisherman_id: "Fisker ID",
    filter: "Filter",
    anonymous_report: "Anonym melder",
    deleted_reports: "Slettede rapporter",
    edited_reports: "Redigerte rapporter",
    reported_on_ticket: "Rapportert på fiskekort",
    not_reported_on_ticket: "Ikke rapportert på fiskekort",
    fisher_name: "Fiskernavn",
    place: "Sted",
    weight: "Vekt",
    weight_constraints: "Vekt må være mellom 0 og 30kg.",
    from: "Fra",
    to: "Til",
    all_paid_orders: "Alle betalte ordre",
    all_orders: "Alle ordre",
    all_unpaid_orders: "Alle ubetalte ordre",
    no_paid_orders_found: "Ingen betalte ordre funnet",
    no_unpaid_orders_found: "Ingen ubetalte ordre funnet",
    no_catches_found: "Ingen fangst funnet",
    unpayed_orders: "Ubetalte ordre",
    settings: "Innstillinger",
    taxes: "MVA",
    percentage: "Prosentdel",
    commissions: "Provisjoner",
    beat_commissions: "Vald provisjoner",
    add_tax: "Legg til MVA",
    update_tax: "Oppdater MVA",
    new_tax: "Ny MVA",
    tax: "MVA",
    remove_tax: "Fjern MVA",
    no_taxes_found: "Ingen MVA funnet",
    full_commission: "Online salg",
    discounted_commission: "Manuelt salg",
    special_commission: "Faste Kunder",
    add_card_type: "Nytt fiskekort",
    edit_card_type: "Mine fiskekort",
    add_type: "Legg til type",
    edit_type: "Redigere type",
    select_card_type: "Velg fiskekorttype",
    select_card: "Velg fiskekort",
    card_id: "Fiskekort ID",
    fishing_card_id: "Fiskekort ID",
    package_id: "Fiskekort pakker ID",
    free_package_id: "Pakker ID",
    season_fishing_card_id: "Sesongkort ID",
    no_catch: "Ingen fangst",
    catch: "Fangst",
    no_catch_column: "Ble det fangst?",
    catch_edited: "Fangst redigert?",
    catch_is_edited: "Redigert",
    catch_not_edited: "Ikke redigert",
    reported_by: "Rapportert av",
    external_id: "Rapportert av",
    fishing_card_type: "Fiskekorttype",
    season_card_type: "Sesongkortttype",
    fishing_packages: "Fiskekortpakker",
    fishing_package: "Fiskekortpakke",
    delete_multiple_products_alert: "Du kan nå velge produkter som du vil slette.",
    confirm_deletion: "Bekreft sletting",
    delete_packages: "Slett pakker",
    delete_fishing_cards: "Slett fiskekort",
    delete_accommodation: "Slett overnatting",
    price_for_fishing_cards: "Fiskekort pris",
    price_for_accommodation: "Overnatting pris",
    next: "Neste",
    confirm: "Bekreft",
    total_price: "Totalpris",
    select_price: "Velg pris",
    no_options_found: "Ingen alternativer funnet",
    this_is_my_customer: "Dette er min tidligere kunde",
    fee: "Gebyr",
    street: "Gate",
    organization_account_number: "Kontonummer",
    organization_address: "Organisasjonsadresse",
    organization_name: "Organisasjonsnavn",
    organization_number: "Organisasjonsnummer",
    organization_phone: "Organisasjonstelefon",
    account_number: "Kontonummer",
    all: "Alle",
    beat_card_limit: "Vald kortgrenser",
    river_card_limit: "Elv kortgrenser",
    card_limit: "Kortgrenser",
    turn_on_river_card_limit: "Kortgrenser for elv",
    turn_on_beat_card_limit: "Kortgrenser for vald",
    nothing_found: "Ingenting funnet",
    equipment: "Redskap",
    select_equipment: "Velg redskap",
    select_fish_type: "Velg fisketype",
    new_fish_type: "Ny fisketype",
    fish_type: "Fisketype",
    fish_types: "Fisketyper",
    type: "Type",
    select_fish_sex: "Velg kjønn",
    sex: "Kjønn",
    length: "Lengde",
    lice: "Lus",
    anonymous: "Anonym",
    removed: "Fjernet",
    select_lice_count: "Hvor mange lus",
    cr: "Gjenutsatt",
    released_catch: "Gjenutsatt",
    male: "Hann",
    female: "Hunn",
    boat: "Båt",
    fettfinne: "Fettfinneklippet",
    adipose_fin_removed: "Fettfinneklippet",
    delivered: "Innlevert",
    scalesample: "Skjellprøve",
    scale_sample: "Skjellprøve",
    on_quota: "Tatt på kvote",
    damaged: "Avlivet grunnet skade/blødning",
    farming: "Oppdrett",
    species: "Art",
    field: "Felt",
    site_not_found: "Fant ikke siden",
    code: "Kode",
    cancel_order: "Avbryt ordre",
    platform_reports: "Samlede rapporter",
    tax_code: "MVA kode",
    tax_percentage: "MVA prosent",
    revenue: "Inntekter",
    revenue_gross: "Inntekter - Brutto",
    revenue_tax: "Inntekter - MVA",
    provision: "Provisjon",
    provision_tax: "Provisjon - MVA",
    provision_percentage: "Provisjonsprosent ink.MVA",
    payment_to_customer: "Betaling til kunde",
    payment_to_customer_online: "Betaling til kunde - Online salg",
    payment_to_customer_manual: "Fakturagrunnlag provisjon",
    stations: "Stasjoner",
    new_station: "Ny stasjon",
    update_station: "Oppdater stasjon",
    staff: "Personnel",
    no_stations_found: "Ingen stasjoner funnet",
    disinfection: "Desinfeksjon",
    disinfections: "Desinfeksjoner",
    no_disinfections_found: "Ingen desinfeksjoner funnet",
    station: "Stasjon",
    transaction_fees: "Transaksjonsgebyrer",
    transaction_fees_manual_payment: "Transaksjonsgebyrer (betalt kontant)",
    transaction_fees_terminal_payment: "Transaksjonsgebyrer (bankterminal)",
    transaction_fees_online_payment: "Transaksjonsgebyrer (online)",
    search: "Søk",
    company_information: "Firmainformasjon",
    summary: "Sammendrag",
    sum: "Sum",
    net_revenue: "Inntekter - Netto",
    download: "Last ned",
    station_panel: "Stasjon panel",
    select_station: "Velg stasjon",
    station_admins: "Stasjon administratorer",
    no_data_found: "Ingen data funnet",
    add_new_disinfection: "Legg til ny desinfeksjon",
    new_disinfection: "Ny desinfeksjon",
    customer_id: "Kunde-ID",
    allow_booking_after_start_date: "Tillat bestilling etter startdato",
    search_by_id_or_name: "Søk etter ID eller navn",
    search_by_id_name_or_email: "Søk etter ID, navn eller epost",
    seasoncard: "Sesongkort",
    season_card: "Sesongkort",
    season_cards: "Sesongkort",
    season: "Sesong",
    select_block_status: "Velg blokkstatus",
    blocked: "Blokkert",
    not_blocked: "Ikke blokkert",
    remember: "Husk",
    payment_performed_manually: "Betaling med kontant",
    payment_performed_manually_2: "Ta i mot betalingen før du trykker Bekreft",
    order_confirmed: "Ordre bekreftet",
    products_nets: "Online",
    products_terminal: "Bankterminal",
    products_manual: "Betalt kontant",
    revenue_online_payment: "Inntekter - Online",
    revenue_terminal_payment: "Inntekter - Bankterminal",
    revenue_manual_payment: "Inntekter - Betalt kontant",
    provision_online_payment: "Provisjon - Online",
    provision_terminal_payment: "Provisjon - Bankterminal",
    provision_manual_payment: "Provisjon - Betalt kontant",
    invoice_to_customer: "Fakturagrunnlag - Provisjon betalt kontant",
    economic_transaction_fee_manual: "Fakturagrunnlag - Transaksjonsgebyr betalt kontant",
    economic_total_payout: "Sum utbetaling",
    intermediary_of_service: "Formidler av tjeneste:",
    telephone: "Telefon",
    email_pdf: "Epost",
    downloaded: "Nedlastet",
    terminal: "Terminal",
    cash: "Kontant",
    flexible_time: "Fleksibelt starttidspunkt",
    select_start_dates: "Velg starttidspunkt for fiskekort",
    user_block: "Brukerblokk",
    "equipment-fly": "Flue",
    "equipment-worm": "Mark",
    "equipment-lure": "Sluk",
    "equipment-wobbler": "Wobbler",
    "equipment-dabbler-fly": "Dupp og flue",
    "equipment-other": "Annet",
    "equipment-spinner": "Spinner",
    "equipment-shrimp": "Reke",
    "equipment-fleet": "Flåte",
    "equipment-fly-weight": "Flue og søkke",
    "equipment-salmon-trap": "Teine",
    "equipment-gip": "Gip",
    "equipment-drift-net": "Drivgarn",
    "equipment-maelkast": "Mælkast",
    "equipment-unknown": "Ukjent",
    "fish_type-salmon": "Laks",
    "fish_type-trout": "Ørret",
    "fish_type-seatrout": "Sjøørret",
    "fish_type-other": "Annet",
    "fish_type-farmed-salmon": "Oppdrettslaks",
    "fish_type-pink-salmon": "Pukkellaks",
    "fish_type-brown-trout": "Brunørret",
    "fish_type-eel": "Ål",
    "fish_type-flounder": "Flyndre",
    "fish_type-sea-char": "Sjørøye",
    "fish_type-stoing": "Støing",
    "fish_type-char": "Røye",
    "fish_type-rainbow-trout-farmed": "Regnbueørret/oppdrett",
    remove_field_description: "Merk som 'Fjernet' for å skjule fangsten fra Laksebørs",
    select_reportby: "Velg periode",
    report_weeks: "Uke",
    report_days: "Dag",
    report_by_product_count: "Antall Solgte Produkter",
    beat_product_count: "Antall Solgte Produkter",
    total_sold_products: "Totalt solgte produkter",
    day: "Dag",
    week: "Uke",
    sold_total: "Salg totalt",
    beat_product_type_price: "Salgsrapport - Produktgrupper",
    economy_beat_reports: "Økonomirapport vald",
    all_beats_economy_report: "Økonomirapport alle vald",
    seller_payment_method: "Salgsrapport - Selgere",
    sales_method: "Salgsmetode",
    web_sale: "Web-salg",
    report_salesman_nets_manual: "Bankterminal",
    report_salesman_manual: "Kontant",
    report_salesman_nets: "Nets Paymentlink",
    report_salesman_total_sale: "Totalt salg",
    payment_methods_sum: "SUM",
    report_salesman_method_nets_manual: "Bankterminal",
    report_salesman_method_manual: "Kontant",
    report_salesman_method_nets: "Nets Betalingslink",
    apply: "Søke om",
    date_start_order_date: "Start (ordredato)",
    date_end_order_date: "Slutt (ordredato)",
    marker: "Punkt",
    polygon: "Område",
    general: "Generell",
    regulations: "Regler",
    images: "Bilder",
    map: "Kart",
    county: "Fylke",
    content: "Innhold",
    title: "Tittel",
    information: "Informasjon",
    contact_person: "Kontaktperson",
    contact_phone: "Telefon",
    contact_email: "Epost",
    river_length: "Elvens lengde (i km)",
    optional_page: "Valgfri side",
    featured_image: "Framhevet bilde",
    image_gallery: "Bildegalleri",
    payment_successful: "Betalingen var vellykket",
    problems_with_payment: "Problemer med betaling",
    payment_error_1002: "Transaksjon ikke funnet, allerede behandlet eller utløpt!",
    payment_error_1003: "Noe gikk galt! Betalingen er ikke behandlet.",
    link: "Lenke",
    add_price_type: "Legg til pristype",
    price_types: "Pristyper",
    warning: "Advarsel",
    you_need_to_update_pricetype: "Du må oppdatere pristyper på produkttype.",
    add_marker: "Legg til punkt",
    add_polygon: "Legg til område",
    marker_default: "Standard",
    marker_accommodation: "Overnatting",
    marker_parking: "Parkering",
    marker_bivouac: "Gapahuk",
    marker_campfire_spot: "Bålplass",
    marker_fishing_net: "Fiskeplass",
    marker_tent_site: "Teltplass",
    marker_toilet: "Toalett",
    seo: "SEO",
    additional_information: "Tilleggsinformasjon",
    hide_from_crawlers: "Skjul for søkemotorer",
    select_county: "Velg fylke",
    beat_length: "Valds lengde (i meter)",
    other_facilities: "Øvrige fasiliteter",
    about_us: "Om oss",
    fishing_types_and_facilities: "Fiske og Fasiliteter",
    fishing_types: "Fiske",
    "flytype-fluefiske": "Fluefiske",
    "flytype-markfiske": "Markfiske",
    "flytype-slukfiske": "Slukfiske",
    "flytype-wobbler": "Wobbler",
    "flytype-batfiske": "Båtfiske",
    facilities: "Fasiliteter",
    "facility-balplass": "Bålplass",
    "facility-bat": "Båt",
    "facility-desinfisering": "Desinfisering",
    "facility-dusj": "Dusj",
    "facility-fryser": "Fryser",
    "facility-gapahuk": "Gapahuk",
    "facility-grill": "Grill",
    "facility-guide": "Guide",
    "facility-handicapvennlig": "Handicapvennlig",
    "facility-hav": "Håv",
    "facility-henting-pa-flyplass": "Henting på flyplass",
    "facility-kjop-leie-av-fiskeutstyr": "Kjøp / Leie av fiskeutstyr",
    "facility-moterom": "Møterom",
    "facility-overnatting": "Overnatting",
    "facility-parkering": "Parkering",
    "facility-servering": "Servering",
    "facility-skilt-til-elva": "Skilt til elva",
    "facility-sti-til-elva": "Sti til elva",
    "facility-tv-stue": "TV-stue",
    "facility-utedo": "Utedo",
    "facility-vaskerom": "Vaskerom",
    "facility-wifi": "Wifi",
    add_center_point: "Legg til midtpunkt",
    add_center_points_first: "Legg til midtpunkter med midtpunktknappen over.",
    add_new_river: "Ny elv",
    add_new_beat: "Nytt vald",
    published: "Publisert",
    draft: "Kladd",
    trash: "Papirkurv",
    future: "Framtid",
    articles: "Artikler",
    status: "Status",
    language: "Språk",
    publish_date: "Publiseringsdato",
    add_new_article: "Ny artikkel",
    add_new_page: "Ny side",
    excerpt: "Utdrag",
    image_and_video: "Bilde og video",
    image: "Bilde",
    categories: "Kategorier",
    header_type: "Type",
    youtube_link: "YouTube lenke",
    vimeo_link: "Vimeo lenke",
    edit_category: "Rediger kategori",
    add_category: "Ny kategori",
    active_on_web: "Aktiv på nettet?",
    active_on_laksebors: "Aktiv på Laksebørs?",
    additional_settings: "Tilleggsinnstillinger",
    fishing_spot: "Fiskeplass",
    hide_unused_tabs: "Skjul ubrukte kolonner",
    image_must_be_smaller: "Bildet må være mindre enn",
    you_can_only_upload_jpg_png_files: "Du kan bare laste opp JPG/PNG filer.",
    select_orders_by: "Velg ordre av",
    product_date: "Produktdato",
    order_date: "Bestillingsdato",
    fishing_card_date: "Fiskekort dato",
    filter_ticket_type: "Filter - fiskekort type",
    buyer_name: "Kjøper navn",
    buyer_address: "Kjøper adresse",
    buyer_country: "Kjøper land",
    buyer_phone: "Kjøper telefon",
    buyer_email: "Kjøper e-post",
    order_id: "Ordre ID",
    price_type: "Pristype",
    price_amount: "Beløp",
    amount: "Beløp",
    seller: "Selger",
    is_in_period_card: "Er i periodekort?",
    period_card_type: "Periodekorttype",
    period_card_price_type: "Periodekort pristype",
    period_card_price_amount: "Periodekort beløp",
    unknown: "Ukjent",
    add_extra_service: "Legg til ekstra tjeneste",
    edit_extra_service: "Redigere ekstra tjeneste",
    select_extra_service: "Velg ekstra tjeneste",
    extra_service: "Ekstra tjeneste",
    extra_services: "Ekstra tjenester",
    member_only: "Kun for medlemmer",
    members_only: "Kun for medlemmer",
    add_edit_price: "Legg til / rediger pris",
    add_edit_dates: "Legg til / rediger datoer",
    select_year: "Velg år",
    show_also_for_members: "Vis kort tilgjengelig for medlemmer også",
    ok: "OK",
    not_ok: "Ikke OK",
    detailed_overview: "Detaljert oversikt",
    details: "Detaljer",
    user_blocked_cards: "Bruker blokkert av kort",
    ban_user: "Sperre bruker fra kjøp",
    member_of_beat: "Medlem av vald",
    new_catch: "Ny fangst",
    register_catch: "Registrer fangst",
    pages: "Sider",
    newsfeed: "Nyhetsstrøm",
    header: "Overskrift",
    created: "Opprettet",
    activity_title: "Tittel aktivitet",
    preamble: "Ingress",
    sender: "Avsender",
    redirect_link: "Link",
    open_reporting: "Åpen fangstrapportering",
    open_reporting_description: "betyr at fiskeren kan velge om fangsten må knyttes til et fiskekort eller ikke",
    simple: "Enkel fangstraportering",
    simple_description:
      "betyr at fangsten ikke skal knyttes til fiskekort, og at elv og vald må fylles inn manuelt av fiskeren.",
    card_connected: "Fangstrapportering tilknyttet kort",
    card_connected_description: "betyr at fangsten må knyttes til et fiskekort.",
    catch_report_option: "Kryss av om fangsrapportering er påkrevd i elva.",
    catch_report_type: "Type fangsrapportering",
    catch_report_status: "Fangst registrert",
    fishing_effort: "Fiskeinnsats",
    did_not_use: "Ikke brukt",
    required_catch_fields: "Påkrevde fangstfelt",
    time_of_day: "Tid",
    equipment_id: "Redskap",
    new_equipment: "Ny redskap",
    fish_type_id: "Fisketype",
    lice_count: "Hvor mange lus",
    name_no: "Navn (NO)",
    name_en: "Navn (EN)",
    obligatory_fields_alert_title: "Obligatoriske felt for rapport til Fangstrapp.no",
    obligatory_1: "Dato",
    obligatory_2: "Art",
    obligatory_3: "Vekt",
    obligatory_4: "Lengde",
    obligatory_5: "'Avlivet' eller 'Gjenutsatt",
    additional_required_fields_text:
      "Velg hva fiskeren skal rapportere utover de obligatoriske feltene ved å huke av boksen og trykke Lagre.",
    required_fields_if_not_released_text: "Følgende felter aktiveres kun om fangsten er avlivet.",
    deny_anonymous_catch_report: "Forby anonyme fangstrapporter",
    controller_user: "Oppsyn",
    controller_user_access: "Oppsyn i elva",
    controller_panel: "Oppsyn panel",
    card_changes: "Kortendringer",
    select_type: "Velg type",
    search_by_order_id_or_fcid: "Søk etter Ordre ID eller FCID",
    owner_name: "Eier",
    free_package: "Pakke",
    actions: "Handlinger",
    season_fishing_card: "Sesongkort",
    edit_owner: "Rediger eier",
    remove_from_order: "Fjerne fra ordren",
    card_will_be_refunded: "Kjøpssummen for kortet vil bli refundert til kjøperen.",
    only_fishing_cards_refunds:
      "Bare fiskekort blir automatisk refundert til kjøperen, øvrige produkter må refunderes manuelt.",
    product_will_not_be_refunded: "Kjøpssummen for produktet vil ikke bli refundert til kjøperen.",
    confirm_removing_card_from_order_question: "Fjern kortet fra ordren?",
    catch_limits: "Fangstgrenser",
    beat_list: "Valdliste",
    all_documents_ok: "All dokumentasjon OK?",
    all_documents_are_ok: "All dokumentasjon OK",
    documents_missing: "Dokumentasjon mangler",
    add_comment: "Legg til en kommentar",
    show_comments: "Vis kommentarer",
    controller_products: "Kontrollert av oppsyn",
    select_comment_status: "Velg kontrollert av oppsyn",
    supervisor_dashboard: "Oppsyn dashboard",
    required_catch_report: "Påkrevd fangstrapport",
    catch_report_settings: "Fangstrapportinnstillinger",
    current_link: "Gjeldende lenke",
    add_attachments_to_billings: "Legg til bilag i mine bilag",
    my_billings: "Mine bilag",
    report_created_at: "Rapport opprettet",
    submit_report: "Sende inn rapporten",
    zones: "Soner",
    add_new_zone: "Legg til ny sone",
    edit_zone: "Rediger sone",
    remove_zone: "Slette sone",
    confirm_removing: "Bekreft fjerning",
    only_superadmin_can_remove_zones: "Bare superadministratorer kan slette soner.",
    about_beat: "Om valdet",
    valid_from_hours: "Gyldig fra (klokkeslett)",
    validity_duration_in_hours: "Gyldighet (timer)",
    net_sales: "Netto salgsinntekt",
    net_provision: "Netto provisjon",
    products_sold: "Antall solgte produkter",
    number_of_catches: "Antall fangst",
    counties: "Fylker",
    top_rivers: "Topp elver",
    number_of_fishermen: "Antall fiskere",
    river_card_stats: "Elvekortstatistikk",
    number_of_cards_total: "Antall totalt",
    number_of_cards_norway: "Antall i Norge",
    number_of_cards_abroad: "Antall i utlandet",
    number_of_disinfections: "Antall desinfiseringer",
    controlled_by_controller: "Kontrollert av oppsyn",
    view_all: "Se alle",
    catch_statistics: "Fangststatistikk",
    all_species: "Totalt alle arter",
    killed: "Avlivet",
    kg_total: "kg totalt",
    kg_avg_weight: "kg snittvekt",
    last_payment: "Siste utbetaling",
    number_of_cards_sold: "Antall solgte fiskekort",
    number_of_season_cards_sold: "Antall solgte sesongkort",
    fishing_pressure: "Fisketrykk",
    cards_sold: "Antall solgte fiskekort",
    catch_reports: "Antall fiskekort med fangstrapport",
    fishing_days:"Antall fiskedager",
    fishermen: "Fiskere",
    recent_catches: "Siste fangster",
    active_fishing_cards: "Aktive fiskekort",
    active_season_cards: "Aktive sesongkort",
    river_cards: "Elvekort",
    add_river_card: "Legg til nytt elvekort",
    sold_river_cards: "Solgte elvekort",
    are_you_sure: "Er du sikker?",
    river_commissions: "Elv provisjoner",
    river_card: "Elvekort",
    river_card_title: "Tittel på kort tilknyttet elv",
    river_card_all_cards: "Alle kort",
    river_cards_expired: "Utløpte kort",
    river_cards_active: "Aktive kort",
    see_picture_below: "Se bildet under.",
    buy_river_card: "Kjøp elvekort",
    survey: "Undersøkelse",
    old: "Gamle",
    send_survey_1: "Send undersøkelse 1",
    survey_text:
      'Undersøkelsen kan bare sendes én gang per elvekort. Send den kun på slutten av en sesong. Etter å ha klikket "Send" vil du ikke kunne sende den igjen.',
    sent_on: "Sendt på",
    number_of_sent_survey_invites: "Antall sendte undersøkelsesinvitasjoner",
    responded_survey_invites: "Antall svarte på undersøkelsesinvitasjoner",
    survey_not_sent: "Undersøkelsen ikke sendt",
    survey_not_responded: "Undersøkelsen ikke svart",
    report_all_rivers: "Alle elver",
    send_email: "Mailutsendelse",
    limit: "Grense",
    year_of_purchase: "Kjøpsår",
    type_1: "Døgnkort",
    send_to: "Send til",
    users: "Brukere",
    buy_more: "Kjøp mer",
    number_of_blocks: "Antall blokker",
    each_block_is_credit_for_500_emails: "Hver blokk er en kreditt for 500 e-poster. En blokk koster 250 NOK.",
    confirmation_on_new_orders: "Motta bekreftelse på nye ordrer",
    history: "Historie",
    change_made_by: "Endring laget av",
    changed_value: "Endret verdi",
    old_value: "Gammel verdi",
    new_value: "Ny verdi",
    user_id: "Bruker ID",
    river_card_commissions: "Elvekort provisjoner",
    send_final_survey: "Send undersøkelse 2",
    survey_1: "Midtsesong undersøkelse",
    final_survey: "Etter sesong undersøkelse",
    survey_message: "Undersøkelsesmelding",
    change_date: "Rediger dato",
    year: "År",
    refunds: "Refundert",
    refund_tax: "Refundert MVA",
    revenue_total: "Innbetalinger totalt (online + terminal + kontant)",
    remove_refund: "Fjern tilbakebetaling",
    comment: "Kommentar",
    new_refund: "Ny tilbakebetaling",
    laksebors_message: "Laksebørs melding",
    river_orders: "Elv",
    beat_orders: "Vald",
    card_type: "Korttype",
    show_on_laksebors: "Vis fangststatistikk på Laksebørs?",
    this_order_has_other_products_besides_river_cards: "Denne bestillingen inneholder andre produkter bortsett fra elvekort.",
    this_order_has_other_products_besides_beat_products:
      "Denne bestillingen inneholder andre produkter bortsett fra vald produkter.",
    daycard: "Døgnkort",
    weekcard: "Ukeskort",
    reported_on: "Rapporterte klokka",
    members: "Medlemmer",
    members_of_beat: "Medlemmer av vald",
    refund_commission: "Refundert provisjon",
    refund_commission_tax: "Refundert provisjon MVA",
    cancellation_policy: "Avbestillingsvilkår",
    cancellation_policy_description_1:
    "Her definerer du refusjonsregler for fiskekort kjøpt i ditt vald. Denne informasjonen blir vist på valdsiden din.",
    cancellation_policy_description_2:
        "Du spesifiser antall dager før fiskekortets start det skal være mulig å refundere. Brukeren kan selv håndtere refusjon, dersom fiskekortet kan refunderes etter reglene som gjaldt ved kjøpetidspunktet.",
    cancellation_policy_description_3:
      "For eksempel betyr 3 dager at kunden kan avbestille inntil 3 dager før fiskekortet starter. Et fiskekort for 9. juni, må kanselleres før 6. juni, altså ikke senere enn 5. juni kl. 23:59.",
    cancellation_policy_description_4:
      "Dersom du ikke ønsker å tilby refusjon, kan du sette 0 dager.",
    cancellation_policy_set_terms: "Sett vilkår",
    cancellation_policy_full_refund_limit: "Grense for 100% refusjon",
    cancellation_policy_no_policy_set: "Foreløpig ingen vilkår for refusjon satt.",
    cancellation_policy_current_terms: "Gjeldende vilkår for",
    cancellation_policy_product: "Produkt",
    cancellation_policy_refund: "Refusjon",
    cancellation_policy_number_of_days: "Antall dager",
    days: "dager",
    currency: "Valuta",
    options: "Valg",
    timezone: "Tidssone",
    adminBeatNotificationWarningText:
      "Vald trenger opplysninger om kunde for å kunne selge produkter. Gå til Innstillinger → Vald for å fylle ut opplysningene",
    adminNotificationWarningTextPre:
      "Vald trenger opplysninger om kunde for å kunne selge produkter. Vald uten nødvendige opplysninger: ",
    adminNotificationWarningTextPost: "Gå til Innstillinger → Vald for å fylle ut opplysningene",
    explanation: "Forklaring",
    gradual_publish_explanation:
      "Med dette valget vil fiskekortene bli gradvis lagt ut for salg ut fra når de er gyldige. Fyll ut hvor mange dager/timer i forkant av gyldigheten publiseringen skal skje.",
    equipment_configuration: "Redskapskonfigurasjon",
    defaultEquipment: "Standard utstyr",
    selling_beats: "Salgsvald",
    choose_beats: "Vald vald",
    unblock: "Fjern blokkering",
    help_text: "Hjelp",
    scribe_create_fishing_ticket: "Opprette fiskekort",
    scribe_publish_fishing_tickets: "Publisere fiskekort",
    scribe_edit_fishing_tickets: "Endre fiskekort",
    scribe_delete_fishing_tickets: "Slett fiskekort",
    scribe_edit_river_cards: "Endre navn på elvekortet",
    scribe_publish_river_cards: "Publisere elvekort",
    scribe_about_beat: "Om valdet",
    scribe_about_us: "Om oss",
    scribe_other_facilities: "Andre fasiliteter",
    scribe_accommodation: "Overnatting",
    scribe_images: "Bilder",
    scribe_map: "Kart",
    scribe_optional_page: "Valgfri side",
    scribe_fishing_regulations: "Fiskeregler",
    scribe_catches_message: "Melding på Laksebørs",
    scribe_catches_disinfection: "Desinfisering",
    scribe_customer_register_beat: "Kunderegister vald",
    scribe_customer_register_river: "Kunderegister elv",
    scribe_customer_blocked_users: "Blokkerte brukere",
    scribe_customer_add_bailiff: "Legg til oppsyn",
    scribe_customer_add_station_admin: "Legg til stasjonsadministrator",
    scribe_customer_how_to_block_user: "Hvordan blokkere bruker",
    scribe_customer_how_to_check_blocked_user: "Hvordan sjekke blokkerte brukere",
  },

  en: {
    send_link: "Send the link",
    password_forgot: "Lost your password?",
    back_to_login: "Back to login page",
    old_password: "Old password",
    new_password: "New password",
    new_password_repeat: "Repeat new password",
    menu: "Menu",
    dashboard: "Dashboard",
    booking: "Booking",
    products: "Products",
    order: "Order",
    orders: "Order",
    registered_clients: "Customer register",
    beat: "Beat",
    all_beats: "All beats",
    edit: "Edit",
    edit_beat: "Edit beat",
    adverts: "Advertising",
    reports: "Reports",
    fishing: "fishing tickets",
    fishing_card: "Fishing tickets",
    accommodation: "Accommodation",
    fishing_card_and_accommodation: "Fishing ticket + Accommodation",
    menu_fishing_card: "Fishing tickets",
    homepage: "Home page",
    back_to_dashboard: "Back to homepage",
    menu_accommodation: "Accommodation",
    my_information: "My information",
    first_name: "First Name",
    last_name: "Last Name",
    phone: "Phone",
    email: "Email",
    address: "Address",
    post_code: "Postcode",
    city: "Location",
    country: "Country",
    birth_date: "Birth date",
    cancel: "Cancel",
    save: "Save",
    remove: "Delete",
    latitude: "Latitude",
    longitude: "Longitude",
    icon: "Icon",
    delete: "Delete",
    change_password: "Change Password",
    login: "Log in",
    send: "Send",
    username: "Username",
    password: "Password",
    no_customers_found: "No customers found",
    no_packages_found: "No packages found",
    update_user: "Update user",
    logout: "Log out",
    is_user_active: "The user is active",
    yes: "Yes",
    no: "No",
    active: "Active",
    user_role: "User role",
    river_admin: "River admin",
    river_administrator: "River admin",
    river_admin_access: "Administrators for the river",
    beat_admin: "Beat admin",
    beat_administrator: "Beat admin",
    beat_admin_access: "Administrators for the beat",
    controller_administrator: "Bailiff",
    station_admin: "Station admin",
    member: "Member",
    more: "More",
    new_booking: "New booking",
    no_results: "No results",
    new_user: "New User",
    user: "User",
    new_package: "New Package",
    select: "Select",
    select_tax: "Select VAT",
    user_not_exists: "User does not exist",
    river_beat_panel: "River and Beat panel",
    beat_panel: "Beat panel",
    seller_panel: "Seller Panel",
    select_river: "Select River",
    river: "River",
    rivers: "Rivers",
    beats: "Beats",
    select_beat: "Select beat",
    select_beat_from_fangstrapp: "Select beat from fangstrapp",
    select_fishing_spot: "Select fishing spot",
    select_filter: "Select filter",
    fishing_spot_title: "Here you can add, remove or edit fishing spots on your beat.",
    fishing_spot_description: 'Press "Add", enter name, and press "Save" when you have added all fishing spots',
    product_name: "Name",
    name: "Name",
    value: "Value",
    product_type: "Type",
    product_total_number: "Total number",
    product_total_sold: "Number sold",
    no_products_found: "No products found",
    new_product: "New Product",
    add: "Add",
    add_availability: "Add Availability",
    add_edit_availability: "Add/Edit Availability",
    add_edit_package: "Add/edit package",
    add_edit_fishing_package: "Add / edit ticket package",
    add_edit_fishing_cards: "Add / edit tickets",
    add_fishing_cards: "Add tickets",
    fishing_cards: "tickets",
    add_accommodation: "Add accommodation",
    edit_accommodation: "Edit accommodation",
    available: "Available",
    reserved: "Reserved",
    price: "Price",
    extra_per_person: "Price for extra persons",
    extra_per_person_short: "Extra persons",
    quantity: "Quantity",
    date: "Date",
    date_start: "Start Date",
    fishing_card_from_date: "From date",
    fishing_card_to_date: "To Date",
    date_end: "End Date",
    publish_type_now_radiotext: "Post for sale now",
    publish_type_ondate_radiotext: "Post for sale on a date",
    publish_type_gradual_radiotext: "Gradual publishing",
    publish_type_gradual_description: "Gradual publishing",
    publish_type_header: "Publication - When will the products be put up for sale?",
    publish_type_subtitle: "When will the product go on sale?",
    publish_type_day_count_label: "Number of days",
    publish_type_hour_count_label: "Number of hours",
    publish_date_start: "Start sale",
    publish_date_end: "Stop sale (optional)",
    description: "Description",
    capacity: "Capacity",
    select_accommodation: "Select accommodation",
    back: "Back",
    prices: "Prices",
    confirm_delete_question: "Are you sure you want to delete this?",
    confirm_cancel_order_question: "Are you sure you want to cancel this order?",
    menu_packages: "Package",
    pack: "Package",
    packs: "Packages",
    package_type: "Package types",
    package_types: "Package types",
    select_package_type: "Select package type",
    select_package: "Select Package",
    add_package: "Add Package",
    add_edit_packages: "Add / edit packages",
    remove_package: "Remove package",
    package_number_of_guests: "Number of guests",
    package_fishing_cards_price: "Fishing ticket price",
    package_accommodation_price: "Accommodation price",
    add_price: "Add price",
    select_order_type: "Select order type",
    pay_now: "Pay now",
    payment_link: "Payment Link",
    no_orders_found: "No orders found",
    select_customer: "Select customer",
    add_new_customer: "Add new customer",
    new_customer: "New customer",
    other_customers: "Other customers",
    first_and_last_name: "First name and last name",
    order_status: "Order status",
    user_name: "Username",
    all_catches: "All catches",
    report_submitted: "Report submitted",
    report_missing: "Missing catch report",
    filter_catch_reporting: "Filter - catch reporting",
    ALL: "Everyone",
    PAID: "Paid",
    PENDING: "Unpaid",
    CANCELLED: "Cancelled",
    customer: "Customer",
    angler_name: "Angler name",
    from_date: "From date",
    to_date: "To date",
    todays_fisherman: "Today's Anglers",
    todays_accommodation: "Today's accommodation",
    last_five_catches: "Last 5 catches",
    catches: "Catches",
    edit_catch: "Edit catch",
    catch_edit_history: "Catch edit history",
    catch_report_version: "Catch report version",
    catch_report_edit_time: "Changed at time",
    all_versions_catch_edit_history: "All versions of report given by user",
    last_year: "last year",
    turnover: "Turnover",
    number_of_salmon: "Number of salmon",
    salmon_per_rod: "Salmon per rod",
    last_orders: "Last order",
    time: "Time",
    fisherman: "Name of Angler",
    fisherman_id: "Angler ID",
    filter: "Filter",
    anonymous_report: "Anonymous reports",
    deleted_reports: "Deleted reports",
    edited_reports: "Edited reports",
    reported_on_ticket: "Reported on ticket",
    not_reported_on_ticket: "Not reported on ticket",
    fisher_name: "Angler Name",
    place: "Place",
    weight: "Weight",
    weight_constraints: "Weight must be between 0 and 30kg.",
    from: "From",
    to: "To",
    all_paid_orders: "All paid orders",
    all_orders: "All orders",
    all_unpaid_orders: "All unpaid orders",
    no_paid_orders_found: "No paid orders found",
    no_unpaid_orders_found: "No unpaid orders found",
    no_catches_found: "No catch found",
    unpaid_orders: "Unpaid orders",
    settings: "Settings",
    taxes: "VAT",
    percentage: "Percentage",
    commissions: "Commissions",
    beat_commissions: "Beat commissions",
    add_tax: "Add VAT",
    update_tax: "Update VAT",
    new_tax: "New VAT",
    tax: "VAT",
    remove_tax: "Remove VAT",
    no_taxes_found: "No VAT found",
    full_commission: "Online sales",
    discounted_commission: "Manual Sales",
    special_commission: "Regular Customers",
    add_card_type: "New tickets",
    edit_card_type: "My tickets",
    add_type: "Add type",
    edit_type: "Edit type",
    select_card_type: "Select ticket type",
    select_card: "Select tickets",
    card_id: "Ticket ID",
    fishing_card_id: "Ticket ID",
    package_id: "Ticket package ID",
    free_package_id: "Package ID",
    season_fishing_card_id: "Season ticket ID",
    no_catch: "No catch",
    catch: "Catch",
    no_catch_column: "Was there a catch?",
    catch_edited: "Catch edited?",
    catch_is_edited: "Edited",
    catch_not_edited: "Not edited",
    reported_by: "Reported by",
    external_id: "Reported by",
    fishing_card_type: "Ticket type",
    season_card_type: "Season ticket Type",
    fishing_packages: "Ticket packages",
    fishing_package: "Ticket package",
    delete_multiple_products_alert: "You can now select products to delete.",
    confirm_deletion: "Confirm deletion",
    delete_packages: "Delete packages",
    delete_fishing_cards: "Delete tickets",
    delete_accommodation: "Delete accommodation",
    price_for_fishing_cards: "Ticket price",
    price_for_accommodation: "Accommodation price",
    next: "Next",
    confirm: "Confirm",
    total_price: "Total price",
    select_price: "Select Price",
    no_options_found: "No options found",
    this_is_my_customer: "This is my previous customer",
    fee: "Fee",
    street: "Street",
    organization_account_number: "Account number",
    organization_address: "Organization Address",
    organization_name: "Organization Name",
    organization_number: "Organization number",
    organization_phone: "Organization phone",
    account_number: "Account number",
    all: "All",
    beat_card_limit: "Choose card limits",
    river_card_limit: "River Card Limits",
    card_limit: "Card Limits",
    turn_on_river_card_limit: "Ticket limits for river",
    turn_on_beat_card_limit: "Ticket Limits for Beat",
    nothing_found: "Nothing found",
    equipment: "Equipment",
    select_equipment: "Select equipment",
    select_fish_type: "Select fish type",
    new_fish_type: "New fish type",
    fish_type: "Fish type",
    fish_types: "Fish types",
    type: "Type",
    select_fish_sex: "Select Gender",
    sex: "Gender",
    length: "Length",
    lice: "Lice",
    anonymous: "Anonymous",
    removed: "Removed",
    select_lice_count: "How many lice",
    cr: "Released",
    released_catch: "Rereleased",
    male: "Male",
    female: "Female",
    boat: "Boat",
    fettfinne: "adipose fin",
    adipose_fin_removed: "Adipose fin cut",
    delivered: "Delivered",
    scalesample: "Scale sample",
    scale_sample: "Scale sample",
    on_quota: "Taken on quota",
    damaged: "Killed due to damage/bleeding",
    farming: "Farming",
    species: "Species",
    field: "Field",
    site_not_found: "Site not found",
    code: "Code",
    cancel_order: "Cancel order",
    platform_reports: "Platform reports",
    tax_code: "VAT code",
    tax_percentage: "VAT percentage",
    revenue: "Revenue",
    revenue_gross: "Revenue - gross",
    revenue_tax: "Revenue - VAT",
    provision: "Commission",
    provision_tax: "Commission - VAT",
    provision_percentage: "Commission percentage incl.VAT",
    payment_to_customer: "Payment to customer",
    payment_to_customer_online: "Payment to customer - Online sales",
    payment_to_customer_manual: "Invoice commission",
    stations: "Stations",
    new_station: "New Station",
    update_station: "Update Station",
    staff: "Personnel",
    no_stations_found: "No stations found",
    disinfection: "Disinfection",
    disinfections: "Disinfections",
    no_disinfections_found: "No disinfections found",
    station: "Station",
    transaction_fees: "Transaction Fees",
    transaction_fees_manual_payment: "Transaction fees (paid in cash)",
    transaction_fees_terminal_payment: "Transaction fees (bank terminal)",
    transaction_fees_online_payment: "Transaction fees (online)",
    search: "Search",
    company_information: "Company Information",
    summary: "Summary",
    sum: "Sum",
    net_revenue: "Revenue - Net",
    download: "Download",
    station_panel: "Station panel",
    select_station: "Select Station",
    station_admins: "Station administrators",
    no_data_found: "No data found",
    add_new_disinfection: "Add new disinfection",
    new_disinfection: "New Disinfection",
    customer_id: "Customer ID",
    allow_booking_after_start_date: "Allow booking after start date",
    search_by_id_or_name: "Search by ID or name",
    search_by_id_name_or_email: "Search by ID, name or email",
    seasoncard: "Season ticket",
    season_card: "Season ticket",
    season_cards: "Season tickets",
    season: "Season",
    select_block_status: "Select block status",
    blocked: "Blocked",
    not_blocked: "Not blocked",
    remember: "Remember",
    payment_performed_manually: "Payment by cash",
    payment_performed_manually_2: "Accept the payment before pressing Confirm",
    order_confirmed: "Order confirmed",
    products_nets: "Online",
    products_terminal: "Bank terminal",
    products_manual: "Paid in cash",
    revenue_online_payment: "Revenue - Online",
    revenue_terminal_payment: "Revenue - Bank Terminal",
    revenue_manual_payment: "Revenue - Paid in cash",
    provision_online_payment: "Commission - Online",
    provision_terminal_payment: "Commission - Bank terminal",
    provision_manual_payment: "Commission - Paid in cash",
    invoice_to_customer: "Invoice - Commission paid in cash",
    economic_transaction_fee_manual: "Invoice - Transaction fee paid in cach",
    economic_total_payout: "Sum Payout",
    intermediary_of_service: "Intermediary of service",
    telephone: "Telephone",
    email_pdf: "Email",
    downloaded: "Downloaded",
    terminal: "Terminal",
    cash: "Cash",
    flexible_time: "Flexible start time",
    select_start_dates: "Select start time for fishing ticket",
    user_block: "User Block",
    "equipment-fly": "Fly",
    "equipment-worm": "Worm",
    "equipment-lure": "Lure",
    "equipment-wobbler": "Wobbler",
    "equipment-dabbler-fly": "Dabbler fly",
    "equipment-other": "Other",
    "equipment-spinner": "Spinner",
    "equipment-shrimp": "Shrimp",
    "equipment-fleet": "Fleet",
    "equipment-fly-weight": "Fly weight",
    "equipment-salmon-trap": "Salmon trap",
    "equipment-gip": "Gip",
    "equipment-drift-net": "Drift net",
    "equipment-maelkast": "Maelkast",
    "equipment-unknown": "Unknown",
    "fish_type-salmon": "Salmon",
    "fish_type-trout": "Trout",
    "fish_type-seatrout": "Seatrout",
    "fish_type-other": "Other",
    "fish_type-farmed-salmon": "Farmed salmon",
    "fish_type-pink-salmon": "Pink salmon",
    "fish_type-brown-trout": "Brown trout",
    "fish_type-eel": "Eel",
    "fish_type-flounder": "Flounder",
    "fish_type-sea-char": "Sea char",
    "fish_type-stoing": "Støing",
    "fish_type-char": "Char",
    "fish_type-rainbow-trout-farmed": "Rainbow trout",
    remove_field_description: "Mark as 'Removed' to hide the catch from Laksebørs",
    select_reportby: "Select Period",
    report_weeks: "Week",
    report_days: "Day",
    report_by_product_count: "Number of Products Sold",
    beat_product_count: "Number of Products Sold",
    total_sold_products: "Total sold products",
    day: "Day",
    week: "Week",
    sold_total: "Total sales",
    beat_product_type_price: "Sales Report - Product Groups",
    economy_beat_reports: "Beat economy reports",
    all_beats_economy_report: "All beats economy report",
    seller_payment_method: "Sales report - Sellers",
    sales_method: "Sales Method",
    web_sale: "Web Sale",
    report_salesman_nets_manual: "Bank Terminal",
    report_salesman_manual: "Cash",
    report_salesman_nets: "Nets Paymentlink",
    report_salesman_total_sale: "Total Sales",
    payment_methods_sum: "SUM",
    report_salesman_method_nets_manual: "Bank Terminal",
    report_salesman_method_manual: "Cash",
    report_salesman_method_nets: "Nets Payment Link",
    apply: "Apply for",
    report_all_beats: "Report All Beats",
    date_start_order_date: "Start (order date)",
    date_end_order_date: "End (order date)",
    marker: "Point",
    polygon: "Area",
    general: "General",
    regulations: "Regulations",
    images: "Images",
    map: "Map",
    county: "County",
    content: "Content",
    title: "Title",
    information: "Information",
    contact_person: "Contact Person",
    contact_phone: "Phone",
    contact_email: "Email",
    river_length: "Length of the river (in km)",
    optional_page: "Optional page",
    featured_image: "Featured Image",
    image_gallery: "Image Gallery",
    payment_successful: "The payment was successful",
    problems_with_payment: "Problems with payment",
    payment_error_1002: "Transaction not found, already processed or expired!",
    payment_error_1003: "Something went wrong! The payment has not been processed.",
    link: "Link",
    add_price_type: "Add price type",
    price_types: "Price types",
    warning: "Warning",
    you_need_to_update_pricetype: "You need to update price types on product type.",
    add_marker: "Add point",
    add_polygon: "Add Area",
    marker_default: "Default",
    marker_accommodation: "Accommodation",
    marker_parking: "Parking",
    marker_bivouac: "Bivouac",
    marker_campfire_spot: "Campfire Spot",
    marker_fishing_net: "Fishing spot",
    marker_tent_site: "Tent site",
    marker_toilet: "Toilet",
    seo: "SEO",
    additional_information: "Additional information",
    hide_from_crawlers: "Hide from search engines",
    select_county: "Select county",
    beat_length: "Beat's length (in meters)",
    other_facilities: "Other facilities",
    about_us: "About Us",
    fishing_types_and_facilities: "Fishing and Facilities",
    fishing_types: "Fishing",
    "flytype-fluefiske": "Flyfishing",
    "flytype-markfiske": "Wobbler",
    "flytype-slukfiske": "Worm fishing",
    "flytype-wobbler": "Spin fishing",
    "flytype-batfiske": "Fishing from boat",
    facilities: "Facilities",
    "facility-balplass": "Campfire spot",
    "facility-bat": "Boat",
    "facility-desinfisering": "Disinfection",
    "facility-dusj": "Shower",
    "facility-fryser": "Freezer",
    "facility-gapahuk": "Bivouac",
    "facility-grill": "Barbeque",
    "facility-guide": "Fishing Guide",
    "facility-handicapvennlig": "Handicap friendly",
    "facility-hav": "Fishing net",
    "facility-henting-pa-flyplass": "Airport transport",
    "facility-kjop-leie-av-fiskeutstyr": "Equipment for rent",
    "facility-moterom": "Meeting room",
    "facility-overnatting": "Accommodation",
    "facility-parkering": "Parking",
    "facility-servering": "Food",
    "facility-skilt-til-elva": "Signs to the river",
    "facility-sti-til-elva": "Path to the river",
    "facility-tv-stue": "TV",
    "facility-utedo": "Toilet",
    "facility-vaskerom": "Washing room",
    "facility-wifi": "WIFI",
    add_center_point: "Add center point",
    add_center_points_first: "Add center points with the center point button above.",
    add_new_river: "New River",
    add_new_beat: "New beat",
    published: "Published",
    draft: "Draft",
    trash: "Trash",
    future: "Future",
    articles: "Articles",
    status: "Status",
    language: "Language",
    publish_date: "Publish date",
    add_new_article: "New Article",
    add_new_page: "New Page",
    excerpt: "Excerpt",
    image_and_video: "Image and video",
    image: "Image",
    categories: "Categories",
    header_type: "Type",
    youtube_link: "YouTube link",
    vimeo_link: "Vimeo link",
    edit_category: "Edit Category",
    add_category: "New Category",
    active_on_web: "Active on the web?",
    active_on_laksebors: 'Active on "Catches"?',
    additional_settings: "Additional settings",
    fishing_spot: "Fishing spot",
    hide_unused_tabs: "Hide unused columns",
    image_must_be_smaller: "The image must be smaller than",
    you_can_only_upload_jpg_png_files: "You can only upload JPG/PNG files.",
    select_orders_by: "Select order by",
    product_date: "Product Date",
    order_date: "Order Date",
    fishing_card_date: "Fishing ticket date",
    filter_ticket_type: "Filter - ticket type",
    buyer_name: "Buyer name",
    buyer_address: "Buying address",
    buyer_country: "Buying country",
    buyer_phone: "Buying phone",
    buyer_email: "Buying email",
    order_id: "Order ID",
    price_type: "Price type",
    price_amount: "Amount",
    amount: "Amount",
    seller: "Seller",
    is_in_period_card: "Is in period ticket?",
    period_card_type: "Period ticket type",
    period_card_price_type: "Period ticket price type",
    period_card_price_amount: "Period ticket amount",
    unknown: "Unknown",
    add_extra_service: "Add extra service",
    edit_extra_service: "Edit extra service",
    select_extra_service: "Select extra service",
    extra_service: "Extra service",
    extra_services: "Extra Services",
    member_only: "Only for members",
    members_only: "Only for members",
    add_edit_price: "Add / edit price",
    add_edit_dates: "Add / edit dates",
    select_year: "Select year",
    show_also_for_members: "Show cards available for members too",
    ok: "OK",
    not_ok: "Not OK",
    detailed_overview: "Detailed overview",
    details: "Details",
    user_blocked_cards: "User blocked by cards",
    ban_user: "Ban user from purchasing",
    member_of_beat: "Member of beat",
    new_catch: "New catch",
    register_catch: "Register catch",
    pages: "Pages",
    newsfeed: "Newsfeed",
    header: "Header",
    created: "Created",
    activity_title: "Activity title",
    preamble: "Preamble",
    sender: "Sender",
    redirect_link: "Link",
    open_reporting: "Open catch reporting",
    open_reporting_description:
      "means that the fisherman can choose whether the catch must be linked to a fishing license or not.",
    simple: "Simple catch reporting",
    simple_description:
      "means that the catch must not be linked to a fishing license, and that rivers and beat must be filled in manually by the fisherman.",
    card_connected: "Catch reporting connected to card",
    card_connected_description: "means that the catch must be linked to a fishing card.",
    catch_report_option: "Check the box if catch reporting is required in river",
    catch_report_type: "Type of capture reporting",
    catch_report_status: "Catch registered",
    fishing_effort: "Fishing Effort",
    did_not_use: "Not Used",
    required_catch_fields: "Required catch fields",
    time_of_day: "Time",
    equipment_id: "Equipment",
    new_equipment: "New equipment",
    fish_type_id: "Fish Type",
    lice_count: "How many lice",
    name_no: "Name (NO)",
    name_en: "Name (EN)",
    obligatory_fields_alert_title: "Mandatory fields for reporting",
    obligatory_1: "Date",
    obligatory_2: "Specie",
    obligatory_3: "Weight",
    obligatory_4: "Length",
    obligatory_5: "'Killed' or 'Resuspended",
    additional_required_fields_text:
      "Choose what the angler should report beyond the mandatory fields by ticking the box and pressing Save.",
    required_fields_if_not_released_text: "The following fields are only activated if the catch has been killed.",
    deny_anonymous_catch_report: "Deny anonymous catch reports",
    controller_user: "Bailiff",
    controller_user_access: "Bailiff in the river",
    controller_panel: "Control Panel",
    card_changes: "Card Changes",
    select_type: "Select type",
    search_by_order_id_or_fcid: "Search by Order ID or FCID",
    owner_name: "Owner",
    free_package: "Package",
    actions: "Actions",
    season_fishing_card: "Season Card",
    edit_owner: "Edit Owner",
    remove_from_order: "Remove from order",
    card_will_be_refunded: "The purchase price for the card will be refunded to the buyer.",
    only_fishing_cards_refunds:
      "Only fishing tickets are automatically refunded to the buyer, other products must be refunded manually.",
    product_will_not_be_refunded: "The purchase price of the product will not be refunded to the buyer.",
    confirm_removing_card_from_order_question: "Remove card from order?",
    catch_limits: "Catch Limits",
    beat_list: "Beat list",
    all_documents_ok: "All documentation OK?",
    all_documents_are_ok: "All documentation OK",
    documents_missing: "Documentation missing",
    add_comment: "Add a comment",
    show_comments: "Show comments",
    controller_products: "Controlled by bailiff",
    select_comment_status: "Select checked by bailiff",
    supervisor_dashboard: "Bailiff dashboard",
    required_catch_report: "Required catch report",
    catch_report_settings: "Catch Report Settings",
    current_link: "Current link",
    add_attachments_to_billings: "Add attachments to my attachments",
    my_billings: "My bills",
    report_created_at: "Report created",
    submit_report: "Submit the report",
    zones: "Zones",
    add_new_zone: "Add new zone",
    edit_zone: "Edit Zone",
    remove_zone: "Delete zone",
    confirm_removing: "Confirm removal",
    only_superadmin_can_remove_zones: "Only super admins can delete zones.",
    about_beat: "About the beat",
    valid_from_hours: "Valid from (time)",
    validity_duration_in_hours: "Validity (hours)",
    net_sales: "Net Sales Revenue",
    net_provision: "Net commission",
    products_sold: "Number of products sold",
    number_of_catches: "Number of catches",
    counties: "Counties",
    top_rivers: "Top Rivers",
    number_of_fishermen: "Number of anglers",
    river_card_stats: "River Card Statistics",
    number_of_cards_total: "Total number",
    number_of_cards_norway: "Number in Norway",
    number_of_cards_abroad: "Number abroad",
    number_of_disinfections: "Number of disinfections",
    controlled_by_controller: "Controlled by bailiff",
    view_all: "View all",
    catch_statistics: "Catch Statistics",
    all_species: "Total all species",
    killed: "Killed",
    kg_total: "kg total",
    kg_avg_weight: "kg average weight",
    last_payment: "Last payment",
    number_of_cards_sold: "Number of fishing cards sold",
    number_of_season_cards_sold: "Number of season cards sold",
    fishing_pressure: "Fishing pressure",
    cards_sold: "Number of sold fishing tickets",
    catch_reports: "Number of fishing tickets with catch report",
    fishing_days:"Number of fishing days",
    fishermen: "Fishermen",
    recent_catches: "Recent Catches",
    active_fishing_cards: "Active fishing cards",
    active_season_cards: "Active Season Cards",
    river_cards: "River Cards",
    add_river_card: "Add new river card",
    sold_river_cards: "Sold River Cards",
    are_you_sure: "Are you sure?",
    river_commissions: "River Commissions",
    river_card: "River Card",
    river_card_title: "Title of the river card",
    river_card_all_cards: "All cards",
    river_cards_expired: "Expired cards",
    river_cards_active: "Active cards",
    see_picture_below: "See the picture below.",
    buy_river_card: "Buy River Card",
    survey: "Investigation",
    old: "Old",
    send_survey_1: "Send Survey 1",
    survey_text:
      'The survey can only be sent once per river card. Send it only at the end of a season. After clicking "Send" you will not be able to send it again.',
    sent_on: "Sent on",
    number_of_sent_survey_invites: "Number of survey invitations sent",
    responded_survey_invites: "Number of respondents to survey invitations",
    survey_not_sent: "Survey not sent",
    survey_not_responded: "Survey not answered",
    report_all_rivers: "All rivers",
    send_email: "Email sending",
    limit: "Limit",
    year_of_purchase: "Year of purchase",
    type_1: "Daily Card",
    send_to: "Send to",
    users: "Users",
    buy_more: "Buy more",
    number_of_blocks: "Number of blocks",
    each_block_is_credit_for_500_emails: "Each block is a credit for 500 emails. A block costs NOK 250.",
    confirmation_on_new_orders: "Receive confirmation on new orders",
    history: "History",
    change_made_by: "Change made by",
    changed_value: "Changed value",
    old_value: "Old value",
    new_value: "New value",
    user_id: "User ID",
    river_card_commissions: "River card commissions",
    send_final_survey: "Send survey 2",
    survey_1: "Mid-season survey",
    final_survey: "Post Season Survey",
    survey_message: "Survey message",
    change_date: "Edit date",
    year: "Year",
    refunds: "Refunded",
    refund_tax: "VAT refunded",
    revenue_total: "Total payments (online + terminal + cash)",
    remove_refund: "Remove refund",
    comment: "Comment",
    new_refund: "New refund",
    laksebors_message: '"Catches" message',
    river_orders: "River",
    beat_orders: "Beat",
    card_type: "Card Type",
    show_on_laksebors: 'Show catch statistics on "Catches"?',
    this_order_has_other_products_besides_river_cards: "This order contains products other than river cards.",
    this_order_has_other_products_besides_beat_products: "This order contains products other than the selected products.",
    daycard: "Day card",
    weekcard: "Week card",
    reported_on: "Reported at",
    members: "Members",
    members_of_beat: "Members of beat",
    refund_commission: "Commission refunded",
    refund_commission_tax: "Refunded commission VAT",
    cancellation_policy: "Cancellation Policy",
    cancellation_policy_description_1:
      "Here you can define refund policy rules for fishing tickets bought in your beat. This information will be shown on your beat page.",
    cancellation_policy_description_2:
      "You specify the number of days before the fishing ticket starts when the user is elibible for a refund. The user can administer the refund themselves, given that they are refunding within the limits set by the cancellation policy at time of purchase.",
    cancellation_policy_description_3:
      "As an example: 3 days before, means that the customer can refund his/her card up until 3 days before the start of the ticket. A ticket for June 9th, must be cancelled before June 6th, that means no later than June 5th, 11:59 PM.",
    cancellation_policy_description_4:
    "If you don't want to allow refund on sold products, please enter 0 days.",
    cancellation_policy_set_terms: "Set terms",
    cancellation_policy_full_refund_limit: "Limit for 100% refund",
    cancellation_policy_no_policy_set: "Currently no conditions for refunds are set.",
    cancellation_policy_current_terms: "Terms and Conditions for",
    cancellation_policy_product: "Product",
    cancellation_policy_refund: "Refund",
    cancellation_policy_number_of_days: "Number of days",
    days: "days",
    currency: "Currency",
    options: "Options",
    timezone: "Timezone",
    adminBeatNotificationWarningText:
      "Beat needs more information from customer to be able to sell products. Please go to Settings → Beat to fill out missing information",
    adminNotificationWarningTextPre:
      "Beat needs more information from customer to be able to sell products. Beat without the necessary information: ",
    adminNotificationWarningTextPost: "Please go to Settings → Beat to fill out missing information",
    explanation: "Explanation",
    gradual_publish_explanation:
      "With this option the fishing tickets will be published gradually for sale a set time before they become valid. Fill out how many days/hours before the validation it should be published for sale.",
    equipment_configuration: "Equipment configuration",
    defaultEquipment: "Default equipment",
    selling_beats: "Selling beats",
    choose_beats: "Choose beat(s)",
    unblock: "Unblock",
    help_text: "Help",
    scribe_create_fishing_ticket: "Create fishing tickets",
    scribe_publish_fishing_tickets: "Publish fishing tickets",
    scribe_edit_fishing_tickets: "Edit fishing tickets",
    scribe_delete_fishing_tickets: "Delete fishing tickets",
    scribe_edit_river_cards: " Edit name of river card",
    scribe_publish_river_cards: "Publish river card",
    scribe_about_beat: "About beat",
    scribe_about_us: "About us",
    scribe_other_facilities: "Other facilities",
    scribe_accommodation: "Accommodation",
    scribe_images: "Images",
    scribe_map: "Map",
    scribe_optional_page: "Optional page",
    scribe_fishing_regulations: "Fishing regulations",
    scribe_catches_message: "Catches message",
    scribe_catches_disinfection: "Disinfection",
    scribe_customer_register_beat: "Customer register beat",
    scribe_customer_register_river: "Customer register river",
    scribe_customer_blocked_users: "Blocked users",
    scribe_customer_add_bailiff: "Add bailiff",
    scribe_customer_add_station_admin: "Add a station administrator",
    scribe_customer_how_to_block_user: "How to block a user",
    scribe_customer_how_to_check_blocked_user: "How to check blocked users",
  },

  sv: {
    send_link: "Skicka länken",
    password_forgot: "Har du glömt ditt lösenord?",
    back_to_login: "Tillbaka till inloggningssidan",
    old_password: "Gammalt lösenord",
    new_password: "Nytt lösenord",
    new_password_repeat: "Upprepa nytt lösenord",
    menu: "Meny",
    dashboard: "Dashboard",
    booking: "Bokning",
    products: "Produkter",
    order: "Order",
    orders: "Order",
    registered_clients: "Kundregister",
    beat: "Vald",
    all_beats: "Alla vald",
    edit: "Redigera",
    edit_beat: "Redigera beat",
    adverts: "Reklam",
    reports: "Rapporter",
    fishing: "fiskekort",
    fishing_card: "Fiskekort",
    accommodation: "Boende",
    fishing_card_and_accommodation: "Fiskekort + Logi",
    menu_fishing_card: "Fiskekort",
    homepage: "Hemsida",
    back_to_dashboard: "Tillbaka till startsidan",
    menu_accommodation: "Boende",
    my_information: "Min information",
    first_name: "Förnamn",
    last_name: "Efternamn",
    phone: "Telefon",
    email: "E-post",
    address: "Adress",
    post_code: "Postnummer",
    city: "Plats",
    country: "Land",
    birth_date: "Födelsedatum",
    cancel: "Avbryt",
    save: "Spara",
    remove: "Radera",
    latitude: "Latitude",
    longitud: "Longitud",
    icon: "Icon",
    delete: "Radera",
    change_password: "Ändra lösenord",
    login: "Logga in",
    send: "Skicka",
    username: "Användarnamn",
    password: "Lösenord",
    no_customers_found: "Inga kunder hittades",
    no_packages_found: "Inga paket hittades",
    update_user: "Uppdatera användare",
    logout: "Logga ut",
    is_user_active: "Användaren är aktiv",
    yes: "Ja",
    no: "Nej",
    active: "Aktiv",
    user_role: "Användarroll",
    river_admin: "Flod admin",
    river_administrator: "River admin",
    river_admin_access: "Administratörer för floden",
    beat_admin: "Välj admin",
    beat_administrator: "Välj admin",
    beat_admin_access: "Administratörer för valet",
    controller_administrator: "Övervakning",
    station_admin: "Stationsadmin",
    member: "Medlem",
    more: "mer",
    new_booking: "Ny bokning",
    no_results: "Inga resultat",
    new_user: "Ny användare",
    user: "Användare",
    new_package: "Nytt paket",
    select: "Välj",
    select_tax: "Välj moms",
    user_not_exists: "Användaren finns inte",
    river_beat_panel: "Flod och vald panel",
    beat_panel: "Vald panel",
    seller_panel: "Säljarpanel",
    select_river: "Välj flod",
    river: "Flod",
    rivers: "Floder",
    beats: "Vald",
    select_beat: "Välj vald",
    select_beat_from_fangstrapp: "Välj beat från fangstapp",
    select_fishing_spot: "Välj fiskeplats",
    select_filter: "Välj filter",
    fishing_spot_title: "Här kan du lägga till, ta bort och ändra fiskeplatserna i valdet.",
    fishing_spot_description: 'Trykk "Legg til", fyll inn navn, og trykke "Lagre" når du har lagt til alle fiskeplassene.',
    product_name: "Namn",
    name: "Namn",
    value: "Värde",
    product_type: "Typ",
    product_total_number: "Totalt antal",
    product_total_sold: "Antal sålt",
    no_products_found: "Inga produkter hittades",
    new_product: "Ny produkt",
    add: "Lägg till",
    add_availability: "Lägg till tillgänglighet",
    add_edit_availability: "Lägg till/redigera tillgänglighet",
    add_edit_package: "Lägg till/redigera paket",
    add_edit_fishing_package: "Lägg till / redigera fiskekortspaket",
    add_edit_fishing_cards: "Lägg till / redigera fiskekort",
    add_fishing_cards: "Lägg till fiskekort",
    fishing_cards: "fiskekort",
    add_accommodation: "Lägg till boende",
    edit_accommodation: "Redigera boende",
    available: "Tillgänglig",
    reserved: "Reserverad",
    price: "Pris",
    extra_per_person: "Pris för extra personer",
    extra_per_person_short: "Extra personer",
    quantity: "Quantity",
    date: "Datum",
    date_start: "Startdatum",
    fishing_card_from_date: "Från datum",
    fishing_card_to_date: "Till datum",
    date_end: "Slutdatum",
    publish_type_now_radiotext: "Inlägg till salu nu",
    publish_type_ondate_radiotext: "Inlägg till försäljning på ett datum",
    publish_type_gradual_radiotext: "Gradvis publicering",
    publish_type_gradual_description: "Gradvis publicering",
    publish_type_header: "Publikation - När släpps produkterna ut till försäljning?",
    publish_type_subtitle: "När kommer produkten att säljas?",
    publish_type_day_count_label: "Antal dagar",
    publish_type_hour_count_label: "Antal timmar",
    publish_date_start: "Starta rea",
    publish_date_end: "Stoppa försäljning (valfritt)",
    description: "Beskrivning",
    capacity: "Capacity",
    select_accommodation: "Välj boende",
    back: "bakåt",
    prices: "Priser",
    confirm_delete_question: "Är du säker på att du vill ta bort detta?",
    confirm_cancel_order_question: "Är du säker på att du vill avbryta den här beställningen?",
    menu_packages: "Paket",
    pack: "Paket",
    packs: "Förpackningar",
    package_type: "Pakettyper",
    package_types: "Pakettyper",
    select_package_type: "Välj pakettyp",
    select_package: "Välj paket",
    add_package: "Lägg till paket",
    add_edit_packages: "Lägg till / redigera paket",
    remove_package: "Ta bort paket",
    package_number_of_guests: "Antal gäster",
    package_fishing_cards_price: "Fiskekortspris",
    package_accommodation_price: "Boendepris",
    add_price: "Lägg till pris",
    select_order_type: "Välj ordertyp",
    pay_now: "Betala nu",
    payment_link: "Betalningslänk",
    no_orders_found: "Inga beställningar hittades",
    select_customer: "Välj kund",
    add_new_customer: "Lägg till ny kund",
    new_customer: "Ny kund",
    other_customers: "Andra kunder",
    first_and_last_name: "Förnamn och efternamn",
    order_status: "Beställningsstatus",
    user_name: "Användarnamn",
    all_catches: "Alla fångster",
    report_submitted: "Fångstrapport registrerad",
    report_missing: "Saknar fångstrapport",
    filter_catch_reporting: "Filter - fångstrapportering",
    ALL: "Alla",
    PAID: "Betalt",
    PENDING: "Obetalt",
    CANCELLED: "Kansellerad",
    customer: "Kund",
    angler_name: "Namn på fisker",
    from_date: "Från datum",
    to_date: "Till datum",
    todays_fisherman: "Dagens fiskare",
    todays_accommodation: "Dagens boende",
    last_five_catches: "Sista 5 fångster",
    catches: "Fångster",
    edit_catch: "Redigera fångst",
    catch_edit_history: "Redigeringshistorik fångst",
    catch_report_version: "Fångstrapport version",
    catch_report_edit_time: "Endringstidspunkt",
    all_versions_catch_edit_history: "Alla versioner av rapporten som ges av fiskar",
    last_year: "förra året",
    turnover: "Omsättning",
    number_of_salmon: "Antal lax",
    salmon_per_rod: "Lax per spö",
    last_orders: "Sista beställning",
    time: "Tid",
    fisherman: "Fiskar",
    fisherman_id: "Fiskar ID",
    filter: "Filtrera",
    anonymous_report: "Anonym rapporterar",
    deleted_reports: "Raderade rapporter",
    edited_reports: "Redigerade rapporter",
    reported_on_ticket: "Anmäld på kort",
    not_reported_on_ticket: "Inte rapporterad på korten",
    fisher_name: "Fishers namn",
    place: "Plats",
    weight: "Vikt",
    weight_constraints: "Vikten måste vara mellan 0 och 30 kg.",
    from: "Från",
    to: "Till",
    all_paid_orders: "Alla betalda beställningar",
    all_orders: "Alla beställningar",
    all_unpaid_orders: "Alla obetalda beställningar",
    no_paid_orders_found: "Inga betalda beställningar hittades",
    no_unpaid_orders_found: "Inga obetalda beställningar hittades",
    no_catches_found: "Ingen fångst hittades",
    unpaid_orders: "Obetalda beställningar",
    settings: "Inställningar",
    taxes: "Moms",
    percentage: "Procentandel",
    commissions: "Provisioner",
    beat_commissions: "Provisioner vald",
    add_tax: "Lägg till moms",
    update_tax: "Uppdatera moms",
    new_tax: "Ny moms",
    tax: "Moms",
    remove_tax: "Ta bort moms",
    no_taxes_found: "Ingen moms hittades",
    full_commission: "Onlineförsäljning",
    discounted_commission: "Manuell försäljning",
    special_commission: "Vanliga kunder",
    add_card_type: "Nytt fiskekort",
    edit_card_type: "Mina fiskekort",
    add_type: "Lägg till typ",
    edit_type: "Redigera typ",
    select_card_type: "Välj fiskekortstyp",
    select_card: "Välj fiskekort",
    card_id: "Fiskekorts-ID",
    fishing_card_id: "Fiskekorts-ID",
    package_id: "Fiskekorts paket-ID",
    free_package_id: "Paket-ID",
    season_fishing_card_id: "Säsongskort ID",
    no_catch: "Ingen fångst",
    catch: "Fånga",
    no_catch_column: "Finns det en hake?",
    catch_edited: "Fangst redigerat?",
    catch_is_edited: "Redigerat",
    catch_not_edited: "Inte redigerat",
    reported_by: "Rapporterad av",
    external_id: "Rapporterad av",
    fishing_card_type: "Fiskekortstyp",
    season_card_type: "Typ av säsongskort",
    fishing_packages: "Fiskekortspaket",
    fishing_package: "Fiskekortspaket",
    delete_multiple_products_alert: "Du kan nu välja produkter att radera.",
    confirm_deletion: "Bekräfta borttagning",
    delete_packages: "Ta bort paket",
    delete_fishing_cards: "Radera fiskekort",
    delete_accommodation: "Radera boende",
    price_for_fishing_cards: "Fiskekortspris",
    price_for_accommodation: "Boendepris",
    next: "Nästa",
    confirm: "Bekräfta",
    total_price: "Totalt pris",
    select_price: "Välj pris",
    no_options_found: "Inga alternativ hittades",
    this_is_my_customer: "Detta är min tidigare kund",
    fee: "Avgift",
    street: "Street",
    organization_account_number: "Kontonummer",
    organization_address: "Organisationsadress",
    organization_name: "Organisationens namn",
    organization_number: "Organisationsnummer",
    organization_phone: "Organisationstelefon",
    account_number: "Kontonummer",
    alla: "Alla",
    beat_card_limit: "Välj kortgränser",
    river_card_limit: "River Card Limits",
    card_limit: "Kortgränser",
    turn_on_river_card_limit: "Kortgränser för river",
    turn_on_beat_card_limit: "Kortgränser för beat",
    nothing_found: "Inget hittades",
    equipment: "Verktyg",
    select_equipment: "Välj utrustning",
    select_fish_type: "Välj fisktyp",
    new_fish_type: "Ny fisktyp",
    fish_type: "Fisktyp",
    fish_types: "Fisktyper",
    type: "Typ",
    select_fish_sex: "Välj sex",
    sex: "Kön",
    length: "Längd",
    lice: "Löss",
    anonymous: "Anonym",
    removed: "Removed",
    select_lice_count: "Hur många löss",
    cr: "Tyckte om",
    released_catch: "Återsläppt",
    male: "Manlig",
    female: "Kvinna",
    boat: "Båt",
    fettfinne: "Fettfin cut",
    adipose_fin_removed: "Fettfena skär",
    delivered: "Levererat",
    scalesample: "Scale sample",
    scale_sample: "Skala prov",
    on_quota: "Tat på kvot",
    damaged: "Dödad på grund av skada/blödning",
    farming: "Farming",
    species: "Art",
    field: "Felt",
    site_not_found: "Webbplatsen hittades inte",
    code: "Kod",
    cancel_order: "Avbryt beställning",
    platform_reports: "Övergripande rapporter",
    tax_code: "momskod",
    tax_percentage: "Momsprocent",
    revenue: "Inkomst",
    revenue_gross: "Inkomst",
    revenue_tax: "Intäkt - moms",
    provision: "Provision",
    provision_tax: "Provision - moms",
    provision_percentage: "Provicionprosent ink.MOMS",
    payment_to_customer: "Betalning till kund",
    payment_to_customer_online: "Betaling til kunde - Online salg",
    payment_to_customer_manual: "Fakturagrunnlag provisjon",
    stations: "Stationer",
    new_station: "Ny station",
    update_station: "Uppdatera station",
    staff: "Personal",
    no_stations_found: "Inga stationer hittades",
    disinfection: "Desinfektion",
    disinfections: "Desinfektioner",
    no_disinfections_found: "Inga desinfektioner hittades",
    station: "Station",
    transaction_fees: "Transaktionsavgifter",
    transaction_fees_manual_payment: "Transaktionsavgifter (betalas kontant)",
    transaction_fees_terminal_payment: "Transaktionsavgifter (bankterminal)",
    transaction_fees_online_payment: "Transaktionsavgifter (online)",
    search: "Sök",
    company_information: "Företagsinformation",
    summary: "Sammanfattning",
    sum: "Sum",
    net_revenue: "Nettoinkomst",
    download: "Ladda ner",
    station_panel: "Stationspanel",
    select_station: "Välj station",
    station_admins: "Stationsadministratörer",
    no_data_found: "Ingen data hittades",
    add_new_disinfection: "Lägg till ny desinfektion",
    new_disinfection: "Ny desinfektion",
    customer_id: "Kund-ID",
    allow_booking_after_start_date: "Tillåt bokning efter startdatum",
    search_by_id_or_name: "Sök på ID eller namn",
    search_by_id_name_or_email: "Sök på ID, namn eller e-post",
    seasoncard: "Säsongskort",
    season_card: "Säsongskort",
    season_cards: "Säsongskort",
    season: "Säsong",
    select_block_status: "Välj blockstatus",
    blocked: "Blockerad",
    not_blocked: "Inte blockerad",
    remember: "Kom ihåg",
    payment_performed_manually: "Betalning med kontanter",
    payment_performed_manually_2: "Acceptera betalningen innan du trycker på Bekräfta",
    order_confirmed: "Beställning bekräftad",
    products_nets: "Online",
    products_terminal: "Bankterminal",
    products_manual: "Betalas kontant",
    revenue_online_payment: "Intäkt - Online",
    revenue_terminal_payment: "Intäkter - Bankterminal",
    revenue_manual_payment: "Intäkt - Betald kontant",
    provision_online_payment: "Provision - Online",
    provision_terminal_payment: "Provision - Bankterminal",
    provision_manual_payment: "Provision - Betald kontant",
    invoice_to_customer: "Fakturagrunnlag - Provisjon betalt kontant",
    economic_transaction_fee_manual: "Fakturagrunnlag - Transaksjonsgebyr betalt kontant",
    economic_total_payout: "Total utbetalning",
    intermediary_of_service: "Tjänsteförmedlare:",
    telephone: "Telefon",
    email_pdf: "Epost",
    downloaded: "Nedladdad",
    terminal: "Terminal",
    cash: "Kontanter",
    flexible_time: "Flexibel starttid",
    select_start_dates: "Välj starttid för fiskekort",
    user_block: "Användarblockering",
    "equipment-fly": "Flyg",
    "equipment-worm": "Markera",
    "equipment-lure": "Lure",
    "equipment-wobbler": "Wobbler",
    "equipment-dabbler-fly": "Doppa med fluga",
    "equipment-other": "Övrigt",
    "equipment-spinner": "Spinner",
    "equipment-shrimp": "Reke",
    "equipment-fleet": "Flåte",
    "equipment-fly-weight": "Flue og søkke",
    "equipment-salmon-trap": "Teine",
    "equipment-gip": "Gip",
    "equipment-drift-net": "Drivgarn",
    "equipment-maelkast": "Mælkast",
    "equipment-unknown": "Ukjent",
    "fish_type-salmon": "Lax",
    "fish_type-trout": "Söring",
    "fish_type-seatrout": "Havsöring",
    "fish_type-other": "Annat",
    "fish_type-farmed-salmon": "Odlad lax",
    "fish_type-pink-salmon": "Knöllax",
    "fish_type-brown-trout": "Bruning",
    "fish_type-eel": "Ål",
    "fish_type-flounder": "Flyndre",
    "fish_type-sea-char": "Sjørøye",
    "fish_type-stoing": "Noise",
    "fish_type-char": "Røye",
    "fish_type-rainbow-trout-farmed": "Regnbueørret/oppdrett",
    remove_field_description: "Markera som 'borttagen' för att dölja fångsten från Laksebørs",
    select_reportby: "Välj period",
    report_weeks: "Vecka",
    report_days: "Dag",
    report_by_product_count: "Antal sålda produkter",
    beat_product_count: "Antal sålda produkter",
    total_sold_products: "Totalt sålda produkter",
    day: "Dag",
    week: "Vecka",
    sold_total: "Total försäljning",
    beat_product_type_price: "Försäljningsrapport - Produktgrupper",
    economy_beat_reports: "Ekonomisk rapport vald",
    all_beats_economy_report: "Ekonomisk rapport alla vald",
    seller_payment_method: "Försäljningsrapport - Säljare",
    sales_method: "Säljmetod",
    web_sale: "Webbförsäljning",
    report_salesman_nets_manual: "Bankterminal",
    report_salesman_manual: "Kontanter",
    report_salesman_nets: "Nets Paymentlink",
    report_salesman_total_sale: "Total försäljning",
    payment_methods_sum: "SUMMA",
    report_salesman_method_nets_manual: "Bankterminal",
    report_salesman_method_manual: "Kontanter",
    report_salesman_method_nets: "Nets Payment Link",
    apply: "Ansök om",
    date_start_order_date: "Start (beställningsdatum)",
    date_end_order_date: "Slut (beställningsdatum)",
    marker: "Point",
    polygon: "Area",
    general: "Allmänt",
    regulations: "Regler",
    images: "Bilder",
    map: "Karta",
    county: "Län",
    content: "Innehåll",
    title: "Titel",
    information: "Information",
    contact_person: "Kontaktperson",
    contact_phone: "Telefon",
    contact_email: "E-post",
    river_length: "Flodens längd (i km)",
    optional_page: "Valfri sida",
    featured_image: "Utvald bild",
    image_gallery: "Bildgalleri",
    payment_successful: "Betalningen lyckades",
    problems_with_payment: "Problem med betalning",
    payment_error_1002: "Transaktionen hittades inte, redan bearbetad eller har löpt ut!",
    payment_error_1003: "Något gick fel! Betalningen har inte behandlats.",
    link: "Länk",
    add_price_type: "Lägg till pristyp",
    price_types: "Pristyper",
    warning: "Varning",
    you_need_to_update_pricetype: "Du måste uppdatera pristyper på produkttyp.",
    add_marker: "Lägg till punkt",
    add_polygon: "Lägg till område",
    marker_default: "Standard",
    marker_accommodation: "Boende",
    marker_parking: "Parkering",
    marker_bivouac: "Bivouac",
    marker_campfire_spot: "Lägereldsplats",
    marker_fishing_net: "Fiskeplats",
    marker_tent_site: "Tältplats",
    marker_toilet: "Toalett",
    seo: "SEO",
    additional_information: "Ytterligare information",
    hide_from_crawlers: "Göm från sökmotorer",
    select_county: "Välj län",
    beat_length: "Slaglängden (i meter)",
    other_facilities: "Andra faciliteter",
    about_us: "Om oss",
    fishing_types_and_facilities: "Fiske och anläggningar",
    fishing_types: "Fiske",
    "flytype-fluefiske": "Fluefiske",
    "flytype-markfiske": "Markfiske",
    "flytype-slukfiske": "Slukfiske",
    "flytype-wobbler": "Wobbler",
    "flytype-batfiske": "Båtfiske",
    facilities: "Faciliteter",
    "facility-balplass": "Bålplass",
    "facility-bat": "Båt",
    "facility-desinfisering": "Desinfisering",
    "facility-dusj": "Dusj",
    "facility-fryser": "Fryser",
    "facility-gapahuk": "Gapahuk",
    "facility-grill": "Grill",
    "facility-guide": "Guide",
    "facility-handicapvennlig": "Handicapvennlig",
    "facility-hav": "Håv",
    "facility-henting-pa-flyplass": "Henting på flyplass",
    "facility-kjop-leie-av-fiskeutstyr": "Kjøp / Leie av fiskeutstyr",
    "facility-moterom": "Møterom",
    "facility-overnatting": "Overnatting",
    "facility-parkering": "Parkering",
    "facility-servering": "Servering",
    "facility-skilt-til-elva": "Skilt til elva",
    "facility-sti-til-elva": "Sti til elva",
    "facility-tv-stue": "TV-stue",
    "facility-utedo": "Utedo",
    "facility-vaskerom": "Vaskerom",
    "facility-wifi": "Wifi",
    add_center_point: "Lägg till mittpunkt",
    add_center_points_first: "Lägg till mittpunkter med mittpunktsknappen ovan.",
    add_new_river: "New River",
    add_new_beat: "Nytt beat",
    published: "Publicerad",
    draft: "Utkast",
    trash: "Trash",
    future: "Framtid",
    articles: "Artiklar",
    status: "Status",
    language: "Språk",
    publish_date: "Publiceringsdatum",
    add_new_article: "Ny artikel",
    add_new_page: "Ny sida",
    excerpt: "Utdrag",
    image_and_video: "Bild och video",
    image: "Bild",
    categories: "Kategorier",
    header_type: "Typ",
    youtube_link: "YouTube-länk",
    vimeo_link: "Vimeolänk",
    edit_category: "Redigera kategori",
    add_category: "Ny kategori",
    active_on_web: "Aktiv på webben?",
    active_on_laksebors: "Aktiv på Laksebørs?",
    additional_settings: "Ytterligare inställningar",
    fishing_spot: "Fiskeplats",
    hide_unused_tabs: "Dölj oanvända kolumner",
    image_must_be_smaller: "Bilden måste vara mindre än",
    you_can_only_upload_jpg_png_files: "Du kan bara ladda upp JPG/PNG-filer.",
    select_orders_by: "Välj ordning efter",
    product_date: "Produktdatum",
    order_date: "Beställningsdatum",
    fishing_card_date: "Fiskekorts datum",
    filter_ticket_type: "Filter - fiskekortstyp",
    buyer_name: "Köparens namn",
    buyer_address: "Köpadress",
    buyer_country: "Köpande land",
    buyer_phone: "Köper telefon",
    buyer_email: "Köper e-post",
    order_id: "Beställnings-ID",
    price_type: "Pristyp",
    price_amount: "Amount",
    amount: "Belopp",
    seller: "Säljare",
    is_in_period_card: "Är i periodkort?",
    period_card_type: "Typ av periodkort",
    period_card_price_type: "Pristyp för periodkort",
    period_card_price_amount: "Period kortbelopp",
    unknown: "Okänd",
    add_extra_service: "Lägg till extra tjänst",
    edit_extra_service: "Redigera extra tjänst",
    select_extra_service: "Välj extra tjänst",
    extra_service: "Extra service",
    extra_services: "Extra tjänster",
    member_only: "Endast för medlemmar",
    members_only: "Endast för medlemmar",
    add_edit_price: "Lägg till / redigera pris",
    add_edit_dates: "Lägg till / redigera datum",
    select_year: "Välj år",
    show_also_for_members: "Visa kort tillgängliga för medlemmar också",
    ok: "okej",
    not_ok: "Inte OK",
    detailed_overview: "Detaljerad översikt",
    details: "Detaljer",
    user_blocked_cards: "Användare blockerad av kort",
    ban_user: "Banna användare från att köpa",
    member_of_beat: "Medlem av beat",
    new_catch: "Ny fångst",
    register_catch: "Registrera fångst",
    pages: "Sidor",
    newsfeed: "Nyheter",
    header: "Rubrik",
    created: "Skapat",
    activity_title: "Tittel aktivitet",
    preamble: "Inledning",
    sender: "Avsender",
    redirect_link: "Link",
    open_reporting: "Öppen fångstrapportering",
    open_reporting_description: "innebär att fiskaren kan välja om fångsten ska vara kopplad till ett fiskekort eller inte",
    simple: "Enkel fångstrapportering",
    simple_description:
      "innebär att fångsten inte får kopplas till fiskekort, och att åar och slag ska fyllas i manuellt av fiskaren.",
    card_connected: "Fångstrapportering kopplad till kort",
    card_connected_description: "innebär att fångsten ska vara kopplad till ett fiskekort.",
    catch_report_option: "Markera rutan om fångstrapportering krävs i floden",
    catch_report_type: "Typ av fångstrapportering",
    catch_report_status: "Fångst rapporterad",
    fishing_effort: "Fiskeinnsats",
    did_not_use: "Ikke brukt",
    required_catch_fields: "Obligatoriska fångstfält",
    time_of_day: "Tid",
    equipment_id: "Utrustning",
    new_equipment: "Ny utrustning",
    fish_type_id: "Fisktyp",
    lice_count: "Hur många löss",
    name_no: "Namn (NO)",
    name_en: "Namn (EN)",
    obligatory_fields_alert_title: "Obligatoriska fält för rapportering till Fangstrapp.no",
    obligatory_1: "Datum",
    obligatory_2: "Art",
    obligatory_3: "Vikt",
    obligatory_4: "Längd",
    obligatory_5: "'Dödad' eller 'återsuspenderad",
    additional_required_fields_text:
      "Välj vad fiskaren ska rapportera utöver de obligatoriska fälten genom att kryssa i rutan och trycka på Spara.",
    required_fields_if_not_released_text: "Följande fält aktiveras endast om fångsten har dödats.",
    deny_anonymous_catch_report: "Neka anonyma fångstrapporter",
    controller_user: "Övervakning",
    controller_user_access: "Övervakning i floden",
    controller_panel: "Kontrollpanel",
    card_changes: "Kortändringar",
    select_type: "Välj typ",
    search_by_order_id_or_fcid: "Sök med order-ID eller FCID",
    owner_name: "Ägare",
    free_package: "Paket",
    actions: "Actions",
    season_fishing_card: "Säsongskort",
    edit_owner: "Redigera ägare",
    remove_from_order: "Ta bort från beställning",
    card_will_be_refunded: "Köpesumman för kortet kommer att återbetalas till köparen.",
    only_fishing_cards_refunds:
      "Endast fiskekort återbetalas automatiskt till köparen, övriga produkter måste återbetalas manuellt.",
    product_will_not_be_refunded: "Inköpspriset för produkten kommer inte att återbetalas till köparen.",
    confirm_removing_card_from_order_question: "Ta bort kortet från beställningen?",
    catch_limits: "Fångstbegränsningar",
    beat_list: "Beatlista",
    all_documents_ok: "All dokumentation OK?",
    all_documents_are_ok: "All dokumentation OK",
    documents_missing: "Dokumentation saknas",
    add_comment: "Lägg till en kommentar",
    show_comments: "Visa kommentarer",
    controller_products: "Kontrolleras av arbetsledare",
    select_comment_status: "Välj kontrolleras av övervakning",
    supervisor_dashboard: "Kontroller dashboard",
    required_catch_report: "Obligatorisk fångstrapport",
    catch_report_settings: "Inställningar för fångstrapport",
    current_link: "Aktuell länk",
    add_attachments_to_billings: "Lägg till bilagor till mina bilagor",
    my_billings: "Mina räkningar",
    report_created_at: "Rapport skapad",
    submit_report: "Skicka in rapporten",
    zones: "Zoner",
    add_new_zone: "Lägg till ny zon",
    edit_zone: "Redigera zon",
    remove_zone: "Ta bort zon",
    confirm_removing: "Bekräfta borttagning",
    only_superadmin_can_remove_zones: "Endast superadministratörer kan ta bort zoner.",
    about_beat: "Om valet",
    valid_from_hours: "Gäller från (tid)",
    validity_duration_in_hours: "Giltighet (timmar)",
    net_sales: "Nettoförsäljningsintäkter",
    net_provision: "Nettoprovision",
    products_sold: "Antal sålda produkter",
    number_of_catches: "Antal fångster",
    counties: "län",
    top_rivers: "Top Rivers",
    number_of_fishermen: "Antal fiskare",
    river_card_stats: "River Card Statistik",
    number_of_cards_total: "Totalt antal",
    number_of_cards_norway: "Nummer i Norge",
    number_of_cards_abroad: "Nummer utomlands",
    number_of_desinfections: "Antal desinfektioner",
    controlled_by_controller: "Styrs av arbetsledare",
    view_all: "Visa alla",
    catch_statistics: "Fångststatistik",
    all_species: "Totalt alla arter",
    killed: "Dödad",
    kg_total: "kg totalt",
    kg_avg_weight: "kg medelvikt",
    last_payment: "Sista betalning",
    number_of_cards_sold: "Antal sålda fiskekort",
    number_of_season_cards_sold: "Antal sålda säsongskort",
    fishing_pressure: "Fisketrykk",
    cards_sold: "Antall solgte fiskekort",
    catch_reports: "Antall fiskekort med fangstrapport",
    fishing_days:"Antall fiskedager",
    fishermen: "Fiskare",
    recent_catches: "Senaste fångster",
    active_fishing_cards: "Aktiva fiskekort",
    active_season_cards: "Aktiva säsongskort",
    river_cards: "River Cards",
    add_river_card: "Lägg till nytt riverkort",
    sold_river_cards: "Sålda River-kort",
    are_you_sure: "Är du säker?",
    river_commissions: "River commissions",
    river_card: "River Card",
    river_card_title: "Titeln på kartan associerad med floden",
    river_card_all_cards: "Alla kort",
    river_cards_expired: "Gamla kort",
    river_cards_active: "Aktive kort",
    see_picture_below: "Se bilden nedan.",
    buy_river_card: "Köp River Card",
    survey: "Utredning",
    old: "Gammal",
    send_survey_1: "Skicka undersökning 1",
    survey_text:
      'Enkäten kan bara skickas en gång per flodkort. Skicka den först i slutet av en säsong. Efter att ha klickat på "Skicka" kommer du inte att kunna skicka det igen.',
    sent_on: "Skickad den",
    number_of_sent_survey_invites: "Antal skickade enkätinbjudningar",
    responded_survey_invites: "Antal svarande på enkätinbjudningar",
    survey_not_sent: "Enkäten har inte skickats",
    survey_not_responded: "Enkäten ej besvarad",
    report_all_rivers: "Alla floder",
    send_email: "E-post skickar",
    limit: "Limit",
    year_of_purchase: "Inköpsår",
    type_1: "Dagligt kort",
    send_to: "Skicka till",
    users: "Användare",
    buy_more: "Köp mer",
    number_of_blocks: "Antal block",
    each_block_is_credit_for_500_emails:
      "Varje blockering är en kredit för 500 e-postmeddelanden. En blockering kostar NOK 250.",
    confirmation_on_new_orders: "Motta bekräftelse på nya beställningar",
    history: "Historia",
    change_made_by: "Ändring gjord av",
    change_value: "Ändrat värde",
    old_value: "Gammalt värde",
    new_value: "Nytt värde",
    user_id: "Användar-ID",
    river_card_commissions: "River card provisions",
    send_final_survey: "Skicka undersökning 2",
    survey_1: "Mellansäsongsundersökning",
    final_survey: "Undersökning efter säsong",
    survey_message: "Enkätmeddelande",
    change_date: "Redigera datum",
    year: "År",
    refunds: "Återbetald",
    refund_tax: "Moms återbetald",
    revenue_total: "Totala betalningar (online + terminal + kontanter)",
    remove_refund: "Ta bort återbetalning",
    comment: "Kommentar",
    new_refund: "Ny återbetalning",
    laksebors_message: "Laksebors meddelande",
    river_orders: "River",
    beat_orders: "Val",
    card_type: "Korttyp",
    show_on_laksebors: "Visa fångststatistik på Laksebørs?",
    this_order_has_other_products_besides_river_cards: "Denna beställning innehåller andra produkter än river-kort.",
    this_order_has_other_products_besides_beat_products:
      "Denna beställning innehåller andra produkter än de valda produkterna.",
    daycard: "Dagskort",
    weekcard: "Veckokort",
    reported_on: "Rapporterade klockan",
    members: "Medlemmar",
    members_of_beat: "Medlemmar av beat",
    refund_commission: "Provision återbetald",
    refund_commission_tax: "Återbetald provisionsmoms",
    cancellation_policy: "Avbokningsregler",
    cancellation_policy_description_1:
    "Här definierar du återbetalningsreglerna för fiskekort köpta i ditt vald. Denna information visas på din valsida.",
    cancellation_policy_description_2:
      "Du anger antalet dagar innan fiskekortets start som det ska vara möjligt att återbetala. Användaren kan själv sköta återbetalningen, om fiskekortet kan återbetalas enligt de regler som gällde vid köptillfället.",
    cancellation_policy_description_3:
      "Exempelvis innebär 3 dagar att kunden kan avboka inntil 3 dagar innan fiskekortet börjar. Ett fiskekort för den 9 juni ska avbokas före den 6 juni, det vill säga senast den 5 juni kl. 23:59.",
    cancellation_policy_description_4:
      "Om du inte vill erbjuda återbetalning kan du ställa in 0 dagar.",
    cancellation_policy_set_terms: "Sätt villkor",
    cancellation_policy_full_refund_limit: "Gräns för 100 % återbetalning",
    cancellation_policy_no_policy_set: "För närvarande finns inga villkor för återbetalning.",
    cancellation_policy_current_terms: "Villkor för",
    cancellation_policy_product: "Produkt",
    cancellation_policy_refund: "Återbetalning",
    cancellation_policy_number_of_days: "Antal dagar",
    days: "dagar",
    currency: "Valuta",
    options: "Val",
    timezone: "Tidsson",
    adminBeatNotificationWarningText:
      "Vald behöver information om kund för att kunna sälja produkter. Gå til Innstillinger → Vald for att fylla ut opplysningene",
    adminNotificationWarningTextPre:
      "Vald behöver information om kunden för att kunna sälja produkter. Vald utan nödvändig information: ",
    adminNotificationWarningTextPost: "Gå til Innstillinger → Vald for att fylla ut opplysningene",
    explanation: "Förklaring",
    gradual_publish_explanation:
      "Med detta val kommer fiskekorten successivt att läggas ut till försäljning baserat på när de är giltiga. Fyll i hur många dagar/timer i forkant av gyldigheten publiseringen skal skje.",
    equipment_configuration: "Utrustningskonfiguration",
    defaultEquipment: "Standard utrustning",
    selling_beats: "Säljsvald",
    choose_beats: "Välj vald",
    unblock: "Ta bort blokkering",
    help_text: "Hjelp",
    scribe_create_fishing_ticket: "Oppretta fiskekort",
    scribe_publish_fishing_tickets: "Publisere fiskekort",
    scribe_edit_fishing_tickets: "Endre fiskekort",
    scribe_delete_fishing_tickets: "Slett fiskekort",
    scribe_edit_river_cards: "Endre navn på elvekortet",
    scribe_publish_river_cards: "Publisere elvekort",
    scribe_about_beat: "Om valdet",
    scribe_about_us: "Om oss",
    scribe_other_facilities: "Andre fasiliteter",
    scribe_accommodation: "Overnatting",
    scribe_images: "Bilder",
    scribe_map: "Kart",
    scribe_optional_page: "Valgfri side",
    scribe_fishing_regulations: "Fiskeregler",
    scribe_catches_message: "Melding på Laksebørs",
    scribe_catches_disinfection: "Desinfisering",
    scribe_customer_register_beat: "Kunderegister vald",
    scribe_customer_register_river: "Kunderegister elv",
    scribe_customer_blocked_users: "Blokkerte brukere",
    scribe_customer_add_bailiff: "Legg til oppsyn",
    scribe_customer_add_station_admin: "Legg til stasjonsadministrator",
    scribe_customer_how_to_block_user: "Hvordan blokkere bruker",
    scribe_customer_how_to_check_blocked_user: "Hvordan sjekke blokkerte brukere",
  },
};
