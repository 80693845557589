import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Row, Col, Form, Label, Input, FormGroup } from "reactstrap";
import Spinner from "../../components/spinner";
import SaveLabel from "../../components/helpers/buttons/save_label";
import { fetch as fetchBeats } from "../../redux/beats/actions";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import {
  fetchBeat as fetchBeatCommissions,
  fetchRiver as fetchRiverCommissions,
  addBeat as addBeatCommission,
  addRiver as addRiverCommission
} from "../../redux/commissions/actions";
const labelWidth = 4;
const inputWidth = 8;

export class Commissions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      beat_id: props.beatId,
      beat: {
        full_commission: null,
        discounted_commission: null,
        special_commission: null
      },
      river_id: props.riverId,
      river: {
        full_commission: null,
        discounted_commission: null,
        special_commission: null
      }
    };
    if(this.props.riverId) {
      this.props.dispatch(fetchRiverCommissions(this.props.riverId));
    }

    if(this.props.beatId) {
      this.props.dispatch(fetchBeatCommissions(this.props.beatId));
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.beatId !== prevProps.beatId) { 
      this.setState({
        beat_id: this.props.beatId,
        beat: {
          full_commission: null,
          discounted_commission: null,
          special_commission: null
        }
      })

      if(this.props.beatId) {
        this.props.dispatch(fetchBeatCommissions(this.props.beatId));
      }
    }

    if (this.props.riverId !== prevProps.riverId) { 
      this.setState({
        river_id: this.props.riverId,
        river: {
          full_commission: null,
          discounted_commission: null,
          special_commission: null
        }
      })

      if(this.props.riverId) {
        this.props.dispatch(fetchRiverCommissions(this.props.riverId));
      }
    }

  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }
    
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
  }

  onChangeBeatCommissionValue(newState) {
    this.setState(prevState => ({ beat: { ...prevState.beat, ...newState } }));
  }
  
  onChangeRiverCommissionValue(newState) {
    this.setState(prevState => ({ river: { ...prevState.river, ...newState } }));
  }


  onBeatSubmit(e) {
    e.preventDefault();

    const full = this.checkCommissionValue("full_commission", "beat");
    const discounted = this.checkCommissionValue("discounted_commission", "beat");
    const special = this.checkCommissionValue("special_commission", "beat");

    const commissions = {
      beat_id: this.state.beat_id,
      beat_commission_full: full === "" ? null : full,
      beat_commission_discounted: discounted === "" ? null : discounted,
      beat_commission_special: special === "" ? null : special
    };

    this.props.dispatch(addBeatCommission(commissions, this.state.beat_id));
  }

  onRiverSubmit(e) {
    e.preventDefault();

    const full = this.checkCommissionValue("full_commission", "river");
    const discounted = this.checkCommissionValue("discounted_commission", "river");
    const special = this.checkCommissionValue("special_commission", "river");

    const commissions = {
      river_id: this.state.river_id,
      river_commission_full: full === "" ? null : full,
      river_commission_discounted: discounted === "" ? null : discounted,
      river_commission_special: special === "" ? null : special
    };

    this.props.dispatch(addRiverCommission(commissions, this.state.river_id));
  }

  checkCommissionValue(key, type) {
    if (this.state[type][key] !== null) {
      return this.state[type][key];
    }

    if (this.props.commissions[type].data[key] && this.props.commissions[type].data[key].value) {
      return this.props.commissions[type].data[key].value;
    }

    return "";
  }

  render() {
    if (!this.props.beats.data || !this.props.rivers.data) {
      return <Spinner />;
    }

    if (this.props.beatId && !this.props.commissions.beat) {
      return <Spinner />;
    }

    if (this.props.riverId && !this.props.commissions.river) {
      return <Spinner />;
    }

    return (
      <div>
       {this.props.beatId && <div className="panel">
          <h2 className="title">{this.props.intl.formatMessage({ id: "beat_commissions" })}</h2>
          <Row>
            <Col xs={12}>
              {!this.props.beats.data ? (
                <Spinner />
              ) : (
                <Form>
                  {this.state.beat_id && (this.props.commissions.beat.data || (!this.props.commissions.beat.data && this.props.commissions.beat.loading)) ? (
                    <div>
                      {this.props.commissions.beat.loading ? (
                        <Spinner />
                      ) : (
                        <div>
                          <FormGroup row>
                            <Label for="full_commission" xs={12} sm={labelWidth}>
                              {this.props.intl.formatMessage({ id: "full_commission" })}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <Input
                                type="number"
                                name="full_commission"
                                id="full_commission"
                                required
                                value={this.checkCommissionValue("full_commission", "beat")}
                                onChange={e => this.onChangeBeatCommissionValue({ full_commission: e.target.value })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="discounted_commission" xs={12} sm={labelWidth}>
                              {this.props.intl.formatMessage({ id: "discounted_commission" })}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <Input
                                type="number"
                                name="discounted_commission"
                                id="discounted_commission"
                                required
                                value={this.checkCommissionValue("discounted_commission", "beat")}
                                onChange={e => this.onChangeBeatCommissionValue({ discounted_commission: e.target.value })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="special_commission" xs={12} sm={labelWidth}>
                              {this.props.intl.formatMessage({ id: "special_commission" })}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <Input
                                type="number"
                                name="special_commission"
                                id="special_commission"
                                required
                                value={this.checkCommissionValue("special_commission", "beat")}
                                onChange={e => this.onChangeBeatCommissionValue({ special_commission: e.target.value })}
                              />
                            </Col>
                          </FormGroup>
                          <hr />
                          <FormGroup row style={{ marginBottom: 0 }}>
                            <Col xs={12} className="text-right">
                              <Button color="secondary" type="submit" onClick={e => this.onBeatSubmit(e)}>
                                <SaveLabel intl={this.props.intl} />
                              </Button>
                            </Col>
                          </FormGroup>
                        </div>
                      )}
                    </div>
                  ) : null}
                </Form>
              )}
            </Col>
          </Row>
        </div> }

        {this.props.riverId && <div className="panel">
          <h2 className="title">{this.props.intl.formatMessage({ id: "river_card_commissions" })}</h2>

          <Row>
            <Col xs={12}>
              {!this.props.rivers.data ? (
                <Spinner />
              ) : (
                <Form>
                  {this.state.river_id && (this.props.commissions.river.data || (!this.props.commissions.river.data && this.props.commissions.river.loading)) ? (
                    <div>
                      {this.props.commissions.river.loading ? (
                        <Spinner />
                      ) : (
                        <div>
                          <FormGroup row>
                            <Label for="full_commission" xs={12} sm={labelWidth}>
                              {this.props.intl.formatMessage({ id: "full_commission" })}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <Input
                                type="number"
                                name="full_commission"
                                id="full_commission"
                                required
                                value={this.checkCommissionValue("full_commission", "river")}
                                onChange={e => this.onChangeRiverCommissionValue({ full_commission: e.target.value })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label for="discounted_commission" xs={12} sm={labelWidth}>
                              {this.props.intl.formatMessage({ id: "discounted_commission" })}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <Input
                                type="number"
                                name="discounted_commission"
                                id="discounted_commission"
                                required
                                value={this.checkCommissionValue("discounted_commission", "river")}
                                onChange={e => this.onChangeRiverCommissionValue({ discounted_commission: e.target.value })}
                              />
                            </Col>
                          </FormGroup>
                          <hr />
                          <FormGroup row style={{ marginBottom: 0 }}>
                            <Col xs={12} className="text-right">
                              <Button color="secondary" type="submit" onClick={e => this.onRiverSubmit(e)}>
                                <SaveLabel intl={this.props.intl} />
                              </Button>
                            </Col>
                          </FormGroup>
                        </div>
                      )}
                    </div>
                  ) : null}
                </Form>
              )}
            </Col>
          </Row>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  commissions: state.Commissions,
  authentication: state.Auth,
  beats: state.Beats,
  rivers: state.Rivers
});

export default withRouter(injectIntl(connect(mapStateToProps)(Commissions)));
