export const FETCH_DASHBOARD_CONTROLLER_STATISTICS = "FETCH_DASHBOARD_CONTROLLER_STATISTICS";
export const FETCH_DASHBOARD_CONTROLLER_STATISTICS_SUCCESS = "FETCH_DASHBOARD_CONTROLLER_STATISTICS_SUCCESS";
export const FETCH_DASHBOARD_CONTROLLER_STATISTICS_FAILURE = "FETCH_DASHBOARD_CONTROLLER_STATISTICS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_CONTROLLER_STATISTICS,
    payload
  };
}
