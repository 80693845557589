export default {
  nb: {
    date: "Dato",
    time: "Tid",
    event: "Hendelse",
    allDay: "Hele dagen",
    week: "Uke",
    work_week: "Arbeidsuke",
    day: "Dag",
    month: "Måned",
    previous: "Tilbake",
    next: "Neste",
    yesterday: "I går",
    tomorrow: "I morgen",
    today: "I dag",
    agenda: "Dagsorden",
    noEventsInRange: "Det er ingen hendelser i dette området."
  },

  en: {
    date: "Date",
    time: "Time",
    event: "Event",
    allDay: "All day",
    week: "Week",
    work_week: "Work week",
    day: "Day",
    month: "Month",
    previous: "Back",
    next: "Next",
    yesterday: "Yesterday",
    tomorrow: "Yesterday",
    today: "Today",
    agenda: "Agenda",
    noEventsInRange: "There are no events in this range."
  },

  sv: {
    date: "Datum",
    time: "Tid",
    event: "Event",
    allDay: "Hela dagen",
    week: "Vecka",
    work_week: "Arbetsvecka",
    day: "Dag",
    month: "Månad",
    previous: "Tillbaka",
    next: "Nästa",
    yesterday: "Igår",
    tomorrow: "I morgon",
    today: "Idag",
    agenda: "Agenda",
    noEventsInRange: "Det finns inga händelser i det här intervallet."
  }
};
