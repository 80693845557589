import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Button, Modal, Row, Col, Input, Select, Checkbox, Alert, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Spinner from "../../../spinner";
import transactionFee from "../../../../services/transaction_fee";
import ANT_CONFIG from "../../../../constants/antconfig";
import { fetch, fetchTypes } from "../../../../redux/season_cards/actions";
import { quiteAdd } from "../../../../redux/customers/actions";
import { add as addBooking } from "../../../../redux/booking/actions";

const SPIN_ICON = <LoadingOutlined className="select-spinner" spin />;

class ModalOrderSeasonCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      member_only: false,
      quantity: 1,
      beat_id: props.selectedBeat,
      date_start: props.modalData.date_start || null,
      currentPage: 0,
      submitted: false,
      season_fishing_cards: [],
      show_new_user_form: false,
      season_fishing_card_type_id: null,
      user: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
      },
      additional_user: [],
      payment_method: false,
      payment_method_selected: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchTypes(this.state.beat_id));

    this.props.dispatch(
      fetch({
        beat_id: this.state.beat_id,
        year: this.state.date_start ? moment(this.state.date_start).format("YYYY") : moment().format("YYYY"),
      }),
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customers.new_user !== this.props.customers.new_user) {
      this.onComponentUpdate();
    }
  }

  onComponentUpdate() {
    this.setState((prevState) => ({
      user: {
        id: this.props.customers.new_user.id,
        first_name: prevState.user.first_name,
        last_name: prevState.user.last_name,
        email: this.props.customers.new_user.email,
      },
    }));
  }

  onSubmit(fee) {
    this.setState({
      submitted: true,
    });

    const data = {
      user_id: this.state.user.id,
      season_fishing_cards: this.state.season_fishing_cards.map((i, key) => ({
        ...i,
        owner_name:
          key === 0 ? `${this.state.user.first_name} ${this.state.user.last_name}` : this.state.additional_user[key]?.name,
        send_to_email: key > 0 ? this.state.additional_user[key]?.send_to_email : "",
      })),
      payment_method: this.state.payment_method ? this.state.payment_method_selected : "nets",
    };

    this.props.dispatch(addBooking(data));
  }

  onCancel = () => {
    this.props.toggleModal();
  };

  onSelectUser(e) {
    const customer = this.props.customers.data.filter((i) => i.id === e)[0];

    if (customer) {
      this.setState({
        user: {
          id: customer.id,
          first_name: customer.first_name,
          last_name: customer.last_name,
          email: customer.email,
        },
      });
    }
  }

  onChangeUserInput(newState) {
    this.setState((prevState) => ({
      user: { ...prevState.user, ...newState },
    }));
  }

  onSaveNewUser() {
    this.props.dispatch(
      quiteAdd({
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        email: this.state.user.email,
      }),
    );
  }

  onCardSelect(card, e) {
    this.setState((prevState) => ({
      season_fishing_cards: [
        ...prevState.season_fishing_cards.filter((i) => i.current_page !== prevState.currentPage),
        {
          current_page: prevState.currentPage,
          id: card.id,
          price_amount_id: e,
          amount: card.price_amounts?.filter((i) => i.id === e)[0]?.amount,
        },
      ],
    }));
  }

  onChangeUserInfo(key, value) {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [key]: value,
      },
    }));
  }

  onChangeAdditionalUser(name) {
    this.setState((prevState) => ({
      additional_user: {
        ...prevState.additional_user,
        [prevState.currentPage - 1]: {
          name,
          send_to_email: prevState.additional_user[prevState.currentPage - 1]?.send_to_email,
        },
      },
    }));
  }

  onChangeAdditionalUserEmail(send_to_email) {
    this.setState((prevState) => ({
      additional_user: {
        ...prevState.additional_user,
        [prevState.currentPage - 1]: {
          send_to_email,
          name: prevState.additional_user[prevState.currentPage - 1]?.name,
        },
      },
    }));
  }

  onCardTypeChange(id) {
    this.setState({
      season_fishing_card_type_id: id,
    });
  }

  addNewUser() {
    this.setState((prevState) => ({
      show_new_user_form: true,
      user: {
        ...prevState.user,
        id: null,
      },
    }));
  }

  changePage(currentPage) {
    this.setState({
      currentPage,
    });
  }

  changePaymentMethod(e) {
    this.setState({
      payment_method_selected: e.target.value,
    });
  }

  render() {
    const {
      currentPage,
      quantity,
      member_only,
      modal,
      show_new_user_form,
      season_fishing_cards,
      payment_method,
      submitted,
      season_fishing_card_type_id,
      user,
      additional_user,
    } = this.state;
    const { intl, season_cards, booking, customers } = this.props;
    const availableCards = season_cards.data.filter(
      (i) =>
          i.season_fishing_card_type.members_only === Number(member_only) &&
        !i.package_id &&
        i.season_fishing_card_type.id === season_fishing_card_type_id,
    );
    const total = season_fishing_cards.map((i) => i.amount)?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

    if (booking.data && !booking.loading && !booking.error && submitted) {
      if (payment_method) {
        this.onCancel();

        return false;
      }

      return (
        <Modal
          width={800}
          title={intl.formatMessage({
            id: "payment_link",
          })}
          visible={modal}
          onCancel={this.onCancel}
          footer={[
            <Button size={ANT_CONFIG.size} key="1" type="primary" onClick={this.onCancel}>
              {intl.formatMessage({ id: "ok" })}
            </Button>,
          ]}
        >
          <Row type="flex" gutter={[20, 20]}>
            <Col xs={24} sm={18}>
              <Input type="text" size={ANT_CONFIG.size} value={booking.data.terminal_urls.url} disabled />
            </Col>
            <Col xs={24} sm={6}>
              <Button href={booking.data.terminal_urls.url} type="danger" size={ANT_CONFIG.size} style={{ width: "100%" }}>
                {intl.formatMessage({ id: "pay_now" })}
              </Button>
            </Col>
          </Row>
        </Modal>
      );
    }

    return (
      <Modal
        width={800}
        title={intl.formatMessage({
          id: "new_booking",
        })}
        visible={modal}
        onCancel={this.onCancel}
        footer={[
          currentPage > 0 && (
            <Button
              size={ANT_CONFIG.size}
              key="1"
              loading={season_cards.loading || booking.loading}
              onClick={() => this.changePage(currentPage - 1)}
            >
              {intl.formatMessage({ id: "back" })}
            </Button>
          ),
          currentPage === 0 && quantity <= availableCards.length && season_fishing_card_type_id && (
            <Button
              size={ANT_CONFIG.size}
              key="2"
              loading={season_cards.loading}
              type="primary"
              onClick={() => this.changePage(currentPage + 1)}
            >
              {intl.formatMessage({ id: "next" })}
            </Button>
          ),
          currentPage > 0 && currentPage <= quantity && (
            <Button
              size={ANT_CONFIG.size}
              key="3"
              loading={season_cards.loading}
              type="primary"
              onClick={() => this.changePage(currentPage + 1)}
            >
              {intl.formatMessage({ id: "next" })}
            </Button>
          ),
          currentPage > quantity && (
            <Button
              size={ANT_CONFIG.size}
              key="4"
              loading={season_cards.loading || booking.loading}
              type="primary"
              onClick={() => this.onSubmit(transactionFee(total))}
            >
              {intl.formatMessage({ id: "confirm" })}
            </Button>
          ),
        ]}
      >
        {currentPage === 0 && (
          <Row type="flex" gutter={[20, 20]}>
            <Col xs={24}>
              <label className="ant-label" htmlFor="season_card_type">
                {intl.formatMessage({ id: "season_card_type" })}
              </label>
              <Select
                id="season_card_type"
                size={ANT_CONFIG.size}
                loading={season_cards.type.loading}
                value={season_fishing_card_type_id}
                style={{ width: "100%" }}
                placeholder={`${intl.formatMessage({
                  id: "select_card_type",
                })}`}
                onChange={(e) => this.onCardTypeChange(e)}
                notFoundContent={season_cards.type.loading ? SPIN_ICON : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              >
                {season_cards.type.data?.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={24}>
              <label className="ant-label" htmlFor="quantity">
                {intl.formatMessage({ id: "quantity" })}
              </label>
              <Input
                type="number"
                name="quantity"
                id="quantity"
                size={ANT_CONFIG.size}
                value={quantity}
                onChange={(e) =>
                  this.setState({
                    quantity: e.target.value > 0 ? e.target.value : 1,
                  })
                }
              />
            </Col>
            <Col xs={24}>
              <Checkbox
                checked={member_only}
                size={ANT_CONFIG.size}
                onChange={() =>
                  this.setState((prevState) => ({
                    member_only: !prevState.member_only,
                  }))
                }
              >
                {intl.formatMessage({ id: "member_only" })}
              </Checkbox>
            </Col>
            <Col xs={24}>
              <Checkbox
                checked={this.state.payment_method}
                onChange={(event) => {
                  const checked = event.target.checked;

                  if (checked) this.setState({ payment_method: true, payment_method_selected: "manual" });
                  else this.setState({ payment_method: false, payment_method_selected: null });
                }}
              >
                {this.props.intl.formatMessage({
                  id: "payment_performed_manually",
                })}
              </Checkbox>
            </Col>
          </Row>
        )}

        {currentPage === 1 && (
          <React.Fragment>
            {show_new_user_form ? (
              <React.Fragment>
                <label htmlFor="or_add_new_customer" className="ant-label">
                  {`${intl.formatMessage({ id: "new_customer" })}`}
                </label>

                <Row type="flex" gutter={[20, 20]}>
                  <Col xs={24} sm={12}>
                    <Input
                      type="text"
                      size={ANT_CONFIG.size}
                      disabled={customers.new_user}
                      placeholder={intl.formatMessage({
                        id: "first_name",
                      })}
                      value={user.first_name}
                      onChange={(e) => this.onChangeUserInput({ first_name: e.target.value })}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Input
                      type="text"
                      size={ANT_CONFIG.size}
                      disabled={customers.new_user}
                      placeholder={intl.formatMessage({
                        id: "last_name",
                      })}
                      value={user.last_name}
                      onChange={(e) => this.onChangeUserInput({ last_name: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row type="flex" gutter={[20, 20]}>
                  <Col xs={24}>
                    <Input
                      size={ANT_CONFIG.size}
                      type="email"
                      disabled={customers.new_user}
                      placeholder={intl.formatMessage({
                        id: "email",
                      })}
                      value={user.email}
                      onChange={(e) => this.onChangeUserInput({ email: e.target.value })}
                    />
                  </Col>
                </Row>

                {customers.loading && (
                  <Row type="flex" gutter={[20, 20]}>
                    <Col xs={24}>
                      <Spinner />
                    </Col>
                  </Row>
                )}

                {!customers.loading && !customers.new_user && (
                  <Row type="flex" gutter={[20, 20]}>
                    <Col xs={24}>
                      <Button
                        size={ANT_CONFIG.size}
                        type="primary"
                        onClick={() => this.onSaveNewUser()}
                        style={{ width: "100%" }}
                      >
                        {intl.formatMessage({ id: "save" })}
                      </Button>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label htmlFor="select_customer" xs={12} className="ant-label">
                  {`${intl.formatMessage({ id: "select_customer" })}`}
                </label>
                <Col xs={24}>
                  <Select
                    showSearch
                    id="select_customer"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${intl.formatMessage({
                      id: "select_customer",
                    })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.onSelectUser(e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {customers.data.map((i) => (
                      <Select.Option key={i.id} value={i.id}>
                        {`${i.first_name} ${i.last_name} (${i.email})`}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                {user.id ? (
                  <Row type="flex" gutter={[20, 20]} style={{ marginTop: 15 }}>
                    <Col xs={24} sm={12}>
                      <Input
                        type="text"
                        size={ANT_CONFIG.size}
                        value={user.first_name}
                        onChange={(e) => this.onChangeUserInfo("first_name", e.target.value)}
                      />
                    </Col>
                    <Col xs={24} sm={12}>
                      <Input
                        type="text"
                        size={ANT_CONFIG.size}
                        value={user.last_name}
                        onChange={(e) => this.onChangeUserInfo("last_name", e.target.value)}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row type="flex" gutter={[20, 20]} style={{ marginTop: 15 }}>
                    <Col xs={24}>
                      <Button size={ANT_CONFIG.size} type="primary" onClick={() => this.addNewUser()} style={{ width: "100%" }}>
                        {intl.formatMessage({ id: "add_new_customer" })}
                      </Button>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )}
            <Row type="flex" gutter={[20, 20]} style={{ paddingTop: 30 }}>
              <Col xs={24}>
                <label htmlFor="select_price" xs={12} className="ant-label">
                  {`${intl.formatMessage({ id: "select_price" })}`}
                </label>
                <Select
                  showSearch
                  id="select_price"
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${intl.formatMessage({
                    id: "select_price",
                  })}`}
                  optionFilterProp="children"
                  value={season_fishing_cards.filter((i) => i.current_page === currentPage)?.[0]?.price_amount_id || null}
                  onChange={(e) => this.onCardSelect(availableCards[currentPage - 1], e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {availableCards[currentPage - 1].price_amounts?.map((i) => (
                    <Select.Option key={i.id} value={i.id}>
                      {`${i.price_type?.name} - ${i.amount} NOK`}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {currentPage > 1 && currentPage <= quantity && (
          <>
            <Row type="flex" gutter={[20, 20]}>
              <Col xs={24}>
                <Input
                  type="text"
                  size={ANT_CONFIG.size}
                  placeholder={intl.formatMessage({
                    id: "first_and_last_name",
                  })}
                  onChange={(e) => this.onChangeAdditionalUser(e.target.value)}
                  value={additional_user?.[currentPage - 1]?.name ? additional_user[currentPage - 1].name : ""}
                />
              </Col>
            </Row>

            <Row type="flex" gutter={[20, 20]}>
              <Col xs={24}>
                <Input
                  type="email"
                  size={ANT_CONFIG.size}
                  placeholder={intl.formatMessage({
                    id: "email",
                  })}
                  onChange={(e) => this.onChangeAdditionalUserEmail(e.target.value)}
                  value={
                    additional_user?.[currentPage - 1]?.send_to_email ? additional_user[currentPage - 1].send_to_email : ""
                  }
                />
              </Col>
            </Row>

            <Row type="flex" gutter={[20, 20]}>
              <Col xs={24}>
                <label htmlFor="select_price" className="ant-label">
                  {`${intl.formatMessage({ id: "select_price" })}`}
                </label>
                <Select
                  showSearch
                  id="select_price"
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${intl.formatMessage({
                    id: "select_price",
                  })}`}
                  optionFilterProp="children"
                  value={season_fishing_cards.filter((i) => i.current_page === currentPage)?.[0]?.price_amount_id || null}
                  onChange={(e) => this.onCardSelect(availableCards[currentPage - 1], e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {availableCards[currentPage - 1].price_amounts?.map((i) => (
                    <Select.Option key={i.id} value={i.id}>
                      {`${i.price_type?.name} - ${i.amount} NOK`}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        )}

        {currentPage > quantity && (
          <React.Fragment>
            <Row type="flex" gutter={[20, 20]}>
              <Col xs={24}>
                <label htmlFor="total_price" className="ant-label">
                  {intl.formatMessage({ id: "total_price" })}
                </label>
                <Input size={ANT_CONFIG.size} type="text" name="total_price" id="total_price" disabled value={total} />
              </Col>
            </Row>

            <Row type="flex" gutter={[20, 20]}>
              <Col xs={24}>
                <label htmlFor="fee" className="ant-label">
                  {intl.formatMessage({ id: "fee" })}
                </label>
                <Input size={ANT_CONFIG.size} type="text" name="fee" id="fee" disabled value={transactionFee(total)} />
              </Col>
            </Row>

            {payment_method && (
              <Row type="flex" gutter={[20, 20]}>
                <Col xs={24}>
                  <Alert
                    message={`${intl.formatMessage({ id: "remember" })}!`}
                    description={intl.formatMessage({
                      id: "payment_performed_manually_2",
                    })}
                    type="warning"
                    showIcon
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  fishing_cards: state.FishingCards,
  booking: state.Booking,
  customers: state.Customers,
  season_cards: state.SeasonCards,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalOrderSeasonCards)));
