import strings from "./languages/strings";

export default {
  ...strings.strings,
  product_types: {
    ...strings.product_types
  },
  calendar: {
    ...strings.calendar
  },
  package_types: {
    ...strings.package_types
  },
  response: {
    ...strings.response
  },
  error: {
    ...strings.error
  }
};
