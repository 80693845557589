export const FETCH_FISH_TYPES = "FETCH_FISH_TYPES";
export const FETCH_FISH_TYPES_SUCCESS = "FETCH_FISH_TYPES_SUCCESS";
export const FETCH_FISH_TYPES_FAILURE = "FETCH_FISH_TYPES_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_FISH_TYPES,
    payload
  };
}
