import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";
import translations from "../../constants/strings";
import { DEFAULT_LANGUAGE } from "../../constants";

const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/beat-options/${action.payload}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_BEAT_OPTIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_BEAT_OPTIONS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_BEAT_OPTIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, "admin/beat-organization", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_BEAT_OPTIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(translations.error[lang].fill_all_fields);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.EDIT_BEAT_OPTIONS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_BEAT_OPTIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* editCardLimits(action) {
  try {
    const response = yield call(API.call, "admin/card-per-day-limit", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_BEAT_CARD_LIMIT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.EDIT_BEAT_CARD_LIMIT_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_BEAT_CARD_LIMIT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_BEAT_OPTIONS, fetch), takeEvery(ACTION.EDIT_BEAT_OPTIONS, edit), takeEvery(ACTION.EDIT_BEAT_CARD_LIMIT, editCardLimits)]);
}
