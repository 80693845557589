export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAILURE = "FETCH_ARTICLE_FAILURE";

export const STORE_ARTICLE = "STORE_ARTICLE";
export const STORE_ARTICLE_SUCCESS = "STORE_ARTICLE_SUCCESS";
export const STORE_ARTICLE_FAILURE = "STORE_ARTICLE_FAILURE";

export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAILURE = "UPDATE_ARTICLE_FAILURE";

export const UPDATE_IMAGE_ARTICLE = "UPDATE_IMAGE_ARTICLE";
export const UPDATE_IMAGE_ARTICLE_SUCCESS = "UPDATE_IMAGE_ARTICLE_SUCCESS";
export const UPDATE_IMAGE_ARTICLE_FAILURE = "UPDATE_IMAGE_ARTICLE_FAILURE";

export function fetch(id) {
  return {
    type: FETCH_ARTICLE,
    id
  };
}

export function store(data) {
  return {
    type: STORE_ARTICLE,
    data
  };
}

export function update(data, id) {
  return {
    type: UPDATE_ARTICLE,
    data,
    id
  };
}

export function updateImage(data, id) {
  return {
    type: UPDATE_IMAGE_ARTICLE,
    data,
    id
  };
}
