import * as ACTION from "./actions";

const INIT_STATE = {
  data: undefined,
  loading: false,
  error: false,
  booking: undefined,
  success: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.GET_INFO:
      return {
        ...state,
        loading: true,
      };

    case ACTION.GET_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };

    case ACTION.GET_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.SEND_MAIL:
      return {
        ...state,
        success: false,
        loading: true,
      };

    case ACTION.SEND_MAIL_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };

    case ACTION.SEND_MAIL_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
      };

    case ACTION.ORDER_ADDITIONAL_EMAILS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ORDER_ADDITIONAL_EMAILS_SUCCESS:
      return {
        ...state,
        booking: action.payload,
        loading: false,
        error: false,
      };

    case ACTION.ORDER_ADDITIONAL_EMAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
