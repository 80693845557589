import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetchBeat(action) {
  try {
    const response = yield call(API.call, `admin/beat-commission/${action.payload}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_BEAT_COMMISSIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_BEAT_COMMISSIONS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_BEAT_COMMISSIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchRiver(action) {
  try {
    const response = yield call(API.call, `admin/river-commission/${action.payload}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_RIVER_COMMISSIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_RIVER_COMMISSIONS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_RIVER_COMMISSIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* addBeat(action) {
  try {
    const response = yield call(API.call, "admin/beat-commission", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_BEAT_COMMISSIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetchBeat, action);
      yield put({
        type: ACTION.ADD_BEAT_COMMISSIONS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_BEAT_COMMISSIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* addRiver(action) {
  try {
    const response = yield call(API.call, "admin/river-commission", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_RIVER_COMMISSIONS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetchRiver, action);
      yield put({
        type: ACTION.ADD_RIVER_COMMISSIONS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_RIVER_COMMISSIONS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_BEAT_COMMISSIONS, fetchBeat),
    takeEvery(ACTION.FETCH_RIVER_COMMISSIONS, fetchRiver),
    takeEvery(ACTION.ADD_BEAT_COMMISSIONS, addBeat),
    takeEvery(ACTION.ADD_RIVER_COMMISSIONS, addRiver),
  ]);
}
