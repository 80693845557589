import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal, Card, Col, Row, PageHeader, Select, Input, Checkbox } from "antd";

import { add as addStation, edit as editStation, show as showStation, remove as removeStation } from "../../redux/stations/actions";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import Spinner from "../../components/spinner";
import riverOptions from "../../services/select_river_options";
import ANT_CONFIG from "../../constants/antconfig";

const { confirm } = Modal;

class Station extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: props.match.params.id === "new",
      id: props.match.params.id,
      updated: false,
      data: {
        name: undefined,
        river_id: this.props.authentication.data.river[0],
        staffed: false
      }
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.dispatch(showStation(this.props.match.params.id));
    }

    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
  }

  componentDidUpdate() {
    if (this.props.stations.station && this.props.stations.station.id === parseInt(this.props.match.params.id, 10)) {
      if (!this.state.updated) {
        this.onComponentUpdate(this.props.stations.station);
      }
    }
  }

  onComponentUpdate(data) {
    this.setState({
      updated: true,
      data
    });
  }

  onSave(e) {
    e.preventDefault();

    this.state.isNew ? this.props.dispatch(addStation(this.state.data)) : this.props.dispatch(editStation(this.state.id, this.state.data));
  }

  onChangeValue(newState) {
    this.setState(prevState => ({ data: { ...prevState.data, ...newState } }));
  }

  handleRiverChange(e) {
    this.props.dispatch(selectRiver(e));
    this.onChangeValue({ river_id: e });
  }

  showConfirm() {
    if (this.props.stations.loading) {
      return false;
    }

    confirm({
      confirmLoading: this.props.stations.loading,
      title: this.props.intl.formatMessage({ id: "confirm_delete_question" }),
      okText: this.props.intl.formatMessage({ id: "yes" }),
      okType: "danger",
      onOk: () => this.handleOk(),
      onCancel() {}
    });
  }

  handleOk() {
    return new Promise((resolve, reject) => {
      this.props
        .dispatch(removeStation(this.state.id))
        .then(e => {
          if (e && e.success) {
            this.props.history.push("/stations");
          }
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  render() {
    if (this.props.rivers.loading || !this.props.rivers.data) {
      return <Spinner />;
    }

    const selectRivers = riverOptions(this.props.rivers, this.props.authentication);

    return (
      <React.Fragment>
        <PageHeader title={this.state.isNew ? this.props.intl.formatMessage({ id: "new_station" }) : this.props.intl.formatMessage({ id: "update_station" })} />

        <Row>
          <Col xs={24}>
            <Card style={{ marginTop: 20 }}>
              <div className="container-600">
                <Row>
                  {selectRivers.length > 1 ? (
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="select_river">
                        {this.props.intl.formatMessage({ id: "select_river" })}
                      </label>
                      <Select
                        showSearch
                        id="select_river"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_river" })}`}
                        optionFilterProp="children"
                        onChange={e => this.handleRiverChange(e)}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        value={this.state.data.river_id}
                      >
                        {selectRivers.map((i, key) => (
                          <Select.Option key={key} value={i.value}>
                            {i.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  ) : null}

                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <Row gutter={8}>
                      <Col>
                        <label className="ant-label" htmlFor="name">
                          {this.props.intl.formatMessage({ id: "name" })}
                        </label>
                      </Col>
                      <Col>
                        <Input
                          size={ANT_CONFIG.size}
                          name="name"
                          id="name"
                          value={this.state.data.name || ""}
                          onChange={e => this.onChangeValue({ name: e.target.value })}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <Checkbox checked={this.state.data.staffed} onChange={() => this.onChangeValue({ staffed: !this.state.data.staffed })}>
                      {this.props.intl.formatMessage({ id: "staff" })}
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} className="text-right">
                    <Button
                      type="danger"
                      onClick={e => this.showConfirm(e)}
                      disabled={this.props.stations.loading}
                      size={ANT_CONFIG.size}
                      icon={<DeleteOutlined />}
                    >
                      {this.props.intl.formatMessage({ id: "delete" })}
                    </Button>
                    <Button type="primary" style={{ marginLeft: 8 }} onClick={e => this.onSave(e)} loading={this.props.stations.loading} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({ id: "save" })}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth,
  rivers: state.Rivers,
  stations: state.Stations
});

export default withRouter(injectIntl(connect(mapStateToProps)(Station)));
