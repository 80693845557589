import React from "react";
import { Button, Timeline, Modal } from "antd";
import moment from "moment";
import { injectIntl } from "react-intl";

import ANT_CONFIG from "../../../constants/antconfig";

const { Item } = Timeline;

const CommentsList = ({ intl, data, closeModal }) => {
  return (
    <Modal
      width={800}
      visible={!!data}
      onCancel={closeModal}
      footer={[
        <Button size={ANT_CONFIG.size} key="submit" type="primary" onClick={closeModal}>
          {intl.formatMessage({ id: "ok" })}
        </Button>,
      ]}
    >
      {data && (
        <Timeline>
          {data.map((item) => {
            const { status, date, comment } = item;
            let color = "red";
            let title = intl.formatMessage({ id: "documents_missing" });

            if (status) {
              color = "green";
              title = intl.formatMessage({ id: "all_documents_are_ok" });
            }

            return (
              <Item color={color}>
                <em>{moment(date).format("DD.MM.YYYY HH:mm")}</em>
                <p style={{ margin: 0 }}>
                  <h2>
                    <strong>{title}</strong>
                  </h2>
                </p>
                {comment && <p>{comment}</p>}
              </Item>
            );
          })}
        </Timeline>
      )}
    </Modal>
  );
};

export default injectIntl(CommentsList);
