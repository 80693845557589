import * as ACTION from "./actions";

const INIT_STATE = {
    loading: false,
    error: false,
    data: undefined
};

export default function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case ACTION.FETCH_NEWSFEED_ACTIVITIES:
            return {
                ...state,
                loading: true
            };

        case ACTION.FETCH_NEWSFEED_ACTIVITIES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: false
            };

        case ACTION.FETCH_NEWSFEED_ACTIVITIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };

        case ACTION.DELETE_NEWSFEED_ACTIVITY:
            return {
                ...state,
                loading: true
            };

        case ACTION.DELETE_NEWSFEED_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false
            };

        case ACTION.DELETE_NEWSFEED_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: true
            };

        default:
            return state;
    }
}
