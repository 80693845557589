export const FETCH_DASHBOARD_BEAT_ADMIN_DAILY_CARDS = "FETCH_DASHBOARD_BEAT_ADMIN_DAILY_CARDS";
export const FETCH_DASHBOARD_BEAT_ADMIN_DAILY_CARDS_SUCCESS = "FETCH_DASHBOARD_BEAT_ADMIN_DAILY_CARDS_SUCCESS";
export const FETCH_DASHBOARD_BEAT_ADMIN_DAILY_CARDS_FAILURE = "FETCH_DASHBOARD_BEAT_ADMIN_DAILY_CARDS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_BEAT_ADMIN_DAILY_CARDS,
    payload
  };
}
