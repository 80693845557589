import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Checkbox, Modal, Row, Col, Input, Table, Select } from "antd";
import uuidv1 from "uuid";

import Spinner from "../../../spinner";
import { editType as editCardType, fetchTypes as fetchCardTypes } from "../../../../redux/fishing_cards/actions";
import ANT_CONFIG from "../../../../constants/antconfig";

class ModalEditCardType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      beat_id: props.selectedBeat,
      data: {
        id: null,
        name: "",
        description: "",
        flexible_time: false,
        members_only: false,
      },
      price_types: [],
      current_price_types: [],
      priceTypeModalVisible: false,
      temporary_price: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchCardTypes(this.state.beat_id));
  }

  componentDidUpdate() {
    if (!this.props.fishing_cards.type.loading && !this.props.fishing_cards.type.error && this.state.submitted) {
      this.onCancel();
    }
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onCardTypeChange(id) {
    const cardType = this.props.fishing_cards.type.data.filter((i) => i.id === id)[0];

    this.setState({
      data: {
        id,
        members_only: cardType.members_only,
        name: cardType.name,
        description: cardType.description,
        flexible_time: cardType.flexible_time,
      },
      price_types: cardType.price_types,
      current_price_types: cardType.price_types,
    });
  }

  onChangeTemporaryPrice(newState) {
    this.setState((prevState) => ({ temporary_price: { ...prevState.temporary_price, ...newState } }));
  }

  onSubmit = () => {
    this.setState({
      submitted: true,
    });

    const currentPriceTypes = this.state.current_price_types.map((i) => i.id);
    const updatedPriceTypes = this.state.price_types.map((i) => i.id);
    const data = {
      ...this.state.data,
    };

    // Add new price types
    if (this.state.price_types.filter((i) => i.new).length > 0) {
      data.add_price_types = [...this.state.price_types.filter((i) => i.new)];
    }

    // Update old price types
    if (this.state.price_types.filter((i) => !i.new).length > 0) {
      data.update_price_types = [...this.state.price_types.filter((i) => !i.new)];
    }

    // Remove price types
    if (currentPriceTypes.filter((i) => !updatedPriceTypes.includes(i)).length > 0) {
      data.remove_price_type_ids = [...currentPriceTypes.filter((i) => !updatedPriceTypes.includes(i))];
    }

    this.props.dispatch(editCardType(data, this.props.calendarData));
  };

  onSubmitPriceType = () => {
    if (!this.state.temporary_price.tax_option_id || !this.state.temporary_price.name) {
      return false;
    }

    this.setState(
      (prevState) => ({
        price_types: [
          ...prevState.price_types,
          {
            new: true,
            id: uuidv1(),
            ...prevState.temporary_price,
          },
        ],
      }),
      () => {
        this.onCancelAddPriceType();
      }
    );
  };

  onAddPriceType = () => {
    this.setState({
      priceTypeModalVisible: true,
    });
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  onCancelAddPriceType = () => {
    this.setState({
      temporary_price: null,
      priceTypeModalVisible: false,
    });
  };

  setMembersOnly = () => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        members_only: !prevState.data.members_only,
      },
    }));
  };

  render() {
    if (!this.state.beat_id) {
      return;
    }

    return (
      <Modal
        width={800}
        title={this.props.intl.formatMessage({ id: "edit_card_type" })}
        visible={this.state.modal}
        onCancel={this.onCancel}
        footer={
          this.state.data?.id && [
            <Button size={ANT_CONFIG.size} key="price_type" disabled={this.props.fishing_cards.type.loading} type="secondary" onClick={this.onAddPriceType}>
              {this.props.intl.formatMessage({ id: "add_price_type" })}
            </Button>,
            <Button size={ANT_CONFIG.size} key="submit" loading={this.props.fishing_cards.type.loading} type="primary" onClick={this.onSubmit}>
              {this.props.intl.formatMessage({ id: "save" })}
            </Button>,
          ]
        }
      >
        {this.props.fishing_cards?.type?.data ? (
          <Row>
            <Col xs={24}>
              <label className="ant-label" htmlFor="fishing_card_type">
                {this.props.intl.formatMessage({ id: "fishing_card" })}
              </label>
              <Select
                id="fishing_card_type"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={`${this.props.intl.formatMessage({ id: "select_card" })}`}
                onChange={(e) => this.onCardTypeChange(e)}
              >
                {this.props.fishing_cards.type.data.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={24}>
              <Spinner />
            </Col>
          </Row>
        )}

        {this.state.data.id && (
          <Row>
            <Col xs={24} style={{ paddingTop: 20 }}>
              <label className="ant-label" htmlFor="name">
                {this.props.intl.formatMessage({ id: "name" })}
              </label>
              <Input
                size={ANT_CONFIG.size}
                type="text"
                name="name"
                id="name"
                value={this.state.data.name}
                onChange={(e) => this.onChangeValue({ name: e.target.value })}
              />
            </Col>
            <Col xs={24} style={{ paddingTop: 20 }}>
              <label className="ant-label" htmlFor="description">
                {this.props.intl.formatMessage({ id: "description" })}
              </label>
              <Input.TextArea
                size={ANT_CONFIG.size}
                name="description"
                id="description"
                autoSize={{ minRows: 3 }}
                value={this.state.data.description}
                onChange={(e) => this.onChangeValue({ description: e.target.value })}
              />
            </Col>
            <Col xs={24} style={{ paddingTop: 20 }}>
              <Checkbox
                size={ANT_CONFIG.size}
                checked={this.state.data.flexible_time}
                onChange={() =>
                  this.setState((prevState) => ({
                    data: {
                      ...prevState.data,
                      flexible_time: !prevState.data.flexible_time,
                    },
                  }))
                }
              >
                {this.props.intl.formatMessage({ id: "flexible_time" })}
              </Checkbox>
            </Col>
            <Col xs={24} style={{ paddingTop: 20, paddingBottom: 30 }}>
              <Checkbox size={ANT_CONFIG.size} checked={this.state.data.members_only || false} onChange={this.setMembersOnly}>
                {this.props.intl.formatMessage({ id: "members_only" })}
              </Checkbox>
            </Col>

            <Col xs={24}>
              <label className="ant-label">{this.props.intl.formatMessage({ id: "price_types" })}</label>
              <Table
                className="ant-design-table-border"
                columns={[
                  {
                    title: "#",
                    dataIndex: "key",
                    key: "key",
                  },
                  {
                    title: this.props.intl.formatMessage({ id: "name" }),
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: this.props.intl.formatMessage({ id: "description" }),
                    dataIndex: "description",
                    key: "description",
                  },
                  {
                    title: this.props.intl.formatMessage({ id: "tax" }),
                    dataIndex: "tax",
                    key: "tax",
                  },
                  {
                    title: "",
                    dataIndex: "action",
                    key: "action",
                  },
                ]}
                dataSource={this.state.price_types.map((i, key) => {
                  const tax = this.props.taxes?.data?.filter((p) => p.id === i.tax_option_id)?.[0];

                  return {
                    key: key + 1,
                    name: i.name || "-",
                    description: i.description || "-",
                    tax: tax ? `${tax.key} (${tax.value}%)` : "-",
                    action: (
                      <div style={{ textAlign: "right", width: "100%" }}>
                        <Button
                          size={ANT_CONFIG.size}
                          type="danger"
                          onClick={() =>
                            this.setState((prevState) => ({
                              price_types: prevState.price_types.filter((p) => p.id !== i.id),
                            }))
                          }
                        >
                          {this.props.intl.formatMessage({ id: "remove" })}
                        </Button>
                      </div>
                    ),
                  };
                })}
                pagination={ANT_CONFIG.pagination}
              />
            </Col>

            <Modal
              title={this.props.intl.formatMessage({ id: "add_price_type" })}
              visible={this.state.priceTypeModalVisible}
              onCancel={this.onCancelAddPriceType}
              footer={[
                <Button
                  disabled={!this.state.temporary_price?.tax_option_id || !this.state.temporary_price?.name || !this.state.temporary_price?.description}
                  key="submit"
                  type="primary"
                  size={ANT_CONFIG.size}
                  onClick={this.onSubmitPriceType}
                >
                  {this.props.intl.formatMessage({ id: "save" })}
                </Button>,
              ]}
            >
              <Row>
                <Col xs={24}>
                  <label className="ant-label" htmlFor="price_name">
                    {this.props.intl.formatMessage({ id: "name" })}
                  </label>
                  <Input
                    size={ANT_CONFIG.size}
                    type="text"
                    name="price_name"
                    id="price_name"
                    value={this.state.temporary_price?.name || ""}
                    onChange={(e) => this.onChangeTemporaryPrice({ name: e.target.value })}
                  />
                </Col>
                <Col xs={24} style={{ paddingTop: 20 }}>
                  <label className="ant-label" htmlFor="price_description">
                    {this.props.intl.formatMessage({ id: "description" })}
                  </label>
                  <Input.TextArea
                    size={ANT_CONFIG.size}
                    name="price_description"
                    id="price_description"
                    autoSize={{ minRows: 2 }}
                    value={this.state.temporary_price?.description || ""}
                    onChange={(e) => this.onChangeTemporaryPrice({ description: e.target.value })}
                  />
                </Col>
                <Col xs={24} style={{ paddingTop: 20 }}>
                  {this.props.taxes.data && (
                    <React.Fragment>
                      <label className="ant-label" htmlFor="select_tax">
                        {this.props.intl.formatMessage({ id: "tax" })}
                      </label>
                      <Select
                        id="select_tax"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_tax" })}`}
                        onChange={(e) => this.onChangeTemporaryPrice({ tax_option_id: e })}
                        value={this.state.temporary_price?.tax_option_id}
                      >
                        {this.props.taxes.data.map((i) => (
                          <Select.Option key={i.id} value={i.id}>
                            {`${i.key} (${i.value}%)`}
                          </Select.Option>
                        ))}
                      </Select>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </Modal>
          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  fishing_cards: state.FishingCards,
  taxes: state.Taxes,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalEditCardType)));
