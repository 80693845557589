import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Row, Col, FormGroup, Label, Form, Input, Button } from "reactstrap";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import { fetch as fetchCurrencies, fetchTimeZones, updateBeat as updateBeatSettings } from "../../redux/settings/actions";
import Spinner from "../../components/spinner";
import SelectInput from "../../components/select";
import { fetch as fetchBeatOptions, edit as editBeatOptions } from "../../redux/beat_options/actions";
import { fetch } from "../../redux/beat/actions";
import SaveLabel from "../../components/helpers/buttons/save_label";
import beatOptions from "../../services/select_beat_options";
import {currencyOptions, timezoneOptions}  from "../../services/select_settings_options"
import Commisions from './commissions' 
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";

const labelWidth = 3;
const inputWidth = 9;

class BeatOptions extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);

    this.state = {
      beat_id: this.props.beats.selected || this.props.authentication.data.beat[0],
      currency_obj: null,
      timezone: null,
      onSaveClicked: false,
      data: {}
    };
  }

  componentDidMount() {
    const { isAdmin } = this.context;

    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (!this.props.settings.currencies && isAdmin) {
      this.props.dispatch(fetchCurrencies());
    }   

    if(!this.props.settings.timezones && isAdmin) {
      this.props.dispatch(fetchTimeZones());
    }

    this.props.dispatch(fetch(this.props.beats.selected || this.props.authentication.data.beat[0]));
    
    this.handleFetchBeatOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: {
        name:
          nextProps.beat_options.data && nextProps.beat_options.data.filter(i => i.key === "organization_name")[0]
            ? nextProps.beat_options.data.filter(i => i.key === "organization_name")[0].value
            : "",
        street:
          nextProps.beat_options.data && nextProps.beat_options.data.filter(i => i.key === "organization_street")[0]
            ? nextProps.beat_options.data.filter(i => i.key === "organization_street")[0].value
            : "",
        post_code:
          nextProps.beat_options.data && nextProps.beat_options.data.filter(i => i.key === "organization_post_code")[0]
            ? nextProps.beat_options.data.filter(i => i.key === "organization_post_code")[0].value
            : "",
        city:
          nextProps.beat_options.data && nextProps.beat_options.data.filter(i => i.key === "organization_city")[0]
            ? nextProps.beat_options.data.filter(i => i.key === "organization_city")[0].value
            : "",
        phone:
          nextProps.beat_options.data && nextProps.beat_options.data.filter(i => i.key === "organization_phone")[0]
            ? nextProps.beat_options.data.filter(i => i.key === "organization_phone")[0].value
            : "",
        number:
          nextProps.beat_options.data && nextProps.beat_options.data.filter(i => i.key === "organization_number")[0]
            ? nextProps.beat_options.data.filter(i => i.key === "organization_number")[0].value
            : "",
        account_number:
          nextProps.beat_options.data && nextProps.beat_options.data.filter(i => i.key === "organization_account_number")[0]
            ? nextProps.beat_options.data.filter(i => i.key === "organization_account_number")[0].value
            : "",
        currencies: nextProps.settings.currencies
      },
    });
  }

  onChangeValue(newState) {
    this.setState(prevState => ({ data: { ...prevState.data, ...newState } }));
  }

  onSubmit() {
    const data = {
      ...this.state.data,
      beat_id: this.state.beat_id
    };

    this.props.dispatch(editBeatOptions(data, this.state.beat_id));
  }

  onSubmitSettings() {
    this.setState({onSaveClicked: true});

    const data = {
      beatId: this.state.beat_id,
      currency_id: this.state.currency_obj ? this.state.currency_obj.value :  this.props.beat.data.currency?.id,
      timezone_id: this.state.timezone ? this.state.timezone.value : this.props.beat.data.timezone?.id
    };

    if(data.currency_id && data.timezone_id) {
      this.props.dispatch(updateBeatSettings(data, this.state.beat_id));
      this.props.dispatch(fetch(this.state.beat_id));
      this.setState({onSaveClicked: false});
    }
  }

  handleBeatChange(e) {
    this.props.dispatch(selectBeat(e));
    this.props.dispatch(fetch(e));
    this.setState({ beat_id: e, currency_obj: null, timezone: null }, () => {
      this.handleFetchBeatOptions();
    });
  }

  handleCurrencyChange(e) {
    this.setState({currency_obj: e});
  }

  handleTimezoneChange(e) {
    this.setState({timezone: e});
  }


  handleFetchBeatOptions() {
    this.props.dispatch(fetchBeatOptions(this.state.beat_id));
  }

  getCurrency(currencies, beat) {
    return beat && beat.currency ? currencies.filter(i => i.value === beat.currency.id) : null;
  }

  getTimezone(timezones, beat) {
    return beat && beat.timezone_id ? timezones.filter(i => i.value === beat.timezone_id) : null;
  }

  render() {
    const { isAdmin, isBeatAdmin } = this.context;
    
    if (this.props.beats.loading || !this.props.beats.data) {
      return <Spinner />;
    }

    if(isAdmin) {
      if (this.props.settings.loading || !this.props.settings.currencies) {
        return <Spinner />;
      }
  
      if (this.props.settings.loading || !this.props.settings.timezones) {
        return <Spinner />;
      }
    }

    const selectBeats = beatOptions(this.props.beats, this.props.authentication);

    const selectCurrencies = currencyOptions(this.props.settings);

    const selectTimezones = timezoneOptions(this.props.settings);

    const selectedBeat = this.props.beat.data;

    return (
      <div>
        {selectBeats.length > 1 ? (
          <div style={{ marginBottom: 30 }} className="panel">
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row style={{ marginBottom: 2 }}>
                    <Label for="select_beat" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <SelectInput
                        id="select_beat"
                        name="select_beat"
                        intl={this.props.intl}
                        value={selectBeats.filter(i => i.value === this.state.beat_id)}
                        onChange={e => this.handleBeatChange(e.value)}
                        options={selectBeats}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>
        ) : null}

        {(isAdmin || isBeatAdmin) &&
          <div>
            {this.props.beat_options.loading ? (
              <Spinner />
            ) : (
              <div className="panel" style={{ paddingTop: 20 }}>
                <h2 className="title">{this.props.intl.formatMessage({ id: "options" })}</h2>
                <Form>
                  <Row>
                    <Col xs={12} lg={6}>
                      <FormGroup row>
                        <Label for="name" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "organization_name" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            value={this.state.data.name || ""}
                            onChange={e => this.onChangeValue({ name: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="street" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "street" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="street"
                            id="street"
                            value={this.state.data.street || ""}
                            onChange={e => this.onChangeValue({ street: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="post_code" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "post_code" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="post_code"
                            id="post_code"
                            value={this.state.data.post_code || ""}
                            onChange={e => this.onChangeValue({ post_code: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} lg={6}>
                      <FormGroup row>
                        <Label for="city" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "city" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            value={this.state.data.city || ""}
                            onChange={e => this.onChangeValue({ city: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
    
                      <FormGroup row>
                        <Label for="phone" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "phone" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="phone"
                            id="phone"
                            value={this.state.data.phone || ""}
                            onChange={e => this.onChangeValue({ phone: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="number" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "organization_number" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="number"
                            id="number"
                            value={this.state.data.number || ""}
                            onChange={e => this.onChangeValue({ number: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
    
                      <FormGroup row>
                        <Label for="account_number" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "account_number" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="account_number"
                            id="account_number"
                            value={this.state.data.account_number || ""}
                            onChange={e => this.onChangeValue({ account_number: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <hr />
                      <FormGroup row style={{ marginBottom: 0 }}>
                        <Col xs={12} className="text-right">
                          <Button color="secondary" type="submit" onClick={() => this.onSubmit()}>
                            <SaveLabel intl={this.props.intl} />
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}

          </div>
        }

      {isAdmin &&
        <div>
          {this.props.beat_options.loading ? (<Spinner />) : (
            <div className="panel">
            <h2 className="title">{this.props.intl.formatMessage({ id: "settings" })}</h2>
    
              <Form>
                <Row>
                  <Col xs={12} lg={6}>
                  <FormGroup row>
                    <Label for="currency" xs={12} sm={labelWidth}>
                    {this.props.intl.formatMessage({ id: "currency" })} <span style={{color: !this.state.currency_obj && this.state.onSaveClicked ? 'red' : ''}}>*</span>
                    </Label>
                        <Col xs={12} sm={inputWidth}>
                          <SelectInput
                            id="select_currency"
                            name="select_currency"
                            value={this.state.currency_obj ? this.state.currency_obj : this.getCurrency(selectCurrencies, selectedBeat)}
                            intl={this.props.intl}
                            onChange={e => this.handleCurrencyChange(e)}
                            options={selectCurrencies}
                          />
                        </Col>
                    </FormGroup>
                  </Col>

                  <Col xs={12} lg={6}>
                  <FormGroup row>
                    <Label for="timezone" xs={12} sm={labelWidth}>
                    {this.props.intl.formatMessage({ id: "timezone" })} <span style={{color: !this.state.timezone && this.state.onSaveClicked ? 'red' : ''}}>*</span>
                    </Label>
                        <Col xs={12} sm={inputWidth}>
                          <SelectInput
                            id="select_timezone"
                            name="select_timezone"
                            placeholder=""
                            value={this.state.timezone ? this.state.timezone : this.getTimezone(selectTimezones, selectedBeat)}
                            intl={this.props.intl}
                            onChange={e => this.handleTimezoneChange(e)}
                            options={selectTimezones}
                          />
                        </Col>
                    </FormGroup>
                  </Col>
    
                  <Col xs={12}>
                      <hr />
                      <FormGroup row style={{ marginBottom: 0 }}>
                        <Col xs={12} className="text-right">
                          <Button color="secondary" type="button" onClick={() => this.onSubmitSettings()}>
                            <SaveLabel intl={this.props.intl} />
                          </Button>
                        </Col>
                      </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
           )}

        </div>
      }

      <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Innstillinger_til_valdet_ditt__RoOIv26CQu6jvhR29mJsLg" />

        {isAdmin && <Commisions beatId={this.state.beat_id} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth,
  beats: state.Beats,
  beat_options: state.BeatOptions,
  settings: state.Settings,
  beat: state.Beat
});

export default withRouter(injectIntl(connect(mapStateToProps)(BeatOptions)));
