import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Table,
  DatePicker,
  Card,
  Col,
  Row,
  PageHeader,
  Modal,
  Select,
  Button,
  Tag,
  Input,
} from "antd";

import {
  fetch as fetchOrders,
  download as downloadOrders,
  cancel as cancelOrder,
} from "../../redux/river_orders/actions";
import Spinner from "../../components/spinner";

import ANT_CONFIG from "../../constants/antconfig";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import riverOptions from "../../services/select_river_options";
import RolesContext from "../../context/RolesContext";
import downloadFile from "../../services/download_file";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import Scribe from "../../components/Scribe";

const { confirm } = Modal;

class OrdersRiver extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.status = ["PAID", "PENDING", "CANCELLED"];
    this.getParams = new URLSearchParams(window.location.search);
    this.state = {
      filter: "",
      isAdmin: false,
      status_filter:
        this.getParams.get("status") &&
        this.status.includes(this.getParams.get("status").toUpperCase())
          ? this.getParams.get("status").toUpperCase()
          : "",
      data: {
        river_id: this.getParams.get("river_id")
          ? parseInt(this.getParams.get("river_id"), 10)
          : this.props.authentication.data.river[0],
        status: this.status,
        date_start: moment().subtract(7, "days").format(`${this.dateFormat}`),
        date_end: moment().format(`${this.dateFormat}`),
      },
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    const { roles } = this.props.authentication.data;

    if (!this.state.isAdmin) {
      roles.map((role) =>
        role === "admin" ? this.setState({ isAdmin: true }) : ""
      );
    }

    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.orders.file !== this.props.orders.file &&
      this.props.orders.file
    ) {
      downloadFile(
        this.props.orders.file,
        "xlsx",
        `river-cards-${moment(this.state.data.date_start).format(
          "YYYY-MM-DD"
        )}-${moment(this.state.data.date_end).format("YYYY-MM-DD")}`
      );
    }
  }

  handleDataChange() {
    this.props.dispatch(fetchOrders(this.state.data));
  }

  handleChangeDate(e, key) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          [key]: moment(e).format(`${this.dateFormat}`),
        },
      }),
      () => {
        this.handleDataChange();
      }
    );
  }

  handleRiverChange(e) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          river_id: e,
        },
      }),
      () => {
        this.handleDataChange();
      }
    );
  }

  handleStatusFilterChange(e) {
    this.setState({ status_filter: e });
  }

  handleFilterChange(filter) {
    this.setState({
      filter,
    });
  }

  handleDownload = () => {
    this.props.dispatch(downloadOrders(this.state.data));
  };

  handleOk(orderId) {
    return new Promise((resolve, reject) => {
      this.props
        .dispatch(cancelOrder(orderId, this.state.data))
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  showConfirm(order) {
    const { id, beat_ids } = order;
    const { intl, orders } = this.props;

    confirm({
      confirmLoading: orders.loading,
      title: `${
        beat_ids?.length > 0
          ? `${intl.formatMessage({
              id: "this_order_has_other_products_besides_river_cards",
            })} `
          : ""
      }${intl.formatMessage({
        id: "confirm_cancel_order_question",
      })}`,
      okText: intl.formatMessage({ id: "yes" }),
      okType: "danger",
      onOk: () => this.handleOk(id),
      onCancel() {},
    });
  }

  render() {
    if (
      !this.props.orders.data ||
      (this.props.orders.loading && !this.state.showModal)
    ) {
      return <Spinner />;
    }

    const {
      orders: { file_loading },
    } = this.props;

    const { isAdmin } = this.context;

    let selectRivers = [];

    if (this.props.rivers.data && this.props.rivers.data.length > 0) {
      selectRivers = [
        ...selectRivers,
        ...riverOptions(this.props.rivers, this.props.authentication),
      ];
    }

    const statusFilter = [
      {
        value: "",
        label: this.props.intl.formatMessage({ id: "ALL" }),
      },
      {
        value: "PAID",
        label: this.props.intl.formatMessage({ id: "PAID" }),
      },
      {
        value: "CANCELLED",
        label: this.props.intl.formatMessage({ id: "CANCELLED" }),
      },
    ];

    let { data } = this.props.orders;

    if (this.state.status_filter) {
      data = data.filter((i) => i.status === this.state.status_filter);
    }

    if (this.state.filter) {
      data = data.filter((i) => {
        const filter = this.state.filter.toLowerCase();
        const fullName = `${i.user.first_name} ${i.user.last_name}`;

        return (
          (i.id && i.id.toString().includes(filter)) ||
          (i.user.first_name &&
            i.user.first_name.toLowerCase().includes(filter)) ||
          (i.user.last_name &&
            i.user.last_name.toLowerCase().includes(filter)) ||
          (fullName && fullName.toLowerCase().includes(filter)) ||
          (i.user.email && i.user.email.toLowerCase().includes(filter))
        );
      });
    }

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: this.props.intl.formatMessage({ id: "angler_name" }),
        dataIndex: "angler_name",
        key: "angler_name",
      },
      {
        title: this.props.intl.formatMessage({ id: "river" }),
        dataIndex: "river",
        key: "river",
      },
      {
        title: this.props.intl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
      },
      {
        title: this.props.intl.formatMessage({ id: "from_date" }),
        dataIndex: "from_date",
        key: "from_date",
      },
      {
        title: this.props.intl.formatMessage({ id: "to_date" }),
        dataIndex: "to_date",
        key: "to_date",
      },
      {
        title: this.props.intl.formatMessage({ id: "price" }),
        dataIndex: "price",
        key: "price",
      },
      {
        title: `${this.props.intl.formatMessage({
          id: "download",
        })} ${this.props.intl.formatMessage({ id: "fishing_cards" })}`,
        dataIndex: "download",
        key: "download",
      },
      {
        title: this.props.intl.formatMessage({ id: "order_status" }),
        dataIndex: "order_status",
        key: "order_status",
      },
    ];
    if (isAdmin) {
      tableColumns.push({
        title: this.props.intl.formatMessage({ id: "cancel_order" }),
        dataIndex: "cancel_order",
        key: "cancel_order",
      });
    }

    const tableData = data.map((order) => {
      let color;
      let anglerNames = ''
      let type = [];
      let dates = [];
      let dateFrom = "";
      let dateTo = "";

      if (order.packages?.length > 0) {
        order.packages.forEach((pack) => {
          if (pack.river_cards.length > 0) {
            pack.river_cards.forEach((riverCard) => {
              if (!type.includes(riverCard.river_card_blueprint.name)) {
                type += `${type.length > 1 ? `, ` : ""}${
                  riverCard.river_card_blueprint.name
                }`;
              }
              if (anglerNames !== '') {
                anglerNames += ', ';
              }
              anglerNames += riverCard.owner_name

              dates = [
                `${riverCard.river_card_blueprint.year}-01-01`,
                `${riverCard.river_card_blueprint.year}-12-31`,
              ];
            });
          }
        });
      }

      if (order.status === "PAID") {
        color = "green";
      }
      if (order.status === "PENDING") {
        color = "orange";
      }
      if (order.status === "CANCELLED") {
        color = "red";
      }

      if (dates?.length > 0) {
        dateFrom = dates.reduce((pre, cur) =>
          Date.parse(pre) > Date.parse(cur) ? cur : pre
        );
        dateTo = dates.reduce((pre, cur) =>
          Date.parse(pre) < Date.parse(cur) ? cur : pre
        );
      }

      return {
        key: order.id,
        angler_name: anglerNames,
        river: order.river_name ?? "-",
        type: type.length > 0 ? type : "-",
        from_date: dateFrom ? moment(dateFrom).format("YYYY") : "-",
        to_date: dateTo ? moment(dateTo).format("YYYY") : "-",
        price: `${order.total} NOK`,
        download:
          order.status === "PAID" ? (
            <a
              href={`${order.river_cards_pdf_url}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button icon={<DownloadOutlined />} type="default">
                {this.props.intl.formatMessage({ id: "download" })}
              </Button>
            </a>
          ) : (
            <span>&nbsp;</span>
          ),
        order_status: (
          <Tag color={color} className="ant-tag">
            {this.props.intl.formatMessage({ id: order.status })}
          </Tag>
        ),
        cancel_order: isAdmin ? (
          order.status !== "CANCELLED" ? (
            <Button
              type="danger"
              onClick={() => this.showConfirm(order)}
              icon={<DeleteOutlined />}
            >
              {this.props.intl.formatMessage({ id: "cancel" })}
            </Button>
          ) : (
            <span>&nbsp;</span>
          )
        ) : null,
      };
    });

    return (
      <div>
        <PageHeader
          title={this.props.intl.formatMessage({ id: "river_orders" })}
          extra={
            <FileDownloadDropdown
              key="1"
              handleClick={this.handleDownload}
              loading={file_loading}
              options={[
                {
                  value: "xlsx",
                  label: "XLSX",
                  type: "excel",
                },
              ]}
            />
          }
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}
                >
                  <label
                    htmlFor="search_by_id_name_or_email"
                    className="ant-label"
                  >
                    {this.props.intl.formatMessage({
                      id: "search_by_id_name_or_email",
                    })}
                  </label>
                  <Input
                    placeholder={this.props.intl.formatMessage({
                      id: "search_by_id_name_or_email",
                    })}
                    id="search_by_id_name_or_email"
                    size={ANT_CONFIG.size}
                    value={this.state.filter}
                    onChange={(e) => this.handleFilterChange(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>

                {selectRivers.length > 1 && (
                  <Col
                    xs={24}
                    sm={4}
                    md={4}
                    style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}
                  >
                    <label htmlFor="select_beat" className="ant-label">
                      {this.props.intl.formatMessage({ id: "select_river" })}
                    </label>
                    <Select
                      id="select_beat"
                      showSearch
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({
                        id: "select_river",
                      })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleRiverChange(e)}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={this.state.data.river_id}
                    >
                      {selectRivers.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col
                  xs={24}
                  sm={6}
                  md={6}
                  style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}
                >
                  <label htmlFor="date_start" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_start" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_start)}
                    onChange={(e) => this.handleChangeDate(e, "date_start")}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={6}
                  md={6}
                  style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}
                >
                  <label htmlFor="date_end" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_end" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_end)}
                    onChange={(e) => this.handleChangeDate(e, "date_end")}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={6}
                  md={6}
                  style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}
                >
                  <label htmlFor="select_order_status" className="ant-label">
                    {this.props.intl.formatMessage({ id: "order_status" })}
                  </label>
                  <Select
                    id="select_order_status"
                    showSearch
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({
                      id: "order_status",
                    })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.handleStatusFilterChange(e)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.status_filter}
                  >
                    {statusFilter.map((i, key) => (
                      <Select.Option key={key} value={i.value}>
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
              />
            </Col>
          </Row>
        </Card>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Ordreliste__se_hvem_som_har_kjopt_elvekort__2ziWiOG8SMGgOb7mdaZrNw" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.RiverOrders,
  authentication: state.Auth,
  rivers: state.Rivers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(OrdersRiver)));
