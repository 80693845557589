import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { PageHeader, Row, Col, Select, Card, Button } from "antd";

import translations from "../../constants/strings";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import Spinner from "../../components/spinner";
import groupBy from "../../services/group_by";
import { fetch as fetchSeasonCards } from "../../redux/season_cards/actions";
import beatOptions from "../../services/select_beat_options";
import ANT_CONFIG from "../../constants/antconfig";
import ModalAddEditSeasonDates from "../../components/modal/season_cards/dates/add";
import ModalAddSeasonCards from "../../components/modal/season_cards/add";
import ModalRemoveSeasonCards from "../../components/modal/season_cards/remove";
import ModalAddCardType from "../../components/modal/season_cards/type/add";
import ModalEditCardType from "../../components/modal/season_cards/type/edit";
import { fetch as fetchBeatNotifications } from "../../redux/beat_notifications/actions";
import Notification from "../../components/Notification/Notification";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";

class SeasonCards extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.localizer = momentLocalizer(moment);
    this.state = {
      year: moment().format("YYYY"),
      data: {
        beat_ids: this.props.beats.selected ? [this.props.beats.selected] : [this.props.authentication.data.beat[0]],
        date_start: moment().subtract(1, "months").startOf("month").format(`${this.dateFormat}`),
        date_end: moment().add(1, "months").endOf("month").format(`${this.dateFormat}`),
      },
      showModal: false,
      modalData: {
        items: [],
      },
      delete_events: false,
      selected_events: [],
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.props.dispatch(
      fetchSeasonCards({
        beat_id: this.state.data.beat_ids[0],
        year: this.state.year,
      })
    );

    this.props.dispatch(fetchBeatNotifications(this.state.data.beat_ids[0]));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.season_dates.data !== this.props.season_dates.data && this.props.season_dates.data) {
      this.props.dispatch(
        fetchSeasonCards({
          beat_id: this.state.data.beat_ids[0],
          year: this.state.year,
        })
      );
    }
  }

  onNavigate(e) {
    const date = moment(e).format(this.dateFormat);
    const startOfMonth = moment(date).subtract(1, "months").startOf("month").format(this.dateFormat);
    const endOfMonth = moment(date).add(1, "months").endOf("month").format(this.dateFormat);
    this.setState(
      (prevState) => ({
        year: moment(e).format("YYYY"),
        data: {
          ...prevState.data,
          date_start: startOfMonth,
          date_end: endOfMonth,
        },
      }),
      () => {
        this.props.dispatch(
          fetchSeasonCards({
            beat_id: this.state.data.beat_ids[0],
            year: this.state.year,
          })
        );
      }
    );
  }

  onSelectEvent(e) {
    if (!e.reserved) {
      const selectedEvents = this.state.selected_events;

      if (this.state.delete_events) {
        if (selectedEvents.map((i) => i.id).includes(e.id)) {
          this.setState({
            selected_events: selectedEvents.filter((i) => i.id !== e.id),
          });
        } else {
          this.setState((prevState) => ({
            selected_events: [...prevState.selected_events, e],
          }));
        }

        return true;
      }

      this.toggleModal("add_availability", e.items);
    }

    return true;
  }

  onSelectSlot() {
    this.toggleModal("add_availability");
  }

  toggleModal(showModal, items = []) {
    this.setState({
      showModal,
      modalData: {
        items,
      },
    });
  }

  handleBeatChange(e) {
    this.props.dispatch(selectBeat(e));

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          beat_ids: [e],
        },
      }),
      () => {
        this.props.dispatch(
          fetchSeasonCards({
            beat_id: this.state.data.beat_ids[0],
            year: moment().format("YYYY"),
          })
        );
        this.props.dispatch(fetchBeatNotifications(this.state.data.beat_ids[0]));
      }
    );
  }

  hideModal() {
    this.setState({
      showModal: null,
    });
  }

  hideModalAfterDelete() {
    this.setState({
      showModal: null,
      delete_events: false,
      selected_events: [],
    });
  }

  render() {

    if (!this.props.beats.data || !this.props.authentication.data) {
      return <Spinner />;
    }

    let seasonCards = [];
    const Event = (event) => {
      let color = this.state.selected_events.map((i) => i.id).includes(event.event.id) ? "yellow" : "blue";

      if (event.event.reserved) {
        color = "green";
      }

      return <div className={`event-item color-${color}`}>{event.title}</div>;
    };
    const selectBeats = beatOptions(this.props.beats, this.props.authentication);

    if (this.props.season_cards.data) {
      const items = this.props.season_cards.data;

      if (items) {
        const results = groupBy(items, (item) => {
          return [
            moment(item.publish_date_start).format("DD-MM-YYYY HH:mm"),
            moment(item.publish_date_end).format("DD-MM-YYYY HH:mm"),
            item.season_fishing_card_type_id,
            item.year,
            item.beat_id,
            item.members_only,
            item.package_id !== null,
          ];
        });

        results.map((items) => {
          const item = items[0];
          const joinedIds = items.map((i) => i.id).join("");
          const reserved = item.package_id !== null;
          const id = `${item.beat_id}${joinedIds}`;
          const titleText = reserved ? this.props.intl.formatMessage({ id: "reserved" }) : this.props.intl.formatMessage({ id: "available" });
          let publishDate = "";
          let type = "";
          const ifPublishDateStart = item.publish_date_start !== "1970-01-01 00:00:00";
          const ifPublishDateEnd = item.publish_date_end !== "3999-12-31 00:00:00";
          if (ifPublishDateStart) {
            publishDate = `(${this.props.intl.formatMessage({ id: "from" })} ${moment(item.publish_date_start).format("DD.MM.YYYY")}`;
          }
          if (ifPublishDateEnd) {
            publishDate += ifPublishDateStart
              ? ` - ${moment(item.publish_date_end).format("DD.MM.YYYY")}`
              : `(${this.props.intl.formatMessage({ id: "to" })} ${moment(item.publish_date_end).format("DD.MM.YYYY")}`;
          }
          if (ifPublishDateStart || ifPublishDateEnd) {
            publishDate += ")";
          }
          let memberOnly = item.members_only ? `${this.props.intl.formatMessage({ id: "member_only" })} ` : "";
          if (memberOnly && publishDate) {
            memberOnly += " - ";
          }
          if (item.season_fishing_card_type?.name) {
            type = `- ${item.season_fishing_card_type?.name} - `;
          }

          const title = `${items.length} ${type}${titleText}${memberOnly || publishDate ? ":" : ""} ${memberOnly}${publishDate}`;
          seasonCards = [...seasonCards, { id, title, items, start: item.season_start_date, end: item.season_end_date, reserved }];

          return seasonCards;
        });
      }
    }

    return (
      <div>
        {this.props.beat_notifications.data && this.context.isAdmin && (
          <div>
            {this.props.beat_notifications.data.map((n, key) => {
              return <Notification key={key} text={n.message} type={n.type} conditions_met={n.conditions_met}></Notification>  
            })}
          </div>
        )}
        {this.state.delete_events && (
          <div className="panel panel-delete">
            {this.props.intl.formatMessage({ id: "delete_multiple_products_alert" })}

            <Button type="danger" onClick={() => this.toggleModal("remove_multiple")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "confirm_deletion" })}
            </Button>
          </div>
        )}

        <PageHeader
          title={this.props.intl.formatMessage({ id: "season_cards" })}
          extra={[
            <Button key="add_type" onClick={() => this.toggleModal("add_type")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_type" })}
            </Button>,
            <Button key="edit_type" onClick={() => this.toggleModal("edit_type")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "edit_type" })}
            </Button>,
            <Button key="add_edit_dates" onClick={() => this.toggleModal("add_edit_dates")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_edit_dates" })}
            </Button>,
            <Button key="add_availability" type="primary" onClick={() => this.toggleModal("add_availability")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_availability" })}
            </Button>,
            <Button
              size={ANT_CONFIG.size}
              key="remove"
              type="danger"
              onClick={() => this.setState((prevState) => ({ delete_events: !prevState.delete_events, selected_events: [] }))}
            >
              {this.props.intl.formatMessage({ id: "remove" })}
            </Button>,
          ]}
        >
          {selectBeats.length > 1 && (
            <Row style={{ marginTop: 15 }}>
              <Col xs={24} sm={10} md={10} style={{ marginTop: 10, marginBottom: 10, maxWidth: 500 }}>
                <label htmlFor="select_beat" className="ant-label">
                  {this.props.intl.formatMessage({ id: "select_beat" })}
                </label>
                <Select
                  id="select_beat"
                  showSearch
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                  optionFilterProp="children"
                  onChange={(e) => this.handleBeatChange(e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={this.state.data.beat_ids}
                >
                  {selectBeats.map((i, key) => (
                    <Select.Option key={key} value={i.value}>
                      {i.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
        </PageHeader>

        <Card className="booking-calendar-card">
          {this.state.showModal === "add_type" && (
            <ModalAddCardType
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              toggleModal={() => this.hideModal()}
            />
          )}

          {this.state.showModal === "edit_type" && (
            <ModalEditCardType
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              toggleModal={() => this.hideModal()}
            />
          )}

          {this.state.showModal === "add_edit_dates" && (
            <ModalAddEditSeasonDates selectedBeat={this.state.data.beat_ids[0]} toggleModal={() => this.hideModal()} />
          )}

          {this.state.showModal === "add_availability" && (
            <ModalAddSeasonCards
              selectedBeat={this.state.data.beat_ids[0]}
              modalData={this.state.modalData}
              toggleModal={() => this.hideModal()}
              calendarData={{
                beat_id: this.state.data.beat_ids[0],
                year: this.state.year,
              }}
            />
          )}

          {this.state.showModal === "remove_multiple" && (
            <ModalRemoveSeasonCards
              calendarData={this.state.data}
              items={this.state.selected_events}
              data={{
                beat_id: this.state.data.beat_ids[0],
                year: this.state.year,
              }}
              toggleModal={() => this.hideModalAfterDelete()}
            />
          )}

          {this.props.season_cards.loading && !this.state.showModal && (
            <div className="booking-panel-preloader">
              <Spinner />
            </div>
          )}

          <Row>
            <Col xs={24} className="rc-calendar-container">
              <Calendar
                popup
                selectable
                localizer={this.localizer}
                culture={this.props.intl.locale}
                events={seasonCards}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={(e) => this.onSelectSlot(e)}
                views={["month"]}
                messages={{
                  ...translations.calendar[this.props.intl.locale],
                  showMore: (total) => `+${total} ${this.props.intl.formatMessage({ id: "more" })}`,
                }}
                onNavigate={(e) => this.onNavigate(e)}
                onSelectEvent={(e) => this.onSelectEvent(e)}
                components={{
                  event: Event,
                }}
              />
            </Col>
          </Row>
        </Card>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Legge_ut_sesongkort_for_salg__4e5ElNugRnakem-_NIIlYA"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  beats: state.Beats,
  season_cards: state.SeasonCards,
  season_dates: state.SeasonDates,
  beat_notifications: state.BeatNotifications,
});

export default withRouter(injectIntl(connect(mapStateToProps)(SeasonCards)));
