import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Select, Input, Popconfirm, Modal, Tabs, Table } from "antd";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import { sendEmails, getInfo, orderAdditionalEmails } from "../../redux/send_mail/actions";
import { getEmailOrders } from "../../redux/send_mail_orders/actions";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import ANT_CONFIG from "../../constants/antconfig";
import HTMLEditor from "../../components/HTMLEditor/HTMLEditor";
import { fetchForEmails as fetchBeats } from "../../redux/beats/actions";
import RolesContext from "../../context/RolesContext";
import Spinner from "../../components/spinner";
import GenericError from "../Errors/GenericError";
import Scribe from "../../components/Scribe";

const { size } = ANT_CONFIG;
const { Option, OptGroup } = Select;
const { TabPane } = Tabs;
const NOW = moment();
const START_YEAR = 2019;
const YEARS = Array(NOW.format("Y") - (START_YEAR - 1))
  .fill("")
  .map((v, index) => (NOW.format("Y") - index).toString());
const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class SendEmail extends React.Component {
  static contextType = RolesContext;

  state = {
    year: "",
    product_type: "",
    subject: "",
    body: "",
    send_to: "",
    is_modal_visible: false,
    number_of_blocks: 1,
    active_tab: "send",
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchBeats());
    dispatch(fetchRivers());
  }

  componentDidUpdate(prevProps) {
    const { send_emails } = this.props;

    if (prevProps.send_emails.booking !== send_emails.booking && send_emails.booking.success) {
      this.toggleModal();
    }

    if (prevProps.send_emails.success !== send_emails.success && send_emails.success) {
      this.resetEmailData();
    }
  }

  setField = (e, loadOrders = false) => {
    this.setState(e, () => {
      this.getInformation(loadOrders);
    });
  };

  getInformation = (loadOrders = false) => {
    const { send_to, year, product_type } = this.state;
    const { dispatch } = this.props;

    dispatch(
      getInfo({
        send_to,
        year,
        product_type,
      }),
    );

    if (loadOrders) {
      dispatch(
        getEmailOrders({
          send_to,
        }),
      );
    }
  };

  send = () => {
    const { subject, body, send_to, year, product_type } = this.state;
    const { dispatch } = this.props;

    dispatch(
      sendEmails({
        send_to,
        year,
        product_type,
        subject,
        body,
      }),
    );
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      is_modal_visible: !prevState.is_modal_visible,
      number_of_blocks: 1,
    }));
  };

  buyAdditionalEmails = () => {
    const { number_of_blocks, send_to } = this.state;
    const { dispatch } = this.props;

    dispatch(
      orderAdditionalEmails({
        number_of_blocks,
        send_to,
      }),
    );
  };

  onTabChange = (active_tab) => {
    this.setState({
      active_tab,
    });
  };

  resetEmailData = () => {
    this.setState({
      subject: "",
      body: "",
    });
  };

  render() {
    const { year, product_type, subject, body, send_to, is_modal_visible, number_of_blocks, active_tab } = this.state;
    const { intl, send_emails, send_emails_orders, authentication, rivers, beats } = this.props;
    const { isAdmin, isRiverAdmin, isBeatAdmin } = this.context;

    if (beats.loading || rivers.loading) {
      return <Spinner />;
    }

    if (beats.data_emails?.length === 0 && rivers.data?.filter((river) => river.send_email_feature_active)?.length === 0) {
      return <GenericError />;
    }

    const tabs = (
      <Tabs defaultActiveKey={active_tab} onChange={this.onTabChange}>
        <TabPane tab={intl.formatMessage({ id: "send" })} key="send" />
        <TabPane tab={intl.formatMessage({ id: "buy_more" })} key="buy_more" />
        <TabPane tab={intl.formatMessage({ id: "orders" })} key="orders" />
      </Tabs>
    );
    const PRODUCT_TYPES = [
      {
        key: "day",
        value: intl.formatMessage({ id: "daycard" }),
      },
      {
        key: "week",
        value: intl.formatMessage({ id: "weekcard" }),
      },
      {
        key: "season",
        value: intl.formatMessage({ id: "seasoncard" }),
      },
      isAdmin || isRiverAdmin
        ? {
            key: "river_cards",
            value: intl.formatMessage({ id: "river_card" }),
          }
        : null,
      isAdmin || isBeatAdmin
        ? {
            key: "members",
            value: intl.formatMessage({ id: "members" }),
          }
        : null,
    ];
    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
      },
      {
        title: intl.formatMessage({ id: "price" }),
        dataIndex: "total",
        key: "total",
      },
      {
        title: intl.formatMessage({ id: "number_of_blocks" }),
        dataIndex: "number_of_blocks",
        key: "number_of_blocks",
      },
    ];
    const tableData = send_emails_orders.data?.map((i, key) => ({
      key: key + 1,
      date: moment(i.created_at).format("YYYY-MM-DD"),
      ...i,
    }));

    return (
      <>
        <Modal
          visible={is_modal_visible}
          onCancel={this.toggleModal}
          footer={[
            <Button
              key="pay_now"
              size={ANT_CONFIG.size}
              type="primary"
              onClick={() => (window.location.href = send_emails.booking?.data?.terminal_urls?.url)}
            >
              {intl.formatMessage({ id: "pay_now" })}
            </Button>,
          ]}
        >
          <Row>
            <Col xs={24}>
              <Input size={ANT_CONFIG.size} type="text" defaultValue={send_emails.booking?.data?.terminal_urls?.url} readOnly />
            </Col>
          </Row>
        </Modal>

        <PageHeader title={intl.formatMessage({ id: "send_email" })} footer={tabs}>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={6} style={{ maxWidth: 300 }}>
              <label className="ant-label" htmlFor="send_to">
                {intl.formatMessage({ id: "send_to" })}
              </label>
              <Select
                showSearch
                id="send_to"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={intl.formatMessage({ id: "send_to" })}
                optionFilterProp="children"
                disabled={send_emails.loading}
                onChange={(e) => this.setField({ send_to: e }, true)}
                filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={send_to}
              >
                {isBeatAdmin && !isRiverAdmin && !isAdmin
                  ? beats.data_emails
                      ?.filter((beat) => authentication.data.beat.includes(beat.id) && beat.send_email_feature_active)
                      .map((beat) => (
                        <Option key={beat.id} value={`beat_${beat.id}`}>
                          {beat.name}
                        </Option>
                      ))
                  : rivers.data
                      ?.filter(
                        (river) =>
                          authentication.data.river.includes(river.id) &&
                          (river.send_email_feature_active ||
                            river.beats?.filter((beat) => beat.send_email_feature_active)?.length > 0),
                      )
                      .map((river) => (
                        <OptGroup key={river.id} label={`${intl.formatMessage({ id: "river" })} - ${river.name}`}>
                          {river.send_email_feature_active && (
                            <Option value={`river_${river.id}`}>{`${intl.formatMessage({ id: "river" })} - ${
                              river.name
                            }`}</Option>
                          )}
                          {beats.data_emails
                            ?.filter(
                              (beat) =>
                                authentication.data.beat.includes(beat.id) &&
                                beat.river_id === river.id &&
                                beat.send_email_feature_active,
                            )
                            .map((beat) => (
                              <Option key={beat.id} value={`beat_${beat.id}`}>{`${intl.formatMessage({ id: "beat" })} - ${
                                beat.name
                              }`}</Option>
                            ))}
                        </OptGroup>
                      ))}
              </Select>
            </Col>
            <Col xs={24} md={6} style={{ maxWidth: 300 }}>
              <label className="ant-label" htmlFor="year_of_purchase">
                {intl.formatMessage({ id: "year_of_purchase" })}
              </label>
              <Select
                showSearch
                id="year_of_purchase"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={intl.formatMessage({ id: "year_of_purchase" })}
                optionFilterProp="children"
                disabled={!send_to || send_emails.loading}
                onChange={(e) => this.setField({ year: e })}
                filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={year}
              >
                <Option value="">{intl.formatMessage({ id: "all" })}</Option>
                {YEARS.map((i) => (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} md={6} style={{ maxWidth: 300 }}>
              <label className="ant-label" htmlFor="fishing_card_type">
                {intl.formatMessage({ id: "fishing_card_type" })}
              </label>
              <Select
                showSearch
                id="fishing_card_type"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={intl.formatMessage({ id: "fishing_card_type" })}
                optionFilterProp="children"
                disabled={!send_to || send_emails.loading}
                onChange={(e) => this.setField({ product_type: e })}
                filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={product_type}
              >
                <Option value="">{intl.formatMessage({ id: "all" })}</Option>
                {PRODUCT_TYPES.map((i) => (
                  <Option key={i?.key} value={i?.key}>
                    {i?.value}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </PageHeader>

        {active_tab === "send" && (
          <>
            {send_emails.data && (
              <Card style={{ marginTop: 20, marginBottom: 20 }}>
                <div className="container-600">
                  <Row>
                    <Col xs={12}>
                      <h2>
                        {intl.formatMessage({ id: "users" })}:{" "}
                        <strong style={{ marginLeft: 10 }}>
                          {send_emails.loading ? <LoadingOutlined style={{ fontSize: 18 }} spin /> : send_emails.data.users}
                        </strong>
                      </h2>
                    </Col>
                    <Col xs={12}>
                      <h2>
                        {intl.formatMessage({ id: "limit" })}:{" "}
                        <strong style={{ marginLeft: 10 }}>
                          {send_emails.loading ? <LoadingOutlined style={{ fontSize: 18 }} spin /> : send_emails.data.limit}
                        </strong>
                      </h2>
                    </Col>
                  </Row>
                </div>
              </Card>
            )}

            <Card style={{ marginTop: 20, marginBottom: 20 }}>
              <div className="container-600">
                <Row>
                  <Col xs={24}>
                    <label className="ant-label" htmlFor="title">
                      {intl.formatMessage({ id: "title" })}
                    </label>
                    <Input
                      id="title"
                      type="text"
                      size={ANT_CONFIG.size}
                      value={subject}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        this.setState({
                          subject: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                  <Col xs={24}>
                    <label className="ant-label" htmlFor="content">
                      {intl.formatMessage({ id: "content" })}
                    </label>
                    <div id="content">
                      <HTMLEditor
                        config={{
                          removePlugins: ["Heading"],
                          toolbar: ["bold", "italic", "|", "link", "|", "redo", "undo"],
                        }}
                        data={body}
                        onChange={(event, editor) => this.setState({ body: editor.getData() })}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                  <Col xs={24} className="text-right">
                    <Popconfirm
                      key="send"
                      disabled={!send_to || send_emails.data?.users < 1 || send_emails.data?.limit < 1}
                      placement="bottomRight"
                      onConfirm={this.send}
                      title={`${intl.formatMessage({ id: "send_to" })} ${send_emails.data?.users} ${intl
                        .formatMessage({ id: "users" })
                        .toLowerCase()}?`}
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    >
                      <Button
                        type="primary"
                        disabled={!send_to || send_emails.data?.users < 1 || send_emails.data?.limit < 1}
                        size={size}
                        loading={send_emails.loading}
                      >
                        {intl.formatMessage({ id: "send" })}
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </div>
            </Card>
          </>
        )}

        {active_tab === "buy_more" && (
          <Card style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="container-600">
              <Row>
                <Col xs={24}>
                  <p style={{ margin: 0 }}>{intl.formatMessage({ id: "each_block_is_credit_for_500_emails" })}</p>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col xs={24}>
                  <label className="ant-label" htmlFor="number_of_blocks">
                    {intl.formatMessage({ id: "number_of_blocks" })}
                  </label>
                  <Input
                    id="number_of_blocks"
                    type="number"
                    size={ANT_CONFIG.size}
                    value={number_of_blocks}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        number_of_blocks: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col xs={24} className="text-right">
                  <Button
                    size={ANT_CONFIG.size}
                    key="submit"
                    type="primary"
                    loading={send_emails.loading}
                    onClick={this.buyAdditionalEmails}
                    disabled={!send_to || number_of_blocks < 1}
                  >
                    {intl.formatMessage({ id: "buy_more" })}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        )}

        {active_tab === "orders" && (
          <Card style={{ marginTop: 20, marginBottom: 20 }}>
            <div className="container-800">
              <Row>
                <Col xs={24}>
                  <Table
                    loading={{ spinning: send_emails_orders.loading, indicator: SPIN_ICON }}
                    columns={tableColumns}
                    dataSource={send_to ? tableData : []}
                    pagination={ANT_CONFIG.pagination}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        )}
        <Scribe intl={intl} srcLink="https://scribehow.com/embed/Sende_e-post_til_fiskere__BI7cJJWSTvqK2RzMhrRjSA" />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  beats: state.Beats,
  rivers: state.Rivers,
  authentication: state.Auth,
  send_emails: state.SendMail,
  send_emails_orders: state.SendMailOrders,
});

export default withRouter(injectIntl(connect(mapStateToProps)(SendEmail)));
