import moment from 'moment'

export const UTCDateToLocalTime = date => {
    return moment(moment.utc(date, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm'));
}

export const UTCDateToLocalTimeString = date => {
    return moment.utc(date, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm');
}

export const UTCConverter = date => {
    return moment.utc(moment(date)).format('YYYY-MM-DD HH:mm')
}

export const UTCTimeToLocalTime = time => {
    return moment.utc(time, 'HH:mm').local().format('HH:mm')
}
