import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";
import strings from "../../constants/languages/strings";

function* fetch(action) {
  try {
    const response = yield call(
      API.call,
      "admin/river-orders",
      "POST",
      action.payload
    );
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_RIVER_ORDERS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_RIVER_ORDERS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_RIVER_ORDERS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* download(action) {
  // I have not figured out how to fetch error response from blob call, so this is the generic error message
  console.log(strings);
  const errorMsg =
    strings?.error?.nb?.something_went_wrong_download || "Something went wrong";
  try {
    const response = yield call(
      API.call,
      "admin/download-report/excel/river-cards",
      "POST",
      action.payload,
        '/api/v2/'
    );
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_RIVER_ORDERS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(errorMsg);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_RIVER_ORDERS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_RIVER_ORDERS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(errorMsg);
  }
}

function* cancel(action) {
  try {
    const response = yield call(
      API.call,
      `admin/order/${action.data}`,
      "DELETE"
    );
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.CANCEL_RIVER_ORDER_FAILURE,
        message: responseBody.errors,
        meta: action.meta,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.CANCEL_RIVER_ORDER_SUCCESS,
        payload: responseBody,
        meta: action.meta,
      });
      APIMessage.success(responseBody.data.message);

      return {
        payload: responseBody,
      };
    }
  } catch (e) {
    yield put({
      type: ACTION.CANCEL_RIVER_ORDER_FAILURE,
      message: e.errors,
      meta: action.meta,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_RIVER_ORDERS, fetch),
    takeEvery(ACTION.DOWNLOAD_RIVER_ORDERS, download),
    takeEvery(ACTION.CANCEL_RIVER_ORDER, cancel),
  ]);
}
