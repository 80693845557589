export default {
  nb: {
    "one-day-fishing-card": "En dags fiskekort",
    "two-days-fishing-card": "To dager fiskekort",
    "three-days-fishing-card": "Tre dager fiskekort",
    "five-days-fishing-card": "Fem dager fiskekort",
    "week-fishing-card": "Ukes fiskekort",
    "fifteen-days-fishing-card": "Femten dager fiskekort",
    "thirty-days-fishing-card": "Tretti dager fiskekort",
    "month-fishing-card": "Måneders fiskekort",
    "season-fishing-card": "Sesongkort",
    "one-day-fishing-card-with-accommodation": "En dags fiskekort med overnatting",
    "two-days-fishing-card-with-accommodation": "To dager fiskekort med overnatting",
    "three-days-fishing-card-with-accommodation": "Tre dager fiskekort med overnatting",
    "five-days-fishing-card-with-accommodation": "Fem dager fiskekort med overnatting",
    "week-fishing-card-with-accommodation": "Ukes fiskekort med overnatting",
    "fifteen-days-fishing-card-with-accommodation": "Femten dager fiskekort med overnatting",
    "thirty-days-fishing-card-with-accommodation": "Tretti dager fiskekort med overnatting",
    "month-fishing-card-with-accommodation": "Måneders fiskekort med overnatting",
    "season-fishing-card-with-accommodation": "Sesongkort med overnatting",
  },

  en: {
    "one-day-fishing-card": "One day fishing ticket",
    "two-days-fishing-card": "Two days fishing ticket",
    "three-days-fishing-card": "Three days fishing ticket",
    "five-days-fishing-card": "Five days fishing ticket",
    "week-fishing-card": "Week's fishing ticket",
    "fifteen-days-fishing-card": "Fifteen days fishing ticket",
    "thirty-days-fishing-card": "Thirty days fishing ticket",
    "month-fishing-card": "Month fishing ticket",
    "season-fishing-card": "Season fishing ticket",
    "one-day-fishing-card-with-accommodation": "One-day fishing ticket with accommodation",
    "two-days-fishing-card-with-accommodation": "Two days fishing ticket with accommodation",
    "three-days-fishing-card-with-accommodation": "Three days fishing ticket with accommodation",
    "five-days-fishing-card-with-accommodation": "Five days fishing ticket with accommodation",
    "week-fishing-card-with-accommodation": "Week's fishing ticket with accommodation",
    "fifteen-days-fishing-card-with-accommodation": "Fifteen days fishing ticket with accommodation",
    "thirty-days-fishing-card-with-accommodation": "Thirty days fishing ticket with accommodation",
    "month-fishing-card-with-accommodation": "Month fishing ticket with accommodation",
    "season-fishing-card-with-accommodation": "Season fishing ticket with accommodation",
  },
  sv: {
    "one-day-fishing-card": "En dags fiskekort",
    "two-days-fishing-card": "Två dagars fiskekort",
    "three-days-fishing-card": "Tre dagars fiskekort",
    "five-days-fishing-card": "Fem dagars fiskekort",
    "week-fishing-card": "Veckans fiskekort",
    "fifteen-days-fishing-card": "Femton dagars fiskekort",
    "thirty-days-fishing-card": "Trettio dagars fiskekort",
    "month-fishing-card": "Månads fiskekort",
    "season-fishing-card": "Säsongskort",
    "one-day-fishing-card-with-accommodation": "Endagsfiskekort med boende",
    "two-days-fishing-card-with-accommodation": "Två dagars fiskekort med boende",
    "three-days-fishing-card-with-accommodation": "Tre dagars fiskekort med boende",
    "five-days-fishing-card-with-accommodation": "Fem dagars fiskekort med boende",
    "week-fishing-card-with-accommodation": "Veckans fiskekort med boende",
    "fifteen-days-fishing-card-with-accommodation": "Femton dagars fiskekort med boende",
    "thirty-days-fishing-card-with-accommodation": "Trettio dagars fiskekort med boende",
    "month-fishing-card-with-accommodation": "Månadsfiskekort med boende",
    "season-fishing-card-with-accommodation": "Säsongsfiskekort med boende"
  }
};
