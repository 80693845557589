import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Button, InputNumber, Modal, Row, Col, Select, DatePicker, Input, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { fetchTypes, post as addSeasonCards, edit as editSeasonCards } from "../../../redux/season_cards/actions";
import ANT_CONFIG from "../../../constants/antconfig";
import { UTCDateToLocalTime } from '../../../services/utc_converter';

const SPIN_ICON = <LoadingOutlined className="select-spinner" spin />;

class ModalAddSeasonCards extends React.Component {
  constructor(props) {
    super(props);

    const isNew = props.modalData?.items?.length === 0;
    let publishDateStart = null;
    let publishDateEnd = null;
    let hasPriceAmount = false;

    if (!isNew) {
      publishDateStart = props.modalData.items[0].publish_date_start !== "1970-01-01 00:00:00" ? UTCDateToLocalTime(props.modalData.items[0].publish_date_start): null;
      publishDateEnd = props.modalData.items[0].publish_date_end !== "3999-12-31 00:00:00" ? UTCDateToLocalTime(props.modalData.items[0].publish_date_end) : null;
      hasPriceAmount = props.modalData.items[0].price_amounts;
    }

    this.state = {
      modal: true,
      submitted: false,
      cardType: null,
      isNew,
      hasPriceAmount,
      data: {
        beat_id: props.selectedBeat,
        season_fishing_card_type_id: !isNew ? props.modalData.items[0].season_fishing_card_type_id : null,
        publish_date_start: publishDateStart,
        publish_date_end: publishDateEnd,
        quantity: 1,
        year: !isNew ? props.modalData.items[0].year : moment().format("YYYY"),
        prices: !isNew ? props.modalData.items[0].price_amounts : [],
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchTypes(this.state.data.beat_id));
  }

  componentDidUpdate() {
    if (!this.props.season_cards.loading && !this.props.season_cards.error && this.state.submitted) {
      this.onCancel();
    }
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onCardTypeChange(id) {
    const cardType = this.props.season_cards.type.data.filter((i) => i.id === id)[0];

    this.setState((prevState) => ({
      cardType,
      data: {
        ...prevState.data,
        season_fishing_card_type_id: id,
      },
    }));
  }

  onSubmit = () => {
    this.setState({
      submitted: true,
    });

    if (this.state.isNew) {
      this.props.dispatch(addSeasonCards(this.state.data));
    } else {
      const { items } = this.props.modalData;
      const data = items.map((item) => {
        return {
          id: item.id,
          publish_date_start: this.state.data.publish_date_start,
          publish_date_end: this.state.data.publish_date_end,
          year: parseInt(this.state.data.year, 10),
          update_price_amounts: {
            ...item.price_amounts.map((i) => {
              const price = this.state.data.prices.filter((k) => k.price_type_id === i.price_type_id)[0];

              return {
                ...i,
                amount: price.amount,
              };
            }),
          },
        };
      });

      this.props.dispatch(editSeasonCards(data, this.props.calendarData));
    }
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  onNewPriceChange(amount, id) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        prices: [
          ...prevState.data.prices.filter((i) => i.price_type_id !== id),
          {
            price_type_id: id,
            amount,
          },
        ],
      },
    }));
  }

  onUpdatePriceChange(amount, id) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        prices: [
          ...prevState.data.prices.filter((i) => i.price_type_id !== id),
          {
            ...prevState.data.prices.filter((i) => i.price_type_id === id)[0],
            amount,
          },
        ],
      },
    }));
  }

  render() {
    return (
      <Modal
        width={600}
        title={this.props.intl.formatMessage({ id: "add_availability" })}
        visible={this.state.modal}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="submit" loading={this.props.season_cards.loading} type="primary" onClick={this.onSubmit}>
            {this.props.intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        <Row type="flex" gutter={[16, 20]}>
          {this.state.isNew && (
            <Col xs={24}>
              <label className="ant-label" htmlFor="season_card_type">
                {this.props.intl.formatMessage({ id: "season_card_type" })}
              </label>
              <Select
                id="season_card_type"
                size={ANT_CONFIG.size}
                loading={this.props.season_cards.type.loading}
                value={this.state.data.season_fishing_card_type_id}
                style={{ width: "100%" }}
                placeholder={`${this.props.intl.formatMessage({ id: "select_card_type" })}`}
                onChange={(e) => this.onCardTypeChange(e)}
                notFoundContent={this.props.season_cards.type.loading ? SPIN_ICON : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              >
                {this.props.season_cards.type.data?.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}

          <Col xs={24}>
            <label className="ant-label" htmlFor="select_year">
              {this.props.intl.formatMessage({ id: "select_year" })}
            </label>
            <Select
              id="select_year"
              size={ANT_CONFIG.size}
              style={{ width: "100%" }}
              value={this.state.data.year}
              placeholder={`${this.props.intl.formatMessage({ id: "select_year" })}`}
              onChange={(e) => this.onChangeValue({ year: e })}
            >
              {[moment().format("YYYY"), moment().add(1, "year").format("YYYY")].map((i) => (
                <Select.Option key={i} value={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Col>

          {this.state.isNew && (
            <Col xs={24}>
              <label className="ant-label" htmlFor="quantity">
                {this.props.intl.formatMessage({ id: "quantity" })}
              </label>
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                size={ANT_CONFIG.size}
                name="quantity"
                id="quantity"
                value={this.state.data.quantity}
                onChange={(e) => this.onChangeValue({ quantity: e })}
              />
            </Col>
          )}

          {this.state.isNew && this.state.cardType?.price_types?.length > 0 && (
            <Col xs={24}>
              <div className="prices-modal-container">
                <label className="ant-label">{this.props.intl.formatMessage({ id: "add_price" })}</label>
                {this.state.cardType.price_types.map((i) => (
                  <Row type="flex" justify="space-between" align="middle" key={i.id} style={{ width: "100%", paddingTop: 20 }}>
                    <Col xs={24} style={{ paddingBottom: 5 }}>
                      {i.name}
                    </Col>
                    <Col xs={24}>
                      <Input
                        style={{ marginBottom: 5 }}
                        type="number"
                        name="price"
                        id="price"
                        size={ANT_CONFIG.size}
                        placeholder={this.props.intl.formatMessage({ id: "price" })}
                        onChange={(e) => this.onNewPriceChange(e.target.value, i.id)}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
          )}

          {!this.state.isNew && this.state.hasPriceAmount && this.state.data.prices && (
            <Col xs={24}>
              <div className="prices-modal-container">
                <label className="ant-label">{this.props.intl.formatMessage({ id: "add_price" })}</label>
                {this.state.data.prices
                  .sort((a, b) => a.id - b.id)
                  .map((i) => (
                    <Row type="flex" justify="space-between" align="middle" key={i.id} style={{ width: "100%", paddingTop: 20 }}>
                      <Col xs={24} style={{ paddingBottom: 5 }}>
                        {i.price_type.name}
                      </Col>
                      <Col xs={24}>
                        <Input
                          style={{ marginBottom: 5 }}
                          type="number"
                          name="price"
                          id="price"
                          size={ANT_CONFIG.size}
                          placeholder={this.props.intl.formatMessage({ id: "price" })}
                          onChange={(e) => this.onUpdatePriceChange(e.target.value, i.price_type_id)}
                          defaultValue={i.amount}
                        />
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
          )}

          <Col xs={12} style={{ paddingTop: 20 }}>
            <label htmlFor="publish_date_start" className="ant-label">
              {this.props.intl.formatMessage({ id: "publish_date_start" })}
            </label>
            <DatePicker
              style={{ width: "100%" }}
              id="publish_date_start"
              size={ANT_CONFIG.size}
              format={ANT_CONFIG.datepicker_time_format}
              value={this.state.data.publish_date_start ? UTCDateToLocalTime(this.state.data.publish_date_start) : null}
              showTime
              onChange={(e) =>
                this.onChangeValue({
                  publish_date_start: e ? moment.utc(e).format(ANT_CONFIG.datetime_format) : null,
                })
              }
            />
          </Col>

          <Col xs={12} style={{ paddingTop: 20 }}>
            <label htmlFor="publish_date_end" className="ant-label">
              {this.props.intl.formatMessage({ id: "publish_date_end" })}
            </label>
            <DatePicker
              style={{ width: "100%" }}
              id="publish_date_end"
              size={ANT_CONFIG.size}
              format={ANT_CONFIG.datepicker_time_format}
              value={this.state.data.publish_date_end ? UTCDateToLocalTime(this.state.data.publish_date_end) : null}
              showTime
              onChange={(e) =>
                this.onChangeValue({
                  publish_date_end: e ? moment.utc(e).format(ANT_CONFIG.datetime_format) : null,
                })
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  season_cards: state.SeasonCards,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddSeasonCards)));
