export const FETCH_CATCHES_FOR_RIVER = "FETCH_CATCHES_FOR_RIVER";
export const FETCH_CATCHES_FOR_RIVER_SUCCESS = "FETCH_CATCHES_FOR_RIVER_SUCCESS";
export const FETCH_CATCHES_FOR_RIVER_FAILURE = "FETCH_CATCHES_FOR_RIVER_FAILURE";

export const FETCH_CATCH = "FETCH_CATCH";
export const FETCH_CATCH_SUCCESS = "FETCH_CATCH_SUCCESS";
export const FETCH_CATCH_FAILURE = "FETCH_CATCH_FAILURE";

export const EDIT_CATCH = "EDIT_CATCH";
export const EDIT_CATCH_SUCCESS = "EDIT_CATCH_SUCCESS";
export const EDIT_CATCH_FAILURE = "EDIT_CATCH_FAILURE";

export const ADD_CATCH = "ADD_CATCH";
export const ADD_CATCH_SUCCESS = "ADD_CATCH_SUCCESS";
export const ADD_CATCH_FAILURE = "ADD_CATCH_FAILURE";

export const REPORT_CATCH = "REPORT_CATCH";
export const REPORT_CATCH_SUCCESS = "REPORT_CATCH_SUCCESS";
export const REPORT_CATCH_FAILURE = "REPORT_CATCH_FAILURE";

export const CATCH_LIST_FILTER = "CATCH_LIST_FILTER";
export const CATCH_PARAM_FILTER = "CATCH_PARAM_FILTER";
export function fetchAll(payload) {
  return {
    type: FETCH_CATCHES_FOR_RIVER,
    payload
  };
}

export function fetch(id) {
  return {
    type: FETCH_CATCH,
    id
  };
}

export function edit(id, payload) {
  return {
    type: EDIT_CATCH,
    id,
    payload
  };
}

export function report(id) {
  return {
    type: REPORT_CATCH,
    id
  };
}

export function catchListFilter(data) {
  return {
    type: CATCH_LIST_FILTER,
    data,
  };
}
export function catchParamFilter(data) {
  return {
    type: CATCH_PARAM_FILTER,
    data,
  };
}

export function add(payload) {
  return {
    type: ADD_CATCH,
    payload
  };
}
