import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/page/${action.id}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_PAGE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_PAGE_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_PAGE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* store(action) {
  try {
    const response = yield call(API.call, "admin/page", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.STORE_PAGE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.STORE_PAGE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.STORE_PAGE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* update(action) {
  try {
    const response = yield call(API.call, `admin/page/${action.id}`, "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UPDATE_PAGE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UPDATE_PAGE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UPDATE_PAGE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* updateImage(action) {
  try {
    const response = yield call(API.call, `admin/page-image/${action.id}`, "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UPDATE_IMAGE_PAGE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UPDATE_IMAGE_PAGE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UPDATE_IMAGE_PAGE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_PAGE, fetch),
    takeEvery(ACTION.STORE_PAGE, store),
    takeEvery(ACTION.UPDATE_PAGE, update),
    takeEvery(ACTION.UPDATE_IMAGE_PAGE, updateImage),
  ]);
}
