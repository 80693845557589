export const FETCH_ARTICLE_CATEGORIES = "FETCH_ARTICLE_CATEGORIES";
export const FETCH_ARTICLE_CATEGORIES_SUCCESS = "FETCH_ARTICLE_CATEGORIES_SUCCESS";
export const FETCH_ARTICLE_CATEGORIES_FAILURE = "FETCH_ARTICLE_CATEGORIES_FAILURE";

export const STORE_ARTICLE_CATEGORY = "STORE_ARTICLE_CATEGORY";
export const STORE_ARTICLE_CATEGORY_SUCCESS = "STORE_ARTICLE_CATEGORY_SUCCESS";
export const STORE_ARTICLE_CATEGORY_FAILURE = "STORE_ARTICLE_CATEGORY_FAILURE";

export function fetch(language) {
  return {
    type: FETCH_ARTICLE_CATEGORIES,
    language
  };
}

export function store(data, language) {
  return {
    type: STORE_ARTICLE_CATEGORY,
    data,
    language
  };
}
