import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Card, Col, Row, PageHeader, Button, Input, Tabs, Checkbox, Alert, Table } from "antd";
import { Marker, Polygon } from "react-google-maps";
import { LoadingOutlined } from "@ant-design/icons";
import { fetch, update, setBeatStatus } from "../../redux/river/actions";
import Spinner from "../../components/spinner";
import GenericError from "../Errors/GenericError";
import CollapsedCard from "../../components/Card/CollapsedCard";
import ANT_CONFIG from "../../constants/antconfig";
import { DEFAULT_LANGUAGE, DEFAULT_POLYGON_STYLE, GOOGLE_MAP_DEFAULT_POSITION } from "../../constants";
import HTMLEditor from "../../components/HTMLEditor/HTMLEditor";
import Map from "../../components/Map/Map";
import MarkerList from "../../components/Map/MarkerList";
import LanguageDropdown from "../../components/LanguageDropdown/LanguageDropdown";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import Counties from "../../components/Counties/Counties";
import Countries from "../../components/Countries/Countries";
import AddMarkerModal from "../../components/modal/Map/AddMarkerModal";
import AddPolygonModal from "../../components/modal/Map/AddPolygonModal";
import AddCenterModal from "../../components/modal/Map/AddCenterModal";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";

const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const TABS_INFO = [
  {
    key: "general",
    forAdmin: false,
  },
  {
    key: "regulations",
    forAdmin: false,
  },
  {
    key: "disinfections",
    forAdmin: false,
  },
  {
    key: "images",
    forAdmin: false,
  },
  {
    key: "map",
    forAdmin: false,
  },
  {
    key: "seo",
    forAdmin: true,
  },
  {
    key: "additional_settings",
    forAdmin: true,
  },
  {
    key: "laksebors_message",
    forAdmin: false,
  },
];

class Page extends React.Component {
  static contextType = RolesContext;

  state = {
    activeTab: TABS_INFO[0].key,
    language: DEFAULT_LANGUAGE,
    data: null,
    active: null,
    showAddMarkerModal: false,
    showAddPolygonModal: false,
    showAddCenterModal: false,
    defaultPosition: GOOGLE_MAP_DEFAULT_POSITION,
  };

  componentDidMount() {
    this.props.dispatch(fetch(this.props.match.params.id));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.river.data !== this.props.river.data && this.props.river.data) {
      this.setFetchedData(this.props.river.data);
    }
  }

  onSave = () => {
    let updatedState = this.state.data;

    if (this.state.data.countries.some((c) => c.country_name)) {
      updatedState = { ...this.state.data, countries: this.state.data.countries.map((c) => c.id) };
    }
    if (this.state.data.counties.some((c) => c.name)) {
      updatedState = { ...updatedState, counties: this.state.data.counties.map((c) => c.id) };
    }

    const data = {
      ...updatedState,
      update_data: 1,
    };

    this.hideModal();

    this.props.dispatch(update(data, this.props.match.params.id));
  };

  setFetchedData(data) {
    this.setState({
      data: {
        name: data.name,
        latitude: data.latitude,
        longitude: data.longitude,
        objects_translations: data.objects_translations || [],
        contact_person: data.contact_person || "",
        contact_phone: data.contact_phone || "",
        contact_email: data.contact_email || "",
        map_data: data.map_data || [],
        length: data.length || "",
        laksebors_message_active: data.laksebors_messages?.[data.laksebors_messages.length - 1]?.active || 0,
        laksebors_message_content: data.laksebors_messages?.[data.laksebors_messages.length - 1]?.content || "",
        countries: data.countries || null,
        counties: data.counties || null,
        active_on_web: data.active_on_web || 0,
        active_on_laksebors: data.active_on_laksebors || 0,
        show_on_laksebors: data.show_on_laksebors || 0,
        featured_image: data.featured_image
          ? [
              {
                id: 1,
                url: data.featured_image,
              },
            ]
          : [],
        gallery_images: data.gallery_images || [],
        send_email_feature_active: data.send_email_feature_active,
        send_email_feature_limit: data.send_email_feature_limit,
      },
    });

    if (data.latitude && data.longitude) {
      this.setState({
        defaultPosition: {
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
        },
      });
    }
  }

  setData(data) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        ...data,
      },
    }));
  }

  setObjectTranslations(data, context, language) {
    const dataToChange = this.state.data?.objects_translations?.filter(
      (i) => i.language === language && i.key === context,
    )?.[0];

    if (!dataToChange) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              key: context,
              value: data,
              language,
            },
          ],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              ...dataToChange,
              value: data,
            },
          ],
        },
      }));
    }
  }

  setLanguage = (language) => {
    this.setState({
      language,
    });
  };

  setBeatStatus(key, value, id) {
    const { dispatch } = this.props;

    dispatch(setBeatStatus({ [key]: value }, id));
  }

  saveCenterMarker = (marker) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          latitude: marker.latitude,
          longitude: marker.longitude,
        },
      }),
      () => {
        this.onSave();
      },
    );
  };

  saveNewMarker = (marker) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          map_data: [...prevState.data.map_data, marker],
        },
      }),
      () => {
        this.onSave();
      },
    );
  };

  removeMarker = (id) => {
    this.setState((prevState) => ({
      active: null,
      data: {
        ...prevState.data,
        map_data: [...prevState.data.map_data.filter((i) => i.id !== id)],
      },
    }));

    this.props.dispatch(
      update(
        {
          remove_marker: {
            id,
          },
        },
        this.props.match.params.id,
      ),
    );
  };

  saveNewPolygon = (polygon) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          map_data: [
            ...prevState.data.map_data,
            {
              ...polygon,
              latitude: null,
              longitude: null,
              poly_style: JSON.stringify(polygon.poly_style || DEFAULT_POLYGON_STYLE),
            },
          ],
        },
      }),
      () => {
        this.onSave();
      },
    );
  };

  handleCountyChange = (e) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        counties: e,
      },
    }));
  };

  handleCountryChange = (e) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        countries: e,
      },
    }));
  };

  onTabChange = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  addFeatureImage = (file) => {
    if (file?.response?.data?.default) {
      this.props.dispatch(
        update(
          {
            featured_image: file.response.data.default,
          },
          this.props.match.params.id,
        ),
      );
    }
  };

  addSeoFeatureImage = (file) => {
    if (file?.response?.data?.default) {
      this.props.dispatch(
        update(
          {
            seo_featured_image: {
              key: "seo_featured_image",
              value: file.response.data.default,
              language: this.state.language,
            },
          },
          this.props.match.params.id,
        ),
      );
    }
  };

  removeFeatureImage = () => {
    this.props.dispatch(
      update(
        {
          remove_featured_image: true,
        },
        this.props.match.params.id,
      ),
    );
  };

  removeSeoFeatureImage = () => {
    this.props.dispatch(
      update(
        {
          seo_featured_image: {
            key: "seo_featured_image",
            value: "",
            language: this.state.language,
          },
        },
        this.props.match.params.id,
      ),
    );
  };

  addGalleryImage = (file) => {
    if (file.response?.data?.default && file.status === "done") {
      this.props.dispatch(
        update(
          {
            add_gallery_image: {
              url: file.response.data.default,
            },
          },
          this.props.match.params.id,
        ),
      );
    }
  };

  removeGalleryImage = (file) => {
    this.props.dispatch(
      update(
        {
          remove_gallery_image: {
            url: file.file || file.response?.data?.default,
          },
        },
        this.props.match.params.id,
      ),
    );
  };

  activeMarker = (active = null) => {
    this.setState({
      active,
    });
  };

  showAddMarkerModal = () => {
    this.setState({
      showAddMarkerModal: true,
    });
  };

  showAddPolygonModal = () => {
    this.setState({
      showAddPolygonModal: true,
    });
  };

  showAddCenterModal = () => {
    this.setState({
      showAddCenterModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      showAddMarkerModal: false,
      showAddCenterModal: false,
      showAddPolygonModal: false,
    });
  };

  render() {
    if (
      (!this.props.river.loading && this.props.river.error) ||
      !this.props.authentication.data?.river?.includes(parseInt(this.props.match.params.id, 10))
    ) {
      return <GenericError />;
    }

    if (!this.state.data) {
      return <Spinner />;
    }

    const { isAdmin } = this.context;
    const { intl } = this.props;

    const tabs = (
      <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange}>
        {TABS_INFO.map(
          (t) =>
            (!t.forAdmin || (t.forAdmin && isAdmin)) && <Tabs.TabPane tab={intl.formatMessage({ id: t.key })} key={t.key} />,
        )}
      </Tabs>
    );

    return (
      <div>
        <PageHeader
          title={this.props.river.data?.name}
          footer={tabs}
          extra={[
            <Button onClick={this.onSave} loading={this.props.river.loading} type="primary" key="save" size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
            <LanguageDropdown key="languageDropdown" setLanguage={this.setLanguage} />,
          ]}
        />

        <div style={this.state.activeTab !== TABS_INFO[0].key ? { display: "none" } : {}}>
          {isAdmin && (
            <CollapsedCard title={intl.formatMessage({ id: "name" })}>
              <div className="container-900">
                <Row>
                  <Col xs={24}>
                    <Input
                      id="name"
                      type="text"
                      size={ANT_CONFIG.size}
                      value={this.state.data?.name || ""}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        this.setData({
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </div>
            </CollapsedCard>
          )}

          <CollapsedCard title={intl.formatMessage({ id: "country" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Countries handleChange={this.handleCountryChange} current={this.state.data.countries} />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "county" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Counties handleChange={this.handleCountyChange} current={this.state.data.counties} />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "content" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "content" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) => this.setObjectTranslations(editor.getData(), "content", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "information" })}>
            <div className="container-900">
              <Row gutter={30}>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="contact_person">
                    {intl.formatMessage({ id: "contact_person" })}
                  </label>
                  <Input
                    id="contact_person"
                    type="text"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.contact_person || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        contact_person: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="contact_phone">
                    {intl.formatMessage({ id: "contact_phone" })}
                  </label>
                  <Input
                    type="tel"
                    id="contact_phone"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.contact_phone || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        contact_phone: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="contact_email">
                    {intl.formatMessage({ id: "contact_email" })}
                  </label>
                  <Input
                    type="email"
                    id="contact_email"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.contact_email || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        contact_email: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={24} sm={12} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="river_length">
                    {intl.formatMessage({ id: "river_length" })}
                  </label>
                  <Input
                    type="number"
                    id="river_length"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.length || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        length: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={`${intl.formatMessage({ id: "optional_page" })} #1`}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <label className="ant-label" htmlFor="optional_1_title">
                    {intl.formatMessage({ id: "title" })}
                  </label>
                  <Input
                    type="text"
                    id="optional_1_title"
                    size={ANT_CONFIG.size}
                    value={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "optional_1_title" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "optional_1_title", this.state.language)}
                  />
                </Col>
                <Col xs={24} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="optional_1_content">
                    {intl.formatMessage({ id: "content" })}
                  </label>
                  <HTMLEditor
                    id="optional_1_content"
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "optional_1_content" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) =>
                      this.setObjectTranslations(editor.getData(), "optional_1_content", this.state.language)
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={`${intl.formatMessage({ id: "optional_page" })} #2`}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <label className="ant-label" htmlFor="optional_2_title">
                    {intl.formatMessage({ id: "title" })}
                  </label>
                  <Input
                    type="text"
                    id="optional_2_title"
                    size={ANT_CONFIG.size}
                    value={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "optional_2_title" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "optional_2_title", this.state.language)}
                  />
                </Col>
                <Col xs={24} style={{ marginTop: 15 }}>
                  <label className="ant-label" htmlFor="optional_2_content">
                    {intl.formatMessage({ id: "content" })}
                  </label>
                  <HTMLEditor
                    id="optional_2_content"
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "optional_2_content" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) =>
                      this.setObjectTranslations(editor.getData(), "optional_2_content", this.state.language)
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== TABS_INFO[1].key ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "link" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    type="text"
                    id="regulation_link"
                    size={ANT_CONFIG.size}
                    value={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "regulation_link" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "regulation_link", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "content" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "regulation" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) =>
                      this.setObjectTranslations(editor.getData(), "regulation", this.state.language)
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== TABS_INFO[2].key ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "content" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor
                    data={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "disinfection" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(event, editor) =>
                      this.setObjectTranslations(editor.getData(), "disinfection", this.state.language)
                    }
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== TABS_INFO[3].key ? { display: "none" } : {}}>
          <Card style={{ marginTop: 20, marginBottom: 20 }} title={intl.formatMessage({ id: "featured_image" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  data={this.state.data.featured_image}
                  limit={1}
                  onFileRemove={this.removeFeatureImage}
                  onFileAdd={this.addFeatureImage}
                />
              </Col>
            </Row>
          </Card>

          <Card style={{ marginTop: 20, marginBottom: 20 }} title={intl.formatMessage({ id: "image_gallery" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  multiple
                  data={this.state.data.gallery_images}
                  onFileRemove={this.removeGalleryImage}
                  onFileAdd={this.addGalleryImage}
                />
              </Col>
            </Row>
          </Card>
        </div>

        <div style={this.state.activeTab !== TABS_INFO[4].key ? { display: "none" } : {}}>
          <PageHeader
            className="ant-page-header-for-maps"
            extra={[
              <Button onClick={this.showAddCenterModal} disabled={this.props.river.loading} key="add_center_point">
                {intl.formatMessage({ id: "add_center_point" })}
              </Button>,
              <Button
                onClick={this.showAddMarkerModal}
                disabled={this.props.river.loading || !this.props.river.data.latitude || !this.props.river.data.longitude}
                key="add_marker"
              >
                {intl.formatMessage({ id: "add_marker" })}
              </Button>,
              <Button
                onClick={this.showAddPolygonModal}
                disabled={this.props.river.loading || !this.props.river.data.latitude || !this.props.river.data.longitude}
                key="add_polygon"
              >
                {intl.formatMessage({ id: "add_polygon" })}
              </Button>,
            ]}
          />

          <AddCenterModal
            position={this.state.defaultPosition}
            save={this.saveCenterMarker}
            cancel={this.hideModal}
            show={this.state.showAddCenterModal}
          />

          <AddMarkerModal
            position={this.state.defaultPosition}
            save={this.saveNewMarker}
            cancel={this.hideModal}
            show={this.state.showAddMarkerModal}
          />

          <AddPolygonModal
            position={this.state.defaultPosition}
            save={this.saveNewPolygon}
            cancel={this.hideModal}
            show={this.state.showAddPolygonModal}
          />

          {(!this.props.river.data.latitude || !this.props.river.data.longitude) && (
            <Card style={{ marginTop: 20, marginBottom: 20 }}>
              <Alert
                message={`${intl.formatMessage({ id: "remember" })}!`}
                description={intl.formatMessage({ id: "add_center_points_first" })}
                type="info"
                showIcon
              />
            </Card>
          )}

          <Card style={{ marginTop: 20, marginBottom: 20 }}>
            <Row>
              <Col xs={24}>
                <Map defaultPosition={this.state.defaultPosition}>
                  {this.props.river.data.latitude && this.props.river.data.longitude && !this.state.active && (
                    <Marker
                      position={{
                        lat: parseFloat(this.props.river.data.latitude),
                        lng: parseFloat(this.props.river.data.longitude),
                      }}
                    />
                  )}

                  {this.state.data?.map_data &&
                    this.state.data.map_data.map((i, key) => {
                      if (i.type === "marker") {
                        const icon = i.icon || "default";

                        if (this.state.active) {
                          if (this.state.active === i.id) {
                            return (
                              <Marker
                                key={`${i.id}-${key}`}
                                position={{ lat: parseFloat(i.latitude), lng: parseFloat(i.longitude) }}
                                icon={`/images/map/${icon}.svg`}
                              />
                            );
                          }

                          return false;
                        }

                        return (
                          <Marker
                            key={`${i.id}-${key}`}
                            position={{ lat: parseFloat(i.latitude), lng: parseFloat(i.longitude) }}
                            icon={`/images/map/${icon}.svg`}
                          />
                        );
                      }

                      if (i.type === "polygon") {
                        const cords = JSON.parse(i.poly_coordinates);
                        const style = i.poly_style ? JSON.parse(i.poly_style) : null;

                        if (this.state.active) {
                          if (this.state.active === i.id) {
                            return <Polygon key={`${i.id}-${key}`} paths={cords[0]} options={style || DEFAULT_POLYGON_STYLE} />;
                          }

                          return false;
                        }

                        return <Polygon key={`${i.id}-${key}`} paths={cords[0]} options={style || DEFAULT_POLYGON_STYLE} />;
                      }

                      return false;
                    })}
                </Map>
              </Col>
            </Row>
          </Card>

          {this.state.data?.map_data && (
            <Card style={{ marginTop: 20, marginBottom: 20 }}>
              <Row>
                <Col xs={24}>
                  {this.props.river.loading ? (
                    <Spinner />
                  ) : (
                    <MarkerList
                      activeMarker={this.activeMarker}
                      data={this.state.data.map_data}
                      onMarkerRemove={this.removeMarker}
                    />
                  )}
                </Col>
              </Row>
            </Card>
          )}
        </div>

        <div style={this.state.activeTab !== TABS_INFO[5].key ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "title" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    type="text"
                    id="title"
                    size={ANT_CONFIG.size}
                    value={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "seo_title" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_title", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "description" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input.TextArea
                    name="description"
                    id="description"
                    autoSize={{ minRows: 3 }}
                    size={ANT_CONFIG.size}
                    value={
                      this.state.data.objects_translations?.filter(
                        (i) => i.key === "seo_description" && i.language === this.state.language,
                      )?.[0]?.value || ""
                    }
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_description", this.state.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "featured_image" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  data={
                    this.state.data.objects_translations?.filter(
                      (i) => i.key === "seo_featured_image" && i.language === this.state.language,
                    )?.[0]?.value
                      ? [
                          {
                            id: 1,
                            url: this.state.data.objects_translations?.filter(
                              (i) => i.key === "seo_featured_image" && i.language === this.state.language,
                            )[0].value,
                          },
                        ]
                      : []
                  }
                  limit={1}
                  onFileRemove={this.removeSeoFeatureImage}
                  onFileAdd={this.addSeoFeatureImage}
                />
              </Col>
            </Row>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "additional_information" })}>
            <Row>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={
                    this.state.data.objects_translations?.filter(
                      (i) => i.key === "seo_noindex" && i.language === this.state.language && parseInt(i.value, 10),
                    ).length > 0
                      ? this.state.data.objects_translations?.filter(
                          (i) => i.key === "seo_noindex" && i.language === this.state.language && parseInt(i.value, 10),
                        )?.[0].value
                      : 0
                  }
                  onChange={(e) => this.setObjectTranslations(e.target.checked ? 1 : 0, "seo_noindex", this.state.language)}
                >
                  {intl.formatMessage({ id: "hide_from_crawlers" })}
                </Checkbox>
              </Col>
            </Row>
          </CollapsedCard>
        </div>

        <div style={this.state.activeTab !== TABS_INFO[6].key ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "additional_settings" })}>
            <Row type="flex" gutter={[20, 20]}>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={this.state.data.active_on_web}
                  onChange={(e) => this.setData({ active_on_web: e.target.checked ? 1 : 0 })}
                >
                  {intl.formatMessage({ id: "active_on_web" })}
                </Checkbox>
              </Col>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={this.state.data.active_on_laksebors}
                  onChange={(e) => this.setData({ active_on_laksebors: e.target.checked ? 1 : 0 })}
                >
                  {intl.formatMessage({ id: "active_on_laksebors" })}
                </Checkbox>
              </Col>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={this.state.data.show_on_laksebors}
                  onChange={(e) => this.setData({ show_on_laksebors: e.target.checked ? 1 : 0 })}
                >
                  {intl.formatMessage({ id: "show_on_laksebors" })}
                </Checkbox>
              </Col>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={this.state.data.send_email_feature_active}
                  onChange={(e) => this.setData({ send_email_feature_active: e.target.checked ? 1 : 0 })}
                >
                  {intl.formatMessage({ id: "send_email" })}
                </Checkbox>
              </Col>
              {this.state.data.send_email_feature_active ? (
                <Col xs={24} md={12} lg={6}>
                  <label className="ant-label" htmlFor="limit">
                    {intl.formatMessage({ id: "limit" })}
                  </label>
                  <Input
                    type="number"
                    id="limit"
                    size={ANT_CONFIG.size}
                    value={this.state.data?.send_email_feature_limit || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setData({
                        send_email_feature_limit: e.target.value,
                      })
                    }
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
          </CollapsedCard>

          {this.props.river.data?.beats && (
            <CollapsedCard title={intl.formatMessage({ id: "beat_list" })}>
              <Row type="flex" gutter={[20, 20]}>
                <Col xs={24}>
                  <Table
                    loading={{ spinning: this.props.river.beatStatusLoading, indicator: SPIN_ICON }}
                    columns={[
                      {
                        title: "#",
                        dataIndex: "key",
                        key: "key",
                      },
                      {
                        title: intl.formatMessage({ id: "beat" }),
                        dataIndex: "beat",
                        key: "beat",
                      },
                      {
                        title: intl.formatMessage({ id: "active_on_web" }),
                        dataIndex: "active_on_web",
                        key: "active_on_web",
                      },
                      {
                        title: intl.formatMessage({ id: "active_on_laksebors" }),
                        dataIndex: "active_on_laksebors",
                        key: "active_on_laksebors",
                      },
                    ]}
                    dataSource={this.props.river.data.beats.map((i) => {
                      return {
                        key: i.id,
                        beat: i.name,
                        active_on_web: (
                          <Checkbox
                            size={ANT_CONFIG.size}
                            defaultChecked={i.active_on_web}
                            onChange={(e) => this.setBeatStatus("active_on_web", e.target.checked ? 1 : 0, i.id)}
                          />
                        ),
                        active_on_laksebors: (
                          <Checkbox
                            size={ANT_CONFIG.size}
                            defaultChecked={i.active_on_laksebors}
                            onChange={(e) => this.setBeatStatus("active_on_laksebors", e.target.checked ? 1 : 0, i.id)}
                          />
                        ),
                      };
                    })}
                    pagination={false}
                  />
                </Col>
              </Row>
            </CollapsedCard>
          )}
        </div>

        <div style={this.state.activeTab !== TABS_INFO[7].key ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "content" })}>
            <div className="container-900">
              <Row style={{ marginBottom: 20 }}>
                <Col xs={24}>
                  <Checkbox
                    size={ANT_CONFIG.size}
                    checked={this.state.data?.laksebors_message_active}
                    onChange={(e) => this.setData({ laksebors_message_active: e.target.checked ? 1 : 0 })}
                  >
                    {intl.formatMessage({ id: "active" })}
                  </Checkbox>
                </Col>
              </Row>

              {this.state.data?.laksebors_message_active ? (
                <Row>
                  <Col xs={24}>
                    <HTMLEditor
                      data={this.state.data?.laksebors_message_content || ""}
                      onChange={(event, editor) => this.setData({ laksebors_message_content: editor.getData() })}
                    />
                  </Col>
                </Row>
              ) : null}
            </div>
          </CollapsedCard>
        </div>
        <Scribe
        intl={intl}
          hasMultiple={true}
          items={[
            {
              title: intl.formatMessage({ id: "scribe_about_us" }),
              link: "https://scribehow.com/embed/Om_elva__oppdatere_tekst_og_kontaktperson__6SOrsG9BRGWaEbqd830YjQ",
            },
            {
              title: intl.formatMessage({ id: "scribe_optional_page" }),
              link: "https://scribehow.com/embed/Valgfri_side_1_og_2__Vfs4TH_TQDW0ZUx4dhUg0w",
            },
            {
              title: intl.formatMessage({ id: "scribe_fishing_regulations" }),
              link: "https://scribehow.com/embed/Regler__legge_til_og_endre_fiskereglene__M05I7IDwTBi5M94yxL9R7A",
            },
            {
              title: intl.formatMessage({ id: "scribe_images" }),
              link: "https://scribehow.com/embed/Om_elva__legge_til_bilder__8BRfEml1RxSUoSWTm8xBKA",
            },
            {
              title: intl.formatMessage({ id: "scribe_map" }),
              link: "https://scribehow.com/embed/Kart__Gjore_endringer_i_kart__Ewt6r5CbQwW61_lp2kPw-w",
            },
            {
              title: intl.formatMessage({ id: "scribe_catches_message" }),
              link: "https://scribehow.com/embed/Melding_pa_lakseborsen_til_vassdraget_ditt__kRQj8zk3T5KJtmyNstIFbg",
            },
            {
              title: intl.formatMessage({ id: "scribe_catches_disinfection" }),
              link: "https://scribehow.com/embed/Desinfisering__legge_inn_informasjon__wtRgwFFQTaKrCfedWSNVbA",
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  river: state.River,
  authentication: state.Auth,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Page)));
