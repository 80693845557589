export const GET_SEND_EMAIL_ORDERS = "GET_SEND_EMAIL_ORDERS";
export const GET_SEND_EMAIL_ORDERS_SUCCESS = "GET_SEND_EMAIL_ORDERS_SUCCESS";
export const GET_SEND_EMAIL_ORDERS_FAILURE = "GET_SEND_EMAIL_ORDERS_FAILURE";

export function getEmailOrders(data) {
  return {
    type: GET_SEND_EMAIL_ORDERS,
    data,
  };
}
