import React from 'react';
import Select from 'react-select';

const SelectInput = props => {
  return (
    <Select
      name={props.name || ''}
      id={props.id || ''}
      className={`${props.className || ''} react-select-container`}
      classNamePrefix="react-select"
      placeholder={props.placeholder ? `${props.placeholder}...` : `${props.intl.formatMessage({ id: 'select' })}...`}
      noOptionsMessage={() => props.intl.formatMessage({ id: 'no_results' })}
      value={props.value || []}
      isMulti={props.isMulti || false}
      onChange={props.onChange}
      options={props.options || []}
      isClearable={props.isClearable || false}
      isDisabled={props.disabled || false}
    />
  );
};

export default SelectInput;
