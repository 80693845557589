import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker, Card, Col, Row, PageHeader, Select, Table } from "antd";

import {
  fetch as fetchDisinfections,
  download as downloadDisinfections
} from "../../redux/disinfection/actions";
import Spinner from "../../components/spinner";
import riverOptions from "../../services/select_river_options";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import ANT_CONFIG from "../../constants/antconfig";
import downloadFile from "../../services/download_file";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import Scribe from "../../components/Scribe";

const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Disinfection extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      data: {
        river_id: this.props.authentication.data.river[0],
        date_start: moment().subtract(14, "days").format(`${this.dateFormat}`),
        date_end: moment().format(`${this.dateFormat}`),
      },
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (
        prevProps.disinfection.file !== this.props.disinfection.file &&
        this.props.disinfection.file
    ) {
      downloadFile(
          this.props.disinfection.file,
          "xlsx",
          `disinfections-${moment(this.state.data.date_start).format(
              "YYYY-MM-DD"
          )}-${moment(this.state.data.date_end).format("YYYY-MM-DD")}`
      );
    }
  }

  handleDataChange() {
    this.props.dispatch(fetchDisinfections(this.state.data));
  }

  handleChangeDate(e, key) {
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, [key]: moment(e).format(`${this.dateFormat}`) },
      }),
      () => {
        this.handleDataChange();
      }
    );
  }

  handleRiverChange(e) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          river_id: e,
        },
      }),
      () => {
        this.props.dispatch(selectRiver(e));
        this.handleDataChange();
      }
    );
  }

  handleDownload = () => {
    this.props.dispatch(downloadDisinfections(this.state.data));
  };

  render() {
    if (!this.props.rivers.data) {
      return <Spinner />;
    }

    const {
      disinfection: { file_loading },
    } = this.props;


    const {
      intl,
      rivers,
      authentication,
      disinfection: { loading, data },
    } = this.props;
    const selectRiver = riverOptions(rivers, authentication);
    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
      },
      {
        title: intl.formatMessage({ id: "station" }),
        dataIndex: "station",
        key: "station",
      },
      {
        title: intl.formatMessage({ id: "customer" }),
        dataIndex: "customer",
        key: "customer",
      },
      {
        title: intl.formatMessage({ id: "customer_id" }),
        dataIndex: "customer_id",
        key: "customer_id",
      },
    ];
    const tableData = data?.map((item) => ({
      key: item.id,
      date: moment(item.created_at).format("DD.MM.YYYY"),
      station: item.station ? `${item.station.name} (ID: ${item.station_id})` : item.station_id,
      customer: item.username || "-",
      customer_id: item.user_id,
    }));

    return (
      <div>
        <PageHeader
            title={intl.formatMessage({ id: "disinfection" })}
            extra={
              <FileDownloadDropdown
                  key="1"
                  handleClick={this.handleDownload}
                  loading={file_loading}
                  options={[
                    {
                      value: "xlsx",
                      label: "XLSX",
                      type: "excel",
                    },
                  ]}
              />
            }>
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                {selectRiver.length > 1 ? (
                  <Col xs={24} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label className="ant-label">{intl.formatMessage({ id: "select_river" })}</label>
                    <Select
                      showSearch
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({ id: "select_river" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleRiverChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.data.river_id}
                    >
                      {selectRiver.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                ) : null}

                <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label className="ant-label">{intl.formatMessage({ id: "date_start" })}</label>
                  <DatePicker
                    id="date_start"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_start)}
                    onChange={(e) => this.handleChangeDate(e, "date_start")}
                  />
                </Col>

                <Col xs={12} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label className="ant-label">{intl.formatMessage({ id: "date_end" })}</label>
                  <DatePicker
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_end)}
                    onChange={(e) => this.handleChangeDate(e, "date_end")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table loading={{ spinning: loading, indicator: SPIN_ICON }} columns={tableColumns} dataSource={tableData} pagination={ANT_CONFIG.pagination} />
            </Col>
          </Row>
        </Card>
        <Scribe intl={intl} srcLink="https://scribehow.com/embed/Se_alle_desinfiseringer_i_elva__kca7p44EQE6lhBwICknjVQ"/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  disinfection: state.Disinfection,
  rivers: state.Rivers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Disinfection)));
