import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import 'moment-timezone';
import { Button, Popconfirm, InputNumber, Modal, Row, Col, Input, Select, DatePicker, Alert, Radio } from "antd";

import {
  add as addFishingCards,
  edit as editFishingCards,
  fetchTypes as fetchCardTypes,
  remove as removeFishingCards,
} from "../../../redux/fishing_cards/actions";
import {fetch as fetchBeat} from '../../../redux/beat/actions'
import Spinner from "../../spinner";
import ANT_CONFIG from "../../../constants/antconfig";
import { UTCDateToLocalTime, UTCTimeToLocalTime } from '../../../services/utc_converter';

const times = [...Array.from({ length: 24 }, (v, k) => (k < 10 ? `0${k}:00` : `${k}:00`))];
const duration = [...Array.from({ length: 24 }, (v, k) => k + 1)];


class ModalModalAddFishingCard extends React.Component {
  constructor(props) {
    super(props);

    const isNew = props.modalData.items.length === 0;
    let prices = [];
    let hasPriceAmount = false;
    let validFrom = "00:00";
    let validForHours = "24";

    let publishDateStart = "";
    let publishDateEnd = "";

    const data = props.modalData.items[0];

    if (!isNew) {

      prices = data.price_amounts || data.price_options;
      hasPriceAmount = data.price_amounts;

      publishDateStart = data?.publish_date_start !== "1970-01-01 00:00:00" ? data?.publish_date_start : null;
      publishDateEnd = data?.publish_date_end !== "3999-12-31 00:00:00" ? data?.publish_date_end : null;

      if (data.valid_from && data.valid_until) {
        const validFromMoment = moment(data.valid_from);
        const validUntilMoment = moment(data.valid_until);
        
        validFrom = UTCDateToLocalTime(data.valid_from);

        const duration = moment.duration(validUntilMoment.diff(validFromMoment));
        validForHours = duration.asHours();
      }
    }

    this.state = {
      modal: true,
      submitted: false,
      isNew,
      card_type: null,
      hasPriceAmount,
      flexible_time: !isNew ? data.fishing_card_type?.flexible_time : false,
      hoursMinutesTimestampLength: 5,
      data: {
        fishing_card_type_name: !isNew ? data.fishing_card_type?.name : null,
        fishing_card_type_id: !isNew ? data.fishing_card_type_id : null,
        beat_id: !isNew ? data.beat_id : props.selectedBeat,
        date_start: props.modalData.startDate,
        date_end: props.modalData.endDate,
        valid_from_time: validFrom,
        validity_duration_in_hours: validForHours,
        price: !isNew ? data.price : "",
        quantity: 1,
        publish_date_start: publishDateStart,
        publish_date_end: publishDateEnd,
        publishing_type: data?.publishing_type,
        publish_day_count_start: data?.publish_day_count_start ?? 0,
        publish_hour_count_start: data?.publish_hour_count_start ?? 0,
        prices,
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchCardTypes(this.state.data.beat_id));
    this.props.dispatch(fetchBeat(this.state.data.beat_id));
  }

  componentDidUpdate() {
    if (!this.props.fishing_cards.loading && !this.props.fishing_cards.error && this.state.submitted) {
      this.onCancel();
    }
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onDelete = () => {
    this.setState({
      submitted: true,
    });

    const ids = this.props.modalData.items.map((i) => i.id);
    this.props.dispatch(removeFishingCards({ card_ids: ids }, this.props.calendarData));
  };

  onSubmit = () => {
    if (!this.saveBtnValidation()) {
      return false;
    }

    this.setState({
      submitted: true,
    });

    const datesBetween = this.getDatesBetween(this.state.data.date_start, this.state.data.date_end);

    const valid_time = datesBetween.map(item => {
      return this.generateUtcDateTime(item);
    })

    const updatedState = {...this.state.data, valid_from_time: valid_time};

    if (this.state.isNew) {
      this.props.dispatch(addFishingCards(updatedState, this.props.calendarData));
    } else {
      const { items } = this.props.modalData;
      const data = items.map((item) => {
        return {
          id: item.id,
          fishing_card_type_id: updatedState.fishing_card_type_id,
          publish_date_start: updatedState.publish_date_start,
          publish_date_end: updatedState.publish_date_end,
          valid_from_time: this.state.card_type?.flexible_time ? "00:00" : updatedState.valid_from_time,
          validity_duration_in_hours: this.state.card_type?.flexible_time ? "24" : updatedState.validity_duration_in_hours,
          update_price_amounts: {
            ...item.price_amounts.map((i) => {
              const price = updatedState.prices.filter((k) => k.price_type_id === i.price_type_id)[0];

              return {
                ...i,
                amount: price.amount,
              };
            }),
          },
        };
      });

      this.props.dispatch(editFishingCards(data, this.props.calendarData));
    }
  };

  getDatesBetween(startDate, stopDate) {
    let dateArray = [];
    let currentDate = moment(startDate);
    let endDate = moment(stopDate);
    while (currentDate <= endDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  generateUtcDateTime(date) {
    let dateTimeInBeatTimeZone;

    if(this.state.data.valid_from_time.length == this.state.hoursMinutesTimestampLength) {
      dateTimeInBeatTimeZone = moment.utc(date).tz(this.props.beat.data.timezone.name).hour(this.state.data.valid_from_time.slice(0,2))
    } else {
      dateTimeInBeatTimeZone = moment.utc(date).tz(this.props.beat.data.timezone.name).set('hour', this.state.data.valid_from_time.hour())
    }

    const formatedDateTime = moment.utc(dateTimeInBeatTimeZone).format('YYYY-MM-DD HH:mm')

    return {
      date: date,
      valid_from: formatedDateTime
     }
  }

  onCardTypeChange(id) {
    const cardType = this.props.fishing_cards.type.data.filter((i) => i.id === id)[0];

    this.setState((prevState) => ({
      card_type: cardType,
      flexible_time: cardType.flexible_time,
      data: {
        ...prevState.data,
        fishing_card_type_id: id,
        valid_from_time: cardType.flexible_time ? "00:00" : prevState.data.valid_from_time,
        validity_duration_in_hours: cardType.flexible_time ? 24 : prevState.data.validity_duration_in_hours,
      },
    }));
  }

  onNewPriceChange(amount, id) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        prices: [
          ...prevState.data.prices.filter((i) => i.price_type_id !== id),
          {
            price_type_id: id,
            amount,
          },
        ],
      },
    }));
  }

  onUpdatePriceChange(amount, id) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        prices: [
          ...prevState.data.prices.filter((i) => i.price_type_id !== id),
          {
            ...prevState.data.prices.filter((i) => i.price_type_id === id)[0],
            amount,
          },
        ],
      },
    }));
  }

  onCancel = () => {
    this.props.toggleModal();
  };

  saveBtnValidation() {
    if (typeof this.state.hasPriceAmount === "undefined") {
      return false;
    }

    if (!this.state.isNew && moment(this.state.data.date_end) < moment()) {
      return false;
    }

    if (this.state.data.publishing_type === null) {
      return false;
    }

    if (this.state.data.publishing_type === 'on-date') {
      if (this.state.data.publish_date_start === null && this.state.data.publish_date_end == null) {
        return false;
      }
    }

    if (this.state.data.publishing_type === 'gradual') {
      if (this.state.data.publish_day_count_start === null && this.state.data.publish_hour_count_start == null) {
        return false;
      }
    }

    return true;
  }

  selectStartTime = (e) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        valid_from_time: e,
      },
    }));
  };

  selectDuration = (e) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        validity_duration_in_hours: e,
      },
    }));
  };

  render() {
    return (
      <Modal
        width={600}
        title={this.props.intl.formatMessage({ id: "add_edit_availability" })}
        visible={this.state.modal}
        onCancel={this.onCancel}
        footer={[
          !this.state.isNew && typeof this.state.hasPriceAmount !== "undefined" && (
            <Popconfirm
              title={this.props.intl.formatMessage({ id: "confirm_delete_question" })}
              onConfirm={this.onDelete}
              okText={this.props.intl.formatMessage({ id: "remove" })}
              cancelText={this.props.intl.formatMessage({ id: "back" })}
              key="remove"
            >
              <Button
                size={ANT_CONFIG.size}
                type="danger"
                disabled={this.props.fishing_cards.loading}
                style={{ marginRight: this.props.fishing_cards.loading ? 8 : 0 }}
              >
                {this.props.intl.formatMessage({ id: "remove" })}
              </Button>
            </Popconfirm>
          ),
          <Button
            size={ANT_CONFIG.size}
            key="submit"
            disabled={!this.saveBtnValidation()}
            loading={this.props.fishing_cards.loading}
            type="primary"
            onClick={this.onSubmit}
          >
            {this.props.intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        {!this.props.fishing_cards.data || this.props.fishing_cards.type.loading ? (
          <Spinner />
        ) : (
          <Row gutter={16}>
            {!this.state.isNew && (
              <Col xs={24}>
                <strong className="strong-title">{this.state.data.fishing_card_type_name}</strong>
              </Col>
            )}

            {this.state.isNew && (
              <Col xs={24}>
                <label className="ant-label" htmlFor="select_card_type">
                  {this.props.intl.formatMessage({ id: "fishing_card" })}
                </label>
                <Select
                  id="select_card_type"
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${this.props.intl.formatMessage({ id: "select_card_type" })}`}
                  onChange={(e) => this.onCardTypeChange(e)}
                >
                  {this.props.fishing_cards?.type?.data?.map((i) => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            )}

            {this.state.isNew && (
              <Col xs={24} style={{ paddingTop: 20 }}>
                <label className="ant-label" htmlFor="quantity">
                  {this.props.intl.formatMessage({ id: "quantity" })}
                </label>
                <InputNumber
                  style={{ width: "100%" }}
                  min={1}
                  size={ANT_CONFIG.size}
                  name="quantity"
                  id="quantity"
                  value={this.state.data.quantity}
                  onChange={(e) => this.onChangeValue({ quantity: e })}
                />
              </Col>
            )}

            {!this.state.isNew && !this.state.hasPriceAmount && (
              <Col xs={24} style={{ paddingBottom: 25 }}>
                <Alert
                  message={this.props.intl.formatMessage({ id: "warning" })}
                  description={this.props.intl.formatMessage({ id: "you_need_to_update_pricetype" })}
                  type="warning"
                  showIcon
                />
              </Col>
            )}

            <Col xs={12} style={{ paddingTop: this.state.isNew ? 20 : 0 }}>
              <label htmlFor="date_start" className="ant-label">
                {this.props.intl.formatMessage({ id: "fishing_card_from_date" })}
              </label>
              <DatePicker
                style={{ width: "100%" }}
                id="date_start"
                size={ANT_CONFIG.size}
                allowClear={false}
                disabled={!this.state.isNew}
                format={ANT_CONFIG.datepicker_format}
                value={moment(this.state.data.date_start)}
                onChange={(e) => this.onChangeValue({ date_start: e.format(ANT_CONFIG.date_format) })}
              />
            </Col>

            <Col xs={12} style={{ paddingTop: this.state.isNew ? 20 : 0 }}>
              <label htmlFor="date_end" className="ant-label">
                {this.props.intl.formatMessage({ id: "fishing_card_to_date" })}
              </label>
              <DatePicker
                style={{ width: "100%" }}
                id="date_end"
                size={ANT_CONFIG.size}
                allowClear={false}
                disabled={!this.state.isNew}
                format={ANT_CONFIG.datepicker_format}
                value={moment(this.state.data.date_end)}
                onChange={(e) => this.onChangeValue({ date_end: e.format(ANT_CONFIG.date_format) })}
              />
            </Col>

            {!this.state.flexible_time ? (
              <>
                <Col xs={12} style={{ paddingTop: 20 }}>
                  <label htmlFor="valid_from_time" className="ant-label">
                    {this.props.intl.formatMessage({ id: "valid_from_hours" })}
                  </label>
                  <Select
                    size={ANT_CONFIG.size}
                    id="valid_from_time"
                    onChange={(e) => this.selectStartTime(e)}
                    style={{ width: "100%" }}
                    value={this.state.data.valid_from_time.length == this.state.hoursMinutesTimestampLength ? this.state.data.valid_from_time : UTCTimeToLocalTime(this.state.data.valid_from_time)}
                  >
                    {times.map((i, key) => (
                      <Select.Option key={key} value={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col xs={12} style={{ paddingTop: 20 }}>
                  <label htmlFor="validity_duration_in_hours" className="ant-label">
                    {this.props.intl.formatMessage({ id: "validity_duration_in_hours" })}
                  </label>
                  <Select
                    size={ANT_CONFIG.size}
                    id="validity_duration_in_hours"
                    onChange={(e) => this.selectDuration(e)}
                    style={{ width: "100%" }}
                    value={this.state.data.validity_duration_in_hours}
                  >
                    {duration.map((i, key) => (
                      <Select.Option key={key} value={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </>
            ) : null}

            <Col xs={24}>
              {this.state.isNew && this.state.card_type?.price_types?.length > 0 && (
                <div className="prices-modal-container">
                  <label className="ant-label">{this.props.intl.formatMessage({ id: "price" })}</label>
                  {this.state.card_type?.price_types?.map((i) => (
                    <Row type="flex" justify="space-between" align="middle" key={i.id} style={{ width: "100%", paddingTop: 20 }}>
                      <Col xs={24} style={{ paddingBottom: 5 }}>
                        {i.name}
                      </Col>
                      <Col xs={24}>
                        <Input
                          style={{ marginBottom: 5 }}
                          type="number"
                          name="price"
                          id="price"
                          size={ANT_CONFIG.size}
                          placeholder={this.props.intl.formatMessage({ id: "price" })}
                          onChange={(e) => this.onNewPriceChange(e.target.value, i.id)}
                        />
                      </Col>
                    </Row>
                  ))}
                </div>
              )}

              {!this.state.isNew && this.state.hasPriceAmount && this.state.data.prices && (
                <div className="prices-modal-container">
                  <label className="ant-label">{this.props.intl.formatMessage({ id: "price" })}</label>
                  {this.state.data.prices
                    .sort((a, b) => a.id - b.id)
                    .map((i) => (
                      <Row type="flex" justify="space-between" align="middle" key={i.id} style={{ width: "100%", paddingTop: 20 }}>
                        <Col xs={24} style={{ paddingBottom: 5 }}>
                          {i.price_type.name}
                        </Col>
                        <Col xs={24}>
                          <Input
                            style={{ marginBottom: 5 }}
                            type="number"
                            name="price"
                            id="price"
                            size={ANT_CONFIG.size}
                            placeholder={this.props.intl.formatMessage({ id: "price" })}
                            onChange={(e) => this.onUpdatePriceChange(e.target.value, i.price_type_id)}
                            defaultValue={i.amount}
                          />
                        </Col>
                      </Row>
                    ))}
                </div>
              )}
            </Col>

            {
              (this.state.isNew) ? (
                <Col xs={24} style={{ paddingTop: 20 }}>
                  <label className="ant-label">
                    {this.props.intl.formatMessage({ id: "publish_type_header" })}
                  </label>
                  <Radio.Group onChange={(type) => this.onChangeValue({ publishing_type: type.target.value })} value={this.state.data.publishing_type}>
                    <Radio value="now">{this.props.intl.formatMessage({ id: "publish_type_now_radiotext" })}</Radio><br />
                    <Radio value="on-date">{this.props.intl.formatMessage({ id: "publish_type_ondate_radiotext" })}</Radio><br />
                    <Radio value="gradual">{this.props.intl.formatMessage({ id: "publish_type_gradual_radiotext" })}</Radio><br />
                  </Radio.Group>
                </Col>
              ) : null
            }

            {
              (this.state.data.publishing_type === 'on-date' || !this.state.isNew) ? (
                <>
                  <Col xs={12} style={{ paddingTop: 20 }}>
                    <label htmlFor="publish_date_start" className="ant-label">
                      {this.props.intl.formatMessage({ id: "publish_date_start" })}
                    </label>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="publish_date_start"
                      size={ANT_CONFIG.size}
                      format={ANT_CONFIG.datepicker_time_format}
                      value={this.state.data.publish_date_start ? UTCDateToLocalTime(this.state.data.publish_date_start) : null}
                      showTime
                      onChange={(e) => this.onChangeValue({
                        publish_date_start: e ? moment.utc(e).format(ANT_CONFIG.datetime_format) : null,
                      })} />
                  </Col>

                  <Col xs={12} style={{ paddingTop: 20 }}>
                    <label htmlFor="publish_date_end" className="ant-label">
                      {this.props.intl.formatMessage({ id: "publish_date_end" })}
                    </label>
                    <DatePicker
                      style={{ width: "100%" }}
                      id="publish_date_end"
                      size={ANT_CONFIG.size}
                      format={ANT_CONFIG.datepicker_time_format}
                      value={this.state.data.publish_date_end ? UTCDateToLocalTime(this.state.data.publish_date_end) : null}
                      showTime
                      onChange={(e) => this.onChangeValue({
                        publish_date_end: e ? moment.utc(e).format(ANT_CONFIG.datetime_format) : null,
                      })} />
                  </Col>
                </>
              ) : null
            }

            {
              (this.state.data.publishing_type === 'gradual') ? (
                <>
                  <Col xs={24} style={{ paddingTop: 20 }}>
                    <p>
                      <b>{this.props.intl.formatMessage({ id: "explanation" })}:</b> {this.props.intl.formatMessage({ id: "gradual_publish_explanation" })}
                    </p>
                  </Col>

                  <Col xs={12} style={{ paddingTop: 20 }}>
                    <label className="ant-label" htmlFor="publish_type_day_count">
                      {this.props.intl.formatMessage({ id: "publish_type_day_count_label" })}
                    </label>
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      size={ANT_CONFIG.size}
                      name="publish_type_day_count"
                      id="publish_type_day_count"
                      placeholder={this.props.intl.formatMessage({ id: "quantity" })}
                      onChange={(e) => this.onChangeValue({ publish_day_count_start: e })} />
                  </Col>

                  <Col xs={12} style={{ paddingTop: 20 }}>
                    <label className="ant-label" htmlFor="publish_type_hour_count">
                      {this.props.intl.formatMessage({ id: "publish_type_hour_count_label" })}
                    </label>
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      size={ANT_CONFIG.size}
                      name="publish_type_hour_count"
                      id="publish_type_hour_count"
                      placeholder={this.props.intl.formatMessage({ id: "quantity" })}
                      onChange={(e) => this.onChangeValue({ publish_hour_count_start: e })} />
                  </Col>
                </>
              ) : null
            }

          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  fishing_cards: state.FishingCards,
  beat: state.Beat
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalModalAddFishingCard)));
