export const FETCH_EXTRA_SERVICE = "FETCH_EXTRA_SERVICE";
export const FETCH_EXTRA_SERVICE_SUCCESS = "FETCH_EXTRA_SERVICE_SUCCESS";
export const FETCH_EXTRA_SERVICE_FAILURE = "FETCH_EXTRA_SERVICE_FAILURE";

export const ADD_EXTRA_SERVICE = "ADD_EXTRA_SERVICE";
export const ADD_EXTRA_SERVICE_SUCCESS = "ADD_EXTRA_SERVICE_SUCCESS";
export const ADD_EXTRA_SERVICE_FAILURE = "ADD_EXTRA_SERVICE_FAILURE";

export const UPDATE_EXTRA_SERVICE = "UPDATE_EXTRA_SERVICE";
export const UPDATE_EXTRA_SERVICE_SUCCESS = "UPDATE_EXTRA_SERVICE_SUCCESS";
export const UPDATE_EXTRA_SERVICE_FAILURE = "UPDATE_EXTRA_SERVICE_FAILURE";

export const REMOVE_EXTRA_SERVICE = "REMOVE_EXTRA_SERVICE";
export const REMOVE_EXTRA_SERVICE_SUCCESS = "ADD_EXTRA_SERVICE_SUCCESS";
export const REMOVE_EXTRA_SERVICE_FAILURE = "ADD_EXTRA_SERVICE_FAILURE";

export function fetch(data) {
  return {
    type: FETCH_EXTRA_SERVICE,
    data
  };
}

export function add(payload, data) {
  return {
    type: ADD_EXTRA_SERVICE,
    payload,
    data
  };
}

export function update(payload, data) {
  return {
    type: UPDATE_EXTRA_SERVICE,
    payload,
    data
  };
}

export function remove(id, data) {
  return {
    type: REMOVE_EXTRA_SERVICE,
    id,
    data
  };
}
