import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  available: undefined,
  blueprint: undefined,
  prices: {
    data: undefined,
    extra: undefined,
    loading: false,
    error: false
  }
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_ACCOMMODATION_BLEUPRINT:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_ACCOMMODATION_BLEUPRINT_SUCCESS:
      return {
        ...state,
        blueprint: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_ACCOMMODATION_BLEUPRINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.ADD_ACCOMMODATION_BLEUPRINT:
      return {
        ...state,
        loading: true
      };

    case ACTION.ADD_ACCOMMODATION_BLEUPRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.ADD_ACCOMMODATION_BLEUPRINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.EDIT_ACCOMMODATION_BLEUPRINT:
      return {
        ...state,
        loading: true
      };

    case ACTION.EDIT_ACCOMMODATION_BLEUPRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.EDIT_ACCOMMODATION_BLEUPRINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_ACCOMMODATIONS:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_ACCOMMODATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_ACCOMMODATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.ADD_ACCOMMODATION:
      return {
        ...state,
        loading: true
      };

    case ACTION.ADD_ACCOMMODATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.ADD_ACCOMMODATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.EDIT_ACCOMMODATION:
      return {
        ...state,
        loading: true
      };

    case ACTION.EDIT_ACCOMMODATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.EDIT_ACCOMMODATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.REMOVE_ACCOMMODATION:
      return {
        ...state,
        loading: true
      };

    case ACTION.REMOVE_ACCOMMODATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.REMOVE_ACCOMMODATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_AVAILABLE_ACCOMMODATIONS:
      return {
        ...state,
        loading: true
      };

    case ACTION.FETCH_AVAILABLE_ACCOMMODATIONS_SUCCESS:
      return {
        ...state,
        available: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_AVAILABLE_ACCOMMODATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.FETCH_ACCOMMODATION_PRICES:
      return {
        ...state,
        prices: {
          ...state.prices,
          loading: true
        }
      };

    case ACTION.FETCH_ACCOMMODATION_PRICES_SUCCESS:
      return {
        ...state,
        prices: {
          data: action.payload.data,
          extra: action.payload.extra,
          loading: false,
          error: false
        }
      };

    case ACTION.FETCH_ACCOMMODATION_PRICES_FAILURE:
      return {
        ...state,
        prices: {
          ...state.prices,
          loading: false,
          error: true
        }
      };

    default:
      return state;
  }
}
