import React from "react";
import { connect } from "react-redux";
import { Button, Col, Input, Modal, Radio, Row } from "antd";
import { Marker } from "react-google-maps";
import { injectIntl } from "react-intl";

import Map from "../../Map/Map";
import { MARKER_TYPES, DEFAULT_LANGUAGE } from "../../../constants";
import LanguageDropdown from "../../LanguageDropdown/LanguageDropdown";
import ANT_CONFIG from "../../../constants/antconfig";

class AddMarkerModal extends React.Component {
  state = {
    type: "marker",
    icon: "default",
    language: DEFAULT_LANGUAGE,
    latitude: parseFloat(this.props.position.lat),
    longitude: parseFloat(this.props.position.lng),
    content: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position && this.props.position) {
      this.setPositionFromProps(this.props.position);
      this.resetState();
    }
  }

  setIcon = (e) => {
    this.setState({
      icon: e.target.value,
    });
  };

  setPositionFromProps = (position) => {
    this.setState({
      latitude: position.lat,
      longitude: position.lng,
    });
  };

  setPosition = (e) => {
    this.setState({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  };

  setLanguage = (language) => {
    this.setState({
      language,
    });
  };

  setObjectTranslations(data, context, language) {
    const dataToChange = this.state.content?.filter((i) => i.language === language && i.key === context)?.[0];

    if (!dataToChange) {
      this.setState((prevState) => ({
        content: [
          ...prevState.content?.filter((i) => i.language !== language || i.key !== context),
          {
            key: context,
            value: data,
            language,
          },
        ],
      }));
    } else {
      this.setState((prevState) => ({
        content: [
          ...prevState.content?.filter((i) => i.language !== language || i.key !== context),
          {
            ...dataToChange,
            value: data,
          },
        ],
      }));
    }

    if (!data) {
      this.setState((prevState) => ({
        content: [...prevState.content?.filter((i) => i.language !== language || i.key !== context)],
      }));
    }
  }

  onSubmit = () => {
    this.props.save(this.state);
  };

  onCancel = () => {
    this.props.cancel();
  };

  resetState() {
    this.setState({
      type: "marker",
      icon: "default",
      language: DEFAULT_LANGUAGE,
      content: [],
    });
  }

  render() {
    return (
      <Modal
        title={this.props.intl.formatMessage({ id: "add_marker" })}
        visible={this.props.show}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="cancel" disabled={this.props.river.loading} type="default" onClick={this.onCancel}>
            {this.props.intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" disabled={this.props.river.loading} type="primary" onClick={this.onSubmit}>
            {this.props.intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        {this.props.show && (
          <React.Fragment>
            <Row>
              <Col xs={24}>
                <Map defaultPosition={{ lat: this.props.position.lat, lng: this.props.position.lng }}>
                  <Marker
                    position={{
                      lat: this.state.latitude,
                      lng: this.state.longitude,
                    }}
                    draggable
                    onDragEnd={this.setPosition}
                    icon={`/images/map/${this.state.icon}.svg`}
                  />
                </Map>
              </Col>
            </Row>

            <Row className="add-marker-modal-types">
              <Col xs={24}>
                <label className="ant-label" htmlFor="type">
                  {this.props.intl.formatMessage({ id: "type" })}
                </label>
                <Radio.Group onChange={this.setIcon} value={this.state.icon} style={{ width: "100%" }}>
                  {MARKER_TYPES.map((i) => (
                    <Row key={i} style={{ paddingTop: 10 }}>
                      <Col xs={24}>
                        <Radio value={i}>{this.props.intl.formatMessage({ id: `marker_${i}` })}</Radio>
                      </Col>
                    </Row>
                  ))}
                </Radio.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={24} style={{ textAlign: "right" }}>
                <LanguageDropdown setLanguage={this.setLanguage} />
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <label className="ant-label" htmlFor="description">
                  {this.props.intl.formatMessage({ id: "description" })}
                </label>
                <Input.TextArea
                  name="description"
                  id="description"
                  size={ANT_CONFIG.size}
                  autoSize={{ minRows: 2 }}
                  value={this.state.content?.filter((i) => i.key === "infowindow_content" && i.language === this.state.language)?.[0]?.value || ""}
                  onChange={(e) => this.setObjectTranslations(e.target.value, "infowindow_content", this.state.language)}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  river: state.River,
});

export default injectIntl(connect(mapStateToProps)(AddMarkerModal));
