export const FETCH_DISINFECTION = "FETCH_DISINFECTION";
export const FETCH_DISINFECTION_SUCCESS = "FETCH_DISINFECTION_SUCCESS";
export const FETCH_DISINFECTION_FAILURE = "FETCH_DISINFECTION_FAILURE";

export const DOWNLOAD_DISINFECTIONS = "DOWNLOAD_DISINFECTIONS";
export const DOWNLOAD_DISINFECTIONS_SUCCESS = "DOWNLOAD_DISINFECTIONS_SUCCESS";
export const DOWNLOAD_DISINFECTIONS_FAILURE = "DOWNLOAD_DISINFECTIONS_FAILURE";

export const FETCH_DISINFECTION_SA = "FETCH_DISINFECTION_SA";
export const FETCH_DISINFECTION_SA_SUCCESS = "FETCH_DISINFECTION_SA_SUCCESS";
export const FETCH_DISINFECTION_SA_FAILURE = "FETCH_DISINFECTION_SA_FAILURE";

export const ADD_DISINFECTION = "ADD_DISINFECTION";
export const ADD_DISINFECTION_SUCCESS = "ADD_DISINFECTION_SUCCESS";
export const ADD_DISINFECTION_FAILURE = "ADD_DISINFECTION_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DISINFECTION,
    payload
  };
}

export function download(payload) {
  return {
    type: DOWNLOAD_DISINFECTIONS,
    payload,
  };
}

export function fetchStationAdmin(payload) {
  return {
    type: FETCH_DISINFECTION_SA,
    payload
  };
}

export function add(payload) {
  return {
    type: ADD_DISINFECTION,
    payload
  };
}
