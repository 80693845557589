import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import Moment from "moment";
import { Table, DatePicker, Col, Row, PageHeader, Select, Card, Button } from "antd";
import { extendMoment } from "moment-range";

import Spinner from "../../components/spinner";
import beatOptions from "../../services/select_beat_options";
import { fetchFishingCardsReport, downloadFishingCardsReport, filterFishingCardsReport } from "../../redux/reports/actions";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import ANT_CONFIG from "../../constants/antconfig";
import downloadFile from "../../services/download_file";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import { Link } from "react-router-dom";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {UTCDateToLocalTimeString} from "../../services/utc_converter";

const moment = extendMoment(Moment);

class FishingCardsReport extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      filetype: null,
      fetch_by: "card_date",
      filterLists: [],
      selectedCatchFilter: "",
      date_start: moment().subtract(1, "day").format(`${this.dateFormat}`),
      date_end: moment().format(`${this.dateFormat}`),
      beat_ids:
        Array.isArray(this.props.beats.selected) || !this.props.beats.selected
          ? this.props.authentication.data.beat[0]
          : this.props.beats.selected,
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reports.file !== this.props.reports.file && this.props.reports.file) {
      const uniqId = Math.random().toString(36).substr(2, 9);

      downloadFile(this.props.reports.file, this.state.filetype, `by-fishing-cards-${uniqId}`);
    }
  }

  onFilterSubmit() {
    this.setState({ filterLists: [], selectedCatchFilter: "" });
    this.props.dispatch(selectBeat(this.state.beat_ids));

    this.handleDataChange();
  }

  handleDownload = (reportType = null, filetype = "pdf") => {
    const { data } = this.props.reports;
    window.event.preventDefault();
    let filteredObj = {};
    for (const [key, value] of Object.entries(data.results)) {
      let finalData = [];
      if (this.state.filterLists.length) {
        for (const v of this.state.filterLists) {
          finalData = [...finalData, ...value.filter((record) => record.type === v)];
        }
      } else {
        finalData = value;
      }

      switch (this.state.selectedCatchFilter) {
        case "registered_catch":
          finalData = finalData.filter((record) => record.registered_catch === 1);
          break;
        case "unregistered_catch":
          finalData = finalData.filter((record) => record.registered_catch === 0);
          break;
        default:
          break;
      }

      filteredObj[key] = finalData;
    }

    let download_data = { ...data };

    this.setState(
      {
        filetype,
      },
      () => {
        download_data.filters = {
          ...download_data.filters,
          filetype,
          view_type: this.state.reportBy,
        };

        download_data.results = filteredObj;

        this.props.dispatch(downloadFishingCardsReport(download_data, reportType));
      },
    );
  };

  handleDataChange() {
    const data = {
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      fetch_by: this.state.fetch_by,
      beat_ids: this.state.beat_ids ? [this.state.beat_ids] : [],
    };

    this.props.dispatch(fetchFishingCardsReport(data));
  }

  handleDateChange(e, key) {
    this.setState({
      [key]: moment(e).format(`${this.dateFormat}`),
    });
  }

  handleBeatChange(e) {
    this.setState({
      beat_ids: e,
    });
  }

  handleDateFilterChange(e) {
    this.setState({
      fetch_by: e,
    });
  }

  render() {
    const { intl } = this.props;

    if (!this.props.reports.data || !this.props.beats.data || this.props.reports.loading) {
      return <Spinner />;
    }

    const data = this.props.reports.filtered_data || [];
    const unfilteredData = Array.isArray(this.props.reports.unfiltered_data) ? this.props.reports.unfiltered_data : [];

    const catchFilterOptions = [
      { label: intl.formatMessage({ id: "all_catches" }), value: "" },
      { label: intl.formatMessage({ id: "report_submitted" }), value: "registered_catch" },
      { label: intl.formatMessage({ id: "report_missing" }), value: "unregistered_catch" },
    ];

    const onCatchFilterSelected = (value, data) => {
      let originalData = data;
      switch (value) {
        case "registered_catch":
          originalData = originalData.filter((record) => record.registered_catch === 1);
          break;
        case "unregistered_catch":
          originalData = originalData.filter((record) => record.registered_catch === 0);
          break;
        default:
          originalData = data;
          break;
      }
      return originalData;
    };

    let selectBeats = [];

    if (this.props.beats.data && this.props.beats.data.length > 0) {
      selectBeats = [...beatOptions(this.props.beats, this.props.authentication)];
    }

    const onFilterSelected = (filterList) => {
      if (!filterList.length) {
        return unfilteredData;
      }
      let originalData = unfilteredData;
      let finalData = [];
      for (const value of filterList) {
        finalData = [...finalData, ...originalData.filter((record) => record.type === value)];
      }
      return finalData;
    };

    const onChange = () => {
      let filterTicket = onFilterSelected(this.state.filterLists);
      let filterCatchData = onCatchFilterSelected(this.state.selectedCatchFilter, filterTicket);
      this.props.dispatch(filterFishingCardsReport(filterCatchData));
    };

    const tableColumns = [
      {
        title: "FCID",
        dataIndex: "key",
        key: "key",
        width: 100,
        sorter: (a, b) => parseInt(a?.key) - parseInt(b?.key),
      },
      {
        title: intl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
        width: 200,
        sorter: (a, b) => (a.type === "-") - (b.type === "-") || ("" + a.type).localeCompare(b.type),
      },
      {
        title: intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
        width: 120,
        sorter: (a, b) => (a.beat === "-") - (b.beat === "-") || ("" + a.beat).localeCompare(b.beat),
      },
      {
        title: intl.formatMessage({ id: "fishing_card_date" }),
        dataIndex: "fishing_card_date",
        key: "fishing_card_date",
        defaultSortOrder: "descend",
        width: 120,
        sorter: (a, b) => new Date(a.fishing_card_date) - new Date(b.fishing_card_date),
      },
      {
        title: intl.formatMessage({ id: "angler_name" }),
        dataIndex: "name",
        key: "name",
        width: 120,
        sorter: (a, b) => (a.name === "-") - (b.name === "-") || ("" + a.name).localeCompare(b.name),
      },
      {
        title: intl.formatMessage({ id: "buyer_name" }),
        dataIndex: "buyer_name",
        key: "buyer_name",
        width: 120,
        sorter: (a, b) => (a.buyer_name === "-") - (b.buyer_name === "-") || ("" + a.buyer_name).localeCompare(b.buyer_name),
        render: (data, rec) => (
          <div onClick={(e) => e.stopPropagation()}>
            {rec?.user_id ? <Link to={`/customer/${rec?.user_id}`}>{data}</Link> : { data }}
          </div>
        ),
      },
      {
        title: intl.formatMessage({ id: "buyer_address" }),
        dataIndex: "buyer_address",
        key: "buyer_address",
        width: 150,
        sorter: (a, b) =>
          (a.buyer_address === "-") - (b.buyer_address === "-") || ("" + a.buyer_address).localeCompare(b.buyer_address),
      },
      {
        title: intl.formatMessage({ id: "buyer_country" }),
        dataIndex: "buyer_country",
        key: "buyer_country",
        width: 100,
        sorter: (a, b) =>
          (a.buyer_country === "-") - (b.buyer_country === "-") || ("" + a.buyer_country).localeCompare(b.buyer_country),
      },
      {
        title: intl.formatMessage({ id: "buyer_phone" }),
        dataIndex: "buyer_phone",
        key: "buyer_phone",
        width: 120,
        sorter: (a, b) => (b.buyer_phone !== "-") - (a.buyer_phone !== "-") || a.buyer_phone - b.buyer_phone,
      },
      {
        title: intl.formatMessage({ id: "buyer_email" }),
        dataIndex: "buyer_email",
        key: "buyer_email",
        width: 200,
        sorter: (a, b) =>
          (a.buyer_email === "-") - (b.buyer_email === "-") || ("" + a.buyer_email).localeCompare(b.buyer_email),
      },
      {
        title: intl.formatMessage({ id: "order_id" }),
        dataIndex: "order_id",
        key: "order_id",
        width: 100,
        sorter: (a, b) => (b.order_id !== "-") - (a.order_id !== "-") || a.order_id - b.order_id,
      },
      {
        title: intl.formatMessage({ id: "order_date" }),
        dataIndex: "order_date",
        key: "order_date",
        width: 120,
        sorter: (a, b) => new Date(a.order_date) - new Date(b.order_date),
      },
      {
        title: intl.formatMessage({ id: "price_type" }),
        dataIndex: "price_type",
        key: "price_type",
        width: 120,
        sorter: (a, b) => (a.price_type === "-") - (b.price_type === "-") || ("" + a.price_type).localeCompare(b.price_type),
      },
      {
        title: intl.formatMessage({ id: "price_amount" }),
        dataIndex: "price_amount",
        key: "price_amount",
        width: 120,
        sorter: (a, b) => (b.price_amount !== "-") - (a.price_amount !== "-") || a.price_amount - b.price_amount,
      },
      {
        title: intl.formatMessage({ id: "seller" }),
        dataIndex: "seller",
        key: "seller",
        width: 140,
        sorter: (a, b) => (a.seller === "-") - (b.seller === "-") || ("" + a.seller).localeCompare(b.seller),
      },
      {
        title: intl.formatMessage({ id: "is_in_period_card" }),
        dataIndex: "is_in_period_card",
        key: "is_in_period_card",
        width: 120,
        sorter: (a, b) =>
          (a.is_in_period_card === "-") - (b.is_in_period_card === "-") ||
          ("" + a.is_in_period_card).localeCompare(b.is_in_period_card),
      },
      {
        title: intl.formatMessage({ id: "period_card_type" }),
        dataIndex: "period_card_type",
        key: "period_card_type",
        width: 120,
        sorter: (a, b) =>
          (a.period_card_type === "-") - (b.period_card_type === "-") ||
          ("" + a.period_card_type).localeCompare(b.period_card_type),
      },
      {
        title: intl.formatMessage({ id: "period_card_price_type" }),
        dataIndex: "period_card_price_type",
        key: "period_card_price_type",
        width: 120,
        sorter: (a, b) =>
          (a.period_card_price_type === "-") - (b.period_card_price_type === "-") ||
          ("" + a.period_card_price_type).localeCompare(b.period_card_price_type),
      },
      {
        title: intl.formatMessage({ id: "period_card_price_amount" }),
        dataIndex: "period_card_price_amount",
        key: "period_card_price_amount",
        width: 120,
        sorter: (a, b) =>
          (b.period_card_price_amount !== "-") - (a.period_card_price_amount !== "-") ||
          a.period_card_price_amount - b.period_card_price_amount,
      },
      {
        title: intl.formatMessage({ id: "catch_report_status" }),
        dataIndex: "registered_catch",
        key: "registered_catch",
        width: 100,
        render: (data, record) => (
          <div>
            {data === 1 ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 30 }} />
            ) : (
              <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 30 }} />
            )}
          </div>
        ),
        sorter: (a, b) => a.registered_catch - b.registered_catch,
      },
      {
        title: intl.formatMessage({ id: "fishing_effort" }),
        dataIndex: "fishing_effort",
        key: "fishing_effort",
        width: 150,
        render: (data, record) => (
          <div>
            {record.registered_catch == 1 ? (
              record.not_used === 1 ? (
                <Button style={{ backgroundColor: "orange", color: "black", padding: "5px 10px", borderRadius: "8px" }}>
                  {intl.formatMessage({ id: "did_not_use" })}
                </Button>
              ) : (
                <> </>
              )
            ) : (
              <></>
            )}
          </div>
        ),
      },
    ];

    const dateFilter = [
      {
        value: "card_date",
        label: this.props.intl.formatMessage({ id: "product_date" }),
      },
      {
        value: "purchase_date",
        label: this.props.intl.formatMessage({ id: "order_date" }),
      },
    ];

    const tableData = [];
    let filterOptions = [];

    let filterItem = unfilteredData.map((it) => {
      return { label: it.type, value: it.type };
    });
    filterOptions = [...filterOptions, filterItem];
    filterOptions = filterOptions
      .flat()
      .filter((value, index, self) => index === self.findIndex((t) => t.label === value.label && t.value === value.value));

    data.forEach((item) => {
      tableData.push({
        key: item.fcId,
        type: item.type,
        beat: item.beat,
        fishing_card_date: item.fishing_card_date,
        name: item.name || "-",
        user_id: item.user_id,
        buyer_name: item.buyer_name || "-",
        buyer_address: item.buyer_address || "-",
        buyer_country: item.buyer_country || "-",
        buyer_phone: item.buyer_phone || "-",
        buyer_email: item.buyer_email || "-",
        order_id: item.order_id,
        order_date: UTCDateToLocalTimeString(item.order_date),
        price_type: item.price_type,
        price_amount: item.price_amount,
        seller: item.seller,
        is_in_period_card: item.is_in_period_card ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" }),
        period_card_type: item.period_card_type || "-",
        period_card_price_type: item.period_card_price_type || "-",
        period_card_price_amount: item.period_card_price_amount || "-",
        registered_catch: item.registered_catch,
        not_used: item.not_used,
      });
    });

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "fishing_card" })}
          extra={[<FileDownloadDropdown key="1" handleClick={this.handleDownload} loading={this.props.reports.file_loading} />]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16} className="row-flex flex-align-end">
                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_start" className="ant-label">
                    {intl.formatMessage({ id: "date_start" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_start)}
                    onChange={(e) => this.handleDateChange(e, "date_start")}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {intl.formatMessage({ id: "date_end" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_end)}
                    onChange={(e) => this.handleDateChange(e, "date_end")}
                  />
                </Col>

                <Col xs={24} sm={4} md={4} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                  <label htmlFor="select_orders_by" className="ant-label">
                    {this.props.intl.formatMessage({ id: "select_orders_by" })}
                  </label>
                  <Select
                    id="select_orders_by"
                    showSearch
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({ id: "select_orders_by" })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.handleDateFilterChange(e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={this.state.fetch_by}
                  >
                    {dateFilter.map((i, key) => (
                      <Select.Option key={key} value={i.value}>
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                {this.props.beats.data.length > 1 && (
                  <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label htmlFor="select_beat" className="ant-label">
                      {intl.formatMessage({ id: "select_beat" })}
                    </label>
                    <Select
                      id="select_beat"
                      showSearch
                      disabled={this.props.reports.file_loading}
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({ id: "select_beat" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleBeatChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.beat_ids}
                    >
                      {selectBeats.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Button
                    type="primary"
                    disabled={this.props.reports.file_loading}
                    onClick={() => this.onFilterSubmit()}
                    loading={this.props.reports.loading || this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                  >
                    {intl.formatMessage({ id: "apply" })}
                  </Button>
                </Col>
                <Col span={12} xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="filter_ticket_type" className="ant-label">
                    {intl.formatMessage({ id: "filter_ticket_type" })}
                  </label>
                  <Select
                    mode="multiple"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={(e) => {
                      this.setState(() => {
                        return { filterLists: e };
                      });
                      setTimeout(() => {
                        onChange();
                      }, 1);
                    }}
                    options={filterOptions}
                  />
                </Col>
                <Col span={12} xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <>
                    <label htmlFor="filter_catch_reporting" className="ant-label">
                      {intl.formatMessage({ id: "filter_catch_reporting" })}
                    </label>
                    <Select
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      onChange={(e) => {
                        this.setState(() => {
                          return { selectedCatchFilter: e };
                        });
                        setTimeout(() => {
                          onChange();
                        }, 1);
                      }}
                      options={catchFilterOptions}
                    />
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                className="ant-no-prewrap"
                bordered
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
                sticky={{ offsetHeader: 50 }}
                scroll={{
                  x: 1500,
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.Reports,
  authentication: state.Auth,
  beats: state.Beats,
});

export default withRouter(injectIntl(connect(mapStateToProps)(FishingCardsReport)));
