import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Row, Col, FormGroup, Label, Form, Input, Button } from "reactstrap";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import { fetch as fetchCurrencies, fetchTimeZones, updateRiver as updateRiverCurrency } from "../../redux/settings/actions";
import Spinner from "../../components/spinner";
import SelectInput from "../../components/select";
import { fetch as fetchRiverOptions, edit as editRiverOptions } from "../../redux/river_options/actions";
import SaveLabel from "../../components/helpers/buttons/save_label";
import riverOptions from "../../services/select_river_options";
import Commisions from "./commissions";
import { currencyOptions, timezoneOptions } from "../../services/select_settings_options";
import RolesContext from "../../context/RolesContext";
import { fetch } from "../../redux/river/actions";
import Scribe from "../../components/Scribe";

const labelWidth = 3;
const inputWidth = 9;

class RiverOptions extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);

    this.state = {
      river_id: this.props.rivers.selected || this.props.authentication.data.river[0],
      currency_obj: null,
      timezone: null,
      onSaveClicked: false,
      data: {},
    };
  }

  componentDidMount() {
    const { isAdmin } = this.context;

    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    if (!this.props.settings.currencies && isAdmin) {
      this.props.dispatch(fetchCurrencies());
    }

    if (!this.props.settings.timezones && isAdmin) {
      this.props.dispatch(fetchTimeZones());
    }

    this.props.dispatch(fetch(this.props.rivers.selected || this.props.authentication.data.river[0]));

    this.handleFetchRiverOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: {
        name:
          nextProps.river_options.data && nextProps.river_options.data.filter((i) => i.key === "organization_name")[0]
            ? nextProps.river_options.data.filter((i) => i.key === "organization_name")[0].value
            : "",
        street:
          nextProps.river_options.data && nextProps.river_options.data.filter((i) => i.key === "organization_street")[0]
            ? nextProps.river_options.data.filter((i) => i.key === "organization_street")[0].value
            : "",
        post_code:
          nextProps.river_options.data && nextProps.river_options.data.filter((i) => i.key === "organization_post_code")[0]
            ? nextProps.river_options.data.filter((i) => i.key === "organization_post_code")[0].value
            : "",
        city:
          nextProps.river_options.data && nextProps.river_options.data.filter((i) => i.key === "organization_city")[0]
            ? nextProps.river_options.data.filter((i) => i.key === "organization_city")[0].value
            : "",
        phone:
          nextProps.river_options.data && nextProps.river_options.data.filter((i) => i.key === "organization_phone")[0]
            ? nextProps.river_options.data.filter((i) => i.key === "organization_phone")[0].value
            : "",
        number:
          nextProps.river_options.data && nextProps.river_options.data.filter((i) => i.key === "organization_number")[0]
            ? nextProps.river_options.data.filter((i) => i.key === "organization_number")[0].value
            : "",
        account_number:
          nextProps.river_options.data && nextProps.river_options.data.filter((i) => i.key === "organization_account_number")[0]
            ? nextProps.river_options.data.filter((i) => i.key === "organization_account_number")[0].value
            : "",
        currencies: nextProps.settings.currencies,
      },
    });
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onSubmitSettings() {
    this.setState({ onSaveClicked: true });

    const data = {
      riverId: this.state.river_id,
      currency_id: this.state.currency_obj ? this.state.currency_obj.value : this.props.river.data.currency?.id,
      timezone_id: this.state.timezone ? this.state.timezone.value : this.props.river.data.timezone_id,
    };

    if (data.currency_id && data.timezone_id) {
      this.props.dispatch(updateRiverCurrency(data, this.state.river_id));
      this.props.dispatch(fetch(this.state.river_id));
      this.setState({ onSaveClicked: false });
    }
  }

  onSubmit() {
    const data = {
      ...this.state.data,
      river_id: this.state.river_id,
    };

    this.props.dispatch(editRiverOptions(data, this.state.river_id));
  }

  handleRiverChange(e) {
    this.props.dispatch(selectRiver(e));
    this.props.dispatch(fetch(e));
    this.setState({ river_id: e, currency_obj: null }, () => {
      this.handleFetchRiverOptions();
    });
  }

  handleCurrencyChange(e) {
    this.setState({ currency_obj: e });
  }

  handleTimezoneChange(e) {
    this.setState({ timezone: e });
  }

  handleFetchRiverOptions() {
    this.props.dispatch(fetchRiverOptions(this.state.river_id));
  }

  getCurrency(currencies, river) {
    return river && river.currency ? currencies.filter((i) => i.value === river.currency.id) : null;
  }

  getTimezone(timezones, river) {
    return river && river.timezone_id ? timezones.filter((i) => i.value === river.timezone_id) : null;
  }

  render() {
    const { isAdmin, isRiverAdmin } = this.context;

    if (this.props.rivers.loading || !this.props.rivers.data) {
      return <Spinner />;
    }

    if (isAdmin) {
      if (this.props.settings.loading || !this.props.settings.currencies) {
        return <Spinner />;
      }

      if (this.props.settings.loading || !this.props.settings.timezones) {
        return <Spinner />;
      }
    }

    const selectRivers = riverOptions(this.props.rivers, this.props.authentication);

    const selectCurrencies = currencyOptions(this.props.settings);

    const selectTimezones = timezoneOptions(this.props.settings);

    const selectedRiver = this.props.river.data;

    return (
      <div>
        {selectRivers.length > 1 ? (
          <div style={{ marginBottom: 30 }} className="panel">
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row style={{ marginBottom: 2 }}>
                    <Label for="select_river" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({ id: "select_river" })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <SelectInput
                        id="select_river"
                        name="select_river"
                        intl={this.props.intl}
                        value={selectRivers.filter((i) => i.value === this.state.river_id)}
                        onChange={(e) => this.handleRiverChange(e.value)}
                        options={selectRivers}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>
        ) : null}

        {(isAdmin || isRiverAdmin) && (
          <div>
            {this.props.river_options.loading ? (
              <Spinner />
            ) : (
              <div className="panel" style={{ paddingTop: 20 }}>
                <h2 className="title">{this.props.intl.formatMessage({ id: "options" })}</h2>
                <Form>
                  <Row>
                    <Col xs={12} lg={6}>
                      <FormGroup row>
                        <Label for="name" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "organization_name" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            value={this.state.data.name || ""}
                            onChange={(e) => this.onChangeValue({ name: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="street" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "street" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="street"
                            id="street"
                            value={this.state.data.street || ""}
                            onChange={(e) => this.onChangeValue({ street: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="post_code" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "post_code" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="post_code"
                            id="post_code"
                            value={this.state.data.post_code || ""}
                            onChange={(e) => this.onChangeValue({ post_code: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} lg={6}>
                      <FormGroup row>
                        <Label for="city" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "city" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="city"
                            id="city"
                            value={this.state.data.city || ""}
                            onChange={(e) => this.onChangeValue({ city: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="phone" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "phone" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="phone"
                            id="phone"
                            value={this.state.data.phone || ""}
                            onChange={(e) => this.onChangeValue({ phone: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="number" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "organization_number" })}
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="number"
                            id="number"
                            value={this.state.data.number || ""}
                            onChange={(e) => this.onChangeValue({ number: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="account_number" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "account_number" })}*
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <Input
                            type="text"
                            name="account_number"
                            id="account_number"
                            value={this.state.data.account_number || ""}
                            onChange={(e) => this.onChangeValue({ account_number: e.target.value })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <hr />
                      <FormGroup row style={{ marginBottom: 0 }}>
                        <Col xs={12} className="text-right">
                          <Button color="secondary" type="submit" onClick={() => this.onSubmit()}>
                            <SaveLabel intl={this.props.intl} />
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </div>
        )}

        {isAdmin && (
          <div>
            {this.props.river_options.loading ? (
              <Spinner />
            ) : (
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "settings" })}</h2>
                <Form>
                  <Row>
                    <Col xs={12} lg={6}>
                      <FormGroup row>
                        <Label for="currency" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "currency" })}{" "}
                          <span style={{ color: !this.state.currency_obj && this.state.onSaveClicked ? "red" : "" }}>*</span>
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <SelectInput
                            id="select_currency"
                            name="select_currency"
                            placeholder=""
                            value={
                              this.state.currency_obj
                                ? this.state.currency_obj
                                : this.getCurrency(selectCurrencies, selectedRiver)
                            }
                            intl={this.props.intl}
                            onChange={(e) => this.handleCurrencyChange(e)}
                            options={selectCurrencies}
                          />
                        </Col>
                      </FormGroup>
                    </Col>

                    <Col xs={12} lg={6}>
                      <FormGroup row>
                        <Label for="timezone" xs={12} sm={labelWidth}>
                          {this.props.intl.formatMessage({ id: "timezone" })}{" "}
                          <span style={{ color: !this.state.timezone && this.state.onSaveClicked ? "red" : "" }}>*</span>
                        </Label>
                        <Col xs={12} sm={inputWidth}>
                          <SelectInput
                            id="select_timezone"
                            name="select_timezone"
                            placeholder=""
                            value={this.state.timezone ? this.state.timezone : this.getTimezone(selectTimezones, selectedRiver)}
                            intl={this.props.intl}
                            onChange={(e) => this.handleTimezoneChange(e)}
                            options={selectTimezones}
                          />
                        </Col>
                      </FormGroup>
                    </Col>

                    <Col xs={12}>
                      <hr />
                      <FormGroup row style={{ marginBottom: 0 }}>
                        <Col xs={12} className="text-right">
                          <Button color="secondary" type="button" onClick={() => this.onSubmitSettings()}>
                            <SaveLabel intl={this.props.intl} />
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </div>
        )}

        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Innstillinger_for_elva__kundeinformasjon_fangstrapportering_kortgrense__jtnzs_c8SdqDsJMEnDjFuw" />

        {isAdmin && <Commisions riverId={this.state.river_id} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  rivers: state.Rivers,
  river_options: state.RiverOptions,
  settings: state.Settings,
  river: state.River,
});

export default withRouter(injectIntl(connect(mapStateToProps)(RiverOptions)));
