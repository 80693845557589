import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, InputGroup, Input } from "reactstrap";
import Spinner from "../../components/spinner";
import { authLogin } from "../../redux/authentication/actions";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  componentDidMount() {
    if (this.nameInput) {
      this.nameInput.focus();
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.dispatch(authLogin(this.state));
  }

  render() {
    return (
      <form className="form-signin" onSubmit={e => this.onSubmit(e)}>
        {this.props.authentication.loading ? (
          <Spinner />
        ) : (
          <div>
            <InputGroup>
              <InputGroup>
                <Input
                  type="email"
                  placeholder={this.props.intl.formatMessage({ id: "email" })}
                  ref={input => {
                    this.nameInput = input;
                  }}
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  autoComplete="username"
                />
              </InputGroup>
              <InputGroup>
                <Input
                  type="password"
                  placeholder={this.props.intl.formatMessage({ id: "password" })}
                  onChange={e => this.setState({ password: e.target.value })}
                  autoComplete="current-password"
                />
              </InputGroup>
            </InputGroup>
            <Button className="eg-green" color="primary" type="submit" size="lg" block>
              {this.props.intl.formatMessage({ id: "login" })}
            </Button>
            <NavLink className="password-reset" to="/password/reset/email">
              <span>{this.props.intl.formatMessage({ id: "password_forgot" })}</span>
            </NavLink>
          </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth
});

export default withRouter(injectIntl(connect(mapStateToProps)(Login)));
