import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Table, Row, Col, Form, FormGroup, Label } from "reactstrap";

import { fetch as fetchStationAdmins } from "../../redux/stationadmins/actions";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import Spinner from "../../components/spinner";
import riverOptions from "../../services/select_river_options";
import SelectInput from "../../components/select";
import Scribe from "../../components/Scribe";

class StationUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      river_id: this.props.authentication.data.river[0]
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    this.handleDataChange();
  }

  onRowClick(id) {
    this.props.history.push(`/sadmin/${id}`);
  }

  handleDataChange() {
    this.props.dispatch(
      fetchStationAdmins({
        river_id: this.state.river_id
      })
    );
  }

  handleRiverChange(e) {
    this.setState(
      {
        river_id: e
      },
      () => {
        this.handleDataChange();
      }
    );
  }

  render() {
    if (!this.props.stationadmins.data || this.props.stationadmins.loading || !this.props.rivers.data) {
      return <Spinner />;
    }

    const { data } = this.props.stationadmins;
    const selectRiver = riverOptions(this.props.rivers, this.props.authentication);

    return (
      <div>
        {this.props.rivers.data.length > 1 ? (
          <div className="panel" style={{ marginBottom: 20 }}>
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row style={{ marginBottom: 1 }}>
                    <Label for="select_river" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({ id: "select_river" })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <SelectInput
                        id="select_river"
                        name="select_river"
                        intl={this.props.intl}
                        value={selectRiver.filter(i => i.value === this.state.river_id)}
                        onChange={e => this.handleRiverChange(e.value)}
                        options={selectRiver}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>
        ) : null}

        <div className="panel">
          <h2 className="title">{this.props.intl.formatMessage({ id: "station_admins" })}</h2>

          <Row>
            <Col xs={12}>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ID</th>
                    <th>{this.props.intl.formatMessage({ id: "stations" })}</th>
                    <th>{this.props.intl.formatMessage({ id: "first_name" })}</th>
                    <th>{this.props.intl.formatMessage({ id: "last_name" })}</th>
                    <th>{this.props.intl.formatMessage({ id: "phone" })}</th>
                    <th>{this.props.intl.formatMessage({ id: "email" })}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((i, key) => (
                      <tr key={i.id} onClick={() => this.onRowClick(i.id)}>
                        <th scope="row">{key + 1}</th>
                        <td>{i.id}</td>
                        <td>
                          {i.stations
                            .filter(i => this.props.authentication.data.river.includes(i.river_id))
                            .map(i => `${i.name} (ID: ${i.id})`)
                            .join(", ")}
                        </td>
                        <td>{i.first_name}</td>
                        <td>{i.last_name}</td>
                        <td>{i.phone}</td>
                        <td>{i.email}</td>
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={7}>{this.props.intl.formatMessage({ id: "no_data_found" })}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Oversikt_over_alle_stasjonsadministratorer__rIES7cv_SFWIc-CIVXp6Rg" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth,
  stationadmins: state.Stationadmins,
  rivers: state.Rivers
});

export default withRouter(injectIntl(connect(mapStateToProps)(StationUsers)));
