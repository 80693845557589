import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  sellingBeatsBeingRemoved: [],
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_BEAT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_BEAT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_BEAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.UPDATE_BEAT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.UPDATE_BEAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UPDATE_BEAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.ADD_SELLING_BEAT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_SELLING_BEAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.ADD_SELLING_BEAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.REMOVE_SELLING_BEAT:
      return {
        ...state,
        loading: true,
        sellingBeatsBeingRemoved: [...state.sellingBeatsBeingRemoved, action.payload.data.selling_beat_id],
      };

    case ACTION.REMOVE_SELLING_BEAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        sellingBeatsBeingRemoved: state.sellingBeatsBeingRemoved.filter(id => id !== action.payload.data.selling_beat_id),
      };

    case ACTION.REMOVE_SELLING_BEAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        sellingBeatsBeingRemoved: state.sellingBeatsBeingRemoved.filter(id => id !== action.payload.data.selling_beat_id),
      };

    default:
      return state;
  }
}
