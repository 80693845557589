import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Select, Checkbox, Typography, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { fetch as fetchEquipment } from "../../redux/equipment_admin/actions";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import { fetch as fetchRiverEquipment, update as updateRiverEquipment } from "../../redux/river_equipment/actions";
import Spinner from "../../components/spinner";
import riverOptions from "../../services/select_river_options";
import ANT_CONFIG from "../../constants/antconfig";
import Scribe from "../../components/Scribe";

const { Text } = Typography;

class EquipmentConfiguration extends React.Component {
  state = {
    equipment_ids: [],
    river_id: this.props.rivers.selected || this.props.authentication.data.river[0],
  }

  componentDidMount() {
    const { equipment, rivers, river_equipment, dispatch } = this.props;

    if (!equipment.data) dispatch(fetchEquipment())
    if (!rivers.data) dispatch(fetchRivers())
    if (!river_equipment.data) dispatch(fetchRiverEquipment(this.state.river_id))
  }

  componentDidUpdate(prevProps) {
    const { river_equipment } = this.props;

    if (prevProps.river_equipment.data !== river_equipment.data) {
      this.setState({
        equipment_ids: river_equipment?.data?.map(equip => equip.id) || [],
      })
    }
  }

  onSave = () => {
    const { dispatch } = this.props
    const { river_id, equipment_ids } = this.state


    dispatch(
      updateRiverEquipment(
        {
          river_id,
          equipment_ids,
        },
      )
    );
  };

  onCheckboxClick = (event) => {
    const { equipment_ids } = this.state
    const { value } = event.target

    
    if (equipment_ids.filter((i) => i === value).length > 0) {
      this.setState((prevState) => ({
        equipment_ids: [...prevState.equipment_ids?.filter((i) => i !== value)],
      }))
      
      return
    }
    
    this.setState((prevState) => ({
      equipment_ids: [...prevState.equipment_ids, value],
    }))
  };

  handleRiverChange(river_id) {
    const { dispatch } = this.props;

    this.setState({
      river_id,
    });

    dispatch(selectRiver(river_id))
    dispatch(fetchRiverEquipment(river_id))
  }

  render() {
    const { equipment, rivers, authentication, intl } = this.props
    const { river_id, equipment_ids } = this.state

    if (!equipment.data) {
      return <Spinner />;
    }

    const selectRivers = riverOptions(rivers, authentication);

    return (
      <>
        <PageHeader
          title={intl.formatMessage({ id: "equipment_configuration" })}
          extra={[
            <Button key="save" type="primary" onClick={this.onSave} loading={equipment.loading} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
          ]}
        >
          {selectRivers?.length > 1 && (
            <Row style={{ marginTop: 15 }}>
              <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                <label className="ant-label" htmlFor="select_river">
                  {intl.formatMessage({ id: "select_river" })}
                </label>
                <Select
                  showSearch
                  id="select_river"
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${intl.formatMessage({ id: "select_river" })}`}
                  optionFilterProp="children"
                  onChange={(e) => this.handleRiverChange(e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={river_id}
                >
                  {selectRivers.map((i, key) => (
                    <Select.Option key={key} value={i.value}>
                      {i.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
        </PageHeader>

        <Row>
          <Col xs={24}>
            <Card style={{ marginTop: 20 }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} spinning={equipment.loading}>
                <div className="container-600">
                  <Row>
                    <Col xs={24}>
                      <Text strong>{intl.formatMessage({ id: "additional_required_fields_text" })}</Text>
                    </Col>
                    <Col xs={24}>
                      <Row gutter={[16, 24]} style={{ margin: "10px 0 0" }}>
                        {equipment.data
                          .filter(e => Boolean(e.active))
                          .map((equip) => {
                            return (
                              <Col xs={24} sm={12} md={8} key={equip.id} style={{ paddingTop: 10 }}>
                                <Checkbox size={ANT_CONFIG.size} value={equip.id} onChange={this.onCheckboxClick} checked={equipment_ids.includes(equip.id)}>
                                  {intl.formatMessage({ id: `equipment-${equip.slug}` })}
                                </Checkbox>
                              </Col>
                          )})
                        }
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Spin>
            </Card>
          </Col>
        </Row>
        <Scribe intl={intl} srcLink="https://scribehow.com/embed/Innstillinger_for_elva__kundeinformasjon_fangstrapportering_kortgrense__jtnzs_c8SdqDsJMEnDjFuw" />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  equipment: state.EquipmentAdmin,
  authentication: state.Auth,
  rivers: state.Rivers,
  river_equipment: state.RiverEquipment,
});

export default withRouter(injectIntl(connect(mapStateToProps)(EquipmentConfiguration)));
