import * as ACTION from './actions';

const INIT_STATE = {
  loading: false,
  error: false,
  main: {
    loading: false,
    error: false,
    data: undefined,
  },
  beat: {
    loading: false,
    error: false,
    data: undefined,
  }
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_BEAT_COMMISSIONS:
      return {
        ...state,
        beat: {
          ...state.beat,
          loading: true,
        },
      };

    case ACTION.FETCH_BEAT_COMMISSIONS_SUCCESS:
      return {
        ...state,
        beat: {
          ...state.beat,
          data: action.payload.data,
          loading: false,
          error: false,
        },
      };

    case ACTION.FETCH_BEAT_COMMISSIONS_FAILURE:
      return {
        ...state,
        beat: {
          ...state.beat,
          loading: false,
          error: false,
        },
      };
    
    case ACTION.FETCH_RIVER_COMMISSIONS:
      return {
        ...state,
        river: {
          ...state.river,
          loading: true,
        },
      };

    case ACTION.FETCH_RIVER_COMMISSIONS_SUCCESS:
      return {
        ...state,
        river: {
          ...state.river,
          data: action.payload.data,
          loading: false,
          error: false,
        },
      };

    case ACTION.FETCH_RIVER_COMMISSIONS_FAILURE:
      return {
        ...state,
        river: {
          ...state.river,
          loading: false,
          error: false,
        },
      };

    case ACTION.ADD_BEAT_COMMISSIONS:
      return {
        ...state,
        beat: {
          ...state.beat,
          loading: true,
        },
      };

    case ACTION.ADD_BEAT_COMMISSIONS_SUCCESS:
      return {
        ...state,
        beat: {
          ...state.beat,
          loading: false,
          error: false,
        },
      };

    case ACTION.ADD_BEAT_COMMISSIONS_FAILURE:
      return {
        ...state,
        beat: {
          ...state.beat,
          loading: false,
          error: false,
        },
      };
    
    case ACTION.ADD_RIVER_COMMISSIONS:
      return {
        ...state,
        river: {
          ...state.river,
          loading: true,
        },
      };

    case ACTION.ADD_RIVER_COMMISSIONS_SUCCESS:
      return {
        ...state,
        river: {
          ...state.river,
          loading: false,
          error: false,
        },
      };

    case ACTION.ADD_RIVER_COMMISSIONS_FAILURE:
      return {
        ...state,
        river: {
          ...state.river,
          loading: false,
          error: false,
        },
      };

    default:
      return state;
  }
}
