import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Dropdown, Menu, Table } from "antd";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

import { fetch as fetchBillings } from "../../redux/billings/actions";
import ANT_CONFIG from "../../constants/antconfig";
import { DOWNLOAD_FILE_TYPES } from "../../constants";
import { downloadBeatReport } from "../../redux/reports/actions";
import downloadFile from "../../services/download_file";
import BeatSelect from "../../components/BeatSelect";
import { pdf } from "@react-pdf/renderer";
import EconomyPDF from "../../components/BeatEconomyReportPDFDownload";
import { saveAs } from "file-saver";

const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Billings extends React.Component {
  state = {
    beatId:
      Array.isArray(this.props.beats.selected) || !this.props.beats.selected
        ? this.props.authentication.data.beat[0]
        : this.props.beats.selected,
    fileType: null,
  };

  componentDidMount() {
    const { beatId } = this.state;

    this.fetchData(beatId);
  }

  componentDidUpdate(prevProps) {
    const {
      reports: { file },
    } = this.props;
    const { fileType } = this.state;

    if (prevProps.reports.file !== file && file) {
      downloadFile(file, fileType);
    }
  }

  handleDownload = async (report, event, intl) => {
    const fileType = event.key;

    if (report.version === 2 && fileType === "pdf") {
      window.event.preventDefault();
      const summaryData = [];
      Object.keys(report.report.summary).forEach((key, index) => {
        let compData = {};
        compData["title"] = key;
        compData["amount"] = report.report.summary[key];
        summaryData.push(compData);
      });

      const blob = await pdf(
        <EconomyPDF
          startDate={report.from}
          endDate={report.to}
          onlinePaymentData={report.report.online_payments}
          manualPaymentData={report.report.manual_payments}
          organizationData={report.report.organization}
          summaryData={summaryData}
          intl={intl}
        />,
      ).toBlob();
      saveAs(blob, `${report.id}.pdf`);
    } else {
      const { dispatch } = this.props;
      const reportType = event.item.props.type;
      const data = {
        filters: {
          date_end: report.to,
          date_start: report.from,
          filetype: fileType,
          created_at: report.created_at,
        },
        ...report.report,
      };
      this.setState(
        {
          fileType,
        },
        () => {
          dispatch(downloadBeatReport(data, reportType));
        },
      );
    }
  };

  handleBeatChange(beatId) {
    this.setState({
      beatId,
    });

    this.fetchData(beatId);
  }

  fetchData(beatId) {
    const { dispatch } = this.props;

    dispatch(
      fetchBillings({
        beat_id: beatId,
      }),
    );
  }

  render() {
    const { intl, billings, reports } = this.props;
    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({ id: "from" }),
        dataIndex: "from",
        key: "from",
      },
      {
        title: intl.formatMessage({ id: "to" }),
        dataIndex: "to",
        key: "to",
      },
      {
        title: intl.formatMessage({ id: "report_created_at" }),
        dataIndex: "report_created_at",
        key: "report_created_at",
      },
      {
        title: "",
        dataIndex: "download",
        key: "download",
      },
    ];
    const tableData = billings.data?.map((i, key) => {
      return {
        key: key + 1,
        id: i.id,
        from: moment(i.from).format("DD.MM.YYYY"),
        to: moment(i.to).format("DD.MM.YYYY"),
        report_created_at: moment(i.created_at).format("DD.MM.YYYY HH:mm:ss"),
        download: (
          <Dropdown
            overlay={
              <Menu onClick={(e) => this.handleDownload(i, e, intl)}>
                {i.version === 2 ? (
                  <Menu.Item key={"pdf"} type={"pdf"}>
                    PDF
                  </Menu.Item>
                ) : (
                  DOWNLOAD_FILE_TYPES.map(({ label, value, type = null }) => (
                    <Menu.Item key={value} type={type}>
                      {label}
                    </Menu.Item>
                  ))
                )}
              </Menu>
            }
          >
            <Button loading={reports.file_loading} type="danger" icon={<DownloadOutlined />} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "download" })}
            </Button>
          </Dropdown>
        ),
      };
    });

    return (
      <>
        <PageHeader title={intl.formatMessage({ id: "my_billings" })}>
          <Row style={{ marginTop: 15 }}>
            <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
              <BeatSelect handleBeatChange={(e) => this.handleBeatChange(e)} />
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                loading={{ spinning: billings.loading, indicator: SPIN_ICON }}
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  beats: state.Beats,
  billings: state.Billings,
  reports: state.Reports,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Billings)));
