export const SEND_RIVER_CARD_SURVEY = "SEND_RIVER_CARD_SURVEY";
export const SEND_RIVER_CARD_SURVEY_SUCCESS = "SEND_RIVER_CARD_SURVEY_SUCCESS";
export const SEND_RIVER_CARD_SURVEY_FAILURE = "SEND_RIVER_CARD_SURVEY_FAILURE";

export const FETCH_RIVER_CARD_SURVEY_SUMMARY = "FETCH_RIVER_CARD_SURVEY_SUMMARY";
export const FETCH_RIVER_CARD_SURVEY_SUMMARY_SUCCESS = "FETCH_RIVER_CARD_SURVEY_SUMMARY_SUCCESS";
export const FETCH_RIVER_CARD_SURVEY_SUMMARY_FAILURE = "FETCH_RIVER_CARD_SURVEY_SUMMARY_FAILURE";

export const RESET_RIVER_CARD_SURVEY_SUMMARY = "RESET_RIVER_CARD_SURVEY_SUMMARY";

export function send(data) {
  return {
    type: SEND_RIVER_CARD_SURVEY,
    data
  };
}

export function fetchSummary(data) {
  return {
    type: FETCH_RIVER_CARD_SURVEY_SUMMARY,
    data
  };
}

export function resetSurveySummary() {
  return {
    type: RESET_RIVER_CARD_SURVEY_SUMMARY
  };
}
