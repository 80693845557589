import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Button, Card, Col, Row, PageHeader, DatePicker, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import ANT_CONFIG from "../../constants/antconfig";
import { fetch, store } from "../../redux/catch_limits/actions";

class CatchLimits extends React.Component {
  state = {
    date_start: null,
    date_end: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetch());
  }

  componentDidUpdate(prevProps) {
    const { catchLimits } = this.props;

    if (prevProps.catchLimits.data !== catchLimits.data && catchLimits.data) {
      this.handleUpdate(catchLimits.data);
    }
  }

  onSave() {
    const { dispatch } = this.props;

    dispatch(store(this.state));
  }

  handleUpdate(data) {
    const { date_start, date_end } = data;

    this.setState({
      date_start,
      date_end,
    });
  }

  handleStateUpdate(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const { intl, catchLimits } = this.props;
    const { date_start, date_end } = this.state;

    return (
      <React.Fragment>
        <PageHeader title={intl.formatMessage({ id: "catch_limits" })} />

        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} spinning={catchLimits.loading}>
          <Row>
            <Col xs={24}>
              <Card style={{ marginTop: 20 }}>
                <div className="container-600">
                  <Row type="flex" gutter={[20, 20]}>
                    <Col xs={24} md={12} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="date_start">
                        {intl.formatMessage({ id: "date_start" })}
                      </label>
                      <DatePicker
                        allowClear={false}
                        id="date_start"
                        size={ANT_CONFIG.size}
                        format={ANT_CONFIG.datepicker_time_format}
                        value={date_start ? moment(date_start) : null}
                        showTime
                        onChange={(e) => this.handleStateUpdate("date_start", e ? e.format(ANT_CONFIG.datetime_format) : null)}
                      />
                    </Col>
                    <Col xs={24} md={12} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="date_end">
                        {intl.formatMessage({ id: "date_end" })}
                      </label>
                      <DatePicker
                        allowClear={false}
                        id="date_end"
                        size={ANT_CONFIG.size}
                        format={ANT_CONFIG.datepicker_time_format}
                        value={date_end ? moment(date_end) : null}
                        showTime
                        onChange={(e) => this.handleStateUpdate("date_end", e ? e.format(ANT_CONFIG.datetime_format) : null)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} className="text-right">
                      <Button type="primary" style={{ marginLeft: 8 }} onClick={(e) => this.onSave(e)} disabled={catchLimits.loading} size={ANT_CONFIG.size}>
                        {intl.formatMessage({ id: "save" })}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Spin>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  catchLimits: state.CatchLimits,
});

export default withRouter(injectIntl(connect(mapStateToProps)(CatchLimits)));
