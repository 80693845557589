import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import RolesContext from "../../context/RolesContext";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import {
  fetch as fetchBlueprints,
  store as storeBlueprint,
  update as updateBlueprint,
  remove as removeBlueprint,
} from "../../redux/river_card_blueprints/actions";
import { fetchCards, fetchBuyRiverCardButtonText, editBuyRiverCardButton } from "../../redux/river_cards/actions";
import {
  send as sendSurvey,
  fetchSummary as fetchSurveySummary,
  resetSurveySummary,
} from "../../redux/river_card_surveys/actions";
import Spinner from "../../components/spinner";
import { Col, Row, PageHeader, Select, Button, Modal, Input, Card } from "antd";
import riverOptions from "../../services/select_river_options";
import ANT_CONFIG from "../../constants/antconfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faAngleRight,
  faUser,
  faAt,
  faPhone,
  faHashtag,
  faTag,
  faClock,
  faFlag,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import HTMLEditor from "../../components/HTMLEditor/HTMLEditor";
import Scribe from "../../components/Scribe";

class RiverCards extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);
    this.state = {
      river_id: this.props.authentication.data.river[0],
      showModal: null,
      addNewData: {
        name: "",
        description: "",
        survey_message: "",
        year: moment().format("YYYY"),
        price: "",
        tax_option_id: null,
      },
      submittedAddNew: false,
      submittedEdit: false,
      submittedRemove: false,
      editData: null,
      removeData: null,
      soldCardsData: null,
      soldCards: [],
      singleCard: null,
      surveyBlueprint: null,
      confirmSurvey: false,
      submittedSendSurvey: false,
      confirmFinalSurvey: false,
      submittedSendFinalSurvey: false,
      buyRiverCardButtonText: this.props.river_cards?.button_text,
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
    this.props.dispatch(fetchBlueprints(this.state.river_id));
    this.props.dispatch(fetchBuyRiverCardButtonText(this.state.river_id));
  }

  componentDidUpdate() {
    if (!this.props.river_card_blueprints.loading && !this.props.river_card_blueprints.error && this.state.submittedAddNew) {
      this.setState({
        addNewData: {
          name: "",
          description: "",
          year: moment().format("YYYY"),
          price: "",
          tax_option_id: null,
        },
        showModal: null,
        submittedAddNew: false,
      });
    }
    if (!this.props.river_card_blueprints.loading && !this.props.river_card_blueprints.error && this.state.submittedEdit) {
      this.setState({
        editData: null,
        showModal: null,
        submittedEdit: false,
      });
    }
    if (!this.props.river_card_blueprints.loading && !this.props.river_card_blueprints.error && this.state.submittedRemove) {
      this.setState({
        removeData: null,
        showModal: null,
        submittedRemove: false,
      });
    }
    if (!this.props.river_card_surveys.loading && !this.props.river_card_surveys.error && this.state.submittedSendSurvey) {
      this.setState({
        submittedSendSurvey: false,
        showModal: null,
      });
      this.props.dispatch(fetchBlueprints(this.state.river_id));
    }
    if (!this.props.river_card_surveys.loading && !this.props.river_card_surveys.error && this.state.submittedSendFinalSurvey) {
      this.setState({
        submittedSendFinalSurvey: false,
        showModal: null,
      });
      this.props.dispatch(fetchBlueprints(this.state.river_id));
    }
  }

  handleDataChange() {
    this.props.dispatch(fetchBlueprints(this.state.river_id));
    this.props.dispatch(fetchBuyRiverCardButtonText(this.state.river_id));
    this.setState({
      buyRiverCardButtonText: this.props.river_cards?.button_text,
    });
  }

  handleRiverChange(e) {
    this.setState(
      (prevState) => ({
        ...prevState,
        river_id: e,
        buyRiverCardButtonText: this.props.river_cards?.button_text,
      }),
      () => {
        this.props.dispatch(selectRiver(e));
        this.handleDataChange();
      },
    );
  }

  toggleModal(modalName, obj = null) {
    this.setState({
      showModal: modalName,
    });
    if (modalName === "edit_river_card" && obj !== null) {
      this.setState({
        editData: JSON.parse(JSON.stringify(obj)),
      });
    }
    if (modalName === "remove_river_card" && obj !== null) {
      this.setState({
        removeData: JSON.parse(JSON.stringify(obj)),
      });
    }
    if (modalName === "sold_river_cards" && obj !== null) {
      this.props.dispatch(fetchCards(obj.id));
      this.setState({
        soldCardsData: JSON.parse(JSON.stringify(obj)),
      });
    }
    if (modalName === "survey" && obj !== null) {
      this.setState({
        surveyBlueprint: JSON.parse(JSON.stringify(obj)),
      });
      this.props.dispatch(resetSurveySummary());
      setTimeout(() => {
        if (this.state.surveyBlueprint.survey_sent_on !== null) {
          this.props.dispatch(fetchSurveySummary({ id: this.state.surveyBlueprint.id, final: 0 }));
        }
      }, 1000);
    }
    if (modalName === "final_survey" && obj !== null) {
      this.setState({
        surveyBlueprint: JSON.parse(JSON.stringify(obj)),
      });
      this.props.dispatch(resetSurveySummary());
      setTimeout(() => {
        if (this.state.surveyBlueprint.final_survey_sent_on !== null) {
          this.props.dispatch(fetchSurveySummary({ id: this.state.surveyBlueprint.id, final: 1 }));
        }
      }, 1000);
    }
  }

  hideModal() {
    this.setState({
      showModal: null,
      editData: null,
      removeData: null,
      soldCardsData: null,
      soldCards: [],
      surveyBlueprint: null,
      confirmSurvey: false,
      confirmFinalSurvey: false,
    });
  }

  onChangeNewRiverCard(key, value) {
    const newState = this.state.addNewData;
    newState[key] = value;
    this.setState({
      addNewData: newState,
    });
  }

  onSubmitAddNew() {
    this.setState({ submittedAddNew: true });
    const data = this.state.addNewData;
    data.river_id = this.state.river_id;
    this.props.dispatch(storeBlueprint(data));
  }

  onChangeEditRiverCard(key, value) {
    const newState = this.state.editData;
    newState[key] = value;
    this.setState({
      editData: newState,
    });
  }

  onBuyRiverCardButtonTextChange(text) {
    this.setState((prevState) => ({
      ...prevState,
      buyRiverCardButtonText: text,
    }));
  }

  onSaveBuyRiverCardButtonText() {
    const payload = {
      buy_river_card_button_text: this.state.buyRiverCardButtonText,
      river_id: this.state.river_id,
    };
    this.props.dispatch(editBuyRiverCardButton(payload));
  }

  onSubmitEdit() {
    this.setState({ submittedEdit: true });
    const data = this.state.editData;
    data.river_id = this.state.river_id;
    this.props.dispatch(updateBlueprint(data));
  }

  onSubmitRemove() {
    this.setState({ submittedRemove: true });
    const data = this.state.removeData;
    data.river_id = this.state.river_id;
    this.props.dispatch(removeBlueprint(data));
  }

  showCard(card) {
    this.setState({ singleCard: card });
  }

  sendSurvey(final) {
    if (final) {
      this.setState({ confirmFinalSurvey: true });
    } else {
      this.setState({ confirmSurvey: true });
    }
  }

  confirmSendSurvey(final) {
    if (final) {
      this.setState({ submittedSendFinalSurvey: true });
    } else {
      this.setState({ submittedSendSurvey: true });
    }
    this.props.dispatch(sendSurvey({ id: this.state.surveyBlueprint.id, final }));
  }

  render() {
    if (!this.props.rivers.data) {
      return <Spinner />;
    }
    const { intl, rivers, authentication, river_card_blueprints, river_cards, river_card_surveys } = this.props;
    const selectRiver = riverOptions(rivers, authentication);
    const buyRiverCardButtonText = river_cards?.button_text ?? this.props.intl.formatMessage({ id: "buy_river_card" });
    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "river_cards" })}
          extra={[
            <Button key="0" type="primary" onClick={() => this.toggleModal("add_river_card")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_river_card" })}
            </Button>,
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                {selectRiver.length > 1 ? (
                  <Col xs={24} sm={8} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label className="ant-label">{intl.formatMessage({ id: "select_river" })}</label>
                    <Select
                      showSearch
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({
                        id: "select_river",
                      })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleRiverChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.river_id}
                    >
                      {selectRiver.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </PageHeader>
        <Card className="river-cards" style={{ marginTop: 30 }}>
          <h4>{this.props.intl.formatMessage({ id: "active" })}</h4>
          {river_card_blueprints?.data?.active?.length > 0 ? (
            <>
              <div style={{ marginBottom: "50px" }}>
                <h2>{this.props.intl.formatMessage({ id: "river_card_title" })}</h2>
                <Input
                  type="text"
                  className="form-control"
                  style={{ margin: "10px 0 10px 0" }}
                  id="myInput"
                  defaultValue={buyRiverCardButtonText}
                  key={buyRiverCardButtonText}
                  onChange={(e) => this.onBuyRiverCardButtonTextChange(e.target.value)}
                  placeholder={this.props.intl.formatMessage({
                    id: "buy_river_card",
                  })}
                />
                <Button key="0" type="primary" onClick={() => this.onSaveBuyRiverCardButtonText()} size={ANT_CONFIG.size}>
                  {this.props.intl.formatMessage({ id: "save" })}
                </Button>
              </div>
              <div>
                <h2>{this.props.intl.formatMessage({ id: "river_card_all_cards" })}</h2>
                {river_card_blueprints.data.active.map((card, key) => (
                  <div key={key} className="river-card-blueprint">
                    <h5>{card.name}</h5>
                    <div>
                      <strong>{card.year}</strong>
                    </div>
                    {card.description && <div dangerouslySetInnerHTML={{ __html: card.description }} />}
                    <div>
                      {this.props.intl.formatMessage({ id: "price" })}: {card.price} (
                      {this.props.intl.formatMessage({ id: "tax" })} {card.tax_option.value}%)
                    </div>
                    <div className="rc-actions">
                      <Button type="default" onClick={() => this.toggleModal("sold_river_cards", card)} size={ANT_CONFIG.size}>
                        {this.props.intl.formatMessage({
                          id: "sold_river_cards",
                        })}{" "}
                        ({card.number_of_sold_cards})
                      </Button>
                      <Button type="primary" onClick={() => this.toggleModal("survey", card)} size={ANT_CONFIG.size}>
                        {this.props.intl.formatMessage({ id: "survey_1" })}
                      </Button>
                      <Button type="primary" onClick={() => this.toggleModal("final_survey", card)} size={ANT_CONFIG.size}>
                        {this.props.intl.formatMessage({ id: "final_survey" })}
                      </Button>
                      <Button type="default" onClick={() => this.toggleModal("edit_river_card", card)} size={ANT_CONFIG.size}>
                        {this.props.intl.formatMessage({ id: "edit" })}
                      </Button>
                      <Button type="danger" onClick={() => this.toggleModal("remove_river_card", card)} size={ANT_CONFIG.size}>
                        {this.props.intl.formatMessage({ id: "remove" })}
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div>{this.props.intl.formatMessage({ id: "no_results" })}</div>
          )}
        </Card>
        {river_card_blueprints?.data?.old?.length > 0 ? (
          <Card className="river-cards" style={{ marginTop: 30 }}>
            <div>
              <h4>{this.props.intl.formatMessage({ id: "old" })}</h4>
              {river_card_blueprints.data.old.map((card, key) => (
                <div key={key} className="river-card-blueprint old-rcb">
                  <h5>{card.name}</h5>
                  <div>
                    <strong>{card.year}</strong>
                  </div>
                  <div>{card.description}</div>
                  <div>
                    {this.props.intl.formatMessage({ id: "price" })}: {card.price} (
                    {this.props.intl.formatMessage({ id: "tax" })} {card.tax_option.value}%)
                  </div>
                  <div className="rc-actions">
                    <Button type="default" onClick={() => this.toggleModal("sold_river_cards", card)} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({
                        id: "sold_river_cards",
                      })}{" "}
                      ({card.number_of_sold_cards})
                    </Button>
                    <Button type="primary" onClick={() => this.toggleModal("survey", card)} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({ id: "survey_1" })}
                    </Button>
                    <Button type="primary" onClick={() => this.toggleModal("final_survey", card)} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({ id: "final_survey" })}
                    </Button>
                    <Button type="default" onClick={() => this.toggleModal("edit_river_card", card)} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({ id: "edit" })}
                    </Button>
                    <Button type="danger" onClick={() => this.toggleModal("remove_river_card", card)} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({ id: "remove" })}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        ) : null}
        {this.state.showModal === "add_river_card" ? (
          <Modal
            width={600}
            title={this.props.intl.formatMessage({ id: "add_river_card" })}
            visible={this.state.showModal === "add_river_card"}
            onCancel={() => this.hideModal()}
            footer={[
              <Button
                size={ANT_CONFIG.size}
                key="submit"
                loading={river_card_blueprints.loading}
                type="primary"
                onClick={() => {
                  this.onSubmitAddNew();
                }}
              >
                {this.props.intl.formatMessage({ id: "save" })}
              </Button>,
            ]}
          >
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="select_year">
                {this.props.intl.formatMessage({ id: "select_year" })}
              </label>
              <Select
                id="select_year"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                value={this.state.addNewData.year}
                placeholder={`${this.props.intl.formatMessage({
                  id: "select_year",
                })}`}
                onChange={(e) => this.onChangeNewRiverCard("year", e)}
              >
                {[moment().format("YYYY"), moment().add(1, "year").format("YYYY")].map((i) => (
                  <Select.Option key={i} value={i}>
                    {i}
                  </Select.Option>
                ))}
              </Select>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="name">
                {this.props.intl.formatMessage({ id: "name" })}
              </label>
              <Input
                style={{ marginBottom: 5 }}
                type="text"
                name="name"
                id="name"
                size={ANT_CONFIG.size}
                placeholder={this.props.intl.formatMessage({ id: "name" })}
                onChange={(e) => this.onChangeNewRiverCard("name", e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="description">
                {this.props.intl.formatMessage({ id: "description" })}
              </label>
              <Input.TextArea
                name="description"
                id="description"
                size={ANT_CONFIG.size}
                autoSize={{ minRows: 2 }}
                value={this.state.addNewData.description}
                onChange={(e) => this.onChangeNewRiverCard("description", e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="survey_message">
                {this.props.intl.formatMessage({ id: "survey_message" })}
              </label>
              <Input.TextArea
                name="survey_message"
                id="survey_message"
                size={ANT_CONFIG.size}
                autoSize={{ minRows: 2 }}
                value={this.state.addNewData.survey_message}
                onChange={(e) => this.onChangeNewRiverCard("survey_message", e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="price">
                {this.props.intl.formatMessage({ id: "price" })}
              </label>
              <Input
                style={{ marginBottom: 5 }}
                type="number"
                name="price"
                id="price"
                size={ANT_CONFIG.size}
                placeholder={this.props.intl.formatMessage({ id: "price" })}
                onChange={(e) => this.onChangeNewRiverCard("price", e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              {this.props.taxes.data && (
                <React.Fragment>
                  <label className="ant-label" htmlFor="select_tax">
                    {this.props.intl.formatMessage({ id: "tax" })}
                  </label>
                  <Select
                    id="select_tax"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({
                      id: "select_tax",
                    })}`}
                    onChange={(e) => this.onChangeNewRiverCard("tax_option_id", e)}
                    value={this.state.addNewData.tax_option_id}
                  >
                    {this.props.taxes.data.map((i) => (
                      <Select.Option key={i.id} value={i.id}>
                        {`${i.key} (${i.value}%)`}
                      </Select.Option>
                    ))}
                  </Select>
                </React.Fragment>
              )}
            </Row>
          </Modal>
        ) : null}
        {this.state.showModal === "edit_river_card" ? (
          <Modal
            width={600}
            title={this.props.intl.formatMessage({ id: "edit" })}
            visible={this.state.showModal === "edit_river_card"}
            onCancel={() => this.hideModal()}
            footer={[
              <Button
                size={ANT_CONFIG.size}
                key="submit"
                loading={river_card_blueprints.loading}
                type="primary"
                onClick={() => {
                  this.onSubmitEdit();
                }}
              >
                {this.props.intl.formatMessage({ id: "save" })}
              </Button>,
            ]}
          >
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="name">
                {this.props.intl.formatMessage({ id: "name" })}
              </label>
              <Input
                style={{ marginBottom: 5 }}
                type="text"
                name="name"
                id="name"
                size={ANT_CONFIG.size}
                value={this.state.editData.name}
                placeholder={this.props.intl.formatMessage({ id: "name" })}
                onChange={(e) => this.onChangeEditRiverCard("name", e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="description">
                {this.props.intl.formatMessage({ id: "description" })}
              </label>
              <HTMLEditor
                data={this.state.editData.description || ""}
                onChange={(event, editor) => this.onChangeEditRiverCard("description", editor.getData())}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="survey_message">
                {this.props.intl.formatMessage({ id: "survey_message" })}
              </label>
              <Input.TextArea
                name="survey_message"
                id="survey_message"
                size={ANT_CONFIG.size}
                autoSize={{ minRows: 2 }}
                value={this.state.editData.survey_message}
                onChange={(e) => this.onChangeEditRiverCard("survey_message", e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              <label className="ant-label" htmlFor="price">
                {this.props.intl.formatMessage({ id: "price" })}
              </label>
              <Input
                style={{ marginBottom: 5 }}
                type="number"
                name="price"
                id="price"
                size={ANT_CONFIG.size}
                value={this.state.editData.price}
                placeholder={this.props.intl.formatMessage({ id: "price" })}
                onChange={(e) => this.onChangeEditRiverCard("price", e.target.value)}
              />
            </Row>
            <Row style={{ marginTop: 15 }}>
              {this.props.taxes.data && (
                <React.Fragment>
                  <label className="ant-label" htmlFor="select_tax">
                    {this.props.intl.formatMessage({ id: "tax" })}
                  </label>
                  <Select
                    id="select_tax"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({
                      id: "select_tax",
                    })}`}
                    onChange={(e) => this.onChangeEditRiverCard("tax_option_id", e)}
                    value={this.state.editData.tax_option_id}
                  >
                    {this.props.taxes.data.map((i) => (
                      <Select.Option key={i.id} value={i.id}>
                        {`${i.key} (${i.value}%)`}
                      </Select.Option>
                    ))}
                  </Select>
                </React.Fragment>
              )}
            </Row>
          </Modal>
        ) : null}
        {this.state.showModal === "remove_river_card" ? (
          <Modal
            width={600}
            title={this.props.intl.formatMessage({ id: "remove" })}
            visible={this.state.showModal === "remove_river_card"}
            onCancel={() => this.hideModal()}
            footer={[
              <Button
                size={ANT_CONFIG.size}
                key="submit"
                loading={river_card_blueprints.loading}
                type="danger"
                onClick={() => {
                  this.onSubmitRemove();
                }}
              >
                {this.props.intl.formatMessage({ id: "remove" })}: {this.state.removeData.name} ({this.state.removeData.year})
              </Button>,
            ]}
          >
            <Row style={{ marginTop: 15 }}>{this.props.intl.formatMessage({ id: "are_you_sure" })}</Row>
          </Modal>
        ) : null}
        {this.state.showModal === "sold_river_cards" ? (
          <Modal
            width={600}
            title={this.props.intl.formatMessage({ id: "sold_river_cards" })}
            visible={this.state.showModal === "sold_river_cards"}
            onCancel={() => this.hideModal()}
            footer={[]}
          >
            {river_cards.loading ? (
              <Spinner />
            ) : (
              <div>
                {river_cards.data.map((card) => (
                  <div className="river-card" key={card.id} onClick={() => this.showCard(card)}>
                    <div className="rc-left">
                      <FontAwesomeIcon
                        icon={faAddressCard}
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </div>
                    <div className="rc-middle">
                      {card.owner_name} / {card.owner_email} / {card.owner_phone}
                    </div>
                    <div className="rc-right">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Modal>
        ) : null}
        {this.state.singleCard !== null ? (
          <Modal
            width={600}
            title={this.props.intl.formatMessage({ id: "river_card" })}
            visible={this.state.singleCard !== null}
            onCancel={() => this.showCard(null)}
            footer={[]}
          >
            <div className="full-river-card">
              <div className="rc-header">
                <strong>{this.state.soldCardsData.name}</strong> / {this.state.soldCardsData.river.name} /{" "}
                {this.state.soldCardsData.year}
              </div>
              <div className="rc-body">
                <div>
                  <FontAwesomeIcon
                    icon={faHashtag}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {this.state.singleCard.id}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {this.state.singleCard.owner_name}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faAt}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {this.state.singleCard.owner_email}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {this.state.singleCard.owner_phone}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faFlag}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {this.state.singleCard.owner_country}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faMapMarker}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {this.state.singleCard.owner_postal_code}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faTag}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {this.state.singleCard.sold_for_price.amount} NOK
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{
                      fontSize: 16,
                      marginRight: 20,
                    }}
                  />
                  {moment(this.state.singleCard.updated_at).format("DD.MM.YYYY HH:mm")}
                </div>
              </div>
              <div className="rc-survey">
                <h2>{this.props.intl.formatMessage({ id: "survey_1" })}</h2>
                {this.state.singleCard.survey_sent_on === null ? (
                  <div>{this.props.intl.formatMessage({ id: "survey_not_sent" })}</div>
                ) : null}
                {this.state.singleCard.survey_sent_on !== null && this.state.singleCard.survey_responded_on === null ? (
                  <div>
                    {this.props.intl.formatMessage({
                      id: "survey_not_responded",
                    })}
                    <br />
                  </div>
                ) : null}
                {this.state.singleCard.survey_sent_on !== null && this.state.singleCard.survey_responded_on !== null ? (
                  <div>
                    Har du rapportert all fangst denne sesongen? -{" "}
                    <strong>{this.state.singleCard.river_card_survey_result.question_1_response === 1 ? "Ja" : "Nei"}</strong>
                    <br />
                    Hvor mange fiskedøgn har du i år?
                    <br />
                    Døgnkort: {this.state.singleCard.river_card_survey_result.question_2_response}
                    <br />
                    Ukeskort: {this.state.singleCard.river_card_survey_result.question_3_response}
                    <br />
                    Sesongkort: {this.state.singleCard.river_card_survey_result.question_4_response}
                  </div>
                ) : null}
              </div>
              <div className="rc-survey">
                <h2>{this.props.intl.formatMessage({ id: "final_survey" })}</h2>
                {this.state.singleCard.final_survey_sent_on === null ? (
                  <div>{this.props.intl.formatMessage({ id: "survey_not_sent" })}</div>
                ) : null}
                {this.state.singleCard.final_survey_sent_on !== null &&
                this.state.singleCard.final_survey_responded_on === null ? (
                  <div>
                    {this.props.intl.formatMessage({
                      id: "survey_not_responded",
                    })}
                    <br />
                  </div>
                ) : null}
                {this.state.singleCard.final_survey_sent_on !== null &&
                this.state.singleCard.final_survey_responded_on !== null ? (
                  <div>
                    Har du rapportert all fangst denne sesongen? -{" "}
                    <strong>
                      {this.state.singleCard.river_card_final_survey_result.question_1_response === 1 ? "Ja" : "Nei"}
                    </strong>
                    <br />
                    Hvor mange fiskedøgn har du i år?
                    <br />
                    Døgnkort: {this.state.singleCard.river_card_final_survey_result.question_2_response}
                    <br />
                    Ukeskort: {this.state.singleCard.river_card_final_survey_result.question_3_response}
                    <br />
                    Sesongkort: {this.state.singleCard.river_card_final_survey_result.question_4_response}
                  </div>
                ) : null}
              </div>
            </div>
          </Modal>
        ) : null}
        {this.state.showModal === "survey" ? (
          <Modal
            width={600}
            title={this.props.intl.formatMessage({ id: "survey_1" })}
            visible={this.state.showModal === "survey"}
            onCancel={() => this.hideModal()}
            footer={[]}
          >
            {this.state.surveyBlueprint.survey_sent_on === null ? (
              <div>
                {!this.state.confirmSurvey ? (
                  <div>
                    <Button
                      size={ANT_CONFIG.size}
                      type="primary"
                      onClick={() => {
                        this.sendSurvey(0);
                      }}
                    >
                      {this.props.intl.formatMessage({ id: "send_survey_1" })}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <p>{this.props.intl.formatMessage({ id: "are_you_sure" })}</p>
                    <Button
                      size={ANT_CONFIG.size}
                      loading={river_card_surveys.loading}
                      type="danger"
                      onClick={() => {
                        this.confirmSendSurvey(0);
                      }}
                    >
                      {this.props.intl.formatMessage({ id: "send" })}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  {this.props.intl.formatMessage({ id: "sent_on" })}:{" "}
                  {moment(this.state.surveyBlueprint.survey_sent_on).format("DD.MM.YYYY HH:mm")}
                </div>
                {river_card_surveys.loading ? (
                  <Spinner />
                ) : (
                  <div>
                    {river_card_surveys.summaryData ? (
                      <div>
                        <div>
                          <strong>
                            {this.props.intl.formatMessage({
                              id: "number_of_sent_survey_invites",
                            })}
                          </strong>
                          : {river_card_surveys.summaryData.sent_surveys}
                        </div>
                        <div>
                          <strong>
                            {this.props.intl.formatMessage({
                              id: "responded_survey_invites",
                            })}
                          </strong>
                          : {river_card_surveys.summaryData.responded_surveys}
                        </div>
                        <div>
                          <i>Har du rapportert all fangst denne sesongen?</i>
                          <br />
                          <strong>Ja</strong>: {river_card_surveys.summaryData.question_1_responses[1]}
                          <br />
                          <strong>Nei</strong>: {river_card_surveys.summaryData.question_1_responses[0] || 0}
                        </div>
                        <div>
                          <i>Hvor mange fiskedøgn har du i år?</i>
                          <br />
                          <strong>Døgnkort</strong>: {river_card_surveys.summaryData.total_question_2}
                          <br />
                          <strong>Ukeskort</strong>: {river_card_surveys.summaryData.total_question_3}
                          <br />
                          <strong>Sesongkort</strong>: {river_card_surveys.summaryData.total_question_4}
                          <br />
                          <strong>Totalt</strong>: {river_card_surveys.summaryData.total_questions_2_3_4}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </Modal>
        ) : null}
        {this.state.showModal === "final_survey" ? (
          <Modal
            width={600}
            title={this.props.intl.formatMessage({ id: "final_survey" })}
            visible={this.state.showModal === "final_survey"}
            onCancel={() => this.hideModal()}
            footer={[]}
          >
            {this.state.surveyBlueprint.final_survey_sent_on === null ? (
              <div>
                {!this.state.confirmFinalSurvey ? (
                  <div>
                    <Button
                      size={ANT_CONFIG.size}
                      type="primary"
                      onClick={() => {
                        this.sendSurvey(1);
                      }}
                    >
                      {this.props.intl.formatMessage({
                        id: "send_final_survey",
                      })}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <p>{this.props.intl.formatMessage({ id: "survey_text" })}</p>
                    <Button
                      size={ANT_CONFIG.size}
                      loading={river_card_surveys.loading}
                      type="danger"
                      onClick={() => {
                        this.confirmSendSurvey(1);
                      }}
                    >
                      {this.props.intl.formatMessage({ id: "send" })}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>
                  {this.props.intl.formatMessage({ id: "sent_on" })}:{" "}
                  {moment(this.state.surveyBlueprint.final_survey_sent_on).format("DD.MM.YYYY HH:mm")}
                </div>
                {river_card_surveys.loading ? (
                  <Spinner />
                ) : (
                  <div>
                    {river_card_surveys.summaryData ? (
                      <div>
                        <div>
                          <strong>
                            {this.props.intl.formatMessage({
                              id: "number_of_sent_survey_invites",
                            })}
                          </strong>
                          : {river_card_surveys.summaryData.sent_surveys}
                        </div>
                        <div>
                          <strong>
                            {this.props.intl.formatMessage({
                              id: "responded_survey_invites",
                            })}
                          </strong>
                          : {river_card_surveys.summaryData.responded_surveys}
                        </div>
                        <div>
                          <i>Har du rapportert all fangst denne sesongen?</i>
                          <br />
                          <strong>Ja</strong>: {river_card_surveys.summaryData.question_1_responses[1]}
                          <br />
                          <strong>Nei</strong>: {river_card_surveys.summaryData.question_1_responses[0] || 0}
                        </div>
                        <div>
                          <i>Hvor mange fiskedøgn har du i år?</i>
                          <br />
                          <strong>Døgnkort</strong>: {river_card_surveys.summaryData.total_question_2}
                          <br />
                          <strong>Ukeskort</strong>: {river_card_surveys.summaryData.total_question_3}
                          <br />
                          <strong>Sesongkort</strong>: {river_card_surveys.summaryData.total_question_4}
                          <br />
                          <strong>Totalt</strong>: {river_card_surveys.summaryData.total_questions_2_3_4}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          </Modal>
        ) : null}
        <Scribe
          intl={this.props.intl}
          hasMultiple={true}
          items={[
            {
              title: this.props.intl.formatMessage({
                id: "scribe_publish_river_cards",
              }),
              link: "https://scribehow.com/embed/Lage_og_legge_ut_et_nytt_elvekort__k5qLJTKWRdCTIrCgO7n53A",
            },
            {
              title: this.props.intl.formatMessage({
                id: "scribe_edit_river_cards",
              }),
              link: "https://scribehow.com/embed/Endre_tekst_pa_elvekort_knappen__VuFMUkLOTci1asaAnolFJg",
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  rivers: state.Rivers,
  river_card_blueprints: state.RiverCardBlueprints,
  river_cards: state.RiverCards,
  taxes: state.Taxes,
  river_card_surveys: state.RiverCardSurveys,
});

export default withRouter(injectIntl(connect(mapStateToProps)(RiverCards)));
