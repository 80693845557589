import React from "react";
import { connect } from "react-redux";
import { Select, Spin } from "antd";
import { injectIntl } from "react-intl";
import { LoadingOutlined } from "@ant-design/icons";

import ANT_CONFIG from "../../constants/antconfig";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";

const { Option } = Select;

class BeatSelect extends React.Component {
  state = {
    beatId: this.props.selected || this.props.beats.selected || this.props.authentication.data.beat[0],
  };

  componentDidUpdate(prevProps) {
    if (this.props.selected === prevProps.selected) return

    this.setState({
      beatId: this.props.selected
    })
  }

  componentDidMount() {
    const { beats, dispatch } = this.props;

    if (!beats.data) {
      dispatch(fetchBeats());
    }
  }

  onChange = (beatId) => {
    const { dispatch, handleBeatChange, onChange } = this.props;

    this.setState({
      beatId,
    });

    if (onChange) {
      onChange(beatId, this.props.beats?.data?.filter((beat) => beatId.includes(beat.id)));
      return
    }

    dispatch(selectBeat(beatId));

    if (handleBeatChange) {
      handleBeatChange(beatId);
    }
  };

  render() {
    const { beats,
      authentication,
      filterData = Boolean,
      intl,
      mode,
      label
    } = this.props;

    const { beatId } = this.state;

    const selectBeats = beats?.data
      ? beats.data
          .filter((beat) => authentication.data.beat.includes(beat.id))
          .filter(filterData)
          .map((beat) => {
            return {
              value: beat.id,
              label: `${beat.name ? beat.name : 'EMPTY BEAT NAME'}  (${beat.river?.name ? beat.river.name : 'EMPTY RIVER NAME'})`,
            };
          })
      : null;

    return selectBeats ? (
      <>
        <label className="ant-label" htmlFor="select_beat">
          {intl.formatMessage({ id: label || "select_beat" })}
        </label>
        <Select
          showSearch
          id="select_beat"
          size={ANT_CONFIG.size}
          style={{ width: "100%" }}
          placeholder={`${intl.formatMessage({ id: "select_beat" })}`}
          optionFilterProp="children"
          onChange={this.onChange}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          value={beatId}
          mode={mode}
        >
          {selectBeats.map((i, key) => (
            <Option key={key} value={i.value}>
              {i.label}
            </Option>
          ))}
        </Select>
      </>
    ) : (
      <Spin indicator={<LoadingOutlined spin />} />
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  beats: state.Beats,
});

export default injectIntl(connect(mapStateToProps)(BeatSelect));
