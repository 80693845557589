import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetchAll(action) {
  try {
    const response = yield call(API.call, "admin/catches/catches", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_CATCHES_FOR_RIVER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_CATCHES_FOR_RIVER_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_CATCHES_FOR_RIVER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/catches/catch/${action.id}`, "GET", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_CATCH_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_CATCH_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_CATCH_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* report(action) {
  try {
    const response = yield call(API.call, `report-catch/${action.id}`, "POST");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REPORT_CATCH_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.REPORT_CATCH_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.REPORT_CATCH_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, `admin/catches/catch/${action.id}`, "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_CATCH_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.EDIT_CATCH_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_CATCH_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* add(action) {
  try {
    const response = yield call(API.call, `admin/catches/catch`, "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_CATCH_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.ADD_CATCH_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_CATCH_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_CATCHES_FOR_RIVER, fetchAll),
    takeEvery(ACTION.FETCH_CATCH, fetch),
    takeEvery(ACTION.REPORT_CATCH, report),
    takeEvery(ACTION.EDIT_CATCH, edit),
    takeEvery(ACTION.ADD_CATCH, add),
  ]);
}
