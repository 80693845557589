import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Modal, Radio, Input, Row, Col } from "antd";
import { injectIntl } from "react-intl";

import ANT_CONFIG from "../../../constants/antconfig";
import { fetchCustomerOrders } from "../../../redux/orders/actions";
import { add as addControllerComment } from "../../../redux/controller_comments/actions";

const INITIAL_STATE = {
  status: 0,
  comment: "",
};

class AddComment extends React.Component {
  state = INITIAL_STATE;

  componentDidUpdate(prevProps) {
    const { match, dispatch, controllerComments } = this.props;
    const userId = match.params.id;

    if (prevProps.controllerComments.data !== controllerComments.data && controllerComments.data) {
      dispatch(fetchCustomerOrders(userId));
      this.onCancel();
    }
  }

  onSave = () => {
    const { status, comment } = this.state;
    const { data, match, dispatch } = this.props;
    const { id, type, beat } = data;
    const userId = match.params.id;

    const payload = {
      type,
      id,
      river_id: beat?.river_id,
      user_id: Number(userId),
      status,
    };

    if (comment && !status) {
      payload.comment = comment;
    }

    dispatch(addControllerComment(payload));
  };

  onCancel = () => {
    const { closeModal } = this.props;

    this.setState({
      ...INITIAL_STATE,
    });
    closeModal();
  };

  setData(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const { status, comment } = this.state;
    const { intl, data, controllerComments } = this.props;

    return (
      <Modal
        width={800}
        visible={!!data}
        onCancel={this.onCancel}
        footer={[
          <Button loading={controllerComments.loading} size={ANT_CONFIG.size} key="cancel" type="danger" onClick={this.onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button loading={controllerComments.loading} size={ANT_CONFIG.size} key="submit" type="primary" onClick={this.onSave}>
            {intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        {data && (
          <Row type="flex" gutter={[20, 20]}>
            <Col xs={24}>
              <Radio.Group defaultValue={status} buttonStyle="solid" onChange={(e) => this.setData("status", e.target.value)}>
                <Radio.Button value={1}>{intl.formatMessage({ id: "all_documents_are_ok" })}</Radio.Button>
                <Radio.Button value={0}>{intl.formatMessage({ id: "documents_missing" })}</Radio.Button>
              </Radio.Group>
            </Col>

            <Col xs={24}>
              <label className="ant-label" htmlFor="add_comment">
                {intl.formatMessage({ id: "add_comment" })}
              </label>
              <Input.TextArea
                disabled={status}
                name="add_comment"
                id="add_comment"
                size={ANT_CONFIG.size}
                autoSize={{ minRows: 2 }}
                value={comment}
                onChange={(e) => this.setData("comment", e.target.value)}
              />
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  controllerComments: state.ControllerComments,
});

export default withRouter(injectIntl(connect(mapStateToProps)(AddComment)));
