import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import Moment from "moment";
import { DatePicker, Col, Row, PageHeader, Select, Card, Button, Divider, Statistic } from "antd";
import { extendMoment } from "moment-range";

import Spinner from "../../components/spinner";
import riverOptions from "../../services/select_river_options";
import { downloadRiverCardsReport, fetchRiverCardsReport} from "../../redux/reports/actions";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import ANT_CONFIG from "../../constants/antconfig";
import downloadFile from "../../services/download_file";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import RolesContext from "../../context/RolesContext";
import { ADMIN } from "../../constants";

const moment = extendMoment(Moment);

class RiverCardsReport extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      river_ids: this.props.authentication.data.roles.includes(ADMIN)
        ? this.props.rivers.selected !== undefined
          ? this.props.rivers.selected
          : null
        : this.props.rivers.selected || this.props.authentication.data.river[0],
      date_start: moment().subtract(4, "week").format(`${this.dateFormat}`),
      date_end: moment().subtract(1, "day").format(`${this.dateFormat}`),
      filetype: null,
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reports.file !== this.props.reports.file && this.props.reports.file) {
      const uniqId = Math.random().toString(36).substr(2, 9);
      downloadFile(this.props.reports.file, this.state.filetype, `river-cards-${uniqId}`);
    }
  }

  handleDataChange() {
    const data = {
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      river_ids: this.state.river_ids ? [this.state.river_ids] : [],
    };

    this.props.dispatch(fetchRiverCardsReport(data));
  }

  handleDateChange(e, key) {
    this.setState({
      [key]: moment(e).format(`${this.dateFormat}`),
    });
  }

  handleRiverChange(e) {
    this.setState({
      river_ids: e,
    });
  }

  onFilterSubmit() {
    this.handleDataChange();
  }

  handleDownload = (reportType = null, filetype = "pdf") => {
    const { data } = this.props.reports;

    this.setState(
      {
        filetype,
      },
      () => {
        data.filters = {
          ...data.filters,
          filetype,
        };

        this.props.dispatch(downloadRiverCardsReport(this.props.reports.data, reportType));
      }
    );
  };

  render() {
    const { intl } = this.props;

    if (!this.props.reports.data || !this.props.rivers.data || this.props.reports.loading) {
      return <Spinner />;
    }

    let selectRivers = [
      {
        value: null,
        label: intl.formatMessage({ id: "report_all_rivers" }),
      },
    ];

    if (this.props.rivers.data && this.props.rivers.data.length > 0) {
      selectRivers = [...selectRivers, ...riverOptions(this.props.rivers, this.props.authentication)];
    }

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "river_card" })}
          extra={[
            <FileDownloadDropdown
              key="1"
              handleClick={this.handleDownload}
              loading={this.props.reports.file_loading}
              options={[{ value: "pdf", label: "PDF" }]}
            />,
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16} className="row-flex flex-align-end">
                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_start" className="ant-label">
                    {intl.formatMessage({ id: "date_start_order_date" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_start)}
                    onChange={(e) => this.handleDateChange(e, "date_start")}
                    showToday={false}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {intl.formatMessage({ id: "date_end_order_date" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_end)}
                    onChange={(e) => this.handleDateChange(e, "date_end")}
                    disabledDate={(d) => !d || d.isAfter(moment().subtract(1, "day"))}
                    showToday={false}
                  />
                </Col>

                {this.props.rivers.data.length > 1 && (
                  <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label htmlFor="select_river" className="ant-label">
                      {intl.formatMessage({ id: "select_river" })}
                    </label>
                    <Select
                      id="select_river"
                      showSearch
                      disabled={this.props.reports.file_loading}
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({ id: "select_river" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleRiverChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.river_ids}
                    >
                      {selectRivers.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Button
                    type="primary"
                    disabled={this.props.reports.file_loading}
                    onClick={() => this.onFilterSubmit()}
                    loading={this.props.reports.loading || this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                  >
                    {intl.formatMessage({ id: "apply" })}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        {this.props.reports.data.organization ? (
          <React.Fragment>
            <Divider style={{ marginTop: 20 }}>{this.props.intl.formatMessage({ id: "company_information" })}</Divider>

            <Row gutter={18} type="flex">
              {Object.keys(this.props.reports.data.organization).map((i, key) => (
                <Col key={key} xs={24} sm={8} lg={6} style={{ marginBottom: 18 }}>
                  <Card style={{ height: "100%" }}>
                    <Statistic groupSeparator="" title={this.props.intl.formatMessage({ id: i })} value={this.props.reports.data.organization[i] || " - "} />
                  </Card>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        ) : null}

        {this.props.reports.data.results ? (
          <React.Fragment>
            <Divider style={{ marginTop: 30 }}>{intl.formatMessage({ id: "summary" })}</Divider>

            <Row gutter={18} type="flex">
              {Object.keys(this.props.reports.data.results).map((i, key) => (
                <Col key={key} xs={24} sm={8} lg={6} style={{ marginBottom: 18 }}>
                  <Card style={{ height: "100%" }}>
                    <Statistic title={intl.formatMessage({ id: i })} value={this.props.reports.data.results[i] || " - "} />
                  </Card>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.Reports,
  authentication: state.Auth,
  rivers: state.Rivers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(RiverCardsReport)));
