import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, InputGroup, Input } from "reactstrap";
import { authForgotPassword, authResetPassword } from "../../redux/authentication/actions";
import Spinner from "../../components/spinner";

export class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      password: "",
      password_confirmation: ""
    };
  }

  componentDidMount() {
    this.setState({ token: this.props.match.params.hash ? this.props.match.params.hash : "" });
  }

  onSendEmail = e => {
    e.preventDefault();
    this.props.dispatch(authForgotPassword(this.state));
  };

  onPasswordChange = e => {
    e.preventDefault();
    this.props.dispatch(authResetPassword(this.state));
  };

  render() {
    return this.props.match.params.hash === "email" ? (
      <form className="form-password-reset" onSubmit={this.onSendEmail}>
        {this.props.authentication.loading ? (
          <Spinner />
        ) : (
          <div>
            <InputGroup>
              <Input
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                type="email"
                placeholder={this.props.intl.formatMessage({ id: "email" })}
              />
            </InputGroup>
            <Button className="eg-green" color="primary" type="submit" size="lg" block>
              {this.props.intl.formatMessage({ id: "send" })}
            </Button>
            <NavLink className="back-to-login" to="/">
              <span>{this.props.intl.formatMessage({ id: "back_to_login" })}</span>
            </NavLink>
          </div>
        )}
      </form>
    ) : (
      <form className="form-password-reset-confirmation" onSubmit={this.onPasswordChange}>
        {this.props.authentication.loading ? (
          <Spinner />
        ) : (
          <div>
            <InputGroup>
              <Input
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                type="email"
                placeholder={this.props.intl.formatMessage({ id: "email" })}
              />
            </InputGroup>
            <InputGroup>
              <Input
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
                type="password"
                className="new-password"
                placeholder={this.props.intl.formatMessage({ id: "new_password" })}
              />
            </InputGroup>
            <InputGroup>
              <Input
                value={this.state.password_confirmation}
                onChange={e => this.setState({ password_confirmation: e.target.value })}
                type="password"
                className="new-password-repeat"
                placeholder={this.props.intl.formatMessage({ id: "new_password_repeat" })}
              />
            </InputGroup>
            <Button className="eg-green" color="primary" type="submit" size="lg" block>
              {this.props.intl.formatMessage({ id: "save" })}
            </Button>
            <NavLink className="back-to-login" to="/">
              <span>{this.props.intl.formatMessage({ id: "back_to_login" })}</span>
            </NavLink>
          </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth
});

export default withRouter(injectIntl(connect(mapStateToProps)(PasswordReset)));
