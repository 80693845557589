import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Table, Tag, Modal, Checkbox, Input, Popconfirm } from "antd";
import { DeleteFilled } from "@ant-design/icons";

import { fetch, add, update, remove } from "../../redux/equipment_admin/actions";
import Spinner from "../../components/spinner";
import ANT_CONFIG from "../../constants/antconfig";

class Equipment extends React.Component {
  state = {
    showModal: false,
    isNew: false,
    data: null,
    active: true,
    defaultEquipment: false,
    deleteLoading: []
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const { equipmentadmin } = this.props;

    if (!equipmentadmin.data) {
      dispatch(fetch());
    }
  }

  onDelete(id) {
    const { dispatch } = this.props;
    const { deleteLoading } = this.state;
    deleteLoading.push(id);
    dispatch(remove(id));
  }

  setData(newState) {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        ...newState
      }
    }));
  }

  setActive(active) {
    this.setState({
      active
    })
  }
  
  setDefaultEquipment(bool) {
    this.setState({
      defaultEquipment: bool
    })
  }

  closeModal = () => {
    this.setState({
      data: null,
      showModal: false,
      isNew: false,
      active: true
    });
  };

  onModalSave = () => {
    const { dispatch } = this.props;
    const { data, active, isNew, defaultEquipment } = this.state;
    const payload = {
      ...data,
      active,
      default: defaultEquipment
    };

    dispatch(isNew ? add(payload) : update(payload));
  };

  openModal(data, isNew) {
    this.setState({
      data,
      isNew,
      showModal: true
    });

    if (!isNew) {
      this.setState({
        active: data.active,
        defaultEquipment: data.default
      });
    }
  }

  render() {
    const { equipmentadmin, intl } = this.props;
    const { data, deleteLoading, showModal, active, defaultEquipment } = this.state;

    if (!equipmentadmin.data) {
      return <Spinner />;
    }

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key"
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
      },
      {
        title: intl.formatMessage({ id: "name" }),
        dataIndex: "slug",
        key: "slug"
      },
      {
        title: intl.formatMessage({ id: "defaultEquipment" }),
        dataIndex: "default",
        key: "default"
      },
      {
        title: intl.formatMessage({ id: "active" }),
        dataIndex: "active",
        key: "active"
      },
      {
        title: "",
        dataIndex: "edit",
        key: "edit"
      }
    ];

    let tableData = [];
    if (equipmentadmin.data?.length > 0) {
      tableData = equipmentadmin.data
        .map((i, key) => ({
          key: key + 1,
          id: i.id,
          slug: intl.formatMessage({ id: "equipment-" + i.slug }),
          active: (
            <Tag color={i.active ? "green" : "red"} className="ant-tag">
              {intl.formatMessage({ id: i.active ? "yes" : "no" })}
            </Tag>
          ),
          default: (
            <Tag color={i.default ? "green" : "red"} className="ant-tag">
              {intl.formatMessage({ id: i.default ? "yes" : "no" })}
            </Tag>
          ),
          edit: (
            <>
              <Button type="primary" size={ANT_CONFIG.size} onClick={() => this.openModal(i, false)} style={{ marginRight: 10, marginTop: 5, marginBottom: 5 }}>
                {intl.formatMessage({ id: "edit" })}
              </Button>
              <Popconfirm
                title={intl.formatMessage({ id: "confirm_delete_question" })}
                onConfirm={() => this.onDelete(i.id)}
                okText={intl.formatMessage({ id: "remove" })}
                cancelText={intl.formatMessage({ id: "cancel" })}
              >
                <Button icon={<DeleteFilled />} type="danger" size={ANT_CONFIG.size} loading={deleteLoading.includes(i.id)}>
                  {intl.formatMessage({ id: "remove" })}
                </Button>
              </Popconfirm>
            </>
          )
        }));
    }

    return (
      <>
        <PageHeader
          title={intl.formatMessage({ id: "equipment" })}
          extra={[
            <Button key="new" type="primary" size={ANT_CONFIG.size} onClick={() => this.openModal(null, true)}>
              {intl.formatMessage({ id: "new_equipment" })}
            </Button>
          ]}
        />

        <Row>
          <Col xs={24}>
            <Card style={{ marginTop: 20 }}>
              <Row>
                <Col xs={24}>
                  <Table columns={tableColumns} dataSource={tableData} pagination={ANT_CONFIG.pagination} />
                </Col>
              </Row>
            </Card>
          </Col>

          {showModal && (
            <Modal
              width={800}
              visible={!!showModal}
              onCancel={this.closeModal}
              footer={[
                <Button size={ANT_CONFIG.size} key="cancel" type="danger" onClick={this.closeModal}>
                  {intl.formatMessage({ id: "cancel" })}
                </Button>,
                <Button size={ANT_CONFIG.size} key="submit" loading={equipmentadmin.loading} type="primary" onClick={this.onModalSave}>
                  {intl.formatMessage({ id: "save" })}
                </Button>
              ]}
            >
              <Row>
                <Col xs={24} style={{ marginBottom: 10 }}>
                  <label className="ant-label" htmlFor="name_no">
                    {intl.formatMessage({ id: "name_no" })}
                  </label>
                  <Input
                    size={ANT_CONFIG.size}
                    type="text"
                    name="name_no"
                    id="name_no"
                    value={data?.name_no}
                    onChange={e => this.setData({ name_no: e.target.value })}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={24} style={{ marginBottom: 10 }}>
                  <label className="ant-label" htmlFor="name_en">
                    {intl.formatMessage({ id: "name_en" })}
                  </label>
                  <Input size={ANT_CONFIG.size} type="text" name="name" id="name" value={data?.name} onChange={e => this.setData({ name: e.target.value })} />
                </Col>
              </Row>

              <Row>
                <Col xs={24}>
                  <Checkbox checked={active} onChange={e => this.setActive(e.target.checked)}>
                    {intl.formatMessage({ id: "active" })}
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <Checkbox checked={defaultEquipment} onChange={e => this.setDefaultEquipment(e.target.checked)}>
                    {intl.formatMessage({ id: "defaultEquipment" })}
                  </Checkbox>
                </Col>
              </Row>
            </Modal>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth,
  equipmentadmin: state.EquipmentAdmin
});

export default withRouter(injectIntl(connect(mapStateToProps)(Equipment)));
