import { ICDN_API_URL } from "../../constants";

class UploadAdapterPlugin {
  constructor(
    loader = {},
    options = {
      uploadUrl: process.env.REACT_APP_API_URL + ICDN_API_URL
    }
  ) {
    this.loader = loader;
    this.options = options;
  }

  process(resolve, reject, file) {
    this.initRequest();
    this.initListeners(resolve, reject, file);
    this.sendRequest(file);
  }

  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          this.process(resolve, reject, file);
        })
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  initRequest() {
    const xhr = new XMLHttpRequest();
    this.xhr = xhr;

    xhr.open("POST", this.options.uploadUrl, true);
    xhr.responseType = "json";
  }

  initListeners(resolve, reject, file) {
    const { xhr, loader } = this;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const { response } = xhr;

      if (!response || response.error) {
        return reject(response && response.error && response.error.message ? response.error.message : genericErrorText);
      }

      resolve(response.data);
    });

    if (xhr.upload) {
      xhr.upload.addEventListener("progress", evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  sendRequest(file) {
    const headers = this.options.headers || {};

    for (const headerName of Object.keys(headers)) {
      this.xhr.setRequestHeader(headerName, headers[headerName]);
    }

    const data = new FormData();

    data.append("file", file);

    this.xhr.withCredentials = true
    this.xhr.send(data);
  }
}

export default UploadAdapterPlugin;
