import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";

import { GOOGLE_MAP_API_KEY, GOOGLE_MAP_DEFAULT_POSITION } from "../../constants";

const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={props.zoom || 8} defaultCenter={props.defaultPosition || GOOGLE_MAP_DEFAULT_POSITION}>
      {props.draw && (
        <DrawingManager
          defaultDrawingMode={'polygon'}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: 2,
              drawingModes: ['polygon']
            }
          }}
          onPolygonComplete={props.onPolygonComplete}
        />
      )}
      {props.children}
    </GoogleMap>
  ))
);

const Map = props => (
  <MapWrapper
    {...props}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAP_API_KEY}`}
    loadingElement={<div style={{ height: "100%" }} />}
    containerElement={<div style={{ height: props.height || 400 }} />}
    mapElement={<div style={{ height: "100%" }} />}
    onMarkerComplete={props.onMarkerComplete}
    onPolygonComplete={props.onPolygonComplete}
    draw={props.draw}
  />
);

export default Map;
