import React from "react";
// import { injectIntl } from "react-intl";
import { StyleSheet, Document, Page, View, Text } from "@react-pdf/renderer";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { SummaryRenderer } from "./summaryRender";
const moment = extendMoment(Moment);

const styles = StyleSheet.create({
  page: { flexDirection: "column", padding: 25, width: "100vw", height: "100vh" },
  table: {
    fontSize: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  smallTable: {
    fontSize: 10,
    width: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 5,
  },
  cell: {
    borderColor: "#cc0000",
    borderStyle: "solid",
    borderWidth: 2,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "100%",
    alignSelf: "stretch",
    fontSize: 8,
    textAlign: "left",
  },
  header: {
    backgroundColor: "#eee",
  },
  headerText: {
    fontSize: 9,
    fontWeight: 1200,
    color: "#1a245c",
    border: "none",
    borderLeft: "1px solid #DDD",
    borderBottom: "1px solid #DDD",
    paddingLeft: 4,
  },
  tableText: {
    margin: 10,
    fontSize: 10,
    color: "#EEE",
  },
  rowCellOrg: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    borderBottom: "1px solid #DDD",
    paddingLeft: 4,
  },
  rowCell: {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderBottom: "1px solid #DDD",
    paddingRight: 4,
    textAlign: "right",
  },
});

const summaryConstColumn = [
  {
    dataIndex: "title",
    render: (data, record) => <SummaryRenderer data={data} record={record} />,
  },
  {
    dataIndex: "amount",
    align: "right",
  },
];
const EconomyPDF = ({
  organizationData = [],
  onlinePaymentData = [],
  manualPaymentData = [],
  summaryColumn = summaryConstColumn,
  summaryData = [],
  intl,
  startDate = "",
  endDate = "",
}) => {
  const orgcolumn = [
    {
      title: intl.formatMessage({ id: "beat" }),
      dataIndex: "beat",
      key: "beat",
    },
    {
      title: intl.formatMessage({ id: "river" }),
      dataIndex: "river",
      key: "river",
    },
    {
      title: intl.formatMessage({ id: "organization_name" }),
      dataIndex: "organization_name",
      key: "organization_name",
    },
    {
      title: intl.formatMessage({ id: "organization_number" }),
      dataIndex: "organization_number",
      key: "organization_number",
    },
    {
      title: intl.formatMessage({ id: "organization_account_number" }),
      dataIndex: "organization_account_number",
      key: "organization_account_number",
    },
    {
      title: intl.formatMessage({ id: "organization_address" }),
      dataIndex: "organization_address",
      key: "organization_address",
    },
    {
      title: intl.formatMessage({ id: "organization_phone" }),
      dataIndex: "organization_phone",
      key: "organization_phone",
    },
  ];

  const column = [
    {
      title: intl.formatMessage({ id: "tax_percentage" }),
      dataIndex: "tax_percentage",
      render: (data, _) => <div style={{ textAlign: "right" }}>{data}</div>,
    },
    {
      title: intl.formatMessage({ id: "revenue_gross" }),
      dataIndex: "revenue",
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "refunds" }),
      dataIndex: "refunds",
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "net_revenue" }),
      dataIndex: "net_revenue",
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "revenue_tax" }),
      dataIndex: "revenue_tax",
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "provision_percentage" }),
      dataIndex: "provision_percentage",
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "provision" }),
      dataIndex: "provision",
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "provision_tax" }),
      dataIndex: "provision_tax",
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "payment_to_customer" }),
      dataIndex: "payment_to_customer",
      align: "right",
    },
  ];
  let manual_Columns = [...column];
  manual_Columns.pop();
  manual_Columns.push({
    title: intl.formatMessage({ id: "payment_to_customer_manual" }),
    dataIndex: "payment_to_customer",
    align: "right",
  });
  return (
    <Document>
      <Page style={styles.page} wrap={false} size="A3">
        <View>
          <Text style={{ textAlign: "left", fontSize: 14, margin: "5px 0px" }}>
            {intl.formatMessage({ id: "economy_beat_reports" })}{" "}
            {moment(startDate).format("DD.MM.YYYY") + ` - ` + moment(endDate).format("DD.MM.YYYY")}
          </Text>
          <View style={{ display: "flex", justifyContent: "flex-end" }}>
            <Text style={{ textAlign: "right", fontSize: 8, margin: "5px 0px" }}>
              {intl.formatMessage({ id: "downloaded" })} {moment(moment.now()).format("DD.MM.YYYY, HH:mm")}
            </Text>
          </View>

          <View style={styles.table}>
            <View style={Object.assign({}, styles.row, styles.header)}>
              {orgcolumn.map((o, index) => (
                <Text key={index} style={Object.assign({}, styles.cell, styles.headerText)}>
                  {o.title}
                </Text>
              ))}
            </View>
            {organizationData.map((e, index) => {
              return (
                <View key={index} style={styles.row}>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>{e.beat}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>{e.river}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>{e.organization_name}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>{e.organization_number}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>{e.organization_account_number}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>{e.organization_address}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>{e.organization_phone}</Text>
                </View>
              );
            })}
          </View>
          <View>
            <Text
              style={{
                backgroundColor: "#598c51",
                textAlign: "center",
                padding: 2,
                fontWeight: "bold",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "stretch",
                flexWrap: "nowrap",
                alignItems: "stretch",
                margin: "8px 0px",
                fontSize: 9,
              }}
            >
              {intl.formatMessage({ id: "revenue_online_payment" })}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={Object.assign({}, styles.row, styles.header)}>
              {column.map((o, index) => (
                <Text key={index} style={Object.assign({}, styles.cell, styles.headerText)}>
                  {o.title}
                </Text>
              ))}
            </View>

            {(onlinePaymentData.rows || []).map((e, index) => {
              return (
                <View key={index} style={styles.row}>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.tax_percentage}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.revenue}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.refunds}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.net_revenue}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.revenue_tax}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.provision_percentage}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.provision}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.provision_tax}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.payment_to_customer}</Text>
                </View>
              );
            })}
            <View style={styles.row}>
              <Text
                style={Object.assign(
                  {},
                  styles.cell,
                  styles.headerText,
                  {
                    borderLeft: "0px",
                    fontWeight: "bold",
                    fontSize: 9,
                  },
                  styles.rowCellOrg,
                )}
              >
                {intl.formatMessage({ id: "sum" })}
              </Text>
              {[
                onlinePaymentData.summary["revenue"],
                onlinePaymentData.summary["refunds"],
                onlinePaymentData.summary["net_revenue"],
                onlinePaymentData.summary["revenue_tax"],
                "-",
                onlinePaymentData.summary["provision"],
                onlinePaymentData.summary["provision_tax"],
                onlinePaymentData.summary["payment_to_customer"],
              ].map((ele, index) => (
                <Text
                  key={index}
                  style={Object.assign(
                    {},
                    styles.cell,
                    styles.headerText,
                    {
                      borderLeft: "0px",
                      fontWeight: "bold",
                      fontSize: 9,
                    },
                    styles.rowCell,
                  )}
                >
                  {ele}
                </Text>
              ))}
            </View>
          </View>
          <View>
            <Text
              style={{
                backgroundColor: "#598c51",
                textAlign: "center",
                padding: 2,
                fontWeight: "bold",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "stretch",
                flexWrap: "nowrap",
                alignItems: "stretch",
                margin: "8px 0px",
                fontSize: 9,
              }}
            >
              {intl.formatMessage({ id: "revenue_manual_payment" })}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={Object.assign({}, styles.row, styles.header)}>
              {manual_Columns.map((o, index) => (
                <Text key={index} style={Object.assign({}, styles.cell, styles.headerText)}>
                  {o.title}
                </Text>
              ))}
            </View>

            {(manualPaymentData.rows || []).map((e) => {
              return (
                <View style={styles.row}>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.tax_percentage}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.revenue}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.refunds}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.net_revenue}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.revenue_tax}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.provision_percentage}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.provision}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.provision_tax}</Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.payment_to_customer}</Text>
                </View>
              );
            })}
            <View style={styles.row}>
              <Text
                style={Object.assign(
                  {},
                  styles.cell,
                  styles.headerText,
                  {
                    borderLeft: "0px",
                    fontWeight: "bold",
                    fontSize: 9,
                  },
                  styles.rowCellOrg,
                )}
              >
                {intl.formatMessage({ id: "sum" })}
              </Text>
              {[
                manualPaymentData.summary["revenue"],
                manualPaymentData.summary["refunds"],
                manualPaymentData.summary["net_revenue"],
                manualPaymentData.summary["revenue_tax"],
                "-",
                manualPaymentData.summary["provision"],
                manualPaymentData.summary["provision_tax"],
                manualPaymentData.summary["payment_to_customer"],
              ].map((ele, index) => (
                <Text
                  key={index}
                  style={Object.assign(
                    {},
                    styles.cell,
                    styles.headerText,
                    {
                      borderLeft: "0px",
                      fontWeight: "bold",
                      fontSize: 9,
                    },
                    styles.rowCell,
                  )}
                >
                  {ele}
                </Text>
              ))}
            </View>
          </View>
          <View>
            <Text
              style={{
                backgroundColor: "#456e99",
                textAlign: "left",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "stretch",
                flexWrap: "nowrap",
                alignItems: "stretch",
                margin: "8px 0px",
                fontSize: 9,
                width: "550px",
                color: "white",
              }}
            >
              {intl.formatMessage({ id: "summary" })}
            </Text>
          </View>

          <View style={styles.smallTable}>
            <View style={Object.assign({}, styles.row, styles.header)}>
              {summaryColumn.map((o, index) => (
                <Text
                  key={index}
                  style={Object.assign({}, styles.cell, styles.headerText, index === 0 ? styles.rowCellOrg : styles.rowCell)}
                >
                  {o.title}
                </Text>
              ))}
            </View>

            {summaryData.map((e, index) => {
              return (
                <View style={styles.row} key={index}>
                  <Text style={Object.assign({}, styles.cell, styles.rowCellOrg)}>
                    {" "}
                    <SummaryRenderer data={e.title} record={e[index]} intl={intl} />{" "}
                  </Text>
                  <Text style={Object.assign({}, styles.cell, styles.rowCell)}>{e.amount}</Text>
                </View>
              );
            })}
          </View>
          <View style={{ marginTop: 15 }}>
            <Text style={{ fontSize: 8 }}>{intl.formatMessage({ id: "intermediary_of_service" })}</Text>
            <Text style={{ fontSize: 8 }}>Xperitech AS</Text>
            <Text style={{ fontSize: 8 }}>Solhøgdvegen 11,</Text>
            <Text style={{ fontSize: 8 }}>7021 TRONDHEIM, Norge</Text>

            <Text style={{ fontSize: 8 }}>Foretaksregisteret</Text>
            <Text style={{ fontSize: 8 }}>919075112 MVA</Text>

            <Text style={{ fontSize: 8 }}>{intl.formatMessage({ id: "telephone" })} : 40 40 23 90</Text>
            <Text style={{ fontSize: 8 }}>{intl.formatMessage({ id: "email_pdf" })} : support@elveguiden.no</Text>
            <Text style={{ fontSize: 8 }}>Web: www.elveguiden.no</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default EconomyPDF;
