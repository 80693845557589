import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, "admin/all-season-fishing-cards", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_SEASON_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_SEASON_CARDS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_SEASON_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* post(action) {
  try {
    const response = yield call(API.call, "admin/season-fishing-cards", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.POST_SEASON_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.POST_SEASON_CARDS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.POST_SEASON_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* edit(action) {
  try {
    const response = yield call(API.call, `admin/season-fishing-cards`, "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_SEASON_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.EDIT_SEASON_CARDS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_SEASON_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, "admin/season-fishing-cards", "DELETE", action.ids);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_SEASON_CARDS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.REMOVE_SEASON_CARDS_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_SEASON_CARDS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* addType(action) {
  try {
    const response = yield call(API.call, `admin/season-fishing-card-type`, "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_SEASON_CARDS_TYPE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.ADD_SEASON_CARDS_TYPE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.ADD_SEASON_CARDS_TYPE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* editType(action) {
  try {
    const response = yield call(API.call, `admin/season-fishing-card-type`, "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.EDIT_SEASON_CARDS_TYPE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.EDIT_SEASON_CARDS_TYPE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.EDIT_SEASON_CARDS_TYPE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchTypes(action) {
  try {
    const response = yield call(API.call, `admin/season-fishing-card-types/${action.payload}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_SEASON_CARDS_TYPES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_SEASON_CARDS_TYPES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_SEASON_CARDS_TYPES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_SEASON_CARDS, fetch),
    takeEvery(ACTION.POST_SEASON_CARDS, post),
    takeEvery(ACTION.EDIT_SEASON_CARDS, edit),
    takeEvery(ACTION.REMOVE_SEASON_CARDS, remove),
    takeEvery(ACTION.ADD_SEASON_CARDS_TYPE, addType),
    takeEvery(ACTION.EDIT_SEASON_CARDS_TYPE, editType),
    takeEvery(ACTION.FETCH_SEASON_CARDS_TYPES, fetchTypes),
  ]);
}
