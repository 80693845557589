import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Col, Row, PageHeader, Button, Input, Tabs, Checkbox, Select, DatePicker } from "antd";
import moment from "moment";

import { fetch, store, update, updateImage } from "../../redux/page/actions";
import Spinner from "../../components/spinner";
import GenericError from "../Errors/GenericError";
import CollapsedCard from "../../components/Card/CollapsedCard";
import ANT_CONFIG from "../../constants/antconfig";
import { DEFAULT_LANGUAGE, LANGUAGES, STATUSES, WEB_URL } from "../../constants";
import HTMLEditor from "../../components/HTMLEditor/HTMLEditor";
import ImageUpload from "../../components/ImageUpload/ImageUpload";

const TABS_INFO = [
  {
    key: "general",
  },
  {
    key: "status",
  },
  {
    key: "seo",
  },
];

class Page extends React.Component {
  state = {
    activeTab: TABS_INFO[0].key,
    isNew: this.props.match.params.id === "new",
    data: null,
  };

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.dispatch(fetch(this.props.match.params.id));
    } else {
      this.setData({
        language: DEFAULT_LANGUAGE,
        status: "draft",
        objects_translations: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page.data !== this.props.page.data && this.props.page.data) {
      this.setFetchedData(this.props.page.data);
    }

    if (prevProps.page.pageId !== this.props.page.pageId && this.props.page.pageId && this.state.isNew) {
      this.props.dispatch(fetch(this.props.page.pageId));
    }
  }

  onSave = () => {
    const { isNew, data } = this.state;
    const { dispatch } = this.props;
    const { id } = this.props.match.params;
    const publishDate = data.publish_date ?? moment().format("DD.MM.YYYY");
    const postData = {
      ...data,
      publish_date: moment(publishDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
    };

    dispatch(isNew ? store(postData) : update(postData, id));
  };

  onChangeValue(data) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        ...data,
      },
    }));
  }

  setLanguage(language) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        language,
      },
    }));
  }

  onTabChange = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  setData(data) {
    this.setState({
      data,
    });
  }

  setFetchedData(data) {
    this.setState(
      {
        isNew: false,
        activeTab: TABS_INFO[0].key,
        data,
      },
      () => {
        if (!this.state.data?.objects_translations) {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              objects_translations: [],
            },
          }));
        }
      }
    );
  }

  setObjectTranslations(data, context, language) {
    const dataToChange = this.state.data?.objects_translations?.filter((i) => i.language === language && i.key === context)?.[0];

    if (!dataToChange) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              key: context,
              value: data,
              language,
            },
          ],
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          objects_translations: [
            ...prevState.data.objects_translations.filter((i) => i.language !== language || i.key !== context),
            {
              ...dataToChange,
              value: data,
            },
          ],
        },
      }));
    }
  }

  addSeoFeatureImage = (file) => {
    if (file?.response?.data?.default) {
      this.setObjectTranslations(file.response.data.default, "seo_featured_image", this.state.data.language);

      if (!this.state.isNew) {
        this.props.dispatch(
          updateImage(
            {
              seo_featured_image: {
                key: "seo_featured_image",
                value: file.response.data.default,
                language: this.state.data.language,
              },
            },
            this.props.match.params.id
          )
        );
      }
    }
  };

  removeSeoFeatureImage = () => {
    this.setObjectTranslations("", "seo_featured_image", this.state.data.language);

    if (!this.state.isNew) {
      this.props.dispatch(
        updateImage(
          {
            seo_featured_image: {
              key: "seo_featured_image",
              value: "",
              language: this.state.data.language,
            },
          },
          this.props.match.params.id
        )
      );
    }
  };

  render() {
    const { intl, page } = this.props;
    const { data, activeTab, isNew } = this.state;
    const content = isNew ? data?.content ?? "" : data?.content ?? page?.data?.content ?? "";
    const urlLang = data?.language === "nb" ? "no" : data?.language;

    if (page.pageId) {
      return <Redirect to={`/content/page/${page.pageId}`} />;
    }

    if (!page.loading && page.error && !page.data && !isNew) {
      return <GenericError />;
    }

    if (!data && page.loading && !isNew) {
      return <Spinner />;
    }

    const tabs = (
      <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onChange={this.onTabChange}>
        {TABS_INFO.map((t) => (
          <Tabs.TabPane tab={intl.formatMessage({ id: t.key })} key={t.key} />
        ))}
      </Tabs>
    );

    return (
      <div>
        <PageHeader
          title={data?.name || intl.formatMessage({ id: "add_new_page" })}
          footer={tabs}
          extra={[
            <Button onClick={this.onSave} loading={page.loading} type="primary" key="save" size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
          ]}
        />

        <div style={activeTab !== TABS_INFO[0].key ? { display: "none" } : {}}>
          {!isNew && data?.slug && (
            <CollapsedCard title={intl.formatMessage({ id: "current_link" })}>
              <div className="container-900">
                <Row>
                  <Col xs={24}>
                    <a href={`${WEB_URL}${urlLang}/${data.slug}`} target="_blank" rel="noopener noreferrer">{`${WEB_URL}${urlLang}/${data.slug}`}</a>
                  </Col>
                </Row>
              </div>
            </CollapsedCard>
          )}

          <CollapsedCard title={intl.formatMessage({ id: "name" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    type="text"
                    id="name"
                    size={ANT_CONFIG.size}
                    value={data?.name}
                    style={{ width: "100%" }}
                    onChange={(e) => this.onChangeValue({ name: e.target.value })}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          {isNew && (
            <CollapsedCard title={intl.formatMessage({ id: "language" })}>
              <div className="container-900">
                <Row>
                  <Col xs={24}>
                    <Select
                      id="select_language"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      showSearch
                      value={data?.language || DEFAULT_LANGUAGE}
                      optionFilterProp="children"
                      onChange={(language) => this.setLanguage(language)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {LANGUAGES.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </div>
            </CollapsedCard>
          )}

          <CollapsedCard title={intl.formatMessage({ id: "content" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <HTMLEditor data={content} onChange={(event, editor) => this.onChangeValue({ content: editor.getData() })} />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={activeTab !== TABS_INFO[1].key ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "status" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Select
                    id="select_status"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    showSearch
                    value={data?.status || STATUSES[0]}
                    optionFilterProp="children"
                    onChange={(status) => this.onChangeValue({ status })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {STATUSES.map((i) => (
                      <Select.Option key={i} value={i}>
                        {intl.formatMessage({ id: i })}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col xs={24}>
                  <label htmlFor="publish_date" className="ant-label">
                    {intl.formatMessage({ id: "publish_date" })}
                  </label>
                  <DatePicker
                    style={{ width: "100%" }}
                    id="publish_date"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={data?.publish_date ? moment(data.publish_date, "DD.MM.YYYY") : moment()}
                    onChange={(e) => this.onChangeValue({ publish_date: e.format("DD.MM.YYYY") })}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>
        </div>

        <div style={activeTab !== TABS_INFO[2].key ? { display: "none" } : {}}>
          <CollapsedCard title={intl.formatMessage({ id: "title" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input
                    type="text"
                    id="title"
                    size={ANT_CONFIG.size}
                    value={data?.objects_translations?.filter((i) => i.key === "seo_title" && i.language === data?.language)?.[0]?.value || ""}
                    style={{ width: "100%" }}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_title", data?.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "description" })}>
            <div className="container-900">
              <Row>
                <Col xs={24}>
                  <Input.TextArea
                    name="description"
                    id="description"
                    autoSize={{ minRows: 3 }}
                    size={ANT_CONFIG.size}
                    value={data?.objects_translations?.filter((i) => i.key === "seo_description" && i.language === data?.language)?.[0]?.value || ""}
                    onChange={(e) => this.setObjectTranslations(e.target.value, "seo_description", data?.language)}
                  />
                </Col>
              </Row>
            </div>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "featured_image" })}>
            <Row>
              <Col xs={24}>
                <ImageUpload
                  data={
                    data?.objects_translations?.filter((i) => i.key === "seo_featured_image" && i.language === data?.language)?.[0]?.value
                      ? [
                          {
                            id: 1,
                            url: data?.objects_translations?.filter((i) => i.key === "seo_featured_image" && i.language === data?.language)[0].value,
                          },
                        ]
                      : []
                  }
                  limit={1}
                  onFileAdd={this.addSeoFeatureImage}
                  onFileRemove={this.removeSeoFeatureImage}
                />
              </Col>
            </Row>
          </CollapsedCard>

          <CollapsedCard title={intl.formatMessage({ id: "additional_information" })}>
            <Row>
              <Col xs={24}>
                <Checkbox
                  size={ANT_CONFIG.size}
                  checked={
                    data?.objects_translations?.filter((i) => i.key === "seo_noindex" && i.language === data?.language && parseInt(i.value, 10)).length > 0
                      ? parseInt(
                          data.objects_translations.filter((i) => i.key === "seo_noindex" && i.language === data.language && parseInt(i.value, 10))[0].value,
                          10
                        )
                      : 0
                  }
                  onChange={(e) => this.setObjectTranslations(e.target.checked ? 1 : 0, "seo_noindex", data?.language)}
                >
                  {intl.formatMessage({ id: "hide_from_crawlers" })}
                </Checkbox>
              </Col>
            </Row>
          </CollapsedCard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  page: state.Page,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Page)));
