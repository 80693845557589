export const FETCH_REFUNDS = "FETCH_REFUNDS";
export const FETCH_REFUNDS_SUCCESS = "FETCH_REFUNDS_SUCCESS";
export const FETCH_REFUNDS_FAILURE = "FETCH_REFUNDS_FAILURE";

export const ADD_REFUND = "ADD_REFUND";
export const ADD_REFUND_SUCCESS = "ADD_CATCH_SUCCESS";
export const ADD_REFUND_FAILURE = "ADD_CATCH_FAILURE";

export const REMOVE_REFUND = "REMOVE_REFUND";
export const REMOVE_REFUND_SUCCESS = "REMOVE_REFUND_SUCCESS";
export const REMOVE_REFUND_FAILURE = "REMOVE_REFUND_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_REFUNDS,
    payload,
  };
}

export function add(data, payload) {
  return {
    type: ADD_REFUND,
    data,
    payload,
  };
}

export function remove(id, payload) {
  return {
    type: REMOVE_REFUND,
    id,
    payload,
  };
}
