export const FETCH_CUSTOMER_BLOCK_CARDS = "FETCH_CUSTOMER_BLOCK_CARDS";
export const FETCH_CUSTOMER_BLOCK_CARDS_SUCCESS = "FETCH_CUSTOMER_BLOCK_CARDS_SUCCESS";
export const FETCH_CUSTOMER_BLOCK_CARDS_FAILURE = "FETCH_CUSTOMER_BLOCK_CARDS_FAILURE";

export const BAN_USER = "BAN_USER";
export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS";
export const BAN_USER_FAILURE = "BAN_USER_FAILURE";

export const UNBAN_USER = "UNBAN_USER";
export const UNBAN_USER_SUCCESS = "UNBAN_USER_SUCCESS";
export const UNBAN_USER_FAILURE = "UNBAN_USER_FAILURE";

export function fetch(id) {
  return {
    type: FETCH_CUSTOMER_BLOCK_CARDS,
    id
  };
}

export function ban(payload) {
  return {
    type: BAN_USER,
    payload
  };
}

export function unban(payload) {
  return {
    type: UNBAN_USER,
    payload
  };
}
