import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Card, Col, Row, PageHeader, Button, Input, Table, Tag, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { fetch, unblock } from "../../redux/customers/actions";
import ANT_CONFIG from "../../constants/antconfig";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";
import { ADMIN, RIVER_ADMIN, BEAT_ADMIN } from "../../constants";

const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const INITIAL_STATE = {
  filter: "",
  block_status_filter: "",
  comments_filter: "",
};
const { size, pagination } = ANT_CONFIG;

class Customers extends React.Component {
  static contextType = RolesContext;

  state = INITIAL_STATE;

  componentDidMount() {
    this.fetchCustomers();
  }

  fetchCustomers() {
    const { dispatch } = this.props;

    dispatch(fetch());
  }

  handleUnblock = (event, id) => {
    event.stopPropagation();
    const { dispatch } = this.props;

    dispatch(unblock({ user_id: id }));
  };

  onRowClick(id) {
    const { history } = this.props;

    history.push(`/customer/${id}`);
  }

  setStateAndInitialState(key, value) {
    this.setState({
      [key]: value,
    });

    INITIAL_STATE[key] = value;
  }

  filterData(value) {
    const { block_status_filter, filter, comments_filter } = this.state;
    let data = value;

    if (block_status_filter === "blocked") {
      data = data?.filter((i) => i.soft_blocked === 1);
    }

    if (block_status_filter === "not_blocked") {
      data = data?.filter((i) => i.soft_blocked === 0);
    }

    if (comments_filter === "ok") {
      data = data?.filter(
        (i) =>
          i.controller_comments &&
          i.controller_comments.length > 0 &&
          i.controller_comments.filter((i) => i.status === 0 && i.invalid === 0).length === 0,
      );
    }

    if (comments_filter === "not_ok") {
      data = data?.filter(
        (i) =>
          i.controller_comments &&
          i.controller_comments.length > 0 &&
          i.controller_comments.filter((i) => i.status === 0 && i.invalid === 0).length > 0,
      );
    }

    if (filter) {
      data = data?.filter((customer) => {
        const f = filter.toLowerCase();
        const fullName = `${customer.first_name} ${customer.last_name}`;

        return (
          (customer.id && customer.id.toString().includes(f)) ||
          (customer.first_name && customer.first_name.toLowerCase().includes(f)) ||
          (customer.last_name && customer.last_name.toLowerCase().includes(f)) ||
          (fullName && fullName.toLowerCase().includes(f)) ||
          (customer.email && customer.email.toLowerCase().includes(f))
        );
      });
    }

    return data;
  }

  render() {
    const { isAdmin, isSupervisorAdmin } = this.context;
    const { block_status_filter, filter, comments_filter } = this.state;
    const { intl, customers } = this.props;
    const data = this.filterData(customers.data);
    const { roles } = this.props.authentication.data;

    const selectStatus = [
      {
        value: "",
        label: intl.formatMessage({ id: "all" }),
      },
      {
        value: "blocked",
        label: intl.formatMessage({ id: "blocked" }),
      },
      {
        value: "not_blocked",
        label: intl.formatMessage({ id: "not_blocked" }),
      },
    ];
    const selectCommentsStatus = [
      {
        value: "",
        label: intl.formatMessage({ id: "all" }),
      },
      {
        value: "ok",
        label: intl.formatMessage({ id: "all_documents_are_ok" }),
      },
      {
        value: "not_ok",
        label: intl.formatMessage({ id: "documents_missing" }),
      },
    ];

    const action = isAdmin
      ? {
          title: "",
          dataIndex: "action",
          key: "action",
        }
      : {};

    const tableColumns = [
      {
        title: "#",
        dataIndex: "visible_key",
        key: "visible_key",
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({ id: "first_name" }),
        dataIndex: "first_name",
        key: "first_name",
      },
      {
        title: intl.formatMessage({ id: "last_name" }),
        dataIndex: "last_name",
        key: "last_name",
      },
      {
        title: intl.formatMessage({ id: "phone" }),
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: intl.formatMessage({ id: "email" }),
        dataIndex: "email",
        key: "email",
      },
      {
        title: intl.formatMessage({ id: "city" }),
        dataIndex: "city",
        key: "city",
      },
      {
        title: intl.formatMessage({ id: "country" }),
        dataIndex: "country",
        key: "country",
      },
      action,
    ];
    const tableData = data?.map((customer, row) => {
      const key = row + 1;
      const { id, first_name, last_name, phone, email, city, country, soft_blocked } = customer;

      return {
        key,
        visible_key: soft_blocked ? <Tag color="#f5222d">{key}</Tag> : key,
        id,
        first_name,
        last_name,
        phone,
        email,
        city,
        country,
        action:
          isAdmin && soft_blocked ? (
            <Button type="danger" size={size} onClick={(event) => this.handleUnblock(event, id)}>
              {intl.formatMessage({ id: "unblock" })}
            </Button>
          ) : null,
      };
    });

    return (
      <>
        <PageHeader
          title={intl.formatMessage({ id: "registered_clients" })}
          extra={[
            <NavLink key="new_user" to="/customer/new">
              <Button type="primary" size={size}>
                {intl.formatMessage({ id: "new_user" })}
              </Button>
            </NavLink>,
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search_by_id_name_or_email" className="ant-label">
                    {intl.formatMessage({ id: "search_by_id_name_or_email" })}
                  </label>
                  <Input
                    placeholder={intl.formatMessage({ id: "search_by_id_name_or_email" })}
                    id="search_by_id_name_or_email"
                    size={size}
                    value={filter}
                    onChange={(e) => this.setStateAndInitialState("filter", e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="select_block_status" className="ant-label">
                    {intl.formatMessage({ id: "select_block_status" })}
                  </label>
                  <Select
                    showSearch
                    size={size}
                    id="select_block_status"
                    style={{ width: "100%" }}
                    placeholder={`${intl.formatMessage({ id: "select_block_status" })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.setStateAndInitialState("block_status_filter", e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={block_status_filter}
                  >
                    {selectStatus.map((i, key) => (
                      <Select.Option key={key} value={i.value}>
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                {(isAdmin || isSupervisorAdmin) && (
                  <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label htmlFor="select_comment_status" className="ant-label">
                      {intl.formatMessage({ id: "select_comment_status" })}
                    </label>
                    <Select
                      showSearch
                      size={size}
                      id="select_comment_status"
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({ id: "select_comment_status" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.setStateAndInitialState("comments_filter", e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={comments_filter}
                    >
                      {selectCommentsStatus.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                loading={{ spinning: customers.loading, indicator: SPIN_ICON }}
                columns={tableColumns}
                dataSource={tableData}
                pagination={pagination}
                onRow={(record) => {
                  return {
                    onClick: () => this.onRowClick(record.id),
                  };
                }}
              />
            </Col>
          </Row>
        </Card>
        <Scribe
          intl={intl}
          hasMultiple
          items={[
            (roles.includes(BEAT_ADMIN) || roles.includes(ADMIN)) && {
              title: intl.formatMessage({ id: "scribe_customer_register_beat" }),
              link: "https://scribehow.com/embed/Kunderegister__wWSGuJWhQgCpoditPLUFXg",
            },
            (roles.includes(RIVER_ADMIN) || roles.includes(ADMIN)) && {
              title: intl.formatMessage({ id: "scribe_customer_register_river" }),
              link: "https://scribehow.com/embed/Se_brukere_i_kunderegistereret__ZcQT3IveSpKS-5i3EmC5yw",
            },
            {
              title: intl.formatMessage({ id: "scribe_customer_blocked_users" }),
              link: "https://scribehow.com/embed/Blokkerte_brukere__sjekke_hvem_som_er_blokkert_og_sperre_de_for_kjop__KmLoZnopQLa8GtZbEPeg0A",
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.Customers,
  authentication: state.Auth,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Customers)));
