export const FETCH_SEASON_CARDS = "FETCH_SEASON_CARDS";
export const FETCH_SEASON_CARDS_SUCCESS = "FETCH_SEASON_CARDS_SUCCESS";
export const FETCH_SEASON_CARDS_FAILURE = "FETCH_SEASON_CARDS_FAILURE";

export const POST_SEASON_CARDS = "POST_SEASON_CARDS";
export const POST_SEASON_CARDS_SUCCESS = "POST_SEASON_CARDS_SUCCESS";
export const POST_SEASON_CARDS_FAILURE = "POST_SEASON_CARDS_FAILURE";

export const EDIT_SEASON_CARDS = "EDIT_SEASON_CARDS";
export const EDIT_SEASON_CARDS_SUCCESS = "EDIT_SEASON_CARDS_SUCCESS";
export const EDIT_SEASON_CARDS_FAILURE = "EDIT_SEASON_CARDS_FAILURE";

export const REMOVE_SEASON_CARDS = "REMOVE_SEASON_CARDS";
export const REMOVE_SEASON_CARDS_SUCCESS = "REMOVE_SEASON_CARDS_SUCCESS";
export const REMOVE_SEASON_CARDS_FAILURE = "REMOVE_SEASON_CARDS_FAILURE";

export const ADD_SEASON_CARDS_TYPE = "ADD_SEASON_CARDS_TYPE";
export const ADD_SEASON_CARDS_TYPE_SUCCESS = "ADD_SEASON_CARDS_TYPE_SUCCESS";
export const ADD_SEASON_CARDS_TYPE_FAILURE = "ADD_SEASON_CARDS_TYPE_FAILURE";

export const EDIT_SEASON_CARDS_TYPE = "EDIT_SEASON_CARDS_TYPE";
export const EDIT_SEASON_CARDS_TYPE_SUCCESS = "EDIT_SEASON_CARDS_TYPE_SUCCESS";
export const EDIT_SEASON_CARDS_TYPE_FAILURE = "EDIT_SEASON_CARDS_TYPE_FAILURE";

export const FETCH_SEASON_CARDS_TYPES = "FETCH_SEASON_CARDS_TYPES";
export const FETCH_SEASON_CARDS_TYPES_SUCCESS = "FETCH_SEASON_CARDS_TYPES_SUCCESS";
export const FETCH_SEASON_CARDS_TYPES_FAILURE = "FETCH_SEASON_CARDS_TYPES_FAILURE";

export function fetch(data) {
  return {
    type: FETCH_SEASON_CARDS,
    data,
  };
}

export function post(data) {
  return {
    type: POST_SEASON_CARDS,
    data,
  };
}

export function edit(payload, data) {
  return {
    type: EDIT_SEASON_CARDS,
    payload,
    data,
  };
}

export function remove(ids, data) {
  return {
    type: REMOVE_SEASON_CARDS,
    ids,
    data,
  };
}

export function addType(data) {
  return {
    type: ADD_SEASON_CARDS_TYPE,
    data,
  };
}

export function editType(data) {
  return {
    type: EDIT_SEASON_CARDS_TYPE,
    data,
  };
}

export function fetchTypes(payload) {
  return {
    type: FETCH_SEASON_CARDS_TYPES,
    payload,
  };
}
