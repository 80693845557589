import moment from "moment";

import * as ACTION from "./actions";

const DATE_FORMAT = "YYYY-MM-DD";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  filteredData: undefined,
  single: undefined,
  filter: {
    date_start: moment().format(`${DATE_FORMAT}`),
    date_end: moment().format(`${DATE_FORMAT}`),
    filter: "",
  },
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_CATCHES_FOR_RIVER:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_CATCHES_FOR_RIVER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        filteredData: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_CATCHES_FOR_RIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.FETCH_CATCH:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_CATCH_SUCCESS:
      return {
        ...state,
        single: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_CATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.EDIT_CATCH:
      return {
        ...state,
        loading: true,
      };

    case ACTION.EDIT_CATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.EDIT_CATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.REPORT_CATCH:
      return {
        ...state,
        loading: true,
      };

    case ACTION.REPORT_CATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.REPORT_CATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.CATCH_LIST_FILTER:
      return {
        ...state,
        filter: action.data,
      };
    case ACTION.CATCH_PARAM_FILTER:
      return {
        ...state,
        filteredData: action.data,
      };
    case ACTION.ADD_CATCH:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_CATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.ADD_CATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
