import React, { Component } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { DatePicker, Col, Row, PageHeader, Select, Button, Card, Table, Typography, Divider } from "antd";
import { extendMoment } from "moment-range";
import { fetchEconomyBeatReport } from "../../redux/reports/actions";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import Spinner from "../../components/spinner";
import beatOptions from "../../services/select_beat_options";
import ANT_CONFIG from "../../constants/antconfig";
import EconomyPDF from "../../components/BeatEconomyReportPDFDownload";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const moment = extendMoment(Moment);
export class EconomyBeatReport extends Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      filetype: null,
      fetch_by: "purchase_date",
      date_start: moment().subtract(1, "day").format(`${this.dateFormat}`),
      date_end: moment().subtract(1, "day").format(`${this.dateFormat}`),
      beat_ids: Array.isArray(this.props.beats.selected) ? this.props.beats.selected : [this.props.authentication.data.beat[0]],
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.handleDataChange();
  }
  handleDataChange() {
    const data = {
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      fetch_by: this.state.fetch_by,
      beat_ids: this.state.beat_ids ? this.state.beat_ids : [],
    };

    this.props.dispatch(fetchEconomyBeatReport(data));
  }
  onFilterSubmit() {
    this.props.dispatch(selectBeat(this.state.beat_ids));

    this.handleDataChange();
  }

  handleDateChange(e, key) {
    this.setState({
      [key]: moment(e).format(`${this.dateFormat}`),
    });
  }

  handleBeatChange(e) {
    this.setState({
      beat_ids: e,
    });
  }

  render() {
    // const { Text } = Typography;
    const { intl } = this.props;

    const TitleWrapper = ({ title }) => <div style={{ fontWeight: "bold" }}>{title}</div>;

    if (!this.props.reports.data || !this.props.beats.data || this.props.reports.loading) {
      return <Spinner />;
    }
    const organizationData =
      this.props.reports.data.organization && Array.isArray(this.props.reports.data.organization)
        ? this.props.reports.data.organization
        : [];
    const onlinePaymentData = this.props.reports.data.online_payments || [];
    const manualPaymentData = this.props.reports.data.manual_payments || [];
    const summaryData = [];
    if (this.props.reports.data.summary) {
      Object.keys(this.props.reports.data.summary).forEach((key, index) => {
        let compData = {};
        compData["title"] = key;
        compData["amount"] = this.props.reports.data.summary[key];
        summaryData.push(compData);
        if (index === 2) {
          summaryData.push({ title: "", amount: "" });
        }
      });
    }

    let selectBeats = [];

    if (this.props.beats.data && this.props.beats.data.length > 0) {
      selectBeats = [...beatOptions(this.props.beats, this.props.authentication)];
    }

    const orgColumns = [
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "beat" })} />,
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "river" })} />,
        dataIndex: "river",
        key: "river",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_name" })} />,
        dataIndex: "organization_name",
        key: "organization_name",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_number" })} />,
        dataIndex: "organization_number",
        key: "organization_number",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_account_number" })} />,
        dataIndex: "organization_account_number",
        key: "organization_account_number",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_address" })} />,
        dataIndex: "organization_address",
        key: "organization_address",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "organization_phone" })} />,
        dataIndex: "organization_phone",
        key: "organization_phone",
      },
    ];

    const online_manual_Columns = [
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "tax_percentage" })} />,
        dataIndex: "tax_percentage",
        render: (data, _) => <div style={{ textAlign: "right" }}>{data}</div>,
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "revenue_gross" })} />,
        dataIndex: "revenue",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "refunds" })} />,
        dataIndex: "refunds",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "net_revenue" })} />,
        dataIndex: "net_revenue",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "revenue_tax" })} />,
        dataIndex: "revenue_tax",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "provision_percentage" })} />,
        dataIndex: "provision_percentage",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "provision" })} />,
        dataIndex: "provision",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "provision_tax" })} />,
        dataIndex: "provision_tax",
        align: "right",
      },
      {
        title: <TitleWrapper title={intl.formatMessage({ id: "payment_to_customer" })} />,
        dataIndex: "payment_to_customer",
        align: "right",
      },
    ];

    const SummaryRenderer = ({ data, record }) => {
      let payment_to_customer_message = intl.formatMessage({ id: "payment_to_customer_online" });
      let transaction_fee_manual = intl.formatMessage({ id: "economic_transaction_fee_manual" });
      let invoice_to_customer = intl.formatMessage({ id: "invoice_to_customer" });
      let total_payout = intl.formatMessage({ id: "economic_total_payout" });
      let currency = intl.formatMessage({ id: "currency" });
      return data === "payment_to_customer" ? (
        <>{payment_to_customer_message}</>
      ) : data === "transaction_fee_manual" ? (
        <>{transaction_fee_manual}</>
      ) : data === "invoice_to_customer" ? (
        <>{invoice_to_customer}</>
      ) : data === "total_payout" ? (
        <>{total_payout}</>
      ) : data === "currency" ? (
        <>{currency}</>
      ) : (
        ""
      );
    };

    const summaryColumn = [
      {
        dataIndex: "title",
        key: "title",
        render: (data, record) => <SummaryRenderer data={data} record={record} />,
      },
      {
        dataIndex: "amount",
        key: "amount",
        align: "right",
      },
    ];

    let manual_Columns = [...online_manual_Columns];
    manual_Columns.pop();
    manual_Columns.push({
      title: <TitleWrapper title={intl.formatMessage({ id: "payment_to_customer_manual" })} />,
      dataIndex: "payment_to_customer",
      align: "right",
    });

    const handleFileDownload = async () => {
      const blob = await pdf(
        <EconomyPDF
          manualPaymentData={manualPaymentData}
          onlinePaymentData={onlinePaymentData}
          organizationData={organizationData}
          summaryColumn={summaryColumn}
          summaryData={summaryData}
          intl={intl}
          startDate={this.state.date_start}
          endDate={this.state.date_end}
        />,
      ).toBlob();
      saveAs(blob, `Beat_Economy_Report_${moment(moment.now()).format("DD.MM.YYYY, HH:mm")}.pdf`);
    };

    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "economy_beat_reports" })}
          extra={[
            <FileDownloadDropdown
              key="economic-file-handler"
              handleClick={handleFileDownload}
              options={[{ label: "PDF", value: "pdf" }]}
            />,
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16} className="row-flex flex-align-end">
                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_start" className="ant-label">
                    {intl.formatMessage({ id: "date_start_order_date" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_start)}
                    onChange={(e) => this.handleDateChange(e, "date_start")}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {intl.formatMessage({ id: "date_end_order_date" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_end)}
                    disabledDate={(d) => !d || d.isAfter(moment().subtract(1, "day"))}
                    showToday={false}
                    onChange={(e) => this.handleDateChange(e, "date_end")}
                  />
                </Col>

                {this.props.beats.data.length > 1 && (
                  <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label htmlFor="select_beat" className="ant-label">
                      {intl.formatMessage({ id: "select_beat" })}
                    </label>
                    <Select
                      mode="multiple"
                      id="select_beat"
                      showSearch
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({ id: "select_beat" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleBeatChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.beat_ids}
                    >
                      {selectBeats.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Button type="primary" onClick={() => this.onFilterSubmit()} size={ANT_CONFIG.size}>
                    {intl.formatMessage({ id: "apply" })}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>
        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                className="ant-no-prewrap"
                size="small"
                columns={orgColumns}
                dataSource={organizationData}
                pagination={ANT_CONFIG.pagination}
              />
            </Col>
          </Row>
          <br />
          <br />

          <Row>
            <Col xs={24}>
              <Table
                className="ant-table-thead"
                columns={online_manual_Columns}
                dataSource={onlinePaymentData.rows || []}
                size="small"
                title={() => (
                  <div style={{ backgroundColor: "#598c51", textAlign: "center", padding: 2, fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "revenue_online_payment" })}
                  </div>
                )}
                pagination={ANT_CONFIG.pagination}
                bordered={false}
                summary={() => {
                  let summary = onlinePaymentData.summary || [];
                  return summary.length === 0 ? (
                    <></>
                  ) : (
                    <Table.Summary.Row style={{ textAlign: "right", border: "5px solid #fe213ee" }}>
                      <Table.Summary.Cell index={0}>
                        <div style={{ textAlign: "left", fontWeight: "bold" }}>SUM</div>
                      </Table.Summary.Cell>

                      {[
                        summary["revenue"],
                        summary["refunds"],
                        summary["net_revenue"],
                        summary["revenue_tax"],
                        "",
                        summary["provision"],
                        summary["provision_tax"],
                        summary["payment_to_customer"],
                      ].map((ele, index) => (
                        <Table.Summary.Cell index={index + 1} key={index}>
                          <Typography.Text strong>{ele}</Typography.Text>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  );
                }}
              />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs={24}>
              <Table
                className="ant-table-thead"
                columns={manual_Columns}
                dataSource={manualPaymentData.rows || []}
                title={() => (
                  <div style={{ backgroundColor: "#9dbf95", color: "black", textAlign: "center", fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "revenue_manual_payment" })}
                  </div>
                )}
                size="small"
                bordered={false}
                pagination={ANT_CONFIG.pagination}
                summary={() => {
                  let summary = manualPaymentData.summary || [];
                  return summary.length === 0 ? (
                    <></>
                  ) : (
                    <Table.Summary.Row style={{ textAlign: "right", border: "5px solid #fe213ee" }}>
                      <Table.Summary.Cell index={0}>
                        <div style={{ textAlign: "left", fontWeight: "bold" }}>SUM</div>
                      </Table.Summary.Cell>

                      {[
                        summary["revenue"],
                        summary["refunds"],
                        summary["net_revenue"],
                        summary["revenue_tax"],
                        "-",
                        summary["provision"],
                        summary["provision_tax"],
                        summary["payment_to_customer"],
                      ].map((ele, index) => (
                        <Table.Summary.Cell index={index + 1} key={index}>
                          <Typography.Text strong>{ele}</Typography.Text>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  );
                }}
              />
            </Col>
          </Row>
          <br />

          <Row>
            <Col xs={10}>
              <Table
                className="ant-no-prewrap"
                size="small"
                title={() => (
                  <div style={{ backgroundColor: "#456e99", color: "white", padding: 2, fontWeight: "bold" }}>
                    {intl.formatMessage({ id: "summary" })}
                  </div>
                )}
                showHeader={false}
                columns={summaryColumn}
                dataSource={summaryData}
                pagination={ANT_CONFIG.pagination}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.Reports,
  authentication: state.Auth,
  beats: state.Beats,
});
export default withRouter(injectIntl(connect(mapStateToProps)(EconomyBeatReport)));
