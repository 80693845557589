import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_RIVER_CARDS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_RIVER_CARDS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_RIVER_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.FETCH_BUY_RIVER_CARD_BUTTON_TEXT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_BUY_RIVER_CARD_BUTTON_TEXT_SUCCESS:
      return {
        ...state,
        button_text: action.payload?.data?.message,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_BUY_RIVER_CARD_BUTTON_TEXT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.EDIT_BUY_RIVER_CARD_BUTTON:
      return {
        ...state,
        loading: true,
      };

    case ACTION.EDIT_BUY_RIVER_CARD_BUTTON_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.EDIT_BUY_RIVER_CARD_BUTTON_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
