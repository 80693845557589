import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import Moment from "moment";
import { Table, DatePicker, Col, Row, PageHeader, Select, Card, Statistic, Button } from "antd";
import { extendMoment } from "moment-range";

import Spinner from "../../components/spinner";
import beatOptions from "../../services/select_beat_options";
import { downloadProductTypeAndPriceReport, fetchProductTypeAndPriceReport } from "../../redux/reports/actions";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import ANT_CONFIG from "../../constants/antconfig";
import translations from "../../constants/strings";
import downloadFile from "../../services/download_file";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";

const moment = extendMoment(Moment);

class ProductTypePrice extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      filetype: null,
      reportBy: "weeks",
      date_start: moment().subtract(4, "week").format(`${this.dateFormat}`),
      date_end: moment().subtract(1, "day").format(`${this.dateFormat}`),
      data: {
        beat_id:
          Array.isArray(this.props.beats.selected) || !this.props.beats.selected
            ? this.props.authentication.data.beat[0]
            : this.props.beats.selected,
        date_start: moment().subtract(4, "week").format(`${this.dateFormat}`),
        date_end: moment().subtract(1, "day").format(`${this.dateFormat}`),
      },
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reports.file !== this.props.reports.file && this.props.reports.file) {
      const uniqId = Math.random().toString(36).substr(2, 9);

      downloadFile(this.props.reports.file, this.state.filetype, `by-type-${uniqId}`);
    }
  }

  onFilterSubmit() {
    this.props.dispatch(selectBeat(this.state.data.beat_id));

    this.setState((prevState) => ({
      date_start: prevState.data.date_start,
      date_end: prevState.data.date_end,
    }));

    this.handleDataChange();
  }

  handleDownload = (reportType = null, filetype = "pdf") => {
    const { data } = this.props.reports;

    this.setState(
      {
        filetype,
      },
      () => {
        data.filters = {
          ...data.filters,
          filetype,
          view_type: this.state.reportBy,
        };

        this.props.dispatch(downloadProductTypeAndPriceReport(this.props.reports.data, reportType));
      },
    );
  };

  handleChangeDate(e, key) {
    this.setState((prevState) => ({
      data: { ...prevState.data, [key]: moment(e).format(`${this.dateFormat}`) },
    }));
  }

  handleBeatChange(e) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        beat_id: e,
      },
    }));
  }

  handleReportByChange(reportBy) {
    this.setState({
      reportBy,
    });
  }

  handleDataChange() {
    const data = {
      date_start: this.state.data.date_start,
      date_end: this.state.data.date_end,
    };
    if (this.state.data.beat_id) {
      data.beat_id = this.state.data.beat_id;
    }

    this.props.dispatch(fetchProductTypeAndPriceReport(data));
  }

  render() {
    if (!this.props.reports.data || !this.props.beats.data || this.props.reports.loading) {
      return <Spinner />;
    }

    const data = this.props.reports.data.results || [];

    let selectBeats = [];

    if (this.props.beats.data && this.props.beats.data.length > 0) {
      selectBeats = [...selectBeats, ...beatOptions(this.props.beats, this.props.authentication)];
    }

    const selectReportBy = [
      {
        value: "weeks",
        label: this.props.intl.formatMessage({ id: "report_weeks" }),
      },
      {
        value: "days",
        label: this.props.intl.formatMessage({ id: "report_days" }),
      },
    ];
    const monthRange = moment.range(this.state.date_start, this.state.date_end);
    const weeks = [];
    const days = [];

    for (const mday of monthRange.by("days")) {
      const d = mday.week().toString().length === 1 ? `0${mday.week()}` : `${mday.week()}`;
      if (weeks.indexOf(d) === -1) {
        weeks.push(d);
      }

      days.push(mday.format("YYYY-MM-DD"));
    }

    const tableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "product_name" }),
        dataIndex: "title",
        key: "title",
        fixed: "left",
        width: 200,
      },
    ];

    if (this.state.reportBy === "weeks") {
      weeks.map((i) => {
        tableColumns.push({
          title: `${this.props.intl.formatMessage({ id: "week" })} ${i}`,
          dataIndex: `week_${i}`,
          key: `week_${i}`,
        });

        return false;
      });
    } else {
      days.map((i) => {
        tableColumns.push({
          title: moment(i).format("DD.MM.YYYY"),
          dataIndex: `day_${i}`,
          key: `day_${i}`,
        });

        return false;
      });
    }

    const tableData = [];

    if (data.fishing_cards && Object.keys(data.fishing_cards).length > 0) {
      Object.keys(data.fishing_cards).map((i) => {
        const item = data.fishing_cards[i];
        const displayData = {};
        const id = i;

        weeks.map((w) => {
          if (item.weeks[w]) {
            displayData[`week_${w}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.weeks[w]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`week_${w}`] = <strong>0</strong>;
          }

          return true;
        });

        days.map((d) => {
          if (item.days[d]) {
            displayData[`day_${d}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.days[d]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`day_${d}`] = <strong>0</strong>;
          }

          return true;
        });

        tableData.push({
          key: `fishing_cards_${id}`,
          class: "ant-design-border-top",
          title: <strong>{`${item.fishing_card_type.name}`}</strong>,
          ...displayData,
        });

        if (item.prices && Object.keys(item.prices).length > 0) {
          Object.keys(item.prices).map((i) => {
            const price = item.prices[i];
            const displayData = {};

            weeks.map((w) => {
              if (price.weeks[w]) {
                displayData[`week_${w}`] = <Statistic value={price.weeks[w]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`week_${w}`] = 0;
              }

              return true;
            });

            days.map((d) => {
              if (price.days[d]) {
                displayData[`day_${d}`] = <Statistic value={price.days[d]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`day_${d}`] = 0;
              }

              return true;
            });

            tableData.push({
              key: `${id}_${i}`,
              title: price.price,
              ...displayData,
            });

            return true;
          });

          return true;
        }

        return true;
      });
    }

    if (data.fishing_card_packages && Object.keys(data.fishing_card_packages).length > 0) {
      Object.keys(data.fishing_card_packages).map((i) => {
        const item = data.fishing_card_packages[i];
        const displayData = {};
        const id = i;

        weeks.map((w) => {
          if (item.weeks[w]) {
            displayData[`week_${w}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.weeks[w]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`week_${w}`] = <strong>0</strong>;
          }

          return true;
        });

        days.map((d) => {
          if (item.days[d]) {
            displayData[`day_${d}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.days[d]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`day_${d}`] = <strong>0</strong>;
          }

          return true;
        });

        tableData.push({
          key: `fishing_card_packages_${item.package_blueprint.type}_${id}`,
          class: "ant-design-border-top",
          title: (
            <strong>{`${
              translations.package_types[this.props.intl.locale][item.package_blueprint.type]
            } (${this.props.intl.formatMessage({
              id: "fishing_package",
            })})`}</strong>
          ),
          ...displayData,
        });

        if (item.prices && Object.keys(item.prices).length > 0) {
          Object.keys(item.prices).map((i) => {
            const price = item.prices[i];
            const displayData = {};

            weeks.map((w) => {
              if (price.weeks[w]) {
                displayData[`week_${w}`] = <Statistic value={price.weeks[w]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`week_${w}`] = 0;
              }

              return true;
            });

            days.map((d) => {
              if (price.days[d]) {
                displayData[`day_${d}`] = <Statistic value={price.days[d]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`day_${d}`] = 0;
              }

              return true;
            });

            tableData.push({
              key: `${id}_${i}`,
              title: price.price,
              ...displayData,
            });

            return true;
          });

          return true;
        }

        return true;
      });
    }

    if (data.accommodations && Object.keys(data.accommodations).length > 0) {
      Object.keys(data.accommodations).map((i) => {
        const item = data.accommodations[i];
        const displayData = {};
        const id = i;

        weeks.map((w) => {
          if (item.weeks[w]) {
            displayData[`week_${w}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.weeks[w]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`week_${w}`] = <strong>0</strong>;
          }

          return true;
        });

        days.map((d) => {
          if (item.days[d]) {
            displayData[`day_${d}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.days[d]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`day_${d}`] = <strong>0</strong>;
          }

          return true;
        });

        tableData.push({
          key: `accommodations_${id}`,
          class: "ant-design-border-top",
          title: (
            <strong>{`${item.accommodation_blueprint.name} (${this.props.intl.formatMessage({
              id: "accommodation",
            })})`}</strong>
          ),
          ...displayData,
        });

        if (item.prices && Object.keys(item.prices).length > 0) {
          Object.keys(item.prices).map((i) => {
            const price = item.prices[i];
            const displayData = {};

            weeks.map((w) => {
              if (price.weeks[w]) {
                displayData[`week_${w}`] = <Statistic value={price.weeks[w]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`week_${w}`] = 0;
              }

              return true;
            });

            days.map((d) => {
              if (price.days[d]) {
                displayData[`day_${d}`] = <Statistic value={price.days[d]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`day_${d}`] = 0;
              }

              return true;
            });

            tableData.push({
              key: `${id}_${i}`,
              title: price.price,
              ...displayData,
            });

            return true;
          });

          return true;
        }

        return true;
      });
    }

    if (data.free_packages && Object.keys(data.free_packages).length > 0) {
      Object.keys(data.free_packages).map((i) => {
        const item = data.free_packages[i];
        const displayData = {};
        const id = i;

        weeks.map((w) => {
          if (item.weeks[w]) {
            displayData[`week_${w}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.weeks[w]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`week_${w}`] = <strong>0</strong>;
          }

          return true;
        });

        days.map((d) => {
          if (item.days[d]) {
            displayData[`day_${d}`] = (
              <Statistic valueStyle={{ fontWeight: 700 }} value={item.days[d]} precision={2} groupSeparator=" " />
            );
          } else {
            displayData[`day_${d}`] = <strong>0</strong>;
          }

          return true;
        });

        tableData.push({
          key: `free_packages_${id}`,
          class: "ant-design-border-top",
          title: <strong>{`${item.free_package_name} (${this.props.intl.formatMessage({ id: "pack" })})`}</strong>,
          ...displayData,
        });

        if (item.prices && Object.keys(item.prices).length > 0) {
          Object.keys(item.prices).map((i) => {
            const price = item.prices[i];
            const displayData = {};

            weeks.map((w) => {
              if (price.weeks[w]) {
                displayData[`week_${w}`] = <Statistic value={price.weeks[w]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`week_${w}`] = 0;
              }

              return true;
            });

            days.map((d) => {
              if (price.days[d]) {
                displayData[`day_${d}`] = <Statistic value={price.days[d]} precision={2} groupSeparator=" " />;
              } else {
                displayData[`day_${d}`] = 0;
              }

              return true;
            });

            tableData.push({
              key: `${id}_${i}`,
              title: price.price,
              ...displayData,
            });

            return true;
          });

          return true;
        }

        return true;
      });
    }

    if (data.season_cards && Object.keys(data.season_cards).length > 0) {
      Object.keys(data.season_cards).map((i) => {
        const item = data.season_cards[i];
        const displayData = {};
        const id = i;

        weeks.map((w) => {
          if (item.weeks[w]) {
            displayData[`week_${w}`] = <strong>{item.weeks[w]}</strong>;
          } else {
            displayData[`week_${w}`] = <strong>0</strong>;
          }

          return true;
        });

        days.map((d) => {
          if (item.days[d]) {
            displayData[`day_${d}`] = <strong>{item.days[d]}</strong>;
          } else {
            displayData[`day_${d}`] = <strong>0</strong>;
          }

          return true;
        });

        tableData.push({
          key: `season_cards_${id}`,
          class: "ant-design-border-top",
          title: <strong>{`${i} (${this.props.intl.formatMessage({ id: "season_cards" })})`}</strong>,
          ...displayData,
        });

        if (item.prices && Object.keys(item.prices).length > 0) {
          Object.keys(item.prices).map((i) => {
            const price = item.prices[i];
            const displayData = {};

            weeks.map((w) => {
              if (price.weeks[w]) {
                displayData[`week_${w}`] = price.weeks[w];
              } else {
                displayData[`week_${w}`] = 0;
              }

              return true;
            });

            days.map((d) => {
              if (price.days[d]) {
                displayData[`day_${d}`] = price.days[d];
              } else {
                displayData[`day_${d}`] = 0;
              }

              return true;
            });

            tableData.push({
              key: `${id}_${i}`,
              title: price.price,
              ...displayData,
            });

            return true;
          });

          return true;
        }

        return true;
      });
    }

    if (data.sums && Object.keys(data.sums).length > 0) {
      const item = data.sums;
      const displayData = {};

      weeks.map((w) => {
        if (item.weeks[w]) {
          displayData[`week_${w}`] = (
            <Statistic valueStyle={{ fontWeight: 700 }} value={item.weeks[w]} precision={2} groupSeparator=" " />
          );
        } else {
          displayData[`week_${w}`] = <strong>0</strong>;
        }

        return true;
      });

      days.map((d) => {
        if (item.days[d]) {
          displayData[`day_${d}`] = (
            <Statistic valueStyle={{ fontWeight: 700 }} value={item.days[d]} precision={2} groupSeparator=" " />
          );
        } else {
          displayData[`day_${d}`] = <strong>0</strong>;
        }

        return true;
      });

      tableData.push({
        key: "sold_total",
        class: "ant-design-border-all",
        title: <strong>{this.props.intl.formatMessage({ id: "sold_total" })}</strong>,
        ...displayData,
      });
    }

    const scrollX = this.state.reportBy === "weeks" ? weeks.length * 130 + 200 : days.length * 130 + 200;

    return (
      <div>
        <PageHeader
          title={this.props.intl.formatMessage({ id: "beat_product_type_price" })}
          extra={[<FileDownloadDropdown key="1" handleClick={this.handleDownload} loading={this.props.reports.file_loading} />]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16} className="row-flex flex-align-end">
                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_start" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_start_order_date" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_start)}
                    onChange={(e) => this.handleChangeDate(e, "date_start")}
                    showToday={false}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_end_order_date" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_end)}
                    onChange={(e) => this.handleChangeDate(e, "date_end")}
                    disabledDate={(d) => !d || d.isAfter(moment().subtract(1, "day"))}
                    showToday={false}
                  />
                </Col>

                {this.props.beats.data.length > 1 && (
                  <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label htmlFor="select_beat" className="ant-label">
                      {this.props.intl.formatMessage({ id: "select_beat" })}
                    </label>
                    <Select
                      id="select_beat"
                      showSearch
                      disabled={this.props.reports.file_loading}
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleBeatChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.data.beat_id}
                    >
                      {selectBeats.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="select_reportby" className="ant-label">
                    {this.props.intl.formatMessage({ id: "select_reportby" })}
                  </label>
                  <Select
                    id="select_reportby"
                    showSearch
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({ id: "select_reportby" })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.handleReportByChange(e)}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={this.state.reportBy}
                  >
                    {selectReportBy.map((i, key) => (
                      <Select.Option key={key} value={i.value}>
                        {i.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Button
                    disabled={this.props.reports.file_loading}
                    type="primary"
                    onClick={() => this.onFilterSubmit()}
                    loading={this.props.reports.loading || this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                  >
                    {this.props.intl.formatMessage({ id: "apply" })}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                className="ant-no-prewrap"
                rowClassName={(record) => record.class || ""}
                bordered
                columns={tableColumns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: scrollX }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.Reports,
  authentication: state.Auth,
  beats: state.Beats,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ProductTypePrice)));
