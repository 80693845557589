import React from "react";
import { Button, Row, Col, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectInput from "../select";

class OptionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: ""
    };
  }

  onNewClick() {
    if (this.props.onNewOption && this.state.selected) {
      this.props.onNewOption(this.state.selected);
    }
  }

  onDeleteClick(i) {
    if (this.props.onRemoveOption) {
      this.props.onRemoveOption(i);
    }
  }

  render() {
    if (this.props.options.length === 0) {
      return <Input type="text" disabled defaultValue={this.props.intl.formatMessage({ id: "no_options_found" })} />;
    }

    return (
      <div className="option-list">
        <Row className="option-select">
          {this.props.label ? (
            <Col xs={12}>
              <Label className="col-form-label">{this.props.label}</Label>
            </Col>
          ) : null}
          <Col xs={12} className="option-select-col">
            <SelectInput
              id="user_active"
              name="user_active"
              intl={this.props.intl}
              value={this.state.selected}
              onChange={e => this.setState({ selected: e })}
              options={this.props.options}
            />
            <Button type="button" onClick={() => this.onNewClick()} disabled={!this.state.selected}>
              <FontAwesomeIcon icon={faPlus} style={{ fontSize: 14 }} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="option-container">
            {this.props.values &&
              this.props.values.map(value =>
                this.props.options.find(option => option.value === value) ? (
                  <Col xs={12} className="option-item" key={value}>
                    <span className="option-label">{this.props.options.find(option => option.value === value).label}</span>
                    <Button color="danger" type="button" onClick={() => this.onDeleteClick(value)}>
                      <FontAwesomeIcon icon={faTimes} style={{ fontSize: 14 }} />
                    </Button>
                  </Col>
                ) : null
              )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default OptionList;
