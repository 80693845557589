import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Button, Popconfirm, Modal, Row, Col, Input, Select, DatePicker, Alert } from "antd";
import { add as addAccommodation, edit as editAccommodation, remove as removeAccommodation } from "../../../redux/accommodation/actions";
import ANT_CONFIG from "../../../constants/antconfig";
import { UTCDateToLocalTime } from '../../../services/utc_converter';

class ModalAddAccommodation extends React.Component {
  constructor(props) {
    super(props);

    const isNew = props.modalData.items.length === 0;
    let publishDateStart = null;
    let publishDateEnd = null;
    let prices = [];
    let priceTypes = [];
    let hasPriceAmount = false;

    if (!isNew) {
      publishDateStart = props.modalData.items[0].publish_date_start !== "1970-01-01 00:00:00" ? props.modalData.items[0].publish_date_start : null;
      publishDateEnd = props.modalData.items[0].publish_date_end !== "3999-12-31 00:00:00" ? props.modalData.items[0].publish_date_end : null;
      prices = props.modalData.items?.[0]?.price_amounts;
      hasPriceAmount = props.modalData.items[0].price_amounts?.length > 0;
      priceTypes = props.accommodation.blueprint[props.selectedBeat].filter(i => i.id === props.modalData.items[0].blueprint_id)?.[0]?.price_types;
    }

    this.state = {
      modal: true,
      submitted: false,
      isNew,
      hasPriceAmount,
      item_id: !isNew ? props.modalData.items[0].id : "",
      beat_id: props.selectedBeat,
      price_types: priceTypes,
      data: {
        blueprint_id: !isNew ? props.modalData.items[0].blueprint_id : "",
        date_start: props.modalData.startDate,
        date_end: props.modalData.endDate,
        publish_date_start: publishDateStart,
        publish_date_end: publishDateEnd,
        prices
      }
    };
  }

  componentDidUpdate() {
    if (!this.props.accommodation.loading && !this.props.accommodation.error && this.state.submitted) {
      this.onCancel();
    }
  }

  onChangeValue(newState) {
    this.setState(prevState => ({ data: { ...prevState.data, ...newState } }));
  }

  onDelete = () => {
    this.setState({
      submitted: true
    });

    this.props.dispatch(removeAccommodation({ accommodation_ids: [this.state.item_id] }, this.props.calendarData));
  };

  onPriceChange(amount, id) {
    const item = this.state.data.prices.filter(i => i.price_type_id === id)[0];

    if (item) {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          prices: [
            ...prevState.data.prices.filter(i => i.price_type_id !== id),
            {
              ...item,
              amount
            }
          ]
        }
      }));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          prices: [
            ...prevState.data.prices.filter(i => i.price_type_id !== id),
            {
              price_type_id: id,
              amount
            }
          ]
        }
      }));
    }
  }

  onExtraPriceChange(extra, id) {
    const item = this.state.data.prices.filter(i => i.price_type_id === id)[0];

    if (item) {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          prices: [
            ...prevState.data.prices.filter(i => i.price_type_id !== id),
            {
              ...item,
              extra
            }
          ]
        }
      }));
    } else {
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          prices: [
            ...prevState.data.prices.filter(i => i.price_type_id !== id),
            {
              price_type_id: id,
              extra
            }
          ]
        }
      }));
    }
  }

  onSubmit = () => {
    if (!this.saveBtnValidation()) {
      return false;
    }

    this.setState({
      submitted: true
    });

    if (this.state.isNew) {
      this.props.dispatch(addAccommodation([this.state.data], this.props.calendarData));
    } else {
      const { items } = this.props.modalData;
      const data = items.map(item => {
        return {
          id: item.id,
          publish_date_start: this.state.data.publish_date_start,
          publish_date_end: this.state.data.publish_date_end,
          update_price_amounts: {
            ...item.price_amounts.map(i => {
              const price = this.state.data.prices.filter(k => k.price_type_id === i.price_type_id)[0];

              return {
                ...i,
                amount: price.amount,
                extra: price.extra
              };
            })
          }
        };
      });

      this.props.dispatch(editAccommodation(data, this.props.calendarData));
    }
  };

  onCancel = () => {
    this.props.toggleModal();
  };

  onChangeAccommodationType(id) {
    const beatId = this.state.beat_id;
    const priceTypes = this.props.accommodation.blueprint[beatId].filter(i => i.id === id)?.[0]?.price_types;

    this.setState(prevState => ({
      price_types: priceTypes || [],
      data: {
        ...prevState.data,
        blueprint_id: id
      }
    }));
  }

  saveBtnValidation() {
    if (typeof this.state.hasPriceAmount === "undefined") {
      return false;
    }

    if (!this.state.isNew && moment(this.state.data.date_end) < moment()) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <Modal
        width={600}
        title={
          this.state.isNew
            ? this.props.intl.formatMessage({ id: "add_availability" })
            : this.props.accommodation.blueprint[this.state.beat_id].filter(i => i.id === this.state.data.blueprint_id)?.[0]?.name
        }
        visible={this.state.modal}
        onCancel={this.onCancel}
        footer={[
          !this.state.isNew && typeof this.state.hasPriceAmount !== "undefined" && (
            <Popconfirm
              title={this.props.intl.formatMessage({ id: "confirm_delete_question" })}
              onConfirm={this.onDelete}
              okText={this.props.intl.formatMessage({ id: "remove" })}
              cancelText={this.props.intl.formatMessage({ id: "back" })}
              key="remove"
            >
              <Button
                size={ANT_CONFIG.size}
                type="danger"
                disabled={this.props.accommodation.loading}
                style={{ marginRight: this.props.accommodation.loading ? 8 : 0 }}
              >
                {this.props.intl.formatMessage({ id: "remove" })}
              </Button>
            </Popconfirm>
          ),
          <Button
            size={ANT_CONFIG.size}
            key="submit"
            disabled={!this.saveBtnValidation()}
            loading={this.props.accommodation.loading}
            type="primary"
            onClick={this.onSubmit}
          >
            {this.props.intl.formatMessage({ id: "save" })}
          </Button>
        ]}
      >
        <Row gutter={16}>
          {this.state.isNew && (
            <Col xs={24}>
              <label className="ant-label" htmlFor="select_accommodation">
                {this.props.intl.formatMessage({ id: "select_accommodation" })}
              </label>
              <Select
                id="select_accommodation"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={`${this.props.intl.formatMessage({ id: "select_accommodation" })}`}
                onChange={e => this.onChangeAccommodationType(e)}
              >
                {this.props.selectBlueprint[this.state.beat_id].map(i => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}

          {!this.state.isNew && !this.state.hasPriceAmount && (
            <Col xs={24} style={{ paddingBottom: 25 }}>
              <Alert
                message={this.props.intl.formatMessage({ id: "warning" })}
                description={this.props.intl.formatMessage({ id: "you_need_to_update_pricetype" })}
                type="warning"
                showIcon
              />
            </Col>
          )}

          <Col xs={12} style={{ paddingTop: this.state.isNew ? 20 : 0 }}>
            <label htmlFor="date_start" className="ant-label">
              {this.props.intl.formatMessage({ id: "date_start" })}
            </label>
            <DatePicker
              style={{ width: "100%" }}
              id="date_start"
              size={ANT_CONFIG.size}
              allowClear={false}
              format={ANT_CONFIG.datepicker_format}
              value={moment(this.state.data.date_start)}
              disabled={!this.state.isNew}
              onChange={e => this.onChangeValue({ date_start: e.format(ANT_CONFIG.date_format) })}
            />
          </Col>

          <Col xs={12} style={{ paddingTop: this.state.isNew ? 20 : 0 }}>
            <label htmlFor="date_end" className="ant-label">
              {this.props.intl.formatMessage({ id: "date_end" })}
            </label>
            <DatePicker
              style={{ width: "100%" }}
              id="date_end"
              size={ANT_CONFIG.size}
              allowClear={false}
              format={ANT_CONFIG.datepicker_format}
              value={moment(this.state.data.date_end)}
              disabled={!this.state.isNew}
              onChange={e => this.onChangeValue({ date_end: e.format(ANT_CONFIG.date_format) })}
            />
          </Col>

          <Col xs={24}>
            {this.state.isNew && this.state.price_types.length > 0 && (
              <div className="prices-modal-container">
                <label className="ant-label">{this.props.intl.formatMessage({ id: "add_price" })}</label>
                {this.state.price_types?.map(i => (
                  <Row type="flex" justify="space-between" align="middle" key={i.id} style={{ width: "100%", paddingTop: 20 }} gutter={16}>
                    <Col xs={24} style={{ paddingBottom: 5 }}>
                      {i.name}
                    </Col>
                    <Col xs={12}>
                      <Input
                        style={{ marginBottom: 5 }}
                        type="number"
                        name="price"
                        id="price"
                        size={ANT_CONFIG.size}
                        placeholder={this.props.intl.formatMessage({ id: "price" })}
                        onChange={e => this.onPriceChange(e.target.value, i.id)}
                      />
                      <small>{this.props.intl.formatMessage({ id: "price" })}</small>
                    </Col>
                    <Col xs={12}>
                      <Input
                        style={{ marginBottom: 5 }}
                        type="number"
                        name="price"
                        id="price"
                        size={ANT_CONFIG.size}
                        placeholder={this.props.intl.formatMessage({ id: "extra_per_person" })}
                        onChange={e => this.onExtraPriceChange(e.target.value, i.id)}
                      />
                      <small>{this.props.intl.formatMessage({ id: "extra_per_person" })}</small>
                    </Col>
                  </Row>
                ))}
              </div>
            )}

            {!this.state.isNew && this.state.hasPriceAmount && this.state.data.prices && (
              <div className="prices-modal-container">
                <label className="ant-label">{this.props.intl.formatMessage({ id: "add_price" })}</label>
                {this.state.data.prices
                  .sort((a, b) => a.id - b.id)
                  .map(i => (
                    <Row type="flex" justify="space-between" align="middle" key={i.id} style={{ width: "100%", paddingTop: 20 }} gutter={16}>
                      <Col xs={24} style={{ paddingBottom: 5 }}>
                        {i.price_type.name}
                      </Col>
                      <Col xs={12}>
                        <Input
                          style={{ marginBottom: 5 }}
                          type="number"
                          name="price"
                          id="price"
                          size={ANT_CONFIG.size}
                          placeholder={this.props.intl.formatMessage({ id: "price" })}
                          onChange={e => this.onPriceChange(e.target.value, i.price_type.id)}
                          defaultValue={i.amount}
                        />
                        <small>{this.props.intl.formatMessage({ id: "price" })}</small>
                      </Col>
                      <Col xs={12}>
                        <Input
                          style={{ marginBottom: 5 }}
                          type="number"
                          name="price"
                          id="price"
                          size={ANT_CONFIG.size}
                          placeholder={this.props.intl.formatMessage({ id: "extra_per_person" })}
                          onChange={e => this.onExtraPriceChange(e.target.value, i.price_type.id)}
                          defaultValue={i.extra}
                        />
                        <small>{this.props.intl.formatMessage({ id: "extra_per_person" })}</small>
                      </Col>
                    </Row>
                  ))}
              </div>
            )}
          </Col>

          <Col xs={12} style={{ paddingTop: 20 }}>
            <label htmlFor="publish_date_start" className="ant-label">
              {this.props.intl.formatMessage({ id: "publish_date_start" })}
            </label>
            <DatePicker
              style={{ width: "100%" }}
              id="publish_date_start"
              size={ANT_CONFIG.size}
              format={ANT_CONFIG.datepicker_time_format}
              value={this.state.data.publish_date_start ? UTCDateToLocalTime(this.state.data.publish_date_start) : null}
              showTime
              onChange={e =>
                this.onChangeValue({
                  publish_date_start: e ? moment.utc(e).format(ANT_CONFIG.datetime_format) : null
                })
              }
            />
          </Col>

          <Col xs={12} style={{ paddingTop: 20 }}>
            <label htmlFor="publish_date_end" className="ant-label">
              {this.props.intl.formatMessage({ id: "publish_date_end" })}
            </label>
            <DatePicker
              style={{ width: "100%" }}
              id="publish_date_end"
              size={ANT_CONFIG.size}
              format={ANT_CONFIG.datepicker_time_format}
              value={this.state.data.publish_date_end ? UTCDateToLocalTime(this.state.data.publish_date_end) : null}
              showTime
              onChange={e =>
                this.onChangeValue({
                  publish_date_end: e ? moment.utc(e).format(ANT_CONFIG.datetime_format) : null
                })
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  accommodation: state.Accommodation,
  taxes: state.Taxes
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddAccommodation)));
