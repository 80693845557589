import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import Moment from "moment";
import {Table, DatePicker, Col, Row, PageHeader, Select, Card, Statistic, Button} from "antd";
import {extendMoment} from "moment-range";

import Spinner from "../../components/spinner";
import beatOptions from "../../services/select_beat_options";
import {fetchSellerPaymentMethodReport, downloadSellerPaymentMethodReport} from "../../redux/reports/actions";
import {fetch as fetchBeats, select as selectBeat} from "../../redux/beats/actions";
import ANT_CONFIG from "../../constants/antconfig";
import downloadFile from "../../services/download_file";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";

const moment = extendMoment(Moment);

class SellerPaymentMethod extends React.Component {
    constructor(props) {
        super(props);
        this.dateFormat = "YYYY-MM-DD";
        this.state = {
            filetype: null,
            reportBy: "weeks",
            date_start: moment()
                .subtract(4, "week")
                .format(`${this.dateFormat}`),
            date_end: moment().subtract(1, "day").format(`${this.dateFormat}`),
            data: {
                beat_id:  Array.isArray(this.props.beats.selected) || !this.props.beats.selected
                ? this.props.authentication.data.beat[0]
                : this.props.beats.selected,
                date_start: moment()
                    .subtract(4, "week")
                    .format(`${this.dateFormat}`),
                date_end: moment().subtract(1, "day").format(`${this.dateFormat}`)
            }
        };
    }

    componentDidMount() {
        if (!this.props.beats.data) {
            this.props.dispatch(fetchBeats());
        }

        this.handleDataChange();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reports.file !== this.props.reports.file && this.props.reports.file) {
            const uniqId = Math.random()
                .toString(36)
                .substr(2, 9);

            downloadFile(this.props.reports.file, this.state.filetype, `by-seller-${uniqId}`);
        }
    }

    onFilterSubmit() {
        this.props.dispatch(selectBeat(this.state.data.beat_id));

        this.setState(prevState => ({
            date_start: prevState.data.date_start,
            date_end: prevState.data.date_end
        }));

        this.handleDataChange();
    }

    handleDownload = (reportType = null, filetype = "pdf") => {
        const {data} = this.props.reports;

        this.setState(
            {
                filetype
            },
            () => {
                data.filters = {
                    ...data.filters,
                    filetype,
                    view_type: this.state.reportBy
                };

                this.props.dispatch(downloadSellerPaymentMethodReport(this.props.reports.data, reportType));
            }
        );
    };

    handleDataChange() {
        const data = {
            date_start: this.state.data.date_start,
            date_end: this.state.data.date_end
        };
        if (this.state.data.beat_id) {
            data.beat_id = this.state.data.beat_id;
        }

        this.props.dispatch(fetchSellerPaymentMethodReport(data));
    }

    handleChangeDate(e, key) {
        this.setState(prevState => ({
            data: {...prevState.data, [key]: moment(e).format(`${this.dateFormat}`)}
        }));
    }

    handleBeatChange(e) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                beat_id: e
            }
        }));
    }

    handleReportByChange(reportBy) {
        this.setState({
            reportBy
        });
    }

    render() {
        if (!this.props.reports.data || !this.props.beats.data || this.props.reports.loading) {
            return <Spinner/>;
        }

        const data = this.props.reports.data.results || [];

        let selectBeats = [];

        if (this.props.beats.data && this.props.beats.data.length > 0) {
            selectBeats = [...selectBeats, ...beatOptions(this.props.beats, this.props.authentication)];
        }

        const selectReportBy = [
            {
                value: "weeks",
                label: this.props.intl.formatMessage({id: "report_weeks"})
            },
            {
                value: "days",
                label: this.props.intl.formatMessage({id: "report_days"})
            }
        ];
        const monthRange = moment.range(this.state.date_start, this.state.date_end);
        const weeks = [];
        const days = [];

        for (const mday of monthRange.by("days")) {
            const d = mday.week().toString().length === 1 ? `0${mday.week()}` : `${mday.week()}`;
            if (weeks.indexOf(d) === -1) {
                weeks.push(d);
            }

            days.push(mday.format("YYYY-MM-DD"));
        }

        const tableColumnsSaleMethod = [
            {
                title: this.props.intl.formatMessage({id: "sales_method"}),
                dataIndex: "title",
                key: "title",
                fixed: "left",
                width: 200
            },
            {
                title: this.props.intl.formatMessage({id: "report_salesman_nets_manual"}),
                dataIndex: "nets_manual",
                key: "nets_manual"
            },
            {
                title: this.props.intl.formatMessage({id: "report_salesman_manual"}),
                dataIndex: "manual",
                key: "manual"
            },
            {
                title: this.props.intl.formatMessage({id: "report_salesman_nets"}),
                dataIndex: "nets",
                key: "nets"
            },
            {
                title: this.props.intl.formatMessage({id: "report_salesman_total_sale"}),
                dataIndex: "total",
                key: "total"
            }
        ];
        const tableDataSaleMethod = [];
        if (data.by_method && Object.keys(data.by_method).length > 0) {
            Object.keys(data.by_method).map(i => {
                const item = data.by_method[i];

                tableDataSaleMethod.push({
                    key: `${item.user ? item.user.id : "web"}_${i}`,
                    title: item.user ? `${item.user.first_name} ${item.user.last_name}` : this.props.intl.formatMessage({id: "web_sale"}),
                    manual: item.manual && item.manual.total ?
                        <Statistic value={item.manual.total} precision={2} groupSeparator=" "/> : 0,
                    nets_manual: item.nets_manual && item.nets_manual.total ?
                        <Statistic value={item.nets_manual.total} precision={2} groupSeparator=" "/> : 0,
                    nets: item.nets && item.nets.total ?
                        <Statistic value={item.nets.total} precision={2} groupSeparator=" "/> : 0,
                    total: item.total ? <Statistic value={item.total} precision={2} groupSeparator=" "/> : 0
                });

                return true;
            });
        }
        if (data.payment_methods_totals && Object.keys(data.payment_methods_totals).length > 0) {
            const item = data.payment_methods_totals;

            if (data.by_method && Object.keys(data.by_method).length > 0) {
                tableDataSaleMethod.push({
                    key: "payment_methods_sum",
                    class: "ant-design-border-top",
                    title: <strong>{this.props.intl.formatMessage({id: "payment_methods_sum"})}</strong>,
                    manual: item.manual ? <Statistic valueStyle={{fontWeight: 700}} value={item.manual} precision={2}
                                                     groupSeparator=" "/> : <strong>0</strong>,
                    nets_manual: item.nets_manual ? (
                        <Statistic valueStyle={{fontWeight: 700}} value={item.nets_manual} precision={2}
                                   groupSeparator=" "/>
                    ) : (
                        <strong>0</strong>
                    ),
                    nets: item.nets ?
                        <Statistic valueStyle={{fontWeight: 700}} value={item.nets} precision={2} groupSeparator=" "/> :
                        <strong>0</strong>,
                    total: item.total ? <Statistic valueStyle={{fontWeight: 700}} value={item.total} precision={2}
                                                   groupSeparator=" "/> : <strong>0</strong>
                });
            }
        }

        const tableColumns = [
            {
                title: this.props.intl.formatMessage({id: "product_name"}),
                dataIndex: "title",
                key: "title",
                fixed: "left",
                width: 200
            }
        ];

        if (this.state.reportBy === "weeks") {
            weeks.map(i => {
                tableColumns.push({
                    title: `${this.props.intl.formatMessage({id: "week"})} ${i}`,
                    dataIndex: `week_${i}`,
                    key: `week_${i}`
                });

                return false;
            });
        } else {
            days.map(i => {
                tableColumns.push({
                    title: moment(i).format("DD.MM.YYYY"),
                    dataIndex: `day_${i}`,
                    key: `day_${i}`
                });

                return false;
            });
        }

        const tableData = [];

        if (data.by_seller && Object.keys(data.by_seller).length > 0) {
            Object.keys(data.by_seller).map(i => {
                const item = data.by_seller[i];
                const displayData = {};
                const id = i;

                weeks.map(w => {
                    if (item.weeks[w] && item.weeks[w].total) {
                        displayData[`week_${w}`] =
                            <Statistic valueStyle={{fontWeight: 700}} value={item.weeks[w].total} precision={2}
                                       groupSeparator=" "/>;
                    } else {
                        displayData[`week_${w}`] = <strong>0</strong>;
                    }

                    return true;
                });

                days.map(d => {
                    if (item.days[d] && item.days[d].total) {
                        displayData[`day_${d}`] =
                            <Statistic valueStyle={{fontWeight: 700}} value={item.days[d].total} precision={2}
                                       groupSeparator=" "/>;
                    } else {
                        displayData[`day_${d}`] = <strong>0</strong>;
                    }

                    return true;
                });

                tableData.push({
                    key: `by_seller_${id}`,
                    class: "ant-design-border-top",
                    title:
                        <strong>{item.user ? `${item.user.first_name} ${item.user.last_name}` : this.props.intl.formatMessage({id: "web_sale"})}</strong>,
                    ...displayData
                });

                if (item.methods && Object.keys(item.methods).length > 0) {
                    Object.keys(item.methods).map(i => {
                        const method = item.methods[i];
                        const displayData = {};

                        weeks.map(w => {
                            if (method.weeks[w]) {
                                displayData[`week_${w}`] =
                                    <Statistic value={method.weeks[w]} precision={2} groupSeparator=" "/>;
                            } else {
                                displayData[`week_${w}`] = 0;
                            }

                            return true;
                        });

                        days.map(d => {
                            if (method.days[d]) {
                                displayData[`day_${d}`] =
                                    <Statistic value={method.days[d]} precision={2} groupSeparator=" "/>;
                            } else {
                                displayData[`day_${d}`] = 0;
                            }

                            return true;
                        });

                        tableData.push({
                            key: `by_seller_${id}_${i}`,
                            title: this.props.intl.formatMessage({id: `report_salesman_method_${i}`}),
                            ...displayData
                        });

                        return true;
                    });

                    return true;
                }

                return true;
            });
        }

        if (data.sum_by_week && Object.keys(data.sum_by_week).length > 0 && (data.sum_by_day && Object.keys(data.sum_by_day).length > 0)) {
            const displayData = {};

            weeks.map(w => {
                if (data.sum_by_week[w]) {
                    displayData[`week_${w}`] =
                        <Statistic valueStyle={{fontWeight: 700}} value={data.sum_by_week[w]} precision={2}
                                   groupSeparator=" "/>;
                } else {
                    displayData[`week_${w}`] = <strong>0</strong>;
                }

                return true;
            });

            days.map(d => {
                if (data.sum_by_day[d]) {
                    displayData[`day_${d}`] =
                        <Statistic valueStyle={{fontWeight: 700}} value={data.sum_by_day[d]} precision={2}
                                   groupSeparator=" "/>;
                } else {
                    displayData[`day_${d}`] = <strong>0</strong>;
                }

                return true;
            });

            tableData.push({
                key: "by_salesman_weeks_days_sold_total",
                class: "ant-design-border-all",
                title: <strong>{this.props.intl.formatMessage({id: "sold_total"})}</strong>,
                ...displayData
            });
        }

        const scrollX = this.state.reportBy === "weeks" ? weeks.length * 130 + 200 : days.length * 130 + 200;

        return (
            <div>
                <PageHeader
                    title={this.props.intl.formatMessage({id: "seller_payment_method"})}
                    extra={[<FileDownloadDropdown key="1" handleClick={this.handleDownload}
                                                  loading={this.props.reports.file_loading}/>]}
                >
                    <Row style={{marginTop: 15}}>
                        <Col xs={24}>
                            <Row gutter={16} className="row-flex flex-align-end">
                                <Col xs={12} sm={6} md={6} style={{marginTop: 10, marginBottom: 10, maxWidth: 200}}>
                                    <label htmlFor="date_start" className="ant-label">
                                        {this.props.intl.formatMessage({id: "date_start_order_date"})}
                                    </label>
                                    <DatePicker
                                        id="date_start"
                                        disabled={this.props.reports.file_loading}
                                        size={ANT_CONFIG.size}
                                        allowClear={false}
                                        format={ANT_CONFIG.datepicker_format}
                                        value={moment(this.state.data.date_start)}
                                        onChange={e => this.handleChangeDate(e, "date_start")}
                                        showToday={false}
                                    />
                                </Col>

                                <Col xs={12} sm={6} md={6} style={{marginTop: 10, marginBottom: 10, maxWidth: 200}}>
                                    <label htmlFor="date_end" className="ant-label">
                                        {this.props.intl.formatMessage({id: "date_end_order_date"})}
                                    </label>
                                    <DatePicker
                                        id="date_end"
                                        disabled={this.props.reports.file_loading}
                                        size={ANT_CONFIG.size}
                                        allowClear={false}
                                        format={ANT_CONFIG.datepicker_format}
                                        value={moment(this.state.data.date_end)}
                                        onChange={e => this.handleChangeDate(e, "date_end")}
                                        disabledDate={d => !d || d.isAfter(moment().subtract(1, "day"))}
                                        showToday={false}
                                    />
                                </Col>

                                {this.props.beats.data.length > 1 && (
                                    <Col xs={24} sm={6} md={6} style={{marginTop: 10, marginBottom: 10, maxWidth: 400}}>
                                        <label htmlFor="select_beat" className="ant-label">
                                            {this.props.intl.formatMessage({id: "select_beat"})}
                                        </label>
                                        <Select
                                            id="select_beat"
                                            showSearch
                                            disabled={this.props.reports.file_loading}
                                            size={ANT_CONFIG.size}
                                            style={{width: "100%"}}
                                            placeholder={`${this.props.intl.formatMessage({id: "select_beat"})}`}
                                            optionFilterProp="children"
                                            onChange={e => this.handleBeatChange(e)}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            value={this.state.data.beat_id}
                                        >
                                            {selectBeats.map((i, key) => (
                                                <Select.Option key={key} value={i.value}>
                                                    {i.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                )}

                                <Col xs={24} sm={6} md={6} style={{marginTop: 10, marginBottom: 10, maxWidth: 200}}>
                                    <label htmlFor="select_reportby" className="ant-label">
                                        {this.props.intl.formatMessage({id: "select_reportby"})}
                                    </label>
                                    <Select
                                        id="select_reportby"
                                        showSearch
                                        disabled={this.props.reports.file_loading}
                                        size={ANT_CONFIG.size}
                                        style={{width: "100%"}}
                                        placeholder={`${this.props.intl.formatMessage({id: "select_reportby"})}`}
                                        optionFilterProp="children"
                                        onChange={e => this.handleReportByChange(e)}
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        value={this.state.reportBy}
                                    >
                                        {selectReportBy.map((i, key) => (
                                            <Select.Option key={key} value={i.value}>
                                                {i.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col xs={24} sm={6} md={6} style={{marginTop: 10, marginBottom: 10}}>
                                    <Button
                                        type="primary"
                                        disabled={this.props.reports.file_loading}
                                        onClick={() => this.onFilterSubmit()}
                                        loading={this.props.reports.loading || this.props.reports.file_loading}
                                        size={ANT_CONFIG.size}
                                    >
                                        {this.props.intl.formatMessage({id: "apply"})}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PageHeader>

                <Card style={{marginTop: 20, marginBottom: 20}}>
                    <Row>
                        <Col xs={24}>
                            <Table
                                className="ant-no-prewrap"
                                rowClassName={record => record.class || ""}
                                bordered
                                columns={tableColumns}
                                dataSource={tableData}
                                pagination={false}
                                scroll={{x: scrollX}}
                            />
                        </Col>
                    </Row>
                </Card>

                <Card style={{marginTop: 20, marginBottom: 20}}>
                    <Row>
                        <Col xs={24}>
                            <Table
                                className="ant-no-prewrap"
                                rowClassName={record => record.class || ""}
                                bordered
                                columns={tableColumnsSaleMethod}
                                dataSource={tableDataSaleMethod}
                                pagination={false}
                                scroll={{x: 4 * 130 + 200}}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    reports: state.Reports,
    authentication: state.Auth,
    beats: state.Beats
});

export default withRouter(injectIntl(connect(mapStateToProps)(SellerPaymentMethod)));
