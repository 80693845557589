import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  pageId: null
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_PAGE:
      return {
        ...state,
        loading: true,
        pageId: null
      };

    case ACTION.FETCH_PAGE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false
      };

    case ACTION.FETCH_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.STORE_PAGE:
      return {
        ...state,
        pageId: null,
        loading: true
      };

    case ACTION.STORE_PAGE_SUCCESS:
      return {
        ...state,
        pageId: action.payload.data.pageId,
        loading: false,
        error: false
      };

    case ACTION.STORE_PAGE_FAILURE:
      return {
        ...state,
        pageId: null,
        loading: false,
        error: true
      };

    case ACTION.UPDATE_PAGE:
      return {
        ...state,
        loading: true
      };

    case ACTION.UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.UPDATE_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.UPDATE_IMAGE_PAGE:
      return {
        ...state,
        loading: true
      };

    case ACTION.UPDATE_IMAGE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case ACTION.UPDATE_IMAGE_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
}
