import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Select, Input } from "antd";

import { add as addDisinfection } from "../../redux/disinfection/actions";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import Spinner from "../../components/spinner";
import ANT_CONFIG from "../../constants/antconfig";
import riverOptionsFromStations from "../../services/select_river_options_from_stations";

class DisinfectionAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        station_id: undefined,
        river_id: this.props.authentication.data.stations[0] ? this.props.authentication.data.stations[0].river_id : null,
        user_id: 0,
        external_id: null,
      },
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
  }

  onSave(e) {
    e.preventDefault();
    this.props.dispatch(addDisinfection(this.state.data));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  handleRiverChange(e) {
    this.props.dispatch(selectRiver(e));
    this.onChangeValue({ river_id: e, station_id: undefined });
  }

  render() {
    if (this.props.rivers.loading || !this.props.rivers.data) {
      return <Spinner />;
    }

    const selectRivers = riverOptionsFromStations(this.props.rivers, this.props.authentication);
    const selectStations = this.props.authentication.data.stations.filter((i) => i.river_id === this.state.data.river_id);

    return (
      <React.Fragment>
        <PageHeader title={this.props.intl.formatMessage({ id: "new_disinfection" })} />

        <Row>
          <Col xs={24}>
            <Card style={{ marginTop: 20 }}>
              <div className="container-600">
                <Row>
                  {selectRivers.length > 1 ? (
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <label htmlFor="select_river" className="ant-label">
                        {this.props.intl.formatMessage({ id: "select_river" })}
                      </label>
                      <Select
                        showSearch
                        id="select_river"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_river" })}`}
                        optionFilterProp="children"
                        onChange={(e) => this.handleRiverChange(e)}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        value={this.state.data.river_id}
                      >
                        {selectRivers.map((i, key) => (
                          <Select.Option key={key} value={i.value}>
                            {i.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  ) : null}

                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <label htmlFor="select_station" className="ant-label">
                      {this.props.intl.formatMessage({ id: "select_station" })}
                    </label>
                    <Select
                      showSearch
                      id="select_station"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({ id: "select_station" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.onChangeValue({ station_id: e })}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.data.station_id}
                    >
                      {selectStations.map((i, key) => (
                        <Select.Option key={key} value={i.id}>
                          {`${i.name} (${i.id})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>

                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <Row gutter={8}>
                      <Col xs={24}>
                        <label className="ant-label" htmlFor="customer_id">
                          {this.props.intl.formatMessage({ id: "customer_id" })}
                        </label>
                      </Col>
                      <Col xs={24}>
                        <Input
                          size={ANT_CONFIG.size}
                          name="customer_id"
                          id="customer_id"
                          value={this.state.data.user_id || ""}
                          onChange={(e) => this.onChangeValue({ user_id: e.target.value })}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24} style={{ marginBottom: 10 }}>
                    <Row gutter={8}>
                      <Col xs={24}>
                        <label className="ant-label" htmlFor="name">
                          {this.props.intl.formatMessage({ id: "name" })}
                        </label>
                      </Col>
                      <Col xs={24}>
                        <Input
                          size={ANT_CONFIG.size}
                          name="name"
                          id="name"
                          value={this.state.data.external_id || ""}
                          onChange={(e) => this.onChangeValue({ external_id: e.target.value })}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} className="text-right" style={{ marginTop: 10 }}>
                    <Button type="primary" onClick={(e) => this.onSave(e)} loading={this.props.disinfection.loading} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({ id: "save" })}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  rivers: state.Rivers,
  disinfection: state.Disinfection,
});

export default withRouter(injectIntl(connect(mapStateToProps)(DisinfectionAdd)));
