import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.ADD_CONTROLER_COMMENT:
      return {
        ...state,
        loading: true,
        data: false,
      };

    case ACTION.ADD_CONTROLER_COMMENT_SUCCESS:
      return {
        ...state,
        data: true,
        loading: false,
        error: false,
      };

    case ACTION.ADD_CONTROLER_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: false,
      };

    default:
      return state;
  }
}
