import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from "reactstrap";

import { remove } from "../../../redux/season_cards/actions";

import Spinner from "../../spinner";

class ModalRemoveSeasonCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      items: props.items,
    };
  }

  componentDidUpdate() {
    if (!this.props.season_cards.loading && !this.props.season_cards.error && this.state.submitted) {
      this.toggle();
    }
  }

  onSubmit() {
    this.setState({
      submitted: true,
    });

    const ids = _.flattenDeep(this.state.items.map((item) => item.items.map((i) => i.id)));
    this.props.dispatch(remove({ season_cards_ids: ids }, this.props.data));
  }

  toggle() {
    this.props.toggleModal();
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "delete_fishing_cards" })}</ModalHeader>
        {this.props.season_cards.loading ? (
          <Spinner />
        ) : (
          <Form>
            <div>
              <ModalBody>
                <h2>{this.props.intl.formatMessage({ id: "confirm_delete_question" })}</h2>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={() => this.onSubmit()}>
                  {this.props.intl.formatMessage({ id: "remove" })}
                </Button>
                <Button color="secondary" onClick={() => this.toggle()}>
                  {this.props.intl.formatMessage({ id: "back" })}
                </Button>
              </ModalFooter>
            </div>
          </Form>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  season_cards: state.SeasonCards,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalRemoveSeasonCards)));
