import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch() {
  try {
    const response = yield call(API.call, "admin/fangstrapp/beats", "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_FANGSTRAPP_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_FANGSTRAPP_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_FANGSTRAPP_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* connectRiver(action) {
  try {
    const response = yield call(API.call, "admin/fangstrapp/connect", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.CONNECT_RIVER_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.CONNECT_RIVER_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.CONNECT_RIVER_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* submitReport(action) {
  try {
    const response = yield call(API.call, "admin/fangstrapp/submit", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.SUBMIT_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.SUBMIT_REPORT_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.SUBMIT_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_FANGSTRAPP, fetch), takeEvery(ACTION.CONNECT_RIVER, connectRiver), takeEvery(ACTION.SUBMIT_REPORT, submitReport)]);
}
