import React from "react";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { message, Modal, Upload, Spin } from "antd";
import { injectIntl } from "react-intl";

import getBase64 from "../../services/getBase64";
import { ICDN_API_URL, MAX_IMAGE_UPLOAD_SIZE } from "../../constants";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class ImageUpload extends React.Component {
  state = {
    loading: false,
    previewImage: "",
    previewVisible: false,
    data: this.props.data
      ? this.props.data.map((i) => {
          return {
            uid: `-${i.id}`,
            name: i.url.split("/").slice(-1)[0],
            status: "done",
            file: i.url,
            url: `${i.url}?width=500&height=500&mode=crop`,
            preview: `${i.url}?width=1600`,
            virtual_path: `/media${i.url.split("/media")[1]}`,
          };
        })
      : [],
    limit: this.props.limit || 10,
  };

  componentDidUpdate(prevProp) {
    if (prevProp.data !== this.props.data) {
      this.updateDate(this.props.data);
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.setState({
      loading,
    });
  }

  onImageRemove = async (file) => {
    this.setLoading(true);

    await fetch(ICDN_API_URL, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify({
        virtual_path: file.response?.data?.virtual_path || file.virtual_path,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.setLoading(false);

    if (this.props.onFileRemove) {
      this.props.onFileRemove(file);
    }
  };

  onImageChange = (file) => {
    if (!this.beforeFileUpload(file)) {
      return false;
    }

    this.setLoading(true);

    return process.env.REACT_APP_API_URL + ICDN_API_URL;
  };

  handleImageChange = ({ file, fileList }) => {
    if (file?.response?.success && this.props.onFileAdd) {
      this.props.onFileAdd(file);
    }

    this.setState({ data: fileList.filter((i) => i.status === "done" || i.status === "uploading") });
  };

  beforeFileUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 <= MAX_IMAGE_UPLOAD_SIZE;

    if (!isJpgOrPng) {
      message.error(this.props.intl.formatMessage({ id: "you_can_only_upload_jpg_png_files" }));
    }

    if (!isLt2M) {
      message.error(`${this.props.intl.formatMessage({ id: "image_must_be_smaller" })} ${MAX_IMAGE_UPLOAD_SIZE}MB.`);
    }

    return isJpgOrPng && isLt2M;
  };

  handlePreview = async (file) => {
    let { preview } = file;
    const { originFileObj } = file;

    if (!preview) {
      preview = await getBase64(originFileObj);
    }

    this.setState({
      previewImage: preview,
      previewVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({ previewVisible: false });

    setTimeout(() => {
      this.setState({ previewImage: "" });
    }, 250);
  };

  updateDate(data) {
    this.setState({
      data: data.map((i) => {
        return {
          uid: `-${i.id}`,
          name: i.url.split("/").slice(-1)[0],
          status: "done",
          file: i.url,
          url: `${i.url}?width=500&height=500&mode=crop`,
          preview: `${i.url}?width=1600`,
          virtual_path: `/media${i.url.split("/media")[1]}`,
        };
      }),
    });
  }

  render() {
    return (
      <React.Fragment>
        <Spin indicator={antIcon} spinning={this.state.loading}>
          <Upload
            multiple={this.props.multiple || false}
            beforeUpload={this.beforeFileUpload}
            onRemove={this.onImageRemove}
            action={this.onImageChange}
            listType="picture-card"
            fileList={this.state.data}
            onPreview={this.handlePreview}
            onChange={this.handleImageChange}
            headers={this.props.headers || {}}
            withCredentials={true}
          >
            {this.state.data?.length < this.state.limit && <PlusOutlined style={{ fontSize: "22px" }} />}
          </Upload>
        </Spin>

        <Modal
          className="ant-image-modal"
          width="80vw"
          visible={this.state.previewVisible}
          footer={null}
          onCancel={this.handleCancel}
          style={{ maxWidth: 1350 }}
        >
          <img alt="" style={{ width: "100%" }} src={this.state.previewImage} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default injectIntl(ImageUpload);
