import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

import { fetchFangstrapp, connectRiver, submitReport } from "../../redux/fangstrapp/actions";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import ANT_CONFIG from "../../constants/antconfig";

const { size } = ANT_CONFIG;

const { Option, OptGroup } = Select;

class Fangstrapp extends React.Component {
  state = {
    fbeatId: null,
    riverId: null,
    year: Number(moment().format("Y")),
  };

  componentDidMount() {
    const { dispatch } = this.props;
    if (!this.props.fangstrapp.data) {
      this.fetchData();
    }

    if (!this.props.rivers.data) {
      dispatch(fetchRivers());
    }
  }

  handlefBeatChange = (fbeatId) => {
    this.setState({
      fbeatId,
    });

    const fb = this.props.fangstrapp.data.map((i) => i.beats.filter((i) => i.id === fbeatId)).filter((i) => i.length > 0)?.[0];

    if (fb?.[0]?.export_river_id) {
      this.setState({
        riverId: fb[0].export_river_id,
      });
    } else {
      this.setState({
        riverId: null,
      });
    }
  };

  handleRiverChange = (riverId) => {
    const { dispatch } = this.props;
    const { fbeatId } = this.state;

    this.setState(
      {
        riverId,
      },
      () => {
        dispatch(
          connectRiver({
            river_id: riverId,
            fangstrapp_beat_id: fbeatId,
          })
        );
      }
    );
  };

  fetchData() {
    const { dispatch } = this.props;

    dispatch(fetchFangstrapp());
  }

  sendReport = () => {
    const { fbeatId, year } = this.state;
    const { dispatch } = this.props;

    dispatch(
      submitReport({
        fangstrapp_beat_id: fbeatId,
        year: year,
      })
    );
  };

  render() {
    const { fbeatId, riverId, year } = this.state;
    const { intl, fangstrapp, rivers, authentication } = this.props;

    const selectRivers = rivers?.data
      ? rivers.data
          .filter((river) => authentication.data.river.includes(river.id))
          .map((river) => {
            return {
              value: river.id,
              label: river.name,
            };
          })
      : [];

    return (
      <>
        <PageHeader
          title="Fangstrapp"
          extra={[
            <Button key={1} type="primary" size={size} onClick={this.sendReport} loading={fangstrapp.loading}>
              {intl.formatMessage({ id: "submit_report" })}
            </Button>,
          ]}
        />

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row gutter={[20, 20]}>
            <Col xs={24} md={12}>
              {fangstrapp.data ? (
                <>
                  <label className="ant-label" htmlFor="select_beat_from_fangstrapp">
                    {intl.formatMessage({ id: "select_beat_from_fangstrapp" })}
                  </label>
                  <Select
                    showSearch
                    disabled={fangstrapp.loading}
                    loading={fangstrapp.loading}
                    id="select_beat_from_fangstrapp"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${intl.formatMessage({ id: "select_beat_from_fangstrapp" })}`}
                    optionFilterProp="children"
                    onChange={this.handlefBeatChange}
                    filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={fbeatId}
                  >
                    {fangstrapp.data.map((i) => (
                      <OptGroup key={i.id} label={i.name}>
                        {i.beats?.map((i) => (
                          <Option key={i.id} value={i.id}>
                            {`${i.name} - ID: ${i.id}`}
                          </Option>
                        ))}
                      </OptGroup>
                    ))}
                  </Select>
                </>
              ) : (
                <Spin indicator={<LoadingOutlined spin />} />
              )}
            </Col>

            <Col xs={24} md={12}>
              {selectRivers?.length > 0 ? (
                <>
                  <label className="ant-label" htmlFor="select_river">
                    {intl.formatMessage({ id: "select_river" })}
                  </label>
                  <Select
                    showSearch
                    disabled={fangstrapp.loading}
                    loading={fangstrapp.loading}
                    id="select_river"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${intl.formatMessage({ id: "select_river" })}`}
                    optionFilterProp="children"
                    onChange={this.handleRiverChange}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    value={riverId}
                  >
                    {selectRivers.map((i, key) => (
                      <Option key={key} value={i.value}>
                        {i.label}
                      </Option>
                    ))}
                  </Select>
                </>
              ) : (
                <Spin indicator={<LoadingOutlined spin />} />
              )}
            </Col>
          </Row>

          <Row gutter={[20, 20]}>
            <Col xs={24} md={12}>
              <label className="ant-label" htmlFor="year">
                {intl.formatMessage({ id: "select_year" })}
              </label>
              <Select
                showSearch
                id="year"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={`${intl.formatMessage({ id: "year" })}`}
                optionFilterProp="children"
                onChange={(e) => this.setState({ year: e })}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={year}
              >
                {Array.from(Array(6), (_, i) => i + Number(moment().format("Y"))).map((i) => (
                  <Option key={i} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  rivers: state.Rivers,
  fangstrapp: state.Fangstrapp,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Fangstrapp)));
