import * as ACTION from "./actions";
import moment from "moment";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_RIVER_CARD_BLUEPRINTS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_RIVER_CARD_BLUEPRINTS_SUCCESS:
      const active = [];
      const old = [];
      const year = moment().year();
      action.payload.data.map((card) => {
        if (card.year < year) {
          old.push(card);
        } else {
          active.push(card);
        }
      });
      return {
        ...state,
        data: {
          old,
          active
        },
        loading: false,
        error: false,
      };

    case ACTION.FETCH_RIVER_CARD_BLUEPRINTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.STORE_RIVER_CARD_BLUEPRINT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.STORE_RIVER_CARD_BLUEPRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.STORE_RIVER_CARD_BLUEPRINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    
    case ACTION.UPDATE_RIVER_CARD_BLUEPRINT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.UPDATE_RIVER_CARD_BLUEPRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UPDATE_RIVER_CARD_BLUEPRINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.REMOVE_RIVER_CARD_BLUEPRINT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.REMOVE_RIVER_CARD_BLUEPRINT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.REMOVE_RIVER_CARD_BLUEPRINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
