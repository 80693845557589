import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/article-categories/${action.language}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ARTICLE_CATEGORIES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ARTICLE_CATEGORIES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ARTICLE_CATEGORIES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* store(action) {
  try {
    const response = yield call(API.call, "admin/article-category", "POST", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.STORE_ARTICLE_CATEGORY_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.STORE_ARTICLE_CATEGORY_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.STORE_ARTICLE_CATEGORY_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_ARTICLE_CATEGORIES, fetch), takeEvery(ACTION.STORE_ARTICLE_CATEGORY, store)]);
}
