export const FETCH_CATCH_LIMITS = "FETCH_CATCH_LIMITS";
export const FETCH_CATCH_LIMITS_SUCCESS = "FETCH_CATCH_LIMITS_SUCCESS";
export const FETCH_CATCH_LIMITS_FAILURE = "FETCH_CATCH_LIMITS_FAILURE";

export const STORE_CATCH_LIMITS = "STORE_CATCH_LIMITS";
export const STORE_CATCH_LIMITS_SUCCESS = "STORE_CATCH_LIMITS_SUCCESS";
export const STORE_CATCH_LIMITS_FAILURE = "STORE_CATCH_LIMITS_FAILURE";

export function fetch() {
  return {
    type: FETCH_CATCH_LIMITS,
  };
}

export function store(data) {
  return {
    type: STORE_CATCH_LIMITS,
    data,
  };
}
