import * as ACTION from './actions';

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  product: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_PRODUCTS:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.EDIT_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.ADD_PRODUCT:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload.data.product,
        loading: false,
        error: false,
      };

    case ACTION.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
