import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch() {
  try {
    const response = yield call(API.call, "admin/zones", "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ZONES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ZONES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ZONES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* store(action) {
  try {
    const response = yield call(API.call, "admin/zones", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.STORE_ZONE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch);
      yield put({
        type: ACTION.STORE_ZONE_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.STORE_ZONE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* update(action) {
  try {
    const response = yield call(API.call, `admin/zones/${action.id}`, "PUT", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UPDATE_ZONE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UPDATE_ZONE_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, action);
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UPDATE_ZONE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* destroy(action) {
  try {
    const response = yield call(API.call, `admin/zones/${action.id}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DESTROY_ZONE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DESTROY_ZONE_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, action);
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.DESTROY_ZONE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_ZONES, fetch),
    takeEvery(ACTION.STORE_ZONE, store),
    takeEvery(ACTION.UPDATE_ZONE, update),
    takeEvery(ACTION.DESTROY_ZONE, destroy),
  ]);
}
