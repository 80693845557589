export const FETCH_DASHBOARD_ADMIN_RIVERS = "FETCH_DASHBOARD_ADMIN_RIVERS";
export const FETCH_DASHBOARD_ADMIN_RIVERS_SUCCESS = "FETCH_DASHBOARD_ADMIN_RIVERS_SUCCESS";
export const FETCH_DASHBOARD_ADMIN_RIVERS_FAILURE = "FETCH_DASHBOARD_ADMIN_RIVERS_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_DASHBOARD_ADMIN_RIVERS,
    payload
  };
}
