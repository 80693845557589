import * as ACTION from './actions';

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  available: {
    loading: false,
    error: false,
    data: undefined,
  },
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_FREE_PACKAGES:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_FREE_PACKAGES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_FREE_PACKAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ACTION.ADD_FREE_PACKAGE:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_FREE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.ADD_FREE_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.EDIT_FREE_PACKAGE:
      return {
        ...state,
        loading: true,
      };

    case ACTION.EDIT_FREE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.EDIT_FREE_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.REMOVE_FREE_PACKAGE:
      return {
        ...state,
        loading: true,
      };

    case ACTION.REMOVE_FREE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.REMOVE_FREE_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.FETCH_AVAILABLE_FREE_PACKAGES:
      return {
        ...state,
        available: {
          ...state.available,
          loading: true,
        },
      };

    case ACTION.FETCH_AVAILABLE_FREE_PACKAGES_SUCCESS:
      return {
        ...state,
        available: {
          ...state.available,
          data: action.payload.data,
          loading: false,
          error: false,
        },
      };

    case ACTION.FETCH_AVAILABLE_FREE_PACKAGES_FAILURE:
      return {
        ...state,
        available: {
          ...state.available,
          loading: false,
          error: true
        },
      };

    default:
      return state;
  }
}
