import React from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "antd";
import { Marker } from "react-google-maps";
import { injectIntl } from "react-intl";

import Map from "../../Map/Map";
import ANT_CONFIG from "../../../constants/antconfig";

class AddCenterModal extends React.Component {
  state = {
    latitude: parseFloat(this.props.position.lat),
    longitude: parseFloat(this.props.position.lng)
  };

  setPosition = e => {
    this.setState({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng()
    });
  };

  onSubmit = () => {
    this.props.save(this.state);
  };

  onCancel = () => {
    this.props.cancel();
  };

  render() {
    return (
      <Modal
        title={this.props.intl.formatMessage({ id: "add_center_point" })}
        visible={this.props.show}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="cancel" disabled={this.props.river.loading} type="default" onClick={this.onCancel}>
            {this.props.intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" disabled={this.props.river.loading} type="primary" onClick={this.onSubmit}>
            {this.props.intl.formatMessage({ id: "save" })}
          </Button>
        ]}
      >
        {this.props.show && (
          <React.Fragment>
            <Row>
              <Col xs={24}>
                <Map defaultPosition={{ lat: this.props.position.lat, lng: this.props.position.lng }}>
                  <Marker
                    position={{
                      lat: this.state.latitude,
                      lng: this.state.longitude
                    }}
                    draggable
                    onDragEnd={this.setPosition}
                  />
                </Map>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  river: state.River
});

export default injectIntl(connect(mapStateToProps)(AddCenterModal));
