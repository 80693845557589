import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch() {
  try {
    const response = yield call(API.call, "newsfeed-activities", "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_NEWSFEED_ACTIVITIES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_NEWSFEED_ACTIVITIES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_NEWSFEED_ACTIVITIES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* remove(action) {
  try {
    const response = yield call(API.call, `admin/newsfeed-activity/${action.payload.id}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DELETE_NEWSFEED_ACTIVITY_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch)
      yield put({
        type: ACTION.DELETE_NEWSFEED_ACTIVITY_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DELETE_NEWSFEED_ACTIVITY_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_NEWSFEED_ACTIVITIES, fetch)]);
  yield all([takeEvery(ACTION.DELETE_NEWSFEED_ACTIVITY, remove)]);
}
