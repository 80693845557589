export const FETCH_CANCELLATION_POLICIES = "FETCH_CANCELLATION_POLICIES"
export const FETCH_CANCELLATION_POLICIES_SUCCESS ="FETCH_CANCELLATION_POLICIES_SUCCESS" 
export const FETCH_CANCELLATION_POLICIES_FAILURE = "FETCH_CANCELLATION_POLICIES_SUCCESS" 

export const EDIT_CANCELLATION_POLICIES = "EDIT_CANCELLATION_POLICIES"
export const EDIT_CANCELLATION_POLICIES_SUCCESS ="EDIT_CANCELLATION_POLICIES_SUCCESS" 
export const EDIT_CANCELLATION_POLICIES_FAILURE = "EDIT_CANCELLATION_POLICIES_SUCCESS" 


export function fetch(data) {
  return {
    type: FETCH_CANCELLATION_POLICIES,
    data
  };
}

export function edit(data, payload) {
  return {
    type: EDIT_CANCELLATION_POLICIES,
    data,
    payload
  };
}