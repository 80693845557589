import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, Row, Col, Input, Select, Table } from "antd";

import ANT_CONFIG from "../../../constants/antconfig";
import { update, destroy } from "../../../redux/zones/actions";
import { fetch as fetchRivers } from "../../../redux/rivers/actions";
import RolesContext from "../../../context/RolesContext";

const DEFAULT_STATE = {
  submitted: false,
  river_ids: [],
  beat_ids: [],
  name: "",
  chosen_rivers: [],
  chosen_beats: [],
  display_beats: [],
  confirm_delete: false
};

class ModalEditZone extends React.Component {
  static contextType = RolesContext;
  state = JSON.parse(JSON.stringify(DEFAULT_STATE));

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
    const river_ids = [];
    const display_beats = [];
    this.props.zone.rivers.map((i) => {
      river_ids.push(i.id);
      i.beats?.map((b) => {
        display_beats.push(b);
      });
    });
    const beat_ids = [];
    this.props.zone.beats.map((i) => {
      beat_ids.push(i.id);
    });
    this.setState({
      river_ids,
      beat_ids,
      display_beats,
      name: this.props.zone.name,
      chosen_rivers: JSON.parse(JSON.stringify(this.props.zone.rivers)),
      chosen_beats: JSON.parse(JSON.stringify(this.props.zone.beats)),
    });
  }

  componentDidUpdate() {
    if (!this.props.zones.loading && !this.props.zones.error && this.state.name && this.state.submitted) {
      this.onCancel();
    }
  }

  onCancel = () => {
    this.setState(DEFAULT_STATE);

    this.props.cancel();
  };

  onSave = () => {
    this.setState({
      submitted: true,
    });

    this.props.dispatch(update({
      name: this.state.name,
      river_ids: this.state.river_ids,
      beat_ids: this.state.beat_ids
    }, this.props.zone.id));
  };

  setName(name) {
    this.setState({
      name,
    });
  }

  setRiver = (id) => {
    const river_ids = this.state.river_ids;
    river_ids.push(id);
    const chosen_rivers = this.state.chosen_rivers;
    const theRiver = this.props.rivers.data.find((r) => {
      return r.id === id;
    });
    chosen_rivers.push(theRiver);
    const display_beats = [];
    chosen_rivers.map((r) => {
      r.beats.map((b) => {
        display_beats.push(b);
      });
    });
    this.setState({
      river_ids,
      chosen_rivers,
      display_beats
    });
  };

  removeRiver = (id) => {
    const river_ids = this.state.river_ids;
    const idIndex = river_ids.findIndex((r) => {
      return r === id;
    });
    river_ids.splice(idIndex, 1);
    const chosen_rivers = this.state.chosen_rivers;
    const riverIndex = chosen_rivers.findIndex((r) => {
      return r.id === id;
    });
    chosen_rivers.splice(riverIndex, 1);
    const display_beats = [];
    chosen_rivers.map((r) => {
      r.beats.map((b) => {
        display_beats.push(b);
      });
    });
    this.setState({
      river_ids,
      chosen_rivers,
      display_beats
    });
  };

  setBeat = (id) => {
    const beat_ids = this.state.beat_ids;
    beat_ids.push(id);
    const chosen_beats = this.state.chosen_beats;
    const theBeat = this.state.display_beats.find((b) => {
      return b.id === id;
    });
    chosen_beats.push(theBeat);
    this.setState({
      beat_ids,
      chosen_beats
    });
  };

  removeBeat = (id) => {
    const beat_ids = this.state.beat_ids;
    const idIndex = beat_ids.findIndex((b) => {
      return b === id;
    });
    beat_ids.splice(idIndex, 1);
    const chosen_beats = this.state.chosen_beats;
    const beatIndex = chosen_beats.findIndex((b) => {
      return b.id === id;
    });
    chosen_beats.splice(beatIndex, 1);
    this.setState({
      beat_ids,
      chosen_beats
    });
  };

  destroyConfirm = () => {
    this.setState({
      confirm_delete: true
    });
  }

  destroy = () => {
    this.setState({
      submitted: true,
    });

    this.props.dispatch(destroy(this.props.zone.id));
  }

  render() {
    const { isAdmin } = this.context;
    const { visible, intl, rivers, zones } = this.props;
    const { zone } = this.props;

    if (zone === null) {
      return null;
    }

    if (this.state.confirm_delete) {
      return (
        <Modal
          width={800}
          title={intl.formatMessage({ id: "remove_zone" })}
          visible={visible}
          onCancel={this.onCancel}
          footer={[]}
        >
          <Row>
            {isAdmin ? (
              <Button size={ANT_CONFIG.size} key="destroy" loading={zones.loading} type="danger" onClick={this.destroy}>
                {intl.formatMessage({ id: "confirm_removing" })} {zone.name}
              </Button>
            ) :
            (
              <span>{intl.formatMessage({ id: "only_superadmin_can_remove_zones"}) }</span>
            )}
          </Row>
        </Modal>
      );
    }

    return (
      <Modal
        width={800}
        title={intl.formatMessage({ id: "edit_zone" })}
        visible={visible}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="destroy" type="danger" onClick={this.destroyConfirm}>
            {intl.formatMessage({ id: "remove_zone" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="cancel" type="default" onClick={this.onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" loading={zones.loading} type="primary" onClick={this.onSave}>
            {intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        <Row>
        <Col xs={24} style={{ paddingTop: 20 }}>
            <label className="ant-label" htmlFor="name">
              {intl.formatMessage({ id: "name" })}
            </label>
            <Input size={ANT_CONFIG.size} type="text" name="name" id="name" value={this.state.name} onChange={(e) => this.setName(e.target.value)} />
          </Col>
          <br /><br />
          <Col xs={24} style={{ paddingTop: 30 }}>
            <label className="ant-label" htmlFor="select_river">
              {intl.formatMessage({ id: "select_river" })}
            </label>
            {visible && (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                id="select_river"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={`${intl.formatMessage({ id: "select_river" })}`}
                onChange={this.setRiver}
              >
                {rivers.data?.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col xs={24}>
            <label className="ant-label">{this.props.intl.formatMessage({ id: "rivers" })}</label>
            <Table
              className="ant-design-table-border"
              columns={[
                {
                  title: "#",
                  dataIndex: "key",
                  key: "key"
                },
                {
                  title: this.props.intl.formatMessage({ id: "name" }),
                  dataIndex: "name",
                  key: "name"
                },
                {
                  title: "",
                  dataIndex: "action",
                  key: "action"
                }
              ]}
              dataSource={this.state.chosen_rivers.map((i, key) => {
                return {
                  key: key + 1,
                  name: i.name || "-",
                  action: (
                    <div style={{ textAlign: "right", width: "100%" }}>
                      <Button
                        size={ANT_CONFIG.size}
                        type="danger"
                        onClick={() => {this.removeRiver(i.id)}}
                      >
                        {this.props.intl.formatMessage({ id: "remove" })}
                      </Button>
                    </div>
                  )
                };
              })}
              pagination={ANT_CONFIG.pagination}
            />
          </Col>
          <Col xs={24} style={{ paddingTop: 30 }}>
            <label className="ant-label" htmlFor="select_beat">
              {intl.formatMessage({ id: "select_beat" })}
            </label>
            {visible && (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                id="select_beat"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={`${intl.formatMessage({ id: "select_beat" })}`}
                onChange={this.setBeat}
              >
                {this.state.display_beats?.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col xs={24}>
            <label className="ant-label">{this.props.intl.formatMessage({ id: "beats" })}</label>
            <Table
              className="ant-design-table-border"
              columns={[
                {
                  title: "#",
                  dataIndex: "key",
                  key: "key"
                },
                {
                  title: this.props.intl.formatMessage({ id: "name" }),
                  dataIndex: "name",
                  key: "name"
                },
                {
                  title: "",
                  dataIndex: "action",
                  key: "action"
                }
              ]}
              dataSource={this.state.chosen_beats.map((i, key) => {
                return {
                  key: key + 1,
                  name: i.name || "-",
                  action: (
                    <div style={{ textAlign: "right", width: "100%" }}>
                      <Button
                        size={ANT_CONFIG.size}
                        type="danger"
                        onClick={() => {this.removeBeat(i.id)}}
                      >
                        {this.props.intl.formatMessage({ id: "remove" })}
                      </Button>
                    </div>
                  )
                };
              })}
              pagination={ANT_CONFIG.pagination}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  rivers: state.Rivers,
  zones: state.Zones,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalEditZone)));
