import React from "react";
import { injectIntl } from "react-intl";

class Notification extends React.Component {
  state = {
    type: this.props.type || 'warning',
    text: this.props.text,
    conditions_met: this.props.conditions_met,
    beatNames: this.props.beat_names || ''
  };

 beatNames = () => {
  return this.state.beatNames.slice(0, this.state.beatNames.length - 2) + '. ';
 }

 bannerMessages = () => {
   if(this.state.beatNames) {
      return this.props.intl.formatMessage({ id: "adminNotificationWarningTextPre" }) +  this.beatNames() + this.props.intl.formatMessage({ id: "adminNotificationWarningTextPost"})
   } else {
      return this.props.intl.formatMessage({ id: "adminBeatNotificationWarningText"}) 
   }
 }

  
  render() {
    if(this.state.conditions_met) {
      return <div></div>
    }

    return (
      <div className={'notification ' + this.state.type}>
          {this.bannerMessages()}
      </div>
    );
  }
}

export default injectIntl(Notification);
