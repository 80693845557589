import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_CARD_OR_ORDER:
      return {
        ...state,
        loading: true,
        articleId: null,
      };

    case ACTION.FETCH_CARD_OR_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_CARD_OR_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.UPDATE_CARD:
      return {
        ...state,
        loading: true,
        articleId: null,
      };

    case ACTION.UPDATE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UPDATE_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.UPDATE_CARD_DATE:
      return {
        ...state,
        loading: true,
        articleId: null,
      };

    case ACTION.UPDATE_CARD_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UPDATE_CARD_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.REMOVE_CARD_FROM_ORDER:
      return {
        ...state,
        loading: true,
        articleId: null,
      };

    case ACTION.REMOVE_CARD_FROM_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.REMOVE_CARD_FROM_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
