export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";

export const FETCH_STATION_ADMIN = "FETCH_STATION_ADMIN";
export const FETCH_STATION_ADMIN_SUCCESS = "FETCH_STATION_ADMIN_SUCCESS";
export const FETCH_STATION_ADMIN_FAILURE = "FETCH_STATION_ADMIN_FAILURE";

export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";

export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILURE = "EDIT_CUSTOMER_FAILURE";

export const UNBLOCK_CUSTOMER = "UNBLOCK_CUSTOMER";
export const UNBLOCK_CUSTOMER_SUCCESS = "UNBLOCK_CUSTOMER_SUCCESS";
export const UNBLOCK_CUSTOMER_FAILURE = "UNBLOCK_CUSTOMER_FAILURE";

export const QUITE_ADD = "QUITE_ADD";
export const QUITE_ADD_SUCCESS = "QUITE_ADD_SUCCESS";
export const QUITE_ADD_FAILURE = "QUITE_ADD_FAILURE";

export const QUITE_REMOVE = "QUITE_REMOVE";

export function fetch() {
  return {
    type: FETCH_CUSTOMERS,
  };
}

export function show(id) {
  return {
    type: FETCH_CUSTOMER,
    id,
  };
}

export function showStationAdmin(id) {
  return {
    type: FETCH_STATION_ADMIN,
    id,
  };
}

export function add(payload) {
  return {
    type: ADD_CUSTOMER,
    payload,
  };
}

export function edit(payload, id) {
  return {
    type: EDIT_CUSTOMER,
    payload,
    id,
  };
}

export function quiteAdd(payload) {
  return {
    type: QUITE_ADD,
    payload,
  };
}

export function quiteRemove() {
  return {
    type: QUITE_REMOVE,
  };
}

export function unblock(payload) {
  return {
    type: UNBLOCK_CUSTOMER,
    payload,
  };
}
