import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Result } from "antd";
import { injectIntl } from "react-intl";

const GenericError = props => (
  <div className="default-font text-center" style={{ marginTop: 50 }}>
    <Result
      status={props.errorCode?.toString() || "404"}
      title={props.errorCode || 404}
      subTitle={props.intl.formatMessage({ id: props.errorMsg || "site_not_found" })}
      extra={
        <NavLink to="/">
          <Button size="large" type="primary">
            {props.intl.formatMessage({ id: "back_to_dashboard" })}
          </Button>
        </NavLink>
      }
    />
  </div>
);

export default withRouter(injectIntl(GenericError));
