export default {
  nb: {
    catch_saved: "Fangst lagret",
    package_available: "Pakke tilgjengelig"
  },
  en: {
    catch_saved: "Catch saved",
    package_available: "Package available" 
  },
  sv: {
    catch_saved: "Fångsten sparad",
    package_available: "Paket tillgängligt"
  }
};
