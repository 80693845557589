const transactionFee = totalPrice => {
  let fee;

  switch (true) {
    case parseInt(totalPrice, 10) === 0:
      fee = 0;
      break;

    case parseInt(totalPrice, 10) < 100:
      fee = 10;
      break;

    case parseInt(totalPrice, 10) < 250:
      fee = 20;
      break;

    case parseInt(totalPrice, 10) < 450:
      fee = 35;
      break;

    case parseInt(totalPrice, 10) < 700:
      fee = 45;
      break;

    case parseInt(totalPrice, 10) < 1000:
      fee = 65;
      break;

    case parseInt(totalPrice, 10) < 5000:
      fee = 85;
      break;

    case parseInt(totalPrice, 10) < 10000:
      fee = 125;
      break;

    case parseInt(totalPrice, 10) < 30000:
      fee = 200;
      break;

    case parseInt(totalPrice, 10) >= 30000:
      fee = 250;
      break;

    default: {
      fee = 0;
    }
  }

  return parseInt(fee, 10);
};

export default transactionFee;
