import Auth from "./authentication/reducers";
import Customers from "./customers/reducers";
import Rivers from "./rivers/reducers";
import River from "./river/reducers";
import Beats from "./beats/reducers";
import Beat from "./beat/reducers";
import Products from "./products/reducers";
import FishingCards from "./fishing_cards/reducers";
import Accommodation from "./accommodation/reducers";
import FishingPackages from "./fishing_packages/reducers";
import FreePackages from "./free_packages/reducers";
import Booking from "./booking/reducers";
import PackageAvailability from "./package_availability/reducers";
import Orders from "./orders/reducers";
import Dashboard from "./dashboard/reducers";
import Taxes from "./taxes/reducers";
import Commissions from "./commissions/reducers";
import BeatOptions from "./beat_options/reducers";
import RiverOptions from "./river_options/reducers";
import Laksebors from "./laksebors/reducers";
import Reports from "./reports/reducers";
import Stations from "./stations/reducers";
import Disinfection from "./disinfection/reducers";
import Stationrole from "./stationrole/reducers";
import Stationadmins from "./stationadmins/reducers";
import Catches from "./catches/reducers";
import Counties from "./counties/reducers";
import Countries from "./countries/reducers";
import FlyTypes from "./flytypes/reducers";
import Facilities from "./facilities/reducers";
import Articles from "./articles/reducers";
import Article from "./article/reducers";
import ArticleCategories from "./categories/reducers";
import Equipment from "./equipment/reducers";
import FishTypes from "./fishtypes/reducers";
import ExtraServices from "./extra_services/reducers";
import SeasonPrices from "./season_prices/reducers";
import SeasonDates from "./season_dates/reducers";
import SeasonCards from "./season_cards/reducers";
import CustomerBlock from "./customer_block/reducers";
import Pages from "./pages/reducers";
import Page from "./page/reducers";
import NewsfeedActivities from "./newsfeed_activities/reducers";
import RiverRequiredFields from "./river_require_catch_fields/reducers";
import FishTypesAdmin from "./fishtypes_admin/reducers";
import EquipmentAdmin from "./equipment_admin/reducers";
import Cards from "./cards/reducers";
import CatchLimits from "./catch_limits/reducers";
import ControllerComments from "./controller_comments/reducers";
import Billings from "./billings/reducers";
import Fangstrapp from "./fangstrapp/reducers";
import Notifications from "./notifications/reducers";
import Zones from "./zones/reducers";
import BeatNotifications from "./beat_notifications/reducers";
import DashboardAdmin from "./dashboard_admin/reducers";
import DashboardAdminCounties from "./dashboard_admin_counties/reducers";
import DashboardAdminRivers from "./dashboard_admin_rivers/reducers";
import DashboardRiverAdmin from "./dashboard_river_admin/reducers";
import DashboardRiverAdminCatches from "./dashboard_river_admin_catches/reducers";
import DashboardRiverAdminCatchStatistics from "./dashboard_river_admin_catch_statistics/reducers";
import DashboardRiverAdminAdmins from "./dashboard_river_admin_admins/reducers";
import DashboardRiverAdminFishingPressure from "./dashboard_river_admin_fishing_pressure/reducers";
import DashboardRiverAdminControllers from "./dashboard_river_admin_controllers/reducers"
import DashboardBeatAdmin from "./dashboard_beat_admin/reducers";
import DashboardBeatAdminAdmins from "./dashboard_beat_admin_admins/reducers";
import DashboardBeatAdminMembers from "./dashboard_beat_admin_members/reducers";
import DashboardBeatAdminDailyCards from "./dashboard_beat_admin_daily_cards/reducers";
import DashboardBeatAdminDailyAccommodation from "./dashboard_beat_admin_daily_accommodation/reducers";
import DashboardControllerAdmin from "./dashboard_controller_admin/reducers";
import DashboardControllerStatistics from "./dashboard_controller_statistics/reducers";
import RiverCardBlueprints from "./river_card_blueprints/reducers";
import RiverCards from "./river_cards/reducers";
import RiverCardSurveys from "./river_card_surveys/reducers";
import SendMail from "./send_mail/reducers";
import SendMailOrders from "./send_mail_orders/reducers";
import Refunds from "./refunds/reducers";
import RiverOrders from "./river_orders/reducers";
import ControllerRivers from "./controller_rivers/reducers";
import CancellationPolicies from "./cancellation_policies/reducers";
import Settings from "./settings/reducers";
import RiverEquipment from "./river_equipment/reducers";

export default {
  Auth,
  Customers,
  Rivers,
  River,
  Beats,
  Beat,
  Products,
  FishingCards,
  Accommodation,
  FishingPackages,
  FreePackages,
  Booking,
  PackageAvailability,
  Orders,
  Dashboard,
  Taxes,
  Commissions,
  BeatOptions,
  RiverOptions,
  Laksebors,
  Reports,
  Stations,
  Disinfection,
  Stationrole,
  Stationadmins,
  Catches,
  Counties,
  Countries,
  FlyTypes,
  Facilities,
  Articles,
  Article,
  ArticleCategories,
  Equipment,
  FishTypes,
  ExtraServices,
  SeasonPrices,
  SeasonDates,
  SeasonCards,
  CustomerBlock,
  Pages,
  Page,
  NewsfeedActivities,
  RiverRequiredFields,
  FishTypesAdmin,
  EquipmentAdmin,
  Cards,
  CatchLimits,
  ControllerComments,
  Billings,
  Fangstrapp,
  Notifications,
  Zones,
  BeatNotifications,
  DashboardAdmin,
  DashboardAdminCounties,
  DashboardAdminRivers,
  DashboardRiverAdmin,
  DashboardRiverAdminCatches,
  DashboardRiverAdminCatchStatistics,
  DashboardRiverAdminAdmins,
  DashboardRiverAdminFishingPressure,
  DashboardRiverAdminControllers,
  DashboardBeatAdmin,
  DashboardBeatAdminAdmins,
  DashboardBeatAdminMembers,
  DashboardBeatAdminDailyCards,
  DashboardBeatAdminDailyAccommodation,
  DashboardControllerAdmin,
  DashboardControllerStatistics,
  RiverCardBlueprints,
  RiverCards,
  RiverCardSurveys,
  SendMail,
  SendMailOrders,
  Refunds,
  RiverOrders,
  ControllerRivers,
  CancellationPolicies,
  Settings,
  RiverEquipment,
};
