import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, Row, Col, Input, Select, Checkbox } from "antd";

import ANT_CONFIG from "../../../constants/antconfig";
import { store } from "../../../redux/beats/actions";
import { fetch as fetchRivers } from "../../../redux/rivers/actions";

const DEFAULT_STATE = {
  submitted: false,
  river_id: null,
  active_on_web: 1,
  active_on_laksebors: 1,
  name: "",
};

class ModalAddNewBeat extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
  }

  componentDidUpdate() {
    if (!this.props.beats.loading && !this.props.beats.error && this.state.name && this.state.submitted) {
      this.onCancel();
    }
  }

  onCancel = () => {
    this.setState(DEFAULT_STATE);

    this.props.cancel();
  };

  onSave = () => {
    this.setState({
      submitted: true,
    });

    this.props.dispatch(store(this.state));
  };

  setName(name) {
    this.setState({
      name,
    });
  }

  setRiver = (id) => {
    this.setState({
      river_id: id,
    });
  };

  setActiveOnWeb(value) {
    this.setState({
      active_on_web: value,
    });
  }

  setActiveOnLaksebors(value) {
    this.setState({
      active_on_laksebors: value,
    });
  }

  render() {
    const { visible, intl, beats, rivers } = this.props;
    const { name, active_on_web, active_on_laksebors } = this.state;

    return (
      <Modal
        width={800}
        title={intl.formatMessage({ id: "add_new_beat" })}
        visible={visible}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="cancel" disabled={beats.loading} type="default" onClick={this.onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" loading={beats.loading} type="primary" onClick={this.onSave}>
            {intl.formatMessage({ id: "save" })}
          </Button>,
        ]}
      >
        <Row>
          <Col xs={24}>
            <label className="ant-label" htmlFor="select_river">
              {intl.formatMessage({ id: "select_river" })}
            </label>
            {visible && (
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                id="select_river"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                placeholder={`${intl.formatMessage({ id: "select_river" })}`}
                onChange={this.setRiver}
              >
                {rivers.data?.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
          <Col xs={24} style={{ paddingTop: 20 }}>
            <label className="ant-label" htmlFor="name">
              {intl.formatMessage({ id: "name" })}
            </label>
            <Input size={ANT_CONFIG.size} type="text" name="name" id="name" value={name} onChange={(e) => this.setName(e.target.value)} />
          </Col>
          <Col xs={24} style={{ paddingTop: 20 }}>
            <Checkbox size={ANT_CONFIG.size} checked={active_on_web} onChange={(e) => this.setActiveOnWeb(e.target.checked ? 1 : 0)}>
              {intl.formatMessage({ id: "active_on_web" })}
            </Checkbox>
          </Col>
          <Col xs={24} style={{ paddingTop: 20 }}>
            <Checkbox size={ANT_CONFIG.size} checked={active_on_laksebors} onChange={(e) => this.setActiveOnLaksebors(e.target.checked ? 1 : 0)}>
              {intl.formatMessage({ id: "active_on_laksebors" })}
            </Checkbox>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  rivers: state.Rivers,
  beats: state.Beats,
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddNewBeat)));
