import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  sendData: undefined,
  summaryData: undefined
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.SEND_RIVER_CARD_SURVEY:
      return {
        ...state,
        loading: true,
      };

    case ACTION.SEND_RIVER_CARD_SURVEY_SUCCESS:
      return {
        ...state,
        sendData: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.SEND_RIVER_CARD_SURVEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    
    case ACTION.FETCH_RIVER_CARD_SURVEY_SUMMARY:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_RIVER_CARD_SURVEY_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryData: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_RIVER_CARD_SURVEY_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.RESET_RIVER_CARD_SURVEY_SUMMARY:
      return {
        ...state,
        summaryData: undefined
      };

    default:
      return state;
  }
}
