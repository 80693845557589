import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { DeleteOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Modal, Button, Table, Tag } from "antd";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";

import {
  add as addCustomer,
  edit as editCustomer,
  show as showCustomer,
  showStationAdmin,
} from "../../redux/customers/actions";
import { cancelForUser, fetchCustomerOrders, sendLink } from "../../redux/orders/actions";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import { fetch as fetchBeats } from "../../redux/beats/actions";
import { getLatestCatch } from "../../redux/laksebors/actions";
import Spinner from "../../components/spinner";
import {
  COUNTRY_CODES,
  USER_ROLES,
  RIVER_ADMIN,
  BEAT_ADMIN,
  STATION_USER_ROLES,
  STATION_ADMIN,
  SELLER_USER_ROLES,
  SELLER,
  SUPERVISOR,
  SUPERVISOR_USER_ROLES,
} from "../../constants";
import SelectInput from "../../components/select";
import OptionList from "../../components/optionlist";
import { fetch as fetchStations } from "../../redux/stations/actions";
import { add as addStationRole, remove as removeStationRole, massRemove } from "../../redux/stationrole/actions";
import ANT_CONFIG from "../../constants/antconfig";
import CatchInfo from "../../components/modal/Catch/CatchInfo";
import CommentsList from "../../components/modal/Customer/CommentsList";
import AddComment from "../../components/modal/Customer/AddComment";
import { fetch as fetchCustomerBlock, ban as banUser, unban as unbanUser } from "../../redux/customer_block/actions";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";

const labelWidth = 4;
const inputWidth = 8;
const { confirm } = Modal;
const DATE_FORMAT = "DD.MM.YYYY";
const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Customer extends React.Component {
  static contextType = RolesContext;

  state = {
    isNew: this.props.match.params.id === "new",
    updated: false,
    data: undefined,
    userHasStations: false,
    userIsSeller: false,
    userIsSupervisor: false,
    sendLinkLoading: [],
    modalDetails: null,
    ban_beat: [],
    lift_ban_beat: [],
    ban_river: [],
    lift_ban_river: [],
    add_member: [],
    remove_member: [],
    modalComments: false,
    addCommentModal: false,
  };

  componentDidMount() {
    if (!this.state.isNew) {
      if (this.props.location.pathname.includes("sadmin")) {
        this.props.dispatch(showStationAdmin(this.props.match.params.id));
      } else {
        this.props.dispatch(showCustomer(this.props.match.params.id));
      }
      this.props.dispatch(fetchCustomerOrders(this.props.match.params.id));
      this.props.dispatch(getLatestCatch(this.props.match.params.id));
      this.props.dispatch(fetchCustomerBlock(this.props.match.params.id));
    }

    if (this.state.isNew && !this.state.updated) {
      this.onComponentUpdate([]);
    }

    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (!this.props.stations.data) {
      this.props.dispatch(fetchStations());
    }
  }

  componentDidUpdate() {
    if (this.props.customers.customer && this.props.customers.customer.id === parseInt(this.props.match.params.id, 10)) {
      if (!this.state.updated) {
        this.onComponentUpdate(this.props.customers.customer);
      }
    }
  }

  onComponentUpdate(customer) {
    this.setState({
      updated: true,
      userHasStations: customer.station && customer.station.length > 0,
      userIsSeller: customer.seller_beat?.length > 0,
      userIsSupervisor: customer.controller_rivers?.length > 0,
      data: customer || [],
      ban_beat: customer.banned_from_beats?.map((i) => i.id) || [],
      ban_river: customer.banned_from_rivers?.map((i) => i.id) || [],
      add_member: customer.member_of_beats?.map((i) => i.id) || [],
    });
  }

  onSave(e) {
    e.preventDefault();
    const { data, add_member, remove_member } = this.state;

    if (add_member.length > 0) {
      data.add_member = add_member;
    }

    if (remove_member.length > 0) {
      data.remove_member = remove_member;
    }

    if (data.controller_rivers?.length === 0) {
      data.roles = data.roles.filter((i) => i !== "controller_user");
    }

    if (data.seller_beat?.length === 0) {
      data.roles = data.roles.filter((i) => i !== "seller");
    }

    this.props.dispatch(this.state.isNew ? addCustomer(data) : editCustomer(data, data.id));
  }

  onChangeValue(newState) {
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));
  }

  onChangeStationRole() {
    this.setState(
      (prevState) => ({ userHasStations: !prevState.userHasStations }),
      () => {
        if (!this.state.isNew && !this.state.userHasStations) {
          this.props.dispatch(
            massRemove(this.state.data.id, {
              station: this.state.data.station,
            }),
          );
          this.onChangeValue({ station: [] });
        }
      },
    );
  }

  onChangeSellerRole() {
    this.setState(
      (prevState) => ({ userIsSeller: !prevState.userIsSeller }),
      () => {
        if (this.state.userIsSeller) {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              roles: prevState.data.roles ? [...prevState.data.roles, "seller"] : ["seller"],
            },
          }));
        }

        if (!this.state.userIsSeller) {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              roles: [...prevState.data.roles.filter((i) => i !== "seller")],
              seller_beat: [],
            },
          }));
        }
      },
    );
  }

  onChangeSupervisorRole() {
    this.setState(
      (prevState) => ({ userIsSupervisor: !prevState.userIsSupervisor }),
      () => {
        if (this.state.userIsSupervisor) {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              roles: prevState.data.roles ? [...prevState.data.roles, "controller_user"] : ["controller_user"],
            },
          }));
        }

        if (!this.state.userIsSupervisor) {
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              roles: [...prevState.data.roles.filter((i) => i !== "controller_user")],
              controller_rivers: [],
            },
          }));
        }
      },
    );
  }

  onChangeOldUser(e) {
    this.setState((prevState) => ({ data: { ...prevState.data, old_customer: e } }));
  }

  onChangePostalCode(newState) {
    this.onChangeValue({ post_code: newState });
  }

  onChangeCountry(newState) {
    this.onChangeValue({ country: newState.value });
  }

  onNewOption(key, option, station = false) {
    if (!this.state.data[key] || this.state.data[key].indexOf(option.value) === -1) {
      const newState = Object.assign({}, this.state.data);

      newState[key] === undefined ? (newState[key] = [option.value]) : newState[key].push(option.value);
      this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));

      if (!this.state.isNew && station) {
        this.props.dispatch(
          addStationRole({
            user_id: newState.id,
            station_id: option.value,
          }),
        );
      }
    }
  }

  onRemoveOption(key, i, station = false) {
    const newState = Object.assign({}, this.state.data);
    newState[key] = newState[key].filter((item) => item !== i);
    this.setState((prevState) => ({ data: { ...prevState.data, ...newState } }));

    if (!this.state.isNew && station) {
      this.props.dispatch(removeStationRole(newState.id, i));
    }
  }

  onUserBan(key, beatOrRiverId, e) {
    if (this.state[key].includes(e.value)) {
      return;
    }

    this.setState((prevState) => ({
      [key]: [...prevState[key], e.value],
      [`lift_${key}`]: [...prevState[`lift_${key}`].filter((i) => i !== e.value)],
    }));

    this.props.dispatch(
      banUser({
        user_id: this.state.data.id,
        [beatOrRiverId]: e.value,
      }),
    );
  }

  onUserLiftBan(key, beatOrRiverId, e) {
    this.setState((prevState) => ({
      [key]: [...prevState[key].filter((i) => i !== e)],
      [`lift_${key}`]: [...prevState[`lift_${key}`], e],
    }));

    this.props.dispatch(
      unbanUser({
        user_id: this.state.data.id,
        [beatOrRiverId]: e,
      }),
    );
  }

  onBeatAddMember(e) {
    if (this.state.add_member.includes(e.value)) {
      return;
    }

    this.setState((prevState) => ({
      add_member: [...prevState.add_member, e.value],
      remove_member: [...prevState.remove_member.filter((i) => i !== e.value)],
    }));
  }

  onBeatRemoveMember(e) {
    this.setState((prevState) => ({
      remove_member: [...prevState.remove_member, e],
      add_member: [...prevState.add_member.filter((i) => i !== e)],
    }));
  }

  closeModal = () => {
    this.setState({
      modalDetails: null,
    });
  };

  showConfirm(id) {
    const orderId = id;

    confirm({
      confirmLoading: this.props.orders.loading,
      title: this.props.intl.formatMessage({ id: "confirm_cancel_order_question" }),
      okText: this.props.intl.formatMessage({ id: "yes" }),
      okType: "danger",
      onOk: () => this.handleOk(orderId),
      onCancel() {},
    });
  }

  handleOk(orderId) {
    return new Promise((resolve, reject) => {
      this.props
        .dispatch(cancelForUser(orderId, this.props.match.params.id))
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  openCatchModal(modalDetails) {
    this.setState({
      modalDetails,
    });
  }

  handleLinkSend(orderId) {
    this.setState(
      (prevState) => ({
        sendLinkLoading: [...prevState.sendLinkLoading, orderId],
      }),
      () => {
        return new Promise((resolve, reject) => {
          this.props
            .dispatch(sendLink(parseInt(orderId, 10)))
            .then(() => {
              this.setState((prevState) => ({
                sendLinkLoading: prevState.sendLinkLoading.filter((i) => i !== orderId),
              }));
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    );
  }

  showAddCommentModal(addCommentModal) {
    this.setState({
      addCommentModal,
    });
  }

  closeAddCommentModal = () => {
    this.setState({
      addCommentModal: null,
    });
  };

  showCommentsModal(modalComments) {
    this.setState({
      modalComments,
    });
  }

  closeCommentsModal = () => {
    this.setState({
      modalComments: null,
    });
  };

  render() {
    if (
      !this.state.data ||
      this.props.customers.loading ||
      this.props.beats.loading ||
      this.props.rivers.loading ||
      this.props.stations.loading
    ) {
      return <Spinner />;
    }

    const { isAdmin, isRiverAdmin, isBeatAdmin, isSupervisorAdmin } = this.context;
    const { authentication } = this.props;

    const userActive = [
      {
        value: 1,
        label: this.props.intl.formatMessage({ id: "yes" }),
      },
      {
        value: 0,
        label: this.props.intl.formatMessage({ id: "no" }),
      },
    ];

    const ordersTableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: this.props.intl.formatMessage({ id: "product_type" }),
        dataIndex: "product_type",
        key: "product_type",
      },
      {
        title: this.props.intl.formatMessage({ id: "river" }),
        dataIndex: "river",
        key: "river",
      },
      {
        title: this.props.intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: this.props.intl.formatMessage({ id: "from_date" }),
        dataIndex: "from_date",
        key: "from_date",
      },
      {
        title: this.props.intl.formatMessage({ id: "to_date" }),
        dataIndex: "to_date",
        key: "to_date",
      },
      {
        title: this.props.intl.formatMessage({ id: "price" }),
        dataIndex: "price",
        key: "price",
      },
      {
        title: this.props.intl.formatMessage({ id: "send_link" }),
        dataIndex: "send_link",
        key: "send_link",
      },
      {
        title: this.props.intl.formatMessage({ id: "order_status" }),
        dataIndex: "order_status",
        key: "order_status",
      },
    ];
    if (isAdmin) {
      ordersTableColumns.push({
        title: `${this.props.intl.formatMessage({ id: "download" })} ${this.props.intl.formatMessage({ id: "fishing_cards" })}`,
        dataIndex: "download",
        key: "download",
      });
      ordersTableColumns.push({
        title: this.props.intl.formatMessage({ id: "cancel_order" }),
        dataIndex: "cancel_order",
        key: "cancel_order",
      });
    }
    let ordersTableData = [];
    if (this.props.orders.data_customer) {
      ordersTableData = this.props.orders.data_customer.map((order) => {
        let color;
        let type =
          order.has_type?.length > 0 ? order.has_type.map((i) => this.props.intl.formatMessage({ id: i })).join(", ") : "-";
        const hasRiverCards = order.has_type?.includes("river_card");

        if (hasRiverCards) {
          order.packages.forEach((pack) => {
            if (pack.river_cards.length > 0) {
              pack.river_cards.forEach((i) => {
                if (!type.includes(i.river_card_blueprint.name)) {
                  type += `, ${i.river_card_blueprint.name}`;
                }
              });
            }
          });
        }

        let dates = [];
        let dateFrom = "";
        let dateTo = "";

        if (order.status === "PAID") {
          color = "green";
        }
        if (order.status === "PENDING") {
          color = "orange";
        }
        if (order.status === "CANCELLED") {
          color = "red";
        }

        if (order.packages?.length > 0) {
          order.packages.forEach((pack) => {
            if (pack.fishing_cards?.length > 0) {
              pack.fishing_cards.forEach((i) => {
                dates = [...dates, i.date];
              });
            }

            if (pack.accommodations?.length > 0) {
              pack.accommodations.forEach((i) => {
                dates = [...dates, i.date];
              });
            }

            if (pack.free_packages?.length > 0) {
              pack.free_packages.forEach((i) => {
                dates = [...dates, i.date_start, i.date_end];
              });
            }

            if (pack.season_fishing_cards.length > 0) {
              pack.season_fishing_cards.forEach((i) => {
                dates = [...dates, i.season_start_date, i.season_end_date];
              });
            }

            if (pack.river_cards.length > 0) {
              pack.river_cards.forEach((i) => {
                dates = [`${i.river_card_blueprint.year}-01-01`, `${i.river_card_blueprint.year}-12-31`];
              });
            }
          });
        }

        if (dates?.length > 0) {
          dateFrom = dates.reduce((pre, cur) => (Date.parse(pre) > Date.parse(cur) ? cur : pre));
          dateTo = dates.reduce((pre, cur) => (Date.parse(pre) < Date.parse(cur) ? cur : pre));
        }

        return {
          key: order.id,
          product_type: type || "-",
          river: order.river_name ?? "-",
          beat: order.beat_name ?? "-",
          from_date: dateFrom ? moment(dateFrom).format(hasRiverCards ? "YYYY" : "DD.MM.YYYY") : "-",
          to_date: dateTo ? moment(dateTo).format(hasRiverCards ? "YYYY" : "DD.MM.YYYY") : "-",
          price: `${order.total} NOK`,
          download: isAdmin ? (
            order.status === "PAID" && type !== this.props.intl.formatMessage({ id: "accommodation" }) ? (
              <a href={`${order.fishing_cards_pdf_url}`} rel="noopener noreferrer" target="_blank">
                <Button icon={<DownloadOutlined />}>{this.props.intl.formatMessage({ id: "download" })}</Button>
              </a>
            ) : (
              <span>&nbsp;</span>
            )
          ): null,
          send_link:
            order.status === "PENDING" ? (
              <Button
                type="primary"
                onClick={() => this.handleLinkSend(order.id)}
                loading={this.state.sendLinkLoading.includes(order.id)}
              >
                {this.props.intl.formatMessage({ id: "send_link" })}
              </Button>
            ) : (
              <span>&nbsp;</span>
            ),
          order_status: (
            <Tag color={color} className="ant-tag">
              {this.props.intl.formatMessage({ id: order.status })}
            </Tag>
          ),
          cancel_order: isAdmin ? (
            order.status !== "CANCELLED" ? (
              <Button type="danger" onClick={() => this.showConfirm(order.id)} icon={<DeleteOutlined />}>
                {this.props.intl.formatMessage({ id: "cancel" })}
              </Button>
            ) : (
              <span>&nbsp;</span>
            )
          ) : null,
        };
      });
    }

    const catchesTableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: this.props.intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
      },
      {
        title: this.props.intl.formatMessage({ id: "card_id" }),
        dataIndex: "card_id",
        key: "card_id",
      },
      {
        title: this.props.intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: this.props.intl.formatMessage({ id: "fish_type" }),
        dataIndex: "fish_type",
        key: "fish_type",
      },
      {
        title: this.props.intl.formatMessage({ id: "equipment" }),
        dataIndex: "equipment",
        key: "equipment",
      },
      {
        title: this.props.intl.formatMessage({ id: "weight" }),
        dataIndex: "weight",
        key: "weight",
      },
      {
        title: this.props.intl.formatMessage({ id: "length" }),
        dataIndex: "length",
        key: "length",
      },
      {
        title: this.props.intl.formatMessage({ id: "cr" }),
        dataIndex: "cr",
        key: "cr",
      },
      {
        title: this.props.intl.formatMessage({ id: "detailed_overview" }),
        dataIndex: "details",
        key: "details",
      },
    ];

    let catchesTableData = [];
    if (this.props.laksebors?.data) {
      catchesTableData = this.props.laksebors.data?.map((i, key) => {
        return {
          key: key + 1,
          date: i.date ? (
            <strong>
              {moment(i.date).format("DD. MMM YYYY")}
              {i.time_of_day ? ` ${i.time_of_day}` : ""}
            </strong>
          ) : (
            "-"
          ),
          beat: i.beat?.name || "-",
          card_id: i.fishing_card_id || i.package_id || i.free_package_id || i.season_fishing_card_id || "-",
          fish_type: i.fish_type?.name_no || "-",
          equipment: i.equipment?.name_no || "-",
          weight: i.weight || "-",
          length: i.length || "-",
          cr: i.released_catch ? (
            <Tag color="green" className="ant-tag">
              {this.props.intl.formatMessage({ id: "yes" })}
            </Tag>
          ) : (
            <Tag color="red" className="ant-tag">
              {this.props.intl.formatMessage({ id: "no" })}
            </Tag>
          ),
          details: (
            <Button type="primary" onClick={() => this.openCatchModal(i)}>
              {this.props.intl.formatMessage({ id: "details" })}
            </Button>
          ),
        };
      });
    }

    const blockedTableColumns = [
      {
        title: this.props.intl.formatMessage({ id: "card_id" }),
        dataIndex: "card_id",
        key: "card_id",
      },
      {
        title: this.props.intl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
      },
      {
        title: this.props.intl.formatMessage({ id: "date_start" }),
        dataIndex: "date_start",
        key: "date_start",
      },
      {
        title: this.props.intl.formatMessage({ id: "date_end" }),
        dataIndex: "date_end",
        key: "date_end",
      },
      {
        title: this.props.intl.formatMessage({ id: "river" }),
        dataIndex: "river",
        key: "river",
      },
      {
        title: this.props.intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: this.props.intl.formatMessage({ id: "register_catch" }),
        dataIndex: "register_catch",
        key: "register_catch",
      },
    ];

    let blockedTableData = [];
    if (this.props.customer_block.data) {
      const { cards, packages, free_packages, season_cards } = this.props.customer_block.data;

      blockedTableData = [
        ...cards.map((i) => ({
          key: `fc_${i.id}`,
          beat: i.beat?.name,
          river: i.beat?.river?.name,
          card_id: i.id,
          date_start: moment(i.date).format(DATE_FORMAT),
          date_end: moment(i.date).format(DATE_FORMAT),
          type: this.props.intl.formatMessage({ id: "fishing_card" }),
          register_catch: (
            <NavLink
              to={`/new-catch?b=${i.beat?.id || ""}&r=${i.beat?.river?.id || ""}&u=${this.props.match.params.id}&fc=${i.id}`}
            >
              <Button type="primary" size={ANT_CONFIG.size}>
                {this.props.intl.formatMessage({ id: "register_catch" })}
              </Button>
            </NavLink>
          ),
        })),
        ...packages.map((i) => ({
          key: `p_${i.id}`,
          beat: i.fishing_cards?.[0]?.beat?.name,
          river: i.fishing_cards?.[0]?.beat?.river?.name,
          card_id: i.id,
          date_start: moment(
            i.fishing_cards?.map((i) => i.date).reduce((pre, cur) => (Date.parse(pre) > Date.parse(cur) ? cur : pre)),
          ).format(DATE_FORMAT),
          date_end: moment(
            i.fishing_cards?.map((i) => i.date).reduce((pre, cur) => (Date.parse(pre) < Date.parse(cur) ? cur : pre)),
          ).format(DATE_FORMAT),
          type: this.props.intl.formatMessage({ id: "fishing_package" }),
          register_catch: (
            <NavLink
              to={`/new-catch?b=${i.beat?.id || ""}&r=${i.beat?.river?.id || ""}&u=${this.props.match.params.id}&fp=${i.id}`}
            >
              <Button type="primary" size={ANT_CONFIG.size}>
                {this.props.intl.formatMessage({ id: "register_catch" })}
              </Button>
            </NavLink>
          ),
        })),
        ...free_packages.map((i) => ({
          key: `fc_${i.id}`,
          beat: i.beat?.name,
          river: i.beat?.river?.name,
          card_id: i.id,
          date_start: moment(i.date_start).format(DATE_FORMAT),
          date_end: moment(i.date_end).format(DATE_FORMAT),
          type: this.props.intl.formatMessage({ id: "pack" }),
          register_catch: (
            <NavLink
              to={`/new-catch?b=${i.beat?.id || ""}&r=${i.beat?.river?.id || ""}&u=${this.props.match.params.id}&p=${i.id}`}
            >
              <Button type="primary" size={ANT_CONFIG.size}>
                {this.props.intl.formatMessage({ id: "register_catch" })}
              </Button>
            </NavLink>
          ),
        })),
        ...season_cards.map((i) => ({
          key: `sc_${i.id}`,
          beat: i.beat?.name,
          river: i.beat?.river?.name,
          card_id: i.id,
          date_start: moment(i.season_start_date).format(DATE_FORMAT),
          date_end: moment(i.season_end_date).format(DATE_FORMAT),
          type: this.props.intl.formatMessage({ id: "season_card" }),
          register_catch: (
            <NavLink
              to={`/new-catch?b=${i.beat?.id || ""}&r=${i.beat?.river?.id || ""}&u=${this.props.match.params.id}&sc=${i.id}`}
            >
              <Button type="primary" size={ANT_CONFIG.size}>
                {this.props.intl.formatMessage({ id: "register_catch" })}
              </Button>
            </NavLink>
          ),
        })),
      ];
    }

    const disinfectionsTableColumns = [
      {
        title: "ID",
        dataIndex: "key",
        key: "key",
      },
      {
        title: this.props.intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
      },
      {
        title: this.props.intl.formatMessage({ id: "station" }),
        dataIndex: "station",
        key: "station",
      },
    ];
    const disinfectionsTableData = this.state.data.disinfections?.map((item) => {
      return {
        key: item.id,
        date: moment(item.created_at).format("DD.MM.YYYY"),
        station: item.station ? `${item.station.name} (ID: ${item.station_id})` : item.station_id,
      };
    });

    const productsForSupervisorColumns = [
      {
        title: "ID",
        dataIndex: "key",
        key: "key",
      },
      {
        title: this.props.intl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
      },
      {
        title: this.props.intl.formatMessage({ id: "owner_name" }),
        dataIndex: "owner_name",
        key: "owner_name",
      },
      {
        title: this.props.intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
      },
      {
        title: this.props.intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: this.props.intl.formatMessage({ id: "all_documents_ok" }),
        dataIndex: "status",
        key: "status",
      },
      {
        title: "",
        dataIndex: "comments",
        key: "comments",
      },
    ];

    const productsForSupervisor = [];
    let productsForSupervisorData = null;

    if (!this.state.isNew && (isAdmin || isSupervisorAdmin)) {
      this.props.orders.data_customer?.forEach((orderData) => {
        if (orderData.packages?.length > 0) {
          orderData.packages.forEach((orderPackage) => {
            if (orderPackage.fishing_cards?.length > 0) {
              orderPackage.fishing_cards.forEach((item) => {
                productsForSupervisor.push({
                  ...item,
                  type: "fishing_card",
                });
              });
            }

            if (orderPackage.free_packages?.length > 0) {
              orderPackage.free_packages.forEach((item) => {
                productsForSupervisor.push({
                  ...item,
                  type: "free_package",
                });
              });
            }

            if (orderPackage.season_fishing_cards?.length > 0) {
              orderPackage.season_fishing_cards.forEach((item) => {
                productsForSupervisor.push({
                  ...item,
                  type: "season_fishing_card",
                });
              });
            }
          });
        }
      });

      productsForSupervisorData = productsForSupervisor?.map((item) => {
        let date = "-";
        let status = null;
        const { type, owner_name, beat, id, controller_comments } = item;

        if (type === "fishing_card") {
          date = moment(item.date).format("DD.MM.YYYY");
        }

        if (type === "free_package") {
          date = `${moment(item.date_start).format("DD.MM.YYYY")} - ${moment(item.date_end).format("DD.MM.YYYY")}`;
        }

        if (type === "season_fishing_card") {
          date = `${moment(item.season_start_date).format("DD.MM.YYYY")} - ${moment(item.season_end_date).format(
            "DD.MM.YYYY",
          )}`;
        }

        if (controller_comments?.length > 0) {
          ({ status } = controller_comments[0]);
        }

        return {
          key: id,
          type: this.props.intl.formatMessage({ id: type }),
          owner_name: owner_name || "-",
          date,
          beat: beat?.name || "-",
          status:
            status !== null ? (
              <Tag color={status ? "green" : "red"} className="ant-tag">
                {this.props.intl.formatMessage({ id: status ? "yes" : "no" })}
              </Tag>
            ) : (
              <span>-</span>
            ),
          comments: (
            <>
              <Button
                type="primary"
                onClick={() => this.showAddCommentModal(item)}
                style={{ marginLeft: 5, marginRight: 5, marginBottom: 5 }}
              >
                {this.props.intl.formatMessage({ id: "add_comment" })}
              </Button>
              {controller_comments?.length > 0 && (
                <Button
                  onClick={() => this.showCommentsModal(controller_comments)}
                  style={{ marginLeft: 5, marginRight: 5, marginBottom: 5 }}
                >
                  {this.props.intl.formatMessage({ id: "show_comments" })}
                </Button>
              )}
            </>
          ),
        };
      });
    }

    return (
      <>
        <Row>
          <Col xs={12}>
            <div className="panel">
              <h2 className="title">
                {this.state.isNew
                  ? this.props.intl.formatMessage({ id: "new_user" })
                  : this.props.intl.formatMessage({ id: "update_user" })}
              </h2>
              <Form>
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} sm={6}>
                        {!this.state.isNew ? (
                          <FormGroup row>
                            <Label for="customer_id" xs={12} sm={labelWidth}>
                              ID
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <Input
                                disabled
                                type="number"
                                name="customer_id"
                                id="customer_id"
                                defaultValue={this.state.data.id}
                              />
                            </Col>
                          </FormGroup>
                        ) : null}
                        <FormGroup row>
                          <Label for="first_name" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "first_name" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              type="text"
                              name="first_name"
                              id="first_name"
                              value={this.state.data.first_name || ""}
                              onChange={(e) => this.onChangeValue({ first_name: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="last_name" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "last_name" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              type="text"
                              name="last_name"
                              id="last_name"
                              value={this.state.data.last_name || ""}
                              onChange={(e) => this.onChangeValue({ last_name: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="phone" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "phone" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              type="text"
                              name="phone"
                              id="phone"
                              value={this.state.data.phone || ""}
                              onChange={(e) => this.onChangeValue({ phone: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="email" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "email" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              disabled={!this.state.isNew && !isAdmin}
                              type="email"
                              name="email"
                              id="email"
                              value={this.state.data.email || ""}
                              onChange={(e) => this.onChangeValue({ email: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="address" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "address" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              type="text"
                              name="address"
                              id="address"
                              value={this.state.data.address || ""}
                              onChange={(e) => this.onChangeValue({ address: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                      </Col>

                      <Col xs={12} sm={6}>
                        <FormGroup row>
                          <Label xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "post_code" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              max={5}
                              type="text"
                              name="post_code"
                              value={this.state.data.post_code || ""}
                              onChange={(e) => this.onChangePostalCode(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "city" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              type="text"
                              name="city"
                              value={this.state.data.city || ""}
                              onChange={(e) => this.onChangeValue({ city: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="country" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "country" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <SelectInput
                              id="country"
                              name="country"
                              intl={this.props.intl}
                              value={COUNTRY_CODES.map((i) => {
                                return {
                                  label: i.value,
                                  value: i.value,
                                };
                              }).filter((i) => i.value === this.state.data.country)}
                              onChange={(e) => this.onChangeCountry(e)}
                              options={COUNTRY_CODES.map((i) => {
                                return {
                                  label: i.value,
                                  value: i.value,
                                };
                              })}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label for="birth_date" xs={12} sm={labelWidth}>
                            {this.props.intl.formatMessage({ id: "birth_date" })}
                          </Label>
                          <Col xs={12} sm={inputWidth}>
                            <Input
                              type="date"
                              name="birth_date"
                              id="birth_date"
                              value={this.state.data.birth_date || ""}
                              onChange={(e) => this.onChangeValue({ birth_date: e.target.value })}
                            />
                          </Col>
                        </FormGroup>
                        {isAdmin ? (
                          <FormGroup row>
                            <Label for="user_active" xs={12} sm={labelWidth}>
                              {`${this.props.intl.formatMessage({ id: "is_user_active" })}?`}
                            </Label>
                            <Col xs={12} sm={inputWidth}>
                              <SelectInput
                                id="user_active"
                                name="user_active"
                                intl={this.props.intl}
                                value={
                                  this.state.data.active !== undefined
                                    ? userActive.filter((i) => i.value === this.state.data.active)
                                    : userActive[0]
                                }
                                onChange={(e) => this.onChangeValue({ active: e.value })}
                                options={userActive}
                              />
                            </Col>
                          </FormGroup>
                        ) : null}
                      </Col>
                    </Row>

                    {this.props.authentication.data.can_add_own ? (
                      <>
                        <hr />
                        <Row>
                          <Col xs={12} sm={6}>
                            <Row style={{ marginBottom: 35 }}>
                              <Col xs={12} sm={labelWidth} />
                              <Col xs={12} sm={inputWidth}>
                                <CheckboxGroup
                                  className="checkbox-group"
                                  checkboxDepth={2}
                                  name="old_customer"
                                  value={this.state.data.old_customer || ""}
                                  onChange={(e) => this.onChangeOldUser(e)}
                                >
                                  <Label>
                                    <Checkbox value={1} />
                                    <span className="mark" />
                                    <span className="text-label">
                                      {this.props.intl.formatMessage({ id: "this_is_my_customer" })}
                                    </span>
                                  </Label>
                                </CheckboxGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={12} sm={6} />
                        </Row>
                      </>
                    ) : null}
                  </Col>

                  <Col xs={12}>
                    {isAdmin ? (
                      <div>
                        <h2 className="title">{this.props.intl.formatMessage({ id: "river_beat_panel" })}</h2>

                        <FormGroup row className="user-modal-roles">
                          <Label for="role" xs={12} className="text-left">
                            {`${this.props.intl.formatMessage({ id: "user_role" })}`}
                          </Label>
                          <Col xs={12}>
                            <CheckboxGroup
                              className="checkbox-group"
                              checkboxDepth={2}
                              name="roles"
                              value={this.state.data.roles || ""}
                              onChange={(e) => this.onChangeValue({ roles: e })}
                            >
                              {USER_ROLES.map((role) => (
                                <Label key={role.id}>
                                  <Checkbox value={role.value} />
                                  <span className="mark" />
                                  <span className="text-label">{this.props.intl.formatMessage({ id: role.value })}</span>
                                </Label>
                              ))}
                            </CheckboxGroup>
                          </Col>
                        </FormGroup>

                        {(this.state.data.roles && this.state.data.roles.length > 0) || this.state.isNew ? (
                          <FormGroup row>
                            {isAdmin && this.state.data.roles && this.state.data.roles.includes(RIVER_ADMIN) ? (
                              <Col xs={12} sm={6}>
                                {this.props.rivers ? (
                                  <OptionList
                                    label={this.props.intl.formatMessage({ id: "select_river" })}
                                    intl={this.props.intl}
                                    options={this.props.rivers.data.map((river) => ({
                                      value: river.id,
                                      label: river.name,
                                    }))}
                                    values={this.state.data.river}
                                    onNewOption={(e) => this.onNewOption("river", e)}
                                    onRemoveOption={(e) => this.onRemoveOption("river", e)}
                                  />
                                ) : null}
                              </Col>
                            ) : null}

                            <Col xs={12} sm={6}>
                              {this.props.beats && this.state.data.roles && this.state.data.roles.includes(BEAT_ADMIN) ? (
                                <OptionList
                                  label={this.props.intl.formatMessage({ id: "select_beat" })}
                                  intl={this.props.intl}
                                  options={this.props.beats.data.map((beat) => ({
                                    value: beat.id,
                                    label: `${beat.name ? beat.name : "EMPTY BEAT NAME"}  (${
                                      beat.river?.name ? beat.river.name : "EMPTY RIVER NAME"
                                    })`,
                                  }))}
                                  values={this.state.data.beat}
                                  onNewOption={(e) => this.onNewOption("beat", e)}
                                  onRemoveOption={(e) => this.onRemoveOption("beat", e)}
                                />
                              ) : null}
                            </Col>
                          </FormGroup>
                        ) : null}
                      </div>
                    ) : null}
                  </Col>

                  <Col xs={12}>
                    {isRiverAdmin || isAdmin ? (
                      <div>
                        <h2 className="title">{this.props.intl.formatMessage({ id: "controller_panel" })}</h2>

                        <FormGroup row className="user-modal-roles">
                          <Col xs={12}>
                            <CheckboxGroup
                              className="checkbox-group"
                              checkboxDepth={2}
                              name="roles"
                              value={this.state.userIsSupervisor ? [SUPERVISOR] : []}
                              onChange={() => this.onChangeSupervisorRole()}
                            >
                              {SUPERVISOR_USER_ROLES.map((role) => (
                                <Label key={role.id}>
                                  <Checkbox value={role.value} />
                                  <span className="mark" />
                                  <span className="text-label">{this.props.intl.formatMessage({ id: role.value })}</span>
                                </Label>
                              ))}
                            </CheckboxGroup>
                          </Col>

                          {this.state.userIsSupervisor && this.props.rivers && (
                            <Col xs={12} sm={6}>
                              <OptionList
                                label={this.props.intl.formatMessage({ id: "select_river" })}
                                intl={this.props.intl}
                                options={this.props.rivers.data.map((i) => ({
                                  value: i.id,
                                  label: i.name,
                                }))}
                                values={this.state.data.controller_rivers}
                                onNewOption={(e) => this.onNewOption("controller_rivers", e)}
                                onRemoveOption={(e) => this.onRemoveOption("controller_rivers", e)}
                              />
                            </Col>
                          )}
                        </FormGroup>
                      </div>
                    ) : null}
                  </Col>

                  <Col xs={12}>
                    {isBeatAdmin || isAdmin ? (
                      <div>
                        <h2 className="title">{this.props.intl.formatMessage({ id: "seller_panel" })}</h2>

                        <FormGroup row className="user-modal-roles">
                          <Col xs={12}>
                            <CheckboxGroup
                              className="checkbox-group"
                              checkboxDepth={2}
                              name="roles"
                              value={this.state.userIsSeller ? [SELLER] : []}
                              onChange={() => this.onChangeSellerRole()}
                            >
                              {SELLER_USER_ROLES.map((role) => (
                                <Label key={role.id}>
                                  <Checkbox value={role.value} />
                                  <span className="mark" />
                                  <span className="text-label">{this.props.intl.formatMessage({ id: role.value })}</span>
                                </Label>
                              ))}
                            </CheckboxGroup>
                          </Col>

                          {this.state.userIsSeller && this.props.beats && (
                            <Col xs={12} sm={6}>
                              <OptionList
                                label={this.props.intl.formatMessage({ id: "select_beat" })}
                                intl={this.props.intl}
                                options={this.props.beats.data.map((beat) => ({
                                  value: beat.id,
                                  label: `${beat.name ? beat.name : "EMPTY BEAT NAME"}  (${
                                    beat.river?.name ? beat.river.name : "EMPTY RIVER NAME"
                                  })`,
                                }))}
                                values={this.state.data.seller_beat}
                                onNewOption={(e) => this.onNewOption("seller_beat", e)}
                                onRemoveOption={(e) => this.onRemoveOption("seller_beat", e)}
                              />
                            </Col>
                          )}
                        </FormGroup>
                      </div>
                    ) : null}
                  </Col>

                  <Col xs={12}>
                    {isRiverAdmin || isAdmin ? (
                      <div>
                        <h2 className="title">{this.props.intl.formatMessage({ id: "station_panel" })}</h2>

                        <FormGroup row>
                          <Col xs={12}>
                            <CheckboxGroup
                              className="checkbox-group"
                              checkboxDepth={2}
                              name="roles"
                              value={this.state.userHasStations ? [STATION_ADMIN] : []}
                              onChange={() => this.onChangeStationRole()}
                            >
                              {STATION_USER_ROLES.map((role) => (
                                <Label key={role.id}>
                                  <Checkbox value={role.value} />
                                  <span className="mark" />
                                  <span className="text-label">{this.props.intl.formatMessage({ id: role.value })}</span>
                                </Label>
                              ))}
                            </CheckboxGroup>
                          </Col>
                        </FormGroup>

                        {this.state.userHasStations ? (
                          <FormGroup row>
                            <Col xs={12} sm={6}>
                              {this.props.stations ? (
                                <OptionList
                                  label={this.props.intl.formatMessage({ id: "select_station" })}
                                  intl={this.props.intl}
                                  options={this.props.stations.data.map((i) => ({
                                    value: i.id,
                                    label: `${i.name} (${i.id})`,
                                  }))}
                                  values={this.state.data.station}
                                  onNewOption={(e) => this.onNewOption("station", e, true)}
                                  onRemoveOption={(e) => this.onRemoveOption("station", e, true)}
                                />
                              ) : null}
                            </Col>
                          </FormGroup>
                        ) : null}
                      </div>
                    ) : null}
                  </Col>

                  {!this.state.isNew && (isAdmin || isRiverAdmin || isSupervisorAdmin) && (
                    <Col xs={12}>
                      <h2 className="title">{this.props.intl.formatMessage({ id: "ban_user" })}</h2>

                      <FormGroup row>
                        {(isRiverAdmin || isAdmin || isSupervisorAdmin) && this.props.rivers && (
                          <Col xs={12} sm={6}>
                            <OptionList
                              label={this.props.intl.formatMessage({ id: "select_river" })}
                              intl={this.props.intl}
                              options={this.props.rivers.data
                                .filter((i) => {
                                  if (isSupervisorAdmin) {
                                    return authentication.data.controller_rivers.includes(i.id);
                                  }

                                  if (isRiverAdmin) {
                                    return authentication.data.river_ids.includes(i.id);
                                  }

                                  return i;
                                })
                                .map((i) => ({
                                  value: i.id,
                                  label: i.name,
                                }))}
                              values={this.state.ban_river}
                              onNewOption={(e) => this.onUserBan("ban_river", "river_id", e)}
                              onRemoveOption={(e) => this.onUserLiftBan("ban_river", "river_id", e)}
                            />
                          </Col>
                        )}

                      </FormGroup>
                    </Col>
                  )}

                  {(isAdmin || isBeatAdmin) && (
                    <Col xs={12}>
                      <h2 className="title">{this.props.intl.formatMessage({ id: "member_of_beat" })}</h2>

                      <FormGroup row>
                        {this.props.beats && (
                          <Col xs={12} sm={6}>
                            <OptionList
                              label={this.props.intl.formatMessage({ id: "select_beat" })}
                              intl={this.props.intl}
                              options={this.props.beats.data.map((i) => ({
                                value: i.id,
                                label: `${i.name} (${i.river?.name})`,
                              }))}
                              values={this.state.add_member}
                              onNewOption={(e) => this.onBeatAddMember(e)}
                              onRemoveOption={(e) => this.onBeatRemoveMember(e)}
                            />
                          </Col>
                        )}
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </Form>
              <Row>
                <Col xs={12} className="text-right">
                  <Button size={ANT_CONFIG.size} type="primary" onClick={(e) => this.onSave(e)}>
                    {this.props.intl.formatMessage({ id: "save" })}
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {!this.state.isNew && (
          <>
            <Row>
              <Col xs={12} style={{ marginTop: 30 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "orders" })}</h2>

                  <Table
                    loading={{ spinning: this.props.orders.loading, indicator: SPIN_ICON }}
                    columns={ordersTableColumns}
                    dataSource={ordersTableData}
                    pagination={ANT_CONFIG.pagination}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} style={{ marginTop: 30 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "catches" })}</h2>

                  <Table
                    loading={{ spinning: this.props.laksebors.loading, indicator: SPIN_ICON }}
                    columns={catchesTableColumns}
                    dataSource={catchesTableData}
                    pagination={ANT_CONFIG.pagination}
                  />
                </div>
              </Col>
            </Row>

            {this.state.data.soft_blocked ? (
              <Row>
                <Col xs={12} style={{ marginTop: 30 }}>
                  <div className="panel">
                    <h2 className="title">{this.props.intl.formatMessage({ id: "user_blocked_cards" })}</h2>

                    <Table
                      loading={{ spinning: this.props.customer_block.loading, indicator: SPIN_ICON }}
                      columns={blockedTableColumns}
                      dataSource={blockedTableData}
                      pagination={ANT_CONFIG.pagination}
                    />
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
                <div className="panel">
                  <h2 className="title">{this.props.intl.formatMessage({ id: "disinfections" })}</h2>

                  <Table
                    columns={disinfectionsTableColumns}
                    dataSource={disinfectionsTableData}
                    pagination={ANT_CONFIG.pagination}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}

        {!this.state.isNew && (isAdmin || isSupervisorAdmin) && (
          <Row>
            <Col xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
              <div className="panel">
                <h2 className="title">{this.props.intl.formatMessage({ id: "controller_products" })}</h2>
                <Table
                  loading={{ spinning: this.props.orders.loading, indicator: SPIN_ICON }}
                  columns={productsForSupervisorColumns}
                  dataSource={productsForSupervisorData}
                  pagination={ANT_CONFIG.pagination}
                />
              </div>
            </Col>
          </Row>
        )}

        <Scribe
          intl={this.props.intl}
          hasMultiple
          items={[
            {
              title: this.props.intl.formatMessage({ id: "scribe_customer_add_bailiff" }),
              link: "https://scribehow.com/embed/Oppsyn__Legge_til_og_fjerne_bruker_som_oppsyn__q038TMD4TDSYKW4TASjtcQ",
            },
            {
              title: this.props.intl.formatMessage({ id: "scribe_customer_add_station_admin" }),
              link: "https://scribehow.com/embed/Stasjons_administrator__Legge_til_og_fjerne_bruker_som_stasjons_administrator__Nl0FpIDzQb6ZzGieuI12ug",
            },
            {
              title: this.props.intl.formatMessage({ id: "scribe_customer_how_to_block_user" }),
              link: "https://scribehow.com/embed/Sperre_bruker_for_kjop_viser_ogsa_hvordan_man_apner__-kWDFJYMQ4eowJGUNzMAxg",
            },
            {
              title: this.props.intl.formatMessage({ id: "scribe_customer_how_to_check_blocked_user" }),
              link: "https://scribehow.com/embed/Blokkerte_brukere__sjekke_hvem_som_er_blokkert_og_sperre_de_for_kjop__KmLoZnopQLa8GtZbEPeg0A",
            },
          ]}
        />

        <CatchInfo data={this.state.modalDetails} closeModal={this.closeModal} />

        <CommentsList data={this.state.modalComments} closeModal={this.closeCommentsModal} />

        <AddComment data={this.state.addCommentModal} closeModal={this.closeAddCommentModal} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.Customers,
  authentication: state.Auth,
  rivers: state.Rivers,
  beats: state.Beats,
  orders: state.Orders,
  laksebors: state.Laksebors,
  stations: state.Stations,
  stationrole: state.Stationrole,
  customer_block: state.CustomerBlock,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Customer)));
