import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/beat/${action.id}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_BEAT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_BEAT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_BEAT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* update(action) {
  try {
    const response = yield call(API.call, `admin/beat/${action.id}`, "PUT", action.data);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UPDATE_BEAT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UPDATE_BEAT_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, action);
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UPDATE_BEAT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

// action.payload.data -> {reporting_beat_id, selling_beat_id}
function* addSellingBeat(action) {
  if (!action.payload.data.reporting_beat_id || !action.payload.data.selling_beat_id) {
    yield put({
      type: ACTION.ADD_SELLING_BEAT_FAILURE,
      message: 'Error when adding beat',
    })

    APIMessage.error('Error when adding beat')
    return
  }

  try {
    const response = yield call(API.call, `admin/beat/selling-beat`, "POST", action.payload.data)
    const responseBody = yield call([response, "json"])

    if (response.status >= 400) {
      yield put({
        type: ACTION.ADD_SELLING_BEAT_FAILURE,
        message: responseBody.errors,
      })

      APIMessage.error(responseBody.errors);
      return
    }

    yield call(fetch, { id: action.payload.data.reporting_beat_id })

    yield put({
      type: ACTION.ADD_SELLING_BEAT_SUCCESS,
      payload: responseBody,
    })

    APIMessage.success(responseBody.data.message)
  } catch (e) {
    yield put({
      type: ACTION.ADD_SELLING_BEAT_FAILURE,
      message: e.errors,
    })

    APIMessage.error(e.errors);
  }
}

// action.payload.data -> {reporting_beat_id, selling_beat_id}
function* removeSellingBeat(action) {
  if (!action.payload.data.reporting_beat_id || !action.payload.data.selling_beat_id) {
    yield put({
      type: ACTION.REMOVE_SELLING_BEAT_FAILURE,
      message: 'Error when removing beat',
    })

    APIMessage.error('Error when removing beat')
    return
  }

  try {
    const response = yield call(API.call, `admin/beat/selling-beat/${action.payload.data.reporting_beat_id}/${action.payload.data.selling_beat_id}`, "DELETE")
    const responseBody = yield call([response, "json"])

    if (response.status >= 400) {
      yield put({
        type: ACTION.REMOVE_SELLING_BEAT_FAILURE,
        message: responseBody.errors,
        payload: action.payload,
      })

      APIMessage.error(responseBody.errors);
      return
    }

    yield call(fetch, { id: action.payload.data.reporting_beat_id })

    yield put({
      type: ACTION.REMOVE_SELLING_BEAT_SUCCESS,
      payload: {
        ...responseBody,
        ...action.payload,
      }
    })

    APIMessage.success(responseBody.data.message)
  
  } catch (e) {
    yield put({
      type: ACTION.REMOVE_SELLING_BEAT_FAILURE,
      message: e.errors,
      payload: action.payload,
    })

    APIMessage.error(e.errors)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_BEAT, fetch),
    takeEvery(ACTION.UPDATE_BEAT, update),
    takeEvery(ACTION.ADD_SELLING_BEAT, addSellingBeat),
    takeEvery(ACTION.REMOVE_SELLING_BEAT, removeSellingBeat),
  ]);
}
