import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Row, Col, FormGroup, Label, Form, Input, Button } from "reactstrap";
import { Checkbox, CheckboxGroup } from "react-checkbox-group";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import Spinner from "../../components/spinner";
import SelectInput from "../../components/select";
import SaveLabel from "../../components/helpers/buttons/save_label";
import beatOptions from "../../services/select_beat_options";
import riverOptions from "../../services/select_river_options";
import { fetch as fetchBeatOptions, editCardLimit as editBeatCardLimit } from "../../redux/beat_options/actions";
import { fetch as fetchRiverOptions, editCardLimit as editRiverCardLimit } from "../../redux/river_options/actions";
import Scribe from "../../components/Scribe";

class CardLimits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      river_id: this.props.rivers.selected || this.props.authentication.data.river[0],
      beat_id: this.props.beats.selected || this.props.authentication.data.beat[0],
      data_river: {},
      data_beat: {}
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    if (this.props.authentication.data.river[0]) {
      this.handleFetchRiverOptions();
    }

    if (this.props.authentication.data.beat[0]) {
      this.handleFetchBeatOptions();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.beat_options !== this.props.beat_options) {
      this.setState({
        data_beat: {
          limit:
            nextProps.beat_options.data &&
            nextProps.beat_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0] &&
            nextProps.beat_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0].value > 0
              ? nextProps.beat_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0].value
              : 1,
          enabled:
            nextProps.beat_options.data &&
            nextProps.beat_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0] &&
            nextProps.beat_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0].value > 0
        }
      });
    }

    if (nextProps.river_options !== this.props.river_options) {
      this.setState({
        data_river: {
          limit:
            nextProps.river_options.data &&
            nextProps.river_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0] &&
            nextProps.river_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0].value > 0
              ? nextProps.river_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0].value
              : 1,
          enabled:
            nextProps.river_options.data &&
            nextProps.river_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0] &&
            nextProps.river_options.data.filter(i => i.key === "card_per_user_per_day_limit")[0].value > 0
        }
      });
    }
  }

  onChangeRiverValue(newState) {
    this.setState(prevState => ({ data_river: { ...prevState.data_river, ...newState } }));
  }

  onChangeBeatValue(newState) {
    this.setState(prevState => ({ data_beat: { ...prevState.data_beat, ...newState } }));
  }

  onRiverSubmit() {
    const data = {
      limit: this.state.data_river.enabled ? this.state.data_river.limit : 0,
      river_id: this.state.river_id
    };

    this.props.dispatch(editRiverCardLimit(data, this.state.river_id));
  }

  onBeatSubmit() {
    const data = {
      limit: this.state.data_beat.enabled ? this.state.data_beat.limit : 0,
      beat_id: this.state.beat_id
    };

    this.props.dispatch(editBeatCardLimit(data, this.state.beat_id));
  }

  handleFetchRiverOptions() {
    this.props.dispatch(fetchRiverOptions(this.state.river_id));
  }

  handleFetchBeatOptions() {
    this.props.dispatch(fetchBeatOptions(this.state.beat_id));
  }

  handleRiverChange(e) {
    this.props.dispatch(selectRiver(e));
    this.setState({ river_id: e }, () => {
      this.handleFetchRiverOptions();
    });
  }

  handleBeatChange(e) {
    this.props.dispatch(selectBeat(e));
    this.setState({ beat_id: e }, () => {
      this.handleFetchBeatOptions();
    });
  }

  render() {
    if (this.props.beats.loading || this.props.rivers.loading || !this.props.beats.data || !this.props.rivers.data) {
      return <Spinner />;
    }

    const selectRivers = riverOptions(this.props.rivers, this.props.authentication);

    const selectBeats = beatOptions(this.props.beats, this.props.authentication);

    return (
      <div>
        {selectRivers.length > 1 ? (
          <div style={{ marginBottom: 30 }} className="panel">
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row style={{ marginBottom: 2 }}>
                    <Label for="select_river" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({ id: "select_river" })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <SelectInput
                        id="select_river"
                        name="select_river"
                        intl={this.props.intl}
                        value={selectRivers.filter(i => i.value === this.state.river_id)}
                        onChange={e => this.handleRiverChange(e.value)}
                        options={selectRivers}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>
        ) : null}

        {selectRivers.length > 0 ? (
          <div>
            {this.props.river_options.loading ? (
              <Spinner style={{ marginBottom: 30 }} />
            ) : (
              <div className="panel" style={{ paddingTop: 20, marginBottom: 30 }}>
                <Form>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row>
                        <Col xs={12} sm={6} style={{ textAlign: "right" }}>
                          <CheckboxGroup
                            className="checkbox-group"
                            checkboxDepth={2}
                            name="river_limit_enabled"
                            value={this.state.data_river.enabled ? [1] : []}
                            onChange={() => this.onChangeRiverValue({ enabled: !this.state.data_river.enabled })}
                          >
                            <Label>
                              <Checkbox value={1} />
                              <span className="mark" />
                              <span className="text-label">{this.props.intl.formatMessage({ id: "turn_on_river_card_limit" })}</span>
                            </Label>
                          </CheckboxGroup>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Input
                            type="number"
                            name="river_limit"
                            id="river_limit"
                            disabled={!this.state.data_river.enabled}
                            value={this.state.data_river.enabled ? this.state.data_river.limit : ""}
                            onChange={e => this.onChangeRiverValue({ limit: e.target.value > 0 ? e.target.value : 1 })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <hr />
                      <FormGroup row style={{ marginBottom: 0 }}>
                        <Col xs={12} className="text-right">
                          <Button color="secondary" type="submit" onClick={() => this.onRiverSubmit()}>
                            <SaveLabel intl={this.props.intl} />
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </div>
        ) : null}

        {selectBeats.length > 1 ? (
          <div style={{ marginBottom: 30 }} className="panel">
            <Row>
              <Col xs={12}>
                <Form>
                  <FormGroup row style={{ marginBottom: 2 }}>
                    <Label for="select_beat" xs={12} sm={2}>
                      {`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                    </Label>
                    <Col xs={12} sm={10}>
                      <SelectInput
                        id="select_beat"
                        name="select_beat"
                        intl={this.props.intl}
                        value={selectBeats.filter(i => i.value === this.state.beat_id)}
                        onChange={e => this.handleBeatChange(e.value)}
                        options={selectBeats}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>
        ) : null}

        {selectBeats.length > 0 ? (
          <div>
            {this.props.beat_options.loading ? (
              <Spinner />
            ) : (
              <div className="panel" style={{ paddingTop: 20 }}>
                <Form>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row>
                        <Col xs={12} sm={6} style={{ textAlign: "right" }}>
                          <CheckboxGroup
                            className="checkbox-group"
                            checkboxDepth={2}
                            name="beat_limit_enabled"
                            value={this.state.data_beat.enabled ? [1] : []}
                            onChange={() => this.onChangeBeatValue({ enabled: !this.state.data_beat.enabled })}
                          >
                            <Label>
                              <Checkbox value={1} />
                              <span className="mark" />
                              <span className="text-label">{this.props.intl.formatMessage({ id: "turn_on_beat_card_limit" })}</span>
                            </Label>
                          </CheckboxGroup>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Input
                            type="number"
                            name="beat_limit"
                            id="beat_limit"
                            disabled={!this.state.data_beat.enabled}
                            value={this.state.data_beat.enabled ? this.state.data_beat.limit : ""}
                            onChange={e => this.onChangeBeatValue({ limit: e.target.value > 0 ? e.target.value : 1 })}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <hr />
                      <FormGroup row style={{ marginBottom: 0 }}>
                        <Col xs={12} className="text-right">
                          <Button color="secondary" type="submit" onClick={() => this.onBeatSubmit()}>
                            <SaveLabel intl={this.props.intl} />
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </div>
        ) : null}
      <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Innstillinger_for_elva__kundeinformasjon_fangstrapportering_kortgrense__jtnzs_c8SdqDsJMEnDjFuw" />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth,
  beats: state.Beats,
  beat_options: state.BeatOptions,
  river_options: state.RiverOptions,
  rivers: state.Rivers
});

export default withRouter(injectIntl(connect(mapStateToProps)(CardLimits)));
