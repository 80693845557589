export const FETCH_CARD_OR_ORDER = "FETCH_CARD_OR_ORDER";
export const FETCH_CARD_OR_ORDER_SUCCESS = "FETCH_CARD_OR_ORDER_SUCCESS";
export const FETCH_CARD_OR_ORDER_FAILURE = "FETCH_CARD_OR_ORDER_FAILURE";

export const UPDATE_CARD = "UPDATE_CARD";
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS";
export const UPDATE_CARD_FAILURE = "UPDATE_CARD_FAILURE";

export const UPDATE_CARD_DATE = "UPDATE_CARD_DATE";
export const UPDATE_CARD_DATE_SUCCESS = "UPDATE_CARD_DATE_SUCCESS";
export const UPDATE_CARD_DATE_FAILURE = "UPDATE_CARD_DATE_FAILURE";

export const REMOVE_CARD_FROM_ORDER = "REMOVE_CARD_FROM_ORDER";
export const REMOVE_CARD_FROM_ORDER_SUCCESS = "REMOVE_CARD_FROM_ORDER_SUCCESS";
export const REMOVE_CARD_FROM_ORDER_FAILURE = "REMOVE_CARD_FROM_ORDER_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_CARD_OR_ORDER,
    payload,
  };
}

export function update(data, payload) {
  return {
    type: UPDATE_CARD,
    data,
    payload,
  };
}

export function updateDate(data, payload) {
  return {
    type: UPDATE_CARD_DATE,
    data,
    payload,
  };
}

export function remove(data, payload) {
  return {
    type: REMOVE_CARD_FROM_ORDER,
    data,
    payload,
  };
}
