import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Table, Card, Col, Row, PageHeader, Input, Button } from "antd";

import Spinner from "../../components/spinner";
import ANT_CONFIG from "../../constants/antconfig";
import { fetch as fetchRivers } from "../../redux/rivers/actions";
import { fetch as fetchZones } from "../../redux/zones/actions";
import AddNewZone from "../../components/modal/Zone/AddNewZone";
import EditZone from "../../components/modal/Zone/EditZone";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";

class Zones extends React.Component {
  static contextType = RolesContext;

  state = {
    showModalAddNew: false,
    editZone: null,
    filter: "",
  };

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    if (!this.props.zones.data) {
      this.props.dispatch(fetchZones());
    }
  }

  showModalAddNew = () => {
    this.setState({
      showModalAddNew: true,
    });
  };

  showModalEdit = (id) => {
    const zone = this.props.zones.data.find((z) => {
      return z.id === id;
    });

    this.setState({
      editZone: zone,
    });
  };

  hideModal = () => {
    this.setState({
      showModalAddNew: false,
      editZone: null,
    });

    this.props.dispatch(fetchZones());
  };

  handleFilterChange(filter) {
    this.setState({
      filter,
    });
  }

  render() {
    const { isRiverAdmin } = this.context;

    if (!this.props.rivers.data || !this.props.zones.data) {
      return <Spinner />;
    }

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: this.props.intl.formatMessage({ id: "name" }),
        dataIndex: "name",
        key: "name",
      },
      {
        title: this.props.intl.formatMessage({ id: "rivers" }),
        dataIndex: "rivers",
        key: "rivers",
      },
      {
        title: this.props.intl.formatMessage({ id: "beats" }),
        dataIndex: "beats",
        key: "beats",
      },
    ];

    let { data } = this.props.zones;

    if (isRiverAdmin) {
      const riverAdminData = [];
      data.forEach((i) => {
        i.rivers.forEach((r) => {
          if (this.props.authentication.data.river_ids.indexOf(r.id) > -1) {
            riverAdminData.push(i);
          }
        });
      });
      data = riverAdminData;
    }

    if (this.state.filter) {
      data = data.filter((i) => {
        const filter = this.state.filter.toLowerCase();
        let hasRiverMatch = false;
        i.rivers.forEach((r) => {
          if (r.name?.toLowerCase().includes(filter)) {
            hasRiverMatch = true;
          }
        });
        let hasBeatMatch = false;
        i.beats.forEach((b) => {
          if (b.name?.toLowerCase().includes(filter)) {
            hasBeatMatch = true;
          }
        });
        return i.id?.toString().includes(filter) || i.name?.toLowerCase().includes(filter) || hasRiverMatch || hasBeatMatch;
      });
    }

    const tableData = data.map((i, key) => {
      let rivers = "";
      i.rivers.forEach((r, rKey) => {
        if (rKey !== 0) {
          rivers += ", ";
        }
        rivers += r.name;
      });
      let beats = "";
      i.beats.forEach((b, bKey) => {
        if (bKey !== 0) {
          beats += ", ";
        }
        beats += b.name;
      });
      return {
        key: key + 1,
        id: i.id,
        name: i.name,
        rivers,
        beats,
      };
    });

    return (
      <div>
        <PageHeader
          title={this.props.intl.formatMessage({ id: "zones" })}
          extra={
            <Button onClick={this.showModalAddNew} loading={false} type="primary" key="add_new_zone" size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_new_zone" })}
            </Button>
          }
        >
          <Row>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                  <label htmlFor="search" className="ant-label">
                    {this.props.intl.formatMessage({ id: "search" })}
                  </label>
                  <Input
                    placeholder={this.props.intl.formatMessage({ id: "search" })}
                    id="search"
                    size={ANT_CONFIG.size}
                    value={this.state.filter}
                    onChange={(e) => this.handleFilterChange(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <AddNewZone visible={this.state.showModalAddNew} cancel={this.hideModal} />

        {this.state.editZone !== null && <EditZone visible zone={this.state.editZone} cancel={this.hideModal} />}

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
                onRow={(record) => {
                  return {
                    onClick: () => this.showModalEdit(record.id),
                  };
                }}
              />
            </Col>
          </Row>
        </Card>
        <Scribe intl={this.props.intl} srcLink="https://scribehow.com/embed/Innstillinger_for_elva__kundeinformasjon_fangstrapportering_kortgrense__jtnzs_c8SdqDsJMEnDjFuw" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  rivers: state.Rivers,
  zones: state.Zones,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Zones)));
