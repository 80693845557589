import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, "admin/orders", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ORDERS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ORDERS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ORDERS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchByProductDate(action) {
  try {
    const response = yield call(API.call, "admin/orders-by-product-date", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ORDERS_BY_PRODUCT_DATE_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ORDERS_BY_PRODUCT_DATE_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ORDERS_BY_PRODUCT_DATE_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchCustomerOrders(action) {
  try {
    const response = yield call(API.call, `admin/customer/orders/${action.payload}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_CUSTOMER_ORDERS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_CUSTOMER_ORDERS_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_CUSTOMER_ORDERS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* sendLink(action) {
  try {
    const response = yield call(API.call, `admin/resend-payment-link/${action.payload}`, "GET");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.SEND_PAYMENT_LINK_FAILURE,
        message: responseBody.errors,
        meta: action.meta,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.SEND_PAYMENT_LINK_SUCCESS,
        payload: responseBody,
        meta: action.meta,
      });
      APIMessage.success(responseBody.data.message);

      return {
        payload: responseBody,
      };
    }
  } catch (e) {
    yield put({
      type: ACTION.SEND_PAYMENT_LINK_FAILURE,
      message: e.errors,
      meta: action.meta,
    });
    APIMessage.error(e.errors);
  }
}

function* cancel(action) {
  try {
    const response = yield call(API.call, `admin/order/${action.data}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.CANCEL_ORDER_FAILURE,
        message: responseBody.errors,
        meta: action.meta,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetch, action);
      yield put({
        type: ACTION.CANCEL_ORDER_SUCCESS,
        payload: responseBody,
        meta: action.meta,
      });
      APIMessage.success(responseBody.data.message);

      return {
        payload: responseBody,
      };
    }
  } catch (e) {
    yield put({
      type: ACTION.CANCEL_ORDER_FAILURE,
      message: e.errors,
      meta: action.meta,
    });
    APIMessage.error(e.errors);
  }
}

function* cancelForUser(action) {
  try {
    const response = yield call(API.call, `admin/order/${action.data}`, "DELETE");
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.CANCEL_ORDER_FOR_USER_FAILURE,
        message: responseBody.errors,
        meta: action.meta,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield call(fetchCustomerOrders, action);
      yield put({
        type: ACTION.CANCEL_ORDER_FOR_USER_SUCCESS,
        payload: responseBody,
        meta: action.meta,
      });
      APIMessage.success(responseBody.data.message);

      return {
        payload: responseBody,
      };
    }
  } catch (e) {
    yield put({
      type: ACTION.CANCEL_ORDER_FOR_USER_FAILURE,
      message: e.errors,
      meta: action.meta,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_ORDERS, fetch),
    takeEvery(ACTION.FETCH_ORDERS_BY_PRODUCT_DATE, fetchByProductDate),
    takeEvery(ACTION.FETCH_CUSTOMER_ORDERS, fetchCustomerOrders),
    takeEvery(ACTION.SEND_PAYMENT_LINK, sendLink),
    takeEvery(ACTION.CANCEL_ORDER, cancel),
    takeEvery(ACTION.CANCEL_ORDER_FOR_USER, cancelForUser),
  ]);
}
