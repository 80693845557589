import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col } from "reactstrap";
import Spinner from "../../spinner";
import { remove as removeTax } from "../../../redux/taxes/actions";

class ModalRemoveTax extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      remove_id: props.modalData !== undefined ? props.modalData.items[0].id : null
    };
  }

  componentDidUpdate() {
    if (!this.props.taxes.loading && !this.props.taxes.error && this.state.submitted) {
      this.toggle();
    }
  }

  onSubmit() {
    this.setState({
      submitted: true
    });

    this.props.dispatch(removeTax(this.state.remove_id));
  }

  toggle() {
    this.props.toggleModal();
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.toggle()}>{this.props.intl.formatMessage({ id: "remove_tax" })}</ModalHeader>
        {this.props.taxes.loading ? (
          <Spinner />
        ) : (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>{this.props.intl.formatMessage({ id: "confirm_delete_question" })}</Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={() => this.onSubmit()}>
                {this.props.intl.formatMessage({ id: "remove" })}
              </Button>
              <Button type="submit" color="secondary" onClick={() => this.toggle()}>
                {this.props.intl.formatMessage({ id: "cancel" })}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  taxes: state.Taxes,
  accommodation: state.Accommodation
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalRemoveTax)));
