export const FETCH_NEWSFEED_ACTIVITIES = "FETCH_NEWSFEED_ACTIVITIES";
export const FETCH_NEWSFEED_ACTIVITIES_SUCCESS = "FETCH_NEWSFEED_ACTIVITIES_SUCCESS";
export const FETCH_NEWSFEED_ACTIVITIES_FAILURE = "FETCH_NEWSFEED_ACTIVITIES_FAILURE";

export const DELETE_NEWSFEED_ACTIVITY = "DELETE_NEWSFEED_ACTIVITY";
export const DELETE_NEWSFEED_ACTIVITY_SUCCESS = "DELETE_NEWSFEED_ACTIVITY_SUCCESS";
export const DELETE_NEWSFEED_ACTIVITY_FAILURE = "DELETE_NEWSFEED_ACTIVITY_FAILURE";

export function fetch() {
  return {
    type: FETCH_NEWSFEED_ACTIVITIES
  };
}

export function remove(payload) {
  return {
    type: DELETE_NEWSFEED_ACTIVITY,
    payload,
  };
}
