export const FETCH_FANGSTRAPP = "FETCH_FANGSTRAPP";
export const FETCH_FANGSTRAPP_SUCCESS = "FETCH_FANGSTRAPP_SUCCESS";
export const FETCH_FANGSTRAPP_FAILURE = "FETCH_FANGSTRAPP_FAILURE";

export const CONNECT_RIVER = "CONNECT_RIVER";
export const CONNECT_RIVER_SUCCESS = "CONNECT_RIVER_SUCCESS";
export const CONNECT_RIVER_FAILURE = "CONNECT_RIVER_FAILURE";

export const SUBMIT_REPORT = "SUBMIT_REPORT";
export const SUBMIT_REPORT_SUCCESS = "SUBMIT_REPORT_SUCCESS";
export const SUBMIT_REPORT_FAILURE = "SUBMIT_REPORT_FAILURE";

export function fetchFangstrapp() {
  return {
    type: FETCH_FANGSTRAPP
  };
}

export function connectRiver(payload) {
  return {
    type: CONNECT_RIVER,
    payload
  };
}

export function submitReport(payload) {
  return {
    type: SUBMIT_REPORT,
    payload
  };
}
