import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import moment from "moment";
import { Table, DatePicker, Card, Col, Tag, Row, PageHeader, Select, Input, Button } from "antd";

import Spinner from "../../components/spinner";
import ANT_CONFIG from "../../constants/antconfig";
import { fetch as fetchRivers, select as selectRiver } from "../../redux/rivers/actions";
import { fetchAll as fetchCatches, catchListFilter, catchParamFilter } from "../../redux/catches/actions";
import riverOptions from "../../services/select_river_options";
import { Link } from "react-router-dom";
class Catches extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";

    this.state = {
      filter: this.props.catches.filter.filter || "",
      data: {
        river_id: this.props.rivers.selected || this.props.authentication.data.river[0],
        date_start: moment(this.props.catches.filter.date_start).subtract(1, "month").format(`${this.dateFormat}`),
        date_end: moment(this.props.catches.filter.date_end).format(`${this.dateFormat}`),
      },
      beat_id: "",
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }

    if (!this.props.catches.data) {
      this.handleDataChange();
    }
  }

  onRowClick(id) {
    this.props.history.push(`/catch/${id}`);
  }

  handleFilterChange(filter) {
    this.props.dispatch(
      catchListFilter({
        ...this.props.catches.filter,
        filter,
      }),
    );

    this.setState({
      filter,
    });
  }

  handleDataChange() {
    this.props.dispatch(fetchCatches(this.state.data));
  }

  handleChangeDate(e, key) {
    this.props.dispatch(
      catchListFilter({
        ...this.props.catches.filter,
        [key]: moment(e).format(`${this.dateFormat}`),
      }),
    );

    this.setState(
      (prevState) => ({
        data: { ...prevState.data, [key]: moment(e).format(`${this.dateFormat}`) },
      }),
      () => {
        this.handleDataChange();
      },
    );
  }

  handleCatchParamFilter(data) {
    this.props.dispatch(catchParamFilter(data));
  }

  handleRiverChange(e) {
    this.props.dispatch(selectRiver(e));

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          river_id: e,
        },
        beat_id: "",
      }),
      () => {
        this.handleDataChange();
      },
    );
  }

  handleBeatSelect(e) {
    this.setState({ beat_id: e });
    if (e==="") {
      this.handleDataChange()
    } else {
       this.props.dispatch(fetchCatches({ ...this.state.data, ...{ beat_id: e } }));
    }
    
   
  }

  onNewCatchClick = () => {
    this.props.history.push("/new-catch");
  };

  render() {
    if (!this.props.rivers.data || this.props.catches.loading || !this.props.catches.data) {
      return <Spinner />;
    }

    const selectRivers = riverOptions(this.props.rivers, this.props.authentication);

    let data = this.props.catches.filteredData;

    if (this.state.filter) {
      data = data.filter((i) => {
        const filter = this.state.filter.toLowerCase();

        return (
          i.id?.toString().includes(filter) ||
          i.user?.first_name?.toLowerCase().includes(filter) ||
          i.user?.last_name?.toLowerCase().includes(filter) ||
          (i.user?.first_name &&
            i.user?.last_name &&
            `${i.user.first_name} ${i.user.last_name}`.toLowerCase().includes(filter)) ||
          i.fishing_card_id?.toString().includes(filter) ||
          i.user?.id?.toString().includes(filter) ||
          i.beat?.name?.toLowerCase().includes(filter) ||
          i.fish_type?.name?.toLowerCase().includes(filter) ||
          i.equipment?.name?.toLowerCase().includes(filter)
        );
      });
    }
    const catchCheckerRender = (no_catch) => {
      let color = "green";
      let noCatchText = this.props.intl.formatMessage({ id: "yes" });
      if (parseInt(no_catch, 10)) {
        color = "red";
        noCatchText = this.props.intl.formatMessage({ id: "no" });
      }
      return (
        <Tag color={color} className="ant-tag">
          {noCatchText}
        </Tag>
      );
    };

    const isEditedRender = (is_edited) => {
      return is_edited ? (
        <Tag color={"yellow"} className="ant-tag">
          {this.props.intl.formatMessage({ id: "catch_is_edited" })}
        </Tag>
      ) : (
        <Tag color={"green"} className="ant-tag">
          {this.props.intl.formatMessage({ id: "catch_not_edited" })}
        </Tag>
      );
    };

    const renderTranslation = (data, prefix) => {
      return data !== null && data !== '-' ? (
          <div>{this.props.intl.formatMessage({ id: prefix + data })}</div>
      ) : (
          <div>-</div>
      )
    };


    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        sorter: (a, b) => parseInt(a?.key) - parseInt(b?.key),
      },
      {
        title: this.props.intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
        title: this.props.intl.formatMessage({ id: "fisherman_id" }),
        dataIndex: "user_id",
        key: "user_id",
        sorter: (a, b) => (b.user_id !== "-") - (a.user_id !== "-") || a.user_id - b.user_id,
      },
      {
        title: this.props.intl.formatMessage({ id: "fisherman" }),
         dataIndex: "external_id",
        key: "external_id",
        sorter: (a, b) => (a.external_id === "-") - (b.external_id === "-") || ("" + a.external_id).localeCompare(b.external_id),
      },
      {
        title: this.props.intl.formatMessage({ id: "card_id" }),
        dataIndex: "card_id",
        key: "card_id",
        sorter: (a, b) => (b.card_id !== "-") - (a.card_id !== "-") || a.card_id - b.card_id,
      },
      {
        title: this.props.intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
        sorter: (a, b) => ("" + a.beat).localeCompare(b.beat),
      },
      {
        title: this.props.intl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => (a.type === "-") - (b.type === "-") || ("" + a.type).localeCompare(b.type),
        render: (data, _) => renderTranslation(data, "fish_type-"),
      },
      {
        title: this.props.intl.formatMessage({ id: "equipment" }),
        dataIndex: "equipment",
        key: "equipment",
        sorter: (a, b) => (a.equipment === "-") - (b.equipment === "-") || ("" + a.equipment).localeCompare(b.equipment),
        render: (data, _) => renderTranslation(data, "equipment-"),
      },
      {
        title: this.props.intl.formatMessage({ id: "weight" }),
        dataIndex: "weight",
        key: "weight",
        sorter: (a, b) => (b.weight !== "-") - (a.weight !== "-") || a.weight - b.weight,
      },

      {
        title: this.props.intl.formatMessage({ id: "reported_by" }),
        dataIndex: "reported_by",
        key: "reported_by",
        sorter: (a, b) => (a.user_name === "-") - (b.user_name === "-") || ("" + a.user_name).localeCompare(b.user_name),
        render: (data, rec) => (
          <div onClick={(e) => e.stopPropagation()}>
            {rec?.user_id ? <Link to={`/customer/${rec?.user_id}`}>{data}</Link> : { data }}
          </div>
        ),
      },
      {
        title: this.props.intl.formatMessage({ id: "no_catch_column" }),
        dataIndex: "no_catch",
        key: "no_catch",
        sorter: (a, b) => (a.no_catch === "-") - (b.no_catch === "-") || ("" + a.no_catch).localeCompare(b.no_catch),
        render: (data, _) => catchCheckerRender(data),
      },
      {
        title: this.props.intl.formatMessage({ id: "catch_edited" }),
        dataIndex: "is_edited",
        key: "is_edited",
        sorter: (a, b) => (a.is_edited === "-") - (b.is_edited === "-") || ("" + a.is_edited).localeCompare(b.is_edited),
        render: (data, _) => isEditedRender(data),
      },
    ];

    const tableData = data.map((i) => {
      let beatString = i.beat?.name || "-";
      const fishingSpot = i.fishing_spot;
      if (fishingSpot) {
        beatString = `${beatString} / ${fishingSpot}`;
      }

      return {
        key: i.id,
        date: i.date || "-",
        user_id: i.user?.id || "-",
        external_id: i.external_id || "-",
        card_id: i.fishing_card_id || "-",
        beat: beatString,
        type: i.fish_type?.slug || "-",
        equipment: i.equipment?.slug || "-",
        weight: i.weight || "-",
        no_catch: i.no_catch,
        is_edited: i.is_edited,
        reported_by: i.user?.first_name && i.user?.last_name ? `${i.user.first_name} ${i.user.last_name}` : "-",
      };
    });

    const filterOptions = [
      {
        label: this.props.intl.formatMessage({ id: "anonymous_report" }),
        value: "anonymous",
      },
      {
        label: this.props.intl.formatMessage({ id: "deleted_reports" }),
        value: "removed",
      },
      {
        label: this.props.intl.formatMessage({ id: "edited_reports" }),
        value: "is_edited",
      },
      {
        label: this.props.intl.formatMessage({ id: "reported_on_ticket" }),
        value: "fishing_card",
      },
      {
        label: this.props.intl.formatMessage({ id: "not_reported_on_ticket" }),
        value: "null_fishing_card",
      },
    ];

    const onFilterSelected = (filterList) => {
      let originalData = this.props.catches.data;
      for (const value of filterList) {
        switch (value) {
          case "anonymous":
            originalData = originalData.filter((record) => record.anonymous === 1);
            break;
          case "removed":
            originalData = originalData.filter((record) => record.removed === 1);
            break;
          case "is_edited":
            originalData = originalData.filter((record) => record.is_edited === true);
            break;
          case "fishing_card":
            originalData = originalData.filter((record) => record.fishing_card_id !== null);
            break;
          case "null_fishing_card":
            originalData = originalData.filter((record) => record.fishing_card_id === null);
            break;
          default:
            originalData = this.props.catches.data;
            break;
        }
      }

      this.handleCatchParamFilter(originalData);
    };

    const onFilterChange = (filterList) => {
      if (!filterList.length) {
        this.handleCatchParamFilter(this.props.catches.data);
      } else {
        onFilterSelected(filterList);
      }
    };
    let beatOptions = this.props.rivers.data
      .find((r) => r.id === this.state.data.river_id)
      .beats.filter((e) => e.active_on_laksebors === 1)
      .map((v) => {
        return { label: v.name, value: v.id };
      })
      .filter((value, index, self) => index === self.findIndex((t) => t.label === value.label && t.value === value.value));

      beatOptions=[{ label: this.props.intl.formatMessage({ id: "all_beats" }), value: "" },...beatOptions]

    return (
      <div>
        <PageHeader
          title={this.props.intl.formatMessage({ id: "catches" })}
          extra={[
            <Button onClick={this.onNewCatchClick} type="primary" key="1" size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "new_catch" })}
            </Button>,
          ]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                {selectRivers.length > 1 ? (
                  <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label className="ant-label" htmlFor="select_river">
                      {this.props.intl.formatMessage({ id: "select_river" })}
                    </label>
                    <Select
                      showSearch
                      id="select_river"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${this.props.intl.formatMessage({ id: "select_river" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleRiverChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.data.river_id}
                    >
                      {selectRivers.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                ) : null}
                <Col xs={24} sm={4} md={4} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label className="ant-label" htmlFor="select_beat">
                    {this.props.intl.formatMessage({ id: "select_beat" })}
                  </label>
                  <Select
                    showSearch
                    id="select_beat"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                    optionFilterProp="children"
                    onChange={(e) => this.handleBeatSelect(e)}
                    options={beatOptions}
                    value={this.state.beat_id}
                    filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </Col>
                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                  <label htmlFor="search" className="ant-label">
                    {this.props.intl.formatMessage({ id: "search" })}
                  </label>
                  <Input
                    placeholder={this.props.intl.formatMessage({ id: "search" })}
                    id="search"
                    size={ANT_CONFIG.size}
                    value={this.state.filter}
                    onChange={(e) => this.handleFilterChange(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_start" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_start" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_start)}
                    onChange={(e) => this.handleChangeDate(e, "date_start")}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {this.props.intl.formatMessage({ id: "date_end" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.data.date_end)}
                    onChange={(e) => this.handleChangeDate(e, "date_end")}
                  />
                </Col>
                <Col xs={24} sm={12} md={8} style={{ marginTop: 10, marginBottom: 10, maxWidth: 250 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {this.props.intl.formatMessage({ id: "filter" })}
                  </label>
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    placeholder={`${this.props.intl.formatMessage({ id: "select_filter" })}`}
                    size="large"
                    onChange={onFilterChange}
                    options={filterOptions}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
                onRow={(record) => {
                  return {
                    onClick: () => this.onRowClick(record.key),
                  };
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  catches: state.Catches,
  authentication: state.Auth,
  rivers: state.Rivers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(Catches)));
