import queryString from "query-string";

import { DEFAULT_LANGUAGE } from "../constants";
import {applyBaseUrlToFetch} from "./HttpInterceptor";

const PREFIX = "/api/v1/";

const API = {
  get headers() {
    const language = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-SetLanguage": language,
    };

    return headers;
  },
  call(ep, method = "GET", data = null, prefix) {
    let endpoint = ep;

    const fetchOpt = {
      method,
      headers: API.headers,
      credentials: 'include',
    };

    if (method !== "GET" && data) {
      fetchOpt.body = JSON.stringify(data);
    }

    if (method === "GET" && data) {
      endpoint += `?${queryString.stringify(data)}`;
    }

    applyBaseUrlToFetch(process.env.REACT_APP_API_URL)
    return fetch((prefix ? prefix : PREFIX) + endpoint, fetchOpt);
  },
  upload(ep, method = "POST", data = null) {
    const fetchOpt = {
      method,
      credentials: "include",
      body: data,
    };

    applyBaseUrlToFetch(process.env.REACT_APP_API_URL)
    return fetch(PREFIX + ep, fetchOpt);
  },
};

export default API;
