import * as ACTION from './actions';

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_TAXES:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_TAXES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_TAXES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.ADD_TAX:
      return {
        ...state,
        loading: true,
      };

    case ACTION.ADD_TAX_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.ADD_TAX_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.EDIT_TAX:
      return {
        ...state,
        loading: true,
      };

    case ACTION.EDIT_TAX_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.EDIT_TAX_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.REMOVE_TAX:
      return {
        ...state,
        loading: true,
      };

    case ACTION.REMOVE_TAX_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.REMOVE_TAX_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}
