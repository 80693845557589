import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { MenuOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LANGUAGES } from '../../constants';

const languages = [[{name: 'Norsk', value:'nb'}, {name: 'Svensk', value:'sv'}, {name: 'English', value:'en'}]]
export class Header extends React.Component {
  constructor() {
    super();

    const languageSelected = localStorage.getItem('language') || window.navigator.languages[1];

    this.state = {
      showDropdown: false,
      languages: LANGUAGES.map((lang) => {
        return ({...lang, active: lang.value == languageSelected})
      })
    }
  }

  toggleMenu() {
    this.props.toggleMenu();
  }

  changeLanguage(lang) {
    localStorage.setItem('language', lang)
    this.props.changeLanguage();
    this.toggleIcon();
    this.setState({
      languages: LANGUAGES.map((lang) => {
        return ({...lang, active: lang.value == lang})
      })
    })
  }

  toggleIcon() {
    if (!this.state.showDropdown) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showDropdown: !prevState.showDropdown
    }));
  }

  handleOutsideClick = e => {
    if (!this.node.contains(e.target)) this.toggleIcon();
  };

  render() {
    return (
      <header className="main-header">
        <Row gutter={8}>
          <Col xs={4}>
            <MenuOutlined onClick={() => this.toggleMenu()} />
          </Col>

          <Col xs={20} className="text-right">
            <div className="profile-layout-wrapper">
              <div className="language-options-wrapper" ref={node => {this.node = node}}>
                <FontAwesomeIcon
                  onClick={() => {this.toggleIcon()}}
                  icon={faGlobe}
                  style={{
                    cursor: 'pointer',
                    fontSize: 20,
                    marginRight: 5,
                    color:'#868686c4'
                  }}
                />

                {this.state.showDropdown && <div className="language-options">
                      {this.state.languages.map(lang => {
                          return <div className={lang.active ? 'active' : ''} onClick={() => this.changeLanguage(lang.value)} key={lang.value}>{lang.name}</div>
                        })}
                 </div>}
              </div>

              <NavLink to="/profile" className="profile-link">
                {this.props.authentication.data.first_name} {this.props.authentication.data.last_name}
              </NavLink>
            </div>
           
          </Col>

        </Row>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.Auth
});

export default withRouter(injectIntl(connect(mapStateToProps)(Header)));
