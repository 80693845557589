export const ADD_STATION_ROLE = "ADD_STATION_ROLE";
export const ADD_STATION_ROLE_SUCCESS = "ADD_STATION_ROLE_SUCCESS";
export const ADD_STATION_ROLE_FAILURE = "ADD_STATION_ROLE_FAILURE";

export const REMOVE_STATION_ROLE = "REMOVE_STATION_ROLE";
export const REMOVE_STATION_ROLE_SUCCESS = "REMOVE_STATION_ROLE_SUCCESS";
export const REMOVE_STATION_ROLE_FAILURE = "REMOVE_STATION_ROLE_FAILURE";

export const MASS_REMOVE_STATION_ROLE = "MASS_REMOVE_STATION_ROLE";
export const MASS_REMOVE_STATION_ROLE_SUCCESS = "MASS_REMOVE_STATION_ROLE_SUCCESS";
export const MASS_REMOVE_STATION_ROLE_FAILURE = "MASS_REMOVE_STATION_ROLE_FAILURE";

export function add(payload) {
  return {
    type: ADD_STATION_ROLE,
    payload
  };
}

export function remove(userId, stationId) {
  return {
    type: REMOVE_STATION_ROLE,
    userId,
    stationId
  };
}

export function massRemove(userId, payload) {
  return {
    type: MASS_REMOVE_STATION_ROLE,
    userId,
    payload
  };
}
