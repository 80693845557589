import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetchPlatformReports(action) {
  try {
    const response = yield call(API.call, "admin/platform-report", "POST", action.payload, '/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_PLATFORM_REPORTS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_PLATFORM_REPORTS_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_PLATFORM_REPORTS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadBeatReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/${action.filetype}/beat` : "admin/download-report/beat";
    const response = yield call(API.call, url, "POST", action.payload, '/api/v2/');
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_BEAT_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_BEAT_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_BEAT_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadAllBeatReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/${action.filetype}/beats` : "admin/download-report/beats";
    const response = yield call(API.call, url, "POST", action.payload, '/api/v2/');
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_ALL_BEAT_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_ALL_BEAT_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_ALL_BEAT_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadPlatformReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/${action.filetype}/platform` : "admin/download-report/platform";
    const response = yield call(API.call, url, "POST", action.payload, '/api/v2/');
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_PLATFORM_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_PLATFORM_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_PLATFORM_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchProductCountReport(action) {
  try {
    const response = yield call(API.call, "admin/beat-report/sold-product-count", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_PRODUCT_COUNT_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_PRODUCT_COUNT_REPORT_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_PRODUCT_COUNT_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadProductCountReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/beat/${action.filetype}/by-product-count` : "admin/download-report/beat/by-product-count";
    const response = yield call(API.call, url, "POST", action.payload);
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_PRODUCT_COUNT_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_PRODUCT_COUNT_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_PRODUCT_COUNT_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchProductTypeAndPriceReport(action) {
  try {
    const response = yield call(API.call, "admin/beat-report/by-product-type-and-price", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadProductTypeAndPriceReport(action) {
  try {
    const url = action.filetype
      ? `admin/download-report/beat/${action.filetype}/by-product-type-and-price`
      : "admin/download-report/beat/by-product-type-and-price";
    const response = yield call(API.call, url, "POST", action.payload);
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchSellerPaymentMethodReport(action) {
  try {
    const response = yield call(API.call, "admin/beat-report/by-seller-and-method", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_SELLER_PAYMENT_METHOD_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_SELLER_PAYMENT_METHOD_REPORT_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_SELLER_PAYMENT_METHOD_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadPaymentMethodReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/beat/${action.filetype}/by-seller` : "admin/download-report/beat/by-seller";
    const response = yield call(API.call, url, "POST", action.payload);
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchFishingCardsReport(action) {
  try {
    const response = yield call(API.call, "admin/fishing-cards-report", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_FISHING_CARDS_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_FISHING_CARDS_REPORT_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_FISHING_CARDS_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadFishingCardsReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/${action.filetype}/fishing-cards` : "admin/download-report/fishing-cards";
    const response = yield call(API.call, url, "POST", action.payload);
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_FISHING_CARDS_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_FISHING_CARDS_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_FISHING_CARDS_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}


function* fetchRiverCardsReport(action) {
  try {
    const response = yield call(API.call, "admin/river-cards-report", "POST", action.payload, '/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_RIVER_CARDS_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_RIVER_CARDS_REPORT_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_RIVER_CARDS_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadRiverCardsReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/${action.filetype}/river-cards` : "admin/download-report/river-cards";
    const response = yield call(API.call, url, "POST", action.payload, '/api/v2/');
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_RIVER_CARDS_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_RIVER_CARDS_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_RIVER_CARDS_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchSeasonCardsReport(action) {
  try {
    const response = yield call(API.call, "admin/season-cards-report", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_SEASON_CARDS_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_SEASON_CARDS_REPORT_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_SEASON_CARDS_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* downloadSeasonCardsReport(action) {
  try {
    const url = action.filetype ? `admin/download-report/${action.filetype}/season-cards` : "admin/download-report/season-cards";
    const response = yield call(API.call, url, "POST", action.payload);
    const responseBody = yield call([response, "blob"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.DOWNLOAD_SEASON_CARDS_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.DOWNLOAD_SEASON_CARDS_REPORT_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.DOWNLOAD_SEASON_CARDS_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchEconomyBeatReport(action) {
  try {
    const response = yield call(API.call, "admin/beat-economy-report", "POST", action.payload,'/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ECONOMY_BEAT_REPORT_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ECONOMY_BEAT_REPORT_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ECONOMY_BEAT_REPORT_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}


function* fetchAllEconomyBeatReport(action) {

  try {
    const response = yield call(API.call, "admin/all-beats-economy-report", "POST", action.payload,'/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_ALL_ECONOMIC_BEAT_REPORTS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_ALL_ECONOMIC_BEAT_REPORTS_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_ALL_ECONOMIC_BEAT_REPORTS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchExportBilling(action){
  try {
    const response = yield call(API.call, "admin/export-billings", "POST", action.payload,'/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_EXPORT_BILLING_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_EXPORT_BILLING_SUCCESS,
        payload: responseBody,
      });

      return responseBody;
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_EXPORT_BILLING_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTION.FETCH_PLATFORM_REPORTS, fetchPlatformReports),
    takeEvery(ACTION.FETCH_PRODUCT_COUNT_REPORT, fetchProductCountReport),
    takeEvery(ACTION.FETCH_PRODUCT_TYPE_AND_PRICE_REPORT, fetchProductTypeAndPriceReport),
    takeEvery(ACTION.FETCH_SELLER_PAYMENT_METHOD_REPORT, fetchSellerPaymentMethodReport),
    takeEvery(ACTION.FETCH_FISHING_CARDS_REPORT, fetchFishingCardsReport),
    takeEvery(ACTION.FETCH_RIVER_CARDS_REPORT, fetchRiverCardsReport),
    takeEvery(ACTION.FETCH_SEASON_CARDS_REPORT, fetchSeasonCardsReport),
    takeEvery(ACTION.DOWNLOAD_BEAT_REPORT, downloadBeatReport),
    takeEvery(ACTION.DOWNLOAD_ALL_BEAT_REPORT, downloadAllBeatReport),
    takeEvery(ACTION.DOWNLOAD_PLATFORM_REPORT, downloadPlatformReport),
    takeEvery(ACTION.DOWNLOAD_PRODUCT_COUNT_REPORT, downloadProductCountReport),
    takeEvery(ACTION.DOWNLOAD_PRODUCT_TYPE_AND_PRICE_REPORT, downloadProductTypeAndPriceReport),
    takeEvery(ACTION.DOWNLOAD_SELLER_PAYMENT_METHOD_REPORT, downloadPaymentMethodReport),
    takeEvery(ACTION.DOWNLOAD_FISHING_CARDS_REPORT, downloadFishingCardsReport),
    takeEvery(ACTION.DOWNLOAD_RIVER_CARDS_REPORT, downloadRiverCardsReport),
    takeEvery(ACTION.DOWNLOAD_SEASON_CARDS_REPORT, downloadSeasonCardsReport),
    takeEvery(ACTION.FETCH_ECONOMY_BEAT_REPORT, fetchEconomyBeatReport),
    takeEvery(ACTION.FETCH_ALL_ECONOMIC_BEAT_REPORTS, fetchAllEconomyBeatReport),
    takeEvery(ACTION.FETCH_EXPORT_BILLING,fetchExportBilling)


  ]);
}
