export const FETCH_PAGE = "FETCH_PAGE";
export const FETCH_PAGE_SUCCESS = "FETCH_PAGE_SUCCESS";
export const FETCH_PAGE_FAILURE = "FETCH_PAGE_FAILURE";

export const STORE_PAGE = "STORE_PAGE";
export const STORE_PAGE_SUCCESS = "STORE_PAGE_SUCCESS";
export const STORE_PAGE_FAILURE = "STORE_PAGE_FAILURE";

export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
export const UPDATE_PAGE_FAILURE = "UPDATE_PAGE_FAILURE";

export const UPDATE_IMAGE_PAGE = "UPDATE_IMAGE_PAGE";
export const UPDATE_IMAGE_PAGE_SUCCESS = "UPDATE_IMAGE_PAGE_SUCCESS";
export const UPDATE_IMAGE_PAGE_FAILURE = "UPDATE_IMAGE_PAGE_FAILURE";

export function fetch(id) {
  return {
    type: FETCH_PAGE,
    id
  };
}

export function store(data) {
  return {
    type: STORE_PAGE,
    data
  };
}

export function update(data, id) {
  return {
    type: UPDATE_PAGE,
    data,
    id
  };
}

export function updateImage(data, id) {
  return {
    type: UPDATE_IMAGE_PAGE,
    data,
    id
  };
}
