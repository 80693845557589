import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Select } from "antd";

import { fetch } from "../../redux/countries/actions";
import Spinner from "../spinner";
import ANT_CONFIG from "../../constants/antconfig";

class Countries extends React.Component {
  componentDidMount() {
    if (!this.props.countries.data) {
      this.props.dispatch(fetch());
    }
  }

  onChange = current => {
    this.props.handleChange(current);
  };

  render() {
    const { countries, current, intl } = this.props;

    if (countries.loading) {
      return <Spinner />;
    }

    if (!countries.data) {
      return <div />;
    }

    const config = {
      placeholder: intl.formatMessage({ id: "select_county" }),
      size: ANT_CONFIG.size,
      style: { width: "100%" },
      showSearch: true,
      optionFilterProp: "children"
    };

    if (current) {
      config.defaultValue = current.map(item => item.id);;
    }

    return (
      <Select mode="multiple" {...config} onChange={this.onChange} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {countries.data.map(i => (
          <Select.Option key={i.id} value={i.id}>
            {i.country_name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

const mapStateToProps = state => ({
  countries: state.Countries
});

export default injectIntl(connect(mapStateToProps)(Countries));
