import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Table, Card, Col, Row, PageHeader, Select, Button, Input, Modal, List, DatePicker } from "antd";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import ANT_CONFIG from "../../constants/antconfig";
import { fetch, update, updateDate, remove } from "../../redux/cards/actions";
import RolesContext from "../../context/RolesContext";
import { fetch as fetchCustomers } from "../../redux/customers/actions";
import Scribe from "../../components/Scribe";

const SPIN_ICON = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { RangePicker } = DatePicker;
const CARD_OPTIONS = ["order", "fishing_card", "free_package", "season_fishing_card"];
const DEFAULT_STATE = {
  type: "order",
  id: "",
  visible: false,
  owner_name: null,
  user_id: null,
  history_visible: false,
  change_date: false,
  new_date: null,
};
const { confirm } = Modal;

class CardChange extends React.Component {
  static contextType = RolesContext;

  state = DEFAULT_STATE;

  componentDidMount() {
    const { dispatch, customers: data } = this.props;

    if (!data?.data) {
      dispatch(fetchCustomers());
    }

    this.handleFetch();
  }

  setOwnerName(e) {
    const owner_name = e.target.value;

    this.setState({
      owner_name,
    });
  }

  setUserId = (user_id) => {
    this.setState({
      user_id,
    });
  };

  handleTypeChange = (type) => {
    DEFAULT_STATE.type = type;
    this.setState({ type });
  };

  handleIdChange = (e) => {
    const id = e.target.value;

    DEFAULT_STATE.id = id;
    this.setState({ id });
  };

  handleFetch = () => {
    const { type, id } = this.state;
    const { dispatch } = this.props;

    if (type && id) {
      dispatch(fetch({ type, id }));
    }
  };

  handleOk = () => {
    const { owner_name, visible, type, id, user_id } = this.state;
    const { dispatch } = this.props;
    const data = {
      owner_name,
      type: visible.type,
      id: visible.id,
    };
    const payload = {
      type,
      id,
    };

    if ((!visible.user_id && user_id !== visible.order?.user_id) || (visible.user_id && user_id !== visible.user_id)) {
      data.user_id = user_id;
    }

    dispatch(update(data, payload));

    this.handleCancel();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      owner_name: null,
      user_id: null,
    });
  };

  handleHistoryCancel = () => {
    this.setState({
      history_visible: false,
    });
  };

  handleCancelChangeDate = () => {
    this.setState({
      change_date: false,
      new_date: null,
    });
  };

  handleChangeDateOk = () => {
    const { type, id, change_date, new_date } = this.state;
    const { dispatch } = this.props;
    const data = {
      type: change_date.type,
      id: change_date.id,
    };
    const payload = {
      type,
      id,
    };

    if (change_date.type === "fishing_card") {
      data.date = moment(new_date).format("YYYY-MM-DD");
    }

    if (change_date.type === "free_package") {
      data.date = new_date.map((i) => moment(i).format("YYYY-MM-DD"));
    }

    if (change_date.type === "season_fishing_card") {
      data.date = moment(new_date).format("YYYY");
    }

    dispatch(updateDate(data, payload));

    this.handleCancelChangeDate();
  };

  showModal(visible) {
    this.setState({
      visible,
      user_id: visible.user_id || visible.order?.user_id,
      owner_name: visible.owner_name,
    });
  }

  showConfirm(item) {
    const { intl, dispatch } = this.props;
    const { type, id } = this.state;
    const titlePrefix = item.type === 'fishing_card' ?
     intl.formatMessage({ id: "card_will_be_refunded" }) :
     intl.formatMessage({ id: "product_will_not_be_refunded" })

    console.log(item.type)

    confirm({
      title: `${titlePrefix} ${intl.formatMessage({ id: "confirm_removing_card_from_order_question" })}`,
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      okText: intl.formatMessage({ id: "yes" }),
      cancelText: intl.formatMessage({ id: "no" }),
      onOk() {
        dispatch(
          remove(
            {
              type: item.type,
              id: item.id,
            },
            {
              type,
              id,
            }
          )
        );
      },
    });
  }

  showHistoryModal(history_visible) {
    this.setState({
      history_visible,
    });
  }

  showChangeDate(change_date) {
    this.setState({
      change_date,
      new_date: null,
    });
  }

  render() {
    const { isAdmin } = this.context;
    const { intl, cards, customers } = this.props;
    const { type, id, visible, history_visible, owner_name, user_id, change_date, new_date } = this.state;
    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "ID (FCID)",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({ id: "product_type" }),
        dataIndex: "product_type",
        key: "product_type",
      },
      {
        title: `${intl.formatMessage({ id: "order" })} ID`,
        dataIndex: "order",
        key: "order",
      },
      {
        title: intl.formatMessage({ id: "owner_name" }),
        dataIndex: "owner_name",
        key: "owner_name",
      },
      {
        title: intl.formatMessage({ id: "date" }),
        dataIndex: "date",
        key: "date",
      },
      {
        title: intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
      },
      {
        title: intl.formatMessage({ id: "actions" }),
        dataIndex: "edit_owner",
        key: "edit_owner",
      },
    ];
    const tableData = cards.data?.map((i, key) => {
      let date = "-";

      if (i.type === "fishing_card") {
        date = moment(i.date).format("DD.MM.YYYY");
      }

      if (i.type === "free_package") {
        date = `${moment(i.date_start).format("DD.MM.YYYY")} - ${moment(i.date_end).format("DD.MM.YYYY")}`;
      }

      if (i.type === "season_fishing_card") {
        date = i.year;
      }

      return {
        key: key + 1,
        id: i.id,
        product_type: i.type ? intl.formatMessage({ id: i.type }) : "-",
        order: i.order?.id || "-",
        owner_name: i.owner_name || "-",
        date,
        beat: `${i.beat?.name} (${i.beat?.river?.name})`,
        edit_owner: (
          <Row gutter={[10, 10]} style={{ margin: 0 }}>
            <Col>
              <Button onClick={() => this.showModal(i)} type="default" size={ANT_CONFIG.size}>
                {intl.formatMessage({ id: "edit_owner" })}
              </Button>
            </Col>
            {i.history?.length > 0 ? (
              <Col>
                <Button onClick={() => this.showHistoryModal(i.history)} type="primary" size={ANT_CONFIG.size}>
                  {intl.formatMessage({ id: "history" })}
                </Button>
              </Col>
            ) : null}
            <Col>
              <Button onClick={() => this.showChangeDate(i)} type="default" size={ANT_CONFIG.size}>
                {intl.formatMessage({ id: "change_date" })}
              </Button>
            </Col>
            {isAdmin && (
              <Col>
                <Button onClick={() => this.showConfirm(i)} type="danger" size={ANT_CONFIG.size}>
                  {intl.formatMessage({ id: "remove_from_order" })}
                </Button>
              </Col>
            )}
          </Row>
        ),
      };
    });

    return (
      <div>
        <PageHeader title={intl.formatMessage({ id: "card_changes" })}>
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16}>
                <Col xs={24} sm={4} md={4} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                  <label htmlFor="select_type" className="ant-label">
                    {intl.formatMessage({ id: "select_type" })}
                  </label>
                  <Select
                    id="select_type"
                    showSearch
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={this.handleTypeChange}
                    value={type}
                    filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {CARD_OPTIONS.map((i) => (
                      <Select.Option key={i} value={i}>
                        {intl.formatMessage({ id: i })}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col xs={24} sm={12} md={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                  <label htmlFor="search_by_order_id_or_fcid" className="ant-label">
                    {intl.formatMessage({ id: "search_by_order_id_or_fcid" })}
                  </label>
                  <Input
                    placeholder={intl.formatMessage({ id: "search_by_order_id_or_fcid" })}
                    id="search_by_order_id_or_fcid"
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    value={id}
                    onChange={this.handleIdChange}
                  />
                </Col>

                <Col xs={24} sm={12} md={12} style={{ marginTop: 10, marginBottom: 10, maxWidth: 300 }}>
                  <div className="ant-label">&nbsp;</div>
                  <Button loading={cards.loading} type="primary" size={ANT_CONFIG.size} onClick={this.handleFetch}>
                    {intl.formatMessage({ id: "search" })}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                loading={{ spinning: cards.loading, indicator: SPIN_ICON }}
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
              />
            </Col>
          </Row>
        </Card>

        <Modal
          visible={!!visible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "cancel" })}
            </Button>,
            <Button key="save" type="primary" disabled={!owner_name} loading={cards.loading} onClick={this.handleOk} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
          ]}
        >
          <Row>
            <Col xs={24}>
              <label htmlFor="owner_name" className="ant-label">
                {intl.formatMessage({ id: "owner_name" })}
              </label>
              <Input
                placeholder={intl.formatMessage({ id: "owner_name" })}
                id="owner_name"
                size={ANT_CONFIG.size}
                style={{ width: "100%" }}
                value={owner_name}
                onChange={(e) => this.setOwnerName(e)}
              />
            </Col>
            <Col xs={24} style={{ marginTop: 20, textAlign: "center" }}>
              {customers.loading ? (
                SPIN_ICON
              ) : (
                <>
                  <label htmlFor="select_user" className="ant-label">
                    {intl.formatMessage({ id: "user" })}
                  </label>
                  <Select
                    id="select_user"
                    showSearch
                    size={ANT_CONFIG.size}
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    onChange={this.setUserId}
                    value={user_id}
                    filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {customers.data?.map((i) => (
                      <Select.Option key={i.id} value={i.id}>
                        {`${i.first_name} ${i.last_name} (ID: ${i.id})`}
                      </Select.Option>
                    ))}
                  </Select>
                </>
              )}
            </Col>
          </Row>
        </Modal>

        <Modal
          visible={!!change_date}
          onCancel={this.handleCancelChangeDate}
          footer={[
            <Button key="cancel" onClick={this.handleCancelChangeDate} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "cancel" })}
            </Button>,
            <Button key="save" type="primary" disabled={!new_date} loading={cards.loading} onClick={this.handleChangeDateOk} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "save" })}
            </Button>,
          ]}
        >
          <Row>
            <Col xs={24}>
              {change_date.type === "fishing_card" && (
                <>
                  <label htmlFor="date" className="ant-label">
                    {intl.formatMessage({ id: "date" })}
                  </label>
                  <DatePicker
                    id="date"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    defaultValue={moment(change_date.date)}
                    onChange={(e) => this.setState({ new_date: e })}
                  />
                </>
              )}

              {change_date.type === "free_package" && (
                <>
                  <label htmlFor="date" className="ant-label">
                    {intl.formatMessage({ id: "date" })}
                  </label>
                  <RangePicker
                    id="date"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    defaultValue={[moment(change_date.date_start), moment(change_date.date_end)]}
                    onChange={(e) => this.setState({ new_date: e })}
                  />
                </>
              )}

              {change_date.type === "season_fishing_card" && (
                <>
                  <label htmlFor="date" className="ant-label">
                    {intl.formatMessage({ id: "date" })}
                  </label>
                  <DatePicker
                    id="date"
                    picker="year"
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format="YYYY"
                    defaultValue={moment(change_date.year, "Y")}
                    onChange={(e) => this.setState({ new_date: e })}
                  />
                </>
              )}
            </Col>
          </Row>
        </Modal>

        <Modal
          visible={!!history_visible}
          onCancel={this.handleHistoryCancel}
          footer={[
            <Button key="ok" type="primary" onClick={this.handleHistoryCancel} size={ANT_CONFIG.size}>
              {intl.formatMessage({ id: "ok" })}
            </Button>,
          ]}
        >
          {history_visible?.length > 0 && (
            <List
              itemLayout="vertical"
              bordered
              dataSource={history_visible}
              renderItem={(item) => (
                <List.Item size={ANT_CONFIG.size} className="ant-list-margin-0">
                  <p>
                    {`${intl.formatMessage({ id: "date" })}: `}
                    <strong>{moment(item.updated_at).format("DD.MM.YYYY HH:mm")}</strong>
                  </p>
                  <p>
                    {`${intl.formatMessage({ id: "change_made_by" })}: `}
                    <strong>{`${item.user.first_name} ${item.user.last_name} (ID: ${item.user.id})`}</strong>
                  </p>
                  <p>
                    {`${intl.formatMessage({ id: "changed_value" })}: `}
                    <strong>{intl.formatMessage({ id: item.changed_in_field })}</strong>
                  </p>
                  <p>
                    {`${intl.formatMessage({ id: "old_value" })}: `}
                    <strong>{item.old_value || "-"}</strong>
                  </p>
                  <p>
                    {`${intl.formatMessage({ id: "new_value" })}: `}
                    <strong>{item.new_value || "-"}</strong>
                  </p>
                </List.Item>
              )}
            />
          )}
        </Modal>
        <Scribe intl={intl} srcLink="https://scribehow.com/embed/Endre_navn_eier_eller_dato_pa_et_fiskekort__LlWOtoD6S-mup1ZhFipP9Q" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cards: state.Cards,
  customers: state.Customers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(CardChange)));
