import { call, put, all, takeEvery } from "redux-saga/effects";

import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";

function* fetch(action) {
  try {
    const response = yield call(API.call, `admin/currency/list`, "GET", null, '/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_CURRENCIES_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_CURRENCIES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_CURRENCIES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* fetchTimezones(action) {
  try {
    const response = yield call(API.call, `admin/timezone/list`, "GET", null, '/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_TIMEZONES,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.FETCH_TIMEZONES_SUCCESS,
        payload: responseBody,
      });
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_TIMEZONES_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* updateRiver(action) {
  try {
    const response = yield call(API.call, `admin/river/admin-update`, "PUT", action.data, '/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UPDATE_RIVER_SETTINGS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UPDATE_RIVER_SETTINGS_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, action);
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UPDATE_RIVER_SETTINGS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

function* updateBeat(action) {
  try {
    const response = yield call(API.call, `admin/beat/admin-update`, "PUT", action.data, '/api/v2/');
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.UPDATE_BEAT_SETTINGS_FAILURE,
        message: responseBody.errors,
      });
      APIMessage.error(responseBody.errors);
    } else {
      yield put({
        type: ACTION.UPDATE_BEAT_SETTINGS_SUCCESS,
        payload: responseBody,
      });
      yield call(fetch, action);
      APIMessage.success(responseBody.data.message);
    }
  } catch (e) {
    yield put({
      type: ACTION.UPDATE_BEAT_SETTINGS_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}



export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_CURRENCIES, fetch), takeEvery(ACTION.FETCH_TIMEZONES, fetchTimezones), takeEvery(ACTION.UPDATE_BEAT_SETTINGS, updateBeat), takeEvery(ACTION.UPDATE_RIVER_SETTINGS, updateRiver)]);
}
