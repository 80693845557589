import { call, put, all, takeEvery } from "redux-saga/effects";
import * as ACTION from "./actions";
import API from "../../services/api";
import APIMessage from "../../services/api_messages";
import translations from "../../constants/strings";
import { DEFAULT_LANGUAGE } from "../../constants";

const lang = window.localStorage.getItem("language") || DEFAULT_LANGUAGE;

function* fetch(action) {
  try {
    const response = yield call(API.call, "check-package-availability", "POST", action.payload);
    const responseBody = yield call([response, "json"]);
    if (response.status >= 400) {
      yield put({
        type: ACTION.FETCH_PACKAGE_AVAILABILITY_FAILURE,
        message: responseBody.errors,
      });

      if (response.status === 409) {
        APIMessage.error(translations.error[lang].package_not_available);
      } else {
        APIMessage.error(responseBody.errors);
      }
    } else {
      yield put({
        type: ACTION.FETCH_PACKAGE_AVAILABILITY_SUCCESS,
        payload: responseBody,
      });
      APIMessage.success(translations.response[lang].package_available);
    }
  } catch (e) {
    yield put({
      type: ACTION.FETCH_PACKAGE_AVAILABILITY_FAILURE,
      message: e.errors,
    });
    APIMessage.error(e.errors);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTION.FETCH_PACKAGE_AVAILABILITY, fetch)]);
}
