import React from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "antd";
import { Polygon } from "react-google-maps";
import { injectIntl } from "react-intl";
import { CirclePicker } from "react-color";

import Map from "../../Map/Map";
import { DEFAULT_POLYGON_STYLE, DEFAULT_COLOR_PALLETE } from "../../../constants";
import ANT_CONFIG from "../../../constants/antconfig";

class AddPolygonModal extends React.Component {
  state = {
    draw: true,
    type: "polygon",
    poly_style: DEFAULT_POLYGON_STYLE,
    latitude: parseFloat(this.props.position.lat),
    longitude: parseFloat(this.props.position.lng),
    poly_coordinates: null
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.resetState();
    }
  }

  getPolygonCords(cords) {
    const polygonCords = [];

    cords.forEach(i =>
      polygonCords.push({
        lat: i.lat(),
        lng: i.lng()
      })
    );

    return JSON.stringify([polygonCords]);
  }

  setCords = e => {
    this.setState({
      draw: false,
      poly_coordinates: this.getPolygonCords(e.getPath())
    });
  };

  setColor = color => {
    this.setState(prevState => ({
      poly_style: {
        ...prevState.poly_style,
        fillColor: color.hex,
        strokeColor: color.hex
      }
    }));
  };

  onSubmit = () => {
    this.props.save(this.state);
  };

  onCancel = () => {
    this.props.cancel();
  };

  resetState() {
    this.setState({
      poly_style: DEFAULT_POLYGON_STYLE,
      draw: true,
      poly_coordinates: null
    });
  }

  render() {
    const { intl, show, river, position } = this.props;

    return (
      <Modal
        title={intl.formatMessage({ id: "add_polygon" })}
        visible={show}
        onCancel={this.onCancel}
        footer={[
          <Button size={ANT_CONFIG.size} key="cancel" disabled={river.loading} type="default" onClick={this.onCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>,
          <Button size={ANT_CONFIG.size} key="submit" disabled={river.loading || !this.state.poly_coordinates} type="primary" onClick={this.onSubmit}>
            {intl.formatMessage({ id: "save" })}
          </Button>
        ]}
      >
        {show && (
          <React.Fragment>
            <Row>
              <Col xs={24}>
                <Map
                  defaultPosition={{ lat: position.lat, lng: position.lng }}
                  polygonOptions={this.state.poly_style}
                  draw={this.state.draw}
                  onPolygonComplete={this.setCords}
                >
                  {this.state.poly_coordinates && <Polygon paths={JSON.parse(this.state.poly_coordinates)} options={this.state.poly_style} />}
                </Map>
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ paddingTop: 20 }}>
                <CirclePicker width="100%" onChangeComplete={this.setColor} colors={DEFAULT_COLOR_PALLETE} />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  river: state.River
});

export default injectIntl(connect(mapStateToProps)(AddPolygonModal));
