import * as ACTION from "./actions";

const INIT_STATE = {
  loading: false,
  error: false,
  data: undefined,
  beatStatusLoading: false,
  beatStatusError: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case ACTION.FETCH_RIVER:
      return {
        ...state,
        loading: true,
      };

    case ACTION.FETCH_RIVER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: false,
      };

    case ACTION.FETCH_RIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.UPDATE_RIVER:
      return {
        ...state,
        loading: true,
      };

    case ACTION.UPDATE_RIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ACTION.UPDATE_RIVER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ACTION.SET_BEAT_STATUS:
      return {
        ...state,
        beatStatusLoading: true,
      };

    case ACTION.SET_BEAT_STATUS_SUCCESS:
      return {
        ...state,
        beatStatusLoading: false,
        beatStatusError: false,
      };

    case ACTION.SET_BEAT_STATUS_FAILURE:
      return {
        ...state,
        beatStatusLoading: false,
        beatStatusError: true,
      };

    default:
      return state;
  }
}
