import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { PageHeader, Row, Col, Select, Card, Button } from "antd";

import translations from "../../constants/strings";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import { fetch as fetchFishingCards } from "../../redux/fishing_cards/actions";
import { fetch as fetchBeatNotifications } from "../../redux/beat_notifications/actions";
import Spinner from "../../components/spinner";
import Notification from "../../components/Notification/Notification";
import ModalAddFishingCard from "../../components/modal/fishing_card/add";
import ModalAddCardType from "../../components/modal/fishing_card/type/add";
import ModalEditCardType from "../../components/modal/fishing_card/type/edit";
import ModalRemoveFishingCards from "../../components/modal/fishing_card/remove";
import groupBy from "../../services/group_by";
import beatOptions from "../../services/select_beat_options";
import ANT_CONFIG from "../../constants/antconfig";
import RolesContext from "../../context/RolesContext";
import Scribe from "../../components/Scribe";

class FishingCards extends React.Component {
  static contextType = RolesContext;

  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.localizer = momentLocalizer(moment);
    this.state = {
      today: moment().format(this.dateFormat),
      data: {
        beat_ids: this.props.beats.selected ? [this.props.beats.selected] : [this.props.authentication.data.beat[0]],
        date_start: moment().startOf("month").format(this.dateFormat),
        date_end: moment().endOf("month").format(this.dateFormat),
      },
      showModal: false,
      modalData: {
        startDate: null,
        endDate: null,
        items: [],
      },
      delete_events: false,
      selected_events: [],
    };
  }

  componentDidMount() {
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.props.dispatch(fetchFishingCards(this.state.data));

    this.props.dispatch(fetchBeatNotifications(this.state.data.beat_ids[0]));
  }

  onSelectEvent(e) {
    if (!e.reserved) {
      const selectedEvents = this.state.selected_events;

      if (this.state.delete_events) {
        if (selectedEvents.map((i) => i.id).includes(e.id)) {
          this.setState({
            selected_events: selectedEvents.filter((i) => i.id !== e.id),
          });
        } else {
          this.setState((prevState) => ({
            selected_events: [...prevState.selected_events, e],
          }));
        }

        return true;
      }

      this.toggleModal("add_availability", e.start, e.end, e.items);
    }

    return true;
  }

  onSelectSlot({ start, end }) {
    const startDate = moment(start).format(this.dateFormat);
    const endDate = moment(end).format(this.dateFormat);
    this.toggleModal("add_availability", startDate, endDate);
  }

  onNavigate(e) {
    const date = moment(e).format(this.dateFormat);
    const startOfMonth = moment(date).startOf("month").format(this.dateFormat);
    const endOfMonth = moment(date).endOf("month").format(this.dateFormat);
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          date_start: startOfMonth,
          date_end: endOfMonth,
        },
      }),
      () => {
        this.props.dispatch(fetchFishingCards(this.state.data));
      },
    );
  }

  toggleModal(modalName, startDate, endDate, items) {
    if (startDate && endDate) {
      this.setState({
        modalData: {
          startDate,
          endDate,
          items: items || [],
        },
      });
    }
    this.setState({
      showModal: modalName,
    });
  }

  handleBeatChange(e) {
    this.props.dispatch(selectBeat(e));
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          beat_ids: [e],
        },
      }),
      () => {
        this.props.dispatch(fetchFishingCards(this.state.data));
        this.props.dispatch(fetchBeatNotifications(this.state.data.beat_ids[0]));
      },
    );
  }

  hideModal() {
    this.setState({
      showModal: null,
    });
  }

  hideModalAfterDelete() {
    this.setState({
      showModal: null,
      delete_events: false,
      selected_events: [],
    });
  }

  render() {
    if (!this.props.beats.data || !this.props.authentication.data) {
      return <Spinner />;
    }

    let fishingCards = [];
    const Event = (event) => {
      let color = this.state.selected_events.map((i) => i.id).includes(event.event.id) ? "yellow" : "blue";

      if (event.event.reserved) {
        color = "green";
      }

      return <div className={`event-item color-${color}`}>{event.title}</div>;
    };
    const selectBeats = beatOptions(this.props.beats, this.props.authentication);

    if (this.props.fishing_cards.data) {
      this.state.data.beat_ids.map((beatId) => {
        const beatCards = this.props.fishing_cards.data[beatId];

        if (beatCards) {
          Object.keys(beatCards).forEach((key) => {
            const results = groupBy(beatCards[key], (item) => {
              const priceType = item.price_options.map((i) => {
                return {
                  price_type_id: i.price_type_id,
                  name: i.name,
                  description: i.description,
                  amount: i.amount,
                  tax_option_id: i.tax_option_id,
                };
              });

              return [
                moment(item.publish_date_start).format("YYYY-MM-DD HH:mm"),
                moment(item.publish_date_end).format("YYYY-MM-DD HH:mm"),
                moment(item.valid_from).format("YYYY-MM-DD HH:mm"),
                moment(item.valid_until).format("YYYY-MM-DD HH:mm"),
                item.fishing_card_type_id,
                priceType,
                item.package_id !== null,
              ];
            });

            Object.values(results).map((items) => {
              const joinedIds = items.map((i) => i.id).join("");
              const reserved = items[0].package_id !== null;
              const id = `${beatId}${joinedIds}`;
              const titleText = reserved
                ? this.props.intl.formatMessage({ id: "reserved" })
                : this.props.intl.formatMessage({ id: "available" });
              const cardTypeName = items[0].fishing_card_type?.name ? ` (${items[0].fishing_card_type?.name})` : null;
              const title = `${items.length} ${titleText}${cardTypeName}`;

              fishingCards = [...fishingCards, { id, title, items, start: key, end: key, reserved }];

              return fishingCards;
            });
          });
        }

        return true;
      });
    }

    return (
      <div>
        {this.props.beat_notifications.data && this.context.isAdmin && (
          <div>
            {this.props.beat_notifications.data.map((n, key) => {
              return <Notification key={key} text={n.message} type={n.type} conditions_met={n.conditions_met}></Notification>;
            })}
          </div>
        )}
        {this.state.delete_events && (
          <div className="panel panel-delete">
            {this.props.intl.formatMessage({ id: "delete_multiple_products_alert" })}

            <Button type="danger" onClick={() => this.toggleModal("remove_multiple")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "confirm_deletion" })}
            </Button>
          </div>
        )}

        <PageHeader
          title={this.props.intl.formatMessage({ id: "menu_fishing_card" })}
          extra={[
            <Button key="1" onClick={() => this.toggleModal("add_card_type")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "add_card_type" })}
            </Button>,
            <Button key="2" onClick={() => this.toggleModal("edit_card_type")} size={ANT_CONFIG.size}>
              {this.props.intl.formatMessage({ id: "edit_card_type" })}
            </Button>,
            <Button
              key="3"
              type="primary"
              onClick={() => this.toggleModal("add_availability", this.state.today, this.state.today)}
              size={ANT_CONFIG.size}
            >
              {this.props.intl.formatMessage({ id: "add_availability" })}
            </Button>,
            <Button
              size={ANT_CONFIG.size}
              key="4"
              type="danger"
              onClick={() => this.setState((prevState) => ({ delete_events: !prevState.delete_events, selected_events: [] }))}
            >
              {this.props.intl.formatMessage({ id: "remove" })}
            </Button>,
          ]}
        >
          {selectBeats.length > 1 && (
            <Row style={{ marginTop: 15 }}>
              <Col xs={24} sm={10} md={10} style={{ marginTop: 10, marginBottom: 10, maxWidth: 500 }}>
                <label htmlFor="select_beat" className="ant-label">
                  {this.props.intl.formatMessage({ id: "select_beat" })}
                </label>
                <Select
                  id="select_beat"
                  showSearch
                  size={ANT_CONFIG.size}
                  style={{ width: "100%" }}
                  placeholder={`${this.props.intl.formatMessage({ id: "select_beat" })}`}
                  optionFilterProp="children"
                  onChange={(e) => this.handleBeatChange(e)}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={this.state.data.beat_ids}
                >
                  {selectBeats.map((i, key) => (
                    <Select.Option key={key} value={i.value}>
                      {i.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          )}
        </PageHeader>

        <Card className="booking-calendar-card">
          {this.state.showModal === "add_card_type" ? (
            <ModalAddCardType
              intl={this.props.intl}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "edit_card_type" ? (
            <ModalEditCardType
              intl={this.props.intl}
              calendarData={this.state.data}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "add_availability" ? (
            <ModalAddFishingCard
              intl={this.props.intl}
              calendarData={this.state.data}
              modalData={this.state.modalData}
              selectedBeat={this.state.data.beat_ids[0]}
              selectBeats={selectBeats}
              toggleModal={() => this.hideModal()}
            />
          ) : null}

          {this.state.showModal === "remove_multiple" ? (
            <ModalRemoveFishingCards
              intl={this.props.intl}
              calendarData={this.state.data}
              items={this.state.selected_events}
              toggleModal={() => this.hideModalAfterDelete()}
            />
          ) : null}

          {this.props.fishing_cards.loading && !this.state.showModal && (
            <div className="booking-panel-preloader">
              <Spinner />
            </div>
          )}

          <Row>
            <Col xs={24} className="rc-calendar-container">
              <Calendar
                popup
                selectable
                localizer={this.localizer}
                culture={this.props.intl.locale}
                events={fishingCards}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={(e) => this.onSelectSlot(e)}
                views={["month"]}
                messages={{
                  ...translations.calendar[this.props.intl.locale],
                  showMore: (total) => `+${total} ${this.props.intl.formatMessage({ id: "more" })}`,
                }}
                onNavigate={(e) => this.onNavigate(e)}
                onSelectEvent={(e) => this.onSelectEvent(e)}
                components={{
                  event: Event,
                }}
              />
            </Col>
          </Row>
        </Card>
        <Scribe
          hasMultiple={true}
          intl={this.props.intl}
          items={[
            {
              title: this.props.intl.formatMessage({ id: "scribe_create_fishing_ticket" }),
              link: "https://scribehow.com/embed/Opprette_et_fiskekort__2lJ8JuwXRGOqqK7bKpFh4A",
            },
            {
              title: this.props.intl.formatMessage({ id: "scribe_publish_fishing_tickets" }),
              link: "https://scribehow.com/embed/Legge_ut_fiskekort_for_salg__ApuID7f1TM-ExJZd-5j4iw",
            },
            {
              title: this.props.intl.formatMessage({ id: "scribe_edit_fishing_tickets" }),
              link: "https://scribehow.com/embed/Oppdatere_fiskekort__ilVqB-jDRzmF65Gii2-MJw",
            },
            {
              title: this.props.intl.formatMessage({ id: "scribe_delete_fishing_tickets" }),
              link: "https://scribehow.com/embed/Slette_fiskekort_som_ligger_til_salgs__P6SKinchTI-ryHega8FEaA",
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  beats: state.Beats,
  fishing_cards: state.FishingCards,
  beat_notifications: state.BeatNotifications,
});

export default withRouter(injectIntl(connect(mapStateToProps)(FishingCards)));
