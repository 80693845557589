import React from "react";
import { GlobalOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

import { DEFAULT_LANGUAGE, LANGUAGES, MAIN_LIGHT_BLUE_COLOR } from "../../constants";
import ANT_CONFIG from "../../constants/antconfig";

class LanguageDropdown extends React.Component {
  state = {
    language: window.localStorage.getItem("language") || DEFAULT_LANGUAGE
  };

  handleMenuClick = e => {
    this.setState({
      language: e.key
    });

    this.props.setLanguage(e.key);
  };

  render() {
    const color = MAIN_LIGHT_BLUE_COLOR;
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        {LANGUAGES.map(({ value, label }) => (
          <Menu.Item key={value} style={this.state.language === value ? { color } : {}}>
            {label}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown key="1" overlay={menu}>
        <Button size={ANT_CONFIG.size}>
          <GlobalOutlined />
        </Button>
      </Dropdown>
    );
  }
}

export default LanguageDropdown;
