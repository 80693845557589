import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import Moment from "moment";
import { Table, DatePicker, Col, Row, PageHeader, Select, Card, Button } from "antd";
import { extendMoment } from "moment-range";

import Spinner from "../../components/spinner";
import beatOptions from "../../services/select_beat_options";
import { fetchSeasonCardsReport, downloadSeasonCardsReport, filterSeasonCardsReport } from "../../redux/reports/actions";
import { fetch as fetchBeats, select as selectBeat } from "../../redux/beats/actions";
import ANT_CONFIG from "../../constants/antconfig";
import downloadFile from "../../services/download_file";
import FileDownloadDropdown from "../../components/FileDownloadDropdown";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {UTCDateToLocalTime, UTCDateToLocalTimeString} from "../../services/utc_converter";

const moment = extendMoment(Moment);

class SeasonCardsReport extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = "YYYY-MM-DD";
    this.state = {
      filetype: null,
      filterLists: [],
      selectedCatchFilter: "",
      fetch_by: "purchase_date",
      date_start: moment().subtract(4, "week").format(`${this.dateFormat}`),
      date_end: moment().format(`${this.dateFormat}`),
      beat_ids:
        Array.isArray(this.props.beats.selected) || !this.props.beats.selected
          ? this.props.authentication.data.beat[0]
          : this.props.beats.selected,
    };
  }

  componentDidMount() {
    this.setState({ beat_ids: null });
    if (!this.props.beats.data) {
      this.props.dispatch(fetchBeats());
    }

    this.handleDataChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reports.file !== this.props.reports.file && this.props.reports.file) {
      const uniqId = Math.random().toString(36).substr(2, 9);

      downloadFile(this.props.reports.file, this.state.filetype, `by-season-cards-${uniqId}`);
    }
  }

  onFilterSubmit() {
    this.setState({ filterLists: [], selectedCatchFilter: "" });

    this.props.dispatch(selectBeat(this.state.beat_ids));

    this.handleDataChange();
  }

  handleDownload = (reportType = null, filetype = "pdf") => {
    const { data } = this.props.reports;

    this.setState(
      {
        filetype,
      },
      () => {
        data.filters = {
          ...data.filters,
          filetype,
          view_type: this.state.reportBy,
        };
        data.results = this.props.reports.filtered_data;

        this.props.dispatch(downloadSeasonCardsReport(this.props.reports.data, reportType));
      },
    );
  };

  handleDataChange() {
    if (!this.state.beat_ids && this.props.beats.data?.length > 0) {
      this.setState({ beat_ids: this.props.beats.data[0].id }, () => {
        this.handleFetchDataChanges();
      });
    } else {
      this.handleFetchDataChanges();
    }
  }

  handleFetchDataChanges() {
    const data = {
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      beat_ids: this.state.beat_ids ? [this.state.beat_ids] : [],
    };

    this.props.dispatch(fetchSeasonCardsReport(data));
  }

  handleDateChange(e, key) {
    this.setState({
      [key]: moment(e).format(`${this.dateFormat}`),
    });
  }

  handleBeatChange(e) {
    this.setState({
      beat_ids: e,
    });
  }

  render() {
    const { intl } = this.props;

    if (!this.props.reports.data || !this.props.beats.data || this.props.reports.loading) {
      return <Spinner />;
    }

    const data = this.props.reports.filtered_data || [];
    const unfilteredData = Array.isArray(this.props.reports.unfiltered_data) ? this.props.reports.unfiltered_data : [];
    const catchFilterOptions = [
      { label: intl.formatMessage({ id: "all_catches" }), value: "" },
      { label: intl.formatMessage({ id: "report_submitted" }), value: "registered_catch" },
      { label: intl.formatMessage({ id: "report_missing" }), value: "unregistered_catch" },
    ];

    const onCatchFilterSelected = (value, data) => {
      let originalData = data;
      switch (value) {
        case "registered_catch":
          originalData = originalData.filter((record) => record.registered_catch === 1);
          break;
        case "unregistered_catch":
          originalData = originalData.filter((record) => record.registered_catch === 0);
          break;
        default:
          originalData = data;
          break;
      }
      return originalData;
    };

    let filterOptions = unfilteredData?.map((it) => {
      return { label: it.type, value: it.type };
    });
    filterOptions = filterOptions.filter(
      (value, index, self) => index === self.findIndex((t) => t.label === value.label && t.value === value.value),
    );

    const onFilterSelected = (filterList) => {
      if (!filterList.length) {
        return unfilteredData;
      }
      let originalData = unfilteredData;
      let filtredData = [];
      for (const value of filterList) {
        filtredData = [...filtredData, ...originalData.filter((record) => record.type === value)];
      }
      return filtredData;
    };

    const onChange = () => {
      let filterTicket = onFilterSelected(this.state.filterLists);
      let filterCatchData = onCatchFilterSelected(this.state.selectedCatchFilter, filterTicket);
      this.props.dispatch(filterSeasonCardsReport(filterCatchData));
    };

    let selectBeats = [];
    if (this.props.beats.data && this.props.beats.data.length > 0) {
      selectBeats = [...selectBeats, ...beatOptions(this.props.beats, this.props.authentication)];
    }

    const tableColumns = [
      {
        title: "SFCID",
        dataIndex: "key",
        key: "key",
        width: 100,
        sorter: (a, b) => parseInt(a?.key) - parseInt(b?.key),
      },
      {
        title: intl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type",
        width: 200,
        sorter: (a, b) => (a.type === "-") - (b.type === "-") || ("" + a.type).localeCompare(b.type),
      },
      {
        title: intl.formatMessage({ id: "beat" }),
        dataIndex: "beat",
        key: "beat",
        width: 120,
        sorter: (a, b) => (a.beat === "-") - (b.beat === "-") || ("" + a.beat).localeCompare(b.beat),
      },
      {
        title: intl.formatMessage({ id: "season" }),
        dataIndex: "year",
        key: "year",
        width: 120,
        sorter: (a, b) => (b.year !== "-") - (a.year !== "-") || a.year - b.year,
      },
      {
        title: intl.formatMessage({ id: "angler_name" }),
        dataIndex: "name",
        key: "name",
        width: 120,
        sorter: (a, b) => (a.name === "-") - (b.name === "-") || ("" + a.name).localeCompare(b.name),
      },
      {
        title: intl.formatMessage({ id: "buyer_name" }),
        dataIndex: "buyer_name",
        key: "buyer_name",
        width: 120,
        sorter: (a, b) => (a.buyer_name === "-") - (b.buyer_name === "-") || ("" + a.buyer_name).localeCompare(b.buyer_name),
        render: (data, rec) => (
          <div onClick={(e) => e.stopPropagation()}>
            {rec?.user_id ? <Link to={`/customer/${rec?.user_id}`}>{data}</Link> : { data }}
          </div>
        ),
      },
      {
        title: intl.formatMessage({ id: "buyer_address" }),
        dataIndex: "buyer_address",
        key: "buyer_address",
        width: 150,
        sorter: (a, b) =>
          (a.buyer_address === "-") - (b.buyer_address === "-") || ("" + a.buyer_address).localeCompare(b.buyer_address),
      },
      {
        title: intl.formatMessage({ id: "buyer_country" }),
        dataIndex: "buyer_country",
        key: "buyer_country",
        width: 100,
        sorter: (a, b) =>
          (a.buyer_country === "-") - (b.buyer_country === "-") || ("" + a.buyer_country).localeCompare(b.buyer_country),
      },
      {
        title: intl.formatMessage({ id: "buyer_phone" }),
        dataIndex: "buyer_phone",
        key: "buyer_phone",
        width: 120,
        sorter: (a, b) => (b.buyer_phone !== "-") - (a.buyer_phone !== "-") || a.buyer_phone - b.buyer_phone,
      },
      {
        title: intl.formatMessage({ id: "buyer_email" }),
        dataIndex: "buyer_email",
        key: "buyer_email",
        width: 200,
        sorter: (a, b) =>
          (a.buyer_email === "-") - (b.buyer_email === "-") || ("" + a.buyer_email).localeCompare(b.buyer_email),
      },
      {
        title: intl.formatMessage({ id: "order_id" }),
        dataIndex: "order_id",
        key: "order_id",
        width: 100,
        sorter: (a, b) => (b.order_id !== "-") - (a.order_id !== "-") || a.order_id - b.order_id,
      },
      {
        title: intl.formatMessage({ id: "order_date" }),
        dataIndex: "order_date",
        key: "order_date",
        width: 120,
        sorter: (a, b) => new Date(a.order_date) - new Date(b.order_date),
      },
      {
        title: intl.formatMessage({ id: "price_type" }),
        dataIndex: "price_type",
        key: "price_type",
        width: 120,
        sorter: (a, b) => (a.price_type === "-") - (b.price_type === "-") || ("" + a.price_type).localeCompare(b.price_type),
      },
      {
        title: intl.formatMessage({ id: "price_amount" }),
        dataIndex: "price_amount",
        key: "price_amount",
        width: 120,
        sorter: (a, b) => (b.price_amount !== "-") - (a.price_amount !== "-") || a.price_amount - b.price_amount,
      },
      {
        title: intl.formatMessage({ id: "seller" }),
        dataIndex: "seller",
        key: "seller",
        width: 140,
        sorter: (a, b) => (a.seller === "-") - (b.seller === "-") || ("" + a.seller).localeCompare(b.seller),
      },
      {
        title: intl.formatMessage({ id: "catch_report_status" }),
        dataIndex: "registered_catch",
        key: "registered_catch",
        width: 100,
        render: (data, record) => (
          <div>
            {data === 1 ? (
              <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 30 }} />
            ) : (
              <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 30 }} />
            )}
          </div>
        ),
        sorter: (a, b) => a.registered_catch - b.registered_catch,
      },
    ];

    const tableData = [];

    if (data && Array.isArray(data)) {
      data.forEach((item) => {
        tableData.push({
          key: item.sfcid,
          type: item.type,
          beat: item.beat,
          year: item.year,
          name: item.name || "-",
          buyer_name: item.buyer_name || "-",
          buyer_address: item.buyer_address || "-",
          buyer_country: item.buyer_country || "-",
          buyer_phone: item.buyer_phone || "-",
          buyer_email: item.buyer_email || "-",
          order_id: item.order_id,
          order_date: UTCDateToLocalTimeString(item.order_date),
          price_type: item.price_type,
          price_amount: item.price_amount,
          seller: item.seller,
          user_id: item.user_id,
          registered_catch: item.registered_catch,
        });
      });
    }
    return (
      <div>
        <PageHeader
          title={intl.formatMessage({ id: "season_card" })}
          extra={[<FileDownloadDropdown key="1" handleClick={this.handleDownload} loading={this.props.reports.file_loading} />]}
        >
          <Row style={{ marginTop: 15 }}>
            <Col xs={24}>
              <Row gutter={16} className="row-flex flex-align-end">
                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_start" className="ant-label">
                    {intl.formatMessage({ id: "date_start_order_date" })}
                  </label>
                  <DatePicker
                    id="date_start"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_start)}
                    onChange={(e) => this.handleDateChange(e, "date_start")}
                  />
                </Col>

                <Col xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 200 }}>
                  <label htmlFor="date_end" className="ant-label">
                    {intl.formatMessage({ id: "date_end_order_date" })}
                  </label>
                  <DatePicker
                    id="date_end"
                    disabled={this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                    allowClear={false}
                    format={ANT_CONFIG.datepicker_format}
                    value={moment(this.state.date_end)}
                    onChange={(e) => this.handleDateChange(e, "date_end")}
                  />
                </Col>

                {this.props.beats.data.length > 1 && (
                  <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10, maxWidth: 400 }}>
                    <label htmlFor="select_beat" className="ant-label">
                      {intl.formatMessage({ id: "select_beat" })}
                    </label>
                    <Select
                      id="select_beat"
                      showSearch
                      disabled={this.props.reports.file_loading}
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      placeholder={`${intl.formatMessage({ id: "select_beat" })}`}
                      optionFilterProp="children"
                      onChange={(e) => this.handleBeatChange(e)}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      value={this.state.beat_ids}
                    >
                      {selectBeats.map((i, key) => (
                        <Select.Option key={key} value={i.value}>
                          {i.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col xs={24} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Button
                    type="primary"
                    disabled={this.props.reports.file_loading}
                    onClick={() => this.onFilterSubmit()}
                    loading={this.props.reports.loading || this.props.reports.file_loading}
                    size={ANT_CONFIG.size}
                  >
                    {intl.formatMessage({ id: "apply" })}
                  </Button>
                </Col>
                <Col span={12} xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <>
                    <label htmlFor="filter_ticket_type" className="ant-label">
                      {intl.formatMessage({ id: "filter_ticket_type" })}
                    </label>
                    <Select
                      mode="multiple"
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      onChange={(e) => {
                        this.setState(() => {
                          return { filterLists: e };
                        });
                        setTimeout(() => {
                          onChange();
                        }, 1);
                      }}
                      options={filterOptions}
                    />
                  </>
                </Col>

                <Col span={12} xs={12} sm={6} md={6} style={{ marginTop: 10, marginBottom: 10 }}>
                  <>
                    <label htmlFor="filter_catch_reporting" className="ant-label">
                      {intl.formatMessage({ id: "filter_catch_reporting" })}
                    </label>
                    <Select
                      size={ANT_CONFIG.size}
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      onChange={(e) => {
                        this.setState(() => {
                          return { selectedCatchFilter: e };
                        });
                        setTimeout(() => {
                          onChange();
                        }, 1);
                      }}
                      options={catchFilterOptions}
                    />
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Card style={{ marginTop: 20, marginBottom: 20 }}>
          <Row>
            <Col xs={24}>
              <Table
                className="ant-no-prewrap"
                bordered
                columns={tableColumns}
                dataSource={tableData}
                pagination={ANT_CONFIG.pagination}
                sticky={{ offsetHeader: 50 }}
                scroll={{
                  x: 1500,
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reports: state.Reports,
  authentication: state.Auth,
  beats: state.Beats,
});

export default withRouter(injectIntl(connect(mapStateToProps)(SeasonCardsReport)));
