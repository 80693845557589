import React from "react";
import { injectIntl } from "react-intl";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";

import ANT_CONFIG from "../../constants/antconfig";
import { DOWNLOAD_FILE_TYPES } from "../../constants";

const FileDownloadDropdown = ({ handleClick, intl, loading, options = DOWNLOAD_FILE_TYPES }) => {
  const handleMenuClick = (e) => {
    handleClick(e.item.props.type, e.key);
  };

  return (
    <Dropdown
      key="1"
      overlay={
        <Menu onClick={handleMenuClick}>
          {options.map(({ label, value, type = null }) => (
            <Menu.Item key={value} type={type}>
              {label}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button loading={loading} type="danger" icon={<DownloadOutlined />} size={ANT_CONFIG.size}>
        {intl.formatMessage({ id: "download" })}
      </Button>
    </Dropdown>
  );
};

export default injectIntl(FileDownloadDropdown);
