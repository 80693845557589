import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import Spinner from "../../spinner";
import SaveLabel from "../../helpers/buttons/save_label";
import { add as addTax, edit as editTax } from "../../../redux/taxes/actions";

const labelWidth = 4;
const inputWidth = 8;

class ModalAddTax extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true,
      submitted: false,
      update_id: props.modalData !== undefined ? props.modalData.items[0].id : null,
      data: {
        description: props.modalData !== undefined ? props.modalData.items[0].key : "",
        percentage: props.modalData !== undefined ? props.modalData.items[0].value : "",
        code: props.modalData !== undefined && props.modalData.items[0].code ? props.modalData.items[0].code : ""
      }
    };
  }

  componentDidUpdate() {
    if (!this.props.taxes.loading && !this.props.taxes.error && this.state.submitted) {
      this.toggle();
    }
  }

  onChangeValue(newState) {
    this.setState(prevState => ({ data: { ...prevState.data, ...newState } }));
  }

  onSubmit() {
    this.setState({
      submitted: true
    });

    if (this.state.update_id) {
      this.props.dispatch(
        editTax({
          id: this.state.update_id,
          description: this.state.data.description,
          percentage: parseFloat(this.state.data.percentage).toFixed(2),
          code: this.state.data.code
        })
      );
    } else {
      this.props.dispatch(
        addTax({
          taxes: [
            {
              description: this.state.data.description,
              percentage: parseFloat(this.state.data.percentage).toFixed(2),
              code: this.state.data.code
            }
          ]
        })
      );
    }
  }

  toggle() {
    this.props.toggleModal();
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={() => this.toggle()} className={this.props.className}>
        <ModalHeader toggle={() => this.toggle()}>
          {this.state.update_id ? this.props.intl.formatMessage({ id: "update_tax" }) : this.props.intl.formatMessage({ id: "add_tax" })}
        </ModalHeader>
        {this.props.taxes.loading ? (
          <Spinner />
        ) : (
          <Form>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <FormGroup row>
                    <Label for="description" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "description" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="text"
                        name="description"
                        id="description"
                        required
                        value={this.state.data.description}
                        onChange={e => this.onChangeValue({ description: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="percentage" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "percentage" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input
                        type="number"
                        name="percentage"
                        id="percentage"
                        required
                        value={this.state.data.percentage}
                        onChange={e => this.onChangeValue({ percentage: e.target.value })}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="percentage" xs={12} sm={labelWidth}>
                      {this.props.intl.formatMessage({ id: "code" })}
                    </Label>
                    <Col xs={12} sm={inputWidth}>
                      <Input type="text" name="code" id="code" value={this.state.data.code} onChange={e => this.onChangeValue({ code: e.target.value })} />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.onSubmit()} type="submit">
                <SaveLabel intl={this.props.intl} />
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  taxes: state.Taxes,
  accommodation: state.Accommodation
});

export default withRouter(injectIntl(connect(mapStateToProps)(ModalAddTax)));
