export const FETCH_ADMIN_FISH_TYPES = "FETCH_ADMIN_FISH_TYPES";
export const FETCH_ADMIN_FISH_TYPES_SUCCESS = "FETCH_ADMIN_FISH_TYPES_SUCCESS";
export const FETCH_ADMIN_FISH_TYPES_FAILURE = "FETCH_ADMIN_FISH_TYPES_FAILURE";

export const ADD_ADMIN_FISH_TYPES = "ADD_ADMIN_FISH_TYPES";
export const ADD_ADMIN_FISH_TYPES_SUCCESS = "ADD_ADMIN_FISH_TYPES_SUCCESS";
export const ADD_ADMIN_FISH_TYPES_FAILURE = "ADD_ADMIN_FISH_TYPES_FAILURE";

export const UPDATE_ADMIN_FISH_TYPES = "UPDATE_ADMIN_FISH_TYPES";
export const UPDATE_ADMIN_FISH_TYPES_SUCCESS = "UPDATE_ADMIN_FISH_TYPES_SUCCESS";
export const UPDATE_ADMIN_FISH_TYPES_FAILURE = "UPDATE_ADMIN_FISH_TYPES_FAILURE";

export const DELETE_ADMIN_FISH_TYPES = "DELETE_ADMIN_FISH_TYPES";
export const DELETE_ADMIN_FISH_TYPES_SUCCESS = "DELETE_ADMIN_FISH_TYPES_SUCCESS";
export const DELETE_ADMIN_FISH_TYPES_FAILURE = "DELETE_ADMIN_FISH_TYPES_FAILURE";

export function fetch() {
  return {
    type: FETCH_ADMIN_FISH_TYPES
  };
}

export function add(data) {
  return {
    type: ADD_ADMIN_FISH_TYPES,
    data
  };
}

export function update(data) {
  return {
    type: UPDATE_ADMIN_FISH_TYPES,
    data
  };
}

export function remove(id) {
  return {
    type: DELETE_ADMIN_FISH_TYPES,
    id
  };
}
