export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const FETCH_ORDERS_BY_PRODUCT_DATE = "FETCH_ORDERS_BY_PRODUCT_DATE";
export const FETCH_ORDERS_BY_PRODUCT_DATE_SUCCESS = "FETCH_ORDERS_BY_PRODUCT_DATE_SUCCESS";
export const FETCH_ORDERS_BY_PRODUCT_DATE_FAILURE = "FETCH_ORDERS_BY_PRODUCT_DATE_FAILURE";

export const FETCH_CUSTOMER_ORDERS = "FETCH_CUSTOMER_ORDERS";
export const FETCH_CUSTOMER_ORDERS_SUCCESS = "FETCH_CUSTOMER_ORDERS_SUCCESS";
export const FETCH_CUSTOMER_ORDERS_FAILURE = "FETCH_CUSTOMER_ORDERS_FAILURE";

export const SEND_PAYMENT_LINK = "SEND_PAYMENT_LINK";
export const SEND_PAYMENT_LINK_SUCCESS = "SEND_PAYMENT_LINK_SUCCESS";
export const SEND_PAYMENT_LINK_FAILURE = "SEND_PAYMENT_LINK_FAILURE";

export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const CANCEL_ORDER_FOR_USER = "CANCEL_ORDER_FOR_USER";
export const CANCEL_ORDER_FOR_USER_SUCCESS = "CANCEL_ORDER_FOR_USER_SUCCESS";
export const CANCEL_ORDER_FOR_USER_FAILURE = "CANCEL_ORDER_FOR_USER_FAILURE";

export function fetch(payload) {
  return {
    type: FETCH_ORDERS,
    payload
  };
}

export function fetchByProductDate(payload) {
  return {
    type: FETCH_ORDERS_BY_PRODUCT_DATE,
    payload
  };
}

export function fetchCustomerOrders(payload) {
  return {
    type: FETCH_CUSTOMER_ORDERS,
    payload
  };
}

export function sendLink(payload) {
  return {
    type: SEND_PAYMENT_LINK,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function cancel(data, payload) {
  return {
    type: CANCEL_ORDER,
    data,
    payload,
    meta: {
      thunk: true
    }
  };
}

export function cancelForUser(data, payload) {
  return {
    type: CANCEL_ORDER_FOR_USER,
    data,
    payload,
    meta: {
      thunk: true
    }
  };
}
