import React from "react";
import { Table, Button } from "antd";
import { injectIntl } from "react-intl";

import ANT_CONFIG from "../../constants/antconfig";

class MarkerList extends React.Component {
  state = {
    active: null
  };

  setActive = id => {
    this.setState(
      prevState => ({
        active: prevState.active !== id ? id : null
      }),
      () => {
        if (this.props.activeMarker) {
          this.props.activeMarker(this.state.active);
        }
      }
    );
  };

  removeActive = () => {
    this.setState(
      {
        active: null
      },
      () => {
        if (this.props.activeMarker) {
          this.props.activeMarker(this.state.active);
        }
      }
    );
  };

  onRemove = id => {
    if (this.props.onMarkerRemove) {
      this.props.onMarkerRemove(id);
    }
  };

  render() {
    const { intl, data } = this.props;

    if (!data) {
      return;
    }

    const tableColumns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key"
      },
      {
        title: intl.formatMessage({ id: "type" }),
        dataIndex: "type",
        key: "type"
      },
      {
        title: intl.formatMessage({ id: "icon" }),
        dataIndex: "icon",
        key: "icon"
      },
      {
        title: intl.formatMessage({ id: "latitude" }),
        dataIndex: "latitude",
        key: "latitude"
      },
      {
        title: intl.formatMessage({ id: "longitude" }),
        dataIndex: "longitude",
        key: "longitude"
      },
      {
        title: "",
        dataIndex: "delete",
        key: "delete"
      }
    ];

    const tableData = data.map((i, key) => {
      const icon = i.icon || "default";

      return {
        key: key + 1,
        id: i.id,
        type: intl.formatMessage({ id: i.type }),
        icon: i.type === "marker" ? <img src={`/images/map/${icon}.svg`} alt="" /> : "",
        latitude: i.latitude || "-",
        longitude: i.longitude || "-",
        delete: (
          <Button type="danger" onClick={() => this.onRemove(i.id)}>
            {intl.formatMessage({ id: "remove" })}
          </Button>
        )
      };
    });

    return (
      <Table
        className="ant-design-table-border"
        onRow={record => {
          return {
            onMouseEnter: () => this.setActive(record.id),
            onMouseLeave: () => this.removeActive()
          };
        }}
        columns={tableColumns}
        dataSource={tableData}
        pagination={ANT_CONFIG.pagination}
      />
    );
  }
}

export default injectIntl(MarkerList);
