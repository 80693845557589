export const FETCH_SEASON_CARDS_DATES = "FETCH_SEASON_CARDS_DATES";
export const FETCH_SEASON_CARDS_DATES_SUCCESS = "FETCH_SEASON_CARDS_DATES_SUCCESS";
export const FETCH_SEASON_CARDS_DATES_FAILURE = "FETCH_SEASON_CARDS_DATES_FAILURE";

export const POST_SEASON_CARDS_DATES = "POST_SEASON_CARDS_DATES";
export const POST_SEASON_CARDS_DATES_SUCCESS = "POST_SEASON_CARDS_DATES_SUCCESS";
export const POST_SEASON_CARDS_DATES_FAILURE = "POST_SEASON_CARDS_DATES_FAILURE";

export function fetch(beatId) {
  return {
    type: FETCH_SEASON_CARDS_DATES,
    beatId
  };
}

export function post(payload, beatId) {
  return {
    type: POST_SEASON_CARDS_DATES,
    payload,
    beatId
  };
}
