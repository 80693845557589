import * as ACTION from './actions';

const INIT_STATE = {
  loading: false,
  error: false,
  types: {
    data: undefined,
    loading: false,
    error: false,
  },
  packages: {
    data: undefined,
    loading: false,
    error: false,
  },
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {

    case ACTION.FETCH_PACKAGES_TYPES:
      return {
        ...state,
        types: {
          ...state.types,
          loading: true,
        }
      };

    case ACTION.FETCH_PACKAGES_TYPES_SUCCESS:
      return {
        ...state,
        types: {
          data: action.payload.data,
          loading: false,
          error: false,
        }
      };

    case ACTION.FETCH_PACKAGES_TYPES_FAILURE:
      return {
        ...state,
        types: {
          ...state.types,
          loading: false,
          error: true,
        }
      };

    case ACTION.FETCH_PACKAGES:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: true,
        }
      };

    case ACTION.FETCH_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: {
          data: action.payload.data,
          loading: false,
          error: false,
        }
      };

    case ACTION.FETCH_PACKAGES_FAILURE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: true
        }
      };

    case ACTION.ADD_PACKAGE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: true,
        }
      };

    case ACTION.ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: false,
        }
      };

    case ACTION.ADD_PACKAGE_FAILURE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: true,
        }
      };

    case ACTION.EDIT_PACKAGE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: true,
        }
      };

    case ACTION.EDIT_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: false,
        }
      };

    case ACTION.EDIT_PACKAGE_FAILURE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: true,
        }
      };

    case ACTION.REMOVE_PACKAGE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: true,
        }
      };

    case ACTION.REMOVE_PACKAGE_SUCCESS:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: false,
        }
      };

    case ACTION.REMOVE_PACKAGE_FAILURE:
      return {
        ...state,
        packages: {
          ...state.packages,
          loading: false,
          error: true,
        }
      };

    default:
      return state;
  }
}
