import React from "react";

function IframeModal({src}) {
  return (
      <iframe
        src={src}
        width="100%"
        height="640"
        allowFullScreen
        frameBorder="0"
      ></iframe>
 
  );
}

export default IframeModal;
