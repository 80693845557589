import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Button, Card, Col, Row, PageHeader, Select, Checkbox, Radio } from "antd";

import { fetch as fetchRivers, select as selectRiver, setCatchReportSettings } from "../../redux/rivers/actions";
import Spinner from "../../components/spinner";
import riverOptions from "../../services/select_river_options";
import ANT_CONFIG from "../../constants/antconfig";

class CatchReportSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      river_id: this.props.rivers.selected || this.props.authentication.data.river[0],
      allow_block: undefined,
      catch_reporting_type: this.props.rivers?.selected?.catch_reporting_type || "OPEN"
    };
  }

  componentDidMount() {
    if (!this.props.rivers.data) {
      this.props.dispatch(fetchRivers());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rivers.data !== this.props.rivers.data && nextProps.rivers.data) {
      this.setState((prevState) => ({
        allow_block: nextProps.rivers.data.filter((i) => i.id === prevState.river_id)[0].allow_block,
      }));
    }
  }

  onSave() {
    const data = {
      allow_block: this.state.allow_block,
      river_id: this.state.river_id,
      catch_reporting_type: this.state.catch_reporting_type
    };

    this.props.dispatch(setCatchReportSettings(data, this.state.river_id));
  }

  handleRiverChange(e) {
    this.props.dispatch(selectRiver(e));
    this.setState({
      river_id: e,
      allow_block: this.props.rivers.data.filter((i) => i.id === e)[0].allow_block,
      catch_reporting_type: this.props.rivers.data.filter((i) => i.id === e)[0].catch_reporting_type,
    });
  }

  render() {
    if (!this.props.rivers.data) {
      return <Spinner />;
    }

    const selectRivers = riverOptions(this.props.rivers, this.props.authentication);

    return (
      <React.Fragment>
        <PageHeader title={this.props.intl.formatMessage({ id: "catch_report_settings" })} />

        <Row>
          <Col xs={24}>
            <Card style={{ marginTop: 20 }}>
              <div className="container-600">
                <Row>
                  {selectRivers.length > 1 ? (
                    <Col xs={24} style={{ marginBottom: 10 }}>
                      <label className="ant-label" htmlFor="select_river">
                        {this.props.intl.formatMessage({ id: "select_river" })}
                      </label>
                      <Select
                        showSearch
                        id="select_river"
                        size={ANT_CONFIG.size}
                        style={{ width: "100%" }}
                        placeholder={`${this.props.intl.formatMessage({ id: "select_river" })}`}
                        optionFilterProp="children"
                        onChange={(e) => this.handleRiverChange(e)}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        value={this.state.river_id}
                      >
                        {selectRivers.map((i, key) => (
                          <Select.Option key={key} value={i.value}>
                            {i.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  ) : null}
                </Row>

                <Row style={{ marginTop: 20 }}>
                  <Col xs={24}>
                    <h2><b>{this.props.intl.formatMessage({ id: "settings" })}</b></h2>
                    <hr style={{ borderTop: "1px solid black" }}/>
                  </Col>
                  <Col xs={24} style={{ marginTop: 10 }}>
                    <p>{this.props.intl.formatMessage({ id: "catch_report_option" })}</p>
                    <Checkbox checked={this.state.allow_block} onChange={() => this.setState((prevState) => ({ ...prevState, allow_block: !prevState.allow_block }))}>
                      {this.props.intl.formatMessage({ id: "required_catch_report" })}
                    </Checkbox>
                  </Col>
                </Row>

                <hr style={{ borderTop: "1px solid black" }}/>

                <Row style={{ marginTop: 10 }}>
                  <Col xs={24}>
                    <label><b>{this.props.intl.formatMessage({ id: "catch_report_type" })}</b></label>
                    <p>"{this.props.intl.formatMessage( { id: "open_reporting"})}" {this.props.intl.formatMessage({ id: "open_reporting_description" })}</p>
                    <p>"{this.props.intl.formatMessage( { id: "simple"})}" {this.props.intl.formatMessage({ id: "simple_description" })}</p>
                    <p>"{this.props.intl.formatMessage( { id: "card_connected"})}" {this.props.intl.formatMessage({ id: "card_connected_description" })}</p>
                  </Col>
                  <Col xs={24}>
                    <Radio.Group onChange={(e) => this.setState((prevState => ({ ...prevState, catch_reporting_type: e.target.value })))} value={this.state.catch_reporting_type}>
                      <Row>
                        <Radio checked={this.state.catch_reporting_type} value="OPEN"><b>{this.props.intl.formatMessage({ id: "open_reporting" })}</b></Radio>
                      </Row>
                      <Row>
                        <Radio checked={this.state.catch_reporting_type} value="SIMPLE"><b>{this.props.intl.formatMessage({ id: "simple" })}</b></Radio>
                      </Row>
                      <Row>
                        <Radio checked={this.state.catch_reporting_type} value="CARD_CONNECTED"><b>{this.props.intl.formatMessage({ id: "card_connected" })}</b></Radio>
                      </Row>
                    </Radio.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} className="text-right">
                    <Button type="primary" style={{ marginLeft: 8 }} onClick={(e) => this.onSave(e)} loading={this.props.rivers.loading} size={ANT_CONFIG.size}>
                      {this.props.intl.formatMessage({ id: "save" })}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.Auth,
  rivers: state.Rivers,
});

export default withRouter(injectIntl(connect(mapStateToProps)(CatchReportSettings)));
